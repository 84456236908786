import React, { useState } from 'react';
import OpenInNewSharpIcon from '@mui/icons-material/OpenInNewSharp';
import { Link } from 'react-router-dom';
import {
  Content,
  ListContainer,
  Item,
  ItemContent,
  PrivateContent,
  HeaderPrivateContent,
  PrivateContainer,
} from './styles';
import { manualList } from './fiedls';
import { useAuth } from '../../hooks/auth';
import ObjectRenderer from '../../components/objectRenderer';

export const ManuaisBEE4 = () => {
  const { currentRole } = useAuth();
  const [privateUrl, setPrivateUrl] = useState<string | null>(null);
  const [chosenItem, setChosenItem] = useState<string | null>(null);

  const handleManual = () =>
    manualList
      .filter(item => item.acess === 'all' || item.acess === currentRole)
      .map((item, index) => {
        if (item.type === 'public') {
          return (
            <Item key={item.name}>
              <Link
                to={item.link}
                target="_blank"
                style={{ textDecoration: 'none' }}
              >
                <ItemContent>
                  <h4>
                    {index + 1}. {item.name}
                  </h4>
                  <OpenInNewSharpIcon sx={{ color: '#0852DD' }} />
                </ItemContent>
              </Link>
            </Item>
          );
        }
        return (
          <Item key={item.name}>
            <ItemContent>
              <button
                type="button"
                onClick={() => {
                  setPrivateUrl(item.link);
                  setChosenItem(`${index + 1}. ${item.name}`);
                }}
              >
                {index + 1}. {item.name}
              </button>
            </ItemContent>
          </Item>
        );
      });

  if (privateUrl) {
    return (
      <PrivateContent>
        <PrivateContainer>
          <HeaderPrivateContent>
            <button
              type="button"
              onClick={() => {
                setPrivateUrl(null);
                setChosenItem(null);
              }}
              aria-label="Sair"
            >
              <img src="images/go-back-icon.svg" alt="" />
            </button>
            {chosenItem}
          </HeaderPrivateContent>

          <div style={{ height: '100%', minHeight: '100%' }}>
            <ObjectRenderer url={privateUrl} />
          </div>
        </PrivateContainer>
      </PrivateContent>
    );
  }
  return (
    <Content>
      <ListContainer>{handleManual()}</ListContainer>
    </Content>
  );
};
