import styled, { css } from 'styled-components';

interface ContentProps {
  status?: number;
}

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  p {
    font-weight: 600;
    color: white;
  }
`;

export const IndexColumns = styled.div`
  width: 100%;
  color: #171725;
  text-align: left;
  text-transform: uppercase;
  letter-spacing: 1.2px;
  display: flex;
  font-weight: 600;
  font-size: 12px;
  height: 48px;
  padding: 0 16px;
  margin: auto;
  margin-top: 3rem;
`;

export const FirstColumn = styled.div`
  width: 20%;
  display: flex;
  align-items: center;
`;

export const SecondColumn = styled.div<any>`
  width: 20%;
  display: flex;
  align-items: center;

  ${({ value }) =>
    value &&
    css`
      overflow: hidden;
      display: inline-block;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-top: 15px;
    `};
`;

export const ThirdColumn = styled.div`
  width: 10%;
  display: flex;
  align-items: center;
`;

export const FourthColumn = styled.div`
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const FifthColumn = styled.div`
  width: 10%;
  display: flex;
  align-items: center;
`;

export const SixthColumn = styled.div`
  width: 7%;
  display: flex;
  align-items: center;
`;

export const SeventhColumn = styled.div`
  width: 10%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const ModalTitle = styled.h3`
  display: flex;
  justify-content: start;
  margin: 1rem 0 1.5rem 0;
`;
export const ModalDescription = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: rgb(69, 75, 98);
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;
  gap: 1rem;
`;

export const MainButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 1.5rem;
  width: 80%;
  gap: 5px;
`;

export const Tags = styled.section`
  display: inline-flex;
  flex-wrap: wrap;
  gap: 5px;
`;

export const Tag = styled.div`
  display: flex;
  padding: 5px 10px;
  background: #0a4cdc;
  color: #fff;
  border-radius: 20px;
`;

export const ContentColumns = styled.div`
  width: 100%;
  text-align: left;
  display: block;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.17px;
  margin: auto;

  & > span {
  }
`;

export const TableRow = styled.div`
  display: flex;
  height: 48px;
  padding: 0 16px;
  font-weight: 400;
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 0;
  h3 {
    font-weight: 400;
    margin-bottom: 0.7rem;
  }
`;

export const Status = styled.div<ContentProps>`
  background-color: ${props => (props.status !== 1 ? '#A4A7B4' : '#0852DD')};
  border-radius: 28px;
  padding: 5px 10px;
  color: white;
  min-width: 118px;
  text-align: center;
`;

export const ModalContentForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;

  h3 {
    font-size: 1rem;
    font-weight: 600;
  }

  .buttons {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    width: 216px;
    gap: 5px;
  }

  .descriptionModal {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    > div {
      display: flex;
      justify-content: center;
    }
  }
`;

export const Wrapper = styled.section`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const InputGroup = styled.div`
  width: 400px;
`;

export const WithoutData = styled.section`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  color: #8c8f9a;

  width: 100%;
  height: 300px;
  text-align: center;

  h1 {
    font-size: 1.5rem;
    font-weight: 400;
  }

  h2 {
    font-size: 0.9rem;
    font-weight: 400;
    margin-top: 5px;
  }
`;
