import styled from 'styled-components';

export const Container = styled.div`
  padding: 50px 45px 80px;

  button {
    margin: 0 auto;
  }
`;

export const Title = styled.div`
  font-size: 18px;
  font-weight: bold;
`;

export const Text = styled.div`
  font-family: Inter;
  font-size: 12px;
  line-height: 16px;
  margin: 30px 0 80px 14px;

  ol {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  li {
    padding-left: 7px;
    margin-bottom: 10px;

    p:not(:last-child) {
      margin-bottom: 10px;
    }
  }
`;
