import React from 'react';
import { File } from '../file';
import { Container, DeleteButton } from './styles';
import { OutletPermify } from '../../../../components/outletPermify';
import { Roles } from '../../../../router/roles.routes';

interface FilePreviewProps {
  nome: string;
  type: string;
  onDelete: () => void;
}

export const FilePreview = ({ nome, type, onDelete }: FilePreviewProps) => {
  return (
    <Container>
      <File nome={nome} type={type} upload />
      <OutletPermify role={[Roles.ExcluirFatoRelevante]}>
        <DeleteButton onClick={onDelete}>
          <img src="images/lixeira.svg" alt="" />
        </DeleteButton>
      </OutletPermify>
    </Container>
  );
};
