import styled from 'styled-components';

export const Content = styled.div`
  width: 90%;
  margin: auto;
`;

export const FirstSection = styled.div`
  display: flex;
  padding: 1rem 0;
  margin-top: 2rem;
  h1 {
    color: #171725;
    font-size: 1.5rem;
    margin-right: 0.5rem;
  }
  button {
    display: flex;
    align-items: center;
    background-color: #171725;
    border-radius: 8px;
    padding: 0.2rem 1.7rem;
    border: none;
    color: #ffffff;
  }
`;

export const SecondSection = styled.div`
  & + & {
    margin-top: 20px;
  }
`;

export const Plus = styled.span`
  font-size: 1.3rem;
  margin-right: 0.4rem;
`;

export const IndexColumns = styled.div`
  width: 100%;
  color: #171725;
  text-align: left;
  text-transform: uppercase;
  letter-spacing: 1.2px;
  display: flex;
  font-weight: 600;
  font-size: 12px;
  height: 48px;
  padding: 0 16px;
  margin: auto;
`;

export const ContentColumns = styled.div`
  width: 100%;
  text-align: left;
  display: block;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.17px;
  margin: auto;

  & > span {
  }
`;

export const FirstColumn = styled.div`
  width: 30%;
  display: flex;
  align-items: center;
`;
export const SecondColumn = styled.div`
  width: 30%;
  display: flex;
  align-items: center;
`;
export const ThirdColumn = styled.div`
  width: 30%;
  display: flex;
  align-items: center;
`;

export const FourthColumn = styled.div`
  width: 10%;
  display: flex;
  justify-content: end;
`;

export const TableLine = styled.hr`
  background-color: #f6f5fc;
  opacity: 0.1;
`;

export const TableRow = styled.div`
  display: flex;
  height: 48px;
  padding: 0 16px;
  font-weight: 400;
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 2rem auto 0 auto;
  width: 216px;
  .delete,
  .cancel {
    border-radius: 8px;
    padding: 0.5rem 0 0.5rem 0;
    text-align: center;
    width: 6rem;
    border: none;
  }
  .cancel {
    background-color: #bdbdbd;
    color: #424242;
    margin-right: 1rem;
  }
  .delete {
    background-color: #f78157;
    color: #ffffff;
  }
`;

export const ModalTitle = styled.h3`
  display: flex;
  justify-content: start;
  margin: 1rem 0 1.5rem 0;
`;

export const EmptyContent = styled.div`
  width: 100%;
  text-align: center;
  display: block;
  font-weight: 500;
  font-size: 0.9rem;
  padding: 2rem 2.5% 2rem 2.5%;
  margin: auto;
  color: #171725;
`;

export const Spacing = styled.div`
  height: 40px;
`;

export const DeleteForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;

  h3 {
    font-size: 1rem;
    font-weight: 600;
  }

  .deleteButtons {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    width: 216px;
  }

  .descriptionDeleteModal {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
`;

export const ModalDescription = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: rgb(69, 75, 98);
`;

export const GenerateLinkDescription = styled.p`
  color: var(--chumbo-1, #454b62);
  font-family: Georama;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const LinkContainer = styled.div`
  background-color: #edeef1;
  padding: 0.5rem;
  width: 100%;
  display: flex;
  gap: 2rem;
  align-items: center;
  margin-top: 1.5rem;
`;

export const LinkContent = styled.div`
  border-radius: 2px;
  background: var(--branco-absoluto, #fff);
  padding: 0.5rem 1rem;

  a {
    color: var(--azul-2, #2dbcf7);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-decoration: none;
  }
`;

export const CopyLinkContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  color: var(--azul-5, #060b7a);
  font-family: Georama;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.6px;
`;
