import styled from 'styled-components';

interface IListItemProps {
  height?: number;
  width?: number;
}

export const ModalContainer = styled.div<IListItemProps>`
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  overflow-y: auto;
  max-height: 90vh;
  width: ${props => (props.width ? `${props.width}px` : '500px')};
  height: ${props => (props.height ? `${props.height}px` : 'unset')};
  background-color: #fefefe;
  border: 1px solid #888;
  border-radius: 10px;
  overflow-x: hidden;
`;

export const ModalContent = styled.div<IListItemProps>`
  margin: auto;
  top: 25%;
  padding: 2rem !important;
`;

export const CloseButton = styled.div`
  position: absolute;
  right: -0.5rem;
  top: 0.5rem;
  width: fit-content;
`;
