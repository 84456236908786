import React from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { AxiosResponse } from 'axios';
import { api } from '../../../services/api';
import { verifyError } from '../../../utils/verifyError';
import { Response } from '../fields';

interface IUseDashboardListagemHooks {
  currentEnterpriseId: string;
}

export const useDasboardListagem = ({
  currentEnterpriseId,
}: IUseDashboardListagemHooks) => {
  const queryClient = useQueryClient();
  const getDashboardStatus = async () => {
    const getToast = toast.loading('Carregando status');

    try {
      const { data }: AxiosResponse<Response> = await api.main.get(
        `/v2/empresas/${currentEnterpriseId}/status-de-validacao`,
      );

      toast.update(getToast, {
        render: 'Status carregado com sucesso!',
        type: 'success',
        isLoading: false,
        autoClose: 1,
      });

      return data;
    } catch (error) {
      const message = verifyError(error, 'Erro ao carregar os status');
      toast.update(getToast, {
        render: message,
        type: 'error',
        isLoading: false,
        autoClose: 5000,
      });
    }
  };

  const {
    isLoading,
    isSuccess,
    isError,
    isIdle,
    data: dashboardStatus,
  } = useQuery(['dashboardStatus', currentEnterpriseId], getDashboardStatus, {
    enabled: !!currentEnterpriseId,
  });

  const invalidateQuery = async () => {
    await queryClient.invalidateQueries('dashboardStatus');
  };

  const capitalizeFirstLetter = (str: string) => {
    return str[0].toUpperCase() + str.slice(1);
  };

  const updateDados = useMutation(async (payload: any) => {
    toast.loading('Atualizando dados');

    const { tipo, valor, motivo } = payload;

    const response = await api.main.put(
      `/v1/empresas/${currentEnterpriseId}/status-de-validacao`,
      { tipo: capitalizeFirstLetter(tipo), valor, motivo },
    );

    invalidateQuery();

    return response;
  });

  const changeStatus = (payload: any) => {
    updateDados.mutate(payload, {
      onSuccess: async res => {
        toast.dismiss();
        if (res?.status === 200 || res?.status === 201) {
          toast.success('Status atualizado com sucesso!');
          invalidateQuery();
        }
      },
      onError: async (err: any) => {
        toast.dismiss();
        const message = verifyError(err, 'Erro ao atualizar status');
        toast.error(message);
        invalidateQuery();
      },
    });
  };

  const generateIPO = useMutation(async () => {
    toast.loading('Atualizando dados');

    const response = await api.main.post(
      `/v1/empresas/${currentEnterpriseId}/ipo/gerar-documentacao`,
    );

    invalidateQuery();

    return response;
  });

  const generateIPOStatus = (payload: any) => {
    generateIPO.mutate(payload, {
      onSuccess: async res => {
        toast.dismiss();
        if (res?.status === 200 || res?.status === 201) {
          toast.success('Status atualizado com sucesso!');
          invalidateQuery();
        }
      },
      onError: async (err: any) => {
        toast.dismiss();
        const message = verifyError(err, 'Erro ao atualizar status');
        toast.error(message);
        invalidateQuery();
      },
    });
  };

  const generateListagem = useMutation(async () => {
    toast.loading('Atualizando dados');

    const response = await api.main.post(
      `/v1/empresas/${currentEnterpriseId}/ipo-listagem/gerar-listagem`,
    );

    invalidateQuery();

    return response;
  });

  return {
    isLoading,
    isError,
    isIdle,
    isSuccess,
    dashboardStatus,
    getDashboardStatus,
    updateDados,
    changeStatus,
    invalidateQuery,
    queryClient,
    generateIPO,
    generateIPOStatus,
    generateListagem,
  };
};

export default useDasboardListagem;
