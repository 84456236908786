import React from 'react';
import { TableContent, TableHeader, TableLabel } from './styles';

export const TableStatusDashboard = ({
  children,
  tableContent,
  tableLabel,
  openDescription,
}: {
  children: any;
  tableContent: any;
  tableLabel: string;
  openDescription: string;
}) => {
  return (
    <div>
      <TableHeader>
        <TableLabel>
          <h3>{tableLabel}</h3>
        </TableLabel>
        {tableContent}
      </TableHeader>
      <TableContent>
        <p>{openDescription}</p>
        <div>{children}</div>
      </TableContent>
    </div>
  );
};
