import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

export interface IAutocompleteOption {
  label: string;
  id?: any;
}
interface IAutocompleteProps {
  onChange: (e?: any) => void;
  label?: string | React.ReactNode;
  name?: string;
  value?: any;
  options?: IAutocompleteOption[];
  disabled?: boolean;
  height?: string;
  width?: string;
  defaultValue?: string;
  error?: boolean;
  errorMessage?: string;
  isLoading?: boolean;
}

export const AutoComplete: React.FC<IAutocompleteProps> = ({
  label,
  name,
  value,
  options,
  onChange,
  disabled,
  height,
  width,
  defaultValue,
  error,
  errorMessage,
  isLoading,
}) => {
  const [val, setVal] = React.useState({ label: '' });

  const handleChange = (event: any, option: any) => {
    setVal(option);
    onChange(option ? option.id ?? option.label : '');
  };

  React.useEffect(() => {
    setVal(
      options?.filter(option =>
        option.id ? option.id === value : option.label === value,
      )[0] ?? { label: '' },
    );
  }, [options, value]);

  return (
    <FormControl sx={{ width: '100%' }} error={error}>
      {label ? (
        <InputLabel shrink style={{ color: '#424242' }} id="lableID">
          {label}
        </InputLabel>
      ) : null}
      <Autocomplete
        disablePortal
        options={options ?? []}
        id={name}
        value={val}
        getOptionLabel={option => option.label}
        onChange={handleChange}
        disabled={disabled}
        style={{ height, width }}
        renderInput={params => (
          <TextField
            {...params}
            id="labelID"
            label={label}
            variant="outlined"
            InputLabelProps={{
              style: { color: disabled ? '#bdbdbd' : '#424242' },
              shrink: true,
            }}
          />
        )}
      />

      <FormHelperText>{errorMessage}</FormHelperText>
    </FormControl>
  );
};
