import React, { useState } from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  CommitteeDecisionButton,
  CommitteeDecisionContent,
  OrientationButton,
  OrientationContainer,
  OrientationContent,
} from './styles';
import { GenerateIPOModal } from '../orientationModal';
import { useEnterprise } from '../../../../hooks/enterprise';
import { useAuth } from '../../../../hooks/auth';
import { CategoryIPO } from '../../fields';

type OrientationIpoProps = {
  status: number;
  documentacaoIPO?: CategoryIPO;
};

export const OrientationIPO = ({
  status,
  documentacaoIPO,
}: OrientationIpoProps) => {
  const [oritentationModal, setOrientationModal] = useState(false);
  const { currentEnterpriseName } = useEnterprise();
  const { currentRole } = useAuth();
  const aprovacaoBEE4 = documentacaoIPO?.aprovacaoBEE4;
  const listaDeAprovacaoAdicionalBEE4 =
    documentacaoIPO?.listaDeAprovacaoAdicionalBEE4;

  const lastAprovacaoStatus = () => {
    if (!listaDeAprovacaoAdicionalBEE4?.length) return false;
    const tam = listaDeAprovacaoAdicionalBEE4?.length - 1;

    if (listaDeAprovacaoAdicionalBEE4[tam].notificacaoDaDecisaoDoComite) {
      return listaDeAprovacaoAdicionalBEE4[tam]?.avaliacaoBEE4 === 3;
    }
    if (tam === 1) {
      return false;
    }
    return listaDeAprovacaoAdicionalBEE4[tam - 1]?.avaliacaoBEE4 === 3;
  };

  const orientation = () => (
    <OrientationButton
      type="button"
      onClick={() => {
        setOrientationModal(true);
      }}
    >
      <img src="images/comment-icon.svg" alt="" />
      <p>
        Visite as orientações para entender as definições de status.
        <strong className="blue"> &rarr;</strong>
      </p>
    </OrientationButton>
  );

  const committeeStatus =
    (aprovacaoBEE4?.avaliacaoBEE4 === 3 &&
      !!aprovacaoBEE4?.notificacaoDaDecisaoDoComite &&
      !listaDeAprovacaoAdicionalBEE4?.length) ||
    lastAprovacaoStatus();

  const showCommitteeDecision =
    status === 4 &&
    currentRole !== 'BEE4' &&
    (!aprovacaoBEE4?.notificacaoDaDecisaoDoComite ||
      (aprovacaoBEE4?.avaliacaoBEE4 === 3 &&
        !!aprovacaoBEE4?.notificacaoDaDecisaoDoComite));

  if (committeeStatus) {
    return (
      <OrientationContainer>
        {showCommitteeDecision && (
          <CommitteeDecisionContent>
            {/* <h3>Decisão do Comitê</h3> */}
            <CommitteeDecisionButton>
              <InfoOutlinedIcon />
              <strong>
                Os documentos foram enviados para análise da BEE4.
              </strong>
              <span>
                Em breve você será notificado sobre a decisão e próximos passos.
              </span>
            </CommitteeDecisionButton>
          </CommitteeDecisionContent>
        )}
        <h3>Validação de documentos com exigências</h3>
        <OrientationContent>
          {currentRole === 'NOMADBOSS' && (
            <>
              <p>
                Ainda há documentos a serem entregues ou revisados. Abaixo
                listamos quais pendências são necessárias serem resolvidas para
                que seu processo de listagem possa efetivamente ser concluído.
                Caso necessário, realize as alterações ou tire dúvidas com a
                nossa equipe.
              </p>
              {orientation()}
            </>
          )}
        </OrientationContent>

        <GenerateIPOModal
          description={oritentationModal}
          closeDropdown={() => {
            setOrientationModal(false);
          }}
        />
      </OrientationContainer>
    );
  }
  return (
    <>
      <OrientationContainer>
        {showCommitteeDecision && (
          <CommitteeDecisionContent>
            {/* <h3>Decisão do Comitê</h3> */}
            <CommitteeDecisionButton>
              <InfoOutlinedIcon />
              <strong>
                Os documentos foram enviados para análise da BEE4.
              </strong>
              <span>
                Em breve você será notificado sobre a decisão e próximos passos.
              </span>
            </CommitteeDecisionButton>
          </CommitteeDecisionContent>
        )}
        <OrientationContent>
          {['NOMADBOSS'].includes(currentRole) ? (
            <>
              <section>
                <h3>Validar documentos</h3>
                <p>
                  Esta é uma lista com todos os documentos do processo de
                  listagem. Agora é o momento de observar o preenchimento dos
                  dados e validar a veracidade e coerência de todas as
                  informações registradas no sistema. Se necessário, solicite
                  revisões e tire dúvidas com nossa equipe.
                </p>
              </section>
              {orientation()}
            </>
          ) : status === 2 && currentRole === 'TECHINTERNO' ? (
            <>
              <section>
                <h3>Upload dos documentos para validação</h3>
                <p>
                  É necessário realizar o upload dos documentos em destaque
                  abaixo, para que eles sejam disponibilizados para o Emissor e
                  Consultores de Listagem Colaborador e Responsável.
                </p>
              </section>
              {orientation()}
            </>
          ) : currentRole === 'BEE4' ? (
            <p>Verifique e defina o status de cada documento.</p>
          ) : (
            <section>
              <h3>Validar documentos</h3>
            </section>
          )}
        </OrientationContent>
        <GenerateIPOModal
          description={oritentationModal}
          closeDropdown={() => {
            setOrientationModal(false);
          }}
        />
      </OrientationContainer>
    </>
  );
};
