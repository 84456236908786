import styled from 'styled-components';

export const OrientationContainer = styled.div`
  margin-top: 2rem;
  h3 {
    color: var(--Chumbo-1, #454b62);
    font-size: 18px;
    font-weight: 600;
  }
`;

export const OrientationContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
  p {
    max-width: 676px;
  }

  section {
    flex-direction: column;

    h3 {
      margin-bottom: 12px;
    }
  }
`;

export const OrientationButton = styled.button`
  border-radius: 4px;
  border: 1px solid var(--cinza-1, #d0d4e0);
  background: #f5f5f5;
  display: flex;
  padding: 0.5rem 1rem;
  align-items: center;
  max-width: 278px;

  img {
    margin-right: 1rem;
  }

  p {
    color: var(--chumbo-1, #454b62);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
  }

  .blue {
    color: var(--azul-2, #2dbcf7);
    font-weight: 700;
  }
`;

export const CommitteeDecisionContent = styled.div`
  margin-bottom: 2rem;
`;

export const CommitteeDecisionButton = styled.button`
  display: flex;
  padding: 0.5rem 1rem;
  align-items: center;
  gap: 1rem;
  border-radius: 4px;
  border: 1px solid var(--Cinza-1, #d0d4e0);
  background: #f5f5f5;
  color: var(--Chumbo-1, #454b62);
  border: px solid var(--Cinza-1, #d0d4e0)
  font-size: 12px;
  font-weight: 700;
  margin: 1.5rem 0 2rem 0;
  
`;
