import React from 'react';
import { toast } from 'react-toastify';
import { ViewDocModal } from '../modals/viewDocModal';
import { RevisaoModal } from '../modals/revisaoModal';
import { PrazoModal } from '../modals/prazoModal';
import { FinishDocModal } from '../modals/finishDocModal';
import { ModalState, StatusChangePayload, UploadDocPayload } from '../fields';
import {
  AddReviewIpoPayload,
  CategoryDocIPO,
} from '../../../pages/documentacaoIPO/fields';
import { DeleteCategoryModal } from '../modals/deleteCategoryModal';
import { ShouldRenderChildren } from '../../shouldRenderChildren';
import { VerifyDocModal } from '../modals/verifyDocModal';
import { UploadModal } from '../../uploadModal';
import { toBase64 } from '../../../services/toBase64';
import { useAuth } from '../../../hooks/auth';
import { PrazoNegadoModal } from '../modals/prazoNegadoModal';
import { validarRoles } from '../../../utils/roles';
import { Roles } from '../../../router/roles.routes';

interface IModalsContainerProps {
  openModal: ModalState;
  handleCloseModal: (e?: any) => void;
  viewCategory?: CategoryDocIPO;
  onStatusChange: (e?: StatusChangePayload) => void;
  statusObject?: StatusChangePayload;
  deleteCategaria: (e?: any) => void;
  editPrazo: (e?: StatusChangePayload) => void;
  uploadDoc: (e?: UploadDocPayload) => void;
  addReview: (e: AddReviewIpoPayload) => void;
  status: number;
}

export const ModalsContainer = ({
  openModal,
  handleCloseModal,
  viewCategory,
  onStatusChange,
  statusObject,
  deleteCategaria,
  editPrazo,
  uploadDoc,
  addReview,
  status,
}: IModalsContainerProps) => {
  const { currentRole, permissions } = useAuth();
  const handleSave = async (files: File[]) => {
    if (!files) {
      toast.error('Coloque um documento antes de salvar');
    } else {
      const base64Content = await toBase64(files[0]);
      const nome = files[0].name;

      if (base64Content) {
        uploadDoc({
          nome,
          base64Content,
        });
      }
    }
  };

  return (
    <>
      <ShouldRenderChildren shouldRender={openModal.viewDocModal}>
        <ViewDocModal
          description={openModal.viewDocModal}
          closeDropdown={() => {
            handleCloseModal();
          }}
          category={viewCategory}
        />
      </ShouldRenderChildren>

      <ShouldRenderChildren
        shouldRender={
          openModal.revisionModal &&
          validarRoles(permissions, [
            Roles.VisualizarMensagemRevisao,
            Roles.SolicitarRevisao,
          ])
        }
      >
        <RevisaoModal
          description={openModal.revisionModal}
          closeDropdown={() => {
            handleCloseModal();
          }}
          onStatusChange={onStatusChange}
          addReview={addReview}
          statusObject={statusObject}
          status={status}
        />
      </ShouldRenderChildren>

      <ShouldRenderChildren
        shouldRender={
          openModal.prazoModal &&
          validarRoles(permissions, [Roles.SolicitarPrazoExcepcional])
        }
      >
        <PrazoModal
          description={openModal.prazoModal}
          closeDropdown={() => {
            handleCloseModal();
          }}
          onStatusChange={onStatusChange}
          statusObject={statusObject}
          editStatus={editPrazo}
          disabled={status !== 3 || currentRole !== 'NOMADBOSS'}
        />
      </ShouldRenderChildren>

      <ShouldRenderChildren shouldRender={openModal.finishDocModal}>
        <FinishDocModal
          description={openModal.finishDocModal}
          closeDropdown={() => {
            handleCloseModal();
          }}
          onStatusChange={onStatusChange}
          statusObject={statusObject}
        />
      </ShouldRenderChildren>

      <ShouldRenderChildren
        shouldRender={
          openModal.deleteCategoryModal &&
          validarRoles(permissions, [Roles.ExcluirCategoria])
        }
      >
        <DeleteCategoryModal
          description={openModal.deleteCategoryModal}
          closeDropdown={() => {
            handleCloseModal();
          }}
          onDelete={deleteCategaria}
        />
      </ShouldRenderChildren>
      <ShouldRenderChildren shouldRender={openModal.verifyDocModal}>
        <VerifyDocModal
          description={openModal.verifyDocModal}
          closeDropdown={() => {
            handleCloseModal();
          }}
          onStatusChange={onStatusChange}
          statusObject={statusObject}
        />
      </ShouldRenderChildren>
      <ShouldRenderChildren
        shouldRender={
          openModal.uploadDocModal &&
          validarRoles(permissions, [
            Roles.FazerUploadDocumento,
            Roles.FazerUploadDocAut,
          ])
        }
      >
        <UploadModal
          openModal={openModal.uploadDocModal}
          dialogTitle="Importar documentos"
          onSave={files => {
            handleSave(files);
            handleCloseModal();
          }}
          onClose={() => {
            handleCloseModal();
          }}
        />
      </ShouldRenderChildren>
      <ShouldRenderChildren
        shouldRender={
          openModal.prazoNegadoModal &&
          validarRoles(permissions, [Roles.AceitarOuNegarPrazo])
        }
      >
        <PrazoNegadoModal
          description={openModal.prazoNegadoModal}
          closeDropdown={() => {
            handleCloseModal();
          }}
          statusObject={statusObject}
          onStatusChange={onStatusChange}
          addPrazoNegado={addReview}
          canAddNewComment={status === 4 && currentRole === 'BEE4'}
        />
      </ShouldRenderChildren>
    </>
  );
};
