import React from 'react';
import { EmptyContainer, EmptyContent, TagContent } from './style';

export const EmptyDocState = () => {
  return (
    <EmptyContainer>
      <EmptyContent>
        <h2>Gerando documentos</h2>
        <p>
          A documentação será gerada dentro de 3 dias úteis. Após esse período,
          seu processo entrará no período de revisão e validação.
        </p>
      </EmptyContent>
    </EmptyContainer>
  );
};
