import React, { useEffect, useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import Accordion from '../../../../components/accordion';
import { Button } from '../../../../components/button';
import {
  Content,
  Title,
  Buttons,
  ContentColumns,
  FirstColumn,
  IndexColumns,
  SecondColumn,
  TableLine,
  TableRow,
  ThirdColumn,
  FirstSection,
} from './styles';
import { CreateReportDateModal } from './components/createReportModal';
import { EditReportDateModal } from './components/editReportModal';
import useReportDate, {
  FormDataReport,
  ResportList,
} from './hooks/useReportDates';
import { dateToTimestamp, getDate } from '../../../../utils/dateHandling';
import { Section } from '../../styles';
import { Container } from '../../../../components/container';
import { OutletPermify } from '../../../../components/outletPermify';
import { Roles } from '../../../../router/roles.routes';

interface Empresa {
  nome: string;
  id: any;
  data: string;
}
export const ReportDate = () => {
  const [createModal, setCreateModal] = useState<boolean>(false);
  const [editModal, setEditModal] = useState<boolean>(false);
  const [currentAccordionExpanded, setCurrentAccordionExpanded] =
    useState<boolean>(false);

  const setOpenAccordion = () => {
    // @ts-ignore
    setCurrentAccordionExpanded(!currentAccordionExpanded);
  };

  const [editCompany, setEditCompany] = useState<ResportList | undefined>(
    undefined,
  );
  const { relatorioDatas, submitDados } = useReportDate();

  const handleCreateDate = (e: FormDataReport) => {
    submitDados({
      ...e,
      empresaListadaNaBee4: dateToTimestamp(e?.empresaListadaNaBee4),
    });
  };

  function reportDateContentList() {
    return relatorioDatas?.map((empresa, index) => {
      return (
        <React.Fragment key={empresa.id}>
          {empresa && index < relatorioDatas.length ? <TableLine /> : null}
          <TableRow>
            <FirstColumn>
              <span>{empresa.nomeFantasia}</span>
            </FirstColumn>
            <SecondColumn>
              <span>{getDate(empresa?.empresaListadaNaBee4)}</span>
            </SecondColumn>
            <ThirdColumn>
              <Buttons>
                <OutletPermify role={[Roles.AtualizarDataEmpListada]}>
                  <button
                    type="button"
                    onClick={(event: any) => {
                      event.stopPropagation();
                      setEditCompany(empresa);
                      setEditModal(true);
                    }}
                    className="deleteEditButton"
                    aria-label="Editar data"
                  >
                    <EditIcon htmlColor="#212E51" />
                  </button>
                </OutletPermify>
              </Buttons>
            </ThirdColumn>
          </TableRow>
        </React.Fragment>
      );
    });
  }

  return (
    <Section>
      <OutletPermify role={[Roles.VisualizarEmpresasListada]}>
        <Container title="Pós-listagem" titleMarginBottom="7px">
          <FirstSection>
            <span>
              Indique a data que a empresa emissora entrou nesta fase. Essa será
              a data base usada para calcular o prazo de entrega das obrigações
              do pós listagem (ITRs, Relatório Anual Auditado e Atualização
              Cadastro).
            </span>
          </FirstSection>
          <Section>
            <Accordion
              id="accordion-docs"
              summaryClasses="accordion__header"
              variantstyle="nomad"
              summary={
                <Content>
                  <Title>Datas</Title>
                  <img
                    src="images/arrow-down.svg"
                    className={
                      currentAccordionExpanded
                        ? 'accordion__icon'
                        : 'accordion__icon rotate'
                    }
                    alt=""
                  />
                  <Buttons>
                    <Button
                      type="button"
                      height="32px"
                      width="108px"
                      fontSize="10px"
                      onClick={(event: any) => {
                        event.stopPropagation();
                        setCreateModal(true);
                      }}
                      variant="outlined"
                    >
                      <span className="plus">+</span>
                      Empresa
                    </Button>
                  </Buttons>
                </Content>
              }
              handleExpanded={setOpenAccordion}
              value={currentAccordionExpanded}
            >
              <IndexColumns>
                <FirstColumn>
                  <span>Nome da empresa</span>
                </FirstColumn>
                <SecondColumn>
                  <span>Data(s)</span>
                </SecondColumn>
                <ThirdColumn />
              </IndexColumns>
              <ContentColumns>{reportDateContentList()}</ContentColumns>
            </Accordion>
            <CreateReportDateModal
              description={createModal}
              closeDropdown={() => {
                setCreateModal(false);
              }}
              createDate={e => {
                handleCreateDate(e);
              }}
            />
            <EditReportDateModal
              description={editModal}
              closeDropdown={() => {
                setEditModal(false);
              }}
              empresa={editCompany}
              onEdit={e => {
                handleCreateDate({ ...e, empresa: editCompany?.id ?? '' });
              }}
            />
          </Section>
        </Container>
      </OutletPermify>
    </Section>
  );
};
