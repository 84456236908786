import styled from 'styled-components';

export const Container = styled.div`
  height: 40px;
  background-color: #a4a7b433;
  border-radius: 4px;
  padding: 0 15px 0 16px;
  align-items: center;
  display: flex;
  margin-top: 10px;
`;

export const FileIcon = styled.img`
  height: 22px;
  margin-right: 12px;
`;

export const FileName = styled.span`
  color: #101219;
  font-size: 12px;
  font-family: Inter;
`;

export const Download = styled.div`
  margin-left: auto;
`;
