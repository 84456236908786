import styled from 'styled-components';

export const Content = styled.div`
  width: 100%;
  padding-bottom: 40px;

  h2 {
    color: #171725;
    font-size: 24px;
  }

  .subtitle {
    font-size: 16px;
    margin-top: 24px;
    font-weight: 400;
  }

  .benefits {
    color: #171725;
    font-weight: 700;
    font-size: 24px;
  }
  .benefitsDescription {
    display: flex;
    margin-top: 8px;
    font-size: 16px;
  }
`;

export const StatusTable = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin: 32px 0 4vw 0;

  @media (max-width: 1440px) {
    width: 1149px;
    font-size: 14px;
    margin-bottom: 57.6px;
  }
`;

export const Explanation = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  margin-top: 2rem;

  @media (max-width: 1440px) {
    width: 1149px;
  }
`;

export const ExplanationBoxes = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: relative;
`;

export const ExplanationBox = styled.div`
  width: 32%;
  height: 21.5vw;
  margin-bottom: 1.25vw;
  background-color: #fff;
  border: 1px solid #161a32;
  border-radius: 16px;
  padding: 1.82vw 1.2vw 1.25vw 1.2vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 1440px) {
    height: 310px;
    margin-bottom: 18px;
    padding: 26px 17px 18px 17px;
  }
`;

export const ArrowsContainer = styled.div`
  position absolute;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
`;

export const ExplanationBigBox = styled.div`
  width: 100%;
  border: 1px solid #161a32;
  border-radius: 16px;
  display: flex;
  background-color: #fff;
`;

export const FaseContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.1vw;
  padding: 0 1.88vw;

  p {
    font-size: 0.83vw;
  }

  @media (max-width: 1440px) {
    padding: 0 27px;
    gap: 30px;

    p {
      font-size: 12px;
    }
  }
`;

export const Fase = styled.div`
  display: flex;

  img {
    width: 3.3vw;
    height: 3.3vw;
    margin-right: 0.83vw;
  }

  div {
    display: flex;
    flex-direction: column;
    gap: 0.5vw;

    h4 {
      font-size: 1.25vw;
    }

    p {
      font-size: 1vw;
    }
  }

  @media (max-width: 1440px) {
    img {
      width: 47.5px;
      height: 47.5px;
      margin-right: 12px;
    }

    div {
      gap: 7.2px;

      h4 {
        font-size: 18px;
      }

      p {
        font-size: 14.4px;
      }
    }
  }
`;

export const FaseDescription = styled.div`
  margin-top: 2.1vw;
  padding: 0 1.72vw;
  background: #eeeff1 0% 0% no-repeat padding-box;
  border-radius: 16px;
  font-size: 0.83vw;
  height: 8.44vw;
  display: flex;
  align-items: center;

  @media (max-width: 1440px) {
    margin-top: 30px;
    font-size: 12px;
    height: 122px;
    padding: 0 25px;
  }
`;

export const Reminder = styled.div`
  background: #eeeff1;
  margin-top: 2.34vw;
  width: 85%;
  height: 4.64vw;
  border-radius: 0 16px 16px 0;
  font-size: 1.25vw;
  display: flex;
  align-items: center;
  justify-content: end;
  padding: 0 3.54vw;
  img {
    width: 1.85vw;
    height: 2.3vw;
    margin-right: 0.7vw;
    position: relative;
    top: -2px;
  }

  @media (max-width: 1440px) {
    margin-top: 33.7px;
    height: 66.8px;
    font-size: 18px;
    padding: 0 51px;
    img {
      width: 26.6px;
      height: 33.1px;
      margin-right: 10px;
    }
  }
`;

export const BigBoxRow1 = styled.div`
  display: flex;
  flex-direction: column;
  width: 32%;
  justify-content: space-between;
`;

export const BigBoxRow2 = styled.div`
  display: flex;
  flex-direction: column;
  padding: 3.23vw 2vw 3.23vw 0;
  width: 68%;
  font-size: 0.94vw;

  ol {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;
    height: 100%;
  }

  @media (max-width: 1440px) {
    padding: 46.5px 29px 46.5px 0;
    font-size: 13.5px;
  }
`;

export const GenerateIpoContent = styled.div`
  display: flex;
  gap: 1rem;
  margin-left: auto;
  padding: 0 16px;
  align-items: center;

  p {
    color: #454b62;
    font-size: 12px;
    width: 260px;
  }
`;

export const GenerateListagemContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  text-align: start;
  align-items: center;

  padding: 0 16px;

  p {
    color: #454b62;
    font-size: 16px;
    width: 640px;
  }
`;

export const GenerateIpoButtonText = styled.text`
  font-size: 12px;
  padding: 0.2rem 0;
  font-style: normal;
  font-weight: 600;
`;

export const AprovalContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 3rem;
`;
