export const texto = (
  currentEnterpriseName: any,
  status: 1 | 2 | 3 | 4 | undefined,
) => {
  if (status === 1) {
    return [
      'Aprovada',
      `Após deliberação do Comitê, foi decidido pela aprovação da empresa ${currentEnterpriseName} no processo para a listagem na BEE4.`,
    ];
  }
  if (status === 2) {
    return [
      'Reprovada',
      `Após deliberação do Comitê, foi decidido pela reprovação da empresa ${currentEnterpriseName} no processo para a listagem na BEE4.`,
    ];
  }
  if (status === 3) {
    return [
      'Exigências',
      `Após deliberação do Comitê, foi decidido que a empresa ${currentEnterpriseName} precisa cumprir as exigências para seguir com o processo de listagem na BEE4.`,
    ];
  }
  if (status === 4) {
    return [
      'Aprovada com condicionantes',
      `Após deliberação do Comitê, foi decidido pela aprovação da empresa ${currentEnterpriseName} no processo para a listagem na BEE4, porém, com alguns condicionantes que ainda precisam ser solucionados.`,
    ];
  }

  return '';
};
