import React from 'react';
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { Button } from '../../button';
import { CategoryDocIPO } from '../../../pages/documentacaoIPO/fields';
import { Tooltip } from '../../tooltip';
import { ModalIPOTypes, StatusChangePayload } from '../fields';
import { useAuth } from '../../../hooks/auth';
import { OutletPermify } from '../../outletPermify';
import { Roles } from '../../../router/roles.routes';

interface IButtonsActionsProps {
  category: CategoryDocIPO;
  handleOpenModal: (e: ModalIPOTypes) => void;
  setViewCategory: (e: CategoryDocIPO) => void;
  setCategoryId: (e: string) => void;
  refreshDocsCategoria: (e: string) => void;
  setStatusObject: (e: StatusChangePayload) => void;
  sendNewDocsToBEE4: (e: StatusChangePayload) => void;
  status: number;
  analyzedByCommittee: boolean;
  type?: number;
}

export const ButtonsActions = ({
  category,
  handleOpenModal,
  setViewCategory,
  refreshDocsCategoria,
  setCategoryId,
  setStatusObject,
  sendNewDocsToBEE4,
  status,
  analyzedByCommittee,
  type,
}: IButtonsActionsProps) => {
  const { currentRole } = useAuth();

  const { statusDaCategoria } = category;

  const buttonsStyle = {
    padding: 0,
    width: 'fit-content',
    minWidth: 'fit-content',
  };

  const enableCommentNOMAD =
    currentRole !== 'BEE4' &&
    status >= 3 &&
    (([4, 5, 6].includes(statusDaCategoria ?? 0) &&
      !!category.solicitacaoPrazoExcepcional) ||
      ([2, 8].includes(statusDaCategoria ?? 0) && !!category.comentarios));

  const enableCommentBEE4 =
    currentRole !== 'NOMADBOSS' &&
    ((status === 2 &&
      !!category.comentarios &&
      category.comentarios.length > 0) ||
      (status >= 3 && statusDaCategoria === 8 && !!category.comentarios) ||
      ([4, 5, 6].includes(statusDaCategoria ?? 0) &&
        !!category.solicitacaoPrazoExcepcional));

  const enableCommentListagem =
    status >= 3 &&
    type === 2 &&
    !!category.comentarios &&
    [2, 8].includes(category?.statusDaCategoria ?? 0);

  const disableComment =
    !(enableCommentNOMAD || enableCommentBEE4 || enableCommentListagem) ||
    status === 5;

  const disableDeleteAdmin =
    currentRole !== 'TECHINTERNO' ||
    !(category?.documentos && category?.documentos?.length > 0) ||
    status !== 2;

  const disableViewCat = category?.documentos?.length === 0;

  const disableRefreshCat = !category?.permiteRefresh;

  // const disableSendToBee4 = !category?.habilitaEnvioRevisaoParaBEE4;

  const adminCanUpload = category?.uploadPeloAdmin && status === 2;

  const onlyViewCat =
    analyzedByCommittee &&
    status === 3 &&
    [5, 7].includes(category?.statusDaCategoria ?? 0);

  const handleRefreshButton = (category: CategoryDocIPO) => {
    refreshDocsCategoria(category?.documentoCategoriaId);
  };

  const handleCommentsButton = (category: CategoryDocIPO) => {
    if ([4, 5].includes(category?.statusDaCategoria ?? 0)) {
      setStatusObject({
        categoryId: category?.documentoCategoriaId,
        motivo: category?.solicitacaoPrazoExcepcional,
        statusDaCategoria: category?.statusDaCategoria,
      });
      handleOpenModal('prazoModal');
    } else if (
      [2, 8].includes(category?.statusDaCategoria ?? 0) ||
      status === 2
    ) {
      setStatusObject({
        categoryId: category?.documentoCategoriaId,
        comments: category?.comentarios,
        statusDaCategoria: category?.statusDaCategoria,
      });
      handleOpenModal('revisionModal');
    } else if (category?.statusDaCategoria === 6) {
      setStatusObject({
        categoryId: category?.documentoCategoriaId,
        comments: category?.comentarios,
        statusDaCategoria: category?.statusDaCategoria,
      });
      handleOpenModal('prazoNegadoModal');
    }
  };

  const handleViewDocumentsButton = (category: CategoryDocIPO) => {
    setViewCategory(category ?? {});

    handleOpenModal('viewDocModal');
  };

  if (currentRole === 'TECHINTERNO' && status === 2) {
    return (
      <>
        <OutletPermify role={[Roles.VisualizarDocumento]}>
          <Button
            variant="text"
            width="fit-content"
            style={buttonsStyle}
            onClick={() => {
              handleViewDocumentsButton(category);
            }}
            disabled={disableViewCat}
          >
            <RemoveRedEyeOutlinedIcon
              sx={{
                color: !disableViewCat ? '#0852DD' : '#DDDDDD',
              }}
            />
          </Button>
        </OutletPermify>
        <OutletPermify role={[Roles.VisualizarMensagemRevisao]}>
          <Button
            variant="text"
            width="fit-content"
            style={buttonsStyle}
            onClick={() => {
              handleCommentsButton(category);
            }}
            disabled={disableComment}
          >
            <PendingOutlinedIcon
              sx={{
                color: !disableComment ? '#0852DD' : '#DDDDDD',
              }}
            />
          </Button>
        </OutletPermify>
        {adminCanUpload && (
          <>
            <OutletPermify
              role={[Roles.FazerUploadDocumento, Roles.FazerUploadDocAut]}
            >
              <Button
                variant="text"
                width="fit-content"
                style={buttonsStyle}
                onClick={() => {
                  setCategoryId(category?.documentoCategoriaId);
                  handleOpenModal('uploadDocModal');
                }}
                disabled={!adminCanUpload}
              >
                <FileUploadOutlinedIcon
                  sx={{
                    color: '#0852DD',
                  }}
                />
              </Button>
            </OutletPermify>
            <OutletPermify role={[Roles.ExcluirCategoria]}>
              <Button
                variant="text"
                width="fit-content"
                style={buttonsStyle}
                onClick={() => {
                  setCategoryId(category?.documentoCategoriaId);
                  handleOpenModal('deleteCategoryModal');
                }}
                disabled={disableDeleteAdmin || category.excluirHidden}
              >
                <DeleteOutlineOutlinedIcon
                  sx={{
                    color:
                      disableDeleteAdmin || category.excluirHidden
                        ? '#DDDDDD'
                        : '#0852DD',
                  }}
                />
              </Button>
            </OutletPermify>
          </>
        )}
      </>
    );
  }
  return (
    <>
      <OutletPermify role={[Roles.VisualizarDocumento]}>
        <Button
          variant="text"
          width="fit-content"
          style={buttonsStyle}
          onClick={() => {
            handleViewDocumentsButton(category);
          }}
          disabled={disableViewCat}
        >
          <RemoveRedEyeOutlinedIcon
            sx={{
              color: !disableViewCat ? '#0852DD' : '#DDDDDD',
            }}
          />
        </Button>
      </OutletPermify>
      {!onlyViewCat && (
        <>
          <OutletPermify role={[Roles.VisualizarMensagemRevisao]}>
            <Button
              variant="text"
              width="fit-content"
              style={buttonsStyle}
              onClick={() => {
                handleCommentsButton(category);
              }}
              disabled={disableComment}
            >
              <PendingOutlinedIcon
                sx={{
                  color: !disableComment ? '#0852DD' : '#DDDDDD',
                }}
              />
            </Button>
          </OutletPermify>
          <OutletPermify role={[Roles.AtualizarDocumento]}>
            <Button
              variant="text"
              style={buttonsStyle}
              disabled={disableRefreshCat}
              onClick={() => {
                handleRefreshButton(category);
              }}
            >
              <Tooltip
                disabled={disableRefreshCat}
                title="Clique para o sistema gerar o documento novamente."
                color="secondary"
              >
                <RefreshOutlinedIcon
                  sx={{
                    color: !disableRefreshCat ? '#0852DD' : '#DDDDDD',
                  }}
                />
              </Tooltip>
            </Button>
          </OutletPermify>
        </>
      )}
      {/*! disableSendToBee4 && (
        <Button
          variant="text"
          style={buttonsStyle}
          disabled={disableSendToBee4}
          onClick={() => {
            sendNewDocsIpoToBEE4({
              documentoCategoriaId: category?.documentoCategoriaId,
            });
          }}
        >
          <SendOutlinedIcon
            sx={{
              color: '#0852DD',
            }}
          />
        </Button>
          ) */}
    </>
  );
};
