import React from 'react';
import * as Sentry from '@sentry/react';

export function ErrorBoundary({ children }: { children: React.ReactNode }) {
  return (
    <Sentry.ErrorBoundary
      fallback={() => {
        return (
          <>
            <h1>Erro interno 🙁</h1>
            <p>Nosso time foi avisado e irá corrigir o quanto antes.</p>
            <button
              onClick={() =>
                window.open(process.env.REACT_APP_URL_WEB_TECH, '_self')
              }
              type="button"
            >
              Voltar
            </button>
          </>
        );
      }}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
}
