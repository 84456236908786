import styled from 'styled-components';

interface IListItemProps {
  finished: boolean;
}

export const StatusCompleteType = styled.div`
  border-radius: 50%;
  height: 40px;
  width: 40px;
  min-width: 40px;
  background-color: #0852dd;
  justify-content: center;
  display: inline-flex;
  align-items: center;

  p {
    font-weight: 500;
    color: white;
  }
`;

export const LinearProgress = styled.hr<IListItemProps>`
  border: none;
  border-top: ${({ finished }) =>
    (finished && '1px solid #0852DD') || '1px dashed #0852DD'};
`;
