import React from 'react';
import { Container, DeleteButton } from './styles';
import { OutletPermify } from '../../../../components/outletPermify';
import { Roles } from '../../../../router/roles.routes';

interface ImageProps {
  id: string;
  nome: string;
  url: string;
  onDelete: () => void;
}

export const ImagePreview = ({ id, nome, url, onDelete }: ImageProps) => {
  return (
    <Container>
      <img src={url} alt="" />
      <OutletPermify role={[Roles.ExcluirFatoRelevante]}>
        <DeleteButton onClick={onDelete}>
          <img src="images/lixeira.svg" alt="" />
        </DeleteButton>
      </OutletPermify>
    </Container>
  );
};
