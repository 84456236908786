import React from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { AxiosResponse } from 'axios';
import { api } from '../../../services/api';
import { verifyError } from '../../../utils/verifyError';
import { CategoryIPO } from '../../documentacaoIPO/fields';

interface IUseIpoProps {
  currentEnterpriseId?: string;
}

export const useHistoricoDecisaoComite = ({
  currentEnterpriseId,
}: IUseIpoProps) => {
  const queryClient = useQueryClient();

  const getHistoricoDecisaoComite = async () => {
    // const getToast = toast.loading('Carregando status');

    try {
      const { data }: AxiosResponse<CategoryIPO> = await api.main.get(
        `/v1/empresas/${currentEnterpriseId}/ipo/historico-comite`,
      );

      // toast.update(getToast, {
      //   render: 'Hisórico da documentação IPO carregada com sucesso!',
      //   type: 'success',
      //   isLoading: false,
      //   autoClose: 1,
      // });

      return data;
    } catch (error) {
      const message = verifyError(
        error,
        'Erro ao carregar o historico da documentação IPO',
      );
      // toast.update(getToast, {
      //   render: message,
      //   type: 'error',
      //   isLoading: false,
      //   autoClose: 5000,
      // });
    }
  };

  const {
    isLoading,
    isSuccess,
    isError,
    isIdle,
    data: historicoDecisaoComite,
  } = useQuery(
    ['historicoDecisaoComite', currentEnterpriseId],
    getHistoricoDecisaoComite,
    {
      enabled: !!currentEnterpriseId,
    },
  );

  const invalidateQuery = async () => {
    await queryClient.invalidateQueries('historicoDecisaoComite');
  };

  return {
    isLoading,
    isError,
    isIdle,
    isSuccess,
    historicoDecisaoComite,
    getHistoricoDecisaoComite,
    invalidateQuery,
  };
};

export default useHistoricoDecisaoComite;
