import React, { useState } from 'react';
import { toast } from 'react-toastify';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Select } from '../../../../components/select';
import {
  ButtonContaner,
  ButtonText,
  CommitteeDecisionContainer,
  CommitteeDecisionDescriptionContainer,
  LabelCancelContainer,
  RadioContent,
  SelectDecisionLabel,
  StatusBall,
} from './styles';
import { UploadModal } from '../../../../components/uploadModal';
import { toBase64 } from '../../../../services/toBase64';
import { ShouldRenderChildren } from '../../../../components/shouldRenderChildren';
import {
  AprovacaoAdicionalBEE4,
  CategoryIPO,
  IpoStatusChangePayload,
  radioDecisionOptions,
} from '../../fields';
import { DocumentListIpo } from '../documentListIpo';
import { Button } from '../../../../components/button';
import { Radio } from '../../../../components/radio';
import { validarRoles } from '../../../../utils/roles';
import { Roles } from '../../../../router/roles.routes';
import { useAuth } from '../../../../hooks/auth';
import { OutletPermify } from '../../../../components/outletPermify';

type CommitteeDecisionProps = {
  documentacaoIPO?: CategoryIPO;
  setIpoStatus: (e: IpoStatusChangePayload) => void;
  deleteStatusDocIpo: (e: string) => void;
  uplaodNewDocsStatusIpo: (e: IpoStatusChangePayload) => void;
  addAdicionalDecisionIpo: (e: IpoStatusChangePayload) => void;
  uplaodAdicionalDocsStatusIpo: (e: IpoStatusChangePayload) => void;
  deleteAdicionalDocIpo: (e: string) => void;
  editDecisionTypeIpo: (e: { tipoDeDecisao: 1 | 2 }) => void;
  isDisable: boolean;
};

export const CommitteeDecision = ({
  documentacaoIPO,
  setIpoStatus,
  deleteStatusDocIpo,
  uplaodNewDocsStatusIpo,
  addAdicionalDecisionIpo,
  uplaodAdicionalDocsStatusIpo,
  deleteAdicionalDocIpo,
  editDecisionTypeIpo,
  isDisable,
}: CommitteeDecisionProps) => {
  const { permissions } = useAuth();

  const [status, setStatus] = useState<1 | 2 | 3 | 4 | undefined | null>();
  const [uploadNewDoc, setUploadNewDoc] = useState(false);

  const [addNewDecision, setNewDecision] = useState(false);
  const [radioError, setRadioError] = useState('');

  const aprovacaoBEE4 = documentacaoIPO?.aprovacaoBEE4;
  const listaDeAprovacaoAdicionalBEE4 =
    documentacaoIPO?.listaDeAprovacaoAdicionalBEE4;
  const statusCommittee = aprovacaoBEE4?.avaliacaoBEE4;
  const adicionalDecision = !!aprovacaoBEE4?.notificacaoDaDecisaoDoComite;
  const lastAprovacao = listaDeAprovacaoAdicionalBEE4?.length
    ? listaDeAprovacaoAdicionalBEE4[listaDeAprovacaoAdicionalBEE4?.length - 1]
    : undefined;

  const disableFirstDecision = isDisable || adicionalDecision;

  const addNewDecisionButton =
    validarRoles(permissions, [
      Roles.AtualizarStsDecisaoComite,
      Roles.CriarStatusDecAdicComite,
    ]) &&
    !addNewDecision &&
    documentacaoIPO?.podeAdicionarNovoComite &&
    documentacaoIPO.statusDocumentacaoIpo === 4;

  const [radioValue, setRadioValue] = useState<1 | 2 | undefined>(
    lastAprovacao && !lastAprovacao?.notificacaoDaDecisaoDoComite
      ? lastAprovacao.tipoDeDecisao
      : undefined,
  );

  const handleCloseModal = () => {
    setStatus(undefined);
    setUploadNewDoc(false);
  };

  const handleSave = async (files: File[]) => {
    if (!files) {
      toast.error('Coloque um documento antes de salvar');
    } else {
      const base64Content = await toBase64(files[0]);
      const nome = files[0].name;

      if (base64Content) {
        // criação da primeira decisao do comitê
        if (!uploadNewDoc && !adicionalDecision) {
          setIpoStatus({
            nome,
            base64Content: base64Content as string,
            avaliacaoBEE4: status as any,
          });
          // upload dos documentos da primeira decisao do comitê
        } else if (uploadNewDoc && !adicionalDecision) {
          uplaodNewDocsStatusIpo({
            nome,
            base64Content: base64Content as string,
          });
        } else if (!uploadNewDoc && adicionalDecision) {
          // criação de uma nova decisao do comitê
          if (radioValue) {
            addAdicionalDecisionIpo({
              nome,
              base64Content: base64Content as string,
              avaliacaoBEE4: status as any,
              tipoDeDecisao: radioValue,
            });
          } else {
            setRadioError('Campo obrigatóro');
          }
        } else {
          uplaodAdicionalDocsStatusIpo({
            nome,
            base64Content: base64Content as string,
          });
        }

        setStatus(undefined);
        setNewDecision(false);
        setUploadNewDoc(false);
      }
    }
  };

  const decisionOptions = [
    {
      label: (
        <SelectDecisionLabel>
          <StatusBall status={1} />
          Aprovada
        </SelectDecisionLabel>
      ),
      value: 1,
    },
    {
      label: (
        <SelectDecisionLabel>
          <StatusBall status={2} />
          Reprovada
        </SelectDecisionLabel>
      ),
      value: 2,
    },
    {
      label: (
        <SelectDecisionLabel>
          <StatusBall status={4} />
          Exigências
        </SelectDecisionLabel>
      ),
      value: 3,
    },
    {
      label: (
        <SelectDecisionLabel>
          <StatusBall status={4} />
          Aprovada com condicionantes
        </SelectDecisionLabel>
      ),
      value: 4,
    },
  ];

  return (
    <>
      <CommitteeDecisionContainer>
        <h3>Decisão do Comitê</h3>
        <CommitteeDecisionDescriptionContainer>
          <p>
            Indique a decisão final do Comitê de Aprovação da Empresa Emissora.
          </p>
          <Select
            onChange={(v: 1 | 2 | 3 | 4) => {
              setStatus(v);
            }}
            value={statusCommittee}
            disabled={disableFirstDecision}
            width="230px"
            height="40px"
            options={decisionOptions}
          />
        </CommitteeDecisionDescriptionContainer>
        {aprovacaoBEE4?.documentos && (
          <OutletPermify role={[Roles.VisualizarListaDocGerados]}>
            <DocumentListIpo
              docList={aprovacaoBEE4?.documentos}
              openUploadModal={() => {
                setUploadNewDoc(true);
              }}
              deleteStatusDocIpo={deleteStatusDocIpo}
              isDisable={disableFirstDecision}
            />
          </OutletPermify>
        )}
      </CommitteeDecisionContainer>

      {listaDeAprovacaoAdicionalBEE4?.map(aprov => (
        <CommitteeDecisionContainer key={aprov.aprovacaoAdicionalIpoId}>
          <h3>Decisão Adicional</h3>
          <RadioContent>
            <h4>Tipo de decisão:</h4>
            <Radio
              value={aprov?.tipoDeDecisao}
              row
              onChange={e => {
                const v = parseInt(e.target.value) as 1 | 2;
                editDecisionTypeIpo({ tipoDeDecisao: v });
              }}
              options={radioDecisionOptions}
              disabled={
                !!aprov?.notificacaoDaDecisaoDoComite ||
                !validarRoles(permissions, [Roles.AtualizarTpDecAdicComite])
              }
            />
          </RadioContent>
          <CommitteeDecisionDescriptionContainer>
            <p>Indique a decisão da BEE4 (Comitê ou validação interna).</p>
            <Select
              onChange={() => {}}
              value={aprov.avaliacaoBEE4}
              disabled={
                !!aprov?.notificacaoDaDecisaoDoComite ||
                !validarRoles(permissions, [Roles.AtualizarStsDecAdicComite])
              }
              width="230px"
              height="40px"
              options={decisionOptions}
            />
          </CommitteeDecisionDescriptionContainer>
          {aprov?.documentos && (
            <OutletPermify role={[Roles.VisualizarListaDocGerados]}>
              <DocumentListIpo
                docList={aprov?.documentos}
                isDisable={
                  !!aprov?.notificacaoDaDecisaoDoComite ||
                  !validarRoles(permissions, [
                    Roles.FazerUpDocDecAdicComite,
                    Roles.ExcluirDocDecAdicComite,
                  ])
                }
                deleteStatusDocIpo={deleteAdicionalDocIpo}
                openUploadModal={() => {
                  setUploadNewDoc(true);
                }}
              />
            </OutletPermify>
          )}
        </CommitteeDecisionContainer>
      ))}
      {addNewDecisionButton && (
        <OutletPermify role={[Roles.CriarStatusDecAdicComite]}>
          <ButtonContaner>
            <hr />
            <Button
              variant="text"
              onClick={() => {
                setNewDecision(true);
              }}
            >
              <ButtonText>
                <AddCircleIcon sx={{ color: 'black' }} />
                <span>ADICIONAR DECISÃO</span>
              </ButtonText>
            </Button>
            <hr />
          </ButtonContaner>
        </OutletPermify>
      )}
      {addNewDecision && (
        <CommitteeDecisionContainer>
          <LabelCancelContainer>
            <h3>Decisão adicional</h3>
            <Button
              variant="text"
              style={{ fontSize: '14px', textDecoration: 'underline' }}
              onClick={() => {
                setNewDecision(false);
              }}
            >
              Cancelar
            </Button>
          </LabelCancelContainer>

          <RadioContent>
            <h4>Tipo de decisão:</h4>
            <Radio
              value={radioValue}
              row
              onChange={e => {
                const v = parseInt(e.target.value) as 1 | 2;
                setRadioValue(v);
              }}
              options={radioDecisionOptions}
              errorMessage={!radioValue ? radioError : undefined}
            />
          </RadioContent>

          <CommitteeDecisionDescriptionContainer>
            <p>Indique a decisão da BEE4 (Comitê ou validação interna).</p>
            <Select
              onChange={(v: 1 | 2 | 3 | 4) => {
                if (!radioValue) {
                  toast.error(
                    'Selecione o tipo de decisão antes de prosseguir.',
                  );

                  setStatus(status === null ? undefined : null);

                  return;
                }

                setStatus(v);
              }}
              value={status}
              width="230px"
              height="40px"
              options={decisionOptions}
            />
          </CommitteeDecisionDescriptionContainer>
        </CommitteeDecisionContainer>
      )}
      <ShouldRenderChildren shouldRender={!!status || uploadNewDoc}>
        <UploadModal
          openModal={!!status || uploadNewDoc}
          dialogTitle="Importar documentos"
          onSave={files => {
            handleSave(files);
          }}
          onClose={() => {
            handleCloseModal();
          }}
        />
      </ShouldRenderChildren>
    </>
  );
};
