import React, { useState } from 'react';
import EditIconMaterial from '@mui/icons-material/Edit';
import ArchiveIcon from '@mui/icons-material/Archive';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import styled from 'styled-components';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import { Container as DraggableContainer, Draggable } from 'react-smooth-dnd';
import {
  TableLine,
  Section,
  FirstSection,
  AccordionHeader,
  AccordionContentDetails,
  EditArchiveButton,
  CategoryDescription,
  EditArchiveButtonContainer,
  CheckArchiveButton,
  DragContent,
} from '../../styles';
import { Container } from '../../../../components/container';
import { CreateEditCategoryModal } from './components/createEditCategoryModal';
import { ArchiveDocumentModal } from './components/archiveModal';
import {
  Categories,
  DocumentForm,
  StageCategoryType,
  initialDocumentForm,
} from './fields';
import useCategoriaEmpresa from './hooks/useEmpresa';
import useCategoriaDiligencia from './hooks/useDiligencia';
import useCategoriaFinanceiro from './hooks/useFinanceiro';
import useCategoriaRelatorio from './hooks/useRelatorio';
import useCategoriaEmissao from './hooks/useEmissao';
import useCategoriaJuridico from './hooks/useJuridico';
import useCategoriaDocIPO from './hooks/useDocIpo';
import { ShouldRenderChildren } from '../../../../components/shouldRenderChildren';
import useCategoriaDocListagem from './hooks/useDocListagem';
import { AccordionCategorySection } from './components/accordionCategorySection';
import { OutletPermify } from '../../../../components/outletPermify';
import { Roles } from '../../../../router/roles.routes';

const EditIconButton = styled(EditIconMaterial)`
  font-size: 20px;
`;

const ArchiveIconButton = styled(ArchiveIcon)`
  font-size: 20px;
`;

const EyeIcon = styled(VisibilityOutlinedIcon)`
  font-size: 20px;
`;

export const CategoriesData = ({
  checkArchiveItems,
}: {
  checkArchiveItems: () => void;
}) => {
  const {
    empresa,
    createCategoriaEmpresa,
    updateCategoriaEmpresa,
    loadingEmpresa,
    archiveCategoriaEmpresa,
    isEmpresaSuccess,
    ordenarCategoriaEmpresa,
  } = useCategoriaEmpresa({ arquivado: false });

  const {
    emissao,
    createCategoriaEmissao,
    updateCategoriaEmissao,
    loadingEmissao,
    archiveCategoriaEmissao,
    isEmissaoSuccess,
    ordenarCategoriaEmissao,
  } = useCategoriaEmissao({ arquivado: false });

  const {
    juridico,
    createCategoriaJuridico,
    updateCategoriaJuridico,
    loadingJuridico,
    archiveCategoriaJuridico,
    isJuridicoSuccess,
    ordenarCategoriaJuridico,
  } = useCategoriaJuridico({ arquivado: false });

  const {
    docIpo,
    createCategoriaDocIpo,
    updateCategoriaDocIpo,
    loadingDocIpo,
    archiveCategoriaDocIpo,
    isDocIpoSuccess,
  } = useCategoriaDocIPO({ arquivado: false });

  const {
    diligencia,
    createCategoriaDiligencia,
    updateCategoriaDiligencia,
    loadingDiligencia,
    archiveCategoriaDiligencia,
    isDiligenciaSuccess,
    ordenarCategoriaDiligencia,
  } = useCategoriaDiligencia({ arquivado: false });

  const {
    financeiro,
    createCategoriaFinanceiro,
    updateCategoriaFinanceiro,
    loadingFinanceiro,
    archiveCategoriaFinanceiro,
    isFinanceiroSuccess,
    ordenarCategoriaFinanceiro,
  } = useCategoriaFinanceiro({ arquivado: false });

  const {
    relatorio,
    createCategoriaRelatorio,
    updateCategoriaRelatorio,
    loadingRelatorio,
    archiveCategoriaRelatorio,
    isRelatorioSuccess,
    ordenarCategoriaRelatorio,
  } = useCategoriaRelatorio({ arquivado: false });

  const {
    docListagem,
    createCategoriaDocListagem,
    updateCategoriaDocListagem,
    loadingDocListagem,
    archiveCategoriaDocListagem,
    isDocListagemSuccess,
  } = useCategoriaDocListagem({ arquivado: false });

  const loading =
    loadingEmpresa ||
    loadingEmissao ||
    loadingJuridico ||
    loadingDocIpo ||
    loadingDocListagem ||
    loadingDiligencia ||
    loadingFinanceiro ||
    loadingRelatorio;

  const isSuccess =
    isEmpresaSuccess ||
    isEmissaoSuccess ||
    isJuridicoSuccess ||
    isDocIpoSuccess ||
    loadingDocListagem ||
    isDiligenciaSuccess ||
    isFinanceiroSuccess ||
    isDocListagemSuccess ||
    isRelatorioSuccess;

  const [categoryId, setCategoryId] = useState<string | undefined>();
  const [categoryStage, setCategoryStage] = useState<StageCategoryType>('');

  const [createEditCategoryModal, setCreateEditCategoryModal] = useState(false);

  const [archiveModal, setArchiveModal] = useState(false);

  const [documentForm, setDocumentForm] =
    useState<DocumentForm>(initialDocumentForm);

  const handleStage = (stage: number): StageCategoryType => {
    if (stage === 1) {
      return 'financeiro';
    }
    if (stage === 2) {
      return 'juridico';
    }
    if (stage === 3) {
      return 'diligencia';
    }
    if (stage === 4) {
      return 'empresa';
    }
    if (stage === 5) {
      return 'emissao';
    }
    if (stage === 6) {
      return 'docIpo';
    }
    if (stage === 7) {
      return 'relatorio';
    }
    if (stage === 9) {
      return 'DocumentacaoListagem';
    }
    return '';
  };

  const handleCreateCategory = (v: any) => {
    if (categoryStage === 'diligencia') {
      return createCategoriaDiligencia(v);
    }
    if (categoryStage === 'emissao') {
      return createCategoriaEmissao(v);
    }
    if (categoryStage === 'relatorio') {
      return createCategoriaRelatorio(v);
    }
    if (categoryStage === 'juridico') {
      return createCategoriaJuridico(v);
    }
    if (categoryStage === 'financeiro') {
      return createCategoriaFinanceiro(v);
    }
    if (categoryStage === 'empresa') {
      return createCategoriaEmpresa(v);
    }
    if (categoryStage === 'docIpo') {
      return createCategoriaDocIpo(v);
    }
    if (categoryStage === 'DocumentacaoListagem') {
      return createCategoriaDocListagem(v);
    }

    return () => {};
  };

  const handleUpdateCategory = (v: any) => {
    if (categoryStage === 'diligencia') {
      return updateCategoriaDiligencia(v);
    }
    if (categoryStage === 'emissao') {
      return updateCategoriaEmissao(v);
    }
    if (categoryStage === 'relatorio') {
      return updateCategoriaRelatorio(v);
    }
    if (categoryStage === 'juridico') {
      return updateCategoriaJuridico(v);
    }
    if (categoryStage === 'financeiro') {
      return updateCategoriaFinanceiro(v);
    }
    if (categoryStage === 'empresa') {
      return updateCategoriaEmpresa(v);
    }
    if (categoryStage === 'docIpo') {
      return updateCategoriaDocIpo(v);
    }

    if (categoryStage === 'DocumentacaoListagem') {
      return updateCategoriaDocListagem(v);
    }

    return () => {};
  };

  const handleArchiveCategory = (category: any) => {
    const v = { id: category.id };
    const chosenStage = handleStage(category.etapa);

    if (chosenStage === 'diligencia') {
      return archiveCategoriaDiligencia(v);
    }
    if (chosenStage === 'emissao') {
      return archiveCategoriaEmissao(v);
    }
    if (chosenStage === 'relatorio') {
      return archiveCategoriaRelatorio(v);
    }
    if (chosenStage === 'juridico') {
      return archiveCategoriaJuridico(v);
    }
    if (chosenStage === 'financeiro') {
      return archiveCategoriaFinanceiro(v);
    }
    if (chosenStage === 'empresa') {
      return archiveCategoriaEmpresa(v);
    }
    if (chosenStage === 'docIpo') {
      return archiveCategoriaDocIpo(v);
    }

    if (chosenStage === 'DocumentacaoListagem') {
      return archiveCategoriaDocListagem(v);
    }

    return () => {};
  };

  const handleChangeOrderCategory = (payload: any, type: number) => {
    if (type === 1) {
      return ordenarCategoriaEmpresa(payload);
    }
    if (type === 2) {
      return ordenarCategoriaFinanceiro(payload);
    }
    if (type === 3) {
      return ordenarCategoriaJuridico(payload);
    }
    if (type === 4) {
      return ordenarCategoriaDiligencia(payload);
    }
    if (type === 5) {
      return ordenarCategoriaEmissao(payload);
    }
    if (type === 7) {
      return ordenarCategoriaRelatorio(payload);
    }

    return () => {};
  };

  const onDrop = (dropResult: any) => {
    const { removedIndex, addedIndex, categoryList, type } = dropResult;

    if (categoryList && type) {
      const documentoCategoriaId = categoryList[removedIndex].id;
      handleChangeOrderCategory(
        { documentoCategoriaId, ordem: addedIndex },
        type,
      );
    }
  };

  const handleCategoryList = (categoryList: Categories[], type: number) => {
    if (categoryList) {
      const final = categoryList.length - 1;
      if (![6, 9].includes(type)) {
        return (
          <DraggableContainer
            dragHandleSelector=".drag-handle"
            lockAxis="y"
            onDrop={(v: any) => {
              onDrop({ ...v, categoryList, type });
            }}
            render={(ref: any) => {
              return (
                <ul ref={ref}>
                  {categoryList.map((category, index) => {
                    return (
                      <Draggable
                        key={category.id}
                        render={() => (
                          <li>
                            <AccordionContentDetails>
                              <OutletPermify role={[Roles.ReordenarCategorias]}>
                                <DragContent>
                                  <DragHandleIcon className="drag-handle" />
                                </DragContent>
                              </OutletPermify>

                              <CategoryDescription>
                                {category.ordem}. {category.nome}
                              </CategoryDescription>
                              <OutletPermify
                                role={[
                                  Roles.EditarCategoria,
                                  Roles.ReativarCategoria,
                                ]}
                              >
                                <EditArchiveButtonContainer>
                                  <EditArchiveButton
                                    onClick={() => {
                                      setCategoryStage(
                                        handleStage(category.etapa),
                                      );
                                      setArchiveModal(true);
                                      handleArchiveCategory(category);
                                    }}
                                  >
                                    <ArchiveIconButton htmlColor="#212e51" />
                                  </EditArchiveButton>
                                  <EditArchiveButton
                                    onClick={() => {
                                      setCategoryId(category?.id);
                                      setCategoryStage(
                                        handleStage(category.etapa),
                                      );
                                      setDocumentForm({
                                        ...documentForm,
                                        ...category,
                                      });

                                      setCreateEditCategoryModal(true);
                                    }}
                                  >
                                    <EditIconButton htmlColor="#212e51" />
                                  </EditArchiveButton>
                                </EditArchiveButtonContainer>
                              </OutletPermify>
                            </AccordionContentDetails>
                            {final === index ? null : <TableLine />}
                          </li>
                        )}
                      />
                    );
                  })}
                </ul>
              );
            }}
          />
        );
      }

      return categoryList.map((category, index) => {
        return (
          <React.Fragment key={category.id}>
            <AccordionContentDetails>
              <CategoryDescription>
                {category.ordem}. {category.nome}
              </CategoryDescription>
              <OutletPermify
                role={[Roles.EditarCategoria, Roles.ReativarCategoria]}
              >
                <EditArchiveButtonContainer>
                  <EditArchiveButton
                    onClick={() => {
                      setCategoryStage(handleStage(category.etapa));
                      setArchiveModal(true);
                      handleArchiveCategory(category);
                    }}
                  >
                    <ArchiveIconButton htmlColor="#212e51" />
                  </EditArchiveButton>
                  <EditArchiveButton
                    onClick={() => {
                      setCategoryId(category.id);
                      setCategoryStage(handleStage(category.etapa));
                      setDocumentForm({
                        ...documentForm,
                        ...category,
                      });
                      setCreateEditCategoryModal(true);
                    }}
                  >
                    <EditIconButton htmlColor="#212e51" />
                  </EditArchiveButton>
                </EditArchiveButtonContainer>
              </OutletPermify>
            </AccordionContentDetails>
            {final === index ? null : <TableLine />}
          </React.Fragment>
        );
      });
    }
    return <></>;
  };

  return (
    <OutletPermify role={[Roles.VisualizarCategorias]}>
      <Container title="Novos Campos" titleMarginBottom="7px">
        <FirstSection>
          <span>
            A Administração global adiciona itens a todas as empresas na qual o
            Tech Interno é cadastrado.
          </span>
          <OutletPermify role={[Roles.VisualizarCategArquivado]}>
            <CheckArchiveButton
              type="button"
              onClick={() => {
                checkArchiveItems();
              }}
            >
              <EyeIcon htmlColor="#212e51" />
              Itens arquivados
            </CheckArchiveButton>
          </OutletPermify>
        </FirstSection>
        <Section>
          <AccordionHeader>
            <span>Cadastro e diligência</span>
          </AccordionHeader>
          <AccordionCategorySection
            loading={loading}
            name="Dados Gerais"
            setOpenCreateCategory={() => {
              setCategoryStage('empresa');
              setCreateEditCategoryModal(true);
            }}
            description="Documentos"
          >
            {handleCategoryList(empresa, 1)}
          </AccordionCategorySection>

          <TableLine />

          <AccordionCategorySection
            loading={loading}
            name="Documentação adicional"
            setOpenCreateCategory={() => {
              setCategoryStage('financeiro');
              setCreateEditCategoryModal(true);
            }}
            description="Documentos"
          >
            {handleCategoryList(financeiro, 2)}
          </AccordionCategorySection>

          <TableLine />

          <AccordionCategorySection
            loading={loading}
            name="Jurídico"
            setOpenCreateCategory={() => {
              setCategoryStage('juridico');
              setCreateEditCategoryModal(true);
            }}
            description="Documentos"
          >
            {handleCategoryList(juridico, 3)}
          </AccordionCategorySection>

          <TableLine />

          <AccordionCategorySection
            loading={loading}
            name="Diligência"
            setOpenCreateCategory={() => {
              setCategoryStage('diligencia');
              setCreateEditCategoryModal(true);
            }}
            description="Documentos"
          >
            {handleCategoryList(diligencia, 4)}
          </AccordionCategorySection>
        </Section>
        <Section>
          <AccordionHeader>
            <span>Sobre o ativo</span>
          </AccordionHeader>
          <AccordionCategorySection
            loading={loading}
            name="Sobre o ativo"
            setOpenCreateCategory={() => {
              setCategoryStage('emissao');
              setCreateEditCategoryModal(true);
            }}
            description="Documentos"
          >
            {handleCategoryList(emissao, 5)}
          </AccordionCategorySection>
        </Section>
        <Section>
          <AccordionHeader>
            <span>Doc. IPO</span>
          </AccordionHeader>
          <AccordionCategorySection
            loading={loading}
            name="Doc. IPO"
            setOpenCreateCategory={() => {
              setCategoryStage('docIpo');
              setCreateEditCategoryModal(true);
            }}
            description="Documentos"
          >
            {handleCategoryList(docIpo, 6)}
          </AccordionCategorySection>
        </Section>

        <Section>
          <AccordionHeader>
            <span>Doc. Listagem</span>
          </AccordionHeader>
          <AccordionCategorySection
            loading={loading}
            name="Doc. Listagem"
            setOpenCreateCategory={() => {
              setCategoryStage('DocumentacaoListagem');
              setCreateEditCategoryModal(true);
            }}
            description="Documentos"
          >
            {handleCategoryList(docListagem, 9)}
          </AccordionCategorySection>
        </Section>

        <Section>
          <AccordionHeader>
            <span>Relatórios</span>
          </AccordionHeader>
          <AccordionCategorySection
            loading={loading}
            name="Relatórios"
            setOpenCreateCategory={() => {
              setCategoryStage('relatorio');
              setCreateEditCategoryModal(true);
            }}
            description="Documentos"
          >
            {handleCategoryList(relatorio, 7)}
          </AccordionCategorySection>
        </Section>

        <ShouldRenderChildren shouldRender={createEditCategoryModal}>
          <CreateEditCategoryModal
            description={createEditCategoryModal}
            closeDropdown={() => {
              setCreateEditCategoryModal(false);
              setDocumentForm(initialDocumentForm);
              setCategoryId(undefined);
            }}
            stage={categoryStage}
            createCategory={handleCreateCategory}
            categoryId={categoryId}
            document={documentForm}
            updateCategory={handleUpdateCategory}
          />
        </ShouldRenderChildren>

        <ShouldRenderChildren
          shouldRender={archiveModal && isSuccess && !loading}
        >
          <ArchiveDocumentModal
            description={archiveModal && isSuccess && !loading}
            closeDropdown={() => {
              setArchiveModal(false);
            }}
          />
        </ShouldRenderChildren>

        <Section />
      </Container>
    </OutletPermify>
  );
};
