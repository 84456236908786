import styled from 'styled-components';

export const Content = styled.div`
  padding-bottom: 55px;
`;

export const FormTitleContainer = styled.div`
  display: flex;
  margin: 80px 0 45px 0;
`;

export const Export = styled.div`
  display: flex;
  gap: 15px;
  margin-left: auto;
  position: absolute;
  top: 32px;
  right: 30px;
`;

export const TitleDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const Title = styled.span`
  display: flex;
  font-size: 24px;
  font-weight: bold;
  color: #171725;
`;

export const InnerTitle = styled.span`
  font-weight: 500;
  font-size: 16px;

  letter-spacing: 0.54px;
  color: #51576c;
`;

export const DeleteButton = styled.button`
  float: right;
  border: none;
  background: transparent;
`;

export const ColumnTitle = styled.span`
  font-size: 18px;
  font-weight: 700;
  color: #171725;
`;

export const BoxSkeleton = styled.span`
  background-color: #fff;
  border-radius: 8px;
  padding: 24px;
  display: flex;
  gap: 24px;
  align-items: center;
  width: 100%;
  margin-bottom: 24px;
`;

export const RowTitle = styled.span`
  font-size: 18px;
  font-weight: 500;
  color: #171725;
`;

export const Margin = styled.div`
  height: 24px;
`;

export const Divider = styled.hr`
  border: 1px solid #ccc;
  opacity: 0.5;
`;

export const CheckboxContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 64px;
`;

export const CheckboxLabel = styled.span`
  a {
    color: #f78157;
    text-decoration: none;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 40px;
`;

export const DisabledWrapper = styled.div`
  position: relative;
`;

export const ContentTitle = styled.h1`
  color: #171725;
  font-size: 1.4rem;
  margin: 3rem 0 1rem 0;
`;
