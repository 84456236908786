import React, { useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Controller } from '../../../../../../components/controller';
import { Modal } from '../../../../../../components/modal';
import { Buttons, ModalTitle } from '../../styles';
import { Button } from '../../../../../../components/button';
import { Grid } from '../../../../../../components/grid';
import { useCollaboratorCompanies } from '../../hooks/collaborator';
import { Collaborator, schemaColaborador } from '../../fields';

interface IButtonProps {
  description: boolean;
  closeDropdown: (e?: any) => void;
  companyId: string;
  companyType?: 'nomad' | 'escriturador' | 'auditor';
  createCollaborator: (e: any) => void;
  updateColaborador: (e: any) => void;
  collaborator?: Collaborator;
  defaultValue?: (string | undefined)[];
}

export const CreateEditCollaboratorModal: React.FC<IButtonProps> = ({
  description,
  closeDropdown,
  companyId,
  createCollaborator,
  updateColaborador,
  collaborator,
  companyType,
  defaultValue,
}) => {
  const { companies, isLoading } = useCollaboratorCompanies({
    companyId,
    colaboradorId: collaborator?.id,
    companyType,
  });

  const { handleSubmit, control, reset } = useForm<Collaborator>({
    resolver: yupResolver(schemaColaborador),
  });

  const submitForm = (data: Collaborator) => {
    if (!data.email) {
      toast.error('Coloque um email para criar o novo colaborador.');
      return;
    }
    if (collaborator) {
      updateColaborador({
        id: companyId,
        consulter: {
          ...data,
          empresasConsultor: data?.empresasConsultor ?? [],
        },
        consultorId: collaborator.id,
      });
    } else {
      createCollaborator({
        id: companyId,
        consulter: {
          ...data,
          empresasConsultor: data?.empresasConsultor ?? [],
        },
      });
    }

    closeDropdown();
  };

  useEffect(() => {
    reset({
      id: collaborator?.id,
      nome: collaborator?.nome,
      cpf: collaborator?.cpf,
      celular: collaborator?.celular,
      email: collaborator?.email,
      empresasConsultor: collaborator?.empresasConsultor,
    });
  }, [reset]);

  return (
    <Modal open={description} handleClose={closeDropdown} width={1200}>
      <form onSubmit={handleSubmit(submitForm)}>
        <ModalTitle>
          {collaborator ? 'Editar' : 'Registrar'} Colaborador(a)
        </ModalTitle>
        <Grid container spacing={4}>
          <Grid item desktop={6}>
            <Controller
              name="nome"
              control={control}
              type="input"
              label="Nome completo"
            />
          </Grid>
          <Grid item desktop={6}>
            <Controller
              name="cpf"
              control={control}
              type="input"
              mask="cpf"
              label="CPF"
            />
          </Grid>
          <Grid item desktop={6}>
            <Controller
              name="celular"
              control={control}
              type="input"
              mask="phone"
              label="Celular"
            />
          </Grid>
          <Grid item desktop={6}>
            <Controller
              name="email"
              control={control}
              type="input"
              label="E-mail do Colaborador"
            />
          </Grid>

          <Grid item desktop={12}>
            <Controller
              name="empresasConsultor"
              control={control}
              type="multipleselect"
              label="SELECIONE A(S) EMPRESA(S) PELA(S) QUAL(IS) É RESPONSÁVEL"
              multipleSelectOptions={companies}
              defaultValue={defaultValue}
              disabled={isLoading}
            />
          </Grid>
        </Grid>
        <Buttons>
          <Button
            width="103px"
            height="40px"
            variant="outlined"
            onClick={closeDropdown}
          >
            CANCELAR
          </Button>
          <Button type="submit" width="103px" height="40px">
            SALVAR
          </Button>
        </Buttons>
      </form>
    </Modal>
  );
};
