import * as yup from 'yup';
import { isValid } from '../../services/isValid';

export const schema = yup.object().shape({
  codigoDeNegociacaoProposto: yup.string().nullable(),
  codigoIsin: yup.string().nullable(),
  dataDeEmissaoDoAtivoASerNegociadoNaBEE4: yup
    .string()
    .test('validateDate', 'Data inválida', data => {
      return isValid.date(data);
    })
    .nullable(),
  loteAdicional: yup.boolean().nullable(),
  qualOPercentual: yup.number().nullable(),
  comentariosSobreAEmissaoAoEscriturador: yup.string().nullable(),
  dataDeInicioDaNegociacao: yup
    .string()
    .test('validateDate', 'Data inválida', data => {
      return isValid.date(data);
    })
    .nullable(),
  loteDeNegociacao: yup.string().nullable(),
  porcentagemDaOfertaQueEVendaPrimaria: yup.number().nullable(),
  porcentagemDaOfertaQueEVendaSecundaria: yup.number().nullable(),
  porcentagemDeParticipacaoNaEmpresa: yup.number().nullable(),
  numeroDeTokensEmitidosParaOfertaPublica: yup.number().nullable(),
  precoUnitarioDoToken: yup.number().nullable(),
  calendarioDaOferta: yup.object().shape({
    dataDeConclusaoDaOfertaPublica: yup
      .string()
      .test('validateDate', 'Data inválida', data => {
        return isValid.date(data);
      })
      .nullable(),
    disponibilizacaoDosMateriaisDeDivulgacao: yup
      .string()
      .test('validateDate', 'Data inválida', data => {
        return isValid.date(data);
      })
      .nullable(),
    inicioDoPeriodoDoPedidoDeReserva: yup
      .string()
      .test('validateDate', 'Data inválida', data => {
        return isValid.date(data);
      })
      .nullable(),
    fimDoPeriodoDoPedidoDeReserva: yup
      .string()
      .test('validateDate', 'Data inválida', data => {
        return isValid.date(data);
      })
      .nullable(),
    dataDeLiquidacaoDaOferta: yup
      .string()
      .test('validateDate', 'Data inválida', data => {
        return isValid.date(data);
      })
      .nullable(),
    dataDeNegociacaoDoPrimeiroLeilao: yup
      .string()
      .test('validateDate', 'Data inválida', data => {
        return isValid.date(data);
      })
      .nullable(),
    dataDeInicioDasNegociacoes: yup
      .string()
      .test('validateDate', 'Data inválida', data => {
        return isValid.date(data);
      })
      .nullable(),
  }),
});

export interface IUsePosIPO {
  montanteCaptado?: number;
  participacaoVendidaNaEmpresa?: number;
  isReadOnly?: boolean;
  documentoGerado?: boolean;
  numeroDeAcoesOuTitulosEmitidosAposAOfertaPublica?: number;
  tipoDeAtivo: number;
  codigoDeNegociacaoProposto: string;
  codigoIsin: string;
  dataDeEmissaoDoAtivoASerNegociadoNaBEE4: string | null;
  loteAdicional: boolean | string;
  qualOPercentual?: number;
  comentariosSobreAEmissaoAoEscriturador: string;
  dataDeInicioDaNegociacao: string | null;
  loteDeNegociacao: string;
  porcentagemDaOfertaQueEVendaPrimaria: number;
  porcentagemDaOfertaQueEVendaSecundaria: number;
  porcentagemDeParticipacaoNaEmpresa: number;
  numeroDeTokensEmitidosParaOfertaPublica: number;
  precoUnitarioDoToken: number;
  calendarioDaOferta: {
    dataDeConclusaoDaOfertaPublica: string | null;
    disponibilizacaoDosMateriaisDeDivulgacao: string | null;
    inicioDoPeriodoDoPedidoDeReserva: string | null;
    fimDoPeriodoDoPedidoDeReserva: string | null;
    dataDeLiquidacaoDaOferta: string | null;
    dataDeNegociacaoDoPrimeiroLeilao: string | null;
    dataDeInicioDasNegociacoes: string | null;
  };
}
