import axios from 'axios';

interface IEndereco {
  endereco: string;
  bairro: string;
  cidade: string;
  uf: string;
}

export async function obterEnderecoAsync(cep: string): Promise<IEndereco> {
  try {
    const response: any = await axios.get(
      `https://viacep.com.br/ws/${cep}/json/`,
    );
    if (!response || !response?.data) {
      return {
        endereco: '',
        bairro: '',
        cidade: '',
        uf: '',
      };
    }
    return {
      endereco: response.data.logradouro,
      bairro: response.data.bairro,
      cidade: response.data.localidade,
      uf: response.data.uf,
    };
  } catch {
    return {
      endereco: '',
      bairro: '',
      cidade: '',
      uf: '',
    };
  }
}
