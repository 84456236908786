/* eslint-disable no-unneeded-ternary */
import React, {
  useState,
  SyntheticEvent,
  ReactElement,
  useEffect,
} from 'react';
import { AccordionProps } from '@mui/material/Accordion';

import * as S from './styles';

export type IAccordionProps = {
  /*
   * To be used as a controlled component
   */
  id: string;
  /*
   * Define the summary (header) of accordion
   */
  summary?: ReactElement;
  /*
   * Define the classes for summary
   */
  summaryClasses?: string;
  /*
   * Define the classes for summary
   */
  variantstyle?: 'base' | 'nomad' | 'default';
  /*
   * Define the classes for global component
   */
  className?: string;
  /*
   * Define the accordion is exapanded to default
   */
  defaultExpanded?: boolean;
  /*
   * Define the value when controlled
   */
  value?: boolean;
  /*
   * Define the accordion method controlled
   */
  handleExpanded?: () => void;
} & AccordionProps;

export default function Accordion({
  summary,
  summaryClasses,
  className,
  children,
  defaultExpanded,
  id = 'accordion-1',
  variantstyle = 'base',
  handleExpanded,
  value,
}: IAccordionProps) {
  const [expanded, setExpanded] = useState<string | boolean>('');

  const handleChange =
    (panel: string) => (event: SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  /*
   *  This is a workaround for the fact that the Accordion component is open to default
   */
  useEffect(() => {
    if (defaultExpanded) {
      setExpanded(id);
      if (handleExpanded) {
        handleExpanded();
      }
    }
  }, [defaultExpanded]);

  return (
    <div>
      <S.Accordion
        id={id}
        className={className}
        expanded={value != null ? value : expanded === id}
        onChange={handleExpanded ? () => handleExpanded() : handleChange(id)}
        variantstyle={variantstyle}
      >
        <S.AccordionSummary
          className={summaryClasses}
          aria-controls={`${id}-content`}
          id={`${id}-summary`}
        >
          {summary}
        </S.AccordionSummary>
        <S.AccordionDetails className={`${id}-content`}>
          {children}
        </S.AccordionDetails>
      </S.Accordion>
    </div>
  );
}
