import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Modal } from '../../modal';
import { Button } from '../../button';
import {
  AddReviewButton,
  ModalButtons,
  ModalForm,
  NewMessageTypography,
  ReviewModalWithComments,
  ReviewModalWithCommentsTitle,
} from './styles';
import { Controller } from '../../controller';
import { StatusChangePayload } from '../fields';
import { ReviewAccordion } from '../components/reviewAccordion';
import { AddReviewIpoPayload } from '../../../pages/documentacaoIPO/fields';
import { useAuth } from '../../../hooks/auth';

interface IModalProps {
  description: boolean;
  closeDropdown: (e?: any) => void;
  statusObject?: StatusChangePayload;
  onStatusChange: (e?: StatusChangePayload) => void;
  addReview: (e: AddReviewIpoPayload) => void;
  status: number;
}

const schema = yup.object().shape({
  motivo: yup.string().required('Campo obrigatório'),
});
export const RevisaoModal: React.FC<IModalProps> = ({
  description,
  closeDropdown,
  statusObject,
  onStatusChange,
  addReview,
  status,
}) => {
  const { currentRole } = useAuth();
  const [addNewComment, setAddNewComment] = useState(false);
  const { handleSubmit, control } = useForm<any>({
    resolver: yupResolver(schema),
  });

  const canAddNewComment =
    (currentRole === 'BEE4' &&
      statusObject?.statusDaCategoria === 8 &&
      status === 4) ||
    (currentRole === 'NOMADBOSS' &&
      statusObject?.statusDaCategoria === 2 &&
      status === 3);

  const submitForm = (e: any) => {
    if (statusObject?.comments && addNewComment) {
      // add comentário
      addReview({ documentoCategoriaId: statusObject?.categoryId, ...e });
    } else {
      // mudar para status de revisão com o primeiro comentário
      onStatusChange({ ...statusObject, ...e });
    }
    closeDropdown();
  };

  // modal para criar um novo comentário
  if (statusObject?.comments && addNewComment) {
    return (
      <Modal open={description} handleClose={closeDropdown} width={672}>
        <ModalForm onSubmit={handleSubmit(submitForm)}>
          <ReviewModalWithCommentsTitle>
            Pedido de revisão
          </ReviewModalWithCommentsTitle>
          <div>
            <NewMessageTypography>Envie uma nova mensagem</NewMessageTypography>
            <Controller
              name="motivo"
              control={control}
              textarea
              type="input"
              label="Mensagem"
            />
          </div>
          <ModalButtons>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => {
                setAddNewComment(false);
              }}
              width="157px"
              height="40px"
            >
              VOLTAR
            </Button>
            <Button type="submit" width="157px" height="40px">
              Salvar
            </Button>
          </ModalButtons>
        </ModalForm>
      </Modal>
    );
  }

  // modal para ver os comentários de revisão
  if (statusObject?.comments) {
    const sortedComments = statusObject?.comments.sort(
      (a, b) => a.ordem - b.ordem,
    );
    return (
      <Modal open={description} handleClose={closeDropdown} width={672}>
        <ReviewModalWithComments>
          <ReviewModalWithCommentsTitle>
            Documento precisa ser revisado
          </ReviewModalWithCommentsTitle>

          {sortedComments?.map(comment => (
            <ReviewAccordion comment={comment} key={comment.id} />
          ))}

          {canAddNewComment && (
            <AddReviewButton
              onClick={() => {
                setAddNewComment(true);
              }}
            >
              <AddCircleOutlineIcon />
              Nova mensagem
            </AddReviewButton>
          )}
        </ReviewModalWithComments>
      </Modal>
    );
  }

  // modal para mudar para o status de revisão
  return (
    <Modal open={description} handleClose={closeDropdown} width={672}>
      <ModalForm onSubmit={handleSubmit(submitForm)}>
        <h3>Documento precisa ser revisado</h3>
        <p>
          Você está solicitando a revisão das informações ou documento inserido
          pelo Emissor e/ou Consultor de Listagem.
          <strong> Para isso, descreva abaixo os ajustes necessários:</strong>
        </p>

        <Controller
          name="motivo"
          control={control}
          textarea
          type="input"
          label="Mensagem"
        />
        <ModalButtons>
          <Button
            variant="outlined"
            color="secondary"
            onClick={closeDropdown}
            width="157px"
            height="40px"
          >
            VOLTAR
          </Button>
          <Button type="submit" width="157px" height="40px">
            Salvar
          </Button>
        </ModalButtons>
      </ModalForm>
    </Modal>
  );
};
