import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Carregando from '../../components/carregando';
import { useAuth } from '../../hooks/auth';

export const RefreshToken = () => {
  const { refreshToken } = useAuth();
  const { search } = useLocation();
  const navigate = useNavigate();

  async function init() {
    await refreshToken();
    const urlSearchParams = new URLSearchParams(search);
    const callback = urlSearchParams.get('callback');
    if (!callback) {
      navigate('/');
    } else {
      navigate(callback);
    }
  }

  useEffect(() => {
    init();
  }, []);

  return <Carregando />;
};
