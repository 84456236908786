import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import NumberFormat from 'react-number-format';
import { Tooltip } from '../tooltip';
import { TooltipDiv } from '../../pages/dadosGerais/styles';

interface InputProps {
  onChange?: (e?: any) => void;
  label?: string;
  name?: string;
  value?: any;
  textarea?: boolean;
  disabled?: boolean;
  error?: boolean;
  errorMessage?: string;
  height?: string;
  width?: string;
  placeholder?: string;
  maxLength?: number;
  ref?: any;
  type?: string;
  mask?: any;
}

export interface MaskedInputOnChangeCallback {
  formattedValue: string;
  value: string;
  floatValue: number;
}

export const masks = {
  currency: {
    thousandSeparator: '.',
    decimalSeparator: ',',
    isNumericString: true,
    prefix: 'R$',
    decimalScale: 2,
    fixedDecimalScale: true,
  },
  percentage: {
    thousandSeparator: '.',
    decimalSeparator: ',',
    isNumericString: true,
    suffix: '%',
  },
  cep: {
    format: '#####-###',
    mask: '_',
    allowLeadingZeros: true,
  },
  date: {
    format: '##/##/####',
    mask: '_',
    allowLeadingZeros: true,
  },
  phone: {
    format: '(##) #####-####',
    mask: '_',
    allowLeadingZeros: true,
  },
  cnpj: {
    format: '##.###.###/####-##',
    mask: '_',
    allowLeadingZeros: true,
  },
  cpf: {
    format: '###.###.###-##',
    mask: '_',
    allowLeadingZeros: true,
  },
  integer: {
    isNumericString: true,
    decimalScale: 0,
    allowLeadingZeros: true,
    allowNegative: false,
  },
  thousandSeparator: {
    thousandSeparator: '.',
    decimalSeparator: ',',
    isNumericString: true,
    decimalScale: 0,
  },
  bank: {
    format: '###.###-#',
    mask: '_',
    allowLeadingZeros: true,
  },
  negativeCurrency: {
    thousandSeparator: '.',
    decimalSeparator: ',',
    isNumericString: true,
    prefix: 'R$',
    decimalScale: 2,
    fixedDecimalScale: false,
    allowNegative: true,
  },
};

export const Input: React.FC<any> = props => {
  const {
    label,
    value,
    textarea,
    onChange,
    disabled,
    error,
    errorMessage,
    height,
    width,
    placeholder,
    maxLength = 350,
    ref,
    type,
    mask,
    noZero,
    onBlur,
    style,
  } = props;

  const [hasEllipsis, setHasEllipsis] = useState<boolean>(false);

  const labelHasEllipsis = () => {
    const labelObj = document.getElementById(label);
    if (labelObj) {
      if (labelObj.offsetWidth < labelObj.scrollWidth) {
        return true;
      }
      return false;
    }
    return true;
  };

  useEffect(() => {
    window.addEventListener('resize', () => setHasEllipsis(labelHasEllipsis()));

    return () => {
      window.removeEventListener('resize', () =>
        setHasEllipsis(labelHasEllipsis()),
      );
    };
  }, []);

  useEffect(() => {
    setHasEllipsis(labelHasEllipsis());
  }, [document.getElementById(label)]);

  return (
    <>
      {mask ? (
        <NumberFormat
          {...mask}
          customInput={TextField}
          disabled={disabled}
          multiline={textarea}
          variant="outlined"
          label={label}
          value={value}
          error={error}
          onBlur={onBlur}
          helperText={errorMessage}
          placeholder={placeholder}
          isAllowed={values => {
            if (noZero && values.floatValue === 0) {
              return false;
            }
            return true;
          }}
          onValueChange={(v: MaskedInputOnChangeCallback) => {
            onChange(v.value);
          }}
          fullWidth
          rows={6}
          inputRef={ref}
          allowLeadingZeros
          inputProps={{ maxLength, style: { height, width } }}
          InputLabelProps={{
            id: label,
            style: { color: disabled ? '#bdbdbd' : '#424242' },
            shrink: true,
          }}
          style={style}
        />
      ) : (
        <TextField
          multiline={textarea}
          variant="outlined"
          label={label}
          disabled={disabled}
          value={value}
          placeholder={placeholder}
          onChange={(v: any) => {
            onChange(v.value ? v.value : v.target.value);
          }}
          fullWidth
          rows={6}
          type={type}
          inputRef={ref}
          inputProps={{ maxLength, style: { height, width } }}
          InputLabelProps={{
            style: { color: disabled ? '#bdbdbd' : '#424242' },
            shrink: true,
          }}
          style={style}
        />
      )}
      <Tooltip title={label} disabled={!hasEllipsis} placement="top">
        <TooltipDiv />
      </Tooltip>
    </>
  );
};
