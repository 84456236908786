import * as yup from 'yup';
import { isValid } from '../../services/isValid';

export const listMarketConcentration = [
  { value: 1, label: 'Pulverizado' },
  { value: 2, label: 'Em consolidação' },
  { value: 3, label: 'Concentrado' },
];

export const listMainSuppliers = [
  { value: 1, label: 'Poucos' },
  { value: 2, label: 'Alguns' },
  { value: 3, label: 'Muitos' },
];

export const listCycleMoment = [
  { value: 1, label: 'Introdução' },
  { value: 2, label: 'Crescimento' },
  { value: 3, label: 'Maturidade' },
  { value: 4, label: 'Declínio' },
];

export const listRisks = [
  { value: 1, label: 'Alto' },
  { value: 2, label: 'Médio' },
  { value: 3, label: 'Baixo' },
];

export const listRisks2 = [
  { value: 1, label: 'Alta' },
  { value: 2, label: 'Média' },
  { value: 3, label: 'Baixa' },
];

export const AuditadoOptions = [
  { value: 1, label: 'Auditado' },
  { value: 2, label: 'Não Auditado' },
];

export const schema = yup.object().shape({
  processoDeDistribuicaoOuProducao: yup.string().nullable(),
  forcasDaMatrizSWOT: yup.string().nullable(),
  fraquezasDaMatrizSWOT: yup.string().nullable(),
  oportunidadesDaMatrizSWOT: yup.string().nullable(),
  ameacasDaMatrizSWOT: yup.string().nullable(),
  principaisCompetidores: yup
    .array()
    .of(yup.object().shape({ nome: yup.string().nullable() })),
  distribuicaoDeReceitaLiquidaPorRegiaoOuPais: yup.array().of(
    yup.object().shape({
      regiaoOuPais: yup.string().nullable(),
      receitaLiquida: yup
        .number()
        .typeError('Precisa ser um número')
        .nullable()
        .transform((v, o) => (o === '' ? undefined : v)),
    }),
  ),

  publicoAlvo: yup.string().nullable(),
  focoGeografico: yup.string().nullable(),
  tamanhoDoMercadoPotencial: yup.string().nullable(),
  explicacaoDoCalculoDoTamanhoDoMercadoPotencial: yup.string().nullable(),
  concentracaoDoMercadoDeAtuacao: yup.number().nullable(),
  maturidadeDoMercadoDeAtuacao: yup.number().nullable(),
  principaisRiscos: yup.array().of(
    yup.object().shape({
      nome: yup.string().nullable(),
      probabilidade: yup.number().nullable(),
      impactoFinanceiro: yup.number().nullable(),
    }),
  ),
  riscosDeExecucao: yup.string().nullable(),
  riscoCambial: yup.string().nullable(),
  riscoFiscal: yup.string().nullable(),
  riscoTecnologico: yup.string().nullable(),
  riscosDeAlavancagem: yup.string().nullable(),
  riscoRegulatorio: yup.string().nullable(),
  explicacaoDoEbitdaAjustado: yup.string().nullable(),
  dataReferenciaDosIndicadoresFinanceiros: yup
    .string()
    .test('validateDate', 'Data inválida', data => {
      return isValid.date(data);
    })
    .nullable(),

  dataReferenciaDoBalancoPatrimonial: yup
    .string()
    .test('validateDate', 'Data inválida', data => {
      return isValid.date(data);
    })
    .nullable(),
  valuationPreMoney: yup.number().nullable(),
  dataDeReferenciaDoValuationPreMoney: yup
    .string()
    .test('validateDate', 'Data inválida', data => {
      return isValid.date(data);
    })
    .nullable(),
  valorDaEmpresaOuEbitda: yup.string().nullable(),
  relacaoPrecoLucro: yup.string().nullable(),
  comentarioDaEmpresaSobreSuaAvaliacao: yup.string().nullable(),
});

export const mockRegion = [
  { regiaoOuPais: '', receitaLiquida: undefined },
  { regiaoOuPais: '', receitaLiquida: undefined },
  { regiaoOuPais: '', receitaLiquida: undefined },
  { regiaoOuPais: '', receitaLiquida: undefined },
];

export type PrincipalFornecedor = {
  nome: string;
};

export type DistribuicaoDeReceitaLiquidaPorRegiaoOuPais = {
  regiaoOuPais: string;
  receitaLiquida?: number;
};

export type PrincipaisRiscos = {
  nome: string;
  probabilidade: number;
  impactoFinanceiro: number;
};

export type Indicadores = {
  referencia: number;
  chave?: string;
  valor?: number;
  ordem: number;
  label: string;
  tipoCampo: number;
};

export type IndicadoresColunas = {
  referencia: number;
  auditado?: number;
};

export type FinancialForm = {
  processoDeDistribuicaoOuProducao: string;
  forcasDaMatrizSWOT: string;
  fraquezasDaMatrizSWOT: string;
  oportunidadesDaMatrizSWOT: string;
  ameacasDaMatrizSWOT: string;
  principaisCompetidores: PrincipalFornecedor[];
  distribuicaoDeReceitaLiquidaPorRegiaoOuPais: DistribuicaoDeReceitaLiquidaPorRegiaoOuPais[];
  publicoAlvo: string;
  focoGeografico: string;
  tamanhoDoMercadoPotencial: string;
  explicacaoDoCalculoDoTamanhoDoMercadoPotencial: string;
  concentracaoDoMercadoDeAtuacao: number;
  maturidadeDoMercadoDeAtuacao: number;
  concentracaoDosPrincipaisFornecedores: number;
  principaisRiscos: PrincipaisRiscos[];
  riscosDeExecucao: string;
  riscoCambial: string;
  riscoFiscal: string;
  riscoTecnologico: string;
  riscosDeAlavancagem: string;
  riscoRegulatorio: string;
  explicacaoDoEbitdaAjustado: string;
  indicadoresFinanceirosAuditados: boolean | string;
  dataReferenciaDosIndicadoresFinanceiros: string | null;
  indicadoresFinanceiros: Indicadores[];
  indicadoresFinanceirosColunas: IndicadoresColunas[];
  balancoPatrimonialAuditado: boolean | string;
  dataReferenciaDoBalancoPatrimonial: string | null;
  balancoPatrimonial: Indicadores[];
  balancoPatrimonialColunas: IndicadoresColunas[];
  valuationPreMoney: number;
  dataDeReferenciaDoValuationPreMoney: string | null;
  valorDaEmpresaOuEbitda: number;
  caixaLiquidoEInvestimentosFinanceiros: number;
  valorDaEmpresaAoPrecoDaOferta: number;
  relacaoPrecoLucroAjustada: number;
  capitalizacaoDeMercadoAoPrecoDaOferta: number;
  relacaoPrecoLucro: number;
  comentarioDaEmpresaSobreSuaAvaliacao: string;
  qualSeraADestinacaoDosRecursosDaOferta: string;
  isReadOnly: boolean;
};

export type FinancialFormSubmit = {
  processoDeDistribuicaoOuProducao: string;
  forcasDaMatrizSWOT: string;
  fraquezasDaMatrizSWOT: string;
  oportunidadesDaMatrizSWOT: string;
  ameacasDaMatrizSWOT: string;
  principaisCompetidores: PrincipalFornecedor[];
  distribuicaoDeReceitaLiquidaPorRegiaoOuPais: DistribuicaoDeReceitaLiquidaPorRegiaoOuPais[];
  publicoAlvo: string;
  focoGeografico: string;
  tamanhoDoMercadoPotencial: string;
  explicacaoDoCalculoDoTamanhoDoMercadoPotencial: string;
  concentracaoDoMercadoDeAtuacao: number;
  maturidadeDoMercadoDeAtuacao: number;
  concentracaoDosPrincipaisFornecedores: number;
  principaisRiscos: PrincipaisRiscos[];
  riscosDeExecucao: string;
  riscoCambial: string;
  riscoFiscal: string;
  riscoTecnologico: string;
  riscosDeAlavancagem: string;
  riscoRegulatorio: string;
  explicacaoDoEbitdaAjustado: string;
  indicadoresFinanceirosAuditados: boolean | string;
  dataReferenciaDosIndicadoresFinanceiros: string | null;
  indicadoresFinanceiros: any;
  indicadoresFinanceirosColunas: (number | undefined)[];
  balancoPatrimonialAuditado: boolean | string;
  dataReferenciaDoBalancoPatrimonial: string | null;
  balancoPatrimonial: any;
  balancoPatrimonialColunas: (number | undefined)[];
  valuationPreMoney: number;
  dataDeReferenciaDoValuationPreMoney: string | null;
  valorDaEmpresaOuEbitda: number;
  caixaLiquidoEInvestimentosFinanceiros: number;
  valorDaEmpresaAoPrecoDaOferta: number;
  relacaoPrecoLucroAjustada: number;
  capitalizacaoDeMercadoAoPrecoDaOferta: number;
  relacaoPrecoLucro: number;
  comentarioDaEmpresaSobreSuaAvaliacao: string;
  qualSeraADestinacaoDosRecursosDaOferta: string;
  isReadOnly: boolean;
};
