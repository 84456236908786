import React, { useEffect, useState } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import {
  dateToTimestamp,
  getDate,
  timestampToDate,
} from '../../utils/dateHandling';
import { Button } from '../../components/button';
import { Section } from '../../components/section';
import { Controller } from '../../components/controller';
import { useAuth } from '../../hooks/auth';
import RenderSkeletonOrContent from '../../components/renderSkeletonOrContent';
import { Skeleton } from '../../components/skeleton';
import { Grid } from '../../components/grid';
import { Container } from '../../components/container';
import {
  FormContent,
  InnerTitle,
  Margin,
  ShareholdersDiv,
  DeleteButtons,
  DeleteButton,
} from '../dadosGerais/styles';
import { useEnterprise } from '../../hooks/enterprise';
import {
  listCompanyType,
  listCountries,
  listStates,
  listYesNo,
} from '../../utils/fields';
import {
  listAdressType,
  listControllerMajority,
  listControllerType,
  schema,
} from '../dadosGerais/fields';
import { obterEnderecoAsync } from '../../services/cep';
import { toBoolean } from '../../services/toBoolean';
import {
  ButtonsWrapp,
  DividedGrid,
  HistoryTitle,
  PageDescription,
  PageDescription2,
  RadioDescription,
  RadioLabel2,
  RadioTitle,
  Version,
  VersionChangeContainer,
  VersionContainer,
  VersionContent,
  VersionControl,
  VersionText,
} from './styles';
import useAtualizacaoCadastral from './hooks/atualizacoesCadastrais';
import { AtualizacaoCadastralForm, DocumentosRequest } from './fields';
import { DocumentAtualizacaoCadastral } from '../../components/documents/components/documentAtualizacaoCadastral';

export const AtualizacoesCadastrais = () => {
  const { currentRole } = useAuth();
  const { currentEnterpriseId } = useEnterprise();

  const [historicoId, setHistoricoId] = useState('');
  const [atualizado, setAtualizado] = useState<boolean>(false);
  const [requestedDocs, setRequestedDocs] = useState<DocumentosRequest[]>([]);
  const [lastData, setLastData] = useState(true);

  const {
    atualizacaoCadastral,
    historicoAtualizacao,
    getAtualizacaoCadastral,
    submitDados,
    sendToBee4,
    loading2,
    loading1,
  } = useAtualizacaoCadastral({
    currentEnterpriseId,
    idHistorico: historicoId,
    atualizado,
  });

  const { handleSubmit, control, reset, watch, setValue, getValues } =
    useForm<AtualizacaoCadastralForm>({
      resolver: yupResolver(schema),
    });

  const country = watch('paisSede');
  const outrosInput = watch('tipoDeEmpresaControladoraMajoritaria');
  const tipoDeCompanhiaControladora = watch('tipoDeCompanhiaControladora');
  const responsavelJuridico = watch('assessoriaJuridica.responsavelJuridico');

  const handleEndereco1 = async (cep: string) => {
    if (!cep || cep?.length !== 8) {
      return null;
    }
    if (cep.length === 8) {
      const { endereco, bairro, cidade, uf } = await obterEnderecoAsync(cep);

      setValue('rua', endereco);
      setValue('bairro', bairro);
      setValue('municipio', cidade);
      setValue('estado', uf);
    }
    return null;
  };

  const handleEndereco2 = async (cep: string) => {
    if (!cep || cep?.length !== 8) {
      return null;
    }
    if (cep.length === 8) {
      const { endereco, bairro, cidade, uf } = await obterEnderecoAsync(cep);

      setValue('executivoResponsavelPeloRegistro.rua', endereco);
      setValue('executivoResponsavelPeloRegistro.bairro', bairro);
      setValue('executivoResponsavelPeloRegistro.municipio', cidade);
      setValue('executivoResponsavelPeloRegistro.estado', uf);
    }
    return null;
  };

  const handleEndereco3 = async (cep: string) => {
    if (!cep || cep?.length !== 8) {
      return null;
    }
    if (cep.length === 8) {
      const { endereco, bairro, cidade, uf } = await obterEnderecoAsync(cep);

      setValue('assessoriaJuridica.rua', endereco);
      setValue('assessoriaJuridica.bairro', bairro);
      setValue('assessoriaJuridica.municipio', cidade);
      setValue('assessoriaJuridica.estado', uf);
    }
    return null;
  };

  const resetValues = () => {
    if (!atualizacaoCadastral) {
      return;
    }
    reset({
      nomeFantasia: atualizacaoCadastral?.nomeFantasia,
      razaoSocial: atualizacaoCadastral?.razaoSocial,
      cnpj: atualizacaoCadastral?.cnpj,
      tipo: atualizacaoCadastral?.tipo,
      numeroDeFuncionarios: atualizacaoCadastral?.numeroDeFuncionarios,
      setorDeAtuacao: atualizacaoCadastral?.setorDeAtuacao,
      modeloDeVenda: atualizacaoCadastral?.modeloDeVenda,
      breveHistorico: atualizacaoCadastral?.breveHistorico,
      comoFuncionaOModeloDeNegocio:
        atualizacaoCadastral?.comoFuncionaOModeloDeNegocio,
      descricaoDasAtividades: atualizacaoCadastral?.descricaoDasAtividades,
      diferenciaisDoEmissor: atualizacaoCadastral?.diferenciaisDoEmissor,
      linhasDeReceita: atualizacaoCadastral?.linhasDeReceita,
      principaisFornecedores: atualizacaoCadastral?.principaisFornecedores,
      dataDaConstituicao: timestampToDate(
        atualizacaoCadastral?.dataDaConstituicao,
      ),
      dataDeEncerramentoDoExercicioSocial: timestampToDate(
        atualizacaoCadastral?.dataDeEncerramentoDoExercicioSocial,
      ),
      dataDoPedidoDeRegistroNaBEE4: timestampToDate(
        atualizacaoCadastral?.dataDoPedidoDeRegistroNaBEE4,
      ),
      website: atualizacaoCadastral?.website,
      urlDoFacebook: atualizacaoCadastral?.urlDoFacebook,
      urlDoYouTube: atualizacaoCadastral?.urlDoYouTube,
      urlDoLinkedIn: atualizacaoCadastral?.urlDoLinkedIn,
      urlDoInstagram: atualizacaoCadastral?.urlDoInstagram,
      urlDoTwitter: atualizacaoCadastral?.urlDoTwitter,
      principalRedeSocial: atualizacaoCadastral?.principalRedeSocial,
      paisSede: atualizacaoCadastral?.paisSede,
      cep: atualizacaoCadastral?.cep,
      rua: atualizacaoCadastral?.rua,
      numero: atualizacaoCadastral?.numero,
      complemento: atualizacaoCadastral?.complemento,
      bairro: atualizacaoCadastral?.bairro,
      municipio: atualizacaoCadastral?.municipio,
      estado: atualizacaoCadastral?.estado,
      tipoDeEndereco: atualizacaoCadastral?.tipoDeEndereco,
      telefone: atualizacaoCadastral?.telefone,
      executivoResponsavelPeloRegistro: {
        ...atualizacaoCadastral?.executivoResponsavelPeloRegistro,
        responsavelPontoDeContatoDaEmpresa:
          atualizacaoCadastral?.executivoResponsavelPeloRegistro?.responsavelPontoDeContatoDaEmpresa?.toString(),
        dateDeInicioNaEmpresa: timestampToDate(
          atualizacaoCadastral?.executivoResponsavelPeloRegistro
            ?.dateDeInicioNaEmpresa,
        ),
      },
      nomeDoCEO: atualizacaoCadastral?.nomeDoCEO,
      cpfDoCEO: atualizacaoCadastral?.cpfDoCEO,
      desdeQuandoOCEOOcupaOCargo: timestampToDate(
        atualizacaoCadastral?.desdeQuandoOCEOOcupaOCargo,
      ),
      linkedInDoCEO: atualizacaoCadastral?.linkedInDoCEO,
      miniCVDoCEO: atualizacaoCadastral?.miniCVDoCEO,
      nomeDoPresidenteDoConselho:
        atualizacaoCadastral?.nomeDoPresidenteDoConselho,
      cpfDoPresidenteDoConselho:
        atualizacaoCadastral?.cpfDoPresidenteDoConselho,
      desdeQuandoOPresidenteDoConselhoOcupaOCargo: timestampToDate(
        atualizacaoCadastral?.desdeQuandoOPresidenteDoConselhoOcupaOCargo,
      ),
      linkedInDoPresidenteDoConselho:
        atualizacaoCadastral?.linkedInDoPresidenteDoConselho,
      miniCVDoPresidenteDoConselho:
        atualizacaoCadastral?.miniCVDoPresidenteDoConselho,
      membrosDoConselho: atualizacaoCadastral?.membrosDoConselho,
      numeroDeMembrosIndependentesDoConselho:
        atualizacaoCadastral?.numeroDeMembrosIndependentesDoConselho,
      periodicidadeDasReunioesDoConselho:
        atualizacaoCadastral?.periodicidadeDasReunioesDoConselho,
      responsabilidadeEObrigacoesDoConselho:
        atualizacaoCadastral?.responsabilidadeEObrigacoesDoConselho,
      umOuMaisMembrosTemPercentualNaCompanhia:
        atualizacaoCadastral?.umOuMaisMembrosTemPercentualNaCompanhia?.toString(),
      nomeDaCompanhiaControladora:
        atualizacaoCadastral?.nomeDaCompanhiaControladora,
      tipoDeCompanhiaControladora:
        atualizacaoCadastral?.tipoDeCompanhiaControladora,
      percentualDaControladora: atualizacaoCadastral?.percentualDaControladora,
      tipoDeEmpresaControladoraMajoritaria:
        atualizacaoCadastral?.tipoDeEmpresaControladoraMajoritaria,
      tipoDeEmpresaControladoraMajoritariaDetalhes:
        atualizacaoCadastral?.tipoDeEmpresaControladoraMajoritariaDetalhes,
      acionistas: atualizacaoCadastral?.acionistas,
      ultimaAlteracaoDoAcordoDeAcionistas: timestampToDate(
        atualizacaoCadastral?.ultimaAlteracaoDoAcordoDeAcionistas,
      ),
      osPrincipaisExecutivosSaoSocios:
        atualizacaoCadastral?.osPrincipaisExecutivosSaoSocios?.toString(),
      possuiProgramaDePartnershipOuStockOptions:
        atualizacaoCadastral?.possuiProgramaDePartnershipOuStockOptions?.toString(),
      dadosBancariosDaEmpresa: atualizacaoCadastral?.dadosBancariosDaEmpresa,
      empresaConsultorListagem: {
        ...atualizacaoCadastral?.empresaConsultorListagem,
        inicioDoContratoDePrestacaoDeServico: timestampToDate(
          atualizacaoCadastral?.empresaConsultorListagem
            ?.inicioDoContratoDePrestacaoDeServico,
        ),
      },
      empresaEscrituradora: {
        ...atualizacaoCadastral?.empresaEscrituradora,
        inicioDoContratoDePrestacaoDeServico: timestampToDate(
          atualizacaoCadastral?.empresaEscrituradora
            ?.inicioDoContratoDePrestacaoDeServico,
        ),
      },
      auditoria: {
        ...atualizacaoCadastral?.auditoria,
        inicioDoContratoDePrestacaoDeServico: timestampToDate(
          atualizacaoCadastral?.auditoria?.inicioDoContratoDePrestacaoDeServico,
        ),
        registroNaCvm: timestampToDate(
          atualizacaoCadastral?.auditoria?.registroNaCvm,
        ),
      },
      categorias: atualizacaoCadastral?.categorias,
      assessoriaJuridica: atualizacaoCadastral?.assessoriaJuridica,
      assessoriaJuridicaDadosContatoFinanceiro:
        atualizacaoCadastral?.assessoriaJuridicaDadosContatoFinanceiro,
      assessoriaJuridicaDiretorDeDivulgacoesDeInformacoesAoMercado:
        atualizacaoCadastral?.assessoriaJuridicaDiretorDeDivulgacoesDeInformacoesAoMercado,
    });
  };

  useEffect(() => {
    resetValues();
  }, [atualizacaoCadastral]);

  useEffect(() => {
    if (tipoDeCompanhiaControladora !== 2) {
      setValue('tipoDeEmpresaControladoraMajoritaria', '');
    }
  }, [tipoDeCompanhiaControladora]);

  useEffect(() => {
    if (outrosInput !== 'Outros')
      setValue('tipoDeEmpresaControladoraMajoritariaDetalhes', '');
  }, [outrosInput]);

  useEffect(() => {
    if (responsavelJuridico === 1) {
      setValue('assessoriaJuridica.razaoSocial', '');
      setValue('assessoriaJuridica.cnpj', '');
      setValue('assessoriaJuridica.inicioDeContratoDePrestacaoDeServico', '');
      setValue('assessoriaJuridica.tipoDeEndereco', null as any);
      setValue('assessoriaJuridica.cep', '');
      setValue('assessoriaJuridica.rua', '');
      setValue('assessoriaJuridica.numero', '');
      setValue('assessoriaJuridica.complemento', '');
      setValue('assessoriaJuridica.bairro', '');
      setValue('assessoriaJuridica.municipio', '');
      setValue('assessoriaJuridica.estado', '');
      setValue('assessoriaJuridica.telefone', '');
    }
  }, [responsavelJuridico]);

  const isRightRole =
    ['Empreendedor', 'TECHTERCEIRO'].includes(currentRole) && lastData;
  const [isDisabled, setIsDisabled] = useState(true);

  const [sentDisable, setSentDisable] = useState(false);

  const submitForm = (data: AtualizacaoCadastralForm) => {
    setIsDisabled(true);

    const payload: any = {
      currentEnterpriseId,
      form: {
        ...data,
        dataDaConstituicao: dateToTimestamp(data?.dataDaConstituicao),
        dataDeEncerramentoDoExercicioSocial: dateToTimestamp(
          data?.dataDeEncerramentoDoExercicioSocial,
        ),
        dataDoPedidoDeRegistroNaBEE4: dateToTimestamp(
          data?.dataDoPedidoDeRegistroNaBEE4,
        ),
        executivoResponsavelPeloRegistro: {
          ...data?.executivoResponsavelPeloRegistro,
          responsavelPontoDeContatoDaEmpresa: toBoolean(
            data?.executivoResponsavelPeloRegistro
              .responsavelPontoDeContatoDaEmpresa,
          ),
          dateDeInicioNaEmpresa: dateToTimestamp(
            data?.executivoResponsavelPeloRegistro.dateDeInicioNaEmpresa,
          ),
        },
        desdeQuandoOCEOOcupaOCargo: dateToTimestamp(
          data?.desdeQuandoOCEOOcupaOCargo,
        ),
        desdeQuandoOPresidenteDoConselhoOcupaOCargo: dateToTimestamp(
          data?.desdeQuandoOPresidenteDoConselhoOcupaOCargo,
        ),
        ultimaAlteracaoDoAcordoDeAcionistas: dateToTimestamp(
          data?.ultimaAlteracaoDoAcordoDeAcionistas,
        ),
        empresaConsultorListagem: {
          ...data?.empresaConsultorListagem,
          inicioDoContratoDePrestacaoDeServico: dateToTimestamp(
            data?.empresaConsultorListagem.inicioDoContratoDePrestacaoDeServico,
          ),
        },
        empresaEscrituradora: {
          ...data?.empresaEscrituradora,
          inicioDoContratoDePrestacaoDeServico: dateToTimestamp(
            data?.empresaEscrituradora.inicioDoContratoDePrestacaoDeServico,
          ),
        },
        auditoria: {
          ...data?.auditoria,
          inicioDoContratoDePrestacaoDeServico: dateToTimestamp(
            data?.auditoria.inicioDoContratoDePrestacaoDeServico,
          ),
          registroNaCvm: dateToTimestamp(data?.auditoria.registroNaCvm),
        },
        documentosRequest: requestedDocs,
      },
    };

    setRequestedDocs([]);
    submitDados(payload);
  };

  const assessorType = [
    {
      label: (
        <div>
          <RadioTitle>Interno</RadioTitle>
          <RadioDescription>Pertence a empresa</RadioDescription>
        </div>
      ),
      value: 1,
    },
    {
      label: (
        <div>
          <RadioTitle>Externo</RadioTitle>
          <RadioDescription>Não pertence a empresa</RadioDescription>
        </div>
      ),
      value: 2,
    },
  ];

  const handleDeleteDoc = (docId: string, categoryId: string) => {
    const categories = getValues('categorias');
    categories.filter(category => {
      if (category.id === categoryId) {
        const documents = category.documentos?.filter(
          docs => docs.id !== docId,
        );

        const cat = category;
        cat.documentos = documents;
        return { cat };
      }
      return category;
    });
    setValue('categorias', categories);
  };

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'acionistas',
  });

  const handleShareholders = () => (
    <ShareholdersDiv>
      <Grid container spacing={4}>
        <Grid item desktop={6}>
          <Grid container spacing={3}>
            <Grid item desktop={12}>
              <h4>Nome</h4>
            </Grid>
            {fields.map((item, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Grid item desktop={12} key={index}>
                <Controller
                  name={`acionistas.${index}.nome`}
                  control={control}
                  type="input"
                  label=""
                  disabled={isDisabled}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item desktop={6}>
          <Grid container spacing={3}>
            <Grid item desktop={12}>
              <h4>Participação</h4>
            </Grid>
            {fields.map((item, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Grid item desktop={12} key={index}>
                <Controller
                  name={`acionistas.${index}.participacao`}
                  control={control}
                  type="input"
                  label=""
                  mask="percentage"
                  disabled={isDisabled}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>

      <DeleteButtons>
        {fields.map((item, index) =>
          fields.length > 1 ? (
            <DeleteButton
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              type="button"
              disabled={isDisabled}
              onClick={() => {
                if (fields.length > 1) {
                  remove(index);
                }
              }}
            >
              <DeleteIcon htmlColor="#212E51" />
            </DeleteButton>
          ) : null,
        )}
      </DeleteButtons>
    </ShareholdersDiv>
  );

  const handleJuridico = () => {
    if ([1, 2].includes(responsavelJuridico))
      return (
        <>
          {responsavelJuridico === 2 && (
            <>
              <Grid item desktop={6}>
                <Controller
                  name="assessoriaJuridica.razaoSocial"
                  control={control}
                  type="input"
                  label="Razão social"
                  disabled={isDisabled}
                />
              </Grid>
              <Grid item desktop={3}>
                <Controller
                  name="assessoriaJuridica.cnpj"
                  control={control}
                  type="input"
                  label="CNPJ"
                  mask="cnpj"
                  disabled={isDisabled}
                />
              </Grid>
              <Grid item desktop={3}>
                <Controller
                  name="assessoriaJuridica.inicioDeContratoDePrestacaoDeServico"
                  control={control}
                  type="input"
                  label="Início da prestação de serviço"
                  disabled={isDisabled}
                />
              </Grid>
              <Grid item desktop={3}>
                <Controller
                  name="assessoriaJuridica.tipoDeEndereco"
                  control={control}
                  type="select"
                  label="Tipo de endereço"
                  options={listAdressType}
                  disabled={isDisabled}
                />
              </Grid>
              <Grid item desktop={3}>
                <Controller
                  name="assessoriaJuridica.cep"
                  control={control}
                  type="input"
                  mask="cep"
                  label="CEP"
                  onBlur={() =>
                    handleEndereco3(getValues('assessoriaJuridica.cep'))
                  }
                  disabled={isDisabled}
                />
              </Grid>
              <Grid item desktop={3}>
                <Controller
                  name="assessoriaJuridica.rua"
                  control={control}
                  type="input"
                  label="Endereço"
                  disabled={isDisabled}
                />
              </Grid>
              <Grid item desktop={3}>
                <DividedGrid>
                  <Controller
                    name="assessoriaJuridica.numero"
                    control={control}
                    type="input"
                    label="Número"
                    disabled={isDisabled}
                  />
                  <Controller
                    name="assessoriaJuridica.complemento"
                    control={control}
                    type="input"
                    label="Complemento"
                    disabled={isDisabled}
                  />
                </DividedGrid>
              </Grid>
              <Grid item desktop={3}>
                <Controller
                  name="assessoriaJuridica.bairro"
                  control={control}
                  type="input"
                  label="Bairro"
                  disabled={isDisabled}
                />
              </Grid>
              <Grid item desktop={3}>
                <Controller
                  name="assessoriaJuridica.municipio"
                  control={control}
                  type="input"
                  label="Município"
                  disabled={isDisabled}
                />
              </Grid>
              <Grid item desktop={3}>
                <Controller
                  name="assessoriaJuridica.estado"
                  control={control}
                  type="input"
                  label="Estado"
                  disabled={isDisabled}
                />
              </Grid>
              <Grid item desktop={3}>
                <Controller
                  name="assessoriaJuridica.telefone"
                  control={control}
                  type="input"
                  label="Telefone da empresa"
                  disabled={isDisabled}
                  mask="phone"
                />
              </Grid>{' '}
            </>
          )}
          <Grid item desktop={6}>
            <Controller
              name="assessoriaJuridica.nomeDoResponsavel"
              control={control}
              type="input"
              label="Nome do responsável"
              disabled={isDisabled}
            />
          </Grid>
          {/* <Grid item desktop={3}>
            <Controller
              name="assessoriaJuridica.cpfDoResponsavel"
              control={control}
              type="input"
              label="CPF do responsável"
              disabled={isDisabled}
              mask="cpf"
            />
          </Grid> */}
          <Grid item desktop={3} />
          <Grid item desktop={3}>
            <Controller
              name="assessoriaJuridica.telefoneDoResponsavel"
              control={control}
              type="input"
              label="Telefone do responsável"
              disabled={isDisabled}
              mask="phone"
            />
          </Grid>
          <Grid item desktop={3}>
            <Controller
              name="assessoriaJuridica.emailDoResponsavel"
              control={control}
              type="input"
              label="E-mail do responsável"
              disabled={isDisabled}
            />
          </Grid>
        </>
      );
    return <></>;
  };

  return (
    <>
      <PageDescription>
        Caso você deseje atualizar seus dados, modifique a versão atual do
        formulário. Somente será criada uma nova versão quando clicar no botão
        &quot;Enviar para a BEE4&quot;. Consulte seu histórico ao final do
        formulário.
      </PageDescription>
      <VersionControl>
        <VersionContent>
          <VersionText>Versão:</VersionText>
          <Version>{getDate(atualizacaoCadastral?.dataDaInclusao)}</Version>
        </VersionContent>
        {isRightRole && (
          <Button
            width="93px"
            color="primary"
            height="44px"
            type="button"
            disabled={!isRightRole}
            onClick={() => {
              setAtualizado(current => !current);
              getAtualizacaoCadastral();
              setIsDisabled(current => !current);
            }}
          >
            {isDisabled ? 'Editar' : 'Cancelar'}
          </Button>
        )}
      </VersionControl>
      <FormContent onSubmit={handleSubmit(submitForm)}>
        <Section>
          <RenderSkeletonOrContent
            isLoading={loading1 || loading2}
            content={
              <Container title="Dados gerais">
                <Grid container spacing={4}>
                  <Grid item desktop={6}>
                    <Controller
                      name="razaoSocial"
                      control={control}
                      type="input"
                      label="Razão social"
                      disabled
                    />
                  </Grid>
                  <Grid item desktop={6}>
                    <Controller
                      name="nomeFantasia"
                      control={control}
                      type="input"
                      label="Nome fantasia"
                      disabled={isDisabled}
                    />
                  </Grid>
                  <Grid item desktop={6}>
                    <Controller
                      name="cnpj"
                      control={control}
                      type="input"
                      mask="cnpj"
                      label="CNPJ"
                      placeholder="Emitido no site da Receita Federal"
                      disabled
                    />
                  </Grid>
                  <Grid item desktop={6}>
                    <Controller
                      name="website"
                      control={control}
                      type="input"
                      label="Site"
                      disabled={isDisabled}
                    />
                  </Grid>
                  <Grid item desktop={3}>
                    <Controller
                      name="dataDeEncerramentoDoExercicioSocial"
                      control={control}
                      type="input"
                      label="Data de encerramento do exercício"
                      mask="date"
                      placeholder="dd/mm/aaaa"
                      disabled={isDisabled}
                    />
                  </Grid>
                  <Grid item desktop={3}>
                    <Controller
                      name="dataDoPedidoDeRegistroNaBEE4"
                      control={control}
                      type="input"
                      label="Data do pedido do registro da BEE4"
                      mask="date"
                      placeholder="dd/mm/aaaa"
                      disabled
                    />
                  </Grid>
                  <Grid item desktop={3}>
                    <Controller
                      name="dataDaConstituicao"
                      control={control}
                      type="input"
                      label="Data da constituição"
                      mask="date"
                      placeholder="dd/mm/aaaa"
                      disabled={isDisabled}
                    />
                  </Grid>
                  <Grid item desktop={3}>
                    <Controller
                      name="paisSede"
                      control={control}
                      type="autocomplete"
                      label="País de sede"
                      autocompleteOptions={listCountries}
                      disabled={isDisabled}
                    />
                  </Grid>
                  <Grid item desktop={3}>
                    <Controller
                      name="cep"
                      control={control}
                      type="input"
                      label="CEP"
                      mask="cep"
                      onBlur={() => handleEndereco1(getValues('cep'))}
                      disabled={isDisabled}
                    />
                  </Grid>
                  <Grid item desktop={3}>
                    <Controller
                      name="rua"
                      control={control}
                      type="input"
                      label="Endereço"
                      disabled={isDisabled}
                    />
                  </Grid>
                  <Grid item desktop={3}>
                    <Grid container spacing={4}>
                      <Grid item desktop={6}>
                        <Controller
                          name="numero"
                          control={control}
                          type="input"
                          label="Número"
                          disabled={isDisabled}
                        />
                      </Grid>
                      <Grid item desktop={6}>
                        <Controller
                          name="complemento"
                          control={control}
                          type="input"
                          label="Complemento"
                          disabled={isDisabled}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item desktop={3}>
                    <Controller
                      name="bairro"
                      control={control}
                      type="input"
                      label="Bairro"
                      disabled={isDisabled}
                    />
                  </Grid>
                  <Grid item desktop={3}>
                    <Controller
                      name="municipio"
                      control={control}
                      type="input"
                      label="Município"
                      disabled={isDisabled}
                    />
                  </Grid>
                  <Grid item desktop={3}>
                    {country === 'Brasil' ? (
                      <Controller
                        name="estado"
                        control={control}
                        type="select"
                        label="Estado"
                        disabled={isDisabled}
                        options={listStates}
                      />
                    ) : (
                      <Controller
                        name="estado"
                        control={control}
                        type="input"
                        label="Estado"
                        disabled={isDisabled}
                      />
                    )}
                  </Grid>
                  <Grid item desktop={3}>
                    <Controller
                      name="tipoDeEndereco"
                      control={control}
                      type="select"
                      label="Tipo de endereço"
                      options={listAdressType}
                      disabled={isDisabled}
                    />
                  </Grid>
                  <Grid item desktop={3}>
                    <Controller
                      name="telefone"
                      control={control}
                      type="input"
                      label="Telefone"
                      mask="phone"
                      disabled={isDisabled}
                    />
                  </Grid>
                  <Grid item desktop={6}>
                    <Grid container spacing={4}>
                      <Grid item desktop={6}>
                        <Controller
                          name="tipo"
                          control={control}
                          type="radio"
                          label="Tipo"
                          options={listCompanyType}
                          row
                          disabled
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Container>
            }
            fallback={
              <Container>
                <Skeleton width="100%" height="62px" variant="text" />
                <Skeleton width="100%" height="62px" variant="text" />
                <Skeleton width="100%" height="168px" variant="text" />
                <Skeleton width="100%" height="168px" variant="text" />
                <Skeleton width="100%" height="62px" variant="text" />
                <Skeleton width="100%" height="62px" variant="text" />
                <Skeleton width="100%" height="62px" variant="text" />
              </Container>
            }
          />
        </Section>
        <Section>
          <RenderSkeletonOrContent
            isLoading={loading1 || loading2}
            content={
              <Container title="Informações do management e governança">
                <Grid container spacing={6}>
                  <Grid item desktop={6}>
                    <Controller
                      name="nomeDoCEO"
                      control={control}
                      type="input"
                      label="Nome completo do CEO"
                      disabled={isDisabled}
                    />
                  </Grid>
                  <Grid item desktop={6}>
                    <Controller
                      name="desdeQuandoOCEOOcupaOCargo"
                      control={control}
                      type="input"
                      label="Desde quando ocupa o cargo"
                      mask="date"
                      placeholder="dd/mm/aaaa"
                      disabled={isDisabled}
                    />
                  </Grid>
                  <Grid item desktop={6}>
                    <Controller
                      name="linkedInDoCEO"
                      control={control}
                      type="input"
                      label="Link do LinkedIn"
                      disabled={isDisabled}
                    />
                  </Grid>
                  <Grid item desktop={6}>
                    <Controller
                      name="nomeDoPresidenteDoConselho"
                      control={control}
                      type="input"
                      label="Nome completo do Pres. do conselho"
                      disabled={isDisabled}
                    />
                  </Grid>
                  <Grid item desktop={6}>
                    <Controller
                      name="desdeQuandoOPresidenteDoConselhoOcupaOCargo"
                      control={control}
                      type="input"
                      label="Desde quando ocupa o cargo"
                      mask="date"
                      placeholder="dd/mm/aaaa"
                      disabled={isDisabled}
                    />
                  </Grid>
                  <Grid item desktop={6}>
                    <Controller
                      name="linkedInDoPresidenteDoConselho"
                      control={control}
                      type="input"
                      label="Link do LinkedIn"
                      disabled={isDisabled}
                    />
                  </Grid>
                </Grid>

                <InnerTitle>Membros do conselho</InnerTitle>
                <Grid container spacing={4}>
                  <Grid item desktop={6}>
                    <Grid container spacing={3}>
                      <Grid item desktop={12}>
                        <h4>Nome</h4>
                      </Grid>
                      <Grid item desktop={12}>
                        <Controller
                          name="membrosDoConselho.0.nome"
                          control={control}
                          type="input"
                          label=""
                          disabled={isDisabled}
                        />
                      </Grid>
                      <Grid item desktop={12}>
                        <Controller
                          name="membrosDoConselho.1.nome"
                          control={control}
                          type="input"
                          label=""
                          disabled={isDisabled}
                        />
                      </Grid>
                      <Grid item desktop={12}>
                        <Controller
                          name="membrosDoConselho.2.nome"
                          control={control}
                          type="input"
                          label=""
                          disabled={isDisabled}
                        />
                      </Grid>
                      <Grid item desktop={12}>
                        <Controller
                          name="membrosDoConselho.3.nome"
                          control={control}
                          type="input"
                          label=""
                          disabled={isDisabled}
                        />
                      </Grid>
                      <Grid item desktop={12}>
                        <Controller
                          name="membrosDoConselho.4.nome"
                          control={control}
                          type="input"
                          label=""
                          disabled={isDisabled}
                        />
                      </Grid>
                      <Grid item desktop={12}>
                        <Controller
                          name="membrosDoConselho.5.nome"
                          control={control}
                          type="input"
                          label=""
                          disabled={isDisabled}
                        />
                      </Grid>
                      <Grid item desktop={12}>
                        <Controller
                          name="membrosDoConselho.6.nome"
                          control={control}
                          type="input"
                          label=""
                          disabled={isDisabled}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item desktop={6}>
                    <Grid container spacing={3}>
                      <Grid item desktop={12}>
                        <h4>Qualificação</h4>
                      </Grid>
                      <Grid item desktop={12}>
                        <Controller
                          name="membrosDoConselho.0.qualificacao"
                          control={control}
                          type="input"
                          label=""
                          disabled={isDisabled}
                        />
                      </Grid>
                      <Grid item desktop={12}>
                        <Controller
                          name="membrosDoConselho.1.qualificacao"
                          control={control}
                          type="input"
                          label=""
                          disabled={isDisabled}
                        />
                      </Grid>
                      <Grid item desktop={12}>
                        <Controller
                          name="membrosDoConselho.2.qualificacao"
                          control={control}
                          type="input"
                          label=""
                          disabled={isDisabled}
                        />
                      </Grid>
                      <Grid item desktop={12}>
                        <Controller
                          name="membrosDoConselho.3.qualificacao"
                          control={control}
                          type="input"
                          label=""
                          disabled={isDisabled}
                        />
                      </Grid>
                      <Grid item desktop={12}>
                        <Controller
                          name="membrosDoConselho.4.qualificacao"
                          control={control}
                          type="input"
                          label=""
                          disabled={isDisabled}
                        />
                      </Grid>
                      <Grid item desktop={12}>
                        <Controller
                          name="membrosDoConselho.5.qualificacao"
                          control={control}
                          type="input"
                          label=""
                          disabled={isDisabled}
                        />
                      </Grid>
                      <Grid item desktop={12}>
                        <Controller
                          name="membrosDoConselho.6.qualificacao"
                          control={control}
                          type="input"
                          label=""
                          disabled={isDisabled}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Margin />
                <Grid container spacing={4}>
                  <Grid item desktop={6}>
                    <Grid container spacing={7}>
                      <Grid item desktop={6}>
                        <Controller
                          name="numeroDeMembrosIndependentesDoConselho"
                          control={control}
                          type="input"
                          mask="integer"
                          label="Número de membros independentes"
                          disabled={isDisabled}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Container>
            }
            fallback={
              <div>
                <Container>
                  <Skeleton width="100%" height="62px" variant="text" />
                  <Skeleton width="100%" height="62px" variant="text" />
                  <Skeleton width="100%" height="62px" variant="text" />
                  <Skeleton width="100%" height="62px" variant="text" />
                </Container>
              </div>
            }
          />
        </Section>
        <Section>
          <RenderSkeletonOrContent
            isLoading={loading1 || loading2}
            content={
              <Container title="Societário">
                <Grid container spacing={4}>
                  <Grid item desktop={3}>
                    <Controller
                      name="tipoDeCompanhiaControladora"
                      control={control}
                      type="select"
                      label="Tipo de Controle"
                      options={listControllerType}
                      disabled={isDisabled}
                    />
                  </Grid>
                  <Grid item desktop={3}>
                    <Controller
                      name="ultimaAlteracaoDoAcordoDeAcionistas"
                      control={control}
                      type="input"
                      label="Última alteração do acordo de acionistas"
                      mask="date"
                      placeholder="dd/mm/aaaa"
                      disabled={isDisabled}
                    />
                  </Grid>
                  <Grid item desktop={3}>
                    <Controller
                      name="possuiProgramaDePartnershipOuStockOptions"
                      control={control}
                      type="select"
                      label="Esse programa possui partnership?"
                      options={listYesNo}
                      disabled={isDisabled}
                    />
                  </Grid>
                  <Grid item desktop={3}>
                    <Controller
                      name="tipoDeEmpresaControladoraMajoritaria"
                      control={control}
                      type="select"
                      label="Tipo da empresa controladora majoritária"
                      options={listControllerMajority}
                      disabled={isDisabled || tipoDeCompanhiaControladora !== 2}
                    />
                  </Grid>
                  {outrosInput === 'Outros' ? (
                    <>
                      <Grid item desktop={9} />
                      <Grid item desktop={3}>
                        <Controller
                          name="tipoDeEmpresaControladoraMajoritariaDetalhes"
                          control={control}
                          type="input"
                          label="Outros"
                          disabled={
                            isDisabled || tipoDeCompanhiaControladora !== 2
                          }
                        />
                      </Grid>
                    </>
                  ) : null}
                </Grid>
                <InnerTitle>Principais Acionistas</InnerTitle>
                {handleShareholders()}
                <Button
                  onClick={() => {
                    append({ nome: '', participacao: 0 });
                  }}
                  width="12rem"
                  height="2.2rem"
                  type="button"
                  disabled={isDisabled}
                >
                  + ADICIONAR ACIONISTA CONTROLADOR
                </Button>
              </Container>
            }
            fallback={
              <Container>
                <Skeleton width="100%" height="62px" variant="text" />
                <Skeleton width="100%" height="62px" variant="text" />
                <Skeleton width="100%" height="62px" variant="text" />
                <Skeleton width="100%" height="62px" variant="text" />
              </Container>
            }
          />
        </Section>
        <Section>
          <RenderSkeletonOrContent
            isLoading={loading1 || loading2}
            content={
              <Container
                title="Assessoria jurídica"
                button={
                  !isDisabled && (
                    <Button
                      width="170px"
                      height="40px"
                      type="submit"
                      disabled={isDisabled}
                    >
                      SALVAR DADOS
                    </Button>
                  )
                }
              >
                <Grid container spacing={4}>
                  <Grid item desktop={6}>
                    <Controller
                      name="assessoriaJuridica.responsavelJuridico"
                      control={control}
                      type="radio"
                      label={
                        <RadioLabel2>
                          O <strong>Responsável jurídico</strong> é:
                        </RadioLabel2>
                      }
                      options={assessorType}
                      row
                      disabled={isDisabled}
                    />
                  </Grid>
                  <Grid item desktop={6} />
                  {handleJuridico()}
                </Grid>
              </Container>
            }
            fallback={
              <Container>
                <Skeleton width="100%" height="62px" variant="text" />
                <Skeleton width="100%" height="62px" variant="text" />
                <Skeleton width="100%" height="62px" variant="text" />
              </Container>
            }
          />
        </Section>
        {/* <Section>
          <RenderSkeletonOrContent
            isLoading={loading1 || loading2}
            content={
              <Container
                title="Responsável financeiro"
                button={
                  !isDisabled && (
                    <Button
                      width="170px"
                      height="40px"
                      type="submit"
                      disabled={isDisabled}
                    >
                      SALVAR DADOS
                    </Button>
                  )
                }
              >
                <Grid container spacing={4}>
                  <Grid item desktop={6}>
                    <Controller
                      name="assessoriaJuridicaDadosContatoFinanceiro.nome"
                      control={control}
                      type="input"
                      label="Nome responsável"
                      disabled={isDisabled}
                    />
                  </Grid>
                  <Grid item desktop={3}>
                    <Controller
                      name="assessoriaJuridicaDadosContatoFinanceiro.telefone"
                      control={control}
                      type="input"
                      label="Telefone do responsável"
                      mask="phone"
                      disabled={isDisabled}
                    />
                  </Grid>
                  <Grid item desktop={3}>
                    <Controller
                      name="assessoriaJuridicaDadosContatoFinanceiro.email"
                      control={control}
                      type="input"
                      label="E-mail do responsável"
                      disabled={isDisabled}
                    />
                  </Grid>
                </Grid>
              </Container>
            }
            fallback={
              <Container>
                <Skeleton width="100%" height="62px" variant="text" />
                <Skeleton width="100%" height="62px" variant="text" />
                <Skeleton width="100%" height="62px" variant="text" />
              </Container>
            }
          />
        </Section> */}
        {/* <Section>
          <RenderSkeletonOrContent
            isLoading={loading1 || loading2}
            content={
              <Container
                title="Diretor(a) de divulgações de Informações de Mercado"
                button={
                  !isDisabled && (
                    <Button
                      width="170px"
                      height="40px"
                      type="submit"
                      disabled={isDisabled}
                    >
                      SALVAR DADOS
                    </Button>
                  )
                }
              >
                <Grid container spacing={4}>
                  <Grid item desktop={6}>
                    <Controller
                      name="assessoriaJuridicaDiretorDeDivulgacoesDeInformacoesAoMercado.nome"
                      control={control}
                      type="input"
                      label="Nome responsável"
                      disabled={isDisabled}
                    />
                  </Grid>
                  <Grid item desktop={3}>
                    <Controller
                      name="assessoriaJuridicaDiretorDeDivulgacoesDeInformacoesAoMercado.telefone"
                      control={control}
                      type="input"
                      label="Telefone do responsável"
                      mask="phone"
                      disabled={isDisabled}
                    />
                  </Grid>
                  <Grid item desktop={3}>
                    <Controller
                      name="assessoriaJuridicaDiretorDeDivulgacoesDeInformacoesAoMercado.email"
                      control={control}
                      type="input"
                      label="E-mail do responsável"
                      disabled={isDisabled}
                    />
                  </Grid>
                  <Grid item desktop={6}>
                    <Controller
                      name="assessoriaJuridicaDiretorDeDivulgacoesDeInformacoesAoMercado.posicaoNaEmpresa"
                      control={control}
                      type="input"
                      label="Posição na empresa"
                      disabled={isDisabled}
                    />
                  </Grid>
                  <Grid item desktop={6} />
                </Grid>
              </Container>
            }
            fallback={
              <Container>
                <Skeleton width="100%" height="62px" variant="text" />
                <Skeleton width="100%" height="62px" variant="text" />
                <Skeleton width="100%" height="62px" variant="text" />
              </Container>
            }
          />
        </Section> */}
        <Section>
          <RenderSkeletonOrContent
            isLoading={loading1 || loading2}
            content={
              <Container title="Consultor de listagem credenciado na BEE4">
                <Grid container spacing={4}>
                  <Grid item desktop={6}>
                    <Controller
                      name="empresaConsultorListagem.razaoSocial"
                      control={control}
                      type="input"
                      label="Razão social"
                      disabled
                    />
                  </Grid>
                  <Grid item desktop={6}>
                    <Controller
                      name="empresaConsultorListagem.inicioDoContratoDePrestacaoDeServico"
                      control={control}
                      disabled
                      type="input"
                      label="Início do contrato de prestação de serviço"
                      mask="date"
                      placeholder="dd/mm/aaaa"
                    />
                  </Grid>
                  <Grid item desktop={6}>
                    <Controller
                      name="empresaConsultorListagem.nomeDoResponsavelTecnico"
                      control={control}
                      type="input"
                      label="Nome do responsável técnico"
                      disabled
                    />
                  </Grid>
                  <Grid item desktop={3}>
                    <Controller
                      name="empresaConsultorListagem.cpf"
                      control={control}
                      type="input"
                      label="CPF"
                      mask="cpf"
                      disabled
                    />
                  </Grid>
                  <Grid item desktop={3}>
                    <Controller
                      name="empresaConsultorListagem.cnpj"
                      control={control}
                      type="input"
                      label="CNPJ"
                      mask="cnpj"
                      disabled
                    />
                  </Grid>
                  <Grid item desktop={6}>
                    <Controller
                      name="empresaConsultorListagem.telefone"
                      control={control}
                      type="input"
                      label="Telefone"
                      mask="phone"
                      disabled
                    />
                  </Grid>
                  <Grid item desktop={6}>
                    <Controller
                      name="empresaConsultorListagem.email"
                      control={control}
                      type="input"
                      label="E-mail"
                      disabled
                    />
                  </Grid>
                </Grid>
              </Container>
            }
            fallback={
              <Container>
                <Skeleton width="100%" height="62px" variant="text" />
                <Skeleton width="100%" height="62px" variant="text" />
                <Skeleton width="100%" height="62px" variant="text" />
              </Container>
            }
          />
        </Section>
        <Section>
          <RenderSkeletonOrContent
            isLoading={loading1 || loading2}
            content={
              <Container title="Escriturador registrado na CVM e credenciado na BEE4">
                <Grid container spacing={4}>
                  <Grid item desktop={6}>
                    <Controller
                      name="empresaEscrituradora.razaoSocial"
                      control={control}
                      type="input"
                      label="Razão social"
                      disabled
                    />
                  </Grid>
                  <Grid item desktop={6}>
                    <Controller
                      name="empresaEscrituradora.inicioDoContratoDePrestacaoDeServico"
                      control={control}
                      type="input"
                      label="Início do contrato de prestação de serviço"
                      mask="date"
                      placeholder="dd/mm/aaaa"
                      disabled
                    />
                  </Grid>
                  <Grid item desktop={6}>
                    <Controller
                      name="empresaEscrituradora.nomeDoResponsavelTecnico"
                      control={control}
                      type="input"
                      label="Nome do responsável técnico"
                      disabled
                    />
                  </Grid>
                  <Grid item desktop={3}>
                    <Controller
                      name="empresaEscrituradora.cpf"
                      control={control}
                      type="input"
                      label="CPF"
                      mask="cpf"
                      disabled
                    />
                  </Grid>
                  <Grid item desktop={3}>
                    <Controller
                      name="empresaEscrituradora.cnpj"
                      control={control}
                      type="input"
                      label="CNPJ"
                      mask="cnpj"
                      disabled
                    />
                  </Grid>
                  <Grid item desktop={6}>
                    <Controller
                      name="empresaEscrituradora.telefone"
                      control={control}
                      type="input"
                      label="Telefone"
                      mask="phone"
                      disabled
                    />
                  </Grid>
                  <Grid item desktop={6}>
                    <Controller
                      name="empresaEscrituradora.email"
                      control={control}
                      type="input"
                      label="E-mail"
                      disabled
                    />
                  </Grid>
                </Grid>
              </Container>
            }
            fallback={
              <Container>
                <Skeleton width="100%" height="62px" variant="text" />
                <Skeleton width="100%" height="62px" variant="text" />
                <Skeleton width="100%" height="62px" variant="text" />
              </Container>
            }
          />
        </Section>

        <Section>
          <RenderSkeletonOrContent
            isLoading={loading1 || loading2}
            content={
              <Container title="Auditor independente credenciado na CVM">
                <Grid container spacing={4}>
                  <Grid item desktop={6}>
                    <Controller
                      name="auditoria.razaoSocial"
                      control={control}
                      type="input"
                      label="Razão social"
                      disabled
                    />
                  </Grid>
                  <Grid item desktop={3}>
                    <Controller
                      name="auditoria.cnpj"
                      control={control}
                      type="input"
                      label="CNPJ"
                      mask="cnpj"
                      disabled
                    />
                  </Grid>
                  <Grid item desktop={3}>
                    <Controller
                      name="auditoria.cep"
                      control={control}
                      type="input"
                      mask="cep"
                      label="CEP"
                      disabled
                    />
                  </Grid>
                  <Grid item desktop={6}>
                    <Controller
                      name="auditoria.rua"
                      control={control}
                      type="input"
                      label="Rua"
                      disabled
                    />
                  </Grid>
                  <Grid item desktop={6}>
                    <Controller
                      name="auditoria.bairro"
                      control={control}
                      type="input"
                      label="Bairro"
                      disabled
                    />
                  </Grid>
                  <Grid item desktop={3}>
                    <Controller
                      name="auditoria.cidade"
                      control={control}
                      type="input"
                      label="Município"
                      disabled
                    />
                  </Grid>
                  <Grid item desktop={3}>
                    <Controller
                      name="auditoria.uf"
                      control={control}
                      type="input"
                      label="Estado"
                      disabled
                    />
                  </Grid>
                  <Grid item desktop={3}>
                    <Controller
                      name="auditoria.tipoDeEndereco"
                      control={control}
                      type="select"
                      label="Tipo de Endereco"
                      options={listAdressType}
                      disabled
                    />
                  </Grid>
                  <Grid item desktop={3}>
                    <Controller
                      name="auditoria.registroNaCvm"
                      control={control}
                      type="input"
                      label="Data de registro na CVM"
                      mask="date"
                      disabled
                    />
                  </Grid>

                  <Grid item desktop={6}>
                    <Controller
                      name="auditoria.inicioDoContratoDePrestacaoDeServico"
                      control={control}
                      type="input"
                      label="Data de início do contrato de prestação de serviço"
                      mask="date"
                      disabled
                    />
                  </Grid>
                  <Grid item desktop={6}>
                    <Controller
                      name="auditoria.nomeDoResponsavelTecnico"
                      control={control}
                      type="input"
                      label="Nome do responsável"
                      disabled
                    />
                  </Grid>
                  <Grid item desktop={3}>
                    <Controller
                      name="auditoria.cpfDoResponsavel"
                      control={control}
                      type="input"
                      label="CPF do responsável"
                      mask="cpf"
                      disabled
                    />
                  </Grid>
                  <Grid item desktop={3}>
                    <Controller
                      name="auditoria.telefoneDoResponsavelTecnico"
                      control={control}
                      type="input"
                      label="Telefone do responsável"
                      mask="phone"
                      disabled
                    />
                  </Grid>
                  <Grid item desktop={6}>
                    <Controller
                      name="auditoria.emailDoResponsavelTecnico"
                      control={control}
                      type="input"
                      label="E-mail do responsável"
                      disabled
                    />
                  </Grid>
                </Grid>
              </Container>
            }
            fallback={
              <Container>
                <Skeleton width="100%" height="62px" variant="text" />
                <Skeleton width="100%" height="62px" variant="text" />
                <Skeleton width="100%" height="62px" variant="text" />
              </Container>
            }
          />
        </Section>

        <Section>
          <RenderSkeletonOrContent
            isLoading={loading1 || loading2}
            content={
              <Container title="Executivo responsável pelo pedido de registro">
                <Grid container spacing={4}>
                  <Grid item desktop={6}>
                    <Controller
                      name="executivoResponsavelPeloRegistro.nome"
                      control={control}
                      type="input"
                      label="Nome completo"
                      disabled={isDisabled}
                    />
                  </Grid>
                  <Grid item desktop={6}>
                    <Controller
                      name="executivoResponsavelPeloRegistro.cpf"
                      control={control}
                      type="input"
                      label="CPF"
                      mask="cpf"
                      disabled={isDisabled}
                    />
                  </Grid>
                  <Grid item desktop={3}>
                    <Controller
                      name="executivoResponsavelPeloRegistro.cep"
                      control={control}
                      type="input"
                      label="CEP"
                      mask="cep"
                      onBlur={() =>
                        handleEndereco2(
                          getValues('executivoResponsavelPeloRegistro.cep'),
                        )
                      }
                      disabled={isDisabled}
                    />
                  </Grid>
                  <Grid item desktop={3}>
                    <Controller
                      name="executivoResponsavelPeloRegistro.rua"
                      control={control}
                      type="input"
                      label="Endereço"
                      disabled={isDisabled}
                    />
                  </Grid>
                  <Grid item desktop={3}>
                    <Grid container spacing={4}>
                      <Grid item desktop={6}>
                        <Controller
                          name="executivoResponsavelPeloRegistro.numero"
                          control={control}
                          type="input"
                          label="Número"
                          disabled={isDisabled}
                        />
                      </Grid>
                      <Grid item desktop={6}>
                        <Controller
                          name="executivoResponsavelPeloRegistro.complemento"
                          control={control}
                          type="input"
                          label="Complemento"
                          disabled={isDisabled}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item desktop={3}>
                    <Controller
                      name="executivoResponsavelPeloRegistro.bairro"
                      control={control}
                      type="input"
                      label="Bairro"
                      disabled={isDisabled}
                    />
                  </Grid>
                  <Grid item desktop={3}>
                    <Controller
                      name="executivoResponsavelPeloRegistro.municipio"
                      control={control}
                      type="input"
                      label="Município"
                      disabled={isDisabled}
                    />
                  </Grid>
                  <Grid item desktop={3}>
                    <Controller
                      name="executivoResponsavelPeloRegistro.estado"
                      control={control}
                      type="select"
                      label="Estado"
                      disabled={isDisabled}
                      options={listStates}
                    />
                  </Grid>
                  <Grid item desktop={3}>
                    <Controller
                      name="executivoResponsavelPeloRegistro.tipoDeEndereco"
                      control={control}
                      type="select"
                      label="Tipo de endereço"
                      options={listAdressType}
                      disabled={isDisabled}
                    />
                  </Grid>
                  <Grid item desktop={3}>
                    <Controller
                      name="executivoResponsavelPeloRegistro.dateDeInicioNaEmpresa"
                      control={control}
                      type="input"
                      label="Data de início na empresa emissora"
                      mask="date"
                      placeholder="dd/mm/aaaa"
                      disabled={isDisabled}
                    />
                  </Grid>
                  <Grid item desktop={6}>
                    <Controller
                      name="executivoResponsavelPeloRegistro.telefone"
                      control={control}
                      type="input"
                      label="Telefone"
                      mask="phone"
                      disabled={isDisabled}
                    />
                  </Grid>
                  <Grid item desktop={6}>
                    <Controller
                      name="executivoResponsavelPeloRegistro.email"
                      control={control}
                      type="input"
                      label="E-mail"
                      disabled={isDisabled}
                    />
                  </Grid>
                </Grid>
              </Container>
            }
            fallback={
              <Container>
                <Skeleton width="100%" height="62px" variant="text" />
                <Skeleton width="100%" height="62px" variant="text" />
                <Skeleton width="100%" height="62px" variant="text" />
                <Skeleton width="100%" height="62px" variant="text" />
              </Container>
            }
          />
        </Section>

        <Section>
          <DocumentAtualizacaoCadastral
            categories={atualizacaoCadastral?.categorias}
            readOnly={isDisabled}
            newDocs={requestedDocs}
            setNewDocs={e => {
              setRequestedDocs(currentDocs => [...currentDocs, e]);
            }}
            setRemoveDocs={e => {
              setRequestedDocs(currentDocs =>
                currentDocs.filter(doc => doc.base64Content !== e),
              );
            }}
            deleteDoc={handleDeleteDoc}
          />
        </Section>
        {!isDisabled && (
          <ButtonsWrapp>
            <Button
              width="170px"
              color="primary"
              height="40px"
              type="submit"
              disabled={isDisabled}
            >
              SALVAR
            </Button>
            <Button
              width="170px"
              height="40px"
              type="submit"
              onClick={() => {
                sendToBee4();
                setSentDisable(true);
              }}
              disabled={isDisabled || sentDisable}
            >
              Enviar para BEE4
            </Button>
          </ButtonsWrapp>
        )}
      </FormContent>
      <Section>
        <HistoryTitle>Histórico de versões</HistoryTitle>
        <PageDescription2>
          Aqui você visualiza os formulários que foram editados anteriormente.
        </PageDescription2>
        <VersionContainer>
          {historicoAtualizacao?.map((historico, index) => (
            <VersionChangeContainer>
              {getDate(historico.dataDaInclusao)}
              <VisibilityOutlinedIcon
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setAtualizado(false);
                  setHistoricoId(historico.id);
                  setLastData(index === 0);
                  setIsDisabled(true);
                  document
                    .getElementById('main')
                    ?.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                }}
              />
            </VersionChangeContainer>
          ))}
        </VersionContainer>
      </Section>
    </>
  );
};
