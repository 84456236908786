import React from 'react';

import { Modal } from '../../../../components/modal';
import { Button } from '../../../../components/button';
import { ModalContent } from './style';

interface IModalProps {
  description: boolean;
  closeDropdown: (e?: any) => void;
}
export const GenerateListagemModal: React.FC<IModalProps> = ({
  description,
  closeDropdown,
}) => {
  return (
    <Modal
      open={description}
      handleClose={closeDropdown}
      width={620}
      modalStyle={{ padding: '11rem 0 2rem 0' }}
    >
      <ModalContent>
        <h3>Orientações</h3>
        <p>Entenda cada status do documento.</p>
        <span>
          <span className="blue">Verificado</span>{' '}
          <strong className="gray">-</strong> Ao selecionar este status, você
          concorda que todas as informações estão corretas e o documento está
          pronto para ser submetido para análise da BEE4.
        </span>
        <span>
          <strong className="blue">Revisão</strong>{' '}
          <strong className="gray">-</strong> Ao selecionar este status, você
          solicita uma revisão do documento pelo emissor e consultor de listagem
          vinculado à empresa emissora.
          <strong className="gray">
            {' '}
            É importante sinalizar quais informações precisam ser revisadas para
            agilizar o processo.
          </strong>
        </span>
        <span>
          <strong className="black">
            Se o sistema não conseguir gerar o documento, significa que as
            informações estavam incompletas.
          </strong>
          <br />
          Nesses casos, há 2 opções:
        </span>
        <span>
          <strong className="blue">Finalizar documento</strong>{' '}
          <strong className="gray">-</strong> Ao selecionar este status, você
          estará solicitando ao emissor e consultor de listagem vinculado à
          empresa emissora que completem as informações faltantes no documento.
        </span>
        <span>
          <strong className="blue">Prazo excepcional</strong>{' '}
          <strong className="gray">-</strong> Ao selecionar este status, você
          solicita ao comitê de aprovação da BEE4 a postergação do prazo de
          entrega deste documento, lembrando que não poderá ultrapassar a data
          oficial da listagem da empresa no mercado BEE4.
          <strong className="gray">
            {' '}
            Nesses casos, o comitê da BEE4 analisará o motivo e poderá dar ou
            não a postergação solicitada.
          </strong>
        </span>
        <span>
          <strong>
            Se ainda estiver com dúvidas sobre os status, entre em contato com a
            área de Relacionamento com Participantes da BEE4 pelo e-mail{' '}
            <a
              href="mailto:emissores@bee4.com.br?subject=Contato BEE4"
              target="_blank"
              rel="noopener noreferrer"
              className="blue"
            >
              emissores@bee4.com.br
            </a>
          </strong>
        </span>
      </ModalContent>

      <Button
        variant="outlined"
        color="primary"
        onClick={closeDropdown}
        width="157px"
        height="40px"
        style={{ margin: 'auto', marginTop: '1.5rem' }}
      >
        VOLTAR
      </Button>
    </Modal>
  );
};
