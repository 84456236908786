export const textoIPO = (
  currentEnterpriseName: any,
  status: 1 | 2 | 3 | 4 | undefined,
) => {
  if (status === 1) {
    return [
      'Empresa aprovada',
      `Após deliberação do Comitê, foi decidido pela aprovação da empresa ${currentEnterpriseName} no processo para a listagem na BEE4.`,
      'A partir de agora, a empresa vai para a fase de captação. Porém, antes da listagem, é importante reunir toda a documentação e preencher formulários que possam ser solicitados. Apenas assim poderemos realizar a listagem na BEE4.',
    ];
  }
  if (status === 2) {
    return [
      'Empresa reprovada',
      `Após deliberação do Comitê, foi decidido pela reprovação da empresa ${currentEnterpriseName} no processo para a listagem na BEE4.`,
      'A partir de agora, é cabível recurso em até 30 dias. Caso queira entrar com recurso, entre em contato com a área de Relações com Participantes pelo e-mail emissores@bee4.com.br.',
    ];
  }
  if (status === 3) {
    return [
      'Empresa com exigências',
      `Após deliberação do Comitê, foi decidido que a empresa ${currentEnterpriseName} precisa cumprir as exigências para seguir com o processo de listagem na BEE4.`,
      'A partir de agora, é importante reunir e revisar toda a documentação pendente. Apenas assim poderemos realizar a listagem na BEE4.',
    ];
  }
  if (status === 4) {
    return [
      'Empresa aprovada com condicionantes',
      `Após deliberação do Comitê, foi decidido pela aprovação da empresa ${currentEnterpriseName} no processo para a listagem na BEE4, porém, com alguns condicionantes que ainda precisam ser solucionados.`,
      'A partir de agora, é importante reunir toda a documentação pendente e preencher todos os formulários da plataforma. Apenas assim poderemos realizar a listagem na BEE4.',
    ];
  }

  return '';
};

export const textoListagem = (
  currentEnterpriseName: any,
  status: 1 | 2 | 3 | 4 | undefined,
) => {
  if (status === 1) {
    return [
      'Empresa aprovada',
      `Após deliberação do Comitê, foi decidido pela aprovação da empresa ${currentEnterpriseName} no processo para a listagem na BEE4.`,
      'A partir de agora, a seção de pós-listagem fica habilitada para realizar atualizações cadastrais, envio de relatórios e notificações de fatos relevantes.',
    ];
  }
  if (status === 2) {
    return [
      'Empresa reprovada',
      `Após deliberação do Comitê, foi decidido pela reprovação da empresa ${currentEnterpriseName} no processo para a listagem na BEE4.`,
      'A partir de agora, é cabível recurso em até 30 dias. Caso queira entrar com recurso, entre em contato com a área de Relações com Participantes pelo e-mail emissores@bee4.com.br.',
    ];
  }
  if (status === 3) {
    return [
      'Empresa com exigências',
      `Após deliberação do Comitê, foi decidido que a empresa ${currentEnterpriseName} precisa cumprir as exigências para seguir com o processo de listagem na BEE4.`,
      'A partir de agora, é importante reunir e revisar toda a documentação pendente. Apenas assim poderemos realizar a listagem na BEE4.',
    ];
  }
  if (status === 4) {
    return [
      'Empresa aprovada com condicionantes',
      `Após deliberação do Comitê, foi decidido pela aprovação da empresa ${currentEnterpriseName} no processo para a listagem na BEE4, porém, com alguns condicionantes que ainda precisam ser solucionados.`,
      'A partir de agora, é importante reunir toda a documentação pendente e preencher todos os formulários da plataforma. Apenas assim poderemos realizar a listagem na BEE4.',
    ];
  }

  return '';
};
