import styled from 'styled-components';

type IStatusProps = {
  status: 1 | 2 | 3 | 4;
};

type ButtonContainerProps = {
  hasText?: boolean;
};

export const ButtonContainer = styled.div<ButtonContainerProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const CommitteeDecisionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-top: 2rem;
  border-top: 1px solid #8c8f9a;

  h3 {
    color: var(--Chumbo-1, #454b62);
    font-size: 22px;
    font-weight: 600;
  }
`;

export const CommitteeDecisionDescriptionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  p {
    color: #7d8ea3;
    font-size: 14px;
    font-weight: 400;
    line-height: 140%;
  }

  & > * {
    max-width: 715px;
  }
`;

export const SelectDecisionLabel = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  color: var(--Chumbo-2, #262936);
  font-size: 12px;
  font-weight: 400;
`;

export const StatusBall = styled.div<IStatusProps>`
  width: 12px;
  min-width: 12px;
  height: 12px;
  min-height: 12px;
  border-radius: 6px;
  background: ${({ status }) =>
    (status === 4 && '#FFB800') ||
    (status === 3 && '#ed7342') ||
    (status === 2 && '#ff6262') ||
    (status === 1 && '#0852DD')};
`;

export const ButtonContaner = styled.div`
  display: flex;
  gap: 0.5rem;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
  hr {
    width: 40%;
  }
  span {
    color: black;
    text-decoration: underline;
  }
`;

export const ButtonText = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

export const LabelCancelContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const RadioContent = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;
