import React from 'react';
import { TermoContainer, ErrorContainer } from './styles';
import useTermo from './hooks/useTermo';
import ObjectRenderer from '../../components/objectRenderer';

export const TermoCLColaborador = () => {
  const { termoAdesao } = useTermo({ termo: 3 });
  if (!termoAdesao) {
    return (
      <TermoContainer>
        <ErrorContainer>
          <p>
            Carregando o PDF do termo do consultor de listagem colaborador...
          </p>
        </ErrorContainer>
      </TermoContainer>
    );
  }

  return (
    <TermoContainer>
      <ObjectRenderer base64={termoAdesao} />
    </TermoContainer>
  );
};
