import styled, { css } from 'styled-components';

type ButtonContainerProps = {
  hasText?: boolean;
  justify?: 'center' | 'flex-end' | 'flex-start' | 'flex-between';
};

type WrapperProps = {
  center?: boolean;
};

export const DocListagemContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.2rem;
  padding-bottom: 10rem;
`;

export const ButtonContainer = styled.div<ButtonContainerProps>`
  display: flex;
  justify-content: ${({ justify }) => justify || 'center'};
`;

export const HistoricoDescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const HistoricoButtonContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

export const Wrapper = styled.div<WrapperProps>`
  width: 100%;

  ${({ center }) =>
    center
      ? css`
          display: flex;
          align-items: center;
          flex-direction: column;
          margin: 0 auto;

          .container {
            display: flex;
            margin-bottom: 1rem;
            width: 440px !important;
          }
        `
      : css`
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
        `}
`;
