import styled from 'styled-components';

export const TableHeader = styled.div`
  display: flex;
  gap: 2rem;
  width: 100%;
  cursor: default;
  align-items: center;
`;

export const TableLabel = styled.div`
  display: flex;
  gap: 1rem;
  alignitems: center;
  cursor: pointer;
  align-items: start;
`;

export const TableContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 1.5rem;

  > p {
    margin-bottom: 1rem;
  }
`;
