import styled from 'styled-components';

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  color: var(--chumbo-2, #262936);
  font-family: Inter;
  font-style: normal;
  padding: 1.5rem 0.5rem;

  h3 {
    font-size: 24px;
    font-weight: 700;
    line-height: normal;
  }

  p {
    color: var(--chumbo-1, #454b62);
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }

  span {
    color: var(--chumbo-1, #454b62);
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }

  .gray {
    color: var(--chumbo-1, #454b62);
    font-family: Inter;
    font-weight: 600;
    line-height: 24px;
  }

  .black {
    color: var(--chumbo-4, #14161d);
    font-family: Inter;
    font-weight: 600;
    line-height: 24px;
  }

  .blue {
    color: var(--azul-2, #2dbcf7);
    font-family: Inter;
    font-weight: 600;
    line-height: 24px;
  }
`;
