import * as yup from 'yup';
import { isValid } from '../../../../services/isValid';

export interface Empresas {
  id: string;
  nome?: string;
  empresaId?: string;
  contratacaoDaPrestacaoDoServico?: string;
}

export interface EmpresasDisponiveisNomad {
  empresaId: string;
  contratacaoDaPrestacaoDeServico: string;
}
interface EmpresasCollaborador {
  contratacaoDaPrestacaoDoServico?: string | null;
  empresaId?: string;
}

export interface Collaborator {
  id?: string;
  nome?: string;
  email?: string;
  razaoSocial?: string;
  cpf?: string;
  celular?: string;
  empresasConsultor?: EmpresasCollaborador[];
  statusDoConvite?: 0 | 1 | 2 | 4 | 5;
  ativo?: boolean;
}

export interface EmpresaEscolhida {
  id: string;
  email: string;
  bairro: string;
  cep: string;
  cidade: string;
  cnpj: string;
  emailDoResponsavel: string;
  cpfDoResponsavel?: string;
  nome: string;
  nomeDoResponsavel: string;
  razaoSocial: string;
  registroNaCvm: string;
  rua: string;
  telefone: string;
  tipoDeEndereco: number;
  uf: string;
  statusDoConvite?: 0 | 1 | 2 | 4 | 5;
  consultores: Collaborator[];
  nomeDaEmpresa?: string;
  empresas?: Empresas[];
  ativo?: boolean;
}

export interface EmpresaNomad {
  id: string;
  email: string;
  bairro: string;
  cep: string;
  cidade: string;
  cnpj: string;
  emailDoResponsavel: string;
  nome: string;
  nomeDoResponsavel: string;
  razaoSocial: string;
  registroNaCvm: string;
  rua: string;
  telefone: string;
  tipoDeEndereco: number;
  uf: string;
  consultores: Collaborator[];
  empresas?: EmpresasDisponiveisNomad[];
}

export interface EmpresaAuditora {
  id: string;
  email: string;
  bairro: string;
  cep: string;
  cidade: string;
  cnpj: string;
  emailDoResponsavel: string;
  nome: string;
  nomeDoResponsavel: string;
  razaoSocial: string;
  registroNaCvm: string;
  rua: string;
  telefone: string;
  tipoDeEndereco: number;
  uf: string;
  consultores: Collaborator[];
  empresas?: Empresas[];
}

export interface EmpresaEscrituradora {
  id: string;
  email: string;
  bairro: string;
  cep: string;
  cidade: string;
  cnpj: string;
  emailDoResponsavel: string;
  nome: string;
  nomeDoResponsavel: string;
  razaoSocial: string;
  registroNaCvm: string;
  rua: string;
  telefone: string;
  tipoDeEndereco: number;
  uf: string;
  consultores: Collaborator[];
  empresas?: Empresas[];
}

export const nomadSchema = yup.object().shape({
  nome: yup.string().required('Campo obrigatório'),
  emailDoResponsavel: yup.string().required('Campo obrigatório'),
  razaoSocial: yup.string().required('Campo obrigatório'),
  telefone: yup.string().required('Campo obrigatório'),
  cnpj: yup
    .string()
    .test('validateCNPJ', 'CNPJ inválido', cnpj => {
      return cnpj ? isValid.cnpj(cnpj) : true;
    })
    .required('Campo obrigatório'),
  cpfDoResponsavel: yup
    .string()
    .test('validadeCPF', 'CPF inválido', cpf => {
      return cpf ? isValid.cpf(cpf) : true;
    })
    .required('Campo obrigatório'),
  empresas: yup.array().of(
    yup.object().shape({
      empresaId: yup
        .string()
        .required(
          'Complete os dados de empresa ou clique na lixeira para não adicionar.',
        ),
      contratacaoDaPrestacaoDoServico: yup
        .string()
        .test('validateDate', 'Data inválida', data => {
          return isValid.date(data);
        })
        .required(
          'Complete os dados de empresa ou clique na lixeira para não adicionar.',
        ),
    }),
  ),
});

export const escrituradorSchema = yup.object().shape({
  nome: yup.string().required('Campo obrigatório'),
  emailDoResponsavel: yup.string().required('Campo obrigatório'),
  cpfDoResponsavel: yup
    .string()
    .test('validadeCPF', 'CPF inválido', cpf => {
      return cpf ? isValid.cpf(cpf) : true;
    })
    .required('Campo obrigatório'),
  razaoSocial: yup.string().required('Campo obrigatório'),
  cnpj: yup
    .string()
    .test('validateCNPJ', 'CNPJ inválido', cnpj => {
      return cnpj ? isValid.cnpj(cnpj) : true;
    })
    .required('Campo obrigatório'),
  telefone: yup.string().required('Campo obrigatório'),
  nomeDaEmpresa: yup.string().required('Campo obrigatório'),
  empresas: yup.array().of(
    yup.object().shape({
      empresaId: yup
        .string()
        .required(
          'Complete os dados de empresa ou clique na lixeira para não adicionar.',
        ),
      contratacaoDaPrestacaoDoServico: yup
        .string()
        .test('validateDate', 'Data inválida', data => {
          return isValid.date(data);
        })
        .required(
          'Complete os dados de empresa ou clique na lixeira para não adicionar.',
        ),
    }),
  ),
});

export const auditoriaSchema = yup.object().shape({
  emailDoResponsavel: yup.string().required('Campo obrigatório'),
  nomeDoResponsavel: yup.string().required('Campo obrigatório'),
  razaoSocial: yup.string().required('Campo obrigatório'),
  cnpj: yup
    .string()
    .test('validateCNPJ', 'CNPJ inválido', cnpj => {
      return cnpj ? isValid.cnpj(cnpj) : true;
    })
    .required('Campo obrigatório'),
  telefone: yup.string().required('Campo obrigatório'),
  cep: yup.string().required('Campo obrigatório'),
  rua: yup.string().required('Campo obrigatório'),
  bairro: yup.string().required('Campo obrigatório'),
  cidade: yup.string().required('Campo obrigatório'),
  uf: yup.string().required('Campo obrigatório'),
  tipoDeEndereco: yup.number().required('Campo obrigatório'),
  cpfDoResponsavel: yup
    .string()
    .test('validadeCPF', 'CPF inválido', cpf => {
      return cpf ? isValid.cpf(cpf) : true;
    })
    .required('Campo obrigatório'),
  registroNaCvm: yup
    .string()
    .required('Campo obrigatório')
    .test('validateDate', 'Data inválida', data => {
      return isValid.date(data);
    }),
  empresas: yup.array().of(
    yup.object().shape({
      empresaId: yup
        .string()
        .required(
          'Complete os dados de empresa ou clique na lixeira para não adicionar.',
        ),
      contratacaoDaPrestacaoDoServico: yup
        .string()
        .test('validateDate', 'Data inválida', data => {
          return isValid.date(data);
        })
        .required(
          'Complete os dados de empresa ou clique na lixeira para não adicionar.',
        ),
    }),
  ),
});

export const schemaColaborador = yup.object().shape({
  email: yup.string().required('Campo obrigatório'),
  nome: yup.string().required('Campo obrigatório'),
  cpf: yup
    .string()
    .test('validadeCPF', 'CPF inválido', cpf => {
      return cpf ? isValid.cpf(cpf) : true;
    })
    .required('Campo obrigatório'),
  celular: yup.string().required('Campo obrigatório'),
  empresas: yup.array().of(
    yup.object().shape({
      empresaId: yup.string().nullable(),
    }),
  ),
});

export const schemaColaboradorEscriturador = yup.object().shape({
  email: yup.string().required('Campo obrigatório'),
});
