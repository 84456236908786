import styled from 'styled-components';

export const FooterDiv = styled.footer`
  position: relative;
  width: 100%;
  background-color: #c3c6cf;
  font-family: Inter;
  font-weight: 300;
`;

export const Info = styled.div`
  height: 178px;
  padding: 22px 70px 25.55px;
  font-size: 11px;
`;

export const FirstLine = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const BEE4Logo = styled.img`
  width: 61.7px;
  height: 18.2px;
`;

export const Icons = styled.div`
  display: flex;
  gap: 96.45px;
`;

export const SocialMedia = styled.div`
  display: flex;
  gap: 31.46px;
`;

export const Contact = styled.div`
  display: flex;
  gap: 16.13px;
`;

export const SecondLine = styled.div`
  margin-top: 28.08px;
`;

export const ThirdLine = styled.div`
  margin-top: 28.08px;

  hr {
    margin-top: 6px;
    border: 1px solid #454b62;
  }
`;

export const Disclaimer = styled.div`
  background-color: #616678;
  font-size: 10px;
  padding: 25px 70px 21.14px;

  img {
    width: 74px;
    height: 30px;
  }

  div {
    display: flex;
    gap: 25.75px;
    margin-bottom: 10px;

    span {
      margin-top: auto;
      color: #d0d4e0;
    }

    a {
      color: #d0d4e0;
    }
  }

  & > span {
    position: relative;
    left: 379px;
    color: white;
  }
`;
