import React from 'react';
import { Button } from '../../../../components/button';
import { DownloadDocsContainer, DownloadDocsDescription } from './styles';

type DownloadDocsProps = {
  downloadDocsIpo: (e?: any) => void;
};
export const DownloadDocs = ({ downloadDocsIpo }: DownloadDocsProps) => {
  return (
    <DownloadDocsContainer>
      <DownloadDocsDescription>
        <h2>Baixar Documentos</h2>
        <p>Faça download dos documentos do Dossiê e envie para o Comitê.</p>
      </DownloadDocsDescription>
      <div>
        <Button
          color="secondary"
          height="40px"
          onClick={() => {
            downloadDocsIpo();
          }}
        >
          BAIXAR TODOS OS DOCUMENTOS
        </Button>
      </div>
    </DownloadDocsContainer>
  );
};
