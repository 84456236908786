import styled from 'styled-components';

interface TitleProps {
  marginBottom?: string;
}

export const ContainerDiv = styled.div`
  position: relative;
  background-color: white;
  padding: 40px 30px;
  border-radius: 4px;
`;

export const Title = styled.h2<TitleProps>`
  margin-bottom: ${({ marginBottom }) => marginBottom || '40px'};
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.54px;
  color: #51576c;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: end;
  margin-top: 40px;
`;
