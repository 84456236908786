import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  align-items: center;
  background-color: #c3c6cf33;
  color: #171725;
  cursor: pointer;
  border: none;
  outline: none;
  transition: background-color 0.6s ease;
  height: 48px;
  padding: 0 16px;
  width: 100%;

  img {
    height: 6px;
    width: 10px;
  }
`;

export const Title = styled.h3`
  font-weight: 700;
  font-size: 11px;
`;

export const Buttons = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 1rem;

  .deleteEditButton {
    margin-right: 1rem;
    border: none;
    background: none;
  }

  .deleteEditButton:hover {
    background: #cbcbcb 0% 0% no-repeat padding-box !important;
    opacity: 1;
    border-radius: 8px;
  }

  .plus {
    font-size: 1.3rem;
    margin-right: 0.4rem;
  }
`;

export const FirstSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  span {
    font-family: Inter;
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 0.17px;
    color: #101219;
  }
`;

export const IndexColumns = styled.div`
  width: 100%;
  color: #171725;
  text-align: left;
  text-transform: uppercase;
  letter-spacing: 1.2px;
  display: flex;
  font-weight: 600;
  font-size: 12px;
  height: 48px;
  padding: 0 16px;
  margin: auto;
`;

export const ContentColumns = styled.div`
  width: 100%;
  text-align: left;
  display: block;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.17px;
  margin: auto;

  & > span {
  }
`;

export const FirstColumn = styled.div`
  width: 60%;
  display: flex;
  align-items: center;
`;
export const SecondColumn = styled.div`
  width: 30%;
  display: flex;
  align-items: center;
`;

export const ThirdColumn = styled.div`
  width: 10%;
  display: flex;
  justify-content: end;
`;

export const TableLine = styled.hr`
  background-color: #f6f5fc;
  opacity: 0.1;
`;

export const TableRow = styled.div`
  display: flex;
  height: 48px;
  padding: 0 16px;
  font-weight: 400;
`;

export const ModalTitle = styled.h3`
  display: flex;
  justify-content: start;
  margin: 1rem 0 1.5rem 0;
`;
