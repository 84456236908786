import React from 'react';
import { IconButton, Menu, MenuItem } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { OutletPermify } from '../outletPermify';

export type KebabOption = {
  icon: any;
  onClick: () => void;
  name: string;
  roles?: string[];
};

interface IKebabProps {
  options: KebabOption[];
  onChange?: (e?: any) => void;
  onClose: (e?: any) => void;
  disabled?: boolean;
}

const ITEM_HEIGHT = 48;
export function Kebab({ options, onChange, onClose, disabled }: IKebabProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    onChange && onChange(true);
  };
  const handleClose = () => {
    setAnchorEl(null);
    onClose();
  };

  const handleChosenKebab = (item: any) => {
    setAnchorEl(null);
    item.onClick();
  };

  function kebabMenu() {
    return options.map(item => {
      return !!item.roles && item.roles.length > 0 ? (
        <OutletPermify role={item.roles}>
          <MenuItem key={item.name} onClick={() => handleChosenKebab(item)}>
            {item.icon}
            <span style={{ paddingLeft: '1rem', color: '#212E51' }}>
              {item.name}
            </span>
          </MenuItem>
        </OutletPermify>
      ) : (
        <MenuItem key={item.name} onClick={() => handleChosenKebab(item)}>
          {item.icon}
          <span style={{ paddingLeft: '1rem', color: '#212E51' }}>
            {item.name}
          </span>
        </MenuItem>
      );
    });
  }
  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls="long-menu"
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        disabled={disabled}
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '8.5rem',
          },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {kebabMenu()}
      </Menu>
    </div>
  );
}
