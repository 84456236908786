import React from 'react';
import { Modal as MaterialModal } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { ModalContent, CloseButton, ModalContainer } from './styles';
import { Button } from '../button';

interface ModalProps {
  handleClose: any;
  open: boolean;
  children: any;
  width?: number;
  height?: number;
  style?: any;
  modalStyle?: any;
}

export const Modal = (props: ModalProps) => {
  const { handleClose, open, children, width, height, style, modalStyle } =
    props;

  const modalStyleProps: React.CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    ...modalStyle,
  };

  return (
    <MaterialModal
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      style={modalStyleProps}
    >
      <ModalContainer width={width} height={height} style={style}>
        <CloseButton>
          <Button type="button" onClick={handleClose} variant="text">
            <CloseIcon
              sx={{ color: '#262936', width: '1rem', height: '1rem' }}
            />
          </Button>
        </CloseButton>
        <ModalContent>{children}</ModalContent>
      </ModalContainer>
    </MaterialModal>
  );
};
