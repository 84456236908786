import React from 'react';
import { Radio } from '@mui/material';
import { withStyles, createTheme, makeStyles } from '@material-ui/core/styles';
import { RadioContainer } from './styles';

const useStyles = makeStyles({
  customRadio: {
    color: '#131B30',
    '&$checked': {
      color: '#131B30',
    },
  },
  checked: {},
});

interface IOptions {
  label?: string;
  value: any;
}

interface IRadioProps {
  onChange: (e?: any) => void;
  label?: string | React.ReactNode;
  checked?: boolean;
  disabled?: boolean;
  canUncheck?: boolean;
}

export const SingleRadio: React.FC<IRadioProps> = ({
  onChange,
  label,
  checked = false,
  disabled = false,
  canUncheck = false,
}) => {
  const CustomRadio = withStyles(theme => ({
    root: {
      color: '#131B30',
      '&$checked': {
        color: '#131B30',
      },
      '&:disabled': {
        span: {
          color: '#A4A7B4',
        },
      },
    },
    checked: {},
  }))(props => (
    <Radio
      color="default"
      checked={checked}
      onChange={e => {
        onChange(e);
      }}
      onClick={() => {
        if (canUncheck && checked) {
          onChange(false);
        }
      }}
      disabled={disabled}
    />
  ));

  return (
    <RadioContainer disabled={disabled}>
      <CustomRadio />
      <p>{label}</p>
    </RadioContainer>
  );
};
