import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button } from '../../../components/button';
import { Controller } from '../../../components/controller';
import { Modal } from '../../../components/modal';
import { Buttons, DeleteButton, ModalContent, ModalTitle } from '../styles';
import { Indicadores } from '../fields';

interface IProps {
  description: boolean;
  closeDropdown: (e?: any) => void;
  size: number;
  type?: 1 | 2;
  field?: 1 | 2;
  submitField: (e: Indicadores[], field?: 1 | 2) => void;
  editField: (
    e: Indicadores[],
    chave?: string,
    type?: 1 | 2,
    field?: 1 | 2,
    isBefore?: boolean,
  ) => void;
  deleteField: (chave: string, type?: 1 | 2, field?: 1 | 2) => void;
  chosenInd?: any;
}

export const AddEditFieldModal: React.FC<IProps> = ({
  description,
  closeDropdown,
  size,
  type,
  field,
  submitField,
  chosenInd,
  editField,
  deleteField,
}) => {
  const { handleSubmit, control, reset, getValues } = useForm<Indicadores>({});

  const { ordem } = chosenInd;

  const handleSize = () => {
    let i = 1;
    const options = [];
    if (isEmpty(chosenInd))
      while (i <= size) {
        options.push({
          label: `${i}°`,
          value: i,
        });
        i += 1;
      }
    else {
      while (i <= size - 1) {
        options.push({
          label: `${i}°`,
          value: i,
        });
        i += 1;
      }
    }
    return options;
  };

  useEffect(() => {
    reset({
      label: chosenInd.label ?? '',
      tipoCampo: chosenInd.tipoCampo ?? undefined,
      ordem: chosenInd.ordem ?? undefined,
    });
  }, [reset, chosenInd]);

  function isEmpty(obj: any) {
    return Object.keys(obj).length === 0;
  }

  const submitForm = (v: Indicadores) => {
    const result = [];

    if (type === 1) {
      result.push({ ...v, referencia: 1 });
      result.push({ ...v, referencia: 2 });
      result.push({ ...v, referencia: 3 });
      result.push({ ...v, referencia: 4 });
      result.push({ ...v, referencia: 5 });
    } else if (type === 2) {
      result.push({ ...v, referencia: 6 });
      result.push({ ...v, referencia: 7 });
      result.push({ ...v, referencia: 8 });
      result.push({ ...v, referencia: 9 });
      result.push({ ...v, referencia: 10 });
    }
    if (isEmpty(chosenInd)) {
      submitField(result, field);
    } else {
      editField(result, chosenInd.chave, type, field, ordem < v.ordem);
    }

    closeDropdown();
  };

  return (
    <Modal open={description} handleClose={closeDropdown} width={600}>
      <ModalContent onSubmit={handleSubmit(submitForm)}>
        <ModalTitle>Insira a linha na tabela</ModalTitle>

        <div style={{ width: '100px', alignItems: 'right' }}>
          <Controller
            name="ordem"
            control={control}
            type="select"
            label="Posição que deve entrar na tabela"
            options={handleSize()}
          />
        </div>

        <Controller
          name="label"
          control={control}
          type="input"
          label="Nome da linha"
        />
        <Controller
          name="tipoCampo"
          control={control}
          type="radio"
          label="Tipo de cédula"
          options={[
            {
              label: 'Número',
              value: 1,
            },
            {
              label: 'Porcentagem',
              value: 2,
            },
          ]}
        />
        <Buttons>
          <Button
            variant="outlined"
            onClick={() => {
              closeDropdown();
            }}
          >
            Cancelar
          </Button>
          <Button type="submit">Confirmar</Button>
        </Buttons>
      </ModalContent>
      {!isEmpty(chosenInd) && (
        <DeleteButton>
          <Button
            variant="text"
            type="button"
            color="error"
            startIcon={<DeleteIcon />}
            onClick={() => {
              deleteField(chosenInd.chave, type, field);
              closeDropdown();
            }}
          >
            EXCLUIR LINHA
          </Button>
        </DeleteButton>
      )}
    </Modal>
  );
};
