import React, { useState } from 'react';
import { SingleRadio } from '../../../../../../components/singleRadio';
import { Modal } from '../../../../../../components/modal';
import { ButtonContent, ModalContent } from './styles';
import { Button } from '../../../../../../components/button';

interface IRadioProps {
  checked?: boolean;
  disabled?: boolean;
  confirmPublished: () => void;
}

export const Bee4Published: React.FC<IRadioProps> = ({
  checked = false,
  confirmPublished,
}) => {
  const [openModal, setOpenModal] = useState(false);
  return (
    <>
      <SingleRadio
        onChange={() => {
          setOpenModal(true);
        }}
        label="Fato Relevante publicado"
        checked={checked}
        disabled={checked}
      />
      <Modal
        open={openModal}
        handleClose={() => {
          setOpenModal(false);
        }}
        width={500}
      >
        <ModalContent>
          <h3>Documento publicado</h3>
          <span>
            Ao clicar nesta opção você confirma que o{' '}
            <strong>Fato relevante</strong> foi publicado
          </span>
          <ButtonContent>
            <Button
              variant="outlined"
              width="160px"
              height="40px"
              onClick={() => {
                setOpenModal(false);
              }}
            >
              VOLTAR
            </Button>
            <Button
              width="160px"
              height="40px"
              onClick={() => {
                confirmPublished();
                setOpenModal(false);
              }}
            >
              SIM, CONFIRMADO!
            </Button>
          </ButtonContent>
        </ModalContent>
      </Modal>
    </>
  );
};
