import styled, { css } from 'styled-components';

interface IButtonStyled {
  disabled?: boolean;
}

const StyledDisabled = css`
  color: rgba(0, 0, 0, 0.26);
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0.12);
`;

export const Container = styled.div<IButtonStyled>`
  display: flex;

  .MuiButton {
    &:disabled {
      background-image: none;
      background-color: #fff;
    }
  }
  .MuiButton-outlinedPrimary {
    background: linear-gradient(white, white) padding-box,
      linear-gradient(
          90deg,
          #49dbdf 0%,
          #219aee 22.16%,
          #0a4cdc 48.26%,
          #1e09d0 73.1%,
          #04037a 100%
        )
        border-box;
    border-width: 2px;
    border-style: solid;
    border-color: transparent;
    color: black;
    transition: border-width 0.2s ease-in-out;
    ${({ disabled }) => disabled && StyledDisabled}

    &:hover {
      border-width: 4px;
    }

    .uploadIcon {
      width: 1rem;
      margin-right: 0.6rem;
    }
  }
`;
