import React from 'react';
import { Link } from 'react-router-dom';
import { CircularStep, LinearStep, StatusContainer } from './styles';
import CircularStatic from '../progress/circular';
import LinearBuffer from '../progress/linear';

type DashboardProgressProps = {
  completed: number[];
  type?: 1 | 2;
  disabledEnd?: boolean;
};
type progressData = {
  name: string;
  link: string;
  rule: boolean;
  disabled1: boolean;
  disabled2: boolean;
  label: string;
  value1: number;
  value2: number;
  linear?: number;
}[];

export const DashboardProgress = ({
  completed,
  type = 1,
  disabledEnd = false,
}: DashboardProgressProps) => {
  const handleStatusRules = (index: number) => {
    return completed[index] === 2 ? 100 : completed[index] === 1 ? 50 : 0;
  };

  const ipoProgress: progressData = [
    {
      name: 'DOCUMENTAÇÃO',
      link: '/documentacao-ipo',
      rule: completed[0] > 1,
      disabled1: false,
      disabled2: completed[1] === 0,
      label: '1',
      value1: 100,
      value2: 50,
      linear: 100,
    },
    {
      name: 'GERAÇÃO DO DOSSIÊ',
      link: '/documentacao-ipo',
      rule: completed[1] > 0,
      disabled1: false,
      disabled2: completed[1] === 0,
      label: '2',
      value1: handleStatusRules(1),
      value2: handleStatusRules(1),
      linear: 100,
    },
    {
      name: 'VALIDAÇÃO',
      link: '/documentacao-ipo',
      rule: completed[2] > 0,
      disabled1: completed[2] === 0,
      disabled2: completed[2] === 0,
      label: '3',
      value1: handleStatusRules(2),
      value2: handleStatusRules(2),
      linear: undefined,
    },
    {
      name: 'ANÁLISE DO COMITÊ',
      link: '/documentacao-ipo',
      rule: completed[3] > 0,
      disabled1: completed[3] === 0,
      disabled2: completed[3] === 0,
      label: '4',
      value1: handleStatusRules(3),
      value2: handleStatusRules(3),
      linear: undefined,
    },
  ];

  const listagemProgress: progressData = [
    {
      name: 'COMPLETAR DOCUMENTOS',
      link: '/documentacao-listagem',
      rule: completed[0] > 1,
      disabled1: false || disabledEnd,
      disabled2: false || disabledEnd,
      label: '1',
      value1: 100,
      value2: 50,
      linear: 100,
    },
    {
      name: 'GERAR DOCUMENTOS',
      link: '/documentacao-listagem',
      rule: completed[1] > 0,
      disabled1: false || disabledEnd,
      disabled2: completed[1] === 0 || disabledEnd,
      label: '2',
      value1: handleStatusRules(1),
      value2: handleStatusRules(1),
      linear: 100,
    },
    {
      name: 'VALIDAÇÃO',
      link: '/documentacao-listagem',
      rule: completed[2] > 0,
      disabled1: completed[2] === 0 || disabledEnd,
      disabled2: completed[2] === 0 || disabledEnd,
      label: '3',
      value1: handleStatusRules(2),
      value2: handleStatusRules(2),
      linear: undefined,
    },
    {
      name: 'ANÁLISE DA BEE4',
      link: '/documentacao-listagem',
      rule: completed[3] > 0,
      disabled1: completed[3] === 0 || disabledEnd,
      disabled2: completed[3] === 0 || disabledEnd,
      label: '4',
      value1: handleStatusRules(3),
      value2: handleStatusRules(3),
      linear: undefined,
    },
  ];

  const progressData = type === 2 ? listagemProgress : ipoProgress;

  return (
    <StatusContainer>
      {progressData.map((data, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <React.Fragment key={index}>
          {data.rule ? (
            data.disabled1 && data.disabled2 ? (
              <CircularStep>
                <CircularStatic label={data.label} value={data.value1} />
                <span className="inside_text">{data.name}</span>
              </CircularStep>
            ) : (
              <Link to={data.link}>
                <CircularStep>
                  <CircularStatic label={data.label} value={data.value1} />
                  <span className="inside_text">{data.name}</span>
                </CircularStep>
              </Link>
            )
          ) : (
            <CircularStep>
              <CircularStatic
                label={data.label}
                value={data.value2}
                disabled={data.disabled2}
              />
              <span className="inside_text">{data.name}</span>
            </CircularStep>
          )}

          {progressData.length - 1 !== index && (
            <LinearStep>
              <LinearBuffer value={data.linear} />
            </LinearStep>
          )}
        </React.Fragment>
      ))}
    </StatusContainer>
  );
};
