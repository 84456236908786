import FileSaver from 'file-saver';

export function downloadZipFile(base64String: string, fileName: string) {
  // Converte a string base64 de volta para dados binários
  const binaryData = atob(base64String);
  const byteNumbers = new Array(binaryData.length);
  for (let i = 0; i < binaryData.length; i++) {
    byteNumbers[i] = binaryData.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);

  // Cria um Blob com os dados binários
  const blob = new Blob([byteArray], { type: 'application/zip' });

  // Faz o download do arquivo usando FileSaver
  FileSaver.saveAs(blob, fileName);
}
