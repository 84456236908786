import React, { useState } from 'react';
import { Pagination } from '@mui/material';
import { Modal } from '../../modal';
import { DocFileViewer } from '../../docViewer';
import {
  CategoryDocIPO,
  DocumentIPO,
} from '../../../pages/documentacaoIPO/fields';
import { ViewDocContent } from './styles';
import { useAuth } from '../../../hooks/auth';

interface IModalProps {
  description: boolean;
  closeDropdown: (e?: any) => void;
  category?: CategoryDocIPO;
}

export const ViewDocModal: React.FC<IModalProps> = ({
  description,
  closeDropdown,
  category,
}) => {
  const [page, setPage] = useState(1);
  const { currentRole } = useAuth();
  const canDownload = currentRole === 'BEE4';
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const handleFileViwer = (doc?: DocumentIPO) => {
    if (!doc) return <></>;

    const fileType = doc?.nome?.split('.')?.pop() as
      | 'jpg'
      | 'png'
      | 'xls'
      | 'xlsx'
      | 'csv'
      | 'pdf'
      | 'doc'
      | 'docx';

    return (
      <DocFileViewer
        url={doc.base64Content}
        fileType={fileType}
        fileName={doc?.nome || fileType}
        canDownload={canDownload}
      />
    );
  };
  if (!category || !category?.documentos) {
    return <></>;
  }
  return (
    <Modal open={description} handleClose={closeDropdown} width={1000}>
      <ViewDocContent>
        {handleFileViwer(category.documentos[page - 1])}
        {category?.documentos?.length > 0 && (
          <Pagination
            count={category?.documentos.length}
            page={page}
            onChange={handleChange}
            style={{ marginTop: canDownload ? '3rem' : '0' }}
          />
        )}
      </ViewDocContent>
    </Modal>
  );
};
