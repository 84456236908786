import React from 'react';
import { Menu } from '../menu';

type WrapperProps = {
  children: any;
  onlyTop?: boolean;
};

const Wrapper = ({ children, onlyTop }: WrapperProps) => {
  return <Menu onlyTop={onlyTop}>{children}</Menu>;
};

export default Wrapper;
