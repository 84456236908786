import { ReviewComment } from '../../pages/documentacaoIPO/fields';

export type ModalIPOTypes =
  | 'prazoModal'
  | 'revisionModal'
  | 'viewDocModal'
  | 'finishDocModal'
  | 'deleteCategoryModal'
  | 'uploadDocModal'
  | 'verifyDocModal'
  | 'prazoNegadoModal';

export interface StatusChangePayload {
  statusDaCategoria?: number;
  categoryId?: string;
  motivo?: string;
  comments?: ReviewComment[];
  documentoCategoriaId?: string;
}

export type ModalState = {
  [key in ModalIPOTypes]: boolean;
};

export const modalInitial: ModalState = {
  prazoModal: false,
  revisionModal: false,
  viewDocModal: false,
  finishDocModal: false,
  uploadDocModal: false,
  deleteCategoryModal: false,
  verifyDocModal: false,
  prazoNegadoModal: false,
};

export type UploadDocPayload = {
  nome?: string;
  base64Content?: any;
  categoriaId?: string;
};
