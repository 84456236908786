import styled from 'styled-components';

interface IListItemProps {
  active: boolean;
  subItemActive: boolean;
  disabled?: boolean;
}

interface IListHeaderProps {
  subItemActive: boolean;
}

interface IListTitleProps {
  listed: boolean;
}

interface IListSubItemProps {
  active: boolean;
  parentActive: boolean;
}

export const Header = styled.div`
  height: 68px;
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 53px 0 58px;
  box-shadow: 0px 1px 4px #15223214;
  position: relative;
  z-index: 7;

  picture img {
    // height: 33.88px;
    width: 155px;
    position: relative;
    // top: 2px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  height: calc(100% - 68px);
`;

export const User = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

export const Info = styled.div`
  display: flex;
  align-items: center;
  text-align: right;

  span {
    font: 400 14px 'Inter';
    color: #171725;
    margin-left: 10.5px;
  }

  .arrow {
    height: 5.4px;
    width: 8.78px;
    margin-left: 9.83px;
    transition: transform 0.3s ease-in-out;
  }

  .logo {
    height: 32px;
    width: 32px;
  }
  &:hover > .arrow {
    transform: rotate(180deg);
  }
`;

export const Avatar = styled.div`
  display: flex;
  align-items: center;

  img {
    border-radius: 50%;
  }
`;

export const UserOptions = styled.div`
  width: 387px;
  background-color: #fff;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.2);
  border-radius: 0 0 5px 5px;
  position: absolute;
  top: 68px;
  visibility: hidden;
  opacity: 0;
  transition: visibility 350ms, opacity 350ms ease-in-out;
  right: 15px;
  z-index: 10;

  hr {
    margin: 0 15px 0 15px;
    background-color: #bdbdbd;
    border-radius: 5px;
    border: 0;
    opacity: 0.7;
    border-top: 1px solid #bdbdbd;
  }

  a {
    color: black;
    font-size: 14px;
    font-weight: 500;
    text-decoration: none;

    li {
      display: flex;
      gap: 12px;
      align-items: center;
      display: flex;
      align-items: center;
      height: 40px;
      margin: 8px 0 8px 0;

      &:hover {
        background-color: #f2f2f2;
      }

      span {
        margin-left: 1rem;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        color: #14161d;
      }
    }
  }

  ${User}:hover & {
    opacity: 1;
    visibility: visible;
  }
`;

export const Global = styled.img`
  height: 20px;
  width: 20px;
  margin-left: 15px;
`;

export const Convite = styled.img`
  height: 14.25px;
  width: 19px;
  margin-left: 15.5px;
`;

export const Lamina = styled.img`
  height: 19px;
  width: 15.83px;
  margin-left: 17.085px;
`;

export const Settings = styled.img`
  height: 21px;
  width: 21px;
  margin-left: 14.5px;
`;

export const Sair = styled.img`
  height: 19.58px;
  width: 18.33px;
  margin-left: 15.835px;
`;

export const Sidebar = styled.div`
  min-width: 240px;
  height: 100%;
  background-color: #eeeff1;
  color: #171725;
`;

export const List = styled.ul`
  list-style-type: none;
  background: #ffffff;
  a {
    text-decoration: none;
  }
`;

export const ListItem = styled.li<IListItemProps>`
  font-weight: ${props => (props.active ? 700 : 500)};
  background-color: ${props => (props.disabled ? '#DDDDDD' : 'inherit')};
  cursor: pointer;
  &:hover {
    background: #f6f6f6;
  }
`;

export const ListTitle = styled.div<IListTitleProps>`
  position: relative;
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  display: flex;
  gap: 0.8rem;
  padding-left: 1.2rem;

  span {
    font-size: 14px;

    color: ${props => (props.listed ? '#171725' : '#bdbdbd')};
  }
`;

export const ListHeaderTitle = styled.div<IListTitleProps>`
  position: relative;
  height: 50px;
  width: 100%;
  display: flex;
  gap: 0.8rem;
  padding-left: 1.2rem;
  align-items: center;

  span {
    font-size: 14px;
    margin-right: 0.2rem;
    font-weight: 600;
    font-size: 14px;
    color: #454b62;
  }
  background: ${props => (props.listed ? '#f6f6f6' : 'inherit')};

  &:hover {
    background: #f6f6f6;
  }
`;

export const ListHeader = styled.li`
  display: flex;
  align-items: center;

  img {
    margin-left: 20px;
    height: 24px;
    width: 24px;
  }

  span {
    font-weight: 500;
    left: 20px;
    color: #8c8f9a;
    font-weight: 600;
    font-size: 12px;
    letter-spacing: 0.12em;
  }

  &:hover {
    background: inherit;
  }
`;

export const ListSubItems = styled.ul<IListTitleProps>`
  font-size: 14px;
  font-weight: 700;
  a {
    text-decoration: none;
  }
  position: relative;

  display: ${props => (props.listed ? 'block' : 'none !important')};
  ani

  
`;

export const ListHR = styled.hr<IListTitleProps>`
  position: absolute;
  left: 12%;
  top: 8%;
  border: 1px solid #d0d4e0;
  width: 3;
  height: 92%;
  display: ${props => (props.listed ? 'flex' : 'none !important')};
`;

export const ListSubItem = styled.li<IListTitleProps>`
  width: 100%;
  height: 50px;

  align-items: center;

  span {
    font-size: 14px;
    color: #454b62;
    font-weight: ${props => (props.listed ? 700 : 400)};
    margin-left: 64px;
  }

  &:hover {
    background-color: #a4a7b433;
  }
  display: flex;
  background-color: inherit;
  cursor: pointer;

  ${ListItem}:active & {
    display: flex;
  }

  a {
    color: inherit;
    text-decoration: none;
  }
`;

export const SelectContent = styled.div`
  width: 40%;
  max-width: 300px;
  margin-left: auto;
  margin-right: 2rem;
  background-color: white;
  border-radius: 4px;

  fieldset {
    border: none;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    color: #fff;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
      0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  }

  &:hover {
    fieldset {
      box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
        0px 4px 5px 0px rgba(0, 0, 0, 0.14),
        0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    }
  }
`;

export const Main = styled.div`
  overflow-y: scroll;
  width: 100%;
`;

export const MainContainer = styled.div`
  padding: 10px 40px 20px;
  min-height: calc(100vh - 68px - 92px);
  // 100vh - altura header - altura pageTitle
`;

export const DashboardButtonContainer = styled.li`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  background-color: #eeeff1;
  padding: 2rem 0 1rem 0;
`;

export const DashboardButton = styled.button`
  display: flex;
  align-items: center;
  padding: 8px 40px;
  gap: 0.5rem;
  background: #ffffff;
  border: 1px solid #0852dd;
  border-radius: 4px;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #0852dd;
  text-decoration: none;
`;

export const AvatarLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  border-radius: 50px;
  border: none;
  background: #454b62;
  color: white;
  text-align: center;
  width: 38px;
  height: 38px;
`;

export const AvatarLogoWithouName = styled.div`
  border-radius: 50px;
  border: none;
  background: #454b62;
  width: 38px;
  height: 38px;
`;

export const ButtonLinkMenu = styled.button`
  background: none;
  border: none;
  width: 100%;
  height: 40px;

  li {
    display: flex;
    align-items: center;
    gap: 12px;
    width: 100%;
    height: 100%;

    &:hover {
      background-color: #f2f2f2;
    }
  }

  .buttonSpan {
    margin-left: 1rem;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #14161d;
    font-family: 'Georama', 'Inter', 'Arial', sans-serif;
  }
`;
