/* eslint-disable no-case-declarations */
import React from 'react';
import { saveAs } from 'file-saver';
import FileViewer from 'react-file-viewer';
import { base64ToArrayBuffer, getMimeType } from '../objectRenderer/utils';
import { BEE4Button, NoSuportContainer, ViewerContainer } from './styles';
import { Button } from '../button';

interface FileViewerProps {
  url: string;
  fileType?: 'jpg' | 'png' | 'xls' | 'xlsx' | 'csv' | 'pdf' | 'doc' | 'docx';
  canDownload?: boolean;
  fileName: string;
  style?: any;
}

export const DocFileViewer: React.FC<FileViewerProps> = ({
  url,
  fileType,
  canDownload = false,
  fileName,
  style,
}) => {
  const mimeType = getMimeType(fileType ?? 'pdf');

  if (!mimeType) {
    console.error('Tipo de arquivo inválido');
    return null;
  }

  const fileArrayBuffer = base64ToArrayBuffer(url);

  if (!mimeType) {
    console.error('Tipo de arquivo inválido');
    return null;
  }

  const blob = new Blob([fileArrayBuffer], {
    type: mimeType,
  });

  const objectUrl = URL.createObjectURL(blob);

  const downloadFile = () => {
    saveAs(blob, fileName ?? `file.${fileType}`);
  };

  const renderViewer = () => {
    switch (fileType) {
      case 'pdf':
      case 'jpg':
      case 'png':
      case 'docx':
        return (
          <FileViewer
            fileType={fileType}
            filePath={objectUrl}
            errorComponent={<div>Erro ao carregar o arquivo.</div>}
            onError={console.error}
            style={{ width: '100%', height: '600px' }}
          />
        );
      case 'csv':
      case 'xlsx':
      case 'xls':
      case 'doc':
        return (
          <>
            <NoSuportContainer>
              <h3>
                Esse tipo de arquivo requer download, clique no botão
                &quot;Baixar&quot; para poder visualizá-lo
              </h3>
            </NoSuportContainer>
          </>
        );

      default:
        console.error('Tipo de arquivo não suportado');
        return null;
    }
  };

  return (
    <ViewerContainer style={style}>
      {renderViewer()}
      {(canDownload ||
        ['csv', 'xlsx', 'xls', 'doc'].includes(fileType ?? '')) && (
        <BEE4Button>
          <Button
            type="button"
            variant="outlined"
            color="secondary"
            onClick={downloadFile}
          >
            Baixar
          </Button>
        </BEE4Button>
      )}
    </ViewerContainer>
  );
};

export default FileViewer;
