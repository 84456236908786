import React, { ReactElement } from 'react';
import { Button } from '../button';
import { Content, HeaderLogo, Title } from './styles';

interface Props {
  title: string;
  titleFontSize?: string;
  description: ReactElement;
  buttonDescription: string;
  buttonFontSize?: string;
  buttonWidth?: string;
  onClick: any;
  top?: string;
}

export const RedirectContainer: React.FC<Props> = ({
  title,
  titleFontSize,
  description,
  buttonDescription,
  buttonFontSize,
  buttonWidth = '250px',
  onClick,
  top,
}) => {
  return (
    <>
      <HeaderLogo src="/logo-emissores.png" alt="Logo da BEE4" />
      <Content top={top}>
        <Title fontSize={titleFontSize}>{title}</Title>
        {description}
        <Button
          onClick={onClick}
          fontSize={buttonFontSize}
          width={buttonWidth}
          height="40px"
          variant="outlined"
        >
          {buttonDescription}
        </Button>
      </Content>
    </>
  );
};
