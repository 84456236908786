import React from 'react';
import { Modal } from '../../../../components/modal';
import {
  StrongLiText,
  ListItem,
  ModalContent,
  ModalImage,
  ModalTitle,
  ModalButtons,
} from './styles';
import { Button } from '../../../../components/button';
import useDasboardListagem from '../../hooks/useDashboardListagem';
import { useEnterprise } from '../../../../hooks/enterprise';

interface IModalProps {
  description: boolean;
  closeDropdown: (e?: any) => void;
  onConfirm: (e?: any) => void;
}
export const GenerateIPOModal: React.FC<IModalProps> = ({
  description,
  closeDropdown,
  onConfirm,
}) => {
  return (
    <Modal open={description} handleClose={closeDropdown} width={640}>
      <ModalContent>
        <ModalTitle style={{ display: 'flex', gap: '1rem' }}>
          <img src="images/documentacaoIPOIcon.svg" alt="" />
          <h3>Etapa &apos;Documentação IPO&apos;</h3>
        </ModalTitle>
        <ModalImage src="images/ipo-etapas.svg" alt="" />
        <ListItem>
          <li>
            O objetivo desta etapa é revisar a documentação do dossiê gerada que
            formaliza o pedido de registro de oferta e listagem da empresa
            emissora.
          </li>
          <li>
            Finalizada a revisão, o Consultor de Listagem deve submeter a
            documentação para o time BEE4. Primeiramente, será verificado se
            todos os documentos obrigatórios foram elaborados e poderemos pedir
            revisão se estiver faltando algo.
            <StrongLiText>
              Lembrando que é responsabilidade do Consultor de Listagem garantir
              a veracidade e coerência de todos os dados registrados no sistema.
            </StrongLiText>
          </li>
          <li>
            Na sequência, o dossiê será enviado para análise efetiva do Comitê
            de Emissores da BEE4, na qual o Emissor poderá ou não receber a
            aprovação para seguir com a oferta.
          </li>
        </ListItem>
        <ModalButtons>
          <Button
            variant="outlined"
            color="secondary"
            onClick={closeDropdown}
            width="124px"
            height="40px"
          >
            VOLTAR
          </Button>
          <Button
            width="183px"
            height="40px"
            onClick={() => {
              onConfirm();
              closeDropdown();
            }}
          >
            INICIAR REVISÃO
          </Button>
        </ModalButtons>
      </ModalContent>
    </Modal>
  );
};
