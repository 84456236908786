import styled from 'styled-components';

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 5rem;
  h2 {
    font-size: 18px;
  }
  p {
    font-size: 12px;
  }
`;

export const ContainerWhite = styled.div`
  background-color: white;
  padding: 2rem;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: 1rem;
`;

export const ContainerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const DocumentTitle = styled.p`
  margin: 1rem 0 2rem 0;
`;

export const DocumentEmptyTile = styled.p`
  margin: 0 auto;
`;
