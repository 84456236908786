import React, { useEffect, useState } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import axios, { AxiosResponse } from 'axios';
import { api } from '../../../services/api';
import { verifyError } from '../../../utils/verifyError';

interface IUseAtualizacaoCadastralHooks {
  termo: number;
}

export const useTermo = ({ termo }: IUseAtualizacaoCadastralHooks) => {
  const queryClient = useQueryClient();
  const getTermoAdesao = async () => {
    const getToast = toast.loading('Carregando termo');

    try {
      const { data }: AxiosResponse<any> = await api.default.get(
        `/v1/termo/base-64/${termo}`,
      );

      toast.update(getToast, {
        render: 'Termo carregado com sucesso',
        type: 'success',
        isLoading: false,
        autoClose: 1,
      });

      return data;
    } catch (error) {
      const message = verifyError(error, 'Erro ao carregar o termo de adesão');
      toast.update(getToast, {
        render: message,
        type: 'error',
        isLoading: false,
        autoClose: 5000,
      });
    }
  };

  const {
    isLoading,
    isSuccess,
    isError,
    isIdle,
    data: termoAdesao,
  } = useQuery(['termoAdesao'], getTermoAdesao, {
    enabled: !!termo,
  });

  const invalidateQuery = async () => {
    await queryClient.invalidateQueries('termoAdesao');
  };

  return {
    isLoading,
    isSuccess,
    isError,
    isIdle,
    termoAdesao,
    queryClient,
  };
};

export default useTermo;
