import React from 'react';
import ReactQuill, { Quill } from 'react-quill';
import BlotFormatter from 'quill-blot-formatter';
import 'react-quill/dist/quill.snow.css';
import './styles.css';

interface EditorProps {
  placeholder?: string;
  readOnly?: boolean;
  onChange?: (e: string) => void;
  html?: any;
}

export const Editor = ({
  placeholder,
  readOnly,
  onChange,
  html,
}: EditorProps) => {
  Quill.register('modules/blotFormatter', BlotFormatter);
  const modules = {
    toolbar: readOnly
      ? false
      : [
          ['bold', 'italic', 'underline', 'strike'], // toggled buttons
          [{ list: 'ordered' }, { list: 'bullet' }],
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          [{ align: [] }],
          ['link', 'video'],
        ],
    blotFormatter: readOnly ? null : {},
  };

  return (
    <ReactQuill
      theme="snow"
      value={html}
      onChange={onChange}
      placeholder={placeholder}
      readOnly={readOnly}
      modules={modules}
      className={readOnly ? '' : 'edit-mode'}
    />
  );
};
