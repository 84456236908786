import React from 'react';
import { useAuth } from '../../hooks/auth';
import { TOutletPermify } from './types';

export const OutletPermify = ({ children, role }: TOutletPermify) => {
  const { permissions } = useAuth();

  let hasPermission = false;

  role.forEach(item => {
    if (!hasPermission)
      hasPermission = permissions.filter(role => role === item).length > 0;
  });

  return hasPermission || role.length === 0 ? children : <></>;
};
