export const handleAvatarName = (name: string) => {
  if (name === '') {
    return '';
  }
  // Dividir o nome
  const fullName = name?.split(' ');

  // Verificar se há pelo menos duas palavras
  if (fullName?.length >= 2) {
    // Pegar a primeira letra de cada palavra
    const L1 = fullName[0].charAt(0);
    const L2 = fullName[1].charAt(0);

    // Retornar as duas primeiras letras em um array
    return L1 + L2;
  }
  if (fullName?.length === 1) {
    const L1 = fullName[0].charAt(0);

    return L1;
  }
  // Caso não haja tenha nome
  return '';
};

export type menuType = {
  [key: string]: string[];
};

export const menuMap: menuType = {
  dashboard: ['/dashboard-listagem'],
  fase1: [
    '/dados-gerais',
    '/documentacao-adicional',
    '/juridico',
    '/diligencia',
  ],
  fase2: ['/sobre-o-ativo'],
  fase4: ['/dados-da-oferta'],
  relatoriosPosListagem: ['/relatorios-pos-listagem'],
  atualizacoesCadastrais: ['/atualizacoes-cadastrais'],
  fatosRelevantes: ['/fatos-relevantes'],
  historicoComite: ['/historico-comite'],
};

export const handlePath = (pathname: string) => {
  if (menuMap.dashboard.includes(pathname)) {
    return 'dashboard';
  }
  if (menuMap.fase1.includes(pathname)) {
    return 'fase1';
  }
  if (menuMap.fase2.includes(pathname)) {
    return 'fase2';
  }
  if (menuMap.fase4.includes(pathname)) {
    return 'fase4';
  }
  if (menuMap.relatoriosPosListagem.includes(pathname)) {
    return 'relatoriosPosListagem';
  }
  if (menuMap.atualizacoesCadastrais.includes(pathname)) {
    return 'atualizacoesCadastrais';
  }
  if (menuMap.fatosRelevantes.includes(pathname)) {
    return 'fatosRelevantes';
  }
  if (menuMap.historicoComite.includes(pathname)) {
    return 'historicoComite';
  }
  return '404';
};

export type helmetType = {
  [key: string]: string;
};

export const helmetMap: helmetType = {
  '/direitos-do-usuario': 'Direitos do usuário',
  '/administracao-global': 'Administração global',
  '/gestao-usuarios': 'Gestão de usuários',
  '/processo-de-listagem': 'Processo de listagem',
  '/dashboard-listagem': 'Dashboard - Processo de listagem',
  '/dados-gerais': 'Dados gerais - Processo de listagem',
  '/documentacao-adicional': 'Documentação adicional - Processo de listagem',
  '/juridico': 'Jurídico - Processo de listagem',
  '/diligencia': 'Diligência - Processo de listagem',
  '/sobre-o-ativo': 'Sobre o ativo - Processo de listagem',
  '/dados-da-oferta': 'Dados da oferta - Processo de listagem',
  '/relatorios-pos-listagem': 'Relatórios - Pós listagem',
  '/atualizacoes-cadastrais': 'Atualizações cadastrais',
  '/fatos-relevantes': 'Fatos relevantes - Pós listagem',
  '/convidar-usuario': 'Convidar usuário',
  '/colaboradores': 'Colaboradores',
  '/emissores': 'Colaboradores',
  '/manual-emissor': 'Manual do usuário',
  '/manuais-bee4': 'Manuais da BEE4',
  '/documentacao-ipo': 'Documentação IPO',
  '/documentacao-listagem': 'Documentação Listagem',
  '/historico-documentacao': 'Histórico de documentação',
  '/decisao-comite': 'Decisão do comitê',
  '/historico-comite': 'Histórico de Comitê',
};
