/* eslint-disable react/no-unescaped-entities */
import React, { useEffect } from 'react';
import moment from 'moment';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { AlertType, DropzoneDialog } from 'material-ui-dropzone';
import { toast } from 'react-toastify';
import saveAs from 'file-saver';
import { UseMutationResult } from 'react-query';
import { AxiosResponse } from 'axios';
import {
  DocumentCard,
  TableLine,
  IndexColumns,
  ContentColumns,
  DocumentDetail,
  SituationDescription,
  DocumentButtons,
  EmptyContainer,
  FirstColumn,
  SecondColumn,
  ThirdColumn,
  FourthColumn,
  FifthColumn,
  ModalTitle,
  ModalContent,
  ModalDescription,
  ModalFilesSuported,
  ExtraArea,
  Description,
  DescriptionContent,
  DownloadButton,
  DescriptionContentMandatory,
  DescriptionMandatoryArea,
  DescriptionMandatoryContent,
  OnlyMandatory,
} from './styles';
import { verifyError } from '../../../../utils/verifyError';
import { Select } from '../../../select';
import { Tooltip } from '../../../tooltip';
import { toBase64 } from '../../../../services/toBase64';
import { Button } from '../../../button';

import { useDocuments } from '../../hooks/use-documents';
import { Document } from '../../types';
import { UploadModal } from '../../../uploadModal';
import { ViewDocumentModal } from '../viewDocumentModal';
import { ShouldRenderChildren } from '../../../shouldRenderChildren';
import { useEnterprise } from '../../../../hooks/enterprise';
import {
  base64ToArrayBuffer,
  getMimeType,
} from '../../../objectRenderer/utils';

const situation: any = {
  1: {
    situation: 'Pendente',
    icon: 'images/pendente.svg',
  },
  2: {
    situation: 'Revisão',
    icon: 'images/revisao.svg',
  },
  3: {
    situation: 'Em andamento',
    icon: 'images/relogio-cinza.svg',
  },
  5: {
    situation: 'Validado',
    icon: 'images/check-verde.svg',
  },
};

const statusOptions = [
  { value: 1, label: 'Pendente' },
  { value: 3, label: 'Em andamento' },
  { value: 5, label: 'Validado' },
  { value: 2, label: 'Revisão' },
];

type MutationRequest = UseMutationResult<
  AxiosResponse<never>,
  unknown,
  any,
  unknown
>;

type MutationRequestFile = UseMutationResult<
  AxiosResponse<{
    nome: any;
    base64Content: any;
  }>,
  unknown,
  any,
  unknown
>;

type MutationRequestStatus = UseMutationResult<
  AxiosResponse<{
    status: any;
  }>,
  unknown,
  any,
  unknown
>;

interface Props {
  currentRole?: string;
  currentEnterpriseId: string;
  stage: string;
  readOnly: boolean;
  category: any;
  posListagem?: boolean;
  updateDeclaracaoDocumento: (payload: any) => void;
  deleteDocument: MutationRequest;
  downloadDocument: MutationRequest;
  updateStatusDocument: MutationRequestStatus;
  updateDocument: MutationRequestFile;
}

export const DocumentsContainer = (props: Props) => {
  const {
    currentRole,
    currentEnterpriseId,
    stage,
    readOnly,
    category,
    posListagem,
    updateDeclaracaoDocumento,
    deleteDocument,
    downloadDocument,
    updateStatusDocument,
    updateDocument,
  } = props;
  const [docId, setDocId] = React.useState('');
  const [base64Document, setBase64Document] = React.useState<string[]>([]);
  const [editModal, setEditModal] = React.useState(false);

  const { enterprises } = useEnterprise();

  const isDisabled =
    currentRole === 'Empreendedor' &&
    enterprises?.length > 0 &&
    enterprises[0].termoEmissor === false;

  const handleCategoryHeader = () => {
    return (
      <>
        {category.descricao ||
        category.urlDoArquivoTemplate ||
        category.obrigatorio ? (
          <div style={{ cursor: 'default' }}>
            <ExtraArea>
              {category.descricao && !category.obrigatorio ? (
                <>
                  <Description>
                    Descrição:
                    <DescriptionContent>
                      {category.descricao}
                    </DescriptionContent>
                  </Description>
                  {category.urlDoArquivoTemplate && (
                    <DownloadButton>
                      <Button
                        type="button"
                        onClick={() => {
                          window.open(category.urlDoArquivoTemplate);
                        }}
                        disabled={isDisabled}
                      >
                        <img
                          className="uploadIcon"
                          src="images/download-branco.svg"
                          alt="upload"
                          style={{
                            marginRight: '1rem',
                            width: '16px',
                            height: '16px',
                          }}
                        />
                        DOWNLOAD DO TEMPLATE
                      </Button>
                    </DownloadButton>
                  )}
                </>
              ) : category.descricao && category.obrigatorio ? (
                <DescriptionMandatoryArea>
                  <DescriptionContentMandatory>
                    <div
                      style={{
                        display: 'flex',
                        fontSize: '0.8rem',
                        fontWeight: '600',
                      }}
                    >
                      Descrição:
                      <DescriptionContent>
                        {category.descricao}
                      </DescriptionContent>
                    </div>
                    {category.urlDoArquivoTemplate && (
                      <DownloadButton>
                        <Button
                          type="button"
                          onClick={() => {
                            window.open(category.urlDoArquivoTemplate);
                          }}
                          disabled={isDisabled}
                        >
                          <img
                            className="uploadIcon"
                            src="images/download-branco.svg"
                            alt="upload"
                            style={{
                              marginRight: '1rem',
                              width: '16px',
                              height: '16px',
                            }}
                          />
                          DOWNLOAD DO TEMPLATE
                        </Button>
                      </DownloadButton>
                    )}
                  </DescriptionContentMandatory>
                  {stage !== 'relatorio' && (
                    <DescriptionMandatoryContent>
                      Marque uma das opções caso não possa realizar o envio
                      obrigatório.
                      <span style={{ fontWeight: '600' }}>
                        {' '}
                        Sobre o documento:
                      </span>
                      <div style={{ marginTop: '1rem', width: '300px' }}>
                        <Select
                          onChange={(v: any) => {
                            updateDeclaracaoDocumento({
                              categoryId: category.id,
                              value: v,
                            });
                          }}
                          options={[
                            { label: '-', value: 0 },
                            { label: 'Não se aplica à empresa', value: 1 },
                            { label: 'Não possui', value: 2 },
                          ]}
                          value={category.declaracaoDocumento}
                          disabled={readOnly}
                        />
                      </div>
                    </DescriptionMandatoryContent>
                  )}
                </DescriptionMandatoryArea>
              ) : (
                <>
                  {category.obrigatorio && stage !== 'relatorio' && (
                    <OnlyMandatory>
                      Marque uma das opções caso não possa realizar o envio
                      obrigatório.
                      <span style={{ fontWeight: '600' }}>
                        {' '}
                        Sobre o documento:
                      </span>
                      <div style={{ marginTop: '1rem', width: '300px' }}>
                        <Select
                          onChange={(v: any) => {
                            updateDeclaracaoDocumento({
                              categoryId: category.id,
                              value: v,
                            });
                          }}
                          options={[
                            { label: '-', value: 0 },
                            { label: 'Não se aplica à empresa', value: 1 },
                            { label: 'Não possui', value: 2 },
                          ]}
                          value={category.declaracaoDocumento}
                          disabled={readOnly}
                        />
                      </div>
                    </OnlyMandatory>
                  )}

                  {category.urlDoArquivoTemplate && (
                    <DownloadButton>
                      <Button
                        type="button"
                        onClick={() => {
                          window.open(category.urlDoArquivoTemplate);
                        }}
                        disabled={isDisabled}
                      >
                        <img
                          className="uploadIcon"
                          src="images/download-branco.svg"
                          alt="upload"
                          style={{
                            marginRight: '1rem',
                            width: '16px',
                            height: '16px',
                          }}
                        />
                        DOWNLOAD DO TEMPLATE
                      </Button>
                    </DownloadButton>
                  )}
                </>
              )}
            </ExtraArea>
            <TableLine />{' '}
          </div>
        ) : (
          <></>
        )}
      </>
    );
  };

  const handleCategoryContent = () => {
    if (!category.documentos.length) {
      return (
        <EmptyContainer>
          <img className="emptyIcon" src="images/EmptyState/vazio.svg" alt="" />
          <p>Ainda não há documentos. Insira clicando em "UPLOAD".</p>
        </EmptyContainer>
      );
    }

    return (
      <>
        <IndexColumns>
          <FirstColumn />
          <SecondColumn>
            <span>NOME</span>
          </SecondColumn>
          <ThirdColumn>
            <span>DATA DE INSERÇÃO</span>
          </ThirdColumn>
          <FourthColumn>
            <></>
          </FourthColumn>
          <FifthColumn />
        </IndexColumns>
        <TableLine />
        <DocumentDetail>{renderDocuments()}</DocumentDetail>
      </>
    );
  };

  const handleDelete = (documentId: string) => {
    const payload = {
      currentEnterpriseId,
      documentId,
      stage,
    };
    deleteDocument.mutate(payload, {
      onSuccess: async (res: any) => {
        if (res?.status === 200 || res?.status === 201) {
          toast.success('Documento excluído com sucesso!');
        }
      },
      onError: async (err: any) => {
        const message = verifyError(err, 'Erro ao deletar o documento');
        toast.error(message);
      },
    });
  };

  const handleBase64ToBlob = ({
    base64,
    name,
  }: {
    base64: string;
    name: string;
  }) => {
    const type = name?.split('.')?.pop();

    const mimeType = getMimeType(type ?? 'pdf');

    if (!mimeType) {
      console.error('Tipo de arquivo inválido');
      return null;
    }

    const fileArrayBuffer = base64ToArrayBuffer(base64);

    if (!mimeType) {
      console.error('Tipo de arquivo inválido');
      return null;
    }

    const blob = new Blob([fileArrayBuffer], {
      type: mimeType,
    });
    return blob;
  };

  const handleDownload = (documentId: string, name: string) => {
    const payload = {
      currentEnterpriseId,
      documentId,
    };
    downloadDocument.mutate(payload, {
      onSuccess: async (res: any) => {
        if (res?.status === 200 || res?.status === 201) {
          toast.success('Download realizado com sucesso!');
          const base64 = res?.data?.base64Content?.result as string;
          const blob = handleBase64ToBlob({ base64, name });
          if (blob) {
            const objectUrl = URL.createObjectURL(blob);
            saveAs(objectUrl, `${name}`);
          }
        }
      },
      onError: async (err: any) => {
        const message = verifyError(
          err,
          'Erro ao realizar o download do documento',
        );
        toast.error(message);
      },
    });
  };

  const handleView = (documentId: string, name?: string) => {
    const payload = {
      currentEnterpriseId,
      documentId,
    };
    downloadDocument.mutate(payload, {
      onSuccess: async (res: any) => {
        if (res?.status === 200 || res?.status === 201) {
          toast.success('Arquivo renderizado com sucesso!');

          const base64 = res?.data?.base64Content?.result as string;

          const type = name?.split('.')?.pop();

          setBase64Document([base64, type ?? 'pdf', name ?? 'Documento']);
        }
      },
      onError: async (err: any) => {
        const message = verifyError(
          err,
          'Erro ao tentar visualizar o documento',
        );
        toast.error(message);
      },
    });
  };

  const handleChangeStatus = (value: any) => {
    const payload = {
      currentEnterpriseId,
      documentId: value.doc.id,
      value: value.e,
      stage,
    };
    updateStatusDocument.mutate(payload, {
      onSuccess: async (res: any) => {
        if (res?.status === 200 || res?.status === 201) {
          toast.success('Documento atualizado com sucesso!');
        }
      },
      onError: async (err: any) => {
        const message = verifyError(err, 'Erro ao atualizar documento');
        toast.error(message);
      },
    });
  };

  const handleSave = async (files: File[]) => {
    if (!files) {
      toast.error('Coloque um documento antes de salvar');
    } else {
      const base64Content = await toBase64(files[0]);
      const nome = files[0].name;
      const document = {
        nome,
        base64Content,
      };
      const payload = { currentEnterpriseId, document, stage, id: docId };
      updateDocument.mutate(payload, {
        onSuccess: async (res: any) => {
          if (res?.status === 200 || res?.status === 201) {
            toast.success('Update do documento realizado com sucesso!');
            setEditModal(false);
          }
        },
        onError: async (err: any) => {
          const message = verifyError(err, 'Erro ao dar update no documento');
          toast.error(message);
        },
      });
    }
  };

  function renderDocuments() {
    return category.documentos.map((doc: Document, index: number) => {
      const chosenOption: number =
        doc.status !== undefined && doc.status >= 0 ? doc.status : 1;
      const date = moment(doc.createdOn).format('DD/MM/YYYY');

      const handleStatus = [1, 2, 3, 5].includes(chosenOption)
        ? situation[chosenOption]
        : undefined;

      return (
        <React.Fragment key={doc.id}>
          <ContentColumns>
            <FirstColumn>
              {stage !== 'dossie' && handleStatus && (
                <img
                  className="columnIcon"
                  src={handleStatus?.icon}
                  alt={handleStatus?.situation}
                />
              )}
            </FirstColumn>
            <SecondColumn>
              <img className="fileIcon" src="images/file.svg" alt={doc.nome} />
              <span>{doc.nome}</span>
            </SecondColumn>
            <ThirdColumn>
              <span>{date}</span>
            </ThirdColumn>
            <FourthColumn>
              {/* {stage === 'dossie' || stage !== 'relatorio' ? ( */}
              <></>
              {/* // ) : currentRole === 'NOMAD' || currentRole === 'NOMADBOSS' ? (
              //   <Select
              //     defaultValue={1}
              //     height="40px"
              //     width="155px"
              //     value={chosenOption}
              //     options={statusOptions}
              //     onChange={(e: number) => {
              //       handleChangeStatus({ e, doc });
              //     }}
              //     disabled={readOnly}
              //   />
              // ) : (
              //   <SituationDescription description={chosenOption}>
              //     {handleStatus?.situation}
              //   </SituationDescription>
              // )} */}
            </FourthColumn>
            <FifthColumn>
              <DocumentButtons validated={readOnly}>
                <button
                  type="button"
                  onClick={() => {
                    const type = doc?.nome?.split('.')?.pop();
                    if (type && ['pdf', 'jpg', 'png', 'docx'].includes(type)) {
                      handleView(doc.id, doc?.nome);
                    } else {
                      handleDownload(doc.id, doc?.nome ?? 'arquivo');
                    }
                  }}
                >
                  {['pdf', 'jpg', 'png', 'docx'].includes(
                    doc?.nome?.split('.')?.pop() ?? '',
                  ) ? (
                    <VisibilityOutlinedIcon />
                  ) : (
                    <FileDownloadOutlinedIcon />
                  )}
                </button>
                {(currentRole === 'Empreendedor' ||
                  currentRole === 'NOMADBOSS') &&
                !doc.isHidden ? (
                  <Tooltip
                    disabled={!readOnly}
                    title="Os arquivos que já foram validados pelo seu consultor de listagem não podem ser deletados"
                  >
                    <button
                      disabled={readOnly}
                      type="button"
                      onClick={() => (readOnly ? {} : handleDelete(doc.id))}
                    >
                      <img
                        className="delete"
                        src={
                          !readOnly
                            ? 'images/lixeira.svg'
                            : 'images/lixeira-cinza.svg'
                        }
                        alt="delete"
                      />
                    </button>
                  </Tooltip>
                ) : (
                  <></>
                )}
                {/* {(currentRole === 'Empreendedor' ||
                  currentRole === 'NOMADBOSS') &&
                !doc.isHidden ? (
                  <Tooltip
                    disabled={!readOnly}
                    title="Não é possível editar este arquivo, pois já foi validado pelo seu consultor de listagem"
                  >
                    <button
                      type="button"
                      disabled={readOnly}
                      onClick={() => {
                        if (!readOnly) {
                          setDocId(doc.id);
                          setEditModal(true);
                        }
                      }}
                      aria-label="Editar documento"
                    >
                      <EditIcon
                        sx={{ fontSize: '20px' }}
                        htmlColor={!readOnly ? '#212e51' : '#a9a9a9'}
                      />
                    </button>
                  </Tooltip>
                ) : (
                  <></>
                )} */}
              </DocumentButtons>
            </FifthColumn>
          </ContentColumns>
          {index < category.documentos.length - 1 ? <TableLine /> : null}
        </React.Fragment>
      );
    });
  }
  return (
    <>
      <DocumentCard>
        {handleCategoryHeader()}
        {handleCategoryContent()}
      </DocumentCard>
      <DropzoneDialog
        maxFileSize={25000000}
        filesLimit={1}
        acceptedFiles={[
          '.jpg',
          '.png',
          '.pdf',
          '.xls',
          '.xlsx',
          '.csv',
          '.doc',
          '.docx',
        ]}
        clearOnUnmount
        showAlerts={false}
        onAlert={(message: string, variant: AlertType) => {
          if (variant === 'error') {
            if (message.includes('File type not supported. File is too big.')) {
              toast.error('Arquivo selecionado muito grande, limite de 25MB');
            } else {
              toast.error(
                'Tipo de documento inválido. Documentos aceitos: .jpg, png, pdf, xls, xlsx, csv, doc e docx',
              );
            }
          }
        }}
        dialogTitle={
          <ModalContent>
            <ModalTitle>Editar documento</ModalTitle>
            <ModalDescription>
              Esse arquivo substituirá o arquivo existente.
            </ModalDescription>
            <ModalFilesSuported>
              Arquivos suportados: jpg, png, xls, xlsx, csv, pdf, doc e docx.
            </ModalFilesSuported>
          </ModalContent>
        }
        cancelButtonText="cancelar"
        dropzoneText="Procurar no computador ou arraste um arquivo até aqui"
        submitButtonText="salvar"
        open={editModal}
        onClose={() => setEditModal(false)}
        onSave={files => handleSave(files)}
        showPreviews
        showFileNamesInPreview
      />
      <UploadModal
        openModal={editModal}
        onSave={files => {
          handleSave(files);
        }}
        onClose={() => {
          setEditModal(false);
        }}
        dialogTitle={
          <ModalContent>
            <ModalTitle>Editar documento</ModalTitle>
            <ModalDescription>
              Esse arquivo substituirá o arquivo existente.
            </ModalDescription>
            <ModalFilesSuported>
              Arquivos suportados: jpg, png, xls, xlsx, csv, pdf, doc e docx.
            </ModalFilesSuported>
          </ModalContent>
        }
      />
      <ShouldRenderChildren shouldRender={base64Document.length > 1}>
        <ViewDocumentModal
          description={base64Document.length > 1}
          closeDropdown={() => {
            setBase64Document([]);
          }}
          base64={base64Document[0]}
          fileType={
            base64Document[1] as
              | 'jpg'
              | 'png'
              | 'xls'
              | 'xlsx'
              | 'csv'
              | 'pdf'
              | 'doc'
              | 'docx'
          }
          canDownload={currentRole === 'BEE4' || posListagem}
          fileName={base64Document[2]}
        />
      </ShouldRenderChildren>
    </>
  );
};
