import React, { useEffect, useState } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import axios, { AxiosResponse } from 'axios';
import { api } from '../../../services/api';
import { verifyError } from '../../../utils/verifyError';

export const useReport = ({
  currentEnterpriseId,
}: {
  currentEnterpriseId: string;
}) => {
  const queryClient = useQueryClient();
  const getAllReport = async () => {
    const getToast = toast.loading(
      'Carregando as datas do relatório da empresa',
    );
    try {
      const { data }: AxiosResponse<any> = await api.main.get(
        `/v1/empresas/${currentEnterpriseId}/documentos-relatorio/relacao-anos`,
      );

      toast.update(getToast, {
        render: 'Dados carregados com sucesso!',
        type: 'success',
        isLoading: false,
        autoClose: 1,
      });
      return data;
    } catch (error) {
      const message = verifyError(
        error,
        'Erro ao carregar as datas do relatório da empresa',
      );
      toast.update(getToast, {
        render: message,
        type: 'error',
        isLoading: false,
        autoClose: 5000,
      });
    }
  };

  const {
    isLoading,
    isSuccess,
    isError,
    isIdle,
    data: relatorioDatas,
  } = useQuery(['reportDates', currentEnterpriseId], getAllReport, {
    enabled: !!currentEnterpriseId,
  });

  const invalidateQuery = async () => {
    await queryClient.invalidateQueries('reportDates');
  };

  return {
    isLoading,
    isError,
    isIdle,
    isSuccess,
    relatorioDatas,
    getAllReport,
    queryClient,
  };
};

export default useReport;
