import styled from 'styled-components';

export const Container = styled.div`
  background-color: #fff;
  border-radius: 4px;
  padding: 0 30px 35px;
  box-shadow: 0px 1px 4px #15223214;
`;

export const ExportContainer = styled.div`
  width: 90%;
  background-color: #fff;
  padding: 22px 32px;
  margin: 1rem auto;
  border-radius: 8px;

  span {
    font-size: 14px;
    color: #171725;
    font-weight: 500;
  }
`;

export const Export = styled.div`
  width: 100%;
  margin-top: 10px;
  border-radius: 8px;
  background-color: #f9f9f9;
  display: flex;
  align-items: center;

  img {
    height: 15px;
    margin-left: 15px;
    margin-right: 10px;
  }

  button {
    margin-left: auto;
  }
`;

export const DescriptionListContent = styled.div`
  padding-top: 40px;
  padding-bottom: 20px;
  margin: auto;
  color: #171725;
  h2 {
    font-size: 18px;
  }
`;
