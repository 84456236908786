import React from 'react';
import { useForm } from 'react-hook-form';
import { Modal } from '../../modal';
import { Button } from '../../button';
import { ModalButtons, ModalForm } from './styles';

interface IModalProps {
  description: boolean;
  closeDropdown: (e?: any) => void;

  onDelete: (e?: any) => void;
}

export const DeleteCategoryModal: React.FC<IModalProps> = ({
  description,
  closeDropdown,
  onDelete,
}) => {
  const { handleSubmit } = useForm<any>({});

  const submitForm = (e: any) => {
    closeDropdown();
    onDelete();
  };
  return (
    <Modal open={description} handleClose={closeDropdown} width={672}>
      <ModalForm onSubmit={handleSubmit(submitForm)}>
        <h3>Excluir documento</h3>
        <p>Tem certeza que deseja excluir o documento desta categoria?</p>

        <ModalButtons>
          <Button
            variant="outlined"
            color="secondary"
            onClick={closeDropdown}
            width="157px"
            height="40px"
          >
            VOLTAR
          </Button>
          <Button width="157px" height="40px" type="submit">
            DELETAR
          </Button>
        </ModalButtons>
      </ModalForm>
    </Modal>
  );
};
