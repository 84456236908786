import React from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { AxiosResponse } from 'axios';

import { api } from '../../../services/api';
import { verifyError } from '../../../utils/verifyError';

export const useDadosGerais = ({
  currentEnterpriseId,
}: {
  currentEnterpriseId: string;
}) => {
  const queryClient = useQueryClient();
  const getDadosGerais = async () => {
    try {
      const { data }: AxiosResponse<any> = await api.main.get(
        `/v1/empresas/${currentEnterpriseId}`,
      );

      return data;
    } catch (error) {
      const message = verifyError(error, 'Erro ao carregar os dados gerais');
      toast.error(message);
    }
  };

  const {
    isLoading,
    isSuccess,
    isError,
    isIdle,
    data: dadosGerais,
  } = useQuery(['dadosGerais', currentEnterpriseId], getDadosGerais, {});

  const invalidateQuery = async () => {
    await queryClient.invalidateQueries('dadosGerais');
  };

  const postDados = useMutation(async (payload: any) => {
    toast.loading('Enviando dados');
    const { form } = payload;

    const response = await api.main.post(
      `/v1/empresas/${currentEnterpriseId}`,
      form,
    );

    invalidateQuery();

    return response;
  });

  const putDados = useMutation(async (payload: any) => {
    toast.loading('Atualizando formulário em dados gerais');

    const response = await api.main.put(
      `/v1/empresas/${currentEnterpriseId}`,
      payload,
    );

    invalidateQuery();

    return response;
  });

  const updateDadosGerais = (payload: any) => {
    putDados.mutate(payload, {
      onSuccess: async res => {
        toast.dismiss();
        if (res?.status === 200 || res?.status === 201) {
          toast.success('Formulário de dados gerais atualizado com sucesso!');
        }
      },
      onError: async (err: any) => {
        toast.dismiss();
        const message = verifyError(
          err,
          'Erro ao atualizar formulário em dados gerais',
        );
        toast.error(message);
      },
    });
  };

  return {
    isLoading,
    isSuccess,
    isError,
    isIdle,
    dadosGerais,
    postDados,
    updateDadosGerais,
    getDadosGerais,
    queryClient,
  };
};

export default useDadosGerais;
