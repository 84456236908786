import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useEnterprise } from '../../hooks/enterprise';
import { DashboardMain } from './dashboardMain';
import useDasboardListagem from './hooks/useDashboardListagem';
import { LoadingDashboard } from './loading';
import { verifyError } from '../../utils/verifyError';
import { statusCompleted } from './fields';

export const DashboardListagem = () => {
  const { currentEnterpriseId } = useEnterprise();
  const [loadingChangeStatus, setLoadingChangeStatus] = useState(false);
  const navigate = useNavigate();

  const {
    dashboardStatus,
    updateDados,
    isLoading,
    invalidateQuery,
    generateIPO,
    generateListagem,
  } = useDasboardListagem({
    currentEnterpriseId,
  });

  const progressStatus =
    dashboardStatus?.statusDocumentacaoIpo === 5
      ? dashboardStatus?.statusListagemIpo
      : dashboardStatus?.statusDocumentacaoIpo;

  const completed = statusCompleted(progressStatus);

  const changeStatus = (payload: any) => {
    setLoadingChangeStatus(true);
    updateDados.mutate(payload, {
      onSuccess: async (res: any) => {
        toast.dismiss();
        if (res?.status === 200 || res?.status === 201) {
          toast.success('Status atualizado com sucesso!');
          invalidateQuery();
        }
        setLoadingChangeStatus(false);
      },
      onError: async (err: any) => {
        toast.dismiss();
        const message = verifyError(err, 'Erro ao atualizar status');
        toast.error(message);
        setLoadingChangeStatus(false);
      },
    });
  };

  const generateIPOStatus = (payload?: any) => {
    generateIPO.mutate(payload, {
      onSuccess: async res => {
        toast.dismiss();
        if (res?.status === 200 || res?.status === 201) {
          toast.success(
            'Solicitação de geração de documentos feita com sucesso!',
          );
          invalidateQuery();
          navigate('/documentacao-ipo');
        }
      },
      onError: async (err: any) => {
        toast.dismiss();
        const message = verifyError(
          err,
          'Erro ao solicitar a geração de documentos.',
        );
        toast.error(message);
        invalidateQuery();
      },
    });
  };

  const generateListagemStatus = (payload?: any) => {
    generateListagem.mutate(payload, {
      onSuccess: async res => {
        toast.dismiss();
        if (res?.status === 200 || res?.status === 201) {
          toast.success(
            'Solicitação de geração de documentos feita com sucesso.',
          );
          invalidateQuery();
          navigate('/documentacao-listagem');
        }
      },
      onError: async (err: any) => {
        toast.dismiss();
        const message = verifyError(
          err,
          'Erro ao solicitar a geração de documentos de listagem.',
        );
        toast.error(message);
        invalidateQuery();
      },
    });
  };

  if (!dashboardStatus) return <LoadingDashboard />;

  return (
    <DashboardMain
      data={dashboardStatus}
      completed={completed}
      isLoading={isLoading}
      changeStatus={(e: any) => {
        changeStatus(e);
      }}
      generateIPOStatus={generateIPOStatus}
      generateListagemStatus={generateListagemStatus}
      loadingChange={loadingChangeStatus}
    />
  );
};
