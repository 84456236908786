import React, { useState } from 'react';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import SendIcon from '@mui/icons-material/Send';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { Button } from '../../components/button';
import { Container } from '../../components/container';
import {
  ButtonsContainer,
  ContentColumns,
  FifthColumn,
  FirstColumn,
  FourthColumn,
  IndexColumns,
  SecondColumn,
  SeventhColumn,
  SixthColumn,
  Status,
  SubTitle,
  TableRow,
  ThirdColumn,
} from './styles';
import { ShouldRenderChildren } from '../../components/shouldRenderChildren';
import { Kebab } from '../../components/kebab';
import { getStatusConvite } from '../../utils/fields';
import { CreateEditNomadParceirosModal } from './modals/createEditParceiroModal';
import { DeleteParceirosModal } from './modals/deleteParceirosModal';
import { useEnterprise } from '../../hooks/enterprise';
import { listProfileType, Parceiro } from './fields';
import useParceiros from './hooks/parceiros';
import { InviteParceiroModal } from './modals/inviteParceiroModal';
import { validarRoles } from '../../utils/roles';
import { Roles } from '../../router/roles.routes';
import { useAuth } from '../../hooks/auth';

export const Parceiros = () => {
  const [addEditParceiroModal, setAddEditParceiroModal] = useState(false);
  const [deleteParceiroModal, setDeleteParceiroModal] = useState(false);
  const [inviteParceiroModal, setInviteParceiroModal] = useState(false);
  const [chosenParceiro, setChosenParceiro] = useState<Parceiro | undefined>(
    undefined,
  );

  const { permissions } = useAuth();
  const { currentEnterpriseId, enterprises } = useEnterprise();

  const isDisabled =
    enterprises?.length > 0 && enterprises[0].termoEmissor === false;

  const {
    parceiros,
    createParceiro,
    updateParceiro,
    deleteParceiro,
    reenviarConviteParceiro,
    activateParceiro,
  } = useParceiros({ currentEnterpriseId });

  function collaboratorContentList() {
    const options = (parceiro: Parceiro) => {
      const list = [];

      console.log(parceiro);

      if (
        validarRoles(permissions, [Roles.ReenviarConviteEmissor]) &&
        (parceiro?.statusDoConvite === 4 || parceiro?.statusDoConvite === 5)
      )
        list.push({
          icon: <SendIcon htmlColor="#212E51" />,
          onClick: () => {
            setInviteParceiroModal(true);
          },
          name: 'Reenviar',
        });

      if (validarRoles(permissions, [Roles.EditarEmissor]))
        list.push({
          icon: <EditIcon htmlColor="#212E51" />,
          onClick: () => {
            setAddEditParceiroModal(true);
          },
          name: 'Editar',
        });

      if (validarRoles(permissions, [Roles.ExcluirEmissor]) && parceiro.ativo)
        list.push({
          icon: <DeleteIcon htmlColor="#212E51" />,
          onClick: () => {
            setDeleteParceiroModal(true);
          },
          name: 'Desativar',
        });

      if (validarRoles(permissions, [Roles.ExcluirEmissor]) && !parceiro.ativo)
        list.push({
          icon: <TaskAltIcon htmlColor="#212E51" />,
          onClick: () => {
            setDeleteParceiroModal(true);
          },
          name: 'Ativar',
        });

      return list;
    };

    if (!parceiros) {
      return null;
    }

    return parceiros.map((parceiro: Parceiro) => {
      const companyTooBig = `${
        (parceiro?.nomeDaEmpresaQualPertence
          ? parceiro?.nomeDaEmpresaQualPertence?.length
          : 0) > 38
          ? '...'
          : ''
      }`;

      return (
        <React.Fragment key={parceiro.id}>
          <TableRow>
            <FirstColumn>
              <span>{parceiro.nome}</span>
            </FirstColumn>
            <SecondColumn>
              <span>{parceiro.email}</span>
            </SecondColumn>
            <ThirdColumn>
              <span>{`${
                parceiro?.nomeDaEmpresaQualPertence
                  ? parceiro?.nomeDaEmpresaQualPertence?.substring(0, 38)
                  : ''
              }${companyTooBig}`}</span>
            </ThirdColumn>

            <FifthColumn>{parceiro?.ativo ? 'Ativo' : 'Inativo'}</FifthColumn>
            <SixthColumn style={{ marginLeft: 15 }}>
              {
                listProfileType.find(
                  item => parceiro.tipoDoPerfil === item.value,
                )?.label
              }
            </SixthColumn>
            <FourthColumn>
              <Status
                status={
                  parceiro?.statusDoConvite ? parceiro?.statusDoConvite : 0
                }
              >
                {
                  getStatusConvite[
                    parceiro?.statusDoConvite ? parceiro?.statusDoConvite : 0
                  ]
                }
              </Status>
            </FourthColumn>
            <SeventhColumn>
              <Kebab
                options={options(parceiro)}
                onChange={e => {
                  setChosenParceiro(parceiro);
                }}
                onClose={() => {
                  setChosenParceiro(undefined);
                }}
                disabled={isDisabled}
              />
            </SeventhColumn>
          </TableRow>
        </React.Fragment>
      );
    });
  }

  return (
    <>
      <SubTitle>
        Convide seus colaboradores para ajudar no preenchimento das informações
        e upload de documentos. Lembrando que eles só poderão incluir dados. Os
        únicos com permissão para edição e exclusão são você e seu Consultor de
        Listagem Responsável.
      </SubTitle>
      <Container style={{ minWidth: 760 }}>
        <Button
          onClick={() => {
            setAddEditParceiroModal(true);
          }}
          disabled={isDisabled}
        >
          <ButtonsContainer>
            <AddCircleOutlineOutlinedIcon /> CONVIDAR COLABORADOR
          </ButtonsContainer>
        </Button>
        <IndexColumns>
          <FirstColumn>
            <span>Nome do Colaborador</span>
          </FirstColumn>
          <SecondColumn>
            <span>E-mail</span>
          </SecondColumn>
          <ThirdColumn>
            <span>Empresa(s)</span>
          </ThirdColumn>
          <FifthColumn>
            <span>Ativo</span>
          </FifthColumn>
          <SixthColumn style={{ marginLeft: 15 }}>
            <span>Perfil</span>
          </SixthColumn>
          <FourthColumn>
            <span>Status</span>
          </FourthColumn>
          <SeventhColumn />
        </IndexColumns>
        <ContentColumns>{collaboratorContentList()}</ContentColumns>
      </Container>
      <ShouldRenderChildren shouldRender={addEditParceiroModal}>
        <CreateEditNomadParceirosModal
          description={addEditParceiroModal}
          closeDropdown={() => {
            setAddEditParceiroModal(false);
            setChosenParceiro(undefined);
          }}
          parceiro={chosenParceiro}
          createParceiro={createParceiro}
          updateParceiro={updateParceiro}
        />
      </ShouldRenderChildren>
      <ShouldRenderChildren shouldRender={deleteParceiroModal}>
        <DeleteParceirosModal
          description={deleteParceiroModal}
          closeDropdown={() => {
            setDeleteParceiroModal(false);
            setChosenParceiro(undefined);
          }}
          parceiroId={chosenParceiro?.id}
          deleteParceiro={payload => {
            chosenParceiro?.ativo
              ? deleteParceiro(payload)
              : activateParceiro(payload);
          }}
          status={chosenParceiro?.statusDoConvite ?? 0}
          ativo={chosenParceiro?.ativo ?? false}
        />
      </ShouldRenderChildren>
      <ShouldRenderChildren
        shouldRender={
          inviteParceiroModal &&
          (chosenParceiro?.statusDoConvite === 4 ||
            chosenParceiro?.statusDoConvite === 5)
        }
      >
        <InviteParceiroModal
          description={inviteParceiroModal}
          closeDropdown={() => {
            setInviteParceiroModal(false);
            setChosenParceiro(undefined);
          }}
          parceiroId={chosenParceiro?.id}
          reenviarConvite={reenviarConviteParceiro}
        />
      </ShouldRenderChildren>
    </>
  );
};
