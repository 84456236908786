import React, { useEffect } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { makeStyles } from '@material-ui/core/styles';

interface TabPanelProps {
  children?: any;
  index: number;
  value: number;
}

interface TabContainerProps {
  children: any;
  label: string;
}

interface MenuTabsProps {
  containers: TabContainerProps[];
  value?: number;
}
const useStyles = makeStyles({
  tabs: {
    '& .MuiTabs-indicator': {
      backgroundColor: '#1E09D0',
    },
    '& .MuiTab-root.Mui-selected': {
      color: '#1E09D0',
    },
  },
});

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component="span">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export const MenuTabs = (props: MenuTabsProps) => {
  const { containers, value = 0 } = props;
  const [currentValue, setCurrentValue] = React.useState(value);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentValue(newValue);
  };

  useEffect(() => {
    setCurrentValue(0);
  }, [containers]);

  const classes = useStyles();
  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={currentValue}
          onChange={handleChange}
          aria-label="basic tabs example"
          className={classes.tabs}
        >
          {containers.map((container, index) => (
            <Tab
              key={container.label}
              label={container.label}
              {...a11yProps(index)}
            />
          ))}
        </Tabs>
      </Box>
      {containers.map((container, index) => (
        <TabPanel key={container.label} value={currentValue} index={index}>
          {container.children}
        </TabPanel>
      ))}
    </Box>
  );
};
