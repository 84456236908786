import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { Button } from '../../../components/button';
import { Modal } from '../../../components/modal';
import { MainButtons, ModalContentForm, Tag, Tags } from '../styles';
import { Empresa, Usuario } from '../fields';
import { formatCPFeCNPJ } from '../../../utils/strings';

interface IButtonProps {
  description: boolean;
  closeDropdown: (e?: any) => void;
  userId: string | undefined;
  getUsuario: (id: string) => Promise<Usuario>;
}

export const DetailsModal: React.FC<IButtonProps> = ({
  description,
  closeDropdown,
  userId,
  getUsuario,
}) => {
  const [user, setUser] = useState<Usuario>({} as Usuario);

  useEffect(() => {
    (async () => {
      const data = await getUsuario(userId as any);
      setUser(data);
    })();
  }, []);

  return (
    <Modal open={description} handleClose={closeDropdown} width={500}>
      <ModalContentForm>
        <h3>Detalhes do usuário</h3>

        <div className="descriptionModal">
          <section>
            <strong>Nome:</strong> {user?.nome}
          </section>
          <section>
            <strong>CPF:</strong> {user?.cpf ? formatCPFeCNPJ(user?.cpf) : ''}
          </section>
          <section>
            <strong>E-mail:</strong> {user?.email}
          </section>
          <section>
            <strong>Último acesso:</strong>{' '}
            {user.ultimoAcesso
              ? dayjs(user.ultimoAcesso).format('DD/MM/YYYY')
              : ''}
          </section>
          <section>
            <strong>Data de cadastro:</strong>{' '}
            {user.dataDeCadastro
              ? dayjs(user.dataDeCadastro).format('DD/MM/YYYY')
              : ''}
          </section>
          <section>
            <strong>Data de desativação:</strong>{' '}
            {user.desativadoEm
              ? dayjs(user.desativadoEm).format('DD/MM/YYYY')
              : ''}
          </section>
          <section>
            <strong>Emissores vinculados:</strong>{' '}
            <Tags>
              {user.empresas?.map((item: Empresa) => (
                <Tag>{item.nomeFantasia ?? item.razaoSocial}</Tag>
              ))}
            </Tags>
          </section>
        </div>

        <MainButtons>
          <Button
            type="button"
            variant="outlined"
            width="96px"
            onClick={closeDropdown}
          >
            FECHAR
          </Button>
        </MainButtons>
      </ModalContentForm>
    </Modal>
  );
};
