import React, { ReactChild, ReactChildren } from 'react';

interface IRenderProps {
  shouldRender: boolean;
  children: any;
}

/** O objetivo desse componente é evitar o uso de expressões dentro do DOM para renderizar ou não um componente. */
export const ShouldRenderChildren: React.FC<IRenderProps> = ({
  shouldRender,
  children,
}) => {
  if (!shouldRender) {
    return <></>;
  }
  return <>{children}</>;
};
