import React, { useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SendIcon from '@mui/icons-material/Send';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import Archive from '@mui/icons-material/Archive';
import styled from 'styled-components';
import Accordion from '../../../../../../components/accordion';
import { Button } from '../../../../../../components/button';
import { Buttons, Content, Status, Title } from './styles';
import { ResendInviteConsultorListagemModal } from '../resendInviteModal';
import { getStatusConvite } from '../../../../../../utils/fields';
import { OutletPermify } from '../../../../../../components/outletPermify';
import { Roles } from '../../../../../../router/roles.routes';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IButtonProps {
  children: any;
  title?: string;
  onOpenModal?: (e?: any) => void;
  nomad?: boolean;
  status?: 0 | 1 | 2 | 4 | 5;
  inviteConsultorDeListagem: (e?: any) => void;
  ativo?: boolean;
  reactivated?: boolean;
  type?: 'nomad' | 'escriturador' | 'auditor' | null;
}

const ArchiveIconButton = styled(Archive)`
  font-size: 20px;
`;

// eslint-disable-next-line no-empty-pattern
export const AccordionCompany = ({
  children,
  title,
  onOpenModal,
  nomad,
  status,
  inviteConsultorDeListagem,
  ativo,
  reactivated,
  type,
}: IButtonProps) => {
  const [currentAccordionExpanded, setCurrentAccordionExpanded] =
    useState<boolean>(false);
  const [rotateStateAccordion, setRotateStateAccordion] =
    useState('accordion__icon');

  const setOpenAccordion = () => {
    // @ts-ignore
    setCurrentAccordionExpanded(!currentAccordionExpanded);

    setRotateStateAccordion(
      currentAccordionExpanded ? 'accordion__icon' : 'accordion__icon rotate',
    );
  };

  const [inviteModal, setInviteModal] = useState(false);

  return (
    <>
      <Accordion
        id="accordion-docs"
        summaryClasses="accordion__header"
        variantstyle="nomad"
        summary={
          <Content>
            <Title>{title}</Title>
            <img
              src="images/arrow-down.svg"
              className={rotateStateAccordion}
              alt=""
            />
            <Buttons>
              {nomad && (
                <Status status={status || 0}>
                  {getStatusConvite[status || 0]}
                </Status>
              )}
              {nomad && (status === 4 || status === 5) && (
                <OutletPermify role={[Roles.ReenviarConsultorListagem]}>
                  <button
                    type="button"
                    className="deleteEditButton"
                    onClick={() => {
                      setInviteModal(true);
                    }}
                    aria-label="Enviar convite"
                  >
                    <SendIcon htmlColor="#212E51" />
                  </button>
                </OutletPermify>
              )}

              {!reactivated &&
                type !== 'auditor' &&
                type !== 'escriturador' && (
                  <OutletPermify role={[Roles.ExcluirCLResp]}>
                    <button
                      type="button"
                      onClick={(event: any) => {
                        event.stopPropagation();
                        onOpenModal ? onOpenModal('delete') : null;
                      }}
                      className="deleteEditButton"
                      aria-label="Deletar empresa"
                    >
                      <DeleteIcon htmlColor="#212E51" />
                    </button>
                  </OutletPermify>
                )}

              {reactivated && ativo && (
                <OutletPermify role={[Roles.ExcluirCLResp]}>
                  <button
                    type="button"
                    onClick={(event: any) => {
                      event.stopPropagation();
                      onOpenModal ? onOpenModal('delete') : null;
                    }}
                    className="deleteEditButton"
                    aria-label="Desativar empresa"
                  >
                    <DeleteIcon htmlColor="#212E51" />
                  </button>
                </OutletPermify>
              )}

              {(type === 'auditor' || type === 'escriturador') && (
                <OutletPermify role={[Roles.ExcluirCLResp]}>
                  <button
                    type="button"
                    onClick={(event: any) => {
                      event.stopPropagation();
                      onOpenModal ? onOpenModal('delete') : null;
                    }}
                    className="deleteEditButton"
                    aria-label="Desativar empresa"
                  >
                    <ArchiveIconButton htmlColor="#212E51" />
                  </button>
                </OutletPermify>
              )}
              {/* )} */}

              {reactivated && !ativo && (
                <OutletPermify role={[Roles.AtivarContaCLResp]}>
                  <button
                    type="button"
                    onClick={(event: any) => {
                      event.stopPropagation();
                      onOpenModal ? onOpenModal('delete') : null;
                    }}
                    className="deleteEditButton"
                    aria-label="Ativar empresa"
                  >
                    <TaskAltIcon htmlColor="#212E51" />
                  </button>
                </OutletPermify>
              )}

              <OutletPermify role={[Roles.EditarCLResp]}>
                <button
                  type="button"
                  onClick={(event: any) => {
                    event.stopPropagation();
                    onOpenModal ? onOpenModal('edit') : null;
                  }}
                  className="deleteEditButton"
                  aria-label="Editar empresa"
                >
                  <EditIcon htmlColor="#212E51" />
                </button>
              </OutletPermify>
              {/* {!nomad && (
                <Button
                  type="button"
                  height="32px"
                  width="108px"
                  fontSize="10px"
                  onClick={(event: any) => {
                    event.stopPropagation();
                    onOpenModal ? onOpenModal('add') : null;
                  }}
                  variant="outlined"
                >
                  <>
                    <span className="plus">+</span>
                    Colaborador
                  </>
                </Button>
              )} */}
            </Buttons>
          </Content>
        }
        handleExpanded={setOpenAccordion}
        value={currentAccordionExpanded}
      >
        {children}
      </Accordion>
      <ResendInviteConsultorListagemModal
        description={inviteModal}
        closeDropdown={() => {
          setInviteModal(false);
        }}
        invite={() => {
          inviteConsultorDeListagem();
        }}
      />
    </>
  );
};
