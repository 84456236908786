import React from 'react';

import { Outlet, Navigate } from 'react-router-dom';
import { useAuth } from '../../hooks/auth';

interface Params {
  pageRole: string[];
}

export const PrivateRoutes = ({ pageRole }: Params) => {
  const { permissions } = useAuth();

  let hasPermission = false;

  pageRole.forEach(item => {
    if (!hasPermission)
      hasPermission = permissions.filter(role => role === item).length > 0;
  });

  return hasPermission ? <Outlet /> : <Navigate to="404" />;
};
