import styled from 'styled-components';

interface IListItemProps {
  description: number;
}

interface ButtonsProps {
  validated: boolean;
}

export const DocumentCard = styled.div`
  background: #ffffff;
  margin: auto;
  border-radius: 0 0 8px 8px;
`;

export const CardHeader = styled.h4`
  background: #e7ebee;
  display: block;
  color: #171725;
  padding: 0.7rem;
  border-radius: 10px 10px 0 0;
`;

export const IndexColumns = styled.div`
  width: 100%;
  color: #171725;
  text-align: left;
  display: flex;
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 1.2px;
  padding: 17px 0 16px;
  margin: auto;
  cursor: default;
`;
export const FirstColumn = styled.div`
  width: 4.25%;
  display: flex;
  align-items: center;
`;
export const SecondColumn = styled.div`
  width: 49.25%;
  display: flex;
  align-items: center;
`;
export const ThirdColumn = styled.div`
  width: 17.8%;
  display: flex;
  align-items: center;
`;

export const FourthColumn = styled.div`
  width: 19.7%;
  display: flex;
  align-items: center;
`;

export const FourthColumn2 = styled.div`
  width: 28.7%;
  display: flex;
  align-items: center;
  padding-right: 1rem;
`;

export const FifthColumn = styled.div`
  width: 9%;
  display: flex;
  align-items: center;
`;

export const TableLine = styled.hr`
  width: 100%;
  height: 1px;
  border: 0;
  margin: auto;
  background-color: #f6f5fc;
  opacity: 1;
`;

export const ContentColumns = styled.div`
  color: #000000;
  font-weight: 400;
  font-family: 'Inter';
  font-size: 12px;
  display: flex;
  padding: 15px 0;
  cursor: default;

  .columnIcon {
    width: 1.2rem;
    height: 1.2rem;
  }
  .fileIcon {
    width: 1.4rem;
    height: 1.4rem;
    margin-right: 0.8rem;
  }
`;

export const DocumentDetail = styled.div`
  display: block;
`;
export const SituationDescription = styled.span<IListItemProps>`
  color: ${props =>
    props.description === 1
      ? '#FFD600'
      : props.description === 2
      ? '#FF9203'
      : props.description === 3
      ? '#BDBDBD'
      : props.description === 4
      ? '#DA1E28'
      : props.description === 5
      ? '#2DB350'
      : '#BDBDBD'};
`;

export const DocumentButtons = styled.div<ButtonsProps>`
  margin-left: auto;
  display: flex;
  gap: 6px;

  button {
    border: none;
    background-color: transparent;
    width: 30px;
    height: 30px;
  }
  .download {
    width: 16.53px;
  }
  .delete {
    width: 12.78px;
    filter: ${props => (props.validated ? 'grayscale(100%) opacity(60%)' : '')};
    cursor: ${props => (props.validated ? 'default' : 'pointer')};
  }
  button:disabled {
    pointer-events: all !important;
  }
`;

export const EmptyContainer = styled.div`
  padding: 7rem 0 7rem 0;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #bdbdbd;
  .emptyIcon {
    width: 3.5rem;
    height: 3.5rem;
    margin-bottom: 1rem;
  }
`;

export const ModalContent = styled.div`
  text-align: center;
`;

export const ModalTitle = styled.h2`
  font-size: 1.5rem;
  color: #171725;
`;

export const ModalDescription = styled.p`
  font-size: 1rem;
  font-weight: 400;
`;

export const ModalFilesSuported = styled.p`
  font-size: 0.9rem;
  color: #bdbdbd;
  font-weight: 400;
  margin-top: 1rem;
`;

export const ExtraArea = styled.div`
  padding: 1rem 0;
  width: 95%;
  margin: auto;
  cursor: default;
`;

export const Description = styled.div`
  font-weight: 600;
  display: flex;
  font-size: 0.8rem;
  align-items: start;
`;

export const DescriptionContentMandatory = styled.div`
  font-weight: 600;
  display: flex;
  flex-direction: column;
  font-size: 0.8rem;
  align-items: start;
  width: 50%;
`;

export const DescriptionMandatoryContent = styled.div`
  width: 50%;
  padding: 0 1rem;
  border-left: 1px solid #f3f4f5;
  font-size: 0.8rem;
`;

export const DescriptionMandatoryArea = styled.div`
  display: flex;
`;

export const OnlyMandatory = styled.div`
  padding: 0 1rem;
  font-size: 0.8rem;
  display: block;
`;

export const DescriptionContent = styled.h3`
  font-weight: 400;
  font-size: 12px;
  margin-left: 1rem;
`;

export const DownloadButton = styled.div`
  margin-top: 2rem;
  width: 280px;
  margin-bottom: 1rem;
`;
