import axios from 'axios';
import { authenticationManager } from './authenticationManager';

export const api = {
  main: axios.create({
    baseURL: process.env.REACT_APP_URL_API_TECH,
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Disposition': 'attachment',
    },
  }),
  users: axios.create({
    baseURL: process.env.REACT_APP_URL_API_USUARIOS,
    headers: {
      'Access-Control-Allow-Origin': '*',
    },
  }),
  default: axios.create({
    baseURL: process.env.REACT_APP_URL_API_TECH,
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Disposition': 'attachment',
    },
  }),
};

api.main.interceptors.request.use(async config => {
  if (!config.headers) {
    return config;
  }
  config.headers.Authorization = `Bearer ${await authenticationManager.getTokenAsync()}`;
  return config;
});

api.users.interceptors.request.use(async config => {
  if (!config.headers) {
    return config;
  }
  config.headers.Authorization = `Bearer ${await authenticationManager.getTokenAsync()}`;
  return config;
});
