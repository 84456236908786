import styled from 'styled-components';

export const CommitteeDecisionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem 0;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
`;

export const DocumentListContent = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1.5rem 0;
`;

export const DocumentListDescription = styled.h3`
  font-family: Inter;
  padding: 16px;
`;

export const Main = styled.main`
  margin-bottom: 50px;
`;

export const Header = styled.section`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-bottom: 20px;
  margin-top: -70px;
`;

export const Date = styled.span`
  font-size: 12px;
  margin-top: -14px;
  font-style: italic;
`;

export const TypeResult = styled.section`
  color: #454b62;
  font-size: 14px;
`;

export const Docs = styled.section`
  display: flex;
  justify-content: flex-end;

  a {
    display: flex;
    align-items: center;
    gap: 10px;
    color: #000;
    font-weight: 600;
  }
`;

export const Wrapper = styled.section`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 15px;
  margin-top: 50px;

  text-align: center;

  div {
    display: flex;
    flex-direction: column;
    gap: 3px;
  }
`;
