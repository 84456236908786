import React, { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useFieldArray, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import DeleteIcon from '@mui/icons-material/Delete';
import { Modal } from '../../../../../../components/modal';
import { Buttons, ModalTitle } from '../../styles';
import { Button } from '../../../../../../components/button';
import { Grid } from '../../../../../../components/grid';
import { Controller } from '../../../../../../components/controller';
import { obterEnderecoAsync } from '../../../../../../services/cep';
import { listStates } from '../../../../../../utils/fields';
import { listAdressType } from '../../../../../dadosGerais/fields';
import {
  dateToTimestamp,
  timestampToDate,
} from '../../../../../../utils/dateHandling';
import useCompanies from '../../../../../../hooks/companies';
import { EmpresaEscolhida, auditoriaSchema } from '../../fields';

interface IButtonProps {
  description: boolean;
  closeDropdown: (e?: any) => void;
  createCompany: (e?: any) => void;
  updateCompany: (v: any) => void;
  chosenCompany?: EmpresaEscolhida;
  defaultValue?: string[];
}

interface FormData {
  razaoSocial?: string;
  cnpj?: string;
  cep?: string;
  rua?: string;
  bairro?: string;
  municipio?: string;
  uf?: string;
  tipoDeEndereco?: number;
  registroNaCvm?: string;
  telefone?: string;
  emailDoResponsavel: string;
  empresas: string[];
}

export const CreateEditAuditoria: React.FC<IButtonProps> = ({
  description,
  closeDropdown,
  createCompany,
  updateCompany,
  chosenCompany,
  defaultValue,
}) => {
  const { companies, isLoading } = useCompanies({
    type: 'auditor',
    id: chosenCompany?.id,
  });

  const [selectedOptions, setSelectedOptions] = useState<string[]>(
    chosenCompany?.empresas?.map((obj: any) => obj.id) ?? [],
  );

  const { handleSubmit, control, setValue, reset, getValues } = useForm<any>({
    resolver: yupResolver(auditoriaSchema),
    mode: 'onSubmit',
  });

  const onClose = () => {
    closeDropdown();
  };

  const submitForm = (company: FormData) => {
    if (!company.razaoSocial) {
      toast.error('Colocar uma razão social para criar o novo auditor');
      return;
    }
    const newComp = {
      ...company,
      registroNaCvm: dateToTimestamp(company?.registroNaCvm),
      empresas: company?.empresas?.map((emp: any) => {
        return {
          contratacaoDaPrestacaoDoServico: dateToTimestamp(
            emp?.contratacaoDaPrestacaoDoServico,
          ),
          empresaId: emp?.empresaId,
        };
      }),
    };
    if (chosenCompany) {
      updateCompany({
        id: chosenCompany?.id,
        company: newComp,
      });
    } else {
      createCompany(newComp);
    }
    onClose();
  };

  const handleEndereco = async (cep: string) => {
    if (!cep || cep?.length !== 8) {
      return null;
    }
    if (cep.length === 8) {
      const { endereco, bairro, cidade, uf } = await obterEnderecoAsync(cep);

      setValue('rua', endereco);
      setValue('bairro', bairro);
      setValue('cidade', cidade);
      setValue('uf', uf);
    }
    return null;
  };

  const handleCompanyOptions = (companyId?: string) => {
    const companyOptions = companies ?? [];
    const companySelected = companyId
      ? companyOptions?.filter(
          (comp: any) =>
            !selectedOptions.includes(comp.id) || comp.id === companyId,
        ) ?? []
      : companyOptions?.filter(
          (comp: any) => !selectedOptions.includes(comp.id),
        ) ?? [];

    return (
      companySelected?.map((company: any) => {
        return {
          value: company.id,
          label: company.nome,
        };
      }) ?? []
    );
  };

  const handleSelectValue = (e: string, index: number) => {
    // Verifica se o índice já existe no array
    if (selectedOptions[index]) {
      // Atualiza o índice existente
      setSelectedOptions(current => {
        const updatedOptions = [...current];
        updatedOptions[index] = e;
        return updatedOptions;
      });
    } else {
      // Adiciona um novo índice ao array
      setSelectedOptions(current => [...current, e]);
    }
  };

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'empresas',
  });

  useEffect(() => {
    reset({
      nome: chosenCompany?.nome ?? '',
      emailDoResponsavel: chosenCompany?.emailDoResponsavel ?? '',
      nomeDoResponsavel: chosenCompany?.nomeDoResponsavel ?? '',
      razaoSocial: chosenCompany?.razaoSocial ?? '',
      cnpj: chosenCompany?.cnpj ?? '',
      telefone: chosenCompany?.telefone ?? '',
      cep: chosenCompany?.cep ?? '',
      rua: chosenCompany?.rua ?? '',
      bairro: chosenCompany?.bairro ?? '',
      cidade: chosenCompany?.cidade ?? '',
      uf: chosenCompany?.uf ?? '',
      tipoDeEndereco: chosenCompany?.tipoDeEndereco,
      cpfDoResponsavel: chosenCompany?.cpfDoResponsavel ?? '',
      registroNaCvm: timestampToDate(chosenCompany?.registroNaCvm) ?? '',
      empresas:
        chosenCompany?.empresas?.map((col: any) => {
          return {
            ...col,
            empresaId: col.id,
            contratacaoDaPrestacaoDoServico: timestampToDate(
              col?.contratacaoDaPrestacaoDoServico,
            ),
          };
        }) || [],
    });
  }, [reset]);

  return (
    <Modal open={description} handleClose={onClose} width={1200}>
      <form onSubmit={handleSubmit(submitForm)}>
        <ModalTitle>
          {chosenCompany ? 'Editar' : 'Registrar'} empresa de auditoria
        </ModalTitle>
        <Grid container spacing={4}>
          <Grid item desktop={6}>
            <Controller
              name="razaoSocial"
              control={control}
              type="input"
              label="Razão social"
            />
          </Grid>
          <Grid item desktop={3}>
            <Controller
              name="cnpj"
              control={control}
              type="input"
              label="CNPJ"
              mask="cnpj"
            />
          </Grid>
          <Grid item desktop={3}>
            <Controller
              name="cep"
              control={control}
              type="input"
              label="CEP"
              mask="cep"
              onBlur={() => handleEndereco(getValues('cep'))}
            />
          </Grid>
          <Grid item desktop={6}>
            <Controller name="rua" control={control} type="input" label="Rua" />
          </Grid>
          <Grid item desktop={6}>
            <Controller
              name="bairro"
              control={control}
              type="input"
              label="Bairro"
            />
          </Grid>
          <Grid item desktop={3}>
            <Controller
              name="cidade"
              control={control}
              type="input"
              label="Município"
            />
          </Grid>
          <Grid item desktop={3}>
            <Controller
              name="uf"
              control={control}
              type="select"
              label="Estado"
              options={listStates}
            />
          </Grid>
          <Grid item desktop={3}>
            <Controller
              name="tipoDeEndereco"
              control={control}
              type="select"
              label="Tipo de endereço"
              options={listAdressType}
            />
          </Grid>
          <Grid item desktop={3}>
            <Controller
              name="registroNaCvm"
              control={control}
              type="input"
              label="Data de registro na CVM"
              mask="date"
            />
          </Grid>
          <Grid item desktop={6}>
            <Controller
              name="nomeDoResponsavel"
              control={control}
              type="input"
              label="Nome do responsável"
            />
          </Grid>
          <Grid item desktop={6}>
            <Controller
              name="emailDoResponsavel"
              control={control}
              type="input"
              label="E-mail do responsável"
            />
          </Grid>
          <Grid item desktop={6}>
            <Controller
              name="cpfDoResponsavel"
              control={control}
              type="input"
              mask="cpf"
              label="CPF do responsável"
            />
          </Grid>
          <Grid item desktop={6}>
            <Controller
              name="telefone"
              control={control}
              type="input"
              mask="phone"
              label="Telefone do responsável"
            />
          </Grid>
          {fields.map((item, index) => (
            <React.Fragment key={item.id}>
              <Grid item desktop={6}>
                <Controller
                  name={`empresas.${index}.empresaId`}
                  control={control}
                  type="select"
                  label="Empresa"
                  options={handleCompanyOptions(
                    getValues(`empresas.${index}.empresaId`),
                  )}
                  disabled={isLoading}
                  onSelectValue={e => {
                    handleSelectValue(e, index);
                  }}
                />
              </Grid>
              <Grid item desktop={5}>
                <Controller
                  name={`empresas.${index}.contratacaoDaPrestacaoDoServico`}
                  control={control}
                  type="input"
                  label="Data de contratação da prestação de serviço"
                  mask="date"
                />
              </Grid>

              {fields.length > 0 && (
                <Grid item desktop={1}>
                  <Button
                    type="button"
                    onClick={() => {
                      if (fields.length > 0) {
                        remove(index);
                      }
                    }}
                    variant="text"
                    disabled={!(fields.length > 0)}
                  >
                    <DeleteIcon htmlColor="#212E51" />
                  </Button>
                </Grid>
              )}
            </React.Fragment>
          ))}
          <Grid item desktop={6}>
            <Button
              onClick={() => {
                append({
                  empresaId: '',
                  contratacaoDaPrestacaoDoServico: '',
                });
              }}
              width="12rem"
              height="2.2rem"
              type="button"
            >
              + ADICIONAR EMPRESA
            </Button>
          </Grid>
        </Grid>

        <Buttons>
          <Button
            width="103px"
            height="40px"
            variant="outlined"
            onClick={() => {
              onClose();
            }}
          >
            CANCELAR
          </Button>
          <Button type="submit" width="103px" height="40px">
            SALVAR
          </Button>
        </Buttons>
      </form>
    </Modal>
  );
};
