import React, { useState } from 'react';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SendIcon from '@mui/icons-material/Send';
import TaskAltIcon from '@mui/icons-material/TaskAlt';

import createTheme from '@material-ui/core/styles/createTheme';
import { Button } from '../../components/button';
import { Container } from '../../components/container';
import {
  ButtonsContainer,
  ContentColumns,
  FifthColumn,
  FirstColumn,
  FourthColumn,
  IndexColumns,
  SecondColumn,
  Status,
  TableRow,
  ThirdColumn,
} from './styles';
import { ShouldRenderChildren } from '../../components/shouldRenderChildren';
import { CreateEditNomadCollaboratorModal } from './modals/createEditCollaboratorModal';
import useConsultorListagem from '../administracaoGlobal/components/companies/hooks/consultorListagem';
import { Collaborator } from '../administracaoGlobal/components/companies/fields';
import { Kebab } from '../../components/kebab';
import { DeleteCollaboratorModal } from './modals/deleteCollaboratorModal';
import { getStatusConvite } from '../../utils/fields';
import { InviteCollaboratorModal } from './modals/inviteCollaboratorModal';
import { Roles } from '../../router/roles.routes';
import { OutletPermify } from '../../components/outletPermify';

export const Colaboradores = () => {
  const [addEditCollaboratorModal, setAddEditCollaboratorModal] =
    useState(false);
  const [inviteModal, setInviteModal] = useState(false);
  const [deleteCollaboratorModal, setDeleteCollaboratorModal] = useState(false);
  const [defaultCompanyValues, setDefaultCompanyValues] = useState<any>([]);
  const [chosenConsulter, setChosenConsulter] = useState<
    Collaborator | undefined
  >(undefined);

  const theme = createTheme({
    shape: {
      borderRadius: 8,
    },
  });

  const {
    nomads,
    createConsultorDeListagemCollaborator,
    updateConsultorDeListagemCollaborator,
    deleteConsultorDeListagemCollaborator,
    activateConsultorDeListagemCollaborator,
    inviteConsultorDeListagemCollaborator,
  } = useConsultorListagem();

  function collaboratorContentList() {
    const options = (status: number, ativo: boolean) => {
      const obj = [];

      if (status === 4 || status === 5)
        obj.push({
          icon: <SendIcon htmlColor="#212E51" />,
          onClick: () => {
            setInviteModal(true);
          },
          name: 'Reenviar',
          roles: [Roles.ReenviarConviteClColab],
        });

      obj.push({
        icon: <EditIcon htmlColor="#212E51" />,
        onClick: () => {
          setAddEditCollaboratorModal(true);
        },
        name: 'Editar',
        roles: [Roles.EditarClColaborador],
      });

      obj.push(
        ativo
          ? {
              icon: <DeleteIcon htmlColor="#212E51" />,
              onClick: () => {
                setDeleteCollaboratorModal(true);
              },
              name: 'Desativar',
              roles: [Roles.ExcluirClColaborador],
            }
          : {
              icon: <TaskAltIcon htmlColor="#212E51" />,
              onClick: () => {
                setDeleteCollaboratorModal(true);
              },
              name: 'Ativar',
              roles: [Roles.AtivarClColaborador],
            },
      );

      return obj;
    };

    if (!nomads) {
      return null;
    }

    return nomads[0]?.consultores.map((consulter: Collaborator) => {
      const empresasNome = consulter.empresasConsultor
        ?.map(emp => {
          const result = nomads[0].empresas?.find(
            item => item.id === emp.empresaId,
          );
          return result?.nome;
        })
        .join(', ');

      const companyTooBig = `${
        (empresasNome ? empresasNome.length : 0) > 38 ? '...' : ''
      }`;

      const empresasId = consulter.empresasConsultor?.map(emp => {
        const result = nomads[0].empresas?.find(
          item => item.id === emp.empresaId,
        );
        return result?.id;
      });

      return (
        <React.Fragment key={consulter.id}>
          <TableRow>
            <FirstColumn>
              <span>{consulter.nome}</span>
            </FirstColumn>
            <SecondColumn>
              <span>{consulter.email}</span>
            </SecondColumn>
            <ThirdColumn>
              <span>{`${
                empresasNome ? empresasNome?.substring(0, 38) : ''
              }${companyTooBig}`}</span>
            </ThirdColumn>
            <FourthColumn>
              <Status
                status={
                  consulter?.statusDoConvite ? consulter?.statusDoConvite : 0
                }
              >
                {
                  getStatusConvite[
                    consulter?.statusDoConvite ? consulter?.statusDoConvite : 0
                  ]
                }
              </Status>
            </FourthColumn>
            <FifthColumn>
              <Kebab
                options={options(
                  consulter?.statusDoConvite ?? 0,
                  consulter?.ativo || false,
                )}
                onChange={e => {
                  setChosenConsulter(consulter);
                  setDefaultCompanyValues(empresasId);
                }}
                onClose={() => {
                  setChosenConsulter(undefined);
                  setDefaultCompanyValues(undefined);
                }}
              />
            </FifthColumn>
          </TableRow>
        </React.Fragment>
      );
    });
  }

  return (
    <>
      <Container style={{ minWidth: '720px' }}>
        <OutletPermify role={[Roles.ConvidarConsultorColab]}>
          <Button
            onClick={() => {
              setAddEditCollaboratorModal(true);
            }}
          >
            <ButtonsContainer>
              <AddCircleOutlineOutlinedIcon /> <p>Adicionar Colaboradores</p>
            </ButtonsContainer>
          </Button>
        </OutletPermify>
        <IndexColumns>
          <FirstColumn>
            <span>Nome do colabolador</span>
          </FirstColumn>
          <SecondColumn>
            <span>E-mail</span>
          </SecondColumn>
          <ThirdColumn>
            <span>Empresa(s)</span>
          </ThirdColumn>
          <FourthColumn>
            <span>Status</span>
          </FourthColumn>
          <FifthColumn />
        </IndexColumns>
        <ContentColumns>{collaboratorContentList()}</ContentColumns>
      </Container>
      <ShouldRenderChildren shouldRender={addEditCollaboratorModal}>
        <CreateEditNomadCollaboratorModal
          description={addEditCollaboratorModal}
          closeDropdown={() => {
            setAddEditCollaboratorModal(false);
            setChosenConsulter(undefined);
          }}
          companyId={nomads ? nomads[0]?.id : ''}
          collaborator={chosenConsulter}
          defaultValue={defaultCompanyValues}
          createCollaborator={createConsultorDeListagemCollaborator}
          updateColaborador={updateConsultorDeListagemCollaborator}
        />
      </ShouldRenderChildren>
      <ShouldRenderChildren shouldRender={deleteCollaboratorModal}>
        <DeleteCollaboratorModal
          description={deleteCollaboratorModal}
          closeDropdown={() => {
            setDeleteCollaboratorModal(false);
            setChosenConsulter(undefined);
          }}
          consulterId={chosenConsulter?.id}
          companyId={nomads ? nomads[0]?.id : ''}
          deleteConsulter={payload =>
            chosenConsulter?.ativo
              ? deleteConsultorDeListagemCollaborator(payload)
              : activateConsultorDeListagemCollaborator(payload)
          }
          status={chosenConsulter?.statusDoConvite ?? 0}
          ativo={chosenConsulter?.ativo || false}
        />
      </ShouldRenderChildren>
      <ShouldRenderChildren shouldRender={inviteModal}>
        <InviteCollaboratorModal
          description={inviteModal}
          closeDropdown={() => {
            setInviteModal(false);
            setChosenConsulter(undefined);
          }}
          consultorId={chosenConsulter?.id}
          nomadId={nomads ? nomads[0]?.id : ''}
          reenviarConvite={inviteConsultorDeListagemCollaborator}
        />
      </ShouldRenderChildren>
    </>
  );
};
