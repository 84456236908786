import React, { CSSProperties } from 'react';
import MaterialCheckbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

interface CheckboxProps {
  onChange: (e?: any) => void;
  label: any;
  checked: boolean;
  disabled?: boolean;
  labelPlacement?: 'end' | 'start' | 'top' | 'bottom' | undefined;
  labelStyle?: CSSProperties;
}

export const Checkbox: React.FC<CheckboxProps> = ({
  label,
  checked,
  onChange,
  disabled,
  labelPlacement,
  labelStyle,
}) => {
  return (
    <FormControlLabel
      label={
        <h4
          style={{ color: '#1F08D0', fontSize: '0.9rem', ...labelStyle }}
        >{` ${label}`}</h4>
      }
      labelPlacement={labelPlacement}
      control={
        <MaterialCheckbox
          checked={checked}
          style={{
            color: '#1F08D0',
          }}
          onChange={onChange}
          disabled={disabled}
        />
      }
    />
  );
};
