import React from 'react';
import { useNavigate } from 'react-router-dom';
import { RedirectContainer } from '../../components/redirectContainer';

export const NotFound = () => {
  const navigate = useNavigate();

  return (
    <RedirectContainer
      title="404"
      description={
        <span style={{ marginBottom: '2rem' }}>Página não encontrada</span>
      }
      buttonDescription="Voltar para o sistema"
      onClick={() => navigate('/')}
    />
  );
};
