import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useEnterprise } from '../../hooks/enterprise';
import { useAuth } from '../../hooks/auth';
import useListagem from './hooks/useDocumentacaoListagem';
import { LoadingDashboard } from '../dashboardListagem/loading';
import { ButtonContainer, DocListagemContent, Wrapper } from './styles';
import { DashboardProgress } from '../../components/dashboardProgress';
import { statusCompletedListagem } from '../dashboardListagem/fields';
import { EmptyDocState } from './components/emptyDocState';
import { OrientationListagem } from './components/orientationListagem';
import { CategoriesTable } from '../../components/categoriesTable';
import { ShouldRenderChildren } from '../../components/shouldRenderChildren';
import { verifyError } from '../../utils/verifyError';
import { Button } from '../../components/button';
import { ClSendDocListagemModal } from './components/clSendDocListagemModal';
import { DownloadDocs } from './components/downloadDocs';
import { CommitteeDecision } from './components/committeeDecision';
import { OutletPermify } from '../../components/outletPermify';
import { Roles } from '../../router/roles.routes';

export const DocumentacaoListagem = () => {
  const navigate = useNavigate();
  const { currentEnterpriseId } = useEnterprise();
  const { currentRole } = useAuth();
  const [clModal, setClModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);

  const {
    documentacaoListagem,
    isLoading,
    updateStatusCategoria,
    refreshDocsCategoria,
    sendDocListagemToCL,
    aproveDocListagembyBEE4,
    deleteCategoriaListagem,
    editPrazoListagem,
    uploadDocListagem,
    addReviewListagem,
    sendNewDocsListagemToBEE4,
    setListagemStatus,
    deleteStatusDocListagem,
    uplaodNewDocsStatusListagem,
    addAdicionalDecisionListagem,
    uplaodAdicionalDocsStatusListagem,
    deleteAdicionalDocListagem,
    editDecisionTypeListagem,
    downloadDocsListagem,
    sendBEE4Listagem,
    updateAdicionalDecisionListagem,
  } = useListagem({
    currentEnterpriseId,
  });

  const status = documentacaoListagem?.statusDocumentacaoListagem;

  const completed = statusCompletedListagem(status);

  const lastAprovacao = documentacaoListagem
    ?.listaDeAprovacaoAdicionalListagemBEE4?.length
    ? documentacaoListagem?.listaDeAprovacaoAdicionalListagemBEE4[
        documentacaoListagem?.listaDeAprovacaoAdicionalListagemBEE4?.length - 1
      ]
    : documentacaoListagem?.aprovacaoListagemBEE4;

  const disableCL =
    currentRole === 'NOMADBOSS' &&
    !documentacaoListagem?.submeterParaAnaliseDaBEE4;

  const disableBEE4 =
    currentRole === 'BEE4' &&
    (!documentacaoListagem?.submeterDecisaoDoComite ||
      status === 5 ||
      (status === 3 && lastAprovacao?.avaliacaoBEE4 === 4));

  const disableButton = disableCL || disableBEE4;

  // desabilitar todos os botões da decisão do comitê
  const disableCommitteeDecision =
    documentacaoListagem?.statusDocumentacaoListagem !== 4;

  const handleSubmitText = () => {
    if (status === 2 && currentRole === 'TECHINTERNO')
      return 'ENVIAR PARA VALIDAÇÃO';

    if (status === 3 && currentRole === 'NOMADBOSS')
      return 'ENVIAR PARA ANÁLISE';

    if (status === 4 && currentRole === 'BEE4') return 'NOTIFICAR DECISÃO';

    if (
      (status === 5 || (status === 3 && lastAprovacao?.avaliacaoBEE4 === 4)) &&
      currentRole === 'BEE4'
    )
      return 'O EMISSOR FOI NOTIFICADO SOBRE ESTA DECISÃO';

    return '';
  };

  const handleSubmit = () => {
    if (status === 2) {
      sendDocListagemToCL();
    } else if (status === 3) {
      setClModal(true);
    } else if (status === 4) {
      aproveDocListagembyBEE4();
    }
  };

  const handleButton = () => {
    if (
      (status === 2 && currentRole === 'TECHINTERNO') ||
      (status === 3 && currentRole === 'NOMADBOSS') ||
      ((status === 4 ||
        status === 5 ||
        (status === 3 && lastAprovacao?.avaliacaoBEE4 === 4)) &&
        currentRole === 'BEE4')
    ) {
      return true;
    }
    return false;
  };

  const handleButtonText = () => {
    if (status === 3 && currentRole === 'NOMADBOSS')
      return (
        <>
          Quando a empresa estiver com todos os documentos validados, é
          necessário enviar para análise.
        </>
      );

    if (status === 2 && currentRole === 'TECHINTERNO')
      return (
        <p>
          Após fazer o upload dos documentos, é necessário enviar para revisão e
          validação do Consultor de Listagem Responsável.
        </p>
      );

    return <></>;
  };

  const sendDocListagemToBEE4 = (payload?: any) => {
    sendBEE4Listagem.mutate(payload, {
      onSuccess: async res => {
        toast.dismiss();
        if (res?.status === 200 || res?.status === 201) {
          setConfirmModal(true);
        }
      },
      onError: async (err: any) => {
        toast.dismiss();
        const message = verifyError(
          err,
          'Erro ao tentar enviar a documentação',
        );
        toast.error(message);
      },
    });
  };

  if (!documentacaoListagem && isLoading) return <LoadingDashboard />;

  if (!documentacaoListagem && !isLoading) {
    return (
      <DocListagemContent>
        <DashboardProgress completed={statusCompletedListagem(2)} type={2} />
        <EmptyDocState />
      </DocListagemContent>
    );
  }

  if (status === 1) {
    return <></>;
  }

  if (currentRole !== 'TECHINTERNO' && status === 2) {
    return (
      <DocListagemContent>
        <DashboardProgress completed={completed} type={2} />
        <EmptyDocState />
      </DocListagemContent>
    );
  }

  return (
    <>
      <DocListagemContent>
        <DashboardProgress completed={completed} type={2} />
        <OrientationListagem
          status={status ?? 0}
          documentacaoListagem={documentacaoListagem}
        />

        <CategoriesTable
          categories={documentacaoListagem?.categorias}
          onStatusChange={updateStatusCategoria}
          refreshDocsCategoria={refreshDocsCategoria}
          status={status ?? 1}
          deleteCategoria={deleteCategoriaListagem}
          editPrazo={editPrazoListagem}
          uploadDoc={uploadDocListagem}
          addReview={addReviewListagem}
          sendNewDocsToBEE4={sendNewDocsListagemToBEE4}
          analyzedByCommittee={
            !!documentacaoListagem?.aprovacaoBEE4?.notificacaoDaDecisaoDoComite
          }
          type={2}
        />

        <ShouldRenderChildren
          shouldRender={
            currentRole === 'BEE4' &&
            (status === 4 ||
              status === 5 ||
              (status === 3 && lastAprovacao?.avaliacaoBEE4 === 4))
          }
        >
          <>
            <OutletPermify role={[Roles.BaixarListaDocZipListagem]}>
              <DownloadDocs downloadDocsIpo={downloadDocsListagem} />
            </OutletPermify>
            <OutletPermify
              role={[Roles.VisualizarDecisaoComite, Roles.StatusDocListagem]}
            >
              <CommitteeDecision
                setIpoStatus={setListagemStatus}
                documentacaoIPO={documentacaoListagem}
                deleteStatusDocIpo={deleteStatusDocListagem}
                uplaodNewDocsStatusIpo={uplaodNewDocsStatusListagem}
                addAdicionalDecisionIpo={addAdicionalDecisionListagem}
                updateAdicionalDecisionIpo={updateAdicionalDecisionListagem}
                uplaodAdicionalDocsStatusIpo={uplaodAdicionalDocsStatusListagem}
                deleteAdicionalDocIpo={deleteAdicionalDocListagem}
                editDecisionTypeIpo={editDecisionTypeListagem}
                isDisable={disableCommitteeDecision}
              />
            </OutletPermify>
          </>
        </ShouldRenderChildren>
        <ShouldRenderChildren shouldRender={handleButton()}>
          <Wrapper
            center={
              !(
                (status === 4 ||
                  status === 5 ||
                  (status === 3 && lastAprovacao?.avaliacaoBEE4 === 4)) &&
                currentRole === 'BEE4'
              )
            }
          >
            <span className="container">{handleButtonText()}</span>
            <ButtonContainer
              hasText={currentRole === 'BEE4' && status === 4}
              justify={
                (status === 4 ||
                  status === 5 ||
                  (status === 3 && lastAprovacao?.avaliacaoBEE4 === 4)) &&
                currentRole === 'BEE4'
                  ? 'flex-end'
                  : 'center'
              }
            >
              <OutletPermify
                role={[Roles.EnviarParaAnalise, Roles.NotificarDecisao]}
              >
                <Button
                  width="220px"
                  height="40px"
                  onClick={() => {
                    handleSubmit();
                  }}
                  disabled={disableButton}
                >
                  <strong>{handleSubmitText()}</strong>
                </Button>
              </OutletPermify>
            </ButtonContainer>
          </Wrapper>
        </ShouldRenderChildren>
      </DocListagemContent>
      <ShouldRenderChildren shouldRender={clModal}>
        <ClSendDocListagemModal
          description={clModal}
          onConfirm={() => {
            sendDocListagemToBEE4();
          }}
          closeDropdown={() => {
            setClModal(false);
            setConfirmModal(false);
          }}
          confirmModal={confirmModal}
        />
      </ShouldRenderChildren>
    </>
  );
};
