import * as React from 'react';
import OpenInNewSharpIcon from '@mui/icons-material/OpenInNewSharp';
import { Link } from 'react-router-dom';
import {
  ContentDescription,
  IndexDescription,
  SelectContainer,
  SelectStatusLabel,
  SelectStatusLabelCircle,
  StatusContainer,
  TableHead,
  Table,
  TableRow,
  TableCell,
  TableBody,
} from './styles';
import {
  StatusType,
  routeName,
  statusMap,
} from '../../pages/dashboardListagem/fields';
import { handleDateOrHour } from '../../utils/dateHandling';
import { useAuth } from '../../hooks/auth';
import { Select } from '../select';
import { useEnterprise } from '../../hooks/enterprise';
import { OutletPermify } from '../outletPermify';
import { Roles } from '../../router/roles.routes';
import { validarRoles } from '../../utils/roles';

export interface DataTable {
  route: string;
  data?: StatusType;
  roles?: string[];
}

interface tableProps {
  data: DataTable[];
  loadingChanges: boolean;
  setRevisonModal: (e: any) => void;
  handleStatusChange: (a: any, b: any) => void;
}

export default function CustomizedTables({
  data,
  loadingChanges,
  setRevisonModal,
  handleStatusChange,
}: tableProps) {
  const { permissions } = useAuth();
  const { resultadoDoComiteBEE4, visualizouDecisaoDoComite } = useEnterprise();

  const isHighlighted = (status: number) => {
    if (
      status !== 5 &&
      [1, 4].includes(resultadoDoComiteBEE4 ?? 0) &&
      visualizouDecisaoDoComite
      // [
      //   'NOMADBOSS',
      //   'NOMAD',
      //   'Empreendedor',
      //   'CVM',
      //   'TECHINTERNO',
      //   'TECHTERCEIRO',
      // ].includes(currentRole) &&
    )
      return true;

    return false;
  };

  const linhaComAlteracaoDeStatus = (d: any, i: number) => (
    <TableRow
      key={d.route}
      style={{
        border: isHighlighted(d.data?.status ?? 0)
          ? '1px solid var(--Amarelo-2, #FFB800)'
          : 'none',
      }}
    >
      <TableCell className="tableCell-1">
        <ContentDescription>{routeName[d.route]}</ContentDescription>
      </TableCell>
      <TableCell className="tableCell-2">
        <ContentDescription>
          {handleDateOrHour(d?.data?.atualizadoEm ?? d?.data?.criadoEm)}
        </ContentDescription>
      </TableCell>
      <TableCell className="tableCell-3">
        <StatusContainer status={d?.data?.status ?? 1}>
          <p>{statusMap[d?.data?.status ?? 1]}</p>
        </StatusContainer>
      </TableCell>
      <TableCell className="tableCell-4">
        <Link
          to={d.route}
          style={{
            display: 'flex',
          }}
        >
          <OpenInNewSharpIcon style={{ color: '#0852DD' }} />
        </Link>
      </TableCell>
    </TableRow>
  );

  const linhaSemAlteracaoStatus = (d: any, i: number) => (
    <TableRow
      key={d.route}
      style={{
        border: isHighlighted(d.data?.status ?? 0)
          ? '1px solid var(--Amarelo-2, #FFB800)'
          : 'none',
      }}
    >
      <TableCell className="tableCell-1">
        <ContentDescription>{routeName[d.route]}</ContentDescription>
      </TableCell>
      <TableCell className="tableCell-2">
        <ContentDescription>
          {handleDateOrHour(d?.data?.atualizadoEm ?? d?.data?.criadoEm)}
        </ContentDescription>
      </TableCell>
      <TableCell className="tableCell-3">
        <SelectContainer>
          <Select
            key={`step${i + 1}`}
            value={d?.data?.status ?? 1}
            onChange={e => {
              if (e === 2) {
                setRevisonModal([true, i + 1, e]);
              } else {
                handleStatusChange(i + 1, e);
              }
            }}
            options={Object.keys(statusMap).map(item => ({
              value: parseInt(item),
              label: (
                <SelectStatusLabel>
                  <SelectStatusLabelCircle status={parseInt(item)} />
                  {statusMap[parseInt(item)]}
                </SelectStatusLabel>
              ),
            }))}
            height="32px"
            width="180px"
            disabled={!d?.data?.podeAlterarOStatus || loadingChanges}
            disableChange
          />
        </SelectContainer>
      </TableCell>
      <TableCell className="tableCell-4">
        <Link
          to={d.route}
          style={{
            display: 'flex',
          }}
        >
          <OpenInNewSharpIcon style={{ color: '#0852DD' }} />
        </Link>
      </TableCell>
    </TableRow>
  );

  return (
    <Table aria-label="customized table">
      <TableHead>
        <TableCell className="tableCell-1">
          <IndexDescription>SEÇÕES</IndexDescription>
        </TableCell>
        <TableCell className="tableCell-2">
          <IndexDescription>ÚLTIMA ATUALIZAÇÃO</IndexDescription>
        </TableCell>
        <TableCell className="tableCell-3">
          <IndexDescription>STATUS</IndexDescription>
        </TableCell>
        <TableCell className="tableCell-4" />
      </TableHead>

      <TableBody>
        {data.map((d, i) => {
          if (validarRoles(permissions, [Roles.EditarStatusDashboard])) {
            return !!d.roles && d.roles.length ? (
              <OutletPermify role={d.roles}>
                {linhaSemAlteracaoStatus(d, i)}
              </OutletPermify>
            ) : (
              linhaSemAlteracaoStatus(d, i)
            );
          }

          return !!d.roles && d.roles.length ? (
            <OutletPermify role={d.roles}>
              {linhaComAlteracaoDeStatus(d, i)}
            </OutletPermify>
          ) : (
            linhaComAlteracaoDeStatus(d, i)
          );
        })}
      </TableBody>
    </Table>
  );
}
