import React from 'react';

export type RenderSkeletonOrContentProps = {
  fallback: React.ReactElement;
  content: React.ReactElement;
  isLoading: boolean;
};

const RenderSkeletonOrContent: React.FC<RenderSkeletonOrContentProps> = ({
  fallback,
  content,
  isLoading,
}) => {
  return isLoading ? fallback : content;
};

export default RenderSkeletonOrContent;
