import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useAuth } from '../../../../hooks/auth';
import { useEnterprise } from '../../../../hooks/enterprise';
import { useDocuments } from '../../hooks/use-documents';
import { DocumentsContainer } from '../documentsContainer';
import { Button } from '../../../button';

import { verifyError } from '../../../../utils/verifyError';
import {
  Container,
  ExportContainer,
  Export,
  DescriptionListContent,
} from './styles';
import { toBase64 } from '../../../../services/toBase64';
import { AccordionDocuments } from './accordion';
import { UploadModal } from '../../../uploadModal';
import { diligenciaIdentifier, reportIdentifier } from './fields';
import { OutletPermify } from '../../../outletPermify';
import { Roles } from '../../../../router/roles.routes';
import { validarRoles } from '../../../../utils/roles';

export const DocumentList = ({
  stage,
  readOnly,
  dossieCantUpload,
  diligenciaType,
  reportMenu,
  reportOrder,
  year,
}: {
  stage: any;
  readOnly?: boolean;
  dossieCantUpload?: boolean;
  diligenciaType?: number;
  reportMenu?: number;
  reportOrder?: number;
  year?: number;
}) => {
  const [addModal, setAddModal] = useState(false);
  const [chosenCategory, setChosenCategory] = useState<any>({});
  const { currentRole, permissions } = useAuth();
  const { currentEnterpriseId } = useEnterprise();

  const {
    categories,
    isLoading,
    createDocument,
    isSuccess,
    updateDeclaracaoDocumento,
    deleteDocument,
    downloadDocument,
    updateStatusDocument,
    updateDocument,
  } = useDocuments({
    currentEnterpriseId,
    stage,
    year,
  });

  const isPosListagem = stage === 'relatorio';

  const handleOpenModal = (e: any, category: any) => {
    e.stopPropagation();
    setAddModal(true);
    setChosenCategory(category);
  };

  const handleSave = async (files: File[]) => {
    if (!files) {
      toast.error('Coloque um documento antes de salvar');
    } else {
      const base64Content = await toBase64(files[0]);
      const nome = files[0].name;
      const etapa = chosenCategory?.etapa;

      const categoriaId = chosenCategory?.id;

      if (base64Content) {
        const payload: any = {
          nome,
          // @ts-ignore
          base64Content,
          etapa,
          categoriaId,
        };
        createDocument.mutate(payload, {
          onSuccess: async res => {
            if (res?.status === 200 || res?.status === 201) {
              toast.success('Upload do documento realizado com sucesso!');
              setAddModal(false);
            }
          },
          onError: async (err: any) => {
            const message = verifyError(err, 'Erro ao dar upload no documento');
            toast.error(message);
          },
        });
      }
    }
  };

  const listCategory =
    categories != null ? (
      stage === 'diligencia' && diligenciaType ? (
        categories
          .filter((cat: any) => cat.segmentoDaDiligencia === diligenciaType)
          .map((category: any) => {
            return (
              <div key={category.id} style={{ padding: '0.7rem 0' }}>
                <AccordionDocuments
                  title={category.nomeDaCategoria}
                  description={category.informacao}
                  mandatory={category.obrigatorio}
                  readOnly={
                    readOnly ||
                    category.isReadOnly ||
                    category.declaracaoDocumento === 1 ||
                    category.declaracaoDocumento === 2
                  }
                  onOpenModal={e => handleOpenModal(e, category)}
                  dossieCantUpload={dossieCantUpload}
                >
                  <DocumentsContainer
                    currentRole={currentRole}
                    currentEnterpriseId={currentEnterpriseId}
                    stage={stage}
                    category={category}
                    readOnly={
                      readOnly !== undefined ? readOnly : category.isReadOnly
                    }
                    posListagem={isPosListagem}
                    deleteDocument={deleteDocument}
                    downloadDocument={downloadDocument}
                    updateStatusDocument={updateStatusDocument}
                    updateDocument={updateDocument}
                    updateDeclaracaoDocumento={updateDeclaracaoDocumento}
                  />
                </AccordionDocuments>
              </div>
            );
          })
      ) : stage === 'relatorio' && reportMenu === 2 && reportOrder ? (
        categories
          .filter((cat: any) => cat.periodoDoRelatorio === reportOrder)
          .map((category: any) => {
            if (category.tipoDoRelatorio === 2)
              return (
                <div key={category.id} style={{ padding: '0.7rem 0' }}>
                  <AccordionDocuments
                    title={category.nomeDaCategoria}
                    description={category.informacao}
                    mandatory={category.obrigatorio}
                    readOnly={
                      !validarRoles(permissions, [
                        Roles.EditarRelatorio,
                        Roles.FazerUploadDocumento,
                      ]) ||
                      readOnly ||
                      category.isReadOnly ||
                      category.declaracaoDocumento === 1 ||
                      category.declaracaoDocumento === 2
                    }
                    onOpenModal={e => handleOpenModal(e, category)}
                    dossieCantUpload={dossieCantUpload}
                  >
                    <DocumentsContainer
                      category={category}
                      currentRole={currentRole}
                      currentEnterpriseId={currentEnterpriseId}
                      stage={stage}
                      readOnly={
                        !validarRoles(permissions, [Roles.EditarRelatorio]) ||
                        readOnly !== undefined
                          ? readOnly
                          : category.isReadOnly
                      }
                      posListagem={isPosListagem}
                      deleteDocument={deleteDocument}
                      downloadDocument={downloadDocument}
                      updateStatusDocument={updateStatusDocument}
                      updateDocument={updateDocument}
                      updateDeclaracaoDocumento={updateDeclaracaoDocumento}
                    />
                  </AccordionDocuments>
                </div>
              );
          })
      ) : stage === 'relatorio' && reportMenu === 3 ? (
        categories.map((category: any) => {
          if (category.tipoDoRelatorio === 3)
            return (
              <div key={category.id} style={{ padding: '0.7rem 0' }}>
                <AccordionDocuments
                  title={category.nomeDaCategoria}
                  description={category.informacao}
                  mandatory={category.obrigatorio}
                  readOnly={
                    !validarRoles(permissions, [
                      Roles.EditarRelatorio,
                      Roles.FazerUploadDocumento,
                    ]) ||
                    readOnly ||
                    category.isReadOnly ||
                    category.declaracaoDocumento === 1 ||
                    category.declaracaoDocumento === 2
                  }
                  onOpenModal={e => handleOpenModal(e, category)}
                  dossieCantUpload={dossieCantUpload}
                >
                  <DocumentsContainer
                    currentRole={currentRole}
                    currentEnterpriseId={currentEnterpriseId}
                    stage={stage}
                    category={category}
                    readOnly={
                      readOnly !== undefined ? readOnly : category.isReadOnly
                    }
                    posListagem={isPosListagem}
                    deleteDocument={deleteDocument}
                    downloadDocument={downloadDocument}
                    updateStatusDocument={updateStatusDocument}
                    updateDocument={updateDocument}
                    updateDeclaracaoDocumento={updateDeclaracaoDocumento}
                  />
                </AccordionDocuments>
              </div>
            );
        })
      ) : (
        categories.map((category: any) => {
          return (
            <div key={category.id} style={{ padding: '0.7rem 0' }}>
              <AccordionDocuments
                title={category.nomeDaCategoria}
                description={category.informacao}
                mandatory={category.obrigatorio}
                readOnly={
                  readOnly ||
                  category.isReadOnly ||
                  category.declaracaoDocumento === 1 ||
                  category.declaracaoDocumento === 2
                }
                onOpenModal={e => handleOpenModal(e, category)}
                dossieCantUpload={dossieCantUpload}
              >
                <DocumentsContainer
                  currentRole={currentRole}
                  currentEnterpriseId={currentEnterpriseId}
                  stage={stage}
                  category={category}
                  readOnly={
                    readOnly !== undefined ? readOnly : category.isReadOnly
                  }
                  posListagem={isPosListagem}
                  deleteDocument={deleteDocument}
                  downloadDocument={downloadDocument}
                  updateStatusDocument={updateStatusDocument}
                  updateDocument={updateDocument}
                  updateDeclaracaoDocumento={updateDeclaracaoDocumento}
                />
              </AccordionDocuments>
            </div>
          );
        })
      )
    ) : (
      <></>
    );

  if (stage === 'empresa' || stage === 'emissao') {
    return (
      <Container>
        <DescriptionListContent>
          <h2>Anexos</h2>
        </DescriptionListContent>
        {isLoading ? 'Aguarde...' : isSuccess ? listCategory : ''}
        <UploadModal
          openModal={addModal}
          onSave={files => {
            handleSave(files);
          }}
          onClose={() => {
            setAddModal(false);
          }}
          dialogTitle="Importar documentos"
        />
      </Container>
    );
  }

  if (stage === 'dossie') {
    return (
      <Container>
        <DescriptionListContent>
          <h2>Lista de documentos</h2>
        </DescriptionListContent>
        {isLoading ? 'Aguarde...' : isSuccess ? listCategory : ''}
        <UploadModal
          openModal={addModal}
          dialogTitle="Importar documentos"
          onSave={files => {
            handleSave(files);
          }}
          onClose={() => {
            setAddModal(false);
          }}
        />
      </Container>
    );
  }
  if (stage === 'relatorio') {
    if (reportOrder && reportMenu === 2)
      return (
        <Container>
          <DescriptionListContent>
            <h2>{reportIdentifier(reportOrder)}</h2>
          </DescriptionListContent>
          {isLoading ? 'Aguarde...' : isSuccess ? listCategory : ''}
          <OutletPermify role={[Roles.FazerUploadRelatorio]}>
            <UploadModal
              openModal={addModal}
              dialogTitle="Importar documentos"
              onSave={files => {
                handleSave(files);
              }}
              onClose={() => {
                setAddModal(false);
              }}
            />
          </OutletPermify>
        </Container>
      );
    return (
      <>
        {isLoading ? 'Aguarde...' : isSuccess ? listCategory : ''}
        <UploadModal
          openModal={addModal}
          dialogTitle="Importar documentos"
          onSave={files => {
            handleSave(files);
          }}
          onClose={() => {
            setAddModal(false);
          }}
        />
      </>
    );
  }

  if (stage === 'diligencia') {
    if (
      categories?.filter(
        (cat: any) => cat.segmentoDaDiligencia === diligenciaType,
      ).length
    ) {
      return (
        <Container>
          <DescriptionListContent>
            <h2>{diligenciaIdentifier(diligenciaType)}</h2>
          </DescriptionListContent>
          {isLoading ? 'Aguarde...' : isSuccess ? listCategory : ''}
          <UploadModal
            openModal={addModal}
            dialogTitle="Importar documentos"
            onSave={files => {
              handleSave(files);
            }}
            onClose={() => {
              setAddModal(false);
            }}
          />
        </Container>
      );
    }
    return <></>;
  }

  return (
    <Container>
      {/* {currentRole === 'BEE4' && (
        <ExportContainer>
          <span>Enviar link com zip de todos os documentos por e-mail</span>
          <Export>
            <img src="images/pasta-azul.svg" alt="" />
            <span>DOCUMENTOS PRÉ-VALIDAÇÃO</span>
            <Button width="120px">Enviar</Button>
          </Export>
        </ExportContainer>
      )} */}
      <DescriptionListContent>
        <h2>Lista de documentos</h2>
      </DescriptionListContent>
      {isLoading ? 'Aguarde...' : isSuccess ? listCategory : ''}
      <UploadModal
        openModal={addModal}
        dialogTitle="Importar documentos"
        onSave={files => {
          handleSave(files);
        }}
        onClose={() => {
          setAddModal(false);
        }}
      />
    </Container>
  );
};
