import React, { ChangeEvent, DragEvent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@mui/icons-material/Close';
import { Button } from '@mui/material';
import { toast } from 'react-toastify';
import saveAs from 'file-saver';
import {
  UplaodContent,
  ButtonContent,
  DescriptionButton,
  CancelUploadContent,
} from './styles';
import { toBase64 } from '../../services/toBase64';
import { base64ToArrayBuffer, getMimeType } from '../objectRenderer/utils';

const useStyles = makeStyles(theme => ({
  input: {
    display: 'none',
  },
}));

interface UploadButtonProps {
  selectedFile: { base64Content: string; nome: string } | null;
  onChange: (
    fileData: { base64Content: string | null; nome: string | null } | null,
  ) => void;
  handleDragOver: (event: DragEvent<HTMLDivElement>) => void;
  handleDrop: (event: DragEvent<HTMLDivElement>) => void;
  disabled?: boolean;
  max_width?: number;
  label?: string | React.ReactNode;
  description?: string | React.ReactNode;
  acceptedFiles?: string[];
}

export const UploadButton: React.FC<UploadButtonProps> = ({
  selectedFile,
  handleDragOver,
  handleDrop,
  onChange,
  disabled = false,
  max_width = 220,
  label,
  description,
  acceptedFiles,
}) => {
  const classes = useStyles();

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if (acceptedFiles && file) {
      const fileExtension = file.name.split('.').pop()?.toLowerCase();
      if (fileExtension && acceptedFiles.includes(`.${fileExtension}`)) {
        const base64Content = (await toBase64(file)) as string;
        const nome = file.name;

        onChange({ base64Content, nome });
      } else {
        toast.error(`Tipo de arquivo não suportado.`);
        onChange(null);
      }
    } else if (file) {
      const base64Content = (await toBase64(file)) as string;
      const nome = file.name;

      // Verificar a largura da imagem
      const reader = new FileReader();
      reader.onload = e => {
        const img = new Image();
        img.onload = () => {
          if (img.width > max_width) {
            toast.error(`Largura máxima para imagem: ${max_width}px`);
            onChange(null);
          } else {
            onChange({ base64Content, nome });
          }
        };
        img.src = e.target?.result as string;
      };
      reader.readAsDataURL(file);
    } else {
      onChange(null);
    }
  };

  const handleDownload = () => {
    if (selectedFile?.base64Content) {
      const fileType = selectedFile?.nome?.split('.')?.pop();

      const mimeType = getMimeType(fileType ?? 'pdf');

      if (!mimeType) {
        console.error('Tipo de arquivo inválido');
        return null;
      }

      const fileArrayBuffer = base64ToArrayBuffer(selectedFile?.base64Content);

      if (!mimeType) {
        console.error('Tipo de arquivo inválido');
        return null;
      }

      const blob = new Blob([fileArrayBuffer], {
        type: mimeType,
      });

      const objectUrl = URL.createObjectURL(blob);

      if (selectedFile?.nome) {
        saveAs(objectUrl, selectedFile?.nome);
      } else {
        saveAs(objectUrl);
      }
    }
  };

  if (selectedFile?.base64Content && selectedFile?.nome) {
    return (
      <UplaodContent
        onClick={() => {
          handleDownload();
        }}
      >
        <div className="content-uploaded">
          <ButtonContent>
            <img src="images/download-blue-icon.svg" alt="" />
            <DescriptionButton>
              <h4>{selectedFile.nome}</h4>
              <p>{description}</p>
            </DescriptionButton>
          </ButtonContent>
          <CancelUploadContent>
            <Button
              variant="text"
              onClick={event => {
                event.stopPropagation();
                onChange(null);
              }}
              disabled={disabled}
            >
              <CloseIcon
                sx={{
                  color: '#262936',
                  width: '1rem',
                  height: '1rem',
                }}
              />
            </Button>
            <span>Enviado</span>
          </CancelUploadContent>
        </div>
      </UplaodContent>
    );
  }

  return (
    <UplaodContent onDragOver={handleDragOver} onDrop={handleDrop}>
      <div>
        <input
          accept={
            acceptedFiles ? acceptedFiles.join(',') : 'image/png, image/jpeg'
          }
          className={classes.input}
          id="file-upload"
          type="file"
          onChange={handleFileChange}
          disabled={disabled}
        />
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label htmlFor="file-upload">
          <Button
            variant="text"
            color="primary"
            component="span"
            style={{ width: '100%' }}
            disabled={disabled}
          >
            <ButtonContent>
              <img src="images/upload-blue-icon.svg" alt="" />
              <DescriptionButton>
                <h4>{label}</h4>
                <p>{description}</p>
              </DescriptionButton>
            </ButtonContent>
          </Button>
        </label>
      </div>
    </UplaodContent>
  );
};

export default UploadButton;
