import React, { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { blue } from '@mui/material/colors';
import { Link } from 'react-router-dom';
import { Modal } from '../modal';
import { Checkbox } from '../checkbox';
import {
  ModalCheckboxContainer,
  ModalButtons,
  ModalContent,
  DescriptionCheckbox,
  Flex,
} from './styles';

import { Button } from '../button';
import { declaracoesEmissor } from './fields';

interface IButtonProps {
  description: boolean;
  closeDropdown: (e?: any) => void;
  onConfirm: (e?: any) => void;
  isConfirmed?: boolean;
}

export const DeclarationModal: React.FC<IButtonProps> = ({
  description,
  closeDropdown,
  onConfirm,
  isConfirmed = false,
}) => {
  const [valueList, setValueList] = useState<boolean[]>(
    declaracoesEmissor?.map(() => {
      if (isConfirmed) {
        return true;
      }
      return false;
    }),
  );

  const handleChange = (index: number) => {
    setValueList(prevState => {
      const updatedList = [...prevState];
      updatedList[index] = !updatedList[index];
      return updatedList;
    });
  };

  const declarationList = (index: number) => {
    if (index === 0)
      return (
        <span onClick={() => handleChange(index)}>
          A Empresa Emissora tem receita bruta anual individual ou consolidada
          de R$ 10 milhões até R$ 300 milhões apurada no exercício social
          encerrado no ano anterior à oferta.
        </span>
      );
    if (index === 1)
      return (
        <span onClick={() => handleChange(index)}>
          A Empresa Emissora não possui registro de Emissor de Valores
          Mobiliários perante a CVM.
        </span>
      );
    if (index === 2)
      return (
        <span onClick={() => handleChange(index)}>
          A Empresa Emissora é operacional há, no mínimo, 12 meses.
        </span>
      );
    if (index === 3)
      return (
        <span onClick={() => handleChange(index)}>
          A Empresa Emissora é organizada sob a forma de sociedade anônima
          (S.A.) ou sociedade limitada (LTDA.). *No caso da Listagem de Valores
          mobiliários previstos na Lei 6.404/76, será obrigatória a organização
          sob a forma de sociedade anônima.
        </span>
      );
    if (index === 4)
      return (
        <span onClick={() => handleChange(index)}>
          Me comprometo a informar, no prazo de 5 (cinco) dias úteis, quaisquer
          alterações que vierem a ocorrer nos dados cadastrais da Empresa
          Emissora.
        </span>
      );
    if (index === 5)
      return (
        <span onClick={() => handleChange(index)}>
          Me comprometo a colocar no Sistema de Emissores apenas informações e
          documentos verdadeiros e que estão de acordo com a documentação
          comprobatória.
        </span>
      );
    if (index === 6)
      return (
        <span onClick={() => handleChange(index)}>
          Li, compreendi e estou plenamente de acordo com os termos e condições
          contidas nos Manuais da BEE4, que se encontram disponíveis no{' '}
          <Link to="/manuais-bee4" target="_blank" style={{ color: blue[600] }}>
            link
          </Link>{' '}
          do sistema, bem como demais Manuais BEE4 disponibilizados por outros
          meios e dos quais este termo é parte integrante e indispensável.
        </span>
      );
    if (index === 7)
      return (
        <span onClick={() => handleChange(index)}>
          Estou ciente que no caso de não ocorrência da listagem, seja por
          desistência da empresa Emissora Requerente, não aprovação pela BEE4 ou
          outro motivo, os valores das taxas pagas não serão devolvidas.
        </span>
      );
  };

  return (
    <Modal open={description} handleClose={closeDropdown} width={680}>
      <ModalContent>
        <h3>Declarações</h3>
        <p>
          Antes de iniciar o processo de listagem, a empresa Emissora deve ler e
          sinalizar que está de acordo com todas as declarações listadas abaixo.
        </p>

        <ModalCheckboxContainer>
          <DescriptionCheckbox>
            A empresa Emissora Requerente, declara para os devidos fins que:
          </DescriptionCheckbox>
          {declaracoesEmissor?.map((item, index) => {
            return (
              <Flex>
                <Checkbox
                  key={item}
                  label=""
                  onChange={e => {
                    handleChange(index);
                  }}
                  checked={valueList[index]}
                  labelStyle={{
                    textAlign: 'start',
                    fontSize: '14px',
                    fontWeight: '400',
                    color: '#171725',
                  }}
                  disabled={isConfirmed}
                />
                {declarationList(index)}
              </Flex>
            );
          })}
        </ModalCheckboxContainer>
        {!isConfirmed && (
          <ModalButtons>
            <Button
              type="button"
              fontSize="12px"
              width="100%"
              height="2.5rem"
              variant="contained"
              color="secondary"
              onClick={() => {
                closeDropdown();
                onConfirm();
              }}
              disabled={valueList.some(item => item === false)}
            >
              Confirmar
            </Button>
          </ModalButtons>
        )}
      </ModalContent>
    </Modal>
  );
};
