import React, { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { AxiosResponse } from 'axios';
import { api } from '../../../services/api';
import { verifyError } from '../../../utils/verifyError';

export const useUsuarios = ({
  queryParameter,
}: {
  queryParameter?: string;
}) => {
  const queryClient = useQueryClient();

  const getUsuarios = async () => {
    const getToast = toast.loading('Carregando usuários.');
    try {
      const response: AxiosResponse<any> = await api.main.get(`/v1/usuarios`, {
        params: {
          queryParameter,
        },
      });
      toast.update(getToast, {
        render: 'Usuários carregados com sucesso!',
        type: 'success',
        isLoading: false,
        autoClose: 1,
      });
      return response?.data;
    } catch (error) {
      const message = verifyError(error, 'Erro ao carregar usuários');
      toast.update(getToast, {
        render: message,
        type: 'error',
        isLoading: false,
        autoClose: 5000,
      });
    }
  };

  const {
    isLoading,
    isSuccess,
    isError,
    isIdle,
    data: usuarios,
  } = useQuery(['usuarios', queryParameter], getUsuarios, {});

  const getUsuario = async (id: string) => {
    const getToast = toast.loading('Carregando dados do usuário.');

    try {
      const response: AxiosResponse<any> = await api.main.get(
        `/v1/usuarios/${id}`,
      );

      return response?.data;
    } catch (error) {
      const message = verifyError(error, 'Erro ao carregar dados do usuário.');
      toast.update(getToast, {
        render: message,
        type: 'error',
        isLoading: false,
        autoClose: 5000,
      });
    } finally {
      toast.dismiss();
    }
  };

  const ativarInativarUsuarioDados = useMutation(async (payload: any) => {
    const { usuarioId, ativo } = payload;

    toast.loading(`${ativo ? 'Desativando' : 'Ativando'} usuário.`);

    const response = await api.main.put(`/v1/usuarios/${usuarioId}/status`);

    toast.dismiss();

    invalidateQuery();

    return response;
  });

  const ativarInativarUsuario = (payload: any) => {
    const { ativo } = payload;

    ativarInativarUsuarioDados.mutate(payload, {
      onSuccess: async res => {
        toast.dismiss();
        if (res?.status === 200 || res?.status === 201) {
          toast.success(`Usuário ${ativo ? 'desativado' : 'ativado'}.`);
        }
      },
      onError: async (err: any) => {
        toast.dismiss();
        const message = verifyError(err, 'Erro ao alterar status de usuário');
        toast.error(message);
      },
    });
  };

  const invalidateQuery = async () => {
    await queryClient.invalidateQueries('usuarios');
  };

  return {
    isLoading,
    isError,
    isIdle,
    isSuccess,
    usuarios,
    getUsuarios,
    ativarInativarUsuario,
    getUsuario,
    queryClient,
  };
};

export default useUsuarios;
