import React from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { AxiosResponse } from 'axios';
import { api } from '../../../services/api';
import { verifyError } from '../../../utils/verifyError';
import {
  AddReviewIpoPayload,
  CategoryIPO,
  IpoStatusChangePayload,
  listaDeDeclaracao,
} from '../fields';
import { downloadZipFile } from '../../../utils/handleDownload';

interface IUseIpoProps {
  currentEnterpriseId?: string;
}

export const useIPO = ({ currentEnterpriseId }: IUseIpoProps) => {
  const queryClient = useQueryClient();
  const getIpo = async () => {
    const getToast = toast.loading('Carregando status');

    try {
      const { data }: AxiosResponse<CategoryIPO> = await api.main.get(
        `/v1/empresas/${currentEnterpriseId}/ipo`,
      );

      toast.update(getToast, {
        render: 'Documentação IPO carregada com sucesso!',
        type: 'success',
        isLoading: false,
        autoClose: 1,
      });

      return data;
    } catch (error) {
      const message = verifyError(error, 'Erro ao carregar a documentação IPO');
      toast.update(getToast, {
        render: message,
        type: 'error',
        isLoading: false,
        autoClose: 5000,
      });
    }
  };

  const {
    isLoading,
    isSuccess,
    isError,
    isIdle,
    data: documentacaoIPO,
  } = useQuery(['documentacaoIPO', currentEnterpriseId], getIpo, {
    enabled: !!currentEnterpriseId,
  });

  const invalidateQuery = async () => {
    await queryClient.invalidateQueries('documentacaoIPO');
  };

  const updateData = useMutation(async (payload: any) => {
    const { statusDaCategoria, motivo, categoryId } = payload;
    const response = await api.main.put(
      `/v1/empresas/${currentEnterpriseId}/ipo/status-da-categoria/${categoryId}`,
      { statusDaCategoria, motivo },
    );
    invalidateQuery();
    return response;
  });

  const updateStatusCategoria = (payload: any) => {
    updateData.mutate(payload, {
      onSuccess: async res => {
        toast.dismiss();
        if (res?.status === 200 || res?.status === 201) {
          if (payload.statusDaCategoria === 3) {
            toast.success('Finalização de documentos solicitada com sucesso!');
          } else {
            toast.success('Status atualizado com sucesso!');
          }
        }
      },
      onError: async (err: any) => {
        toast.dismiss();
        const message = verifyError(
          err,
          'Erro ao tentar atualizar a declaração do documento',
        );
        toast.error(message);
      },
    });
  };

  const refreshData = useMutation(async (payload: any) => {
    const response = await api.main.put(
      `/v1/empresas/${currentEnterpriseId}/ipo/atualizar-documentacao/${payload}`,
    );
    invalidateQuery();
    return response;
  });

  const refreshDocsCategoria = (payload: any) => {
    refreshData.mutate(payload, {
      onSuccess: async res => {
        toast.dismiss();
        if (res?.status === 200 || res?.status === 201) {
          toast.success('Recarregamento de documentos realizado com sucesso!');
        }
      },
      onError: async (err: any) => {
        toast.dismiss();
        const message = verifyError(
          err,
          'Erro ao tentar recarregar os documentos',
        );
        toast.error(message);
      },
    });
  };

  const deleteCategory = useMutation(async (payload: any) => {
    const response = await api.main.delete(
      `/v1/empresas/${currentEnterpriseId}/ipo/deletar-arquivo/${payload}`,
    );
    invalidateQuery();
    return response;
  });

  const deleteCategoria = (payload: any) => {
    deleteCategory.mutate(payload, {
      onSuccess: async res => {
        toast.dismiss();
        if (res?.status === 200 || res?.status === 201) {
          toast.success('Documento deletado com sucesso!');
        }
      },
      onError: async (err: any) => {
        toast.dismiss();
        const message = verifyError(
          err,
          'Erro ao tentar deletar os documentos',
        );
        toast.error(message);
      },
    });
  };

  const sendCL = useMutation(async (payload: any) => {
    const response = await api.main.put(
      `/v1/empresas/${currentEnterpriseId}/ipo/status-documento-ipo/`,
      { statusDocumentacaoIpo: 3, listaDeDeclaracao },
    );
    invalidateQuery();
    return response;
  });

  const sendDocIPOToCL = (payload?: any) => {
    sendCL.mutate(payload, {
      onSuccess: async res => {
        toast.dismiss();
        if (res?.status === 200 || res?.status === 201) {
          toast.success('Documentação enviada com sucesso!');
        }
      },
      onError: async (err: any) => {
        toast.dismiss();
        const message = verifyError(err, 'Erro ao tentar enviar os documentos');
        toast.error(message);
      },
    });
  };

  const sendBEE4 = useMutation(async (payload: any) => {
    const response = await api.main.put(
      `/v1/empresas/${currentEnterpriseId}/ipo/status-documento-ipo/`,
      { statusDocumentacaoIpo: 4, listaDeDeclaracao },
    );
    invalidateQuery();
    return response;
  });

  const sendDocIPOToBEE4 = (payload?: any) => {
    sendBEE4.mutate(payload, {
      onSuccess: async res => {
        toast.dismiss();
        if (res?.status === 200 || res?.status === 201) {
          toast.success('Docuemntação enviada com sucesso!');
        }
      },
      onError: async (err: any) => {
        toast.dismiss();
        const message = verifyError(
          err,
          'Erro ao tentar enviar a documentação',
        );
        toast.error(message);
      },
    });
  };

  const aproveDocIPO = useMutation(async (payload: any) => {
    const response = await api.main.post(
      `/v1/empresas/${currentEnterpriseId}/ipo-comite/notificacao-aprovacao-ipo/`,
    );
    invalidateQuery();
    return response;
  });

  const aproveDocIPObyBEE4 = (payload?: any) => {
    aproveDocIPO.mutate(payload, {
      onSuccess: async res => {
        toast.dismiss();
        // if (res?.status === 200 || res?.status === 201) {
        //   toast.success(
        //     'Notificação da decisão do comitê realizada com sucesso!',
        //   );
        // }
      },
      onError: async (err: any) => {
        toast.dismiss();
        const message = verifyError(
          err,
          'Erro ao tentar notificar a decisão do comitê',
        );
        toast.error(message);
      },
    });
  };

  const editPrazo = useMutation(async (payload: any) => {
    const { categoryId, motivo } = payload;
    const response = await api.main.put(
      `/v1/empresas/${currentEnterpriseId}/ipo/status-da-categoria/${categoryId}/prazo-excepcional`,
      { motivo },
    );
    invalidateQuery();
    return response;
  });

  const editPrazoIpo = (payload?: any) => {
    editPrazo.mutate(payload, {
      onSuccess: async res => {
        toast.dismiss();
        if (res?.status === 200 || res?.status === 201) {
          toast.success('Justificativa do prazo alterada com sucesso!');
        }
      },
      onError: async (err: any) => {
        toast.dismiss();
        const message = verifyError(
          err,
          'Erro ao tentar alterar a justificativa do prazo',
        );
        toast.error(message);
      },
    });
  };

  const uploadDoc = useMutation(async (payload: any) => {
    const response = await api.main.post(
      `/v1/empresas/${currentEnterpriseId}/ipo/adicionar-arquivo`,
      { ...payload },
    );
    invalidateQuery();
    return response;
  });

  const uploadDocIpo = (payload?: any) => {
    uploadDoc.mutate(payload, {
      onSuccess: async res => {
        toast.dismiss();
        if (res?.status === 200 || res?.status === 201) {
          toast.success('Upload de documento realizado com sucesso!');
        }
      },
      onError: async (err: any) => {
        toast.dismiss();
        const message = verifyError(
          err,
          'Erro ao tentar fazer uplaod do documento',
        );
        toast.error(message);
      },
    });
  };

  const addComment = useMutation(async (payload: AddReviewIpoPayload) => {
    const { motivo, documentoCategoriaId, type } = payload;

    const response = await api.main.post(
      `/v1/empresas/${currentEnterpriseId}/ipo/comentario/${documentoCategoriaId}/${
        type === 2 ? 'negado' : 'revisao'
      }`,
      { motivo },
    );
    invalidateQuery();
    return response;
  });

  const addReviewIpo = (payload: AddReviewIpoPayload) => {
    addComment.mutate(payload, {
      onSuccess: async res => {
        toast.dismiss();
        if (res?.status === 200 || res?.status === 201) {
          toast.success('Revisão de documento adicionada com sucesso!');
        }
      },
      onError: async (err: any) => {
        toast.dismiss();
        const message = verifyError(err, 'Erro ao adicionar uma revisão');
        toast.error(message);
      },
    });
  };

  const sendNewDocsToBEE4 = useMutation(
    async (payload: AddReviewIpoPayload) => {
      const { documentoCategoriaId } = payload;
      const response = await api.main.post(
        `/v1/empresas/${currentEnterpriseId}/ipo/enviar-documentacao-ipo-para-bee4/${documentoCategoriaId}`,
      );
      invalidateQuery();
      return response;
    },
  );

  const sendNewDocsIpoToBEE4 = (payload: AddReviewIpoPayload) => {
    sendNewDocsToBEE4.mutate(payload, {
      onSuccess: async res => {
        toast.dismiss();
        if (res?.status === 200 || res?.status === 201) {
          toast.success('Documentos enviados com sucesso!');
        }
      },
      onError: async (err: any) => {
        toast.dismiss();
        const message = verifyError(err, 'Erro ao tentar enviar os documentos');
        toast.error(message);
      },
    });
  };

  const changeIpoStatus = useMutation(
    async (payload: IpoStatusChangePayload) => {
      const response = await api.main.put(
        `/v1/empresas/${currentEnterpriseId}/ipo-comite/status-aprovacao-ipo`,
        payload,
      );
      invalidateQuery();
      return response;
    },
  );

  const setIpoStatus = (payload: IpoStatusChangePayload) => {
    changeIpoStatus.mutate(payload, {
      onSuccess: async res => {
        toast.dismiss();
        // if (res?.status === 200 || res?.status === 201) {
        //   toast.success('Status alterado com sucesso!');
        // }
      },
      onError: async (err: any) => {
        toast.dismiss();
        const message = verifyError(err, 'Erro ao tentar alterar o status');
        toast.error(message);
      },
    });
  };

  const uplaodNewDocStatus = useMutation(
    async (payload: IpoStatusChangePayload) => {
      const response = await api.main.post(
        `/v1/empresas/${currentEnterpriseId}/ipo-comite/adicionar-arquivo-aprovacao`,
        payload,
      );
      invalidateQuery();
      return response;
    },
  );

  const uplaodNewDocsStatusIpo = (payload: IpoStatusChangePayload) => {
    uplaodNewDocStatus.mutate(payload, {
      onSuccess: async res => {
        toast.dismiss();
        if (res?.status === 200 || res?.status === 201) {
          toast.success('Upload do documento feito com sucesso!');
        }
      },
      onError: async (err: any) => {
        toast.dismiss();
        const message = verifyError(
          err,
          'Erro ao tentar fazer uplaod do documento',
        );
        toast.error(message);
      },
    });
  };

  const deleteDoc = useMutation(async (payload: string) => {
    const response = await api.main.delete(
      `/v1/empresas/${currentEnterpriseId}/ipo-comite/deletar-arquivo-aprovacao/${payload}`,
    );
    invalidateQuery();
    return response;
  });

  // deletar os documentos de aprocação ou reprovação da documentação IPO feita pela BEE4
  const deleteStatusDocIpo = (payload: string) => {
    deleteDoc.mutate(payload, {
      onSuccess: async res => {
        toast.dismiss();
        if (res?.status === 200 || res?.status === 201) {
          toast.success('Arquivo excluído com sucesso!');
        }
      },
      onError: async (err: any) => {
        toast.dismiss();
        const message = verifyError(err, 'Erro ao tentar excluir o documento');
        toast.error(message);
      },
    });
  };

  const addAdicionalDecision = useMutation(
    async (payload: IpoStatusChangePayload) => {
      const response = await api.main.post(
        `/v1/empresas/${currentEnterpriseId}/ipo-comite-adicional/status-aprovacao-adicional-ipo`,
        payload,
      );
      invalidateQuery();
      return response;
    },
  );

  const addAdicionalDecisionIpo = (payload?: any) => {
    addAdicionalDecision.mutate(payload, {
      onSuccess: async res => {
        toast.dismiss();
        // if (res?.status === 200 || res?.status === 201) {
        //   toast.success('Documentação IPO prosseguida com sucesso!');
        // }
      },
      onError: async (err: any) => {
        toast.dismiss();
        const message = verifyError(
          err,
          'Erro ao tentar prosseguir com a documentação IPO',
        );
        toast.error(message);
      },
    });
  };

  const uplaodAdicionalDocStatus = useMutation(
    async (payload: IpoStatusChangePayload) => {
      const response = await api.main.post(
        `/v1/empresas/${currentEnterpriseId}/ipo-comite-adicional/adicionar-arquivo-aprovacao-adicional`,
        payload,
      );
      invalidateQuery();
      return response;
    },
  );

  const uplaodAdicionalDocsStatusIpo = (payload: IpoStatusChangePayload) => {
    uplaodAdicionalDocStatus.mutate(payload, {
      onSuccess: async res => {
        toast.dismiss();
        if (res?.status === 200 || res?.status === 201) {
          toast.success('Upload do documento feito com sucesso!');
        }
      },
      onError: async (err: any) => {
        toast.dismiss();
        const message = verifyError(
          err,
          'Erro ao tentar fazer uplaod do documento',
        );
        toast.error(message);
      },
    });
  };

  const deleteAdicionalDoc = useMutation(async (payload: string) => {
    const response = await api.main.delete(
      `/v1/empresas/${currentEnterpriseId}/ipo-comite-adicional/deletar-arquivo-aprovacao-adicional/${payload}`,
    );
    invalidateQuery();
    return response;
  });

  // deletar os documentos de decisão adicionais da documentação IPO feita pela BEE4
  const deleteAdicionalDocIpo = (payload: string) => {
    deleteAdicionalDoc.mutate(payload, {
      onSuccess: async res => {
        toast.dismiss();
        if (res?.status === 200 || res?.status === 201) {
          toast.success('Arquivo excluído com sucesso!');
        }
      },
      onError: async (err: any) => {
        toast.dismiss();
        const message = verifyError(err, 'Erro ao tentar excluir o documento');
        toast.error(message);
      },
    });
  };

  const editDecisionType = useMutation(
    async (payload: { tipoDeDecisao: 1 | 2 }) => {
      const response = await api.main.put(
        `/v1/empresas/${currentEnterpriseId}/ipo-comite-adicional/aprovacao-adicional-tipo-de-decisao`,
        payload,
      );
      invalidateQuery();
      return response;
    },
  );

  // editar o tipo de decisão do comitê do doc ipo
  const editDecisionTypeIpo = (payload: { tipoDeDecisao: 1 | 2 }) => {
    editDecisionType.mutate(payload, {
      onSuccess: async res => {
        toast.dismiss();
        if (res?.status === 200 || res?.status === 201) {
          toast.success('Tipo de decisão alterada com sucesso!');
        }
      },
      onError: async (err: any) => {
        toast.dismiss();
        const message = verifyError(
          err,
          'Erro ao tentar alterar o tipo de decisão',
        );
        toast.error(message);
      },
    });
  };

  const downloadDocs = useMutation(async () => {
    const response = await api.main.post(
      `/v1/empresas/${currentEnterpriseId}/ipo/download-documentacao-zip`,
    );

    return response;
  });

  // download de todos os documentos do doc ipo
  const downloadDocsIpo = (payload: any) => {
    downloadDocs.mutate(payload, {
      onSuccess: async res => {
        toast.dismiss();
        if (res?.status === 200 || res?.status === 201) {
          toast.success('Download feito com sucesso!');
          const result = res?.data as any;
          downloadZipFile(
            result?.base64Content,
            result?.nome ?? 'Documentos.zip',
          );
        }
      },
      onError: async (err: any) => {
        toast.dismiss();
        const message = verifyError(
          err,
          'Erro ao tentar fazer download de todos os documentos',
        );
        toast.error(message);
      },
    });
  };

  return {
    isLoading,
    isError,
    isIdle,
    isSuccess,
    documentacaoIPO,
    getIpo,
    invalidateQuery,
    updateStatusCategoria,
    refreshDocsCategoria,
    sendDocIPOToCL,
    sendBEE4,
    sendDocIPOToBEE4,
    aproveDocIPObyBEE4,
    deleteCategoria,
    editPrazoIpo,
    uploadDocIpo,
    queryClient,
    addReviewIpo,
    sendNewDocsIpoToBEE4,
    setIpoStatus,
    deleteStatusDocIpo,
    uplaodNewDocsStatusIpo,
    addAdicionalDecisionIpo,
    uplaodAdicionalDocsStatusIpo,
    deleteAdicionalDocIpo,
    editDecisionTypeIpo,
    downloadDocsIpo,
  };
};

export default useIPO;
