import React, { useState } from 'react';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import saveAs from 'file-saver';
import { DocumentIPODecision } from '../../fields';
import {
  ButtonsContainer,
  DocumentLine,
  DocumentListContent,
  DocumentListDescription,
  DocumentName,
  UploadTextButton,
} from './styles';
import { Button } from '../../../../components/button';
import {
  base64ToArrayBuffer,
  getMimeType,
} from '../../../../components/objectRenderer/utils';
import { OutletPermify } from '../../../../components/outletPermify';
import { Roles } from '../../../../router/roles.routes';

type DocumentListIpoType = {
  docList: DocumentIPODecision[];
  openUploadModal?: () => void;
  deleteStatusDocIpo?: (e: string) => void;
  deleteAdicionalDocIpo?: (e: string) => void;
  isDisable?: boolean;
  page?: boolean;
};

const buttonsStyle = {
  padding: 0,
  width: 'fit-content',
  minWidth: 'fit-content',
};

export const DocumentListIpo = ({
  docList,
  openUploadModal,
  deleteStatusDocIpo,
  deleteAdicionalDocIpo,
  isDisable = false,
  page = false,
}: DocumentListIpoType) => {
  const handleDownload = (doc: DocumentIPODecision) => {
    if (doc?.base64Content) {
      const fileType = doc?.nome?.split('.')?.pop();

      const mimeType = getMimeType(fileType ?? 'pdf');

      if (!mimeType) {
        console.error('Tipo de arquivo inválido');
        return null;
      }

      const fileArrayBuffer = base64ToArrayBuffer(doc?.base64Content);

      if (!mimeType) {
        console.error('Tipo de arquivo inválido');
        return null;
      }

      const blob = new Blob([fileArrayBuffer], {
        type: mimeType,
      });

      const objectUrl = URL.createObjectURL(blob);

      if (doc?.nome) {
        saveAs(objectUrl, doc?.nome);
      } else {
        saveAs(objectUrl);
      }
    }
  };

  return (
    <>
      <DocumentListContent>
        <div>
          {!page && (
            <DocumentListDescription>
              Faça o upload dos documentos comprobatórios
            </DocumentListDescription>
          )}
          {docList?.map(doc => (
            <DocumentLine key={doc.id}>
              <DocumentName>
                <InsertDriveFileOutlinedIcon sx={{ color: '#454B62' }} />
                {doc.nome}
              </DocumentName>
              <ButtonsContainer>
                <OutletPermify role={[Roles.FazerDownloadDocumento]}>
                  <Button
                    variant="text"
                    width="fit-content"
                    style={buttonsStyle}
                    onClick={() => {
                      handleDownload(doc);
                    }}
                  >
                    <FileDownloadOutlinedIcon sx={{ color: '#262936' }} />
                  </Button>
                </OutletPermify>
                {!isDisable && (
                  <OutletPermify
                    role={[
                      Roles.ExcluirDocDecisaoComite,
                      Roles.ExcluirDocDecisaoComite,
                      Roles.ExcluirDocComprobatorios,
                    ]}
                  >
                    <Button
                      variant="text"
                      width="fit-content"
                      style={buttonsStyle}
                      onClick={() => {
                        deleteStatusDocIpo
                          ? deleteStatusDocIpo(doc.id)
                          : deleteAdicionalDocIpo
                          ? deleteAdicionalDocIpo(doc.id)
                          : () => {};
                      }}
                      disabled={isDisable}
                    >
                      <DeleteOutlineOutlinedIcon sx={{ color: '#262936' }} />
                    </Button>
                  </OutletPermify>
                )}
              </ButtonsContainer>
            </DocumentLine>
          ))}
        </div>
        {!isDisable && (
          <OutletPermify
            role={[
              Roles.AtualizarDocDecisaoComite,
              Roles.AtualizarDeclaracaoDeDoc,
              Roles.FazerUpDocComprobatorios,
            ]}
          >
            <Button
              variant="text"
              onClick={() => {
                openUploadModal!();
              }}
              style={{ marginLeft: 'auto' }}
            >
              <UploadTextButton>
                <CloudUploadOutlinedIcon />
                Outros documentos
              </UploadTextButton>
            </Button>
          </OutletPermify>
        )}
      </DocumentListContent>
    </>
  );
};
