import * as yup from 'yup';
import { isValid } from '../../services/isValid';

export type DadosEmissaoForm = {
  isReadOnly: boolean;
  tipoDeAtivo: number;
  numeroDeAcoesOuTitulosEmitidosNaOfertaPublica: number;
  numeroDeAcoesOuTitulosEmitidosAposAOfertaPublica: number;
  direitoDeTagAlong: boolean | string;
  direitoDeDragAlong: boolean | string;
  direitoAoVoto: boolean | string;
  explicacaoDoDireitoDeTagAlong?: string;
  explicacaoDoDireitoDeDragAlong?: string;
  explicacaoDoDireitoAoVoto?: string;
  descricaoDasCondicoesPosConversao: string;
  propositoDaEntradaNoMercadoSecundario: string;
  politicaDeDividendos: string;
  participacaoPercentualPreEmissaoDoCEO: number;
  participacaoPercentualPosEmissaoDoCEO: number;
  oceoTemLockup: boolean | string;
  quantosDiasAposEmissaoOCEOTemLockup?: string;
  temControladores: boolean | string;
  participacaoPercentualPreEmissaoDoControlador?: number;
  participacaoPercentualPosEmissaoDoControlador?: number;
  oControladorTemLockup?: boolean | string;
  quantosDiasAposEmissaoOControladorTemLockup?: string;
  osPrincipaisExecutivosSaoSocios: boolean | string;
  participacaoPercentualDosControladoresPosConversaoEmBaseTotalmenteDiluida?: number;
  capitalSocialEmBaseTotalmenteDiluido?: number;
  totalDeAcoesDaEmpresa: number;
  osPrincipaisExecutivosTemParticipacoes?: boolean | string;
  participacaoVendidaNaEmpresa: number;
  osMembrosDoConselhoTemLockup: boolean | string;
  quantosDiasAposEmissaoOsMembrosDoConselhoTemLockup?: string;
  dadosDaOfertaPublica: {
    dataDeInicio: string | null;
    dataDeEncerramento: string | null;
    montanteCaptado: number;
    participacaoVendidaNaEmpresa: number;
    valuationPostMoneyDaEmpresa: number;
    numeroTotalDeAcoesOuTitulosAposOfertaPublica: number;
    sobreAOferta: string;
    teveInvestidorLider: boolean | string;
    nomeDoInvestidorLider?: string;
    urlDoLinkedInDoInvestidorLider?: string;
    houveMaisInstituicoesEnvolvidasNoProcesso: boolean | string;
  };
  instituicoes: {
    nome: string | null;
    contato: string | null;
    site: string | null;
    papelDesempenhado: string | null;
  }[];
  alertaDeRiscoDePerdaDoPrincipal: string;
  alertaDeRiscoDePerdaDeFaltaDeLiquidez: string;
  riscoDeDificuldadeDeEntendimento: string;
  riscosInerentesAoPerfilDeInvestimentoNaoMencionadosNosItensAnteriores: string;
  calendarioDaOferta: {
    dataDeInicioDaOfertaPublica: string | null;
    previsaoDeEncerramentoDaOfertaPublica: string | null;
    dataDeInicioDoExercicioDoDireitoDePreferencia: string | null;
    dataDeFimDoExercicioDoDireitoDePreferencia: string | null;
  };
};

export const listTipoDeAtivo = [
  { label: 'Ações ON', value: 1 },
  { label: 'Ações PN', value: 2 },
  { label: 'Título Conversível', value: 3 },
  { label: 'Título Não Conversível', value: 4 },
];

export const schema = yup.object().shape({
  tipoDeAtivo: yup.number().nullable(),
  numeroDeAcoesOuTitulosEmitidosNaOfertaPublica: yup.number().nullable(),
  numeroDeAcoesOuTitulosEmitidosAposAOfertaPublica: yup.number().nullable(),
  direitoDeTagAlong: yup.boolean().nullable(),
  direitoDeDragAlong: yup.boolean().nullable(),
  direitoAoVoto: yup.boolean().nullable(),
  explicacaoDoDireitoDeTagAlong: yup.string().max(400).nullable(),
  explicacaoDoDireitoDeDragAlong: yup.string().max(400).nullable(),
  explicacaoDoDireitoAoVoto: yup
    .string()
    .max(700)
    .when('direitoAoVoto', {
      is: (value: boolean) => value === true,
      then: yup.string().required('Campo obrigatório'),
      otherwise: yup.string().nullable(),
    }),
  descricaoDasCondicoesPosConversao: yup.string().nullable(),
  propositoDaEntradaNoMercadoSecundario: yup.string().max(700).nullable(),
  politicaDeDividendos: yup.string().nullable(),
  participacaoPercentualPreEmissaoDoCEO: yup.number().nullable(),
  participacaoPercentualPosEmissaoDoCEO: yup.number().nullable(),
  oceoTemLockup: yup.boolean().nullable(),
  quantosDiasAposEmissaoOCEOTemLockup: yup.string().nullable(),
  temControladores: yup.boolean().nullable(),
  participacaoPercentualPreEmissaoDoControlador: yup.number().nullable(),
  participacaoPercentualPosEmissaoDoControlador: yup.number().nullable(),
  oControladorTemLockup: yup.boolean().nullable(),
  quantosDiasAposEmissaoOControladorTemLockup: yup.string().nullable(),
  osPrincipaisExecutivosSaoSocios: yup.boolean().nullable(),
  participacaoPercentualDosControladoresPosConversaoEmBaseTotalmenteDiluida: yup
    .number()
    .nullable(),
  capitalSocialEmBaseTotalmenteDiluido: yup.number().nullable(),
  totalDeAcoesDaEmpresa: yup.number().nullable(),
  osPrincipaisExecutivosTemParticipacoes: yup.boolean().nullable(),
  participacaoVendidaNaEmpresa: yup.number().nullable(),
  osMembrosDoConselhoTemLockup: yup.boolean().nullable(),
  quantosDiasAposEmissaoOsMembrosDoConselhoTemLockup: yup.string().nullable(),
  dadosDaOfertaPublica: yup.object().shape({
    dataDeInicio: yup
      .string()
      .test('validateDate', 'Data inválida', data => {
        return isValid.date(data);
      })
      .nullable(),
    dataDeEncerramento: yup
      .string()
      .test('validateDate', 'Data inválida', data => {
        return isValid.date(data);
      })
      .nullable(),
    montanteCaptado: yup.number().nullable(),
    participacaoVendidaNaEmpresa: yup.number().nullable(),
    valuationPostMoneyDaEmpresa: yup.number().nullable(),
    sobreAOferta: yup.string().nullable(),
    teveInvestidorLider: yup.boolean().nullable(),
    nomeDoInvestidorLider: yup.string().nullable(),
    urlDoLinkedInDoInvestidorLider: yup.string().nullable(),
    houveMaisInstituicoesEnvolvidasNoProcesso: yup.boolean().nullable(),
    instituicoes: yup.array().of(
      yup.object().shape({
        nome: yup.string().nullable(),
        contato: yup.string().nullable(),
        site: yup.string().nullable(),
        papelDesempenhado: yup.string().nullable(),
      }),
    ),
    quaisPapeisForamDesempenhadosPelasInstituicoes: yup
      .string()
      .max(700)
      .nullable(),
  }),
  alertaDeRiscoDePerdaDoPrincipal: yup.string().max(400).nullable(),
  alertaDeRiscoDePerdaDeFaltaDeLiquidez: yup.string().max(400).nullable(),
  riscoDeDificuldadeDeEntendimento: yup.string().max(400).nullable(),
  riscosInerentesAoPerfilDeInvestimentoNaoMencionadosNosItensAnteriores: yup
    .string()
    .max(400)
    .nullable(),
  calendarioDaOferta: yup.object().shape({
    dataDeInicioDaOfertaPublica: yup
      .string()
      .test('validateDate', 'Data inválida', data => {
        return isValid.date(data);
      })
      .nullable(),
    previsaoDeEncerramentoDaOfertaPublica: yup
      .string()
      .test('validateDate', 'Data inválida', data => {
        return isValid.date(data);
      })
      .nullable(),

    dataDeInicioDoExercicioDoDireitoDePreferencia: yup
      .string()
      .test('validateDate', 'Data inválida', data => {
        return isValid.date(data);
      })
      .nullable(),
    dataDeFimDoExercicioDoDireitoDePreferencia: yup
      .string()
      .test('validateDate', 'Data inválida', data => {
        return isValid.date(data);
      })
      .nullable(),
  }),
});
