import styled from 'styled-components';

export const ModalContent = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;

  h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #262936;
  }

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #454b62;
  }
`;

export const ModalButtons = styled.div`
  display: flex;
  gap: 2rem;
  margin-top: 1rem;
`;

export const ModalCheckboxContainer = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: column;
  align-items: start;
`;

export const ConfirmModalContent = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 1rem 0;
`;
