import styled from 'styled-components';

export const Content = styled.div`
  padding: 7.5rem 0;
`;

export const ListContainer = styled.li`
  display: flex;
  flex-direction: column;
`;

export const Item = styled.ul`
  padding: 10px;
  border-bottom: 1px solid #d0d4e0;

  padding: 1.5rem 0;

  &:last-child {
    border-bottom: none;
  }
`;

export const ItemContent = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
  h4 {
    color: var(--chumbo-1, #454b62);
    font-size: 0.875rem;
    font-family: Inter;
    font-weight: 600;
    line-height: 150%;
    text-decoration: none;
  }

  button {
    border: none;
    background: none;
    color: var(--chumbo-1, #454b62);
    font-size: 0.875rem;
    font-family: Inter;
    font-weight: 600;
    line-height: 150%;
  }
`;

export const PrivateContent = styled.div`
  width: 100%;
  height: 100%;
  height: 1500px;
  margin-bottom: 3rem;
`;

export const PrivateContainer = styled.div`
  width: calc(100% - 240px);
  height: calc(100% - 68px);
  position: absolute;
  left: 241px;
  top: 68px;

  margin-bottom: 3rem;
`;

export const HeaderPrivateContent = styled.div`
  display: flex;
  gap: 1rem;
  padding: 0 40px;
  width: 100%;
  height: 128.1px;
  align-items: center;
  position: fixed;
  box-shadow: inset 0px -1px 1px -1px rgba(0, 0, 0, 0.5);
  z-index: 6;
  background: white;
  min-width: 400px;
  width: 100%;
  color: var(--chumbo-1, #454b62);
  text-align: right;
  font-size: 1.125rem;
  font-family: Inter;
  font-weight: 600;
  button {
    border: none;
    background: none;
  }
`;
