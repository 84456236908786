/* eslint-disable class-methods-use-this */
class IsValid {
  cpf(value: string) {
    let sum;
    let rest;
    sum = 0;

    value = value.replace(/\D/g, '');

    for (let i = 0; i <= 9; i++) {
      if (value === i.toString().repeat(11)) {
        return false;
      }
    }

    for (let i = 1; i <= 9; i++) {
      sum += parseInt(value.substring(i - 1, i)) * (11 - i);
    }

    rest = (sum * 10) % 11;

    if (rest === 10 || rest === 11) {
      rest = 0;
    }

    if (rest !== parseInt(value.substring(9, 10))) {
      return false;
    }

    sum = 0;

    for (let i = 1; i <= 10; i++) {
      sum += parseInt(value.substring(i - 1, i)) * (12 - i);
    }

    rest = (sum * 10) % 11;

    if (rest === 10 || rest === 11) {
      rest = 0;
    }

    if (rest !== parseInt(value.substring(10, 11))) {
      return false;
    }

    return true;
  }

  cnpj(value: string) {
    if (!value) return false;

    const validTypes =
      typeof value === 'string' ||
      Number.isInteger(value) ||
      Array.isArray(value);

    if (!validTypes) return false;

    const match = value.toString().match(/\d/g);
    const numbers = Array.isArray(match) ? match.map(Number) : [];

    if (numbers.length !== 14) return false;

    const items = Array.from(new Set(numbers));
    if (items.length === 1) return false;

    const calc = (x: any) => {
      const slice = numbers.slice(0, x);
      let factor = x - 7;
      let sum = 0;

      for (let i = x; i >= 1; i--) {
        const n = slice[x - i];
        sum += n * factor--;
        if (factor < 2) factor = 9;
      }

      const result = 11 - (sum % 11);

      return result > 9 ? 0 : result;
    };

    const digits = numbers.slice(12);

    const digit0 = calc(12);
    if (digit0 !== digits[0]) return false;

    const digit1 = calc(13);
    return digit1 === digits[1];
  }

  date(input: any) {
    if (!input) {
      return true;
    }
    if (input.length !== 8) {
      return false;
    }
    const day = input.slice(0, 2);
    const month = input.slice(2, 4);
    const year = input.slice(4, input.length);
    const leapYear = (year: number) => {
      return (year % 4 == 0 && year % 100 != 0) || year % 400 == 0;
    };

    const months = [
      '01',
      '02',
      '03',
      '04',
      '05',
      '06',
      '07',
      '08',
      '09',
      '10',
      '11',
      '12',
    ];
    const days = [
      '01',
      '02',
      '03',
      '04',
      '05',
      '06',
      '07',
      '08',
      '09',
      '10',
      '11',
      '12',
      '13',
      '14',
      '15',
      '16',
      '17',
      '18',
      '19',
      '20',
      '21',
      '22',
      '23',
      '24',
      '25',
      '26',
      '27',
      '28',
      '29',
      '30',
      '31',
    ];
    if (months.indexOf(month) != -1 && days.indexOf(day) != -1) {
      if (
        (month == '02' && day == '29' && leapYear(parseInt(year)) == false) ||
        (month == '02' && day == '30') ||
        (month == '02' && day == '31') ||
        (month == '04' && day == '31') ||
        (month == '06' && day == '31') ||
        (month == '09' && day == '31') ||
        (month == '11' && day == '31')
      ) {
        return false;
      }

      /*
      const CurrentDate = new Date();
      const GivenDate = new Date(`${year}-${month}-${day}`);
      if (GivenDate > CurrentDate) {
        return false;
      }
      */
      return true;
    }
    return false;
  }

  fullName(value: string) {
    if (value.length < 1) {
      return false;
    }
    if (!value.includes(' ')) {
      return false;
    }
    if (!/[[ ]|\p{L}*]+$/.test(value)) {
      return false;
    }
    if (value?.split(' ').filter(x => x !== '').length < 2) {
      return false;
    }
    return true;
  }
}

export const isValid = new IsValid();
