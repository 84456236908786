import styled from 'styled-components';

export const UplaodContent = styled.div`
  border-radius: 4px;
  border: 1px solid #79747e;
  background: #f5f5f5;
  width: 100%;
  cursor: pointer;

  .content-uploaded {
    padding: 6px 8px;
    display: flex;
    justify-content: space-between;
  }
`;

export const ButtonContent = styled.div`
  display: flex;
  gap: 1rem;
  padding: 1rem;
  width: 100%;
  align-items: center;

  img {
    width: 49px;
    height: 49px;
  }
`;

export const DescriptionButton = styled.div`
  text-transform: initial;
  h4 {
    color: var(--azul-2, #2dbcf7);
    font-size: 1rem;
    font-family: Inter;
    font-weight: 600;
    line-height: 1.5rem;
  }
  p {
    color: var(--cinza-4, #8c8f9a);
    font-size: 0.625rem;
    font-family: Georama;
    font-weight: 500;
    line-height: 1rem;
    letter-spacing: 0.0625rem;
  }
`;

export const CancelUploadContent = styled.div`
  display: flex;

  flex-direction: column;
  margin: auto;

  span {
    color: var(--chumbo-2, #262936);
    font-size: 0.625rem;
    font-family: Georama;
    font-weight: 500;
    line-height: 1rem;
    letter-spacing: 0.0625rem;
  }
`;
