import React, { useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Controller } from '../../../../../../components/controller';
import { Modal } from '../../../../../../components/modal';
import { Buttons, ModalTitle } from '../../styles';
import { Button } from '../../../../../../components/button';
import { schemaColaboradorEscriturador } from '../../fields';

interface FormData {
  id?: string;
  email?: string;
}

interface IButtonProps {
  description: boolean;
  closeDropdown: (e?: any) => void;
  companyId: string;
  handleCollaborator: (e: any) => void;
}

export const CreateEditCollaboratorEscrituradorModal: React.FC<
  IButtonProps
> = ({ description, closeDropdown, companyId, handleCollaborator }) => {
  const { handleSubmit, control, reset } = useForm<any>({
    resolver: yupResolver(schemaColaboradorEscriturador),
  });

  const submitForm = (data: FormData) => {
    if (!data.email) {
      toast.error('Coloque um email para criar o novo colaborador.');
      return;
    }
    handleCollaborator({ id: companyId, consulter: data });

    closeDropdown();
  };

  useEffect(() => {
    reset({
      email: '',
    });
  }, [reset]);

  return (
    <Modal open={description} handleClose={closeDropdown} width={600}>
      <form onSubmit={handleSubmit(submitForm)}>
        <ModalTitle>Registrar colaborador(a)</ModalTitle>
        <div style={{ marginBottom: '1.5rem' }}>
          <Controller
            name="email"
            control={control}
            type="input"
            label="E-mail do Colaborador"
          />
        </div>
        <Buttons>
          <Button
            width="103px"
            height="40px"
            variant="outlined"
            onClick={closeDropdown}
          >
            CANCELAR
          </Button>
          <Button type="submit" width="103px" height="40px">
            SALVAR
          </Button>
        </Buttons>
      </form>
    </Modal>
  );
};
