import styled from 'styled-components';

export const StatusContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 1.5rem;
  color: #454b62;
  min-width: 750px;
  justify-content: space-between;

  a {
    color: #454b62;
    text-decoration: none;
  }
`;

export const CircularStep = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  max-width: 20%;
  .inside_text {
    min-width: 80px;
  }
`;

export const LinearStep = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: inherit;
`;
