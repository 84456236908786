import React from 'react';
import { RedirectContainer } from '../../components/redirectContainer';
import { useAuth } from '../../hooks/auth';

export const NotInTheSystem = () => {
  const path = 'https://beegin.com.br/';
  return (
    <RedirectContainer
      title="Você está sem acesso ao sistema"
      description={
        <div
          style={{
            marginBottom: '2rem',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <span>Para resolver entre em contato com</span>
          <h4 style={{ marginTop: '5px', marginBottom: '0' }}>
            atendimento@beegin.com.br
          </h4>
        </div>
      }
      buttonDescription="Ir para beegin"
      onClick={() => window.location.replace(path)}
    />
  );
};
