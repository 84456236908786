import React from 'react';
import { AlertType, DropzoneDialog } from 'material-ui-dropzone';
import { toast } from 'react-toastify';
import './styles.css';

interface IUploadModalProps {
  openModal: boolean;
  onClose: (e?: any) => void;
  onSave: (e?: any) => void;
  dialogTitle?: string | JSX.Element;
  acceptedFiles?: string[];
}

export function UploadModal({
  openModal,
  onClose,
  onSave,
  dialogTitle,
  acceptedFiles,
}: IUploadModalProps) {
  const handleAcceptedFiles = () => {
    if (acceptedFiles)
      return acceptedFiles?.map(extension => extension.slice(1));
    return 'jpg, png, pdf, xls, xlsx, csv, doc e docx';
  };
  return (
    <DropzoneDialog
      maxFileSize={25000000}
      filesLimit={1}
      acceptedFiles={
        acceptedFiles ?? [
          '.jpg',
          '.png',
          '.pdf',
          '.xls',
          '.xlsx',
          '.csv',
          '.doc',
          '.docx',
        ]
      }
      clearOnUnmount
      showAlerts={['success']}
      onAlert={(message: string, variant: AlertType) => {
        if (variant === 'error') {
          if (message.includes('File type not supported. File is too big.')) {
            toast.error('Arquivo selecionado muito grande, limite de 25MB');
          } else if (
            message.includes(
              'Maximum allowed number of files exceeded. Only 1 allowed',
            )
          ) {
            toast.error('Insira apenas 1 documento');
          } else {
            toast.error(
              `Tipo de documento inválido. Documentos aceitos: ${handleAcceptedFiles()}`,
            );
          }
        }
      }}
      dialogTitle={dialogTitle}
      cancelButtonText="cancelar"
      dropzoneText="Procurar no computador ou arraste um arquivo até aqui"
      submitButtonText="salvar"
      open={openModal}
      onClose={onClose}
      onSave={file => onSave(file)}
      showPreviews
      showPreviewsInDropzone={false}
      useChipsForPreview
      previewText="Arquivo selecionado"
      alertSnackbarProps={{
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        style: { color: 'white !important' },
      }}
      getFileAddedMessage={() => 'Seu documento foi inserido com sucesso!'}
    />
  );
}
