export enum Roles {
  //   Cadastro Global - Admin global
  CriarCategoria = 'CriarCategoria',
  EditarCategoria = 'EditarCategoria',
  ExcluirCategoria = 'ExcluirCategoria',
  ReativarCategoria = 'ReativarCategoria',
  ReordenarCategorias = 'ReordenarCategorias',
  VisualizarCategorias = 'VisualizarCategorias',
  VisualizarCategoria = 'VisualizarCategoria',
  VisualizarCategArquivado = 'VisualizarCategArquivado',

  CriarEscriturador = 'CriarEscriturador',
  EditarEscriturador = 'EditarEscriturador',
  ExcluirEscriturador = 'ExcluirEscriturador',
  VisualizarEscriturador = 'VisualizarEscriturador',
  VisualizarEscritArquivado = 'VisualizarEscritArquivado',
  DesarquivarEscriturador = 'DesarquivarEscriturador',

  CriarAuditor = 'CriarAuditor',
  EditarAuditor = 'EditarAuditor',
  ExcluirAuditor = 'ExcluirAuditor',
  VisualizarAuditor = 'VisualizarAuditor',
  VisualizarAuditorArquivad = 'VisualizarAuditorArquivad',
  DesarquivarAuditor = 'DesarquivarAuditor',

  EditarEmissor = 'EditarEmissor',
  ExcluirEmissor = 'ExcluirEmissor',
  ConsultarEmissor = 'ConsultarEmissor',
  ReenviarConviteEmissor = 'ReenviarConviteEmissor',
  AtivarContaEmissor = 'AtivarContaEmissor',

  GerarUrlEmissor = 'GerarUrlEmissor', // admin global (Gerar link)
  ConvidarConsultorListagem = 'ConvidarConsultorListagem', // admin global (+ Consultor de listagem)
  ConvidarEmissorColab = 'ConvidarEmissorColab', // nao desenvolvido
  // AtualizarCLRespAdmin = 'AtualizarCLRespAdmin', // admin global (Editar consultor listagem)
  ReenviarConsultorListagem = 'ReenviarConsultorListagem', // admin global (Reenviar consultor de listagem)
  VisualizarCLResps = 'VisualizarCLResps', // admin global (Lista consultores de Listagem)
  EditarCLResp = 'EditarCLResp', // admin global (Editar consultor listagem)
  ExcluirCLResp = 'ExcluirCLResp', // admin global (Excluir consultor listagem)
  AtivarContaCLResp = 'AtivarContaCLResp',

  // VisualizarCLResp = 'VisualizarCLResp',
  VisualizarEmpresaSemColab = 'VisualizarEmpresaSemColab', // admin global (Modal convidar consultor listagem)
  VisualizarEmpresas = 'VisualizarEmpresas', // admin global (Modal empresas auditor, etc ...)
  AtualizarDataEmpListada = 'AtualizarDataEmpListada', // admin global (Pós-listagem datas)
  VisualizarDataEmpListada = 'VisualizarDataEmpListada', // admin global (Pós-listagem datas)
  VisualizarEmpresasListada = 'VisualizarEmpresasListada', // admin global (Pós-listagem datas) *

  // Rota Colaboradores
  ConsultarClColaborador = 'ConsultarClColaborador',
  ConvidarConsultorColab = 'ConvidarConsultorColab', // adicionar colaboradores
  EditarClColaborador = 'EditarClColaborador',
  ExcluirClColaborador = 'ExcluirClColaborador',
  AtivarClColaborador = 'AtivarClColaborador',
  ReenviarConviteClColab = 'ReenviarConviteClColab',

  AcessarAdminGlobal = 'AcessarAdminGlobal',
  ConsultarUsuario = 'ConsultarUsuario',

  // EditarDadosProcessoAdm = 'EditarDadosProcessoAdm',
  // VisualizarEmpresa = 'VisualizarEmpresa',

  //   Primeiro Acesso
  // VisualizarModalDeclaracao = 'VisualizarModalDeclaracao', // Obrigatorio ter
  // AtualizarDeclaracaEmissor = 'AtualizarDeclaracaEmissor', // Obrigatorio ter

  //   Documentacao
  VisualizarDashboard = 'VisualizarDashboard', //
  EditarStatusDashboard = 'EditarStatusDashboard', //

  AtualizarEmpresa = 'AtualizarEmpresa', // Dados gerais (Salvar dados - Dados do negócio)
  VisualizarDadosGerais = 'VisualizarDadosGerais',
  EditarCamposDadoGeral = 'EditarCamposDadoGeral',

  VisualizarFinanceiroENeg = 'VisualizarFinanceiroENeg',
  // AtualizarFinanceiroENeg = 'AtualizarFinanceiroENeg', Nao existe mais
  // ImportarFinanceiroENeg = 'ImportarFinanceiroENeg',  Nao existe mais
  // DownloadFinanceiroENeg = 'DownloadFinanceiroENeg', Nao existe mais
  // VisualizarDiligencia = 'VisualizarDiligencia', Nao existe mais

  VisualizarSobreAtivo = 'VisualizarSobreAtivo',
  EditarCamposSobreAtivo = 'EditarCamposSobreAtivo',

  // VisualizarDadosOferta = 'VisualizarDadosOferta', Nao existe mais
  // EditarCamposDadosOferta = 'EditarCamposDadosOferta', Nao existe mais

  FazerUploadDocumento = 'FazerUploadDocumento',
  FazerDownloadDocumento = 'FazerDownloadDocumento',
  VisualizarDocumento = 'VisualizarDocumento',
  ExcluirDocumento = 'ExcluirDocumento',
  EditarDocumento = 'EditarDocumento',

  HistoricoDocumentos = 'HistoricoDocumentos', // Historico documentos
  HistoricoComite = 'HistoricoComite', // Tela historico comite

  // ValidarDocumento = 'ValidarDocumento', Nao existe mais

  // Url fatos relevantes
  VisualizarFatoRelevante = 'VisualizarFatoRelevante',
  BaixarDocFatoRelevante = 'BaixarDocFatoRelevante',
  PublicarFatoRelevante = 'PublicarFatoRelevante',
  CompartilharFatoRelevante = 'CompartilharFatoRelevante',
  ExcluirFatoRelevante = 'ExcluirFatoRelevante',

  // AtualizarArquivoHash = 'AtualizarArquivoHash', // Processo interno
  // AtualizarStatusDocumento = 'AtualizarStatusDocumento', Nao existe mais
  // IndicarDocumentoNaoAplica = 'IndicarDocumentoNaoAplica',

  // Análise do Comitê
  // url decisao-comite
  VisualizarDecisaoComite = 'VisualizarDecisaoComite',
  AtualizarStsDecisaoComite = 'AtualizarStsDecisaoComite', // Dentro da documentacao
  FazerUpDocDecisaoComite = 'FazerUpDocDecisaoComite', // Dentro da documentacao
  AtualizarDocDecisaoComite = 'AtualizarDocDecisaoComite', // Dentro da documentacao
  ExcluirDocDecisaoComite = 'ExcluirDocDecisaoComite', // Dentro da documentacao
  AtualizarDeclaracaoDeDoc = 'AtualizarDeclaracaoDeDoc', // Toda lista que tem upload de arquivo

  // Adicionar decisao
  CriarStatusDecAdicComite = 'CriarStatusDecAdicComite',
  AtualizarStsDecAdicComite = 'AtualizarStsDecAdicComite',
  FazerUpDocDecAdicComite = 'FazerUpDocDecAdicComite',
  AtualizarDocDecAdicComite = 'AtualizarDocDecAdicComite',
  ExcluirDocDecAdicComite = 'ExcluirDocDecAdicComite',
  AtualizarTpDecAdicComite = 'AtualizarTpDecAdicComite',

  NotificarDecisaoAdicional = 'NotificarDecisaoAdicional',

  AtualizarStatusDossie = 'AtualizarStatusDossie',

  // VisualizarListaDocumentos = 'VisualizarListaDocumentos',
  GerarDocumentacao = 'GerarDocumentacao', // Botao de gerar documentos no dashboard
  AceitarOuNegarPrazo = 'AceitarOuNegarPrazo', // Modal de comentario para negar prazo
  VisualizarJustPrazoExcep = 'VisualizarJustPrazoExcep', // Olho de visualizar documentos
  FazerUpDocComprobatorios = 'FazerUpDocComprobatorios', // Etapa 4 - Outros documentos
  ExcluirDocComprobatorios = 'ExcluirDocComprobatorios', // Etapa 4 - Excluir documento

  NotificarDispDocsParaBee4 = 'NotificarDispDocsParaBee4', // Etapa 3 - sendNewDocsToBEE4 --
  NotificarDecisao = 'NotificarDecisao', // Etapa 4 - sendNewDocsToBEE4 --

  // Geração do dossiê
  FazerUploadDocAut = 'FazerUploadDocAut', // ETAPA 2 - upload
  // GerarDocumentacaoDossie = 'GerarDocumentacaoDossie', //Nao existe mais

  // Validação
  VisualizarStatusComite = 'VisualizarStatusComite', // url de abrir listagem e IPO - Lista documentacao Etapa 3
  VisualizarListaDocGerados = 'VisualizarListaDocGerados', // Lista documentacao - Etapa 3
  SolicitarPrazoExcepcional = 'SolicitarPrazoExcepcional', // Modal prazo excepcional
  AtualizarStatusCategoria = 'AtualizarStatusCategoria', // Bolinha de status documentacao
  SolicitarRevisao = 'SolicitarRevisao', // Salvar do modal de solicitar revisao
  VisualizarDocumentacao = 'VisualizarDocumentacao', // Botao de visualizar documentos
  VisualizarMensagemRevisao = 'VisualizarMensagemRevisao', // Botao de visualizar mensagens

  AtualizarDocumento = 'AtualizarDocumento', // Botao de refresh

  // Validacao e Revisao
  ValidarDocumentacao = 'ValidarDocumentacao', // Bolinha de status documentacao

  EnviarParaAnalise = 'EnviarParaAnalise', // Botao no rodape de enviar

  // IPO
  StatusDocIPO = 'StatusDocIPO',
  BaixarListaDocZipIPO = 'BaixarListaDocZipIPO', // Download de zip IPO

  // LISTAGEM
  StatusDocListagem = 'StatusDocListagem',
  BaixarListaDocZipListagem = 'BaixarListaDocZipListagem', // Download de zip Listagem

  // FALTA FAZER

  // Pós listagem
  VisualizarRelatorio = 'VisualizarRelatorio',
  FazerUploadRelatorio = 'FazerUploadRelatorio',
  EditarRelatorio = 'EditarRelatorio',
  ExcluirRelatorio = 'ExcluirRelatorio',
  FazerDownloadRelatorio = 'FazerDownloadRelatorio',
  AlterarSttsValidRelatorio = 'AlterarSttsValidRelatorio',

  ConvidarEmissor = 'ConvidarEmissor', // nao desenvolvido

  //   Permissões - Nao foi desenvolvido no front
  // CriarFuncionalidade = 'CriarFuncionalidade',
  // AtualizarFuncionalidade = 'AtualizarFuncionalidade',
  // ExcluirFuncionalidade = 'ExcluirFuncionalidade',
  // ConsultarFuncionalidade = 'ConsultarFuncionalidade',
  // CriarGrupo = 'CriarGrupo',
  // AtualizarGrupo = 'AtualizarGrupo',
  // ExcluirGrupo = 'ExcluirGrupo',
  // ConsultarGrupo = 'ConsultarGrupo',
  // CriarPapel = 'CriarPapel',
  // AtualizarPapel = 'AtualizarPapel',
  // ExcluirPapel = 'ExcluirPapel',
  // ConsultarPapel = 'ConsultarPapel',
  // CriarGrupoFunc = 'CriarGrupoFunc',
  // ExcluirGrupoFunc = 'ExcluirGrupoFunc',
  // ConsultarGrupoFunc = 'ConsultarGrupoFunc',
  // CriarGrupoPapel = 'CriarGrupoPapel',
  // ExcluirGrupoPapel = 'ExcluirGrupoPapel',
  // ConsultarGrupoPapel = 'ConsultarGrupoPapel',

  // Preenchimento de dados de forma automática - FLUXO INTERNO
  // PreencherDadosGerais = 'PreencherDadosGerais',
  // PreencherFinanceiroNeg = 'PreencherFinanceiroNeg',
  // PreencherSobreAtivo = 'PreencherSobreAtivo',
  // PreencherDadosDaOferta = 'PreencherDadosDaOferta',
  // PreencherDocCategoria = 'PreencherDocCategoria',
  // PreencherCompleto = 'PreencherCompleto',

  // BACK END
  // ConvidarAdministrador = 'ConvidarAdministrador',
  // EditarAdministrador = 'EditarAdministrador',
  // ExcluirAdministrador = 'ExcluirAdministrador',
  // ConsultarAdministrador = 'ConsultarAdministrador',
  // ReenviarConviteAdmin = 'ReenviarConviteAdmin',
}
