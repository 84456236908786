import React from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { AxiosResponse } from 'axios';
import { api } from '../../../services/api';
import { verifyError } from '../../../utils/verifyError';
import { IUsePosIPO } from '../fields';

interface IUsePosIPOHooks {
  currentEnterpriseId: string;
}

export const usePosIPO = ({ currentEnterpriseId }: IUsePosIPOHooks) => {
  const queryClient = useQueryClient();

  const getPosIPOData = async () => {
    const getToast = toast.loading('Carregando os dados da oferta');
    try {
      const response: AxiosResponse<IUsePosIPO> = await api.main.get(
        `/v1/empresas/${currentEnterpriseId}/pos-ipo`,
      );
      toast.update(getToast, {
        render: 'Dados carregado com sucesso!',
        type: 'success',
        isLoading: false,
        autoClose: 1,
      });
      return response?.data;
    } catch (error) {
      const message = verifyError(error, 'Erro ao caregar os dados da oferta');
      toast.update(getToast, {
        render: message,
        type: 'error',
        isLoading: false,
        autoClose: 5000,
      });
    }
  };

  const {
    isLoading,
    isSuccess,
    isError,
    isIdle,
    data: posIPOData,
  } = useQuery(['posIPO', currentEnterpriseId], getPosIPOData, {
    enabled: !!currentEnterpriseId,
  });

  const invalidateQuery = async () => {
    await queryClient.invalidateQueries('posIPO');
  };

  const putDados = useMutation(async (payload: any) => {
    toast.loading('Atualizando dados');

    const response = await api.main.put(
      `/v1/empresas/${currentEnterpriseId}/pos-ipo`,
      payload,
    );

    invalidateQuery();

    return response;
  });

  const updateDados = (payload: any) => {
    putDados.mutate(payload, {
      onSuccess: async res => {
        toast.dismiss();
        if (res?.status === 200 || res?.status === 201) {
          toast.success('Dados atualizados com sucesso!');
        }
      },
      onError: async (err: any) => {
        toast.dismiss();
        const message = verifyError(err, 'Erro ao atualizar o formulário');
        toast.error(message);
      },
    });
  };

  return {
    isLoading,
    isError,
    isIdle,
    isSuccess,
    posIPOData,
    getPosIPOData,
    updateDados,
    queryClient,
  };
};

export default usePosIPO;
