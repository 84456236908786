import React from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { AxiosResponse } from 'axios';
import { api } from '../../../services/api';
import { verifyError } from '../../../utils/verifyError';
import { downloadZipFile } from '../../../utils/handleDownload';
import {
  AddReviewListagemPayload,
  CategoryListagem,
  ListagemStatusChangePayload,
} from '../fields';
import { listaDeDeclaracao } from '../../documentacaoIPO/fields';

interface IUseListagemProps {
  currentEnterpriseId?: string;
}

export const useListagem = ({ currentEnterpriseId }: IUseListagemProps) => {
  const queryClient = useQueryClient();
  const getListagem = async () => {
    const getToast = toast.loading('Carregando status');

    try {
      const { data }: AxiosResponse<CategoryListagem> = await api.main.get(
        `/v1/empresas/${currentEnterpriseId}/ipo-listagem`,
      );

      toast.update(getToast, {
        render: 'Documentação Listagem carregada com sucesso!',
        type: 'success',
        isLoading: false,
        autoClose: 1,
      });

      return data;
    } catch (error) {
      const message = verifyError(
        error,
        'Erro ao carregar a documentação Listagem',
      );
      toast.update(getToast, {
        render: message,
        type: 'error',
        isLoading: false,
        autoClose: 5000,
      });
    }
  };

  const {
    isLoading,
    isSuccess,
    isError,
    isIdle,
    data: documentacaoListagem,
  } = useQuery(['documentacaoListagem', currentEnterpriseId], getListagem, {
    enabled: !!currentEnterpriseId,
  });

  const invalidateQuery = async () => {
    await queryClient.invalidateQueries('documentacaoListagem');
  };

  const updateData = useMutation(async (payload: any) => {
    const { statusDaCategoria, motivo, categoryId } = payload;
    const response = await api.main.put(
      `/v1/empresas/${currentEnterpriseId}/ipo-listagem/status-da-categoria/${categoryId}`,
      { statusDaCategoria, motivo },
    );
    invalidateQuery();
    return response;
  });

  const updateStatusCategoria = (payload: any) => {
    updateData.mutate(payload, {
      onSuccess: async res => {
        toast.dismiss();
        if (res?.status === 200 || res?.status === 201) {
          if (payload.statusDaCategoria === 3) {
            toast.success('Finalização de documentos solicitada com sucesso!');
          } else {
            toast.success('Status atualizado com sucesso!');
          }
        }
      },
      onError: async (err: any) => {
        toast.dismiss();
        const message = verifyError(
          err,
          'Erro ao tentar atualizar a declaração do documento',
        );
        toast.error(message);
      },
    });
  };

  const refreshData = useMutation(async (payload: any) => {
    const response = await api.main.put(
      `/v1/empresas/${currentEnterpriseId}/ipo-listagem/atualizar-documentacao/${payload}`,
    );
    invalidateQuery();
    return response;
  });

  const refreshDocsCategoria = (payload: any) => {
    refreshData.mutate(payload, {
      onSuccess: async res => {
        toast.dismiss();
        if (res?.status === 200 || res?.status === 201) {
          toast.success('Recarregamento de documentos realizado com sucesso!');
        }
      },
      onError: async (err: any) => {
        toast.dismiss();
        const message = verifyError(
          err,
          'Erro ao tentar recarregar os documentos',
        );
        toast.error(message);
      },
    });
  };

  const deleteCategory = useMutation(async (payload: any) => {
    const response = await api.main.delete(
      `/v1/empresas/${currentEnterpriseId}/ipo-listagem/deletar-arquivo/${payload}`,
    );
    invalidateQuery();
    return response;
  });

  const deleteCategoriaListagem = (payload: any) => {
    deleteCategory.mutate(payload, {
      onSuccess: async res => {
        toast.dismiss();
        if (res?.status === 200 || res?.status === 201) {
          toast.success('Documento deletado com sucesso!');
        }
      },
      onError: async (err: any) => {
        toast.dismiss();
        const message = verifyError(
          err,
          'Erro ao tentar deletar os documentos',
        );
        toast.error(message);
      },
    });
  };

  const sendCL = useMutation(async (payload: any) => {
    const response = await api.main.put(
      `/v1/empresas/${currentEnterpriseId}/ipo-listagem/status-listagem-ipo/`,
      { statusListagemIpo: 3 },
    );
    invalidateQuery();
    return response;
  });

  const sendDocListagemToCL = (payload?: any) => {
    sendCL.mutate(payload, {
      onSuccess: async res => {
        toast.dismiss();
        if (res?.status === 200 || res?.status === 201) {
          toast.success('Documentação enviada com sucesso!');
        }
      },
      onError: async (err: any) => {
        toast.dismiss();
        const message = verifyError(err, 'Erro ao tentar enviar os documentos');
        toast.error(message);
      },
    });
  };

  const sendBEE4Listagem = useMutation(async (payload: any) => {
    const response = await api.main.put(
      `/v1/empresas/${currentEnterpriseId}/ipo-listagem/status-listagem-ipo`,
      { statusListagemIpo: 4, listaDeDeclaracao },
    );
    invalidateQuery();
    return response;
  });

  const sendDocListagemToBEE4 = (payload?: any) => {
    sendBEE4Listagem.mutate(payload, {
      onSuccess: async res => {
        toast.dismiss();
        if (res?.status === 200 || res?.status === 201) {
          toast.success('Documentação enviada com sucesso!');
        }
      },
      onError: async (err: any) => {
        toast.dismiss();
        const message = verifyError(
          err,
          'Erro ao tentar enviar a documentação',
        );
        toast.error(message);
      },
    });
  };

  const aproveDocListagem = useMutation(async (payload: any) => {
    const response = await api.main.post(
      `/v1/empresas/${currentEnterpriseId}/ipo-comite-adicional-listagem/notificacao-aprovacao-adicional-ipo/`,
    );
    invalidateQuery();
    return response;
  });

  const aproveDocListagembyBEE4 = (payload?: any) => {
    aproveDocListagem.mutate(payload, {
      onSuccess: async res => {
        toast.dismiss();
        // if (res?.status === 200 || res?.status === 201) {
        //   toast.success(
        //     'Notificação da decisão do comitê realizada com sucesso!',
        //   );
        // }
      },
      onError: async (err: any) => {
        toast.dismiss();
        const message = verifyError(
          err,
          'Erro ao tentar notificar a decisão do comitê',
        );
        toast.error(message);
      },
    });
  };

  const editPrazo = useMutation(async (payload: any) => {
    const { categoryId, motivo } = payload;
    const response = await api.main.put(
      `/v1/empresas/${currentEnterpriseId}/ipo-listagem/status-da-categoria/${categoryId}/prazo-excepcional`,
      { motivo },
    );
    invalidateQuery();
    return response;
  });

  const editPrazoListagem = (payload?: any) => {
    editPrazo.mutate(payload, {
      onSuccess: async res => {
        toast.dismiss();
        if (res?.status === 200 || res?.status === 201) {
          toast.success('Justificativa do prazo alterada com sucesso!');
        }
      },
      onError: async (err: any) => {
        toast.dismiss();
        const message = verifyError(
          err,
          'Erro ao tentar alterar a justificativa do prazo',
        );
        toast.error(message);
      },
    });
  };

  const uploadDoc = useMutation(async (payload: any) => {
    const response = await api.main.post(
      `/v1/empresas/${currentEnterpriseId}/ipo-listagem/adicionar-arquivo`,
      { ...payload },
    );
    invalidateQuery();
    return response;
  });

  const uploadDocListagem = (payload?: any) => {
    uploadDoc.mutate(payload, {
      onSuccess: async res => {
        toast.dismiss();
        if (res?.status === 200 || res?.status === 201) {
          toast.success('Upload de documento realizado com sucesso!');
        }
      },
      onError: async (err: any) => {
        toast.dismiss();
        const message = verifyError(
          err,
          'Erro ao tentar fazer uplaod do documento',
        );
        toast.error(message);
      },
    });
  };

  const addComment = useMutation(async (payload: AddReviewListagemPayload) => {
    const { motivo, documentoCategoriaId, type } = payload;

    const response = await api.main.post(
      `/v1/empresas/${currentEnterpriseId}/ipo-listagem/comentario/${documentoCategoriaId}/${
        type === 2 ? 'negado' : 'revisao'
      }`,
      { motivo },
    );
    invalidateQuery();
    return response;
  });

  const addReviewListagem = (payload: AddReviewListagemPayload) => {
    addComment.mutate(payload, {
      onSuccess: async res => {
        toast.dismiss();
        if (res?.status === 200 || res?.status === 201) {
          toast.success('Revisão de documento adicionada com sucesso!');
        }
      },
      onError: async (err: any) => {
        toast.dismiss();
        const message = verifyError(err, 'Erro ao adicionar uma revisão');
        toast.error(message);
      },
    });
  };

  const sendNewDocsToBEE4 = useMutation(
    async (payload: AddReviewListagemPayload) => {
      const { documentoCategoriaId } = payload;
      const response = await api.main.post(
        `/v1/empresas/${currentEnterpriseId}/ipo-listagem/enviar-documentacao-ipo-para-bee4/${documentoCategoriaId}`,
      );
      invalidateQuery();
      return response;
    },
  );

  const sendNewDocsListagemToBEE4 = (payload: AddReviewListagemPayload) => {
    sendNewDocsToBEE4.mutate(payload, {
      onSuccess: async res => {
        toast.dismiss();
        if (res?.status === 200 || res?.status === 201) {
          toast.success('Documentos enviados com sucesso!');
        }
      },
      onError: async (err: any) => {
        toast.dismiss();
        const message = verifyError(err, 'Erro ao tentar enviar os documentos');
        toast.error(message);
      },
    });
  };

  const changeListagemStatus = useMutation(
    async (payload: ListagemStatusChangePayload) => {
      const response = await api.main.put(
        `/v1/empresas/${currentEnterpriseId}/ipo-comite-adicional-listagem/status-aprovacao-adicional-ipo`,
        payload,
      );
      invalidateQuery();
      return response;
    },
  );

  const setListagemStatus = (payload: ListagemStatusChangePayload) => {
    changeListagemStatus.mutate(payload, {
      onSuccess: async res => {
        toast.dismiss();
        // if (res?.status === 200 || res?.status === 201) {
        //   toast.success('Status alterado com sucesso!');
        // }
      },
      onError: async (err: any) => {
        toast.dismiss();
        const message = verifyError(err, 'Erro ao tentar alterar o status');
        toast.error(message);
      },
    });
  };

  const uplaodNewDocStatus = useMutation(
    async (payload: ListagemStatusChangePayload) => {
      const response = await api.main.post(
        `/v1/empresas/${currentEnterpriseId}/ipo-comite-adicional-listagem/adicionar-arquivo-aprovacao-adicional`,
        payload,
      );
      invalidateQuery();
      return response;
    },
  );

  const uplaodNewDocsStatusListagem = (
    payload: ListagemStatusChangePayload,
  ) => {
    uplaodNewDocStatus.mutate(payload, {
      onSuccess: async res => {
        toast.dismiss();
        if (res?.status === 200 || res?.status === 201) {
          toast.success('Upload do documento feito com sucesso!');
        }
      },
      onError: async (err: any) => {
        toast.dismiss();
        const message = verifyError(
          err,
          'Erro ao tentar fazer uplaod do documento',
        );
        toast.error(message);
      },
    });
  };

  const deleteDoc = useMutation(async (payload: string) => {
    const response = await api.main.delete(
      `/v1/empresas/${currentEnterpriseId}/ipo-comite-adicional-listagem/deletar-arquivo-aprovacao-adicional/${payload}`,
    );
    invalidateQuery();
    return response;
  });

  // deletar os documentos de aprocação ou reprovação da documentação listagem feita pela BEE4
  const deleteStatusDocListagem = (payload: string) => {
    deleteDoc.mutate(payload, {
      onSuccess: async res => {
        toast.dismiss();
        if (res?.status === 200 || res?.status === 201) {
          toast.success('Arquivo excluído com sucesso!');
        }
      },
      onError: async (err: any) => {
        toast.dismiss();
        const message = verifyError(err, 'Erro ao tentar excluir o documento');
        toast.error(message);
      },
    });
  };

  const addAdicionalDecision = useMutation(
    async (payload: ListagemStatusChangePayload) => {
      const response = await api.main.post(
        `/v1/empresas/${currentEnterpriseId}/ipo-comite-adicional-listagem/status-aprovacao-adicional-ipo`,
        payload,
      );
      invalidateQuery();
      return response;
    },
  );

  const addAdicionalDecisionListagem = (payload?: any) => {
    addAdicionalDecision.mutate(payload, {
      onSuccess: async res => {
        toast.dismiss();
        // if (res?.status === 200 || res?.status === 201) {
        //   toast.success('Documentação listagem prosseguida com sucesso!');
        // }
      },
      onError: async (err: any) => {
        toast.dismiss();
        const message = verifyError(
          err,
          'Erro ao tentar prosseguir com a documentação listagem',
        );
        toast.error(message);
      },
    });
  };

  const updateAdicionalDecision = useMutation(
    async (payload: ListagemStatusChangePayload) => {
      const response = await api.main.put(
        `/v1/empresas/${currentEnterpriseId}/ipo-comite-adicional-listagem/status-aprovacao-adicional-ipo/${payload.idAprovacao}`,
        payload,
      );
      invalidateQuery();
      return response;
    },
  );

  const updateAdicionalDecisionListagem = (
    payload?: any,
    idAprovacao?: string,
  ) => {
    payload.idAprovacao = idAprovacao;

    updateAdicionalDecision.mutate(payload, {
      onSuccess: async res => {
        toast.dismiss();
        // if (res?.status === 200 || res?.status === 201) {
        //   toast.success('Documentação listagem prosseguida com sucesso!');
        // }
      },
      onError: async (err: any) => {
        toast.dismiss();
        const message = verifyError(
          err,
          'Erro ao tentar prosseguir com a documentação listagem',
        );
        toast.error(message);
      },
    });
  };

  const uplaodAdicionalDocStatus = useMutation(
    async (payload: ListagemStatusChangePayload) => {
      const response = await api.main.post(
        `/v1/empresas/${currentEnterpriseId}/ipo-comite-adicional-listagem/adicionar-arquivo-aprovacao-adicional`,
        payload,
      );
      invalidateQuery();
      return response;
    },
  );

  const uplaodAdicionalDocsStatusListagem = (
    payload: ListagemStatusChangePayload,
  ) => {
    uplaodAdicionalDocStatus.mutate(payload, {
      onSuccess: async res => {
        toast.dismiss();
        if (res?.status === 200 || res?.status === 201) {
          toast.success('Upload do documento feito com sucesso!');
        }
      },
      onError: async (err: any) => {
        toast.dismiss();
        const message = verifyError(
          err,
          'Erro ao tentar fazer uplaod do documento',
        );
        toast.error(message);
      },
    });
  };

  const deleteAdicionalDoc = useMutation(async (payload: string) => {
    const response = await api.main.delete(
      `/v1/empresas/${currentEnterpriseId}/ipo-comite-adicional-listagem/deletar-arquivo-aprovacao-adicional/${payload}`,
    );
    invalidateQuery();
    return response;
  });

  // deletar os documentos de decisão adicionais da documentação listagem feita pela BEE4
  const deleteAdicionalDocListagem = (payload: string) => {
    deleteAdicionalDoc.mutate(payload, {
      onSuccess: async res => {
        toast.dismiss();
        if (res?.status === 200 || res?.status === 201) {
          toast.success('Arquivo excluído com sucesso!');
        }
      },
      onError: async (err: any) => {
        toast.dismiss();
        const message = verifyError(err, 'Erro ao tentar excluir o documento');
        toast.error(message);
      },
    });
  };

  const editDecisionType = useMutation(
    async (payload: { tipoDeDecisao: 1 | 2 }) => {
      const response = await api.main.put(
        `/v1/empresas/${currentEnterpriseId}/ipo-comite-adicional-listagem/aprovacao-adicional-tipo-de-decisao`,
        payload,
      );
      invalidateQuery();
      return response;
    },
  );

  // editar o tipo de decisão do comitê do doc ipo
  const editDecisionTypeListagem = (payload: { tipoDeDecisao: 1 | 2 }) => {
    editDecisionType.mutate(payload, {
      onSuccess: async res => {
        toast.dismiss();
        if (res?.status === 200 || res?.status === 201) {
          toast.success('Tipo de decisão alterada com sucesso!');
        }
      },
      onError: async (err: any) => {
        toast.dismiss();
        const message = verifyError(
          err,
          'Erro ao tentar alterar o tipo de decisão',
        );
        toast.error(message);
      },
    });
  };

  const downloadDocs = useMutation(async () => {
    const response = await api.main.post(
      `/v1/empresas/${currentEnterpriseId}/ipo-listagem/download-documentacao-zip`,
    );

    return response;
  });

  // download de todos os documentos do doc ipo
  const downloadDocsListagem = (payload: any) => {
    downloadDocs.mutate(payload, {
      onSuccess: async res => {
        toast.dismiss();
        if (res?.status === 200 || res?.status === 201) {
          toast.success('Download feito com sucesso!');
          const result = res?.data as any;
          downloadZipFile(
            result?.base64Content,
            result?.nome ?? 'Documentos.zip',
          );
        }
      },
      onError: async (err: any) => {
        toast.dismiss();
        const message = verifyError(
          err,
          'Erro ao tentar fazer download de todos os documentos',
        );
        toast.error(message);
      },
    });
  };

  return {
    isLoading,
    isError,
    isIdle,
    isSuccess,
    documentacaoListagem,
    getListagem,
    invalidateQuery,
    updateStatusCategoria,
    refreshDocsCategoria,
    sendDocListagemToCL,
    sendBEE4Listagem,
    sendDocListagemToBEE4,
    aproveDocListagembyBEE4,
    deleteCategoriaListagem,
    editPrazoListagem,
    uploadDocListagem,
    queryClient,
    addReviewListagem,
    sendNewDocsListagemToBEE4,
    setListagemStatus,
    deleteStatusDocListagem,
    uplaodNewDocsStatusListagem,
    addAdicionalDecisionListagem,
    uplaodAdicionalDocsStatusListagem,
    deleteAdicionalDocListagem,
    editDecisionTypeListagem,
    downloadDocsListagem,
    updateAdicionalDecisionListagem,
  };
};

export default useListagem;
