import React from 'react';

import { DocumentList } from '../../components/documents';

export const Diligencia = () => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '2rem',
        marginBottom: '4rem',
      }}
    >
      <DocumentList stage="diligencia" diligenciaType={1} />
      <DocumentList stage="diligencia" diligenciaType={2} />
      <DocumentList stage="diligencia" diligenciaType={3} />
      <DocumentList stage="diligencia" diligenciaType={4} />
      <DocumentList stage="diligencia" diligenciaType={5} />
      <DocumentList stage="diligencia" diligenciaType={6} />
      <DocumentList stage="diligencia" diligenciaType={7} />
      <DocumentList stage="diligencia" diligenciaType={8} />
      <DocumentList stage="diligencia" diligenciaType={9} />
      <DocumentList stage="diligencia" diligenciaType={10} />
    </div>
  );
};
