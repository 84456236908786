import styled from 'styled-components';

export const ModalContent = styled.div`
  display: flex;
  gap: 2rem;
  justify-content: center;
  flex-direction: column;
  padding: 1.5rem 0.5rem;
  align-items: start;
`;

export const ModalTitle = styled.div`
  display: flex;
  gap: 1rem;
  font-size: 1.5rem;
`;

export const ModalImage = styled.img`
  box-shadow: 0px 4px 8px 0px rgba(81, 87, 108, 0.1);
`;

export const ListItem = styled.ol`
  color: var(--chumbo-1, #454b62);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5rem;
  list-style-position: inside;
`;

export const StrongLiText = styled.p`
  color: var(--chumbo-1, #454b62);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  margin-top: 1.5rem;
`;

export const ModalButtons = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem;
`;
