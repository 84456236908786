import * as React from 'react';
import CircularProgress, {
  CircularProgressProps,
} from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { StatusCompleteType } from '../styles';

function CircularProgressWithLabel(
  props: CircularProgressProps & { value: number; label: string },
) {
  const { value, label, sx } = props;

  if (value === 0) {
    return (
      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
        <CircularProgress
          variant="determinate"
          size={40}
          {...props}
          sx={{
            ...sx,
            border: '2px dashed #D0D4E0',
            borderRadius: '50%',
          }}
          value={0}
        />
        <CircularProgress
          variant="determinate"
          {...props}
          sx={{ ...sx, position: 'absolute' }}
        />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <p style={{ fontWeight: 500 }}>{label}</p>
        </Box>
      </Box>
    );
  }
  if (value === 100) {
    return (
      <StatusCompleteType>
        <p>{label}</p>
      </StatusCompleteType>
    );
  }
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress
        variant="determinate"
        size={40}
        {...props}
        sx={{
          color: '#D0D4E0',
        }}
        value={100}
      />
      <CircularProgress
        variant="determinate"
        {...props}
        sx={{ ...sx, position: 'absolute' }}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <p style={{ fontWeight: 500 }}>{label}</p>
      </Box>
    </Box>
  );
}

export default function CircularStatic({
  value = 0,
  label,
  disabled,
}: {
  value?: number;
  label: string;
  disabled?: boolean;
}) {
  if (disabled) {
    return (
      <img
        src="images/blockIconDashboard.svg"
        alt="bloqueado"
        style={{ width: '40px', height: '40px' }}
      />
    );
  }
  return (
    <CircularProgressWithLabel
      value={value}
      label={label}
      sx={{ color: '#0852DD' }}
    />
  );
}
