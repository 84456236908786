import * as yup from 'yup';
import { isValid } from '../../services/isValid';

export const listControllerType = [
  {
    label: 'Estatal',
    value: 1,
  },
  {
    label: 'Privado',
    value: 2,
  },
];

export const listYesNoProgram = [
  {
    label: 'Sim, tenho programa.',
    value: 'true',
  },
  {
    label: 'Não tenho.',
    value: 'false',
  },
];

export const listAdressType = [
  {
    label: 'Sede',
    value: 1,
  },
  {
    label: 'Correspondência',
    value: 2,
  },
];

export const listControllerMajority = [
  {
    value: 'Fundador(es)',
  },
  {
    value: 'Holding Familiar',
  },
  {
    value: 'Holding',
  },
  {
    value: 'Fundos de Venture Capital & Private Equity',
  },
  {
    value: 'Outros',
  },
];

export const listSocialNetwork = [
  {
    value: 'Facebook',
  },
  {
    value: 'Instagram',
  },
  {
    value: 'Youtube',
  },
  {
    value: 'Twitter',
  },
  {
    value: 'LinkedIn',
  },
];

export const schema = yup.object().shape({
  nomeFantasia: yup.string().nullable(),
  razaoSocial: yup.string().nullable(),
  cnpj: yup
    .string()
    .test('validateCNPJ', 'CNPJ inválido', cnpj => {
      return cnpj ? isValid.cnpj(cnpj) : true;
    })
    .nullable(),
  tipo: yup.number().nullable(),
  numeroDeFuncionarios: yup
    .number()
    // .typeError('Precisa ser um número')
    .nullable()
    .transform((v, o) => (o === '' || o === 0 ? undefined : v)),
  setorDeAtuacao: yup.string().max(7000).nullable(),
  modeloDeVenda: yup.number().nullable(),
  breveHistorico: yup.string().max(1000).nullable(),
  comoFuncionaOModeloDeNegocio: yup.string().max(1000).nullable(),
  descricaoDasAtividades: yup.string().max(1000).nullable(),
  diferenciaisDoEmissor: yup.string().max(1000).nullable(),
  linhasDeReceita: yup.array().of(
    yup.object().shape({
      nome: yup.string().nullable(),
      percentualDaReceita: yup
        .number()
        .nullable()
        .transform((v, o) => (o === '' ? undefined : v)),
    }),
  ),
  principaisFornecedores: yup
    .array()
    .of(yup.object().shape({ nome: yup.string().nullable() })),
  dataDaConstituicao: yup
    .string()
    .test('validateDate', 'Data inválida', data => {
      return isValid.date(data);
    })
    .nullable(),
  dataDeEncerramentoDoExercicioSocial: yup
    .string()
    .test('validateDate', 'Data inválida', data => {
      return isValid.date(data);
    })
    .nullable(),
  dataDoPedidoDeRegistroNaBEE4: yup
    .string()
    .test('validateDate', 'Data inválida', data => {
      return isValid.date(data);
    })
    .nullable(),
  website: yup.string().nullable(),
  urlDoFacebook: yup.string().nullable(),
  urlDoYouTube: yup.string().nullable(),
  urlDoLinkedIn: yup.string().nullable(),
  urlDoInstagram: yup.string().nullable(),
  urlDoTwitter: yup.string().nullable(),
  principalRedeSocial: yup.string().nullable(),
  paisSede: yup.string().nullable(),
  cep: yup.string().nullable(),
  rua: yup.string().nullable(),
  numero: yup.string().nullable(),
  complemento: yup.string().nullable(),
  bairro: yup.string().nullable(),
  municipio: yup.string().nullable(),
  estado: yup.string().nullable(),
  tipoDeEndereco: yup.number().nullable(),
  telefone: yup.string().nullable(),
  executivoResponsavelPeloRegistro: yup.object().shape({
    nome: yup.string().nullable(),
    cpf: yup
      .string()
      .nullable()
      .test('validadeCPF', 'CPF inválido', cpf => {
        return cpf ? isValid.cpf(cpf) : true;
      }),
    cep: yup.string().nullable(),
    rua: yup.string().nullable(),
    numero: yup.string().nullable(),
    bairro: yup.string().nullable(),
    municipio: yup.string().nullable(),
    estado: yup.string().nullable(),
    tipoDeEndereco: yup.number().nullable(),
    dateDeInicioNaEmpresa: yup
      .string()
      .test('validateDate', 'Data inválida', data => {
        return isValid.date(data);
      })
      .nullable(),
    telefone: yup.string().nullable(),
    email: yup.string().nullable(),
    responsavelPontoDeContatoDaEmpresa: yup.string().nullable(),
    nomeDoResponsavel: yup.string().nullable(),
    emailResponsavel: yup.string().nullable(),
    telefoneResponsavel: yup.string().nullable(),
  }),
  nomeDoCEO: yup.string().nullable(),
  cpfDoCEO: yup
    .string()
    .nullable()
    .test('validadeCPF', 'CPF inválido', cpf => {
      return cpf ? isValid.cpf(cpf) : true;
    }),
  desdeQuandoOCEOOcupaOCargo: yup
    .string()
    .test('validateDate', 'Data inválida', data => {
      return isValid.date(data);
    })
    .nullable(),
  linkedInDoCEO: yup.string().nullable(),
  miniCVDoCEO: yup.string().max(700).nullable(),
  nomeDoPresidenteDoConselho: yup.string().nullable(),
  cpfDoPresidenteDoConselho: yup
    .string()
    .nullable()
    .test('validadeCPF', 'CPF inválido', cpf => {
      return cpf ? isValid.cpf(cpf) : true;
    }),
  desdeQuandoOPresidenteDoConselhoOcupaOCargo: yup
    .string()
    .test('validateDate', 'Data inválida', data => {
      return isValid.date(data);
    })
    .nullable(),
  linkedInDoPresidenteDoConselho: yup.string().nullable(),
  miniCVDoPresidenteDoConselho: yup.string().max(700).nullable(),
  membrosDoConselho: yup.array().of(
    yup.object().shape({
      nome: yup.string().nullable(),
      cpf: yup
        .string()
        .test('validadeCPF', 'CPF inválido', cpf => {
          return cpf ? isValid.cpf(cpf) : true;
        })
        .nullable(),
      qualificacao: yup.string().nullable(),
    }),
  ),
  numeroDeMembrosIndependentesDoConselho: yup.number().min(0).nullable(),
  periodicidadeDasReunioesDoConselho: yup.string().nullable(),
  responsabilidadeEObrigacoesDoConselho: yup.string().max(700).nullable(),
  umOuMaisMembrosTemPercentualNaCompanhia: yup.boolean().nullable(),
  nomeDaCompanhiaControladora: yup.string().nullable(),
  tipoDeCompanhiaControladora: yup.number().nullable(),
  percentualDaControladora: yup
    .number()
    .nullable()
    .transform((v, o) => (o === '' ? undefined : v)),
  tipoDeEmpresaControladoraMajoritaria: yup.string().nullable(),
  tipoDeEmpresaControladoraMajoritariaDetalhes: yup
    .string()
    .when('tipoDeEmpresaControladoraMajoritaria', {
      is: (value: string) => value === 'Outros',
      then: yup.string().required('Campo obrigatório'),
      otherwise: yup.string().nullable(),
    }),
  acionistas: yup.array().of(
    yup.object().shape({
      nome: yup.string().nullable(),
      participacao: yup
        .number()
        .notRequired()
        .nullable()
        .transform((v, o) => (o === '' || o === 0 ? undefined : v)),
    }),
  ),
  ultimaAlteracaoDoAcordoDeAcionistas: yup
    .string()
    .test('validateDate', 'Data inválida', data => {
      return isValid.date(data);
    })
    .nullable(),
  osPrincipaisExecutivosSaoSocios: yup.boolean().nullable(),
  possuiProgramaDePartnershipOuStockOptions: yup.boolean().nullable(),
  consultorDeListagem: yup.object().shape({
    razaoSocial: yup.string().nullable(),
    nomeDoResponsavelTecnico: yup.string().nullable(),
    telefone: yup.string().nullable(),
    cpf: yup
      .string()
      .test('validadeCPF', 'CPF inválido', cpf => {
        return cpf ? isValid.cpf(cpf) : true;
      })
      .nullable(),
    cnpj: yup
      .string()
      .test('validateCNPJ', 'CNPJ inválido', cnpj => {
        return cnpj ? isValid.cnpj(cnpj) : true;
      })
      .nullable(),
    email: yup.string().nullable(),
    inicioDoContratoDePrestacaoDeServico: yup
      .string()
      .test('validateDate', 'Data inválida', data => {
        return isValid.date(data);
      })
      .nullable(),
  }),
  escriturador: yup.object().shape({
    razaoSocial: yup.string().nullable(),
    nomeDoResponsavelTecnico: yup.string().nullable(),
    telefone: yup.string().nullable(),
    cpf: yup.string().nullable(),
    cnpj: yup.string().nullable(),
    email: yup.string().nullable(),
    inicioDoContratoDePrestacaoDeServico: yup.string().nullable(),
  }),
  dadosBancariosDaEmpresa: yup.object().shape({
    nome: yup.string().nullable(),
    agencia: yup.string().nullable(),
    conta: yup.string().nullable(),
  }),
  logo: yup
    .object()
    .shape({
      base64Content: yup.string().nullable(),
      nome: yup.string().nullable(),
    })
    .nullable(),
  assessoriaJuridica: yup.object().shape({
    responsavelJuridico: yup.number().nullable(),
    razaoSocial: yup.string().nullable(),
    cnpj: yup
      .string()
      .test('validateCNPJ', 'CNPJ inválido', cnpj => {
        return cnpj ? isValid.cnpj(cnpj) : true;
      })
      .nullable(),
    rua: yup.string().nullable(),
    numero: yup.string().nullable(),
    bairro: yup.string().nullable(),
    municipio: yup.string().nullable(),
    estado: yup.string().nullable(),
    cep: yup.string().nullable(),
    tipoDeEndereco: yup.number().nullable(),
    inicioDeContratoDePrestacaoDeServico: yup.string().nullable(),
    telefone: yup.string().nullable(),
    nomeDoResponsavel: yup.string().nullable(),
    emailDoResponsavel: yup.string().nullable(),
    cpfDoResponsavel: yup
      .string()
      .test('validadeCPF', 'CPF inválido', cpf => {
        return cpf ? isValid.cpf(cpf) : true;
      })
      .nullable(),
    telefoneDoResponsavel: yup.string().nullable(),
  }),
  assessoriaJuridicaDadosContatoFinanceiro: yup.object().shape({
    nome: yup.string().nullable(),
    email: yup.string().nullable(),
    telefone: yup.string().nullable(),
  }),
  assessoriaJuridicaDiretorDeDivulgacoesDeInformacoesAoMercado: yup
    .object()
    .shape({
      nome: yup.string().nullable(),
      email: yup.string().nullable(),
      telefone: yup.string().nullable(),
      posicaoNaEmpresa: yup.string().nullable(),
    }),
});

export type CreateCompanyForm = {
  nomeFantasia: string;
  cnpj: string;
  tipo: number;
};

export type LinhaDeReceita = {
  nome: string;
  percentualDaReceita: number;
};

export type PrincipalFornecedor = {
  nome: string;
};

export type MembroDoConselho = {
  nome: string;
  cpf: string;
  qualificacao: string;
};

export type Acionista = {
  nome: string;
  participacao: number | null;
};

export type ExecutivoResponsavelPeloRegistro = {
  nome: string;
  cpf: string;
  cep: string;
  rua: string;
  numero: string;
  bairro: string;
  municipio: string;
  estado: string;
  tipoDeEndereco: number;
  dateDeInicioNaEmpresa: string | null;
  telefone: string;
  email: string;
  responsavelPontoDeContatoDaEmpresa: string;
  nomeDoResponsavel: string;
  emailResponsavel: string;
  telefoneResponsavel: string;
};

export type ConsultorDeListagemEscriturador = {
  razaoSocial: string;
  nomeDaEmpresa: string;
  nomeDoResponsavelTecnico: string;
  telefone: string;
  cpf: string;
  cnpj: string;
  email: string;
  inicioDoContratoDePrestacaoDeServico: string | null;
};

export type Auditor = {
  razaoSocial: string;
  nomeDoResponsavelTecnico: string;
  telefone: string;
  cpf: string;
  cnpj: string;
  email: string;
  inicioDoContratoDePrestacaoDeServico: string | null;
  registroNaCvm: string | null;
  cep: string;
  rua: string;
  cidade: string;
  uf: string;
  bairro: string;
  tipoDeEndereço: number;
  telefoneDoResponsavelTecnico: string;
  emailDoResponsavelTecnico: string;
};

export type AssessoriaJuridica = {
  responsavelJuridico: number;
  razaoSocial: string;
  cnpj: string;
  rua: string;
  numero: string;
  bairro: string;
  municipio: string;
  estado: string;
  cep: string;
  tipoDeEndereco: number;
  inicioDeContratoDePrestacaoDeServico: string;
  telefone: string;
  nomeDoResponsavel: string;
  emailDoResponsavel: string;
  cpfDoResponsavel: string;
  telefoneDoResponsavel: string;
  complemento: string;
};

export type AssessoriaJuridicaDadosContatoFinanceiro = {
  nome: string;
  email: string;
  telefone: string;
};

export type AssessoriaJuridicaDiretorDeDivulgacoesDeInformacoesAoMercado = {
  nome: string;
  email: string;
  telefone: string;
  posicaoNaEmpresa: string;
};

export type Banco = {
  nome: string;
  agencia: string;
  conta: string;
};

export type DadosGeraisForm = {
  isReadOnly: boolean;
  nomeFantasia: string;
  razaoSocial: string;
  cnpj: string;
  tipo: number;
  orgaoSocietario: string;
  numeroDeFuncionarios: number;
  setorDeAtuacao: number;
  emailContatoDaEmpresa: string;
  modeloDeVenda: number;
  breveHistorico: string;
  comoFuncionaOModeloDeNegocio: string;
  descricaoDasAtividades: string;
  diferenciaisDoEmissor: string;
  linhasDeReceita: LinhaDeReceita[];
  principaisFornecedores: PrincipalFornecedor[];
  dataDaConstituicao: string | null;
  dataDeEncerramentoDoExercicioSocial: string | null;
  dataDoPedidoDeRegistroNaBEE4: string | null;
  website: string;
  urlDoFacebook: string;
  urlDoYouTube: string;
  urlDoLinkedIn: string;
  urlDoInstagram: string;
  urlDoTwitter: string;
  principalRedeSocial: string;
  paisSede: string;
  cep: string;
  rua: string;
  numero: string;
  complemento: string;
  bairro: string;
  municipio: string;
  estado: string;
  tipoDeEndereco: number;
  telefone: string;
  executivoResponsavelPeloRegistro: ExecutivoResponsavelPeloRegistro;
  nomeDoCEO: string;
  cpfDoCEO: string;
  desdeQuandoOCEOOcupaOCargo: string | null;
  linkedInDoCEO: string;
  miniCVDoCEO: string;
  nomeDoPresidenteDoConselho: string;
  cpfDoPresidenteDoConselho: string;
  desdeQuandoOPresidenteDoConselhoOcupaOCargo: string | null;
  linkedInDoPresidenteDoConselho: string;
  miniCVDoPresidenteDoConselho: string;
  membrosDoConselho: MembroDoConselho[];
  numeroDeMembrosIndependentesDoConselho: number;
  periodicidadeDasReunioesDoConselho: string;
  responsabilidadeEObrigacoesDoConselho: string;
  umOuMaisMembrosTemPercentualNaCompanhia: boolean | string;
  nomeDaCompanhiaControladora: string;
  tipoDeCompanhiaControladora: number;
  percentualDaControladora: number;
  tipoDeEmpresaControladoraMajoritaria: string;
  tipoDeEmpresaControladoraMajoritariaDetalhes: string;
  acionistas: Acionista[];
  ultimaAlteracaoDoAcordoDeAcionistas: string | null;
  osPrincipaisExecutivosSaoSocios: boolean | string;
  possuiProgramaDePartnershipOuStockOptions: boolean | string;
  consultorDeListagem: ConsultorDeListagemEscriturador;
  escriturador: ConsultorDeListagemEscriturador;
  auditoria: Auditor;
  dadosBancariosDaEmpresa: Banco;
  temControlador: string;
  entreOsControladoresExisteAlgumEstatal: string;
  porcentagemDosControladores: number;
  nomeColaboradorEstatal: string;
  logo: { base64Content: string; nome: string };
  assessoriaJuridica: AssessoriaJuridica;
  assessoriaJuridicaDadosContatoFinanceiro: AssessoriaJuridicaDadosContatoFinanceiro;
  assessoriaJuridicaDiretorDeDivulgacoesDeInformacoesAoMercado: AssessoriaJuridicaDiretorDeDivulgacoesDeInformacoesAoMercado;
};
