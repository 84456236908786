import { mapType } from '../../utils/fields';

export type DocumentIPO = {
  id: string;
  nome: string;
  status: number;
  base64Content: string;
  blobContainer: string;
  blobName: string;
  createdOn: string | null;
};

export type DocumentIPODecision = {
  id: string;
  nome: string;
  base64Content: string;
  blobContainer: string;
  blobName: string;
};

export type ReviewComment = {
  comentario: string;
  criadoEm: string;
  criadoPeloUsuarioDeId: string;
  documentoGeracaoIpoId: string;
  id: string;
  ordem: number;
  nomeDoUsuario: string;
};

export type CategoryDocIPO = {
  solicitacaoPrazoExcepcional?: string;
  comentarios?: ReviewComment[];
  documentoCategoriaId: string;
  documentos?: DocumentIPO[];
  etapa?: number;
  id: string;
  isReadOnly?: boolean;
  nomeDaCategoria?: string;
  statusGeralDaCategoria?: 1 | 2;
  statusDaCategoria?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;
  statusDaCategoriaReadOnly?: boolean;
  tema?: number;
  obrigatorio: boolean;
  uploadPeloAdmin: boolean;
  permiteRefresh: boolean;
  habilitaEnvioRevisaoParaBEE4: boolean;
  excluirHidden?: boolean;
};

export type AprovacaoBEE4 = {
  avaliacaoBEE4?: 1 | 2 | 3 | 4;
  avaliacaoBEE4Descricao: string;
  notificacaoDaDecisaoDoComite: string;
  documentos: DocumentIPODecision[];
  resultadoDoComiteBEE4?: 1 | 2 | 3;
};

export type AprovacaoAdicionalBEE4 = {
  aprovacaoAdicionalIpoId: string;
  avaliacaoBEE4?: 1 | 2 | 3 | 4;
  tipoDeDecisao?: 1 | 2;
  avaliacaoBEE4Descricao: string;
  notificacaoDaDecisaoDoComite: string;
  documentos: DocumentIPODecision[];
};

export type CategoryIPO = {
  categorias: CategoryDocIPO[];
  statusDocumentacaoIpo: number;
  descricaoStatusDocumentacaoIpo: string;
  aprovacaoBEE4: AprovacaoBEE4;
  submeterParaAnaliseDaBEE4: boolean;
  submeterDecisaoDoComite: boolean;
  listaDeAprovacaoAdicionalBEE4: AprovacaoAdicionalBEE4[];
  podeAdicionarNovoComite?: boolean;
  listaDeAprovacaoAdicionalListagemBEE4?: AprovacaoAdicionalBEE4[];
};

export const temaName: mapType = {
  1: 'Financeiro',
  2: 'Jurídico',
  3: 'Material Institucional',
};

export type AddReviewIpoPayload = {
  motivo?: string;
  documentoCategoriaId: string;
  type?: 1 | 2;
};

export type IpoStatusChangePayload = {
  avaliacaoBEE4?: 1 | 2 | 3 | 4;
  nome: string;
  base64Content: string;
  tipoDeDecisao?: 1 | 2;
};

export const radioDecisionOptions = [
  {
    label: 'Comitê BEE4',
    value: 1,
  },
  {
    label: 'Validação interna',
    value: 2,
  },
];

export const listaDeDeclaracao = [
  'Elaborou o relatório ora submetido',
  'No seu maior conhecimento, reuniu todos os documentos necessários e exigidos no processo de Listagem da BEE4',
  'No seu melhor conhecimento, são verdadeiras, válidas, corretas e completas todas as informações fornecidas no referido relatório.',
];
