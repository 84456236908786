import React, { useState } from 'react';
import { ImageList, ImageListItem } from '@mui/material';
import moment from 'moment';
import 'moment/locale/pt-br';
import { Editor } from '../../../../components/editor';

import {
  CompanyName,
  Date,
  Header,
  Info,
  PostContainer,
  Lightbox,
  LeftButton,
  RightButton,
  Divider,
  RadioContainer,
} from './styles';
import { File } from '../file';
import useFatosRelevantes from '../../hooks/fatosRelevantes';
import { monthEnum, timestampToDate } from '../../../../utils/dateHandling';
import { useAuth } from '../../../../hooks/auth';
import { Bee4Published } from './componets/bee4Published';
import { PostProps, pattern, srcset } from '../../fields';
import { SingleRadio } from '../../../../components/singleRadio';

export const Post = ({ company, fatoRelevante }: PostProps) => {
  const {
    fatoRelevanteId,
    criadoEm,
    imagens,
    post,
    documentos,
    isReadOnly,
    fatoRelevanteVinculadoEmOutroCanal,
    qualCanalFoiVinculado,
  } = fatoRelevante;
  const { handlePublishPost } = useFatosRelevantes({
    currentEnterpriseId: company.id,
  });
  const { currentRole } = useAuth();

  const [lightboxDisplay, setLightboxDisplay] = useState(false);
  const [imageToShow, setImageToShow] = useState('');

  const showImage = (image: string) => {
    setImageToShow(image);
    setLightboxDisplay(true);
  };

  const hideLightBox = () => {
    setLightboxDisplay(false);
  };

  const showNext = (e: any) => {
    if (imagens) {
      e.stopPropagation();
      const currentIndex = imagens?.findIndex(item => item.url === imageToShow);
      if (currentIndex >= imagens.length - 1) {
        setLightboxDisplay(false);
      } else {
        const nextImage = imagens[currentIndex + 1].url;
        setImageToShow(nextImage);
      }
    }
  };

  const showPrev = (e: any) => {
    if (imagens) {
      e.stopPropagation();
      const currentIndex = imagens?.findIndex(item => item.url === imageToShow);
      if (currentIndex <= 0) {
        setLightboxDisplay(false);
      } else {
        const nextImage = imagens[currentIndex - 1].url;
        setImageToShow(nextImage);
      }
    }
  };

  const sizeCalculator = (index: number) => {
    return index - Math.floor(index / pattern.length) * pattern.length;
  };

  document.onkeydown = checkKey;

  function checkKey(e: any) {
    e = e || window.event;

    if (e.keyCode == '37') {
      // left arrow
      showPrev(e);
    } else if (e.keyCode == '39') {
      // right arrow
      showNext(e);
    } else if (e.keyCode == '27') {
      setLightboxDisplay(false);
    }
  }

  const parsedDate = timestampToDate(criadoEm) || '';
  const formattedDate = `${parseInt(parsedDate.slice(0, 2))} de ${
    monthEnum[parseInt(parsedDate.slice(2, 4))]
  } de ${parsedDate.slice(4)}`;
  const timeSince = moment(criadoEm).fromNow();

  return (
    <PostContainer>
      <Header>
        <Info>
          <CompanyName>{company.name}</CompanyName>
          <Date>
            {formattedDate} · {timeSince}
          </Date>
        </Info>

        <RadioContainer>
          {currentRole === 'BEE4' && (
            <Bee4Published
              confirmPublished={() => {
                handlePublishPost({ id: fatoRelevanteId });
              }}
              checked={isReadOnly}
            />
          )}
          {fatoRelevanteVinculadoEmOutroCanal && (
            <SingleRadio
              disabled
              onChange={() => {}}
              label="Fato relevante veiculado em outro canal"
              checked={fatoRelevanteVinculadoEmOutroCanal}
            />
          )}
        </RadioContainer>
      </Header>

      <Editor readOnly html={post} />
      {imagens && imagens.length > 0 && (
        <ImageList
          variant="quilted"
          cols={2}
          rowHeight={250}
          gap={20}
          style={{ marginTop: '30px' }}
        >
          {imagens.map((item, index) => (
            <ImageListItem
              key={item.imagemId}
              cols={pattern[sizeCalculator(index)].cols}
              rows={pattern[sizeCalculator(index)].rows}
              sx={{
                transition: 'opacity .3s linear',
                cursor: 'pointer',
                '&:hover': { opacity: '.8' },
              }}
              onClick={() => showImage(item.url)}
            >
              <img
                {...srcset(
                  item.url,
                  200,
                  pattern[sizeCalculator(index)].rows,
                  pattern[sizeCalculator(index)].cols,
                )}
                alt=""
                loading="lazy"
                style={{ borderRadius: '4px' }}
              />
            </ImageListItem>
          ))}
        </ImageList>
      )}
      {documentos && documentos.length > 0 && (
        <>
          <Divider />
          {documentos.map((item, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <File {...item} key={index} />
          ))}
        </>
      )}
      {lightboxDisplay && (
        <Lightbox onClick={hideLightBox}>
          <LeftButton type="button" onClick={showPrev}>
            <img src="images/arrow-down-branco.svg" alt="" />
          </LeftButton>
          <img alt="" src={imageToShow} />
          <RightButton type="button" onClick={showNext}>
            <img src="images/arrow-down-branco.svg" alt="" />
          </RightButton>
        </Lightbox>
      )}
    </PostContainer>
  );
};
