import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '../../../../components/button';
import { Controller } from '../../../../components/controller';
import { Modal } from '../../../../components/modal';
import { Buttons, Form } from './styles';

interface IModalProps {
  description: boolean;
  closeDropdown: (e?: any) => void;
  handleStatusChange: (e?: any) => void;
}
const schema = yup.object().shape({
  motivo: yup.string().required('Campo obrigatório'),
});

export const RevisionMoal: React.FC<IModalProps> = ({
  description,
  closeDropdown,
  handleStatusChange,
}) => {
  const handleConfirm = (e: any) => {
    handleStatusChange(e.motivo);
    closeDropdown();
  };

  const { handleSubmit, control, reset } = useForm<{ motivo: string }>({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    reset({
      motivo: '',
    });
  }, [reset, description]);

  return (
    <Modal open={description} handleClose={closeDropdown} width={500}>
      <Form onSubmit={handleSubmit(handleConfirm)}>
        <h3>Alterar status para revisão</h3>
        <span>
          Indique as revisões necessárias para que o Emissor e/ou Consultor de
          Listagem possam ajustar
        </span>
        <Controller
          name="motivo"
          control={control}
          type="input"
          label="Pontos a serem revisados"
          textarea
        />
        <Buttons>
          <Button
            type="button"
            variant="outlined"
            width="96px"
            onClick={closeDropdown}
          >
            Cancelar
          </Button>
          <Button type="submit" width="96px">
            Alterar status
          </Button>
        </Buttons>
      </Form>
    </Modal>
  );
};
