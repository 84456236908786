import styled from 'styled-components';

export const EmptyContainer = styled.div`
  margin: auto;
  margin-top: 8.3rem;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const EmptyContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 600px;
  gap: 0.5rem;
  text-align: center;
  line-height: normal;
  font-style: normal;

  h2 {
    color: var(--chumbo-3, #181b23);
    font-size: 2rem;
    font-weight: 700;
  }

  p {
    color: var(--cinza-5, #737787);
    font-size: 1rem;
    font-weight: 400;
  }
`;

export const TagContent = styled.div`
  border-radius: 4px;
  background: var(--azul-2, #2dbcf7);
  color: #fff;
  text-align: right;
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  padding: 0.5rem 1rem;
  width: fit-content;
`;
