import React, { useEffect, useState } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import axios, { AxiosResponse } from 'axios';
import { saveAs } from 'file-saver';
import { api } from '../../../services/api';
import { verifyError } from '../../../utils/verifyError';
import { FinancialForm } from '../fields';

interface IUseAtualizacaoCadastralHooks {
  currentEnterpriseId: string;
}

export const useFinanceiro = ({
  currentEnterpriseId,
}: IUseAtualizacaoCadastralHooks) => {
  const queryClient = useQueryClient();

  const getFinanceiro = async () => {
    const getToast = toast.loading('Carregando dados financeiro');
    try {
      const { data }: AxiosResponse<FinancialForm> = await api.main.get(
        `/v1/empresas/${currentEnterpriseId}/dados-financeiros`,
      );

      toast.update(getToast, {
        render: 'Status carregado com sucesso!',
        type: 'success',
        isLoading: false,
        autoClose: 1,
      });
      return data;
    } catch (error) {
      const message = verifyError(
        error,
        'Erro ao carregar os dados do financeiro',
      );
      toast.update(getToast, {
        render: message,
        type: 'error',
        isLoading: false,
        autoClose: 5000,
      });
    }
  };

  const {
    isLoading,
    isSuccess,
    isError,
    isIdle,
    data: financeiroData,
  } = useQuery(['financeiro'], getFinanceiro, {
    enabled: !!currentEnterpriseId,
  });

  const invalidateQuery = async () => {
    await queryClient.invalidateQueries('financeiro');
  };

  const updateDados = useMutation(async (payload: any) => {
    toast.loading('Atualizando dados');

    const response = await api.main.put(
      `/v1/empresas/${currentEnterpriseId}/dados-financeiros`,
      payload,
    );

    invalidateQuery();

    return response;
  });

  const submitDadosFinanceiro = (payload: any) => {
    updateDados.mutate(payload, {
      onSuccess: async res => {
        toast.dismiss();
        if (res?.status === 200 || res?.status === 201) {
          toast.success('Dados atualizados com sucesso!');
        }
      },
      onError: async (err: any) => {
        toast.dismiss();
        const message = verifyError(
          err,
          'Houve um erro ao tentar atualizar o formulário',
        );
        toast.error(message);
      },
    });
  };

  const updateFile = useMutation(async (payload: any) => {
    toast.loading('Atualizando dados');
    const { templateDadosFinanceiros, base64Content } = payload;

    const response = await api.main.put(
      `/v1/empresas/${currentEnterpriseId}/dados-financeiros/${templateDadosFinanceiros}/template-import`,
      { base64Content },
    );

    invalidateQuery();

    return response;
  });

  const submitTemplate = (payload: any) => {
    updateFile.mutate(payload, {
      onSuccess: async res => {
        toast.dismiss();
        if (res?.status === 200 || res?.status === 201) {
          toast.success('Dados atualizados com sucesso!');
        }
      },
      onError: async (err: any) => {
        toast.dismiss();
        const message = verifyError(
          err,
          'Houve um erro ao tentar submeter arquivo template',
        );
        toast.error(message);
      },
    });
  };

  const dowloadTemplate = async (payload: number) => {
    const getToast = toast.loading('Realizando download');

    try {
      const response: any = await api.main.get(
        `/v1/empresas/${currentEnterpriseId}/dados-financeiros/${payload}/template-download`,
        {
          responseType: 'arraybuffer',
          headers: {
            'Content-Disposition': 'attachment',
          },
        },
      );

      toast.update(getToast, {
        render: 'Download realizado com sucesso!',
        type: 'success',
        isLoading: false,
        autoClose: 1,
      });

      const filename = response.headers['content-disposition']
        ?.split('filename*=UTF-8')[1]
        ?.replace(/[\\"']/g, '')

        ?.trim();

      const url = window.URL.createObjectURL(new Blob([response.data]));
      saveAs(url, decodeURI(filename));

      return response;
    } catch (error) {
      const message = verifyError(error, 'Erro ao realizar download');
      toast.update(getToast, {
        render: message,
        type: 'error',
        isLoading: false,
        autoClose: 5000,
      });
      return error;
    }
  };

  return {
    isLoading,
    isError,
    isIdle,
    isSuccess,
    financeiroData,
    getFinanceiro,
    submitDadosFinanceiro,
    dowloadTemplate,
    submitTemplate,
    queryClient,
  };
};

export default useFinanceiro;
