import React, { useState } from 'react';
import { useEnterprise } from '../../hooks/enterprise';
import { CategoriesTable } from '../../components/categoriesTable';
import useHistoricoDocumentos from './hooks/useHistoricoDocumentos';
import { LoadingDashboard } from '../dashboardListagem/loading';
import { getDate } from '../../utils/dateHandling';
import {
  DocIpoContent,
  HistoricoButtonContainer,
  HistoricoDescriptionContainer,
} from '../documentacaoIPO/styles';
import { OrientationButton } from '../documentacaoIPO/components/orientationIpo/styles';
import { GenerateIPOModal } from '../documentacaoIPO/components/orientationModal';
import useListagem from '../documentacaoListagem/hooks/useDocumentacaoListagem';
import useDasboardListagem from '../dashboardListagem/hooks/useDashboardListagem';
import useHistoricoDocumentosListagem from './hooks/useHistoricoDocumentosListagem';
import { OutletPermify } from '../../components/outletPermify';
import { Roles } from '../../router/roles.routes';

export const HistoricoDocumentacaoIPO = () => {
  const { currentEnterpriseId } = useEnterprise();

  const { dashboardStatus } = useDasboardListagem({
    currentEnterpriseId,
  });

  const { historicoDocumentos } = useHistoricoDocumentos({
    currentEnterpriseId,
  });

  const { historicoDocumentosListagem, isLoading } =
    useHistoricoDocumentosListagem({
      currentEnterpriseId,
    });

  const listaDeAprovacaoAdicionalBEE4 =
    historicoDocumentos?.listaDeAprovacaoAdicionalBEE4;

  const listaDeAprovacaoAdicionalListagemBEE4 =
    historicoDocumentosListagem?.listaDeAprovacaoAdicionalListagemBEE4;

  const aprovacaoIPO = listaDeAprovacaoAdicionalBEE4?.length
    ? listaDeAprovacaoAdicionalBEE4[listaDeAprovacaoAdicionalBEE4?.length - 1]
    : historicoDocumentos?.aprovacaoBEE4;

  const aprovacaoListagem = listaDeAprovacaoAdicionalListagemBEE4?.length
    ? listaDeAprovacaoAdicionalListagemBEE4[
        listaDeAprovacaoAdicionalListagemBEE4?.length - 1
      ]
    : historicoDocumentosListagem?.aprovacaoBEE4;

  const lastAprovacao =
    dashboardStatus?.statusDocumentacaoIpo === 5 &&
    aprovacaoIPO?.avaliacaoBEE4 !== 2
      ? aprovacaoListagem
      : aprovacaoIPO;

  const [oritentationModal, setOrientationModal] = useState(false);

  const status =
    dashboardStatus?.statusDocumentacaoIpo === 5 &&
    aprovacaoIPO?.avaliacaoBEE4 !== 2
      ? lastAprovacao?.avaliacaoBEE4
      : historicoDocumentos?.statusDocumentacaoIpo;

  if (
    (!historicoDocumentos && isLoading) ||
    (dashboardStatus?.statusDocumentacaoIpo === 5 &&
      aprovacaoIPO?.avaliacaoBEE4 !== 2 &&
      !historicoDocumentosListagem)
  )
    return <LoadingDashboard />;

  if (!historicoDocumentos && !isLoading) {
    return <></>;
  }

  // ver texto de decisão para descrição do histórico de documentação
  const handleDecisionText = () => {
    const decisionStatus = lastAprovacao?.avaliacaoBEE4;

    if (decisionStatus === 1) return 'Aprovada';

    if (decisionStatus === 2) return 'Reprovada';

    if (decisionStatus === 3) return 'Necessário cumprir exigênicias';

    if (decisionStatus === 4) return 'Aprovada com condicionantes';
  };

  // ver texto de decisão para descrição do histórico de documentação
  const handleDecisionDate = () => {
    const decisionDate = lastAprovacao?.notificacaoDaDecisaoDoComite;

    return getDate(decisionDate);
  };

  return (
    <>
      <DocIpoContent>
        <HistoricoDescriptionContainer>
          <h2>Histórico de documentos</h2>
          <p>
            Abaixo está disponível para consulta o histórico dos documentos
            enviados para o processo de listagem até o momento da decisão do
            Comitê. Esta página não vislumbra edições posteriores.
          </p>

          <p>
            <strong>Decisão:</strong> {handleDecisionText()}
          </p>
          <HistoricoButtonContainer>
            <p>
              <strong>Data:</strong> {handleDecisionDate()}
            </p>

            <OrientationButton
              type="button"
              onClick={() => {
                setOrientationModal(true);
              }}
            >
              <img src="images/comment-icon.svg" alt="" />
              <p>
                Visite as orientações para entender as definições de status.
                <strong className="blue"> &rarr;</strong>
              </p>
            </OrientationButton>
          </HistoricoButtonContainer>
        </HistoricoDescriptionContainer>

        <OutletPermify role={[Roles.HistoricoDocumentos]}>
          <CategoriesTable
            categories={
              dashboardStatus?.statusDocumentacaoIpo === 5 &&
              aprovacaoIPO?.avaliacaoBEE4 !== 2
                ? historicoDocumentosListagem?.categorias
                : historicoDocumentos?.categorias
            }
            onStatusChange={() => {}}
            refreshDocsCategoria={() => {}}
            status={status ?? 1}
            deleteCategoria={() => {}}
            editPrazo={() => {}}
            uploadDoc={() => {}}
            addReview={() => {}}
            sendNewDocsToBEE4={() => {}}
            analyzedByCommittee={
              !!historicoDocumentos?.aprovacaoBEE4?.notificacaoDaDecisaoDoComite
            }
            type={
              dashboardStatus?.statusDocumentacaoIpo === 5 &&
              aprovacaoIPO?.avaliacaoBEE4 !== 2
                ? 2
                : 1
            }
          />
        </OutletPermify>
      </DocIpoContent>
      <GenerateIPOModal
        description={oritentationModal}
        closeDropdown={() => {
          setOrientationModal(false);
        }}
      />
    </>
  );
};
