import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import { useAuth } from '../../hooks/auth';
import { Tooltip } from '../tooltip';
import { Footer } from '../footer';

import {
  Header,
  List,
  ListItem,
  Sidebar,
  User,
  Info,
  Avatar,
  UserOptions,
  Global,
  Convite,
  Lamina,
  Settings,
  Sair,
  Wrapper,
  ListTitle,
  ListHeader,
  ListSubItem,
  ListSubItems,
  Main,
  MainContainer,
  SelectContent,
  DashboardButton,
  DashboardButtonContainer,
  ListHeaderTitle,
  ListHR,
  AvatarLogo,
  AvatarLogoWithouName,
  ButtonLinkMenu,
} from './styles';
import { PageTitle } from '../pageTitle';
import { Select } from '../select';
import { useEnterprise } from '../../hooks/enterprise';
import { ErrorBoundary } from '../errorBoundary';
import { Button } from '../button';
import { handleAvatarName, handlePath, helmetMap } from './fields';
import { DeclarationModal } from '../declarationModal';
import { ShouldRenderChildren } from '../shouldRenderChildren';
import useDasboardListagem from '../../pages/dashboardListagem/hooks/useDashboardListagem';
import { OutletPermify } from '../outletPermify';
import { Roles } from '../../router/roles.routes';
import { validarRoles } from '../../utils/roles';

type MenuProps = {
  children: any;
  onlyTop?: boolean;
};
export const Menu = ({ children, onlyTop = false }: MenuProps) => {
  const { currentRole, currentUserName, permissions } = useAuth();
  const navigate = useNavigate();
  const {
    enterprises,
    currentEnterpriseId,
    setCurrentEnterpriseId,
    updateTermsAndAgreements,
    visualizouDecisaoDoComite,
    passouPelaDecisaoDoComite,
    resultadoDoComiteBEE4,
    resultadoDoComiteBEE4Listagem,
    visualizouDecisaoDoComiteListagem,
    passouPelaDecisaoDoComiteListagem,
  } = useEnterprise();

  const { dashboardStatus } = useDasboardListagem({
    currentEnterpriseId,
  });

  const [validTerms, setValidTerms] = useState(false);

  const completed = resultadoDoComiteBEE4Listagem === 1;

  // desabilitar compos caso o comitê reprove a documentação ipo
  const disableAll =
    resultadoDoComiteBEE4 === 2 || resultadoDoComiteBEE4Listagem === 2;

  const linkStyle: any = disableAll ? { pointerEvents: 'none' } : undefined;

  const { pathname } = useLocation();

  useEffect(() => {
    document.getElementById('main')?.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    if (
      currentRole === 'Empreendedor' &&
      enterprises?.length > 0 &&
      !enterprises[0].termoEmissor
    ) {
      setValidTerms(true);
    }
  }, [pathname, currentEnterpriseId]);

  useEffect(() => {
    if (['/atualizacoes-cadastrais'].includes(pathname)) navigate('/');

    if (
      // currentRole !== 'TECHINTERNO' &&
      disableAll &&
      ((visualizouDecisaoDoComite && visualizouDecisaoDoComiteListagem) ||
        (visualizouDecisaoDoComite && resultadoDoComiteBEE4 === 2) ||
        currentRole === 'BEE4') &&
      ![
        '/administracao-global',
        '/direitos-do-usuario',
        '/manuais-bee4',
      ].includes(pathname)
    ) {
      navigate('/historico-documentacao');
    } else if (
      ((!visualizouDecisaoDoComite && passouPelaDecisaoDoComite) ||
        (!visualizouDecisaoDoComiteListagem &&
          passouPelaDecisaoDoComiteListagem)) &&
      validarRoles(permissions, [Roles.VisualizarDecisaoComite])
    ) {
      navigate('/decisao-comite');
    }
  }, [
    visualizouDecisaoDoComite,
    passouPelaDecisaoDoComite,
    currentEnterpriseId,
    resultadoDoComiteBEE4,
    visualizouDecisaoDoComiteListagem,
    passouPelaDecisaoDoComiteListagem,
    resultadoDoComiteBEE4Listagem,
  ]);

  const handleEnterprises = () => {
    return enterprises.map(enterprise => {
      return {
        value: enterprise.empresaId,
        label: enterprise.nomeFantasia,
      };
    });
  };

  const [showMenu, setShowMenu] = useState<
    | 'dashboard'
    | 'fase1'
    | 'fase2'
    | 'fase3'
    | 'fase4'
    | 'relatoriosPosListagem'
    | 'atualizacoesCadastrais'
    | 'fatosRelevantes'
    | 'historicoComite'
    | '404'
  >(handlePath(pathname));

  const [activeSublist, setActiveSublist] = useState<boolean>(
    showMenu === 'fase1',
  );

  function handleClickMenu(
    value:
      | 'dashboard'
      | 'fase1'
      | 'fase2'
      | 'fase3'
      | 'fase4'
      | 'relatoriosPosListagem'
      | 'atualizacoesCadastrais'
      | 'fatosRelevantes'
      | 'historicoComite',
  ) {
    setShowMenu(value);
  }

  const getTermoRoute = () => {
    switch (currentRole) {
      case 'Empreendedor':
        return '/termo-emissor';
      case 'NOMADBOSS':
        return '/termo-cl';
      case 'NOMAD':
        return '/termo-cl-colaborador';
      case 'TECHTERCEIRO':
        return '/termo-parceiro';
      default:
        return '';
    }
  };

  return (
    <>
      <Header>
        <Link to="/">
          <picture>
            <img src="/logo-emissores.png" alt="Logo da bee4" />
          </picture>
        </Link>
        <div style={{ display: 'flex' }}>
          {currentRole !== 'Empreendedor' ? (
            <SelectContent>
              <Select
                options={handleEnterprises()}
                value={currentEnterpriseId}
                height="52px"
                onChange={(e: any) => {
                  navigate('/dashboard-listagem');
                  setCurrentEnterpriseId(e);
                }}
              />
            </SelectContent>
          ) : null}

          <OutletPermify role={[Roles.ConsultarClColaborador]}>
            <Button color="info" style={{ margin: '0 2rem 0 1rem' }}>
              <Link
                to="/colaboradores"
                style={{
                  display: 'flex',
                  gap: '0.5rem',
                  alignItems: 'center',
                  textDecoration: 'none',
                  color: 'black',
                }}
              >
                <GroupAddOutlinedIcon />
                Colaboradores
              </Link>
            </Button>
          </OutletPermify>

          {/* {currentRole === 'Empreendedor' ? ( */}
          <OutletPermify role={[Roles.ConsultarEmissor]}>
            <Button color="info" style={{ margin: '0 2rem 0 1rem' }}>
              <Link
                to="/emissores"
                style={{
                  display: 'flex',
                  gap: '0.5rem',
                  alignItems: 'center',
                  textDecoration: 'none',
                  color: 'black',
                }}
              >
                <GroupAddOutlinedIcon />
                Convidar
              </Link>
            </Button>
          </OutletPermify>
          {/* ) : null} */}
          <Button color="info" style={{ margin: '0 2rem 0 1rem' }}>
            <Link
              to="/manuais-bee4"
              style={{
                display: 'flex',
                gap: '0.5rem',
                alignItems: 'center',
                textDecoration: 'none',
                color: 'black',
              }}
            >
              <InsertDriveFileOutlinedIcon />
              Biblioteca
            </Link>
          </Button>
          <User>
            <Info>
              <Avatar>
                {currentUserName ? (
                  <AvatarLogo>{handleAvatarName(currentUserName)}</AvatarLogo>
                ) : (
                  <AvatarLogoWithouName />
                )}
              </Avatar>
              <span>{currentUserName}</span>
              <img src="images/arrow-down.svg" className="arrow" alt="" />
            </Info>
            <UserOptions>
              <ul>
                {/* {currentRole === 'TECHINTERNO' && ( */}
                <OutletPermify role={[Roles.AcessarAdminGlobal]}>
                  <Link to="/administracao-global">
                    <li>
                      <Global src="images/global.svg" alt="" />
                      <span>Administração global</span>
                    </li>
                  </Link>
                </OutletPermify>
                <OutletPermify role={[Roles.ConsultarUsuario]}>
                  <Link to="/gestao-usuarios">
                    <li>
                      <Global src="images/users.svg" alt="" />
                      <span>Gestão de usuários</span>
                    </li>
                  </Link>
                </OutletPermify>
                {/* <Link to="/convidar-usuario">
                      <li>
                        <Convite src="images/convite.svg" alt="" />
                        <span>Convites</span>
                      </li>
                    </Link>
                    <Link to="/dados-da-oferta">
                      <li>
                        <Lamina src="images/lamina.svg" alt="" />
                        <span>Lâmina</span>
                      </li>
                    </Link> */}
                {/* )} */}

                <Link to="/manual-emissor" target="_blank">
                  <li>
                    <Settings src="images/manual-emissor-item.svg" alt="" />
                    <span>Manual de Utilização</span>
                  </li>
                </Link>

                {(currentRole === 'Empreendedor' ||
                  currentRole === 'NOMAD' ||
                  currentRole === 'NOMADBOSS' ||
                  currentRole === 'TECHTERCEIRO') && (
                  <Link to={getTermoRoute()} target="_blank">
                    <li>
                      <Settings src="images/termo-icon.svg" alt="" />
                      <span>Termos de Adesão</span>
                    </li>
                  </Link>
                )}

                <Link
                  to="https://bee4.com.br/politica-de-privacidade/"
                  target="_blank"
                >
                  <li>
                    <Settings
                      src="images/politica-privacidade-icon.svg"
                      alt=""
                    />
                    <span>Política de Privacidade</span>
                  </li>
                </Link>
                {currentRole === 'Empreendedor' && (
                  <ButtonLinkMenu
                    onClick={() => {
                      setValidTerms(true);
                    }}
                  >
                    <li>
                      <Settings src="images/declaration-icon.svg" alt="" />
                      <span className="buttonSpan">Declarações</span>
                    </li>
                  </ButtonLinkMenu>
                )}

                {/* currentRole === 'Empreendedor' && (
                  <Link to="/manual-emissor" target="_blank">
                    <li>
                      <Settings src="images/declaracoes-icon.svg" alt="" />
                      <span>Declarações</span>
                    </li>
                  </Link>
                ) */}
                <Link to="/direitos-do-usuario">
                  <li>
                    <Settings src="images/settings-preto.svg" alt="" />
                    <span>Direitos do usuário</span>
                  </li>
                </Link>
                <hr />
                <Link to="/sair">
                  <li>
                    <Sair src="images/System/10.svg" alt="" />
                    <span>Sair</span>
                  </li>
                </Link>
              </ul>
            </UserOptions>
          </User>
        </div>
      </Header>
      {!onlyTop ? (
        <Wrapper>
          <Sidebar>
            <List>
              <DashboardButtonContainer>
                <OutletPermify role={[Roles.VisualizarDashboard]}>
                  <Link
                    to="/dashboard-listagem"
                    style={{
                      textDecoration: 'none',
                      pointerEvents:
                        resultadoDoComiteBEE4 === 2 ||
                        resultadoDoComiteBEE4Listagem === 2
                          ? 'none'
                          : undefined,
                    }}
                  >
                    <DashboardButton
                      type="button"
                      onClick={() => handleClickMenu('dashboard')}
                      disabled={
                        resultadoDoComiteBEE4 === 2 ||
                        resultadoDoComiteBEE4Listagem === 2
                      }
                    >
                      <img
                        className="emptyIcon"
                        src="images/dashboardButtonMenuIcon.svg"
                        alt=""
                      />
                      Dashboard
                    </DashboardButton>
                  </Link>
                </OutletPermify>
              </DashboardButtonContainer>
              <ListHeader>
                <ListTitle listed>
                  <span>LISTAGEM</span>
                </ListTitle>
              </ListHeader>
              <ListItem
                active={showMenu === 'fase1'}
                subItemActive={handlePath(pathname) === 'fase1'}
                disabled={disableAll}
                style={linkStyle}
              >
                <ListHeaderTitle
                  listed={activeSublist}
                  onClick={() => {
                    handleClickMenu('fase1');
                    setActiveSublist(current => !current);
                  }}
                >
                  <img src="images/emissaoIcon.svg" alt="" />
                  <span>Documentação</span>
                  {activeSublist ? (
                    <RemoveIcon sx={{ color: '#454b62' }} />
                  ) : (
                    <AddIcon sx={{ color: '#454b62' }} />
                  )}
                </ListHeaderTitle>
                <ListSubItems listed={activeSublist}>
                  <ListHR listed={activeSublist} />

                  <OutletPermify role={[Roles.VisualizarDadosGerais]}>
                    <Link to="/dados-gerais" style={linkStyle}>
                      <ListSubItem listed={pathname === '/dados-gerais'}>
                        <span>Dados gerais</span>
                      </ListSubItem>
                    </Link>
                  </OutletPermify>

                  <OutletPermify role={[Roles.VisualizarFinanceiroENeg]}>
                    <Link to="/documentacao-adicional" style={linkStyle}>
                      <ListSubItem
                        listed={pathname === '/documentacao-adicional'}
                      >
                        <span>Documentação adicional</span>
                      </ListSubItem>
                    </Link>
                  </OutletPermify>
                  {/* <Link to="/diligencia" style={linkStyle}>
                    <ListSubItem listed={pathname === '/diligencia'}>
                      <span>Diligência</span>
                    </ListSubItem>
                  </Link> */}
                  <OutletPermify role={[Roles.VisualizarSobreAtivo]}>
                    <Link to="/sobre-o-ativo" style={linkStyle}>
                      <ListSubItem listed={pathname === '/sobre-o-ativo'}>
                        <span>Sobre o ativo</span>
                      </ListSubItem>
                    </Link>
                  </OutletPermify>
                  {/* {dashboardStatus?.statusDocumentacaoIpo === 5 && (
                    <Link to="/dados-da-oferta" style={linkStyle}>
                      <ListSubItem listed={pathname === '/dados-da-oferta'}>
                        <span>Dados da oferta</span>
                      </ListSubItem>
                    </Link>
                  )} */}
                </ListSubItems>
              </ListItem>

              <OutletPermify role={[Roles.HistoricoComite]}>
                <Link to="/historico-comite">
                  <ListItem
                    active={showMenu === 'historicoComite'}
                    subItemActive={handlePath(pathname) === 'historicoComite'}
                    onClick={() => handleClickMenu('historicoComite')}
                  >
                    <ListTitle listed>
                      <img
                        src="images/pen.svg"
                        alt=""
                        style={{ width: 24, marginRight: -6 }}
                      />
                      <span>Decisão do Comitê</span>
                    </ListTitle>
                  </ListItem>
                </Link>
              </OutletPermify>
            </List>

            <Tooltip
              disabled={completed}
              title="O Pós-listagem só será liberado após a empresa estiver listada na BEE4."
            >
              <List>
                <ListHeader>
                  <ListTitle listed>
                    <span>PÓS-LISTAGEM</span>
                  </ListTitle>
                </ListHeader>
                <OutletPermify role={[Roles.VisualizarRelatorio]}>
                  <Link
                    to="/relatorios-pos-listagem"
                    style={completed ? undefined : { pointerEvents: 'none' }}
                  >
                    <ListItem
                      active={showMenu === 'relatoriosPosListagem'}
                      subItemActive={
                        handlePath(pathname) === 'relatoriosPosListagem'
                      }
                      disabled={!completed}
                      onClick={() => handleClickMenu('relatoriosPosListagem')}
                    >
                      <ListTitle listed>
                        <img src="images/relatoriosIcon.svg" alt="" />
                        <span>Relatórios</span>
                      </ListTitle>
                    </ListItem>
                  </Link>
                </OutletPermify>
                {/* <Link
                  to="/atualizacoes-cadastrais"
                  style={completed ? undefined : { pointerEvents: 'none' }}
                >
                  <ListItem
                    active={showMenu === 'atualizacoesCadastrais'}
                    subItemActive={
                      handlePath(pathname) === 'atualizacoesCadastrais'
                    }
                    disabled={!completed}
                    onClick={() => handleClickMenu('atualizacoesCadastrais')}
                  >
                    <ListTitle listed>
                      <img src="images/atualizacoesCadastraisIcon.svg" alt="" />
                      <span>Atualizações cadastrais</span>
                    </ListTitle>
                  </ListItem>
                </Link> */}
                <OutletPermify role={[Roles.VisualizarFatoRelevante]}>
                  <Link
                    to="/fatos-relevantes"
                    style={completed ? undefined : { pointerEvents: 'none' }}
                  >
                    <ListItem
                      active={showMenu === 'fatosRelevantes'}
                      subItemActive={handlePath(pathname) === 'fatosRelevantes'}
                      disabled={!completed}
                      onClick={() => handleClickMenu('fatosRelevantes')}
                    >
                      <ListTitle listed>
                        <img src="images/fatosRelevantesIcon.svg" alt="" />
                        <span>Fatos relevantes</span>
                      </ListTitle>
                    </ListItem>
                  </Link>
                </OutletPermify>
              </List>
            </Tooltip>
          </Sidebar>
          <HelmetProvider>
            <Main id="main">
              <Helmet>
                <title>{helmetMap[pathname] ?? 'Sistema Beegin'}</title>
              </Helmet>

              <PageTitle />
              <MainContainer>
                <ErrorBoundary>{children}</ErrorBoundary>
              </MainContainer>
              <Footer />
            </Main>
          </HelmetProvider>
        </Wrapper>
      ) : (
        <HelmetProvider>
          <Main id="main">
            <Helmet>
              <title>{helmetMap[pathname] ?? 'Sistema Beegin'}</title>
            </Helmet>

            <PageTitle />
            <MainContainer>
              <ErrorBoundary>{children}</ErrorBoundary>
            </MainContainer>
            <Footer />
          </Main>
        </HelmetProvider>
      )}

      <ShouldRenderChildren
        shouldRender={currentRole === 'Empreendedor' && validTerms}
      >
        <DeclarationModal
          description={validTerms}
          closeDropdown={() => {
            setValidTerms(false);
          }}
          onConfirm={() => {
            updateTermsAndAgreements();
          }}
          isConfirmed={enterprises?.length > 0 && enterprises[0]?.termoEmissor}
        />
      </ShouldRenderChildren>
    </>
  );
};
