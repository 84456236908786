import React from 'react';
import { Modal } from '../../../modal';
import Carregando from '../../../carregando';
import { DocFileViewer } from '../../../docViewer';

interface IButtonProps {
  description: boolean;
  closeDropdown: (e?: any) => void;
  base64?: string;
  canDownload?: boolean;
  fileType?: 'jpg' | 'png' | 'xls' | 'xlsx' | 'csv' | 'pdf' | 'doc' | 'docx';
  fileName: string;
}
export const ViewDocumentModal: React.FC<IButtonProps> = ({
  description,
  closeDropdown,
  base64,
  canDownload = false,
  fileType = 'pdf',
  fileName,
}) => {
  if (!base64) {
    return (
      <Modal open={description} handleClose={closeDropdown} width={1000}>
        <div
          style={{ position: 'relative', height: '600px', textAlign: 'center' }}
        >
          <h3>Analisando documento</h3>
          <Carregando />
        </div>
      </Modal>
    );
  }
  return (
    <Modal
      open={description}
      handleClose={closeDropdown}
      width={1000}
      height={700}
    >
      <div style={{ position: 'relative', height: '100%', width: '100%' }}>
        <DocFileViewer
          url={base64}
          canDownload={canDownload}
          fileType={fileType}
          fileName={fileName}
        />
      </div>
    </Modal>
  );
};
