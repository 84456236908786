import React from 'react';
import { mapType } from '../../utils/fields';

export type StatusType = {
  status: number;
  motivo: string;
  podeAlterarOStatus: boolean;
  atualizadoEm?: string;
  criadoEm: string;
};

export type Response = {
  empresaId?: string;
  dadosDaEmpresaInfo?: StatusType;
  dadosFinanceirosInfo?: StatusType;
  dadosDeDiligenciaInfo?: StatusType;
  dadosParaEmissaoInfo?: StatusType;
  pedidoDeRegistroParaListagemNaBEE4Info?: StatusType;
  documentacaoIpoInfo?: StatusType;
  statusDocumentacaoIpo?: number;
  statusListagemIpo?: number;
  dadosDaOfertaInfo?: StatusType;
};

export const stepMap: mapType = {
  1: (
    <>
      <img src="images/clipboard-azul.svg" alt="" />
      <span>Dados gerais</span>
    </>
  ),
  2: (
    <>
      <img src="images/financeiro-azul.svg" alt="" />
      <span>Documentação adicional</span>
    </>
  ),
  3: (
    <>
      <img src="images/diligencia-azul.svg" alt="" />
      <span>Diligência</span>
    </>
  ),
  4: (
    <>
      <img src="images/emissao-azul.svg" alt="" />
      <span>Sobre o ativo</span>
    </>
  ),
};

export const statusMap: mapType = {
  1: 'Pendente',
  2: 'Revisão',
  3: 'Em andamento',
  5: 'Validado',
};

export const stepNames: mapType = {
  1: 'dadosDaEmpresa',
  2: 'dadosFinanceiros',
  3: 'dadosParaEmissao',
  // 3: 'dadosDeDiligencia',
  // 5: 'dadosDaOferta',
};

export const titleMap: mapType = {
  1: (
    <span>
      <b>Cadastro e diligência</b>
      <br />
      Dados gerais
    </span>
  ),
  2: (
    <span>
      <b>Cadastro e diligência</b>
      <br />
      Dados financeiros
    </span>
  ),
  3: (
    <span>
      <b>Cadastro e diligência</b>
      <br />
      Jurídico
    </span>
  ),
  4: (
    <span>
      <b>Cadastro e diligência</b>
      <br />
      Diligência
    </span>
  ),
  5: (
    <span>
      <b>Sobre o ativo</b>
      <br />
      Dados sobre o ativo
    </span>
  ),
  6: (
    <span>
      <b>Dossiê</b>
      <br />
      Conteúdo do dossiê
    </span>
  ),
};

export const iconMap: mapType = {
  1: {
    approved: 'images/clipboard-branco.svg',
    notApproved: 'images/clipboard-azul.svg',
  },
  2: {
    approved: 'images/financeiro-branco.svg',
    notApproved: 'images/financeiro-azul.svg',
  },
  3: {
    approved: 'images/juridico-branco.svg',
    notApproved: 'images/juridico-azul.svg',
  },
  4: {
    approved: 'images/diligencia-branco.svg',
    notApproved: 'images/diligencia-azul.svg',
  },
  5: {
    approved: 'images/emissao-branco.svg',
    notApproved: 'images/emissao-azul.svg',
  },
  6: {
    approved: 'images/dossie-branco.svg',
    notApproved: 'images/dossie-azul.svg',
  },
};

export const routeMap: mapType = {
  1: '/dados-gerais',
  2: '/documentacao-adicional',
  3: '/juridico',
  // 4: '/diligencia',
  5: '/sobre-o-ativo',
};

export const routeName: any = {
  '/dados-gerais': 'Dados gerais',
  '/documentacao-adicional': 'Documentação adicional',
  '/juridico': 'Jurídico',
  // '/diligencia': 'Diligência',
  '/sobre-o-ativo': 'Sobre o ativo',
  '/dados-da-oferta': 'Dados da oferta',
};

export const statusMapEmp: mapType = [
  { status: 1, title: 'Pendente' },
  { status: 3, title: 'Em andamento' },
  { status: 2, title: 'Revisão' },
  { status: 5, title: 'Validado' },
];

export const statusCompleted = (status?: number) => {
  if (!status) return [0, 0, 0, 0];

  if (status === 1) {
    return [1, 0, 0, 0];
  }
  if (status === 2) {
    return [2, 1, 0, 0];
  }
  if (status === 3) {
    return [2, 2, 1, 0];
  }
  if (status === 4) {
    return [2, 2, 2, 1];
  }
  if (status === 5) {
    return [2, 2, 2, 2];
  }
  return [0, 0, 0, 0];
};

export const statusCompletedListagem = (status?: number) => {
  if (!status) return [0, 0, 0, 0];

  if (status === 1) {
    return [1, 0, 0, 0];
  }
  if (status === 2) {
    return [2, 1, 0, 0];
  }
  if (status === 3) {
    return [2, 2, 1, 0];
  }
  if (status === 4) {
    return [2, 2, 2, 1];
  }
  if (status === 5) {
    return [2, 2, 2, 2];
  }
  return [0, 0, 0, 0];
};
