import styled from 'styled-components';

interface TitleProps {
  isDashboardListagemEmpreeendedor: boolean;
}

export const Title = styled.div<TitleProps>`
  display: flex;
  padding: 0 40px;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  height: 92px;

  @media (max-width: 1440px) {
    // Na dashboard-listagem do usuário, o main pode ter um scroll horizontal dependendo da width da tela. Aqui garante que o pageTitle acompanhe esse tamanho
    width: ${props =>
      props.isDashboardListagemEmpreeendedor ? '1223px' : '100%'};
    padding: 0 40px;
  }

  h2 {
    font-weight: 600;
    color: #454b62;
    font-size: 18px;
    font-family: Georama;
    letter-spacing: 0.72px;
  }

  .help {
    display: flex;
    gap: 16px;
    align-items: center;

    a {
      text-decoration: none;
    }
  }

  span {
    font-weight: medium;
    font-size: 12px;
    font-family: Georama;
    letter-spacing: 1.2px;
  }
`;

export const FixedTitle = styled.div`
  display: flex;
  padding: 32px 40px;
  justify-content: space-between;
  width: fit-content;
  align-items: center;
  height: fit-content;
  position: fixed;
  box-shadow: inset 0px -1px 1px -1px rgba(0, 0, 0, 0.5);
  z-index: 5;
  background: white;
  min-width: 400px;
  width: 100%;

  h2 {
    font-weight: 600;
    color: #454b62;
    font-size: 18px;
    font-family: Georama;
    letter-spacing: 0.72px;
  }

  .help {
    display: flex;
    gap: 16px;
    align-items: center;

    a {
      text-decoration: none;
    }
  }

  span {
    font-weight: medium;
    font-size: 12px;
    font-family: Georama;
    letter-spacing: 1.2px;
  }
`;

export const Description = styled.p`
  font-weight: 400;
  font-size: 14px;
  margin-top: 0.6rem;
  width: 653px;
`;
