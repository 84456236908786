import React from 'react';
import { ToastContainer } from 'react-toastify';
import { QueryClient, QueryClientProvider } from 'react-query';
import { AuthProvider } from './hooks/auth';
import { Router } from './router';
import { GlobalStyle } from './styles/global';
import { EnterpriseProvider } from './hooks/enterprise';
import { RouterWithoutToken } from './router/routerWithoutToken';
import { routeWithNoToken } from './router/routeCheck';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  if (routeWithNoToken()) {
    return (
      <QueryClientProvider client={queryClient}>
        <ToastContainer />
        <RouterWithoutToken />
        <GlobalStyle />
      </QueryClientProvider>
    );
  }
  return (
    <>
      <AuthProvider>
        <EnterpriseProvider>
          <QueryClientProvider client={queryClient}>
            <ToastContainer />
            <GlobalStyle />
            <Router />
          </QueryClientProvider>
        </EnterpriseProvider>
      </AuthProvider>
    </>
  );
}

export default App;
