import { useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { AxiosResponse } from 'axios';
import { api } from '../services/api';
import { verifyError } from '../utils/verifyError';

interface Company {
  nome: string;
  id: string;
}
export const useCompanies = ({
  id,
  type,
}: {
  id?: string;
  type?: 'nomad' | 'escriturador' | 'auditor';
}) => {
  const queryClient = useQueryClient();

  const handleType = () => {
    if (!type) {
      return '';
    }
    if (type === 'nomad') {
      if (id) {
        return `?filtro=SemNomad&nomad-id=${id}`;
      }
      return '?filtro=SemNomad';
    }
    if (type === 'escriturador') {
      if (id) {
        return `?filtro=SemEmpresaEscrituradora&empresa-escrituradora-id=${id}`;
      }
      return '?filtro=SemEmpresaEscrituradora';
    }
    if (type === 'auditor') {
      if (id) {
        return `?filtro=2&auditoria-id=${id}`;
      }
      return '?filtro=2';
    }
    return '';
  };

  const getCompanies = async () => {
    const getToast = toast.loading('Carregando empresas disponíveis');

    try {
      const response: AxiosResponse<any> = await api.main.get(
        `/v1/empresas${handleType()}`,
      );

      toast.update(getToast, {
        render: 'Empresas carregadas com sucesso!',
        type: 'success',
        isLoading: false,
        autoClose: 1,
      });
      return response?.data;
    } catch (error) {
      const message = verifyError(
        error,
        'Erro ao carregar empresas disponíveis',
      );
      toast.update(getToast, {
        render: message,
        type: 'error',
        isLoading: false,
        autoClose: 5000,
      });
    }
  };

  const {
    isLoading,
    isSuccess,
    isError,
    isIdle,
    data: companies,
  } = useQuery(['empresas', id, type], getCompanies, {});

  const invalidateQuery = async () => {
    await queryClient.invalidateQueries('empresasColaborador');
  };

  return {
    isLoading,
    isError,
    isIdle,
    isSuccess,
    companies,
    getCompanies,
    queryClient,
  };
};

export default useCompanies;
