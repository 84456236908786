import React, { useState, useRef } from 'react';
import MUISkeleton from '@mui/material/Skeleton';

interface SkeletonProps {
  classes?: string;
  width?: string;
  height?: string;
  variant: 'circular' | 'rectangular' | 'text';
}

export const Skeleton = ({
  width,
  height,
  variant = 'text',
  classes,
}: SkeletonProps) => {
  return (
    <MUISkeleton
      className={classes}
      variant={variant}
      width={width}
      height={height}
    />
  );
};
