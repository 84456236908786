import React, { useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import isEqual from 'lodash/isEqual';
import { Controller } from '../../../components/controller';
import { Collaborator } from '../../administracaoGlobal/components/companies/fields';
import useCollaboratorCompanies from '../hooks/collaboratorCompanies';
import { Buttons, ModalDescription, ModalTitle } from '../styles';
import { Modal } from '../../../components/modal';
import { Grid } from '../../../components/grid';
import { Button } from '../../../components/button';
import { schemaColaboradorNomad } from '../fields';

interface IButtonProps {
  description: boolean;
  closeDropdown: (e?: any) => void;
  companyId: string;
  defaultValue?: (string | undefined)[];
  createCollaborator: (e: any) => void;
  updateColaborador: (e: any) => void;
  collaborator?: Collaborator;
}

export const CreateEditNomadCollaboratorModal: React.FC<IButtonProps> = ({
  description,
  closeDropdown,
  companyId,
  createCollaborator,
  updateColaborador,
  collaborator,
  defaultValue,
}) => {
  const { companies, isLoading } = useCollaboratorCompanies({
    companyId,
    colaboradorId: collaborator?.id,
  });

  const { handleSubmit, control, reset } = useForm<Collaborator>({
    resolver: yupResolver(schemaColaboradorNomad),
  });

  const empresasConsultor = (data: any) => {
    const empresasConsultorUnchanged = isEqual(
      collaborator?.empresasConsultor,
      data?.empresasConsultor,
    );
    if (empresasConsultorUnchanged) {
      return data?.empresasConsultor;
    }
    return (
      data?.empresasConsultor?.map((emp: any) => {
        return {
          empresaId: emp,
        };
      }) ?? []
    );
  };

  const submitForm = (data: Collaborator) => {
    if (!data.email) {
      toast.error('Coloque um email para criar o novo colaborador.');
      return;
    }
    if (collaborator) {
      updateColaborador({
        id: companyId,
        consulter: {
          ...data,
          empresasConsultor: empresasConsultor(data),
        },
        consultorId: collaborator.id,
      });
    } else {
      createCollaborator({
        id: companyId,
        consulter: {
          ...data,
          empresasConsultor:
            data?.empresasConsultor?.map(emp => {
              return {
                empresaId: emp,
              };
            }) ?? [],
        },
      });
    }

    closeDropdown();
  };

  useEffect(() => {
    reset({
      id: collaborator?.id,
      nome: collaborator?.nome,
      cpf: collaborator?.cpf,
      celular: collaborator?.celular,
      email: collaborator?.email,
      empresasConsultor: collaborator?.empresasConsultor,
    });
  }, [reset]);

  return (
    <Modal open={description} handleClose={closeDropdown} width={1200}>
      <form onSubmit={handleSubmit(submitForm)}>
        <ModalTitle>
          {collaborator ? 'Editar' : 'Convidar'} colaborador(a)
        </ModalTitle>
        <Grid container spacing={4}>
          {!collaborator && (
            <Grid item desktop={12}>
              <ModalDescription>
                Ao convidar um(a) Colaborador(a), este(a) usuário(a) poderá
                inserir dados e documentos no sistema pela Empresa Emissora em
                que ficará responsável, porém este(a) não poderá deletar
                documentos.
              </ModalDescription>
              <br />
              <ModalDescription>
                Caso este(a) Colaborador(a) não deva mais acessar o sistema, é
                de sua responsabilidade deletar o(a) usuário(a).
              </ModalDescription>
            </Grid>
          )}
          <Grid item desktop={6}>
            <Controller
              name="nome"
              control={control}
              type="input"
              label="Nome completo"
            />
          </Grid>
          <Grid item desktop={6}>
            <Controller
              name="cpf"
              control={control}
              type="input"
              mask="cpf"
              label="CPF"
              disabled={!!collaborator}
            />
          </Grid>
          <Grid item desktop={6}>
            <Controller
              name="celular"
              control={control}
              type="input"
              mask="phone"
              label="Celular"
            />
          </Grid>
          <Grid item desktop={6}>
            <Controller
              name="email"
              control={control}
              type="input"
              label="E-mail do Colaborador"
              disabled={!!collaborator}
            />
          </Grid>
          <Grid item desktop={12}>
            <Controller
              name="empresasConsultor"
              control={control}
              type="multipleselect"
              label="SELECIONE A(S) EMPRESA(S) PELA(S) QUAL(IS) É RESPONSÁVEL"
              multipleSelectOptions={companies}
              defaultValue={defaultValue}
              disabled={isLoading}
            />
          </Grid>
        </Grid>
        <Buttons>
          <Button
            width="103px"
            height="40px"
            variant="outlined"
            onClick={closeDropdown}
          >
            CANCELAR
          </Button>
          <Button type="submit" width="103px" height="40px">
            SALVAR
          </Button>
        </Buttons>
      </form>
    </Modal>
  );
};
