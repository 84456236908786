import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useAuth } from '../../../../hooks/auth';
import { useEnterprise } from '../../../../hooks/enterprise';

import { verifyError } from '../../../../utils/verifyError';

import { toBase64 } from '../../../../services/toBase64';
import { UploadModal } from '../../../uploadModal';
import { AccordionDocuments } from '../documentList/accordion';
import { DescriptionListContent, Container } from '../documentList/styles';
import {
  DocumentosRequest,
  DocumentPerCategory,
} from '../../../../pages/atualizacoesCadastrais/fields';
import { DocumentsContainerAC } from './documentsContainer';

export const DocumentAtualizacaoCadastral = ({
  categories,
  readOnly,
  newDocs,
  setNewDocs,
  setRemoveDocs,
  deleteDoc,
}: {
  categories?: DocumentPerCategory[];
  readOnly: boolean;
  newDocs?: DocumentosRequest[];
  setNewDocs: (e: DocumentosRequest) => void;
  setRemoveDocs: (e: string) => void;
  deleteDoc: (e: string, v: string) => void;
}) => {
  const [addModal, setAddModal] = useState(false);
  const [chosenCategory, setChosenCategory] = useState<any>({});
  const { currentRole } = useAuth();
  const [newCategories, setNewCategories] = useState<any[] | undefined>(
    categories,
  );
  const [docs, setDocs] = useState<any>({});

  const handleResquestedDocuments = (newDoc: any, categoriaId: string) => {
    const tempCategories = newCategories?.map(category => {
      if (categoriaId === category.id) {
        if (category.documentos) {
          return {
            ...category,
            documentos: [...category.documentos, newDoc],
          };
        }
        if (docs[categoriaId]) {
          return { ...category, documentos: [...docs[categoriaId], newDoc] };
        }
        return { ...category, documentos: [newDoc] };
      }

      return category;
    });

    setDocs((currentDocs: any) =>
      currentDocs[categoriaId]
        ? {
            ...currentDocs,
            [categoriaId]: [...currentDocs[categoriaId], newDoc],
          }
        : { ...currentDocs, [categoriaId]: [newDoc] },
    );

    setNewCategories(tempCategories);
  };

  const handleDeleteDocuments = (removedDoc: any, categoriaId: string) => {
    const tempCategories = newCategories?.map(category => {
      if (categoriaId === category.id && category.documentos) {
        return {
          ...category,
          documentos: category.documentos.filter(
            (doc: any) => doc !== removedDoc,
          ),
        };
      }

      return category;
    });

    if (removedDoc.id) {
      deleteDoc(removedDoc.id, categoriaId);
    } else {
      setRemoveDocs(removedDoc.base64Content);
      setDocs((currentDocs: any) => ({
        ...currentDocs,
        [currentDocs[removedDoc.categoriaId]]: currentDocs[
          removedDoc.categoriaId
        ].filter((v: any) => v !== removedDoc),
      }));
    }

    setNewCategories(tempCategories);
  };

  useEffect(() => {
    setNewCategories(categories);
  }, [categories]);

  const handleOpenModal = (e: any, category: any) => {
    e.stopPropagation();
    setAddModal(true);
    setChosenCategory(category);
  };

  const handleSave = async (files: File[]) => {
    if (!files) {
      toast.error('Coloque um documento antes de salvar');
    } else {
      const base64Content = await toBase64(files[0]);
      const nome = files[0].name;

      const categoriaId = chosenCategory?.id;

      if (base64Content) {
        const payload: any = {
          nome,
          // @ts-ignore
          base64Content,
          categoriaId,
        };
        setNewDocs(payload);

        handleResquestedDocuments(payload, categoriaId);
      }
      setAddModal(false);
    }
  };

  const listCategory =
    newCategories != null ? (
      newCategories.map((category: any, index: number) => {
        return (
          // eslint-disable-next-line react/no-array-index-key
          <div style={{ padding: '0.7rem 0' }} key={index}>
            <AccordionDocuments
              title={category.nomeDaCategoria}
              description={category.informacao}
              mandatory={category.obrigatorio}
              readOnly={readOnly}
              onOpenModal={e => handleOpenModal(e, category)}
            >
              <DocumentsContainerAC
                docList={category.documentos}
                descricao={category.descricao}
                urlDoArquivoTemplate={category.urlDoArquivoTemplate}
                readOnly={
                  readOnly !== undefined ? readOnly : category.isReadOnly
                }
                onDelete={e => {
                  handleDeleteDocuments(e, category.id);
                }}
              />
            </AccordionDocuments>
          </div>
        );
      })
    ) : (
      <></>
    );

  return (
    <Container>
      <DescriptionListContent>
        <h2>Anexos</h2>
      </DescriptionListContent>
      {listCategory}
      <UploadModal
        openModal={addModal}
        onSave={files => {
          handleSave(files);
        }}
        onClose={() => {
          setAddModal(false);
        }}
        dialogTitle="Importar documentos"
      />
    </Container>
  );
};
