import React, { useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { AxiosResponse } from 'axios';
import { api } from '../../../services/api';
import { verifyError } from '../../../utils/verifyError';

export const useCollaboratorCompanies = ({
  companyId,
  colaboradorId,
}: {
  companyId: string;
  colaboradorId?: string;
}) => {
  const queryClient = useQueryClient();

  const getCollaboratorCompanies = async () => {
    const getToast = toast.loading('Carregando empresas disponíveis');
    try {
      const response: AxiosResponse<any> = await api.main.get(
        `/v1/empresas/sem-colaborador/${companyId}${
          colaboradorId ? `?colaboradorId=${colaboradorId}` : ''
        }`,
      );
      toast.update(getToast, {
        render: 'Empresas carregadas com sucesso!',
        type: 'success',
        isLoading: false,
        autoClose: 1,
      });
      return response?.data;
    } catch (error) {
      const message = verifyError(
        error,
        'Erro ao carregar empresas disponíveis para o colaborador',
      );
      toast.update(getToast, {
        render: message,
        type: 'error',
        isLoading: false,
        autoClose: 5000,
      });
    }
  };

  const {
    isLoading,
    isSuccess,
    isError,
    isIdle,
    data: companies,
  } = useQuery(
    ['empresasColaborador', companyId, colaboradorId],
    getCollaboratorCompanies,
    {},
  );

  const invalidateQuery = async () => {
    await queryClient.invalidateQueries('empresasColaborador');
  };

  return {
    isLoading,
    isError,
    isIdle,
    isSuccess,
    companies,
    getCollaboratorCompanies,
    queryClient,
  };
};

export default useCollaboratorCompanies;
