export const manualList = [
  {
    name: 'Código de Ética e Conduta',
    link: 'https://bee4-institucional-prd.s3.sa-east-1.amazonaws.com/wp-content/uploads/2023/09/Codigo-de-Conduta-e-Etica_2023-1.pdf',
    type: 'public',
    acess: 'all',
  },
  {
    name: 'Estrutura e Governança do Depto de Autorregulação',
    link: 'https://bee4-institucional-prd.s3.sa-east-1.amazonaws.com/wp-content/uploads/2022/09/Estrutura-e-Governanca-do-Departamento-de-Autorregulacao.pdf',
    type: 'public',
    acess: 'all',
  },
  {
    name: 'Glossário',
    link: 'https://bee4-institucional-prd.s3.sa-east-1.amazonaws.com/wp-content/uploads/2022/09/Glossario.pdf',
    type: 'public',
    acess: 'all',
  },
  {
    name: 'Manual de Acesso',
    link: 'https://bee4-institucional-prd.s3.sa-east-1.amazonaws.com/wp-content/uploads/2022/09/Manual-de-Acesso.pdf',
    type: 'public',
    acess: 'all',
  },
  {
    name: 'Manual de Escrituração e Tokenização',
    link: 'https://docs.google.com/document/d/1ukfWO8-WT20SAz1d8iO9BiDaC02uNYNm/edit',
    type: 'private',
    acess: 'all',
  },
  {
    name: 'Manual de Listagem e Admissão à Negociação',
    link: 'https://bee4-institucional-prd.s3.sa-east-1.amazonaws.com/wp-content/uploads/2022/09/Manual-de-Listagem-e-Admissao-a-Negociacao.pdf',
    type: 'public',
    acess: 'all',
  },
  {
    name: 'Manual de Operações e Liquidação',
    link: 'https://bee4-institucional-prd.s3.sa-east-1.amazonaws.com/wp-content/uploads/2022/10/Manual-de-Operacoes-e-Liquidacao.pdf',
    type: 'public',
    acess: 'all',
  },
  {
    name: 'Manual de Cadastro',
    link: 'https://bee4-institucional-prd.s3.sa-east-1.amazonaws.com/wp-content/uploads/2023/04/Manual-de-Cadastro.docx.pdf',
    type: 'public',
    acess: 'all',
  },
  {
    name: 'Política de Multas e Sanções',
    link: 'https://bee4-institucional-prd.s3.sa-east-1.amazonaws.com/wp-content/uploads/2023/01/Politica-de-Multas-e-Sancoes.pdf',
    type: 'public',
    acess: 'all',
  },
  {
    name: 'Política de Preços',
    link: 'https://docs.google.com/document/d/1eHNTrvNSRHFnN5yuJv5sg3XgXOEsY6g2/edit',
    type: 'private',
    acess: 'all',
  },
  {
    name: 'Política de Privacidade e Proteção de Dados BEE4',
    link: 'https://bee4.com.br/politica-de-privacidade/',
    type: 'public',
    acess: 'all',
  },
  {
    name: 'Política de Promoção de Liquidez',
    link: 'https://docs.google.com/document/d/1QRQ6LIKI4vqvnuRbckHKvo_SuCHZNOTb/edit',
    type: 'private',
    acess: 'Empreendedor',
  },
  {
    name: 'Política de Riscos, Compliance e Controles Internos',
    link: 'https://bee4-institucional-prd.s3.sa-east-1.amazonaws.com/wp-content/uploads/2023/01/Politica-de-Compliance-e-Controles-Internos.pdf',
    type: 'public',
    acess: 'all',
  },
  {
    name: 'Política PLDFT',
    link: 'https://docs.google.com/document/d/1vyWZeFt9K4GEMEr3gxhtINdpGDMnTdUC/edit',
    type: 'private',
    acess: 'all',
  },
  {
    name: 'Regimento Interno do Conselho de Autorregulação',
    link: 'https://bee4-institucional-prd.s3.sa-east-1.amazonaws.com/wp-content/uploads/2023/01/Regimento-Interno-do-Conselho-de-Autorregulacao.pdf',
    type: 'public',
    acess: 'all',
  },
  {
    name: 'Regulamento do MRP',
    link: 'https://bee4-institucional-prd.s3.sa-east-1.amazonaws.com/wp-content/uploads/2022/09/Regulamento-do-MRP.pdf',
    type: 'public',
    acess: 'all',
  },
  {
    name: 'Regulamento Processual da BEE4',
    link: 'https://bee4-institucional-prd.s3.sa-east-1.amazonaws.com/wp-content/uploads/2022/09/Regulamento-Processual.pdf',
    type: 'public',
    acess: 'all',
  },
];
