import React from 'react';
import { useLocation } from 'react-router-dom';
import { Description, FixedTitle } from './styles';
import { routes } from './fields';

export const FixedPageTitle = () => {
  const { pathname } = useLocation();

  return (
    <FixedTitle>
      <div>
        <h2>{routes[pathname]}</h2>

        <Description>
          Documentos formais de regulação e supervisão da BEE4 que detalha
          procedimentos, manuais de autorregulação e funcionamento do Mecanismo
          de Ressarcimento de Prejuízo.
        </Description>
      </div>
    </FixedTitle>
  );
};
