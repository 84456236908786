import React, { useState } from 'react';
import { RadioLable } from '../styles';
import {
  ModalIPOTypes,
  ModalState,
  StatusChangePayload,
  modalInitial,
} from '../fields';
import {
  AddReviewIpoPayload,
  CategoryDocIPO,
} from '../../../pages/documentacaoIPO/fields';
import { availableRoles } from '../../../hooks/auth';
import variablesDocIPO from './variablesDocIPO';
import variablesDocListagem from './variablesDocListagem';

interface IUseListagemProps {
  onStatusChange: (status: any) => void;
  status: number;
  analyzedByCommittee: boolean;
  expanded: boolean;
  setExpanded: (e: boolean) => void;
  setOpenModal: (e: ModalState) => void;
  handleOpenModal: (e: ModalIPOTypes) => void;
  setCloseViewCategory: () => void;
  setCategoryId: (e: string) => void;
  setStatusObject: (e: StatusChangePayload | undefined) => void;
  currentRole: availableRoles;
  type?: 1 | 2;
}

export const variablesDoc = ({
  onStatusChange,
  status,
  analyzedByCommittee,
  expanded,
  setExpanded,
  handleOpenModal,
  setCloseViewCategory,
  setCategoryId,
  setStatusObject,
  setOpenModal,
  currentRole,
  type = 1,
}: IUseListagemProps) => {
  const docIpo = variablesDocIPO({
    onStatusChange,
    status,
    analyzedByCommittee,
    expanded,
    setExpanded,
    handleOpenModal,
    setCloseViewCategory,
    setCategoryId,
    setStatusObject,
    setOpenModal,
    currentRole,
  });

  const docListagem = variablesDocListagem({
    onStatusChange,
    status,
    analyzedByCommittee,
    expanded,
    setExpanded,
    handleOpenModal,
    setCloseViewCategory,
    setCategoryId,
    setStatusObject,
    setOpenModal,
    currentRole,
  });

  const doc = type === 1 ? docIpo : docListagem;

  const {
    hasToFinish,
    isHighlighted,
    handleChange,
    handleOptions,
    handleRadioChange,
    handleCloseModal,
  } = doc;

  return {
    hasToFinish,
    isHighlighted,
    handleChange,
    handleOptions,
    handleRadioChange,
    handleCloseModal,
  };
};

export default variablesDoc;
