import * as React from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Chip from '@mui/material/Chip';

const names = [
  'Oliver Hansen',
  'Van Henry',
  'April Tucker',
  'Ralph Hubbard',
  'Omar Alexander',
  'Carlos Abbott',
  'Miriam Wagner',
  'Bradley Wilkerson',
  'Virginia Andrews',
  'Kelly Snyder',
];

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: '300px',
    },
  },
};

export interface IMultipleSelectOption {
  nome: string;
  id: any;
}
interface IButtonProps {
  onChange: (e: string[]) => void;
  label?: string | React.ReactNode;
  defaultValue?: string[];
  options?: IMultipleSelectOption[];
  disabled?: boolean;
  height?: string;
}

function getStyles(
  name: IMultipleSelectOption,
  personName: readonly IMultipleSelectOption[],
  theme: Theme,
) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export const MultipleSelect: React.FC<IButtonProps> = ({
  label,
  defaultValue,
  options,
  onChange,
  disabled,
  height,
}) => {
  const theme = useTheme();
  const [val, setVal] = React.useState<string[]>([]);
  const handleChange = (event: SelectChangeEvent<typeof val>) => {
    const {
      target: { value },
    } = event;
    setVal(
      // On autofill we get a the stringified value.
      typeof value === 'string' ? value?.split(',') : value,
    );
    typeof value === 'string' ? onChange([value]) : onChange(value);
  };
  function findName(value: string) {
    const selectedValue = options?.filter(val => val.id === value)[0];
    return selectedValue;
  }

  React.useEffect(() => {
    if (defaultValue) {
      setVal(defaultValue);
    } else {
      setVal([]);
    }
  }, [defaultValue]);
  return (
    <div>
      <FormControl sx={{ width: '100%' }}>
        <InputLabel id="demo-multiple-chip-label">{label}</InputLabel>
        <Select
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          multiple
          value={val}
          onChange={handleChange}
          input={<OutlinedInput id="select-multiple-chip" label={label} />}
          renderValue={selected => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map(value => {
                const selectedValue = findName(value);
                if (!selectedValue) {
                  return null;
                }
                return (
                  <Chip key={selectedValue.id} label={selectedValue.nome} />
                );
              })}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {options?.map(option => (
            <MenuItem
              key={option.id}
              value={option.id}
              style={getStyles(option, options, theme)}
            >
              {option.nome}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};
