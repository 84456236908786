import React, { useEffect } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Controller } from '../../../components/controller';
import { Modal } from '../../../components/modal';
import { Grid } from '../../../components/grid';
import { Button } from '../../../components/button';
import { Parceiro, listProfileType, schemaParceiro } from '../fields';
import { Buttons, ModalDescription, ModalTitle } from '../styles';

interface IButtonProps {
  description: boolean;
  closeDropdown: (e?: any) => void;
  createParceiro: (e: any) => void;
  updateParceiro: (e: any) => void;
  parceiro?: Parceiro;
}

export const CreateEditNomadParceirosModal: React.FC<IButtonProps> = ({
  description,
  closeDropdown,
  createParceiro,
  updateParceiro,
  parceiro,
}) => {
  const { handleSubmit, control, reset, getValues } = useForm<Parceiro>({
    resolver: yupResolver(schemaParceiro),
  });

  const submitForm = (data: Parceiro) => {
    if (!data.email) {
      toast.error('Coloque um email para criar o novo colaborador.');
      return;
    }
    if (parceiro) {
      updateParceiro({ parceiro: data, parceiroId: parceiro.id });
    } else {
      createParceiro(data);
    }

    closeDropdown();
  };

  useEffect(() => {
    reset({
      id: parceiro?.id,
      nome: parceiro?.nome,
      cpf: parceiro?.cpf,
      objetivoParaDarAcesso: parceiro?.objetivoParaDarAcesso,
      email: parceiro?.email,
      nomeDaEmpresaQualPertence: parceiro?.nomeDaEmpresaQualPertence,
      celular: parceiro?.celular,
      tipoDoPerfil: parceiro?.tipoDoPerfil,
    });
  }, [reset]);

  return (
    <Modal open={description} handleClose={closeDropdown} width={1200}>
      <form onSubmit={handleSubmit(submitForm)}>
        <ModalTitle>
          {parceiro ? 'Editar' : 'Convidar'} colaborador(a)
        </ModalTitle>

        <Grid container spacing={4}>
          {!parceiro && (
            <Grid item desktop={12}>
              <ModalDescription>
                Ao convidar um(a) colaborador(a), este(a) usuário(a) poderá
                inserir dados e documentos no sistema pela Empresa Emissora,
                porém este(a) não poderá deletar documentos.
              </ModalDescription>
              <br />
              <ModalDescription>
                Caso este(a) colaborador(a) não deva mais acessar o sistema, é
                de sua responsabilidade deletar o(a) usuário(a).
              </ModalDescription>
            </Grid>
          )}

          <Grid item desktop={6}>
            <Controller
              name="nome"
              control={control}
              type="input"
              label="Nome completo"
            />
          </Grid>
          <Grid item desktop={6}>
            <Controller
              name="cpf"
              control={control}
              type="input"
              mask="cpf"
              label="CPF"
              disabled={!!parceiro}
            />
          </Grid>
          <Grid item desktop={6}>
            <Controller
              name="email"
              control={control}
              type="input"
              label="E-mail do Parceiro"
              disabled={!!parceiro}
            />
          </Grid>
          <Grid item desktop={6}>
            <Controller
              name="nomeDaEmpresaQualPertence"
              control={control}
              type="input"
              label="Empresa em que trabalha"
              disabled={!!parceiro}
            />
          </Grid>
          <Grid item desktop={6}>
            <Controller
              name="celular"
              control={control}
              type="input"
              mask="phone"
              label="Telefone"
              disabled={!!parceiro}
            />
          </Grid>
          <Grid item desktop={6}>
            <Controller
              name="tipoDoPerfil"
              control={control}
              type="select"
              label="Tipo do perfil"
              options={listProfileType}
              disabled={!!parceiro}
            />
          </Grid>
          <Grid item desktop={12}>
            <Controller
              name="objetivoParaDarAcesso"
              control={control}
              type="input"
              textarea
              label="Descreva o objetivo do acesso"
              disabled={!!parceiro}
            />
          </Grid>
        </Grid>

        <Buttons>
          <Button
            width="103px"
            height="40px"
            variant="outlined"
            onClick={closeDropdown}
          >
            CANCELAR
          </Button>
          <Button type="submit" width="103px" height="40px">
            SALVAR
          </Button>
        </Buttons>
      </form>
    </Modal>
  );
};
