import React from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { AxiosResponse } from 'axios';
import { api } from '../../../services/api';
import { verifyError } from '../../../utils/verifyError';
import { DadosEmissaoForm } from '../fields';

interface IUseIpoProps {
  currentEnterpriseId?: string;
}

export const useSobreAtivo = ({ currentEnterpriseId }: IUseIpoProps) => {
  const queryClient = useQueryClient();
  const getSobreAtivo = async () => {
    const getToast = toast.loading('Carregando status');

    try {
      const { data }: AxiosResponse<DadosEmissaoForm> = await api.main.get(
        `/v1/empresas/${currentEnterpriseId}/dados-para-emissao`,
      );

      toast.update(getToast, {
        render: 'Dados carregados com sucesso!',
        type: 'success',
        isLoading: false,
        autoClose: 1,
      });

      return data;
    } catch (error) {
      const message = verifyError(
        error,
        'Erro ao carregar os dados do formulário',
      );
      toast.update(getToast, {
        render: message,
        type: 'error',
        isLoading: false,
        autoClose: 5000,
      });
    }
  };

  const {
    isLoading,
    isSuccess,
    isError,
    isIdle,
    data: sobreAtivo,
  } = useQuery(['sobreAtivo', currentEnterpriseId], getSobreAtivo, {
    enabled: !!currentEnterpriseId,
  });

  const invalidateQuery = async () => {
    await queryClient.invalidateQueries('documentacaoIPO');
  };

  const putDados = useMutation(async (payload: any) => {
    const response = await api.main.put(
      `/v1/empresas/${currentEnterpriseId}/dados-para-emissao`,
      payload,
    );
    invalidateQuery();
    return response;
  });

  const updateSobreAtivo = (payload?: any) => {
    putDados.mutate(payload, {
      onSuccess: async res => {
        toast.dismiss();
        if (res?.status === 200 || res?.status === 201) {
          toast.success('Dados atualizados com sucesso!');
        }
      },
      onError: async (err: any) => {
        toast.dismiss();
        const message = verifyError(
          err,
          'Erro ao tentar atualizar o formulário',
        );
        toast.error(message);
      },
    });
  };

  return {
    isLoading,
    isError,
    isIdle,
    isSuccess,
    sobreAtivo,
    getSobreAtivo,
    updateSobreAtivo,
    invalidateQuery,
  };
};

export default useSobreAtivo;
