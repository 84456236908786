import React, { useEffect } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import {
  Input,
  MaskedInputOnChangeCallback,
  masks,
} from '../../../../../../components/input';
import { Modal } from '../../../../../../components/modal';
import { Buttons, ModalTitle } from '../../styles';
import { Button } from '../../../../../../components/button';
import { ResportList } from '../../hooks/useReportDates';
import { isValid } from '../../../../../../services/isValid';

interface FormData {
  empresaListadaNaBee4: string;
}

interface IEditModalProps {
  description: boolean;
  closeDropdown: (e?: any) => void;
  empresa?: ResportList;
  onEdit: (e: FormData) => void;
}

const schema = yup.object().shape({
  empresaListadaNaBee4: yup
    .string()
    .test('validateDate', 'Data inválida', data => {
      return isValid.date(data);
    })
    .nullable(),
});
export const EditReportDateModal: React.FC<IEditModalProps> = ({
  description,
  closeDropdown,
  empresa,
  onEdit,
}) => {
  const { handleSubmit, control } = useForm<any>({
    resolver: yupResolver(schema),
  });

  const submitForm = (nomad: FormData) => {
    onEdit(nomad);
    closeDropdown();
  };

  return (
    <Modal open={description} handleClose={closeDropdown} width={500}>
      <form onSubmit={handleSubmit(submitForm)}>
        <ModalTitle>
          Editar a data da empresa {empresa?.nomeFantasia}
        </ModalTitle>
        <div style={{ marginBottom: '1.5rem' }}>
          <Controller
            name="empresaListadaNaBee4"
            control={control}
            render={({
              field: { onChange, ...field },
              fieldState: { invalid, error },
              formState,
            }) => (
              <Input
                label="Data"
                mask={masks.date}
                onChange={(v: MaskedInputOnChangeCallback) => onChange(v)}
                error={invalid}
                errorMessage={error?.message}
                {...field}
              />
            )}
          />
        </div>
        <Buttons>
          <Button
            width="103px"
            height="40px"
            variant="outlined"
            onClick={closeDropdown}
          >
            CANCELAR
          </Button>
          <Button type="submit" width="103px" height="40px">
            SALVAR
          </Button>
        </Buttons>
      </form>
    </Modal>
  );
};
