import styled from 'styled-components';

export const DeleteButton = styled.button`
  position: absolute;
  border-radius: 50%;
  border: none;
  width: 25px;
  height: 25px;
  top: 3px;
  right: -5px;
  display: none;
`;
export const Container = styled.div`
  position: relative;
  padding-top: 5px;
  &:hover ${DeleteButton} {
    display: block;

    img {
      height: 15px;
      width: 15px;
    }
  }
`;
