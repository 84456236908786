import React, { useState } from 'react';
import { RadioLable } from '../styles';
import {
  ModalIPOTypes,
  ModalState,
  StatusChangePayload,
  modalInitial,
} from '../fields';
import {
  AddReviewIpoPayload,
  CategoryDocIPO,
} from '../../../pages/documentacaoIPO/fields';
import { availableRoles } from '../../../hooks/auth';

interface IUseListagemProps {
  onStatusChange: (status: any) => void;
  status: number;
  analyzedByCommittee: boolean;
  expanded: boolean;
  setExpanded: (e: boolean) => void;
  setOpenModal: (e: ModalState) => void;
  handleOpenModal: (e: ModalIPOTypes) => void;
  setCloseViewCategory: () => void;
  setCategoryId: (e: string) => void;
  setStatusObject: (e: StatusChangePayload | undefined) => void;
  currentRole: availableRoles;
}

export const variablesDocListagem = ({
  onStatusChange,
  status,
  analyzedByCommittee,
  expanded,
  setExpanded,
  handleOpenModal,
  setCloseViewCategory,
  setCategoryId,
  setStatusObject,
  setOpenModal,
  currentRole,
}: IUseListagemProps) => {
  const isDisabled =
    !['NOMADBOSS', 'BEE4'].includes(currentRole) || status === 5;

  const disabledForCL = currentRole === 'NOMADBOSS' && status !== 3;

  const disabledForBEE4 = currentRole === 'BEE4' && status !== 4;

  const hasToFinish = (category: CategoryDocIPO) =>
    (analyzedByCommittee &&
      status === 3 &&
      [5, 7].includes(category?.statusDaCategoria ?? 0)) ||
    disabledForCL ||
    disabledForBEE4 ||
    isDisabled ||
    category.statusDaCategoriaReadOnly;

  const isHighlighted = (cat: CategoryDocIPO) => {
    const bee4Highlight =
      [1, 4].includes(cat?.statusDaCategoria ?? 0) &&
      analyzedByCommittee &&
      status === 4 &&
      currentRole === 'BEE4';

    const clHighlight =
      [6, 8].includes(cat?.statusDaCategoria ?? 0) &&
      analyzedByCommittee &&
      status === 3 &&
      currentRole !== 'BEE4';

    if (bee4Highlight || clHighlight) return true;
    return false;
  };

  const handleChange = () => {
    setExpanded(!expanded);
  };

  const handleCloseModal = () => {
    setOpenModal(modalInitial);
    setCategoryId('');
    setCloseViewCategory();
    setStatusObject(undefined);
  };

  const handleRadioChange = (value: number, cat: CategoryDocIPO) => {
    setStatusObject({
      statusDaCategoria: value,
      categoryId: cat?.documentoCategoriaId,
    });

    if ([1, 7].includes(value)) {
      handleOpenModal('verifyDocModal');
    } else if ([2, 8].includes(value)) {
      handleOpenModal('revisionModal');
    } else if (value === 3) {
      handleOpenModal('finishDocModal');
    } else if (value === 4) {
      handleOpenModal('prazoModal');
    } else if (value === 6) {
      handleOpenModal('prazoNegadoModal');
    } else {
      onStatusChange({
        statusDaCategoria: value,
        categoryId: cat?.documentoCategoriaId,
      });
    }
  };

  // determina o radio button de cada categorai de documento
  const handleOptions = (item: CategoryDocIPO) => {
    const { statusDaCategoria } = item;

    // Caso do cl resp finalizar a validação dos doc
    const clFinishDoc = currentRole !== 'BEE4' && status !== 5;

    // Caso da BEE4 finalizar a validação dos doc
    const bee4FinishDoc =
      (currentRole === 'BEE4' && status >= 3) ||
      (currentRole === 'BEE4' &&
        [7, 8].includes(statusDaCategoria ?? 0) &&
        status >= 3);

    if (bee4FinishDoc) {
      return [
        {
          value: 8,
          label: <RadioLable>Solicitar revisão</RadioLable>,
        },
        {
          value: 7,
          label: <RadioLable>Validar</RadioLable>,
        },
      ];
    }

    if (clFinishDoc) {
      return [
        {
          value: 2,
          label: <RadioLable>Solicitar revisão</RadioLable>,
        },
        {
          value: 1,
          label: <RadioLable>Validar</RadioLable>,
        },
      ];
    }

    return [
      {
        value: 2,
        label: <RadioLable>Solicitar revisão</RadioLable>,
      },
      {
        value: 1,
        label: <RadioLable>Validar</RadioLable>,
      },
    ];
  };

  return {
    hasToFinish,
    isHighlighted,
    handleChange,
    handleOptions,
    handleRadioChange,
    handleCloseModal,
  };
};

export default variablesDocListagem;
