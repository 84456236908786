import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useAuth } from '../../hooks/auth';

export function Sair() {
  const { logOut } = useAuth();

  useEffect(() => {
    logOut();
  }, [logOut]);

  return (
    <>
      <Helmet>
        <title>Saindo da sua conta - sistema beegin</title>
      </Helmet>
      <h1>Saindo da sua conta...</h1>
    </>
  );
}
