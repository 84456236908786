import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '../../../../../../components/button';
import {
  DocumentForm,
  StageCategoryType,
  automatedDocList,
  categoryDocumentSchema,
  listDiligenciaType,
  listTipoEmpresa,
} from '../../fields';
import { Grid } from '../../../../../../components/grid';
import { Modal } from '../../../../../../components/modal';
import { Controller } from '../../../../../../components/controller';

interface IButtonProps {
  description: boolean;
  closeDropdown: (e?: any) => void;
  stage: StageCategoryType;
  createCategory: (e?: any) => void;
  document?: DocumentForm;
  categoryId?: string;
  updateCategory: (e?: any) => void;
}

export const CreateEditCategoryModal: React.FC<IButtonProps> = ({
  description,
  closeDropdown,
  stage,
  createCategory,
  document,
  categoryId,
  updateCategory,
}) => {
  const { handleSubmit, control, reset, watch } = useForm<DocumentForm>({
    resolver: yupResolver(categoryDocumentSchema(stage)),
  });

  const { comporNoDossie, gerarDocumentoAutomaticamente } = watch();

  const handleEtapaLabel = () => {
    if (stage === 'financeiro') {
      return [1, 'Documentação adicional'];
    }
    if (stage === 'juridico') {
      return [2, 'Jurídico'];
    }
    if (stage === 'diligencia') {
      return [3, 'Diligência'];
    }
    if (stage === 'empresa') {
      return [4, 'Dados gerais'];
    }
    if (stage === 'emissao') {
      return [5, 'Sobre o ativo'];
    }
    if (stage === 'docIpo') {
      return [6, 'Documentação IPO'];
    }
    if (stage === 'relatorio') {
      return [7, 'Relatório'];
    }
    if (stage === 'DocumentacaoListagem') {
      return [9, 'Documentação Listagem'];
    }
    return [0, ''];
  };

  const onClose = () => {
    closeDropdown();
  };
  const handleSaveDocForm = (form: DocumentForm) => {
    // criar uma categoria nova
    if (!categoryId) {
      createCategory({
        form: {
          ...form,
          tema:
            form.comporNoDossie || stage === 'DocumentacaoListagem'
              ? form?.tema
              : null,
          outputAutomatico: form.gerarDocumentoAutomaticamente
            ? form?.outputAutomatico
            : null,
          periodoDoRelatorio:
            form?.tipoDoRelatorio === 2 ? form?.periodoDoRelatorio : null,
          etapa: handleEtapaLabel()[0],
        },
        stage,
      });
    } else {
      // editar uma categoria
      updateCategory({
        form: {
          ...form,
          tema:
            form.comporNoDossie ||
            stage === 'docIpo' ||
            stage === 'DocumentacaoListagem'
              ? form?.tema
              : null,
          periodoDoRelatorio:
            form?.tipoDoRelatorio === 2 ? form?.periodoDoRelatorio : null,
          arquivoTemplate: form?.arquivoTemplate ?? {},
        },
        stage,
        id: categoryId,
      });
    }
    onClose();
  };

  useEffect(() => {
    reset({
      nome: document?.nome,
      tipoDeEmpresa: document?.tipoDeEmpresa,
      informacao: document?.informacao,
      descricao: document?.descricao,
      segmentoDaDiligencia: document?.segmentoDaDiligencia,
      obrigatorio: document?.obrigatorio,
      comporNaAtualizacaoCadastral: document?.comporNaAtualizacaoCadastral,
      comporNoDossie: document?.comporNoDossie,
      gerarDocumentoAutomaticamente: document?.gerarDocumentoAutomaticamente,
      outputAutomatico: document?.outputAutomatico,
      comporNoDossieTodosOsDocumentos:
        document?.comporNoDossieTodosOsDocumentos,
      tipoDoRelatorio: document?.tipoDoRelatorio,
      periodoDoRelatorio: document?.periodoDoRelatorio,
      arquivoTemplate: document?.arquivoTemplate,
      tema: document?.tema,
    });
  }, [reset]);

  return (
    <>
      <Modal open={description} handleClose={closeDropdown} width={1000}>
        <form onSubmit={handleSubmit(handleSaveDocForm)}>
          <Grid container spacing={2}>
            <Grid item desktop={12}>
              <h3>
                {categoryId ? 'Editar' : 'Novo'} Item - {handleEtapaLabel()[1]}
              </h3>
            </Grid>
            <Grid item desktop={12} />
            <Grid item desktop={9}>
              <Controller
                name="nome"
                control={control}
                type="input"
                label="Nome"
              />
            </Grid>
            <Grid item desktop={3}>
              <Controller
                name="tipoDeEmpresa"
                control={control}
                type="select"
                options={listTipoEmpresa}
                label="Tipo de Empresa"
              />
            </Grid>

            <Grid item desktop={12}>
              <Controller
                name="informacao"
                control={control}
                type="input"
                textarea
                label="Informações (aparecerá em um ícone)"
                maxLength={500}
              />
            </Grid>
            <Grid item desktop={12}>
              <Controller
                name="descricao"
                control={control}
                type="input"
                textarea
                label="Descrição"
                maxLength={500}
              />
            </Grid>
            {stage === 'diligencia' && (
              <Grid item desktop={12}>
                <Controller
                  name="segmentoDaDiligencia"
                  control={control}
                  type="select"
                  options={listDiligenciaType}
                  label="Tipo"
                />
              </Grid>
            )}
            <Grid item desktop={3}>
              <Controller
                name="obrigatorio"
                control={control}
                type="checkbox"
                label="Item obrigatório"
              />

              {((stage !== 'docIpo' &&
                stage !== 'relatorio' &&
                stage !== 'DocumentacaoListagem') ||
                document?.comporNoDossie) && (
                <Controller
                  name="comporNoDossie"
                  control={control}
                  type="checkbox"
                  label="Compor no doc. IPO"
                />
              )}

              {(comporNoDossie ||
                ['docIpo', 'DocumentacaoListagem'].includes(stage ?? '')) && (
                <>
                  <Controller
                    name="tema"
                    control={control}
                    type="radio"
                    options={[
                      { value: 1, label: 'Financeiro' },
                      { value: 2, label: 'Jurídico' },
                      { value: 3, label: 'Material Institucional' },
                    ]}
                  />
                  <Controller
                    name="comporNoDossieTodosOsDocumentos"
                    control={control}
                    type="checkbox"
                    label="Buscar todas as versões"
                  />
                </>
              )}
            </Grid>

            <Grid item desktop={4}>
              {stage !== 'relatorio' && (
                <Controller
                  name="comporNaAtualizacaoCadastral"
                  control={control}
                  type="checkbox"
                  label="Compor na atualização cadastral"
                />
              )}
              {stage !== 'relatorio' && (
                <>
                  <Controller
                    name="gerarDocumentoAutomaticamente"
                    control={control}
                    type="checkbox"
                    label="Gerar documento automaticamente"
                  />
                  {gerarDocumentoAutomaticamente && (
                    <Controller
                      name="outputAutomatico"
                      control={control}
                      type="select"
                      options={automatedDocList}
                    />
                  )}
                </>
              )}
            </Grid>
            <Grid item desktop={1} />
            <Grid item desktop={4}>
              <Controller
                name="arquivoTemplate"
                control={control}
                type="uploadButton"
                label="TEMPLATE"
                acceptedFiles={[
                  '.jpg',
                  '.png',
                  '.pdf',
                  '.xls',
                  '.xlsx',
                  '.csv',
                  '.doc',
                  '.docx',
                ]}
              />
            </Grid>
            {stage === 'relatorio' ? (
              <>
                <Grid item desktop={6}>
                  <Controller
                    name="tipoDoRelatorio"
                    control={control}
                    type="select"
                    options={[
                      { label: 'Relatórios', value: 2 },
                      { label: 'Relatório anual da auditoria', value: 3 },
                    ]}
                    label="Tipo do relatório"
                  />
                </Grid>
                <Grid item desktop={6}>
                  <Controller
                    name="periodoDoRelatorio"
                    control={control}
                    type="select"
                    options={[
                      { label: '1/4', value: 1 },
                      { label: '2/4', value: 2 },
                      { label: '3/4', value: 3 },
                      { label: '4/4', value: 4 },
                    ]}
                    label="Periodo do relatório"
                  />
                </Grid>
              </>
            ) : null}

            <Grid item desktop={12}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  gap: '2rem',
                }}
              >
                <Button
                  width="103px"
                  height="40px"
                  variant="outlined"
                  onClick={onClose}
                >
                  CANCELAR
                </Button>

                <Button width="103px" height="40px" type="submit">
                  SALVAR
                </Button>
              </div>
            </Grid>
          </Grid>
        </form>
      </Modal>
    </>
  );
};
