export interface ImageProps {
  id: string;
  url: string;
  nome: string;
  imagemId: string;
}

export interface FileProps {
  nome: string;
  type: string;
  url?: string;
  upload?: boolean;
  base64Content?: any;
}

export interface RelacaoFatosRelevantes {
  razaoSocial: string;
  fatoRelevanteId: string;
  armazenar: boolean;
  post: string;
  empresaId: string;
  criadoEm: string;
  documentos: FileProps[];
  imagens: ImageProps[];
  fatoRelevanteVinculadoEmOutroCanal: boolean;
  qualCanalFoiVinculado: string;
  isReadOnly: boolean;
}

export interface IFatosRelevantes {
  relacaoFatoRelevantes: RelacaoFatosRelevantes[];
}

export interface PostProps {
  company: { name: string; id: string };
  fatoRelevante: RelacaoFatosRelevantes;
}

export const iconMap: any = {
  pdf: 'images/pdf.svg',
  xls: 'images/sheets.svg',
  xlsx: 'images/sheets.svg',
  docx: 'images/docx.svg',
  ppt: 'images/ppt.svg',
};

export const pattern = [
  {
    rows: 2,
    cols: 1,
  },
  {
    rows: 1,
    cols: 1,
  },
  {
    rows: 1,
    cols: 1,
  },
  {
    rows: 1,
    cols: 1,
  },
  {
    rows: 2,
    cols: 1,
  },
  {
    rows: 1,
    cols: 1,
  },
];

export function srcset(image: string, size: number, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${
      size * rows
    }&fit=crop&auto=format&dpr=2 2x`,
  };
}
