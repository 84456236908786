import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import MaterialSelect, { SelectChangeEvent } from '@mui/material/Select';
import { Tooltip } from '../tooltip';
import { TooltipDiv } from '../../pages/dadosGerais/styles';
import { LoadingEmptySection } from './styles';
import { Loader } from '../loader';

export interface IOption {
  label?: string | React.ReactNode;
  value: any;
}

interface ISelectProps {
  onChange: (e?: any) => void;
  label?: string | React.ReactNode;
  name?: string;
  value?: any;
  options: IOption[] | [];
  disabled?: boolean;
  height?: string;
  width?: string;
  defaultValue?: any;
  error?: boolean;
  errorMessage?: string;
  isLoading?: boolean;
  disableChange?: boolean;
  onSelectValue?: (e: any) => void;
}

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: '300px',
    },
  },
};

export const Select: React.FC<ISelectProps> = ({
  label,
  name,
  value,
  options = [],
  onChange,
  disabled,
  height,
  width,
  defaultValue,
  error,
  errorMessage,
  isLoading,
  disableChange = false,
  onSelectValue,
}) => {
  const [val, setVal] = React.useState(value || '');
  const [hasEllipsis, setHasEllipsis] = React.useState<boolean>(false);

  const handleChange = (event: SelectChangeEvent) => {
    if (!disableChange) {
      setVal(event.target.value);
    }
    onChange(event.target.value);
    onSelectValue && onSelectValue(event.target.value);
  };

  React.useEffect(() => {
    setVal(value || '');
  }, [value]);

  const labelHasEllipsis = () => {
    const labelObj = document.getElementById(`${name}Label`);
    if (labelObj) {
      if (labelObj.offsetWidth < labelObj.scrollWidth) {
        return true;
      }
      return false;
    }
    return true;
  };

  React.useEffect(() => {
    window.addEventListener('resize', () => setHasEllipsis(labelHasEllipsis()));

    return () => {
      window.removeEventListener('resize', () =>
        setHasEllipsis(labelHasEllipsis()),
      );
    };
  }, []);

  React.useEffect(() => {
    setHasEllipsis(labelHasEllipsis());
  }, [document.getElementById(`${name}Label`)]);

  window.addEventListener('resize', () => setHasEllipsis(labelHasEllipsis()));

  return (
    <>
      <FormControl sx={{ width: width ?? '100%', height }} error={error}>
        {label ? (
          <InputLabel
            shrink
            style={{ color: disabled ? '#bdbdbd' : '#424242' }}
            id={`${name}Label`}
          >
            {label}
          </InputLabel>
        ) : null}
        <MaterialSelect
          defaultValue={defaultValue}
          id={name}
          labelId="lableID"
          value={val}
          label={label}
          onChange={handleChange}
          disabled={disabled}
          MenuProps={MenuProps}
          style={{ height, width }}
          input={<OutlinedInput notched={!!label} label={label} />}
        >
          {isLoading ? (
            <LoadingEmptySection>
              <Loader />
            </LoadingEmptySection>
          ) : options.length === 0 ? (
            <LoadingEmptySection>Sem itens</LoadingEmptySection>
          ) : (
            options.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.label ? option.label : option.value}
              </MenuItem>
            ))
          )}
        </MaterialSelect>
        <FormHelperText>{errorMessage}</FormHelperText>
      </FormControl>
      {label && (
        <Tooltip title={label} disabled={!hasEllipsis} placement="top">
          <TooltipDiv />
        </Tooltip>
      )}
    </>
  );
};
