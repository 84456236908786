import React, { useEffect, useState } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import axios, { AxiosResponse } from 'axios';
import { api } from '../../../services/api';
import { verifyError } from '../../../utils/verifyError';

interface IUseAtualizacaoCadastralHooks {
  currentEnterpriseId: string;
  idHistorico: string;
  atualizado: boolean;
}

interface HistoricoData {
  dataDaInclusao: string;
  id: string;
}

export const useAtualizacaoCadastral = ({
  currentEnterpriseId,
  idHistorico,
  atualizado,
}: IUseAtualizacaoCadastralHooks) => {
  const queryClient = useQueryClient();
  const getAtualizacaoCadastral = async () => {
    const getToast = toast.loading('Carregando dados da atualização cadastral');

    try {
      const { data }: AxiosResponse<any> = await api.main.get(
        `/v1/empresas/${currentEnterpriseId}/historico-item/${
          atualizado ? 'atualizado' : idHistorico || ''
        }`,
      );

      toast.update(getToast, {
        render: 'Status carregado com sucesso!',
        type: 'success',
        isLoading: false,
        autoClose: 1,
      });

      return data;
    } catch (error) {
      const message = verifyError(
        error,
        'Erro ao carregar os dados da atualização cadastral',
      );
      toast.update(getToast, {
        render: message,
        type: 'error',
        isLoading: false,
        autoClose: 5000,
      });
    }
  };

  const getHistoricoAtualizacao = async () => {
    const getToast = toast.loading('Carregando histórico de atualizações');
    try {
      const response: AxiosResponse<HistoricoData[]> = await api.main.get(
        `/v1/empresas/${currentEnterpriseId}/historico-data`,
      );
      toast.update(getToast, {
        render: 'Histórico carregado com sucesso!',
        type: 'success',
        isLoading: false,
        autoClose: 1,
      });

      return response?.data;
    } catch (error) {
      const message = verifyError(
        error,
        'Erro ao carregar o histórico de atualizações',
      );
      toast.update(getToast, {
        render: message,
        type: 'error',
        isLoading: false,
        autoClose: 5000,
      });
    }
  };

  const {
    isLoading: loading1,
    isSuccess: isSuccess1,
    isError: isError1,
    isIdle: isIdle1,
    data: historicoAtualizacao,
  } = useQuery(['historicoAtualizacao'], getHistoricoAtualizacao, {
    enabled: !!currentEnterpriseId,
  });

  const {
    isLoading: loading2,
    isSuccess: isSuccess2,
    isError: isError2,
    isIdle: isIdle2,
    data: atualizacaoCadastral,
    refetch,
  } = useQuery(
    ['atualizacaoCadastral', idHistorico, atualizado],
    getAtualizacaoCadastral,
    {
      enabled: !!currentEnterpriseId,
    },
  );

  const invalidateQuery = async () => {
    await queryClient.invalidateQueries('historicoAtualizacao');
    await queryClient.invalidateQueries('atualizacaoCadastral');
  };

  const updateDados = useMutation(async (payload: any) => {
    toast.loading('Atualizando dados');

    const response = await api.main.post(
      `/v1/empresas/${payload.currentEnterpriseId}/historico`,
      payload.form,
    );

    invalidateQuery();

    return response;
  });

  const submitDados = (payload: any) => {
    updateDados.mutate(payload, {
      onSuccess: async res => {
        toast.dismiss();
        if (res?.status === 200 || res?.status === 201) {
          toast.success('Dados atualizados com sucesso!');
          refetch();
        }
      },
      onError: async (err: any) => {
        toast.dismiss();
        const message = verifyError(err, 'Erro ao criar um novo histórico');
        toast.error(message);
      },
    });
  };

  const sendToBee4 = async () => {
    const getToast = toast.loading('Enviando para a BEE4');
    try {
      const response: AxiosResponse<any> = await api.main.post(
        `/v1/empresas/${currentEnterpriseId}/atualizacao-cadastral-envio-bee4`,
      );
      toast.update(getToast, {
        render: 'Enviado para a BEE4 com sucesso!',
        type: 'success',
        isLoading: false,
        autoClose: 1,
      });
    } catch (error) {
      const message = verifyError(error, 'Erro ao enviar para a BEE4');
      toast.update(getToast, {
        render: message,
        type: 'error',
        isLoading: false,
        autoClose: 5000,
      });
    }
  };

  return {
    loading1,
    isError1,
    isIdle1,
    isSuccess1,
    loading2,
    isError2,
    isIdle2,
    isSuccess2,
    atualizacaoCadastral,
    historicoAtualizacao,
    getAtualizacaoCadastral,
    getHistoricoAtualizacao,
    submitDados,
    sendToBee4,
    queryClient,
  };
};

export default useAtualizacaoCadastral;
