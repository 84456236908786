import styled from 'styled-components';

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  gap: 22px;
  p {
    font-size: 16px;
    line-height: 22px;
  }
  margin-bottom: 48px;
`;

export const FormSection = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 2rem;

  button {
    margin: 0 auto;
  }
`;

export const FileContainer = styled.div`
  background-color: white;
  border-radius: 4px;
  box-shadow: 0px 1px 4px #15223214;
  padding: 30px 50px;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

export const ButtonDiv = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
`;

export const DocumentsDiv = styled.div`
  margin-top: 2rem;
`;

export const FileRow = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 24px;
  padding-right: 12px;
  background-color: #c3c6cf4d;
  border-radius: 4px;

  div {
    display: flex;
    align-items: center;
  }

  span {
    margin-left: 16px;
    font-size: 12px;
    font-weight: bold;
  }

  img {
    height: 20px;
  }

  button {
    border: none;
    border-radius: 8px;
    height: 40px;
    width: 40px;
    background-color: none;
    padding: 9px;

    img {
      height: 20px;
    }

    &:hover {
      background-color: #cbcbcb;
    }
  }
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 2rem auto 0 auto;
  width: 216px;
`;
