import React, { useEffect, useState } from 'react';
import { useAuth } from '../../../../../hooks/auth';
import Accordion from '../../../../accordion';
import { Button } from '../../../../button';
import { Tooltip } from '../../../../tooltip';
import { Buttons, Content, Title, Subtitle, TooltipDiv } from './styles';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IButtonProps {
  children: any;
  title?: string;
  description?: string;
  mandatory?: boolean;
  readOnly?: boolean;
  onOpenModal?: (e?: any) => void;
  dossieCantUpload?: boolean;
}

// eslint-disable-next-line no-empty-pattern
export const AccordionDocuments = ({
  children,
  title,
  description,
  mandatory,
  readOnly,
  onOpenModal,
  dossieCantUpload,
}: IButtonProps) => {
  const { currentRole } = useAuth();
  const [currentAccordionExpanded, setCurrentAccordionExpanded] =
    useState<boolean>(false);
  const [rotateStateAccordion, setRotateStateAccordion] =
    useState('accordion__icon');

  const setOpenAccordion = () => {
    // @ts-ignore
    setCurrentAccordionExpanded(!currentAccordionExpanded);

    setRotateStateAccordion(
      currentAccordionExpanded ? 'accordion__icon' : 'accordion__icon rotate',
    );
  };

  const toggleAddDropdown = (event: any) => {
    event.stopPropagation();
    if (!readOnly) {
      onOpenModal ? onOpenModal(event) : null;
    }
  };

  return (
    <>
      <Accordion
        id="accordion-docs"
        summaryClasses="accordion__header"
        variantstyle="nomad"
        summary={
          <Content>
            <Title>{title}</Title>
            {description && (
              <div className="accordion-info">
                <Tooltip title={description}>
                  <img
                    src="images/System/18.svg"
                    alt=""
                    style={{ height: '16px' }}
                  />
                </Tooltip>
              </div>
            )}
            {!mandatory && <Subtitle>(Opcional)</Subtitle>}
            <img
              src="images/arrow-down.svg"
              className={rotateStateAccordion}
              alt=""
            />

            {dossieCantUpload ? null : readOnly ? (
              <Buttons>
                <Button onClick={toggleAddDropdown} disabled={readOnly}>
                  <img
                    className="uploadIcon"
                    src="images/upload-azul.svg"
                    alt="upload"
                  />
                  UPLOAD
                </Button>
                {currentRole === 'TECHINTERNO' ? (
                  <TooltipDiv />
                ) : (
                  <Tooltip
                    title="Esta etapa já foi validada. Entre em contato com o seu
                    consultor de listagem e peça para alterar o status para
                    Revisão, caso precise realizar alguma alteração."
                  >
                    <TooltipDiv />
                  </Tooltip>
                )}
              </Buttons>
            ) : (
              <Buttons>
                <Button
                  type="button"
                  onClick={toggleAddDropdown}
                  disabled={readOnly}
                  variant="outlined"
                >
                  <img
                    className="uploadIcon"
                    src="images/upload-azul.svg"
                    alt="upload"
                  />
                  UPLOAD
                </Button>
              </Buttons>
            )}
          </Content>
        }
        handleExpanded={setOpenAccordion}
        value={currentAccordionExpanded}
      >
        {children}
      </Accordion>
    </>
  );
};
