import styled from 'styled-components';

interface RadioConteiner {
  disabled: boolean;
}

export const RadioContainer = styled.div<RadioConteiner>`
  display: flex;
  gap: 0.4rem;
  align-items: center;
  p {
    color: ${props => (props.disabled ? '#A4A7B4' : '#131b30')};
    font-size: 14px;
  }
  span {
    color: ${props => (props.disabled ? '#A4A7B4' : '#131b30')};
  }
`;
