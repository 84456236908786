import styled from 'styled-components';

export const TableLine = styled.hr`
  width: 95%;
  margin: auto;
  background-color: #f6f6f6;
  opacity: 0.6;
`;

export const TableRow = styled.div`
  margin: 2rem;
`;

export const TableContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #fff;
  border-radius: 8px;
  margin-bottom: 4rem;
`;

export const OptionLink = styled.a`
  text-decoration: none;
  font-size: 1rem;
  font-weight: 600;
  color: #212e51;
`;
