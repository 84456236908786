import * as yup from 'yup';
import { isValid } from '../../services/isValid';

export const schemaColaboradorNomad = yup.object().shape({
  email: yup.string().required('Campo obrigatório'),
  nome: yup.string().required('Campo obrigatório'),
  cpf: yup
    .string()
    .test('validadeCPF', 'CPF inválido', cpf => {
      return cpf ? isValid.cpf(cpf) : true;
    })
    .required('Campo obrigatório'),
  celular: yup.string().required('Campo obrigatório'),
  empresas: yup.array().of(
    yup.object().shape({
      empresaId: yup.string().nullable(),
    }),
  ),
});
