import React from 'react';
import { Button } from '../../../components/button';
import { Modal } from '../../../components/modal';
import { DeleteButtons, ModalContentForm } from '../styles';

interface IButtonProps {
  description: boolean;
  closeDropdown: (e?: any) => void;
  consultorId?: string;
  nomadId?: string;
  reenviarConvite: (e: any) => void;
}
export const InviteCollaboratorModal: React.FC<IButtonProps> = ({
  description,
  closeDropdown,
  consultorId,
  nomadId,
  reenviarConvite,
}) => {
  const handleInvite = () => {
    reenviarConvite({ nomadId, consultorId });
    closeDropdown();
  };

  return (
    <Modal open={description} handleClose={closeDropdown} width={500}>
      <ModalContentForm>
        <h3>Essa ação reenviará o convite para o colaborador</h3>

        <div className="descriptionDeleteModal">
          <span>Tem certeza que deseja continuar com essa ação?</span>
        </div>

        <DeleteButtons>
          <Button
            type="button"
            variant="outlined"
            width="96px"
            onClick={closeDropdown}
          >
            CANCELAR
          </Button>
          <Button
            type="button"
            width="96px"
            variant="outlined"
            onClick={handleInvite}
          >
            REENVIAR
          </Button>
        </DeleteButtons>
      </ModalContentForm>
    </Modal>
  );
};
