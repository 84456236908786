import React from 'react';
import WarningIcon from '@mui/icons-material/Warning';
import { Button } from '../../../components/button';
import { Modal } from '../../../components/modal';
import { MainButtons, ModalContentForm } from '../styles';
import { Usuario } from '../fields';

interface IButtonProps {
  description: boolean;
  closeDropdown: (e?: any) => void;
  user?: Usuario;
  updateUser: (e: any) => void;
}
export const UpdateModal: React.FC<IButtonProps> = ({
  description,
  closeDropdown,
  user,
  updateUser,
}) => {
  const handleInvite = () => {
    updateUser(user);
    closeDropdown();
  };

  return (
    <Modal open={description} handleClose={closeDropdown} width={500}>
      <ModalContentForm>
        <h3>Confirmar {user?.ativo ? 'desativação' : 'ativação'} de usuário</h3>

        <div className="descriptionModal">
          <div>
            <WarningIcon style={{ fontSize: 70 }} />
          </div>
          <span>
            Tem certeza que deseja {user?.ativo ? 'desabilitar' : 'habilitar'} o
            usuário {user?.nome}?
          </span>
          {user?.ativo ? (
            <span>O usuário não poderá mais acessar o sistema.</span>
          ) : (
            <span>
              O usuário poderá acessar o sistema de acordo com as permissões do
              seu perfil.
            </span>
          )}
          <span>Esta ação poderá ser revertida a qualquer momento.</span>
        </div>

        <MainButtons>
          <Button
            type="button"
            variant="text"
            width="96px"
            onClick={closeDropdown}
          >
            CANCELAR
          </Button>
          <Button
            type="button"
            width="160px"
            variant="contained"
            onClick={handleInvite}
          >
            {user?.ativo ? 'DESATIVAR' : 'ATIVAR'} usuário
          </Button>
        </MainButtons>
      </ModalContentForm>
    </Modal>
  );
};
