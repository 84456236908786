import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Carregando from '../../components/carregando';
import { useAuth } from '../../hooks/auth';
import { useEnterprise } from '../../hooks/enterprise';

export const Index = () => {
  const navigate = useNavigate();
  const { currentRole, userId } = useAuth();
  const { updateEnterprisesAsync, usuarioNaoPossuiEmpresas } = useEnterprise();

  async function init() {
    await updateEnterprisesAsync();

    if (!currentRole) {
      navigate('/sem-conta');
    } else if (usuarioNaoPossuiEmpresas) {
      navigate('/sem-empresas');
    } else if (userId) {
      navigate('/dashboard-listagem');
    }
  }

  useEffect(() => {
    init();
  }, [currentRole, userId, navigate, usuarioNaoPossuiEmpresas]);

  return <Carregando />;
};
