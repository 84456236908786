import styled, { css } from 'styled-components';

import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

export type StyledAccordion = {
  variantstyle: 'base' | 'nomad' | 'default';
  className?: string;
} & AccordionProps;

export const AccordionBaseStyle = css`
  background-color: #fff;
  color: #444;
  cursor: pointer;
  border: none;
  outline: none;
  transition: background-color 0.6s ease;
  border-radius: 8px;

  .accordion__base {
    display: flex;
    &-content {
      display: flex;
      width: 2.5rem;
      justify-content: space-between;
      padding-left: 0.2rem;
    }
  }

  .accordion__header {
    /* padding: 1rem; */
    width: 100%;
  }

  .accordion__title {
    font-weight: 600;
    font-size: 16px;
    color: #171725;
  }

  .accordion__icon {
    margin-left: 18px;
    transition: transform 0.6s ease;
    transform: rotate(0deg);
  }
  .rotate {
    transform: rotate(180deg);
  }
  .MuiButtonBase-root {
    width: 100%;
  }
`;

export const AccordionNomadStyle = css`
  /* background-color: #e7ebee; */
  color: #171725;
  cursor: pointer;
  border: none;
  outline: none;
  transition: background-color 0.6s ease;
  border-radius: 8px;

  .accordion__base {
    display: flex;
    padding: 0.7rem 2rem;
    align-items: center;
    width: 100%;
  }

  .accordion__title {
    font-weight: 600;
    font-size: 16px;
    color: #171725;
  }

  .accordion-info {
    margin-left: 0.8rem;
  }

  .accordion__icon {
    margin-left: 0.8rem;
    transition: transform 0.6s ease;
    transform: rotate(0deg);
  }
  .rotate {
    transform: rotate(180deg);
  }

  .end_buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .MuiButton-root {
    line-height: inherit;
  }

  .MuiButtonBase-root {
    width: 100%;
  }
`;

export const Accordion = styled(MuiAccordion)<StyledAccordion>`
  box-shadow: 0 0 0 0;
  background: transparent;

  ${({ variantstyle }) => variantstyle === 'base' && AccordionBaseStyle};
  ${({ variantstyle }) => variantstyle === 'nomad' && AccordionNomadStyle};

  > .Mui-expanded {
    min-height: auto;
  }
  &:not(:last-child) {
    border-bottom: 0;
  }
  &:before {
    display: none;
  }
  .MuiAccordion-region {
    background-color: #fff;
  }
`;

export const AccordionSummary = styled(MuiAccordionSummary)`
  flex-direction: 'row-reverse';
  padding: 0;
  height: 48px;
  .Mui-expanded {
    margin: 12px 0;
  }
  .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
    transform: 'rotate(90deg)';
  }
  .MuiAccordionSummary-content {
    margin: 0;
  }
`;

export const AccordionDetails = styled(MuiAccordionDetails)`
  padding: 0;
`;
