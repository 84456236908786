import styled from 'styled-components';

export const ModalContent = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: column;
  position: relative;
`;

export const ModalCheckboxContainer = styled.div`
  display: flex;
  gap: 1.5rem;
  flex-direction: column;
  align-items: start;
  margin-top: 2rem;
`;

export const ModalButtons = styled.div`
  width: 100%;
  margin-top: 2rem;
`;

export const DescriptionCheckbox = styled.span`
  color: #101219;
  font-size: 0.875rem;
  font-family: Georama;
`;

export const Flex = styled.section`
  display: flex;
`;
