export const formatCPFeCNPJ = (value: string | number): string => {
  if (!value) return value?.toString();

  const newValue = value.toString().replace(/[^0-9]/g, '');
  return newValue.length === 14
    ? newValue.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
    : newValue.length === 11
    ? newValue.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
    : value.toString();
};
