import React from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { AxiosResponse } from 'axios';
import { api } from '../../../../../services/api';
import { verifyError } from '../../../../../utils/verifyError';

export const useCompanyLink = ({ generateLink }: { generateLink: boolean }) => {
  const queryClient = useQueryClient();

  const getInviteLink = async () => {
    const getToast = toast.loading('Carregando o link de convite');
    try {
      const response: AxiosResponse<any> = await api.main.get(
        `/v1/empreendedor/gerar-url`,
      );
      toast.update(getToast, {
        render: 'Link carregado com sucesso!',
        type: 'success',
        isLoading: false,
        autoClose: 1,
      });
      return response?.data;
    } catch (error) {
      const message = verifyError(error, 'Erro ao carregar link de convite');
      toast.update(getToast, {
        render: message,
        type: 'error',
        isLoading: false,
        autoClose: 5000,
      });
    }
  };

  const {
    isLoading,
    isSuccess,
    isError,
    isIdle,
    data: linkInvite,
  } = useQuery(['linkInvite'], getInviteLink, {
    enabled: generateLink,
  });

  const invalidateQuery = async () => {
    await queryClient.invalidateQueries('linkInvite');
  };

  return {
    isLoading,
    isError,
    isIdle,
    isSuccess,
    linkInvite,
    getInviteLink,
    queryClient,
    invalidateQuery,
  };
};

export default useCompanyLink;
