import moment from 'moment';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

// Importe o plugin de texto relativo do Day.js
dayjs.extend(relativeTime);

export const timestampToDate = (timestamp?: string | null) => {
  if (timestamp) {
    return `${timestamp?.slice(8, 10)}${timestamp?.slice(
      5,
      7,
    )}${timestamp?.slice(0, 4)}`;
  }
  return null;
};

export const dateToTimestamp = (date?: string | null) => {
  if (date) {
    const dateString = date?.toString();

    const result = moment(
      `${dateString?.slice(2, 4)}-${dateString?.slice(
        0,
        2,
      )}-${dateString?.slice(4, 9)}`,
    ).format();

    return result;
  }
  return null;
};

// converte o objeto date retornado pelo banco de dados para dd/mm/yyyy
export const getDate = (timestamp?: string | null) => {
  if (timestamp) {
    return `${timestamp?.slice(8, 10)}/${timestamp?.slice(
      5,
      7,
    )}/${timestamp?.slice(0, 4)}, ${timestamp?.slice(11, 16)}`;
  }

  return null;
};

export const monthEnum: {
  [key: number]: string;
} = {
  1: 'janeiro',
  2: 'fevereiro',
  3: 'março',
  4: 'abril',
  5: 'maio',
  6: 'junho',
  7: 'julho',
  8: 'agosto',
  9: 'setembro',
  10: 'outubro',
  11: 'novembro',
  12: 'dezembro',
};

// retorna ano da data
export const dateYear = (date?: string | null) => {
  const dateString = dateToTimestamp(date);
  if (date) {
    return parseInt(moment(dateString).format('YYYY'));
  }
  return null;
};

// retorna a data dd/mm/yyyy ou hh horas atras(caso seja hoje)
export function handleDateOrHour(date?: string | null) {
  if (date === null) {
    return '';
  }
  const dataAtual = dayjs();

  const data = dayjs(date, 'DD/MM/YYYY HH:mm');

  // Verifique se a data fornecida é hoje
  if (data.isSame(dataAtual, 'day')) {
    // Calcule a diferença em horas
    const horasAtras = Math.abs(data.diff(dataAtual, 'hour'));
    const s = horasAtras > 1 ? 's' : '';

    return `${horasAtras} hora${s} atrás`;
  }

  return data.format('DD/MM/YYYY');
}
