import styled from 'styled-components';

export const TermoContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`;

export const ErrorContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
`;

export const ObjectPDF = styled.object`
  width: 100%;
  height: 100%;
`;
