import styled from 'styled-components';

interface ContentProps {
  status?: number;
}

export const Content = styled.div`
  display: flex;
  align-items: center;
  background-color: #c3c6cf33;
  color: #171725;
  cursor: pointer;
  border: none;
  outline: none;
  transition: background-color 0.6s ease;
  height: 48px;
  padding: 0 16px;
  width: 100%;

  img {
    height: 6px;
    width: 10px;
  }
`;

export const Title = styled.h3`
  font-weight: 700;
  font-size: 11px;
`;

export const Buttons = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 1rem;

  .deleteEditButton {
    border: none;
    background: none;
  }

  .deleteEditButton:hover {
    background: #cbcbcb 0% 0% no-repeat padding-box !important;
    opacity: 1;
    border-radius: 8px;
  }

  .plus {
    font-size: 1.3rem;
    margin-right: 0.4rem;
  }
`;

export const Status = styled.div<ContentProps>`
  background-color: ${props => (props.status !== 1 ? '#A4A7B4' : '#0852DD')};
  border-radius: 28px;
  padding: 5px 10px;
  color: white;
  min-width: 118px;
  text-align: center;
`;
