import React from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { AxiosResponse } from 'axios';
import { api } from '../../../../../services/api';
import { verifyError } from '../../../../../utils/verifyError';
import { CategoryProps } from '../fields';

export const useCategoriaDocIPO = ({ arquivado }: CategoryProps) => {
  const queryClient = useQueryClient();
  const getCategoriaDocIpo = async () => {
    try {
      const { data }: AxiosResponse<any> = await api.main.get(
        `/v1/categorias-de-documento${
          arquivado ? '/armazenado' : ''
        }?etapa=dossie`,
      );

      return data;
    } catch (error) {
      const message = verifyError(
        error,
        'Erro ao carregar os dados da categoria doc. IPO',
      );
      toast.error(message);
    }
  };

  const {
    isLoading: loadingDocIpo,
    isSuccess: isDocIpoSuccess,
    isError,
    isIdle,
    data: docIpo,
  } = useQuery(['docIpo', arquivado], getCategoriaDocIpo, {});

  const invalidateQuery = async () => {
    await queryClient.invalidateQueries('docIpo');
  };

  const postDados = useMutation(async (payload: any) => {
    toast.loading('Criando categoria');
    const { form } = payload;

    const response = await api.main.post('/v1/categorias-de-documento', form);

    invalidateQuery();

    return response;
  });

  const createCategoriaDocIpo = (payload: any) => {
    postDados.mutate(payload, {
      onSuccess: async res => {
        toast.dismiss();
        if (res?.status === 200 || res?.status === 201) {
          toast.success('Categoria criada com sucesso!');
        }
      },
      onError: async (err: any) => {
        toast.dismiss();
        const message = verifyError(err, 'Erro ao criar uma nova categoria');
        toast.error(message);
      },
    });
  };

  const putDados = useMutation(async (payload: any) => {
    toast.loading('Atualizando categoria');
    const { form, id } = payload;

    const response = await api.main.put(
      `/v1/categorias-de-documento/${id}`,
      form,
    );

    invalidateQuery();

    return response;
  });

  const updateCategoriaDocIpo = (payload: any) => {
    putDados.mutate(payload, {
      onSuccess: async res => {
        toast.dismiss();
        if (res?.status === 200 || res?.status === 201) {
          toast.success('Categoria atualizada com sucesso!');
        }
      },
      onError: async (err: any) => {
        toast.dismiss();
        const message = verifyError(err, 'Erro ao atualizar a categoria');
        toast.error(message);
      },
    });
  };

  const deleteDados = useMutation(async (payload: any) => {
    toast.loading('Arquivando categoria');
    const { id } = payload;

    if (arquivado) {
      const response = await api.main.put(
        `/v1/categorias-de-documento/${id}/desarquivar`,
      );

      invalidateQuery();

      return response;
    }

    const response = await api.main.delete(`/v1/categorias-de-documento/${id}`);

    invalidateQuery();

    return response;
  });

  const archiveCategoriaDocIpo = (payload: any) => {
    deleteDados.mutate(payload, {
      onSuccess: async res => {
        toast.dismiss();
        if (res?.status === 200 || res?.status === 201) {
          toast.success('Categoria arquivada com sucesso!');
        }
      },
      onError: async (err: any) => {
        toast.dismiss();
        const message = verifyError(err, 'Erro ao arquivar a categoria');
        toast.error(message);
      },
    });
  };

  return {
    loadingDocIpo,
    isDocIpoSuccess,
    isError,
    isIdle,
    docIpo,
    createCategoriaDocIpo,
    updateCategoriaDocIpo,
    archiveCategoriaDocIpo,
    queryClient,
  };
};

export default useCategoriaDocIPO;
