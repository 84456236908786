import React from 'react';
import MaterialGrid from '@mui/material/Grid';

type GridSize = 'auto' | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

interface IGridProps {
  children?: React.ReactNode;
  mobile?: GridSize;
  desktop?: GridSize;
  container?: boolean;
  item?: boolean;
  spacing?: number;
  rowSpacing?: number;
  columnSpacing?: number;
  style?: Record<string, unknown>;
}

export function Grid({
  children,
  mobile,
  desktop,
  container,
  item,
  spacing,
  rowSpacing,
  columnSpacing,
  style,
}: IGridProps) {
  return (
    <MaterialGrid
      container={container}
      item={item}
      xs={mobile}
      md={desktop}
      spacing={spacing}
      rowSpacing={rowSpacing}
      columnSpacing={columnSpacing}
      style={style}
    >
      {children}
    </MaterialGrid>
  );
}
