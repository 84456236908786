/* eslint-disable react/no-unescaped-entities */
import React, { useState, useEffect } from 'react';
import { useAuth } from '../../hooks/auth';
import {
  Main,
  ContainerWhite,
  DocumentTitle,
  ContainerHeader,
  DocumentEmptyTile,
} from './styles';
import { MenuTabs } from '../../components/tabs';
import { DocumentList } from '../../components/documents';
import { Select } from '../../components/select';
import useReport from './hooks/useReport';
import { useEnterprise } from '../../hooks/enterprise';

export const Relatorios = () => {
  const { currentUserName } = useAuth();
  const [reportDate, setReportDate] = useState(1);
  const [date, setDate] = useState();
  const { currentEnterpriseId } = useEnterprise();

  const { relatorioDatas } = useReport({ currentEnterpriseId });

  const dateList = relatorioDatas?.anos?.map((data: number, index: number) => {
    return { value: index + 1, label: data };
  });

  useEffect(() => {
    !!dateList &&
      dateList.length > 0 &&
      setDate(dateList[reportDate - 1].label);
  }, [reportDate, dateList]);

  const handleYear = () => {
    const obj: any = dateList.find((date: any) => date.value === reportDate);
    if (obj) return obj.label;
    return '';
  };

  const handleLabel2 = (
    <div>
      <DocumentTitle>Por trimestre, deve ser enviado:</DocumentTitle>
      <DocumentList
        stage="relatorio"
        reportMenu={2}
        reportOrder={1}
        year={date}
      />
      <DocumentList
        stage="relatorio"
        reportMenu={2}
        reportOrder={2}
        year={date}
      />
      <DocumentList
        stage="relatorio"
        reportMenu={2}
        reportOrder={3}
        year={date}
      />
      <DocumentList
        stage="relatorio"
        reportMenu={2}
        reportOrder={4}
        year={date}
      />
    </div>
  );

  const handleLabel3 = (
    <div>
      <DocumentTitle>
        Enviar anualmente ao final do ano. Deve conter Parecer da Auditoria,
        DRE, BP, FCX auditados.
      </DocumentTitle>
      <DocumentList stage="relatorio" reportMenu={3} year={date} />
    </div>
  );

  const container = () => {
    return [
      { label: 'RELATÓRIOS', children: handleLabel2 },
      {
        label: 'RELATÓRIO ANUAL DA AUDITORIA',
        children: handleLabel3,
      },
    ];
  };

  return (
    <Main>
      <h2>Olá {currentUserName}</h2>
      <p>
        Aqui você deverá emitir alguns relatórios específicos em uma determinada
        periodicidade ao longo do ano.
      </p>
      <ContainerWhite>
        {relatorioDatas ? (
          <>
            {' '}
            <ContainerHeader>
              <h2>Relatórios de {handleYear()}</h2>
              <div style={{ width: '150px' }}>
                <Select
                  options={dateList}
                  value={reportDate}
                  height="35px"
                  width="150px"
                  onChange={(e: any) => {
                    setReportDate(e);
                  }}
                />
              </div>
            </ContainerHeader>
            <MenuTabs containers={container()} />{' '}
          </>
        ) : (
          <>
            <DocumentEmptyTile>
              Essa empresa ainda não tem uma data de relatório definida
            </DocumentEmptyTile>
          </>
        )}
      </ContainerWhite>
    </Main>
  );
};
