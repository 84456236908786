import React from 'react';
import { Button } from '../../../../../../components/button';
import { Modal } from '../../../../../../components/modal';
import { DeleteButtons, ModalContent } from './styles';

interface IButtonProps {
  description: boolean;
  closeDropdown: (e?: any) => void;
  invite: (e?: any) => void;
}
export const ResendInviteConsultorListagemModal: React.FC<IButtonProps> = ({
  description,
  closeDropdown,
  invite,
}) => {
  const handleDelete = () => {
    invite();
    closeDropdown();
  };

  return (
    <Modal open={description} handleClose={closeDropdown} width={500}>
      <ModalContent>
        <h3>Essa ação reenviará o convite para o consultor de listagem.</h3>
        <span>Tem certeza que deseja continuar com essa ação?</span>
        <DeleteButtons>
          <Button
            type="button"
            variant="outlined"
            width="96px"
            onClick={closeDropdown}
          >
            CANCELAR
          </Button>
          <Button
            type="button"
            width="96px"
            variant="outlined"
            onClick={() => {
              handleDelete();
            }}
          >
            REENVIAR
          </Button>
        </DeleteButtons>
      </ModalContent>
    </Modal>
  );
};
