import styled from 'styled-components';

export const FormContent = styled.form`
  padding-bottom: 55px;
`;

export const Container = styled.div`
  background-color: #fff;
  border-radius: 8px;
  padding: 40px 54px 35px;
`;

export const InnerTitle = styled.span`
  display: flex;
  margin: 63px 0 33px 0;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.54px;
  color: #51576c;
`;

export const InnerTitle2 = styled(InnerTitle)`
  margin: 63px 0 0 0;
`;

export const InnerTitle3 = styled(InnerTitle)`
  margin: 0;
  font-size: 14px;
`;

export const Margin = styled.div`
  height: 70px;
`;
export const ShareholdersDiv = styled.div`
  display: flex;
  margin: 30px 0;
`;

export const DeleteButtons = styled.div`
  padding-top: 62px;
  display: flex;
  flex-direction: column;
  width: 5%;
  gap: 25px;
`;
export const DeleteButton = styled.button`
  background: none;
  position: relative;
  left: 10px;
  border: none;
  height: 55px;
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;
  .save,
  .cancel {
    border-radius: 8px;
    padding: 0.5rem 0rem;
    text-align: center;
    width: 9rem;
    border: none;
    font-size: 16px;
    font-weight: bold;
  }
  .cancel {
    background-color: #bdbdbd;
    color: #424242;
    margin-right: 1rem;
  }
  .save {
    background-color: #f78157;
    color: #ffffff;
  }
`;

export const RadioContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  .form-control {
    margin-right: 1rem;
  }
`;

export const RadioLabel = styled.label`
  color: #131b30;
`;

export const RadioInput = styled.input`
  margin-right: 0.3rem;
`;

export const TooltipDiv = styled.div`
  position: relative;
  width: 100%;
  height: 23%;
  top: -62px;
`;

export const TooltipDivTextArea = styled.div`
  position: relative;
  width: 100%;
  height: 8%;
  top: -198px;
  z-index: 2;
`;

export const RadioTitle = styled.p`
  font-size: 14px;
  color: #171725;
`;

export const RadioDescription = styled.p`
  font-size: 12px;
  color: #454b62;
`;

export const RadioLabel2 = styled.div`
  font-size: 14px;
  color: #51576c;
  margin-bottom: 2rem;
`;

export const DividedGrid = styled.div`
  display: flex;
  gap: 5px;
  width: 100%;

  & > * {
    flex: 1;
  }
`;
