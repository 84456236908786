import React from 'react';
import { TableLine, TableContent, TableRow, OptionLink } from './styles';

export const DireitosUsuario = () => {
  return (
    <>
      <>
        <TableContent>
          <TableRow>
            <OptionLink
              href="https://bee4.com.br/direito-dos-titulares/"
              target="_blank"
              rel="noreferrer"
            >
              Cancelamento de conta
            </OptionLink>
          </TableRow>
          <TableLine />
          <TableRow>
            <OptionLink
              href="https://bee4.com.br/direito-dos-titulares/"
              target="_blank"
              rel="noreferrer"
            >
              Revogação de consentimento
            </OptionLink>
          </TableRow>
          <TableLine />
          <TableRow>
            <OptionLink
              href="https://bee4.com.br/direito-dos-titulares/"
              target="_blank"
              rel="noreferrer"
            >
              Consulta dos dados
            </OptionLink>
          </TableRow>
          <TableLine />
          <TableRow>
            <OptionLink
              href="https://bee4.com.br/direito-dos-titulares/"
              target="_blank"
              rel="noreferrer"
            >
              Atualização dos dados
            </OptionLink>
          </TableRow>
          <TableLine />
          <TableRow>
            <OptionLink
              href="https://bee4.com.br/direito-dos-titulares/"
              target="_blank"
              rel="noreferrer"
            >
              Eliminar conta ou limpar os dados
            </OptionLink>
          </TableRow>
        </TableContent>
      </>
    </>
  );
};
