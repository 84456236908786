import styled from 'styled-components';

interface ContentProps {
  top?: string;
}
interface TitleProps {
  fontSize?: string;
}

export const Content = styled.div<ContentProps>`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: ${props => props.top || '40%'};
  width: 99%;
  color: #171725;
  font-size: 1.6rem;
`;
export const HeaderLogo = styled.img`
  position: absolute;
  top: -50px;
  left: 1%;
  width: 200px;
`;

export const Title = styled.h1<TitleProps>`
  margin-bottom: 1rem;
  margin-top: 0px;
  position: relative;
  z-index: 10;
  font-size: ${props => props.fontSize || '2.2rem'};
`;
