import React from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { AxiosResponse } from 'axios';
import { api } from '../../../../../services/api';
import { verifyError } from '../../../../../utils/verifyError';

export const useAuditor = ({ arquivado }: { arquivado?: boolean }) => {
  const queryClient = useQueryClient();

  const getAuditor = async () => {
    const getToast = toast.loading('Carregando auditores');
    try {
      const response: AxiosResponse<any> = await api.main.get(
        `/v1/auditoria${arquivado ? '/armazenado' : ''}`,
      );

      toast.update(getToast, {
        render: 'Auditores carregados com sucesso!',
        type: 'success',
        isLoading: false,
        autoClose: 1,
      });
      return response?.data;
    } catch (error) {
      const message = verifyError(error, 'Erro ao carregar auditores');
      toast.update(getToast, {
        render: message,
        type: 'error',
        isLoading: false,
        autoClose: 5000,
      });
    }
  };

  const {
    isLoading,
    isSuccess,
    isError,
    isIdle,
    data: auditores,
  } = useQuery(['auditor'], getAuditor, {});

  const invalidateQuery = async () => {
    await queryClient.invalidateQueries('auditor');
  };

  const createDados = useMutation(async (payload: any) => {
    toast.loading('Criando empresa auditora');

    const response = await api.main.post(`/v1/auditoria`, payload);

    invalidateQuery();

    return response;
  });

  const createAuditor = (payload: any) => {
    createDados.mutate(payload, {
      onSuccess: async res => {
        toast.dismiss();
        if (res?.status === 200 || res?.status === 201) {
          toast.success('Auditor registrado com sucesso!');
        }
      },
      onError: async (err: any) => {
        toast.dismiss();
        const message = verifyError(err, 'Erro ao registrar um novo auditor');
        toast.error(message);
      },
    });
  };

  const updateDados = useMutation(async (payload: any) => {
    toast.loading('Atualizando auditor');
    const { id, company } = payload;

    const response = await api.main.put(`/v1/auditoria/${id}`, company);

    invalidateQuery();

    return response;
  });

  const updateAuditor = (payload: any) => {
    updateDados.mutate(payload, {
      onSuccess: async res => {
        toast.dismiss();
        if (res?.status === 200 || res?.status === 201) {
          toast.success('Auditor atualizado com sucesso!');
        }
      },
      onError: async (err: any) => {
        toast.dismiss();
        const message = verifyError(err, 'Erro ao atualizar o auditor');
        toast.error(message);
      },
    });
  };

  // const deleteDados = useMutation(async (payload: any) => {
  //   toast.loading('Excluindo o auditor');

  //   const response = await api.main.delete(`/v1/auditoria/${payload}`);

  //   invalidateQuery();

  //   return response;
  // });

  // const deleteAuditor = (payload: any) => {
  //   deleteDados.mutate(payload, {
  //     onSuccess: async res => {
  //       toast.dismiss();
  //       if (res?.status === 200 || res?.status === 201) {
  //         toast.success('Auditor(a) excluído(a) com sucesso!');
  //       }
  //     },
  //     onError: async (err: any) => {
  //       toast.dismiss();
  //       const message = verifyError(err, 'Erro ao excluir o auditor');
  //       toast.error(message);
  //     },
  //   });
  // };

  const createCollaborator = useMutation(async (payload: any) => {
    toast.loading('Criando colaborador para uma empresa auditora');
    const { id, consulter } = payload;

    const response = await api.main.post(
      `/v1/auditoria/${id}/consultores`,
      consulter,
    );

    invalidateQuery();

    return response;
  });

  const createAuditorCollaborator = (payload: any) => {
    createCollaborator.mutate(payload, {
      onSuccess: async res => {
        toast.dismiss();
        if (res?.status === 200 || res?.status === 201) {
          toast.success('Colaborador registrado com sucesso!');
        }
      },
      onError: async (err: any) => {
        toast.dismiss();
        const message = verifyError(
          err,
          'Erro ao registrar um novo colaborador para uma empresa auditora',
        );
        toast.error(message);
      },
    });
  };

  const updateCollaborator = useMutation(async (payload: any) => {
    toast.loading(
      'Criando colaborador para uma empresa consultora de listagem',
    );
    const { id, consulter, consultorId } = payload;

    const response = await api.main.put(
      `/v1/auditoria/${id}/consultores/${consultorId}`,
      consulter,
    );

    invalidateQuery();

    return response;
  });

  const updateAuditoriaCollaborator = (payload: any) => {
    updateCollaborator.mutate(payload, {
      onSuccess: async res => {
        toast.dismiss();
        if (res?.status === 200 || res?.status === 201) {
          toast.success('Colaborador atualizado com sucesso!');
        }
      },
      onError: async (err: any) => {
        toast.dismiss();
        const message = verifyError(
          err,
          'Erro ao atualizar o colaborador para uma empresa de auditoria',
        );
        toast.error(message);
      },
    });
  };

  const deleteCollaborator = useMutation(async (payload: any) => {
    toast.loading('Excluindo colaborador de empresa audidora');

    const { companyId, consulterId } = payload;

    const response = await api.main.delete(
      `/v1/auditoria/${companyId}/consultores/${consulterId}`,
    );

    invalidateQuery();

    return response;
  });

  const deleteAuditorCollaborator = (payload: any) => {
    deleteCollaborator.mutate(payload, {
      onSuccess: async res => {
        toast.dismiss();
        if (res?.status === 200 || res?.status === 201) {
          toast.success('Colaborador(a) excluído(a) com sucesso!');
        }
      },
      onError: async (err: any) => {
        toast.dismiss();
        const message = verifyError(
          err,
          'Erro ao excluir o colaborador de empresa auditora',
        );
        toast.error(message);
      },
    });
  };

  const deleteDados = useMutation(async (payload: any) => {
    const { id } = payload;

    if (arquivado) {
      toast.loading('Desarquivando auditor');
      const response = await api.main.put(`/v1/auditoria/${id}/desarquivar`);

      invalidateQuery();

      return response;
    }

    toast.loading('Arquivando auditor');
    const response = await api.main.delete(`/v1/auditoria/${id}`);

    invalidateQuery();

    return response;
  });

  const archiveAuditor = (payload: any) => {
    deleteDados.mutate(payload, {
      onSuccess: async res => {
        toast.dismiss();
        if (res?.status === 200 || res?.status === 201) {
          toast.success(
            `Auditor ${!arquivado ? 'arquivado' : 'desarquivado'} com sucesso!`,
          );
        }
      },
      onError: async (err: any) => {
        toast.dismiss();
        const message = verifyError(err, 'Erro ao arquivar o auditor');
        toast.error(message);
      },
    });
  };

  return {
    isLoading,
    isError,
    isIdle,
    isSuccess,
    auditores,
    getAuditor,
    createAuditor,
    updateAuditor,
    // deleteAuditor,
    createAuditorCollaborator,
    updateAuditoriaCollaborator,
    deleteAuditorCollaborator,

    archiveAuditor,
    queryClient,
  };
};

export default useAuditor;
