import React from 'react';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../../hooks/auth';
import { Description, Title } from './styles';
import { FixedPageTitle } from './fixedTitle';
import { Routes } from './fields';

export const PageTitle = () => {
  const { pathname } = useLocation();
  const { currentRole, currentUserName } = useAuth();

  const routes: Routes = {
    '/direitos-do-usuario': 'Direitos do usuário',
    '/relacao-com-investidores': 'Relação com investidores',
    '/administracao-global': 'Administração global',
    '/gestao-usuarios': 'Gestão de usuários',
    '/processo-de-listagem': 'Processo de listagem na BEE4',
    '/dashboard-listagem': `Olá ${currentUserName ?? ''},`,
    '/dados-gerais': 'Dados gerais',
    '/documentacao-adicional': 'Documentação adicional',
    '/juridico': 'Jurídico',
    '/diligencia': 'Diligência',
    '/sobre-o-ativo': 'Sobre o ativo',
    '/dados-da-oferta': 'Dados da oferta',
    '/relatorios-pos-listagem': 'Relatórios',
    '/atualizacoes-cadastrais': 'Atualizações cadastrais',
    '/convidar-usuario': 'Convidar usuário',
    '/fatos-relevantes': 'Fatos relevantes',
    '/emissores': 'Colaboradores',
    '/manuais-bee4': 'Manuais da BEE4',
    '/documentacao-ipo': `Olá ${currentUserName ?? ''},`,
    '/documentacao-listagem': `Olá ${currentUserName ?? ''},`,
  };

  if (pathname === '/manuais-bee4') {
    return <FixedPageTitle />;
  }
  return (
    <Title
      isDashboardListagemEmpreeendedor={
        pathname === '/dashboard-listagem' && currentRole == 'Empreendedor'
      }
    >
      <div>
        <h2>{routes[pathname]}</h2>
        {pathname === '/dados-gerais' && (
          <Description>
            Preencha com atenção o formulário de cadastro de Empresa Emissora
          </Description>
        )}
        {pathname === '/documentacao-adicional' && (
          <Description>
            Preencha com atenção as informações adicionais da Empresa Emissora
          </Description>
        )}
        {(pathname === '/dashboard-listagem' ||
          pathname === '/documentacao-ipo' ||
          pathname === '/documentacao-listagem') && (
          <Description>
            Nesta tela você poderá acompanhar o status do processo de listagem
            da sua empresa.
          </Description>
        )}
      </div>
    </Title>
  );
};
