export const diligenciaIdentifier = (value: any) => {
  switch (value) {
    case 1:
      return 'Societário';
    case 2:
      return 'Tributário';
    case 3:
      return 'Contencioso';
    case 4:
      return 'Certidões';
    case 5:
      return 'Contratos';
    case 6:
      return 'Propriedade Intelectual';
    case 7:
      return 'Patrimônio';
    case 8:
      return 'Trabalhista';
    case 9:
      return 'Estrutura de TI';
    case 10:
      return 'Em relação aos sócios';
    default:
      return 'Lista de documentos';
  }
};

export const reportIdentifier = (value: any) => {
  switch (value) {
    case 1:
      return 'Relatórios trimestrais (1/4)';
    case 2:
      return 'Relatórios trimestrais (2/4)';
    case 3:
      return 'Relatórios trimestrais (3/4)';
    case 4:
      return 'Relatórios trimestrais (4/4)';
    default:
      return '';
  }
};
