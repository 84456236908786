import React, { useEffect, useState } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import axios, { AxiosResponse } from 'axios';
import { verifyError } from '../../../../../utils/verifyError';
import { api } from '../../../../../services/api';

export interface FormDataReport {
  empresa: string;
  empresaListadaNaBee4: string;
}

export interface ResportList {
  nomeFantasia: string;
  id: string;
  empresaListadaNaBee4: string;
}

export const useReportDate = () => {
  const queryClient = useQueryClient();
  const getReportDates = async () => {
    const getToast = toast.loading('Carregando dados das datas do relatório');
    try {
      const { data }: AxiosResponse<ResportList[]> = await api.main.get(
        `/v1/empresas/lista-empresas-listada-bee4`,
      );

      toast.update(getToast, {
        render: 'Dados carregados com sucesso!',
        type: 'success',
        isLoading: false,
        autoClose: 1,
      });
      return data;
    } catch (error) {
      const message = verifyError(
        error,
        'Erro ao carregar os dados das datas do relatório',
      );
      toast.update(getToast, {
        render: message,
        type: 'error',
        isLoading: false,
        autoClose: 5000,
      });
    }
  };

  const {
    isLoading,
    isSuccess,
    isError,
    isIdle,
    data: relatorioDatas,
  } = useQuery(['relatorioDatas'], getReportDates, {});

  const invalidateQuery = async () => {
    await queryClient.invalidateQueries('relatorioDatas');
  };

  const updateDados = useMutation(async (payload: FormDataReport) => {
    const response = await api.main.put(
      `/v1/empresas/${payload.empresa}/data-listada-bee4`,
      { empresaListadaNaBee4: payload.empresaListadaNaBee4 },
    );

    invalidateQuery();

    return response;
  });

  const submitDados = (payload: any) => {
    const getToast = toast.loading('Atualizando datas do relatório');
    updateDados.mutate(payload, {
      onSuccess: async (res: any) => {
        toast.update(getToast, {
          render: 'Datas atualizadas com sucesso!',
          type: 'success',
          isLoading: false,
          autoClose: 5000,
        });
      },
      onError: async (err: any) => {
        const message = verifyError(err, 'Erro ao atualizar os dados');
        toast.update(getToast, {
          render: message,
          type: 'error',
          isLoading: false,
          autoClose: 5000,
        });
      },
    });
  };

  return {
    isLoading,
    isError,
    isIdle,
    isSuccess,
    relatorioDatas,
    getReportDates,
    submitDados,
    queryClient,
  };
};

export default useReportDate;
