import React, { useLayoutEffect, useState } from 'react';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import styled from 'styled-components';

import {
  Section,
  FirstSection,
  CheckMainButton,
  AccordionContentDetails,
  CategoryDescription,
  EditArchiveButtonContainer,
  EditArchiveButton,
} from '../../styles';
import { Container } from '../../../../components/container';
import { OutletPermify } from '../../../../components/outletPermify';
import { Roles } from '../../../../router/roles.routes';
import useEscriturador from './hooks/escriturador';
import useAuditor from './hooks/auditoria';
import { EmpresaEscolhida } from './fields';
import { AccordionCategorySection } from '../categories/components/accordionCategorySection';
import { ArchiveDocumentModal } from '../categories/components/archiveModal';

export const ArchiveData = ({
  leaveArchiveItems,
  type,
}: {
  leaveArchiveItems: () => void;
  type: 'escriturador' | 'auditor';
}) => {
  const ArchiveIconButton = styled(UnarchiveIcon)`
    font-size: 20px;
  `;

  const ArrowIcon = styled(ArrowBackOutlinedIcon)`
    font-size: 16px;
  `;

  const [archiveModal, setArchiveModal] = useState(false);

  const {
    escrituradores,
    archiveEscriturador,
    isLoading: loadingEscriturador,
    isSuccess: isEscrituradorSuccess,
  } = useEscriturador({
    arquivado: true,
  });

  const {
    auditores,
    archiveAuditor,
    isLoading: loadingAuditor,
    isSuccess: isAuditorSuccess,
  } = useAuditor({
    arquivado: true,
  });

  useLayoutEffect(() => {
    (document.querySelector('#main') as any).scrollTo(0, 0);
  }, []);

  const loading = loadingAuditor || loadingEscriturador;
  const isSuccess = isAuditorSuccess || isEscrituradorSuccess;

  const handleArchiveCategory = (payload: any) => {
    if (type === 'auditor') {
      return archiveAuditor(payload);
    }

    if (type === 'escriturador') {
      return archiveEscriturador(payload);
    }

    return () => {};
  };

  function collaboratorList(companyList?: EmpresaEscolhida[]) {
    return companyList?.map(company => {
      return (
        <React.Fragment key={company.id}>
          <AccordionContentDetails>
            <CategoryDescription>
              {type === 'escriturador'
                ? company.nomeDaEmpresa
                : company.razaoSocial}
            </CategoryDescription>
            <EditArchiveButtonContainer>
              <OutletPermify role={[Roles.EditarCategoria]}>
                <EditArchiveButton
                  onClick={() => {
                    setArchiveModal(true);
                    handleArchiveCategory(company);
                  }}
                >
                  <ArchiveIconButton htmlColor="#212e51" />
                </EditArchiveButton>
              </OutletPermify>
            </EditArchiveButtonContainer>
          </AccordionContentDetails>
          {/* {final === index ? null : <TableLine />} */}
        </React.Fragment>
      );
    });
  }

  return (
    <>
      <CheckMainButton
        type="button"
        onClick={() => {
          leaveArchiveItems();
        }}
      >
        <ArrowIcon htmlColor="#2dbcf7" />
        Voltar
      </CheckMainButton>
      <Container title="Novos Campos" titleMarginBottom="7px">
        <FirstSection>
          <span>
            A Administração global adiciona itens a todas as empresas na qual o
            Tech Interno é cadastrado.
          </span>
        </FirstSection>

        {type === 'auditor' && (
          <Section>
            <AccordionCategorySection
              loading={loading}
              name="Auditor"
              description=""
            >
              {collaboratorList(auditores) as any}
            </AccordionCategorySection>
          </Section>
        )}

        {type === 'escriturador' && (
          <Section>
            <AccordionCategorySection
              loading={loading}
              name="Escriturador"
              description=""
            >
              {collaboratorList(escrituradores) as any}
            </AccordionCategorySection>
          </Section>
        )}

        <ArchiveDocumentModal
          description={archiveModal && isSuccess && !loading}
          label="Campo desarquivado com sucesso"
          closeDropdown={() => {
            setArchiveModal(false);
          }}
          text="Agora ele está disponível para edição na 'Administração global'"
        />

        <Section />
      </Container>
    </>
  );
};
