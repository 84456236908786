import React from 'react';
import { Container, FileIcon, FileName, Download } from './styles';
import { FileProps, iconMap } from '../../fields';
import { Button } from '../../../../components/button';

export const File = ({
  nome,
  type,
  url,
  base64Content,
  upload = false,
}: FileProps) => {
  const handleDownload = async () => {
    if (base64Content) {
      const link = `data:application/octet-stream;base64,${base64Content}`;

      const btn = document.createElement('a');
      btn.href = link;
      btn.download = nome;

      btn.click();
      btn.remove();
    }
  };
  return (
    <Container>
      <FileIcon src={iconMap[type]} alt="" />
      <FileName>{nome}</FileName>
      {!upload && (
        <Download>
          <Button
            onClick={() => {
              handleDownload();
            }}
            variant="text"
          >
            <img src="images/download-preto.svg" alt="download" />
          </Button>
        </Download>
      )}
    </Container>
  );
};
