import React from 'react';
import {
  AccordionDescriptionTable,
  CategoriesContainer,
  CategoryFilterButton,
  CategoryFilterDefaultButton,
  FilterButtonsContent,
  FilterContent,
} from './styles';
import { CategoriesAccordion } from './components/accordion';
import {
  AddReviewIpoPayload,
  CategoryDocIPO,
  temaName,
} from '../../pages/documentacaoIPO/fields';
import { useAuth } from '../../hooks/auth';
import { StatusChangePayload, UploadDocPayload } from './fields';

interface ICategoriesTableProps {
  categories?: CategoryDocIPO[];
  onStatusChange: (e: any) => void;
  refreshDocsCategoria: (e: string) => void;
  deleteCategoria: (e: string) => void;
  editPrazo: (e: any) => void;
  uploadDoc: (e?: UploadDocPayload) => void;
  addReview: (e: AddReviewIpoPayload) => void;
  sendNewDocsToBEE4: (e: any) => void;
  status: number;
  analyzedByCommittee: boolean;
  type?: 1 | 2;
}

export const CategoriesTable = ({
  categories,
  onStatusChange,
  refreshDocsCategoria,
  deleteCategoria,
  editPrazo,
  uploadDoc,
  addReview,
  sendNewDocsToBEE4,
  status,
  analyzedByCommittee,
  type,
}: ICategoriesTableProps) => {
  const [filterStatus, setFilterStatus] = React.useState<number | undefined>(
    undefined,
  );
  const { currentRole } = useAuth();

  const handleCategories = () => {
    // separa por tema
    const themeMap = categories
      ?.filter(item => item.tema !== undefined)
      .reduce<{ [theme: number]: CategoryDocIPO[] }>((acc, item) => {
        if (!acc[item.tema!]) {
          acc[item.tema!] = [];
        }
        acc[item.tema!].push(item);
        return acc;
      }, {});

    // mapeaia e exibe por tema
    const themeOptions = Object.keys(temaName);

    const categoriesDisplay = themeOptions?.map(theme => {
      // caso não tenha filtro pega todas as categorias

      // pega o filtro do status
      const filteredCategory = !filterStatus
        ? themeMap![parseInt(theme)]
        : themeMap![parseInt(theme)].filter(
            category => category.statusGeralDaCategoria === filterStatus,
          );

      return (
        <CategoriesAccordion
          categoryTheme={filteredCategory}
          key={theme}
          label={temaName[parseInt(theme)]}
          onStatusChange={onStatusChange}
          refreshDocsCategoria={refreshDocsCategoria}
          status={status}
          deleteCategaria={deleteCategoria}
          editPrazo={editPrazo}
          uploadDoc={uploadDoc}
          addReview={addReview}
          sendNewDocsToBEE4={sendNewDocsToBEE4}
          analyzedByCommittee={analyzedByCommittee}
          type={type}
        />
      );
    });

    return <CategoriesContainer>{categoriesDisplay}</CategoriesContainer>;
  };

  // if (status == 0 || (status === 1 && currentRole !== 'TECHINTERNO')) {
  if (status == 0) {
    return <></>;
  }

  return (
    <div>
      <FilterContent>
        DOCUMENTOS
        {type === 1 && (
          <FilterButtonsContent>
            <CategoryFilterDefaultButton
              onClick={() => {
                setFilterStatus(undefined);
              }}
            >
              <p>TODOS</p>
            </CategoryFilterDefaultButton>
            <CategoryFilterButton
              status={1}
              onClick={() => {
                setFilterStatus(1);
              }}
            >
              <p>Entregue</p>
            </CategoryFilterButton>
            <CategoryFilterButton
              status={2}
              onClick={() => {
                setFilterStatus(2);
              }}
            >
              <p>Em andamento</p>
            </CategoryFilterButton>
          </FilterButtonsContent>
        )}
      </FilterContent>
      <AccordionDescriptionTable>
        <div className="extra-space" />
        <h3>Verifique cada documento e defina seu status:</h3>
      </AccordionDescriptionTable>
      {handleCategories()}
    </div>
  );
};
