import styled from 'styled-components';

interface IStatusProps {
  status: number;
}

interface ITableLineProps {
  index: number;
  highlight?: boolean;
}

export const FilterContent = styled.div`
  display: flex;
  gap: 7.2rem;
  align-items: center;
  color: #7d8ea3;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const FilterButtonsContent = styled.div`
  display: flex;
  gap: 1.5rem;
  align-items: center;
`;

export const CategoryFilterDefaultButton = styled.button`
  color: #8c8f9a;
  font-weight: 700;
  font-size: 12;
  background: none;
  border: none;
  text-decoration: underline;
  text-underline-offset: 3px;
`;

export const CategoryFilterButton = styled.button<IStatusProps>`
  border: none;
  padding: 4px 16px;
  border-radius: 30px;
  background: ${({ status }) =>
    (status === 2 && '#FFCC4A') || (status === 1 && '#0852DD')};
  font-weight: 400;
  font-size: 12px;
  text-align: center;
  p {
    color: ${({ status }) => (status === 1 && '#FFFFFF') || '#565656'};
    font-size: 0.75rem;
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;

export const AccordionExpandedButton = styled.button`
  background: none;
  border: none;
  display: flex;
  align-items: center;
  color: #7d8ea3;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const AccordionDescriptionTable = styled.div`
  width: 100%;
  display: flex;

  .extra-space {
    width: 65%;
  }

  h3 {
    color: #171725;
    font-family: Georama;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    width: 35%;
  }
`;

export const TableLine = styled.div<ITableLineProps>`
  background: ${({ index, highlight }) =>
    highlight ? '#F5F5F5' : index % 2 === 0 ? 'none' : '#F6F9FF'};
  display: flex;
  max-width: 2000px;
  width: 100%;
  padding: 12px 16px;
  align-items: center;
  border ${({ highlight }) =>
    highlight ? '1px solid var(--Amarelo-2, #FFB800)' : 'none'}
`;

export const TableLableColumn = styled.div`
  width: 35%;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  color: #7d8ea3;
  font-size: 14px;
  font-weight: 400;
`;

export const TableActionsColumn = styled.div`
  width: 30%;
  display: flex;

  align-items: center;
  justify-content: space-evenly;
`;

export const TableButtonsColumn = styled.div`
  width: 35%;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .form-radio {
    &.MuiFormControlLabel-root {
      margin-left: -25px;
    }
    &.Mui-disabled {
      > span {
        &:first-child {
          width: 35px;
        }
      }
    }

    span {
      &:last-child {
        width: 194px;
        @media screen and (max-width: 1700px) {
          width: 170px;
        }
        @media screen and (max-width: 1500px) {
          width: 162px;
          margin-left: -2px;
          font-size: 13px;
        }
        @media screen and (max-width: 1460px) {
          width: 150px;
        }
        @media screen and (max-width: 1410px) {
          width: 145px;
        }
      }
    }
  }
`;

export const AccordionContent = styled.div`
  .Mui-expanded {
    margin: 0;
    height: fit-content;
    min-height: fit-content;
  }

  .MuiAccordionSummary-content {
    margin: 0;
  }
`;

export const RadioLable = styled.span`
  color: var(--chumbo-2, #262936);
  font-family: Georama;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const CategoriesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export const StatusBall = styled.div<IStatusProps>`
  width: 12px;
  min-width: 12px;
  height: 12px;
  min-height: 12px;
  border-radius: 6px;
  background: ${({ status }) =>
    (status === 2 && '#FFCC4A') || (status === 1 && '#0852DD')};
`;

export const ReviewLabel = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;

  h3 {
    font-size: 14px;
    font-weight: 600;
    color: #000;
  }

  p {
    font-size: 12px;
    font-weight: 400;
    color: #000;
  }

  span {
    color: #79747e;
    font-size: 12px;
    font-weight: 400;
    line-height: 16.018px;
    letter-spacing: 0.501px;
  }
`;

export const ReviewSummaryContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const ReviewCommentContent = styled.p`
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16.018px; /* 133.486% */
  letter-spacing: 0.501px;
`;
