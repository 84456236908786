import styled from 'styled-components';

export const DocumentListContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export const DocumentListDescription = styled.div`
  color: var(--Chumbo-1, #454b62);
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  padding: 16px;
`;

export const DocumentLine = styled.div`
  display: flex;
  padding: 16px;
  align-items: center;
  justify-content: space-between;

  border-top: 1px solid #d0d4e0;
`;

export const DocumentName = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--Chumbo-2, #262936);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 1.5rem;
  align-items: center;
`;

export const UploadTextButton = styled.div`
  display: flex;
  gap: 0.5rem;
  text-decoration: underline;
  text-underline-offset: 3px;
  align-items: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
`;
