/* eslint-disable react/no-unescaped-entities */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../hooks/auth';
import { TableStatusDashboard } from '../../../components/tableStatusDashboard';
import CustomizedTables, {
  DataTable,
} from '../../../components/tableDashboard';
import {
  DashboardContent,
  StatusButton,
  AccordionContainer,
  DashboardContainer,
  DefaultButton,
  ContactInfoContainer,
} from '../styles';

import { Response, stepNames } from '../fields';
import { RevisionMoal } from '../modal/revisionModal';
import { Button } from '../../../components/button';
import {
  AprovalContainer,
  GenerateIpoButtonText,
  GenerateIpoContent,
  GenerateListagemContent,
} from './styles';
import { GenerateIPOModal } from '../modal/generateIPOModal';
import { DashboardProgress } from '../../../components/dashboardProgress';
import { useEnterprise } from '../../../hooks/enterprise';
import { Roles } from '../../../router/roles.routes';
import { validarRoles } from '../../../utils/roles';

interface DashboardTypes {
  data: Response;
  completed: number[];
  isLoading: boolean;
  changeStatus: (e: any) => void;
  loadingChange: boolean;
  generateIPOStatus: (e?: any) => void;
  generateListagemStatus: (e?: any) => void;
}

export const DashboardMain = ({
  data,
  completed,
  isLoading,
  changeStatus,
  loadingChange,
  generateIPOStatus,
  generateListagemStatus,
}: DashboardTypes) => {
  const { permissions } = useAuth();
  const { resultadoDoComiteBEE4, resultadoDoComiteBEE4Listagem } =
    useEnterprise();

  const [dataFiltered, setDataFiltered] = useState<false | 1 | 2 | 3 | 4 | 5>(
    false,
  );
  const [generateIPOModal, setGenerateIPOModal] = useState(false);
  const [revisionModal, setRevisonModal] = useState<[boolean, number, number]>([
    false,
    0,
    0,
  ]);

  const canGenerateListagem =
    data.dadosDaEmpresaInfo?.status === 5 &&
    data.dadosFinanceirosInfo?.status === 5 &&
    data.dadosParaEmissaoInfo?.status === 5 &&
    data.statusDocumentacaoIpo === 5 &&
    data.statusListagemIpo === 1;
  // data.dadosDeDiligenciaInfo?.status === 5 &&
  // data.dadosDaOfertaInfo?.status === 5 &&

  const progressType = data.statusDocumentacaoIpo === 5 ? 2 : 1;

  const tableData: DataTable[] =
    progressType === 2
      ? [
          {
            route: '/dados-gerais',
            data: data.dadosDaEmpresaInfo,
            roles: [Roles.VisualizarDadosGerais],
          },
          {
            route: '/documentacao-adicional',
            data: data.dadosFinanceirosInfo,
            roles: [Roles.VisualizarFinanceiroENeg],
          },
          // { route: '/diligencia', data: data.dadosDeDiligenciaInfo },
          {
            route: '/sobre-o-ativo',
            data: data.dadosParaEmissaoInfo,
            roles: [Roles.VisualizarSobreAtivo],
          },
          // { route: '/dados-da-oferta', data: data.dadosDaOfertaInfo },
        ]
      : [
          {
            route: '/dados-gerais',
            data: data.dadosDaEmpresaInfo,
            roles: [Roles.VisualizarDadosGerais],
          },
          {
            route: '/documentacao-adicional',
            data: data.dadosFinanceirosInfo,
            roles: [Roles.VisualizarFinanceiroENeg],
          },
          // { route: '/diligencia', data: data.dadosDeDiligenciaInfo },
          {
            route: '/sobre-o-ativo',
            data: data.dadosParaEmissaoInfo,
            roles: [Roles.VisualizarSobreAtivo],
          },
        ];

  const handleStatusChange = (
    step: number,
    status: number,
    motivo?: string,
  ) => {
    const tipo = stepNames[step];
    changeStatus({ tipo, valor: status, motivo });
  };

  const handleTableData = () => {
    if (dataFiltered) {
      return tableData.filter(data => data?.data?.status === dataFiltered);
    }
    return tableData;
  };

  return (
    <>
      <DashboardContent>
        <DashboardProgress
          completed={completed}
          type={progressType}
          disabledEnd={
            progressType === 2 ? resultadoDoComiteBEE4Listagem === 1 : false
          }
        />
        <DashboardContainer>
          <AccordionContainer>
            <div>
              {[1, 4].includes(resultadoDoComiteBEE4 ?? 0) && (
                <AprovalContainer>
                  <h2>Documentos a completar</h2>
                  <p>
                    Parabéns, a empresa foi aprovada no processo de listagem na
                    BEE4! No entanto, ainda há documentos a serem entregues e
                    formulários no sistema a preencher. Abaixo listamos quais
                    pendências são necessárias serem resolvidas para que seu
                    processo de listagem possa efetivamente ser concluído.
                  </p>
                </AprovalContainer>
              )}
              <TableStatusDashboard
                tableLabel="Documentação"
                openDescription="Este é o momento de informar todos os detalhes sobre a empresa. Acompanhe o status do preenchimento dos formulários e validação dos documentos que são pré-requisito para a listagem."
                tableContent={
                  <>
                    {/* <DefaultButton
                      type="button"
                      onClick={() => {
                        setDataFiltered(false);
                      }}
                    >
                      <p>TODOS</p>
                    </DefaultButton>
                    <StatusButton
                      status={1}
                      onClick={() => {
                        setDataFiltered(1);
                      }}
                    >
                      <p>Pendente</p>
                    </StatusButton>
                    <StatusButton
                      status={3}
                      onClick={() => {
                        setDataFiltered(3);
                      }}
                    >
                      <p>Em andamento</p>
                    </StatusButton>
                    <StatusButton
                      status={2}
                      onClick={() => {
                        setDataFiltered(2);
                      }}
                    >
                      <p>Revisão</p>
                    </StatusButton>
                    <StatusButton
                      status={5}
                      onClick={() => {
                        setDataFiltered(5);
                      }}
                    >
                      <p>Validado</p>
                    </StatusButton> */}
                  </>
                }
              >
                <CustomizedTables
                  data={handleTableData()}
                  handleStatusChange={handleStatusChange}
                  setRevisonModal={setRevisonModal}
                  loadingChanges={loadingChange}
                />
              </TableStatusDashboard>
            </div>
            {validarRoles(permissions, [Roles.GerarDocumentacao]) &&
              data?.statusDocumentacaoIpo === 1 && (
                <GenerateIpoContent>
                  <p>
                    Gere a documentação quando estiver pronto para submeter seu
                    pedido para iniciar o IPO.
                  </p>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => {
                      setGenerateIPOModal(true);
                    }}
                    width="255px"
                  >
                    <GenerateIpoButtonText>
                      GERAR DOCUMENTAÇÃO
                    </GenerateIpoButtonText>
                  </Button>
                </GenerateIpoContent>
              )}
            {validarRoles(permissions, [Roles.GerarDocumentacao]) &&
              canGenerateListagem && (
                <GenerateListagemContent>
                  <p>
                    Quando estiver pronto para submeter seu pedido, gere a
                    documentação clicando no botão abaixo.
                  </p>
                  <Button
                    color="secondary"
                    onClick={() => {
                      generateListagemStatus();
                    }}
                    disabled={isLoading}
                    width="240px"
                  >
                    <GenerateIpoButtonText>
                      GERAR DOCUMENTAÇÃO
                    </GenerateIpoButtonText>
                  </Button>
                </GenerateListagemContent>
              )}
          </AccordionContainer>
          <ContactInfoContainer>
            <img src="images/mail_icon.svg" alt="" />
            <h2>Ficou com alguma dúvida ou tem alguma sugestão?</h2>
            <p>Entre em contato por um dos nossos canais.</p>
            <a
              href="mailto:emissores@bee4.com.br?subject=Contato BEE4"
              target="_blank"
              rel="noopener noreferrer"
            >
              &rarr; emissores@bee4.com.br
            </a>
          </ContactInfoContainer>
        </DashboardContainer>
      </DashboardContent>
      <RevisionMoal
        description={revisionModal[0]}
        closeDropdown={() => {
          setRevisonModal([false, 0, 0]);
        }}
        handleStatusChange={(e: any) => {
          handleStatusChange(revisionModal[1], revisionModal[2], e);
        }}
      />
      <GenerateIPOModal
        description={generateIPOModal}
        closeDropdown={() => {
          setGenerateIPOModal(false);
        }}
        onConfirm={() => {
          generateIPOStatus();
        }}
      />
    </>
  );
};
