import React, { createContext, useState, useContext, useEffect } from 'react';
import jwt from 'jsonwebtoken';
import { authenticationManager } from '../services/authenticationManager';
import { roles } from '../utils/roles';
import Carregando from '../components/carregando';
import { api } from '../services/api';

export type availableRoles =
  | 'Empreendedor'
  | 'NOMAD'
  | 'TECHINTERNO'
  | 'CVM'
  | 'BEE4'
  | 'NOMADBOSS'
  | 'TECHTERCEIRO'
  | 'TECHCOLABORADOR'
  | 'TECHSUPERVISOR';

interface IToken {
  acr: string;
  aud: string;
  auth_time: number;
  email: string;
  email_confirmed: boolean;
  exp: number;
  family_name: string;
  given_name: string;
  iat: number;
  idp: string;
  isRefreshToken: boolean;
  iss: string;
  name: string;
  nbf: number;
  new_user: boolean;
  nonce: string;
  sub: string;
  user_groups: string;
  enterpriseId: string;
  nome_completo: string;
  roles: string[];
  object_permissions: string[];
}

interface AuthState {
  token: string;
  userId: string;
  enterpriseId: string;
  role: availableRoles;
  currentUserName: string;
  permissions: string[];
}

interface AuthType {
  children: JSX.Element;
}

interface AuthContextData {
  userId: string;
  currentEnterpriseId: string;
  currentRole: availableRoles;
  currentUserName: string;
  refreshToken(): Promise<void>;
  logOut(): Promise<void>;
  permissions: string[];
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);
const MINUTES_TIMEOUT = 5 * 60 * 1000;

export const AuthProvider = ({ children }: { children: any }) => {
  const [error, setError] = useState('');
  const [data, setData] = useState<AuthState>({} as AuthState);

  useEffect(() => {
    const interval = setInterval(async () => {
      if (!!data && !!data.userId) {
        const activeUser = await checkUserActive(data.userId);
        if (!activeUser) setTimeout(logOut, 10000);
      }
    }, MINUTES_TIMEOUT);

    return function () {
      clearInterval(interval);
    };
  }, [data]);

  async function checkUserActive(id: string): Promise<boolean> {
    const { data }: any = await api.main.get(`/v1/usuarios/${id}/status`);
    return data.ativo;
  }

  async function refreshToken() {
    try {
      await authenticationManager.refreshToken();

      const token = await authenticationManager.getTokenAsync();
      if (!token) {
        return;
      }

      const decoded = jwt.decode(token) as IToken | null;
      if (!decoded) {
        return;
      }

      setData({
        userId: decoded.sub,
        enterpriseId: decoded.enterpriseId,
        currentUserName: decoded.nome_completo
          ? decoded.nome_completo
          : decoded.name,
        role: roles[
          decoded.roles?.filter(x => Object.keys(roles).includes(x))?.[0]
        ],
        permissions: decoded.object_permissions,
      } as AuthState);
    } catch (err) {
      console.error('[auth.refreshToken] Erro ao fazer refresh token.', err);
      await authenticationManager.signIn();
    }
  }

  async function logOut() {
    setData({} as AuthState);
    await authenticationManager.logOutInactiveUser();
  }

  useEffect(() => {
    let retry = 0;
    async function init() {
      retry++;
      if (retry > 5) {
        setError('Erro ao fazer login. Verifique o console.');

        setTimeout(logOut, 20000);

        return;
      }

      const token = await authenticationManager.getTokenAsync();
      if (!token) {
        init();
        return;
      }

      const decoded = jwt.decode(token) as IToken | null;
      if (!decoded) {
        return;
      }

      console.log('decoded', decoded);

      setData({
        userId: decoded.sub,
        enterpriseId: decoded.enterpriseId,
        currentUserName: decoded.nome_completo,
        role: roles[
          decoded.roles?.filter(x => Object.keys(roles).includes(x))?.[0]
        ],
        permissions: decoded.object_permissions,
      } as AuthState);
    }
    init();
  }, []);

  if (error) {
    return (
      <>
        <p>{error}</p>
      </>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        userId: data.userId,
        currentEnterpriseId: data.enterpriseId,
        currentRole: data.role,
        currentUserName: data.currentUserName,
        permissions: data.permissions,
        refreshToken,
        logOut,
      }}
    >
      {data.userId ? children : <Carregando />}
    </AuthContext.Provider>
  );
};

export function useAuth(): AuthContextData {
  const context = useContext(AuthContext);
  return context;
}
