import React from 'react';
import { Button } from '../../../../components/button';
import { Container, Title, Text } from './styles';

interface ObligationsProps {
  handleClose: () => void;
}

export const Obligations = ({ handleClose }: ObligationsProps) => {
  return (
    <Container>
      <Title>Obrigações sobre Fatos relevantes</Title>
      <Text>
        <ol>
          <li>
            <p>
              A Emissora, quando de sua Listagem na BEE4, deverá indicar um
              Diretor para atuar como responsável pela divulgação e comunicação
              dos fatos relevantes no Sistema de Emissores, e a BEE4 poderá
              solicitar, a qualquer tempo, para que o referido Diretor apresente
              esclarecimentos sobre a divulgação (ou falta de divulgação) de ato
              ou fato relevante.
            </p>
          </li>
          <li>
            <p>
              De acordo com a Resolução CVM n° 44, de 23 de agosto de 2021,
              considera-se fato relevante, qualquer decisão de acionista
              controlador, deliberação da assembleia geral ou dos órgãos de
              administração da companhia, ou qualquer outro ato ou fato de
              caráter político-administrativo, técnico, negocial ou
              econômico-financeiro ocorrido ou relacionado aos seus negócios que
              possa influir de modo ponderável:
            </p>
            <p>
              a) Na cotação dos valores mobiliários de emissão da companhia ou a
              eles referenciados;
            </p>
            <p>
              b) Na decisão dos Investidores de comprar, vender ou manter
              aqueles Valores Mobiliários; ou
            </p>
            <p>
              c) Na decisão dos Investidores de exercer quaisquer direitos
              inerentes à condição de titular de Valores Mobiliários emitidos
              pela companhia ou a eles referenciados.
            </p>
          </li>
          <li>
            <p>
              São exemplos de ato ou fato potencialmente relevante, dentre
              outros, os seguintes:
            </p>
            <p>
              1) Assinatura de acordo ou contrato de transferência do controle
              acionário da companhia, ainda que sob condição suspensiva ou
              resolutiva;
            </p>
            <p>
              2) Mudança no controle da companhia, inclusive por meio de
              celebração, alteração ou rescisão de acordo de acionistas;
            </p>
            <p>
              3) Celebração, alteração ou rescisão de acordo de acionistas em
              que a companhia seja parte ou interveniente, ou que tenha sido
              averbado no livro próprio da companhia;
            </p>
            <p>
              4) Ingresso ou saída de sócio que mantenha, com a companhia,
              contrato ou colaboração operacional, financeira, tecnológica ou
              administrativa;
            </p>
            <p>
              5) Autorização para negociação dos valores mobiliários de emissão
              da companhia em qualquer mercado, nacional ou estrangeiro;
            </p>
            <p>
              6) Decisão de promover o cancelamento da Listagem da companhia na
              BEE4;
            </p>
            <p>
              7) Incorporação, fusão ou cisão envolvendo a companhia ou empresas
              ligadas;
            </p>
            <p>8) Transformação ou dissolução da companhia;</p>
            <p>9) Mudança na composição do patrimônio da companhia;</p>
            <p>10) Mudança de critérios contábeis;</p>
            <p>11) Renegociação de dívidas;</p>
            <p>
              12) Aprovação de plano de outorga de opção de compra de ações;
            </p>
            <p>
              13) Alteração nos direitos e vantagens dos valores mobiliários
              emitidos pela companhia;
            </p>
            <p>
              14) Desdobramento ou grupamento de ações ou atribuição de
              bonificação;
            </p>
            <p>
              15) Aquisição de valores mobiliários de emissão da companhia para
              permanência em tesouraria ou cancelamento, e alienação de valores
              mobiliários assim adquiridos;
            </p>
            <p>
              16) Lucro ou prejuízo da companhia e a atribuição de proventos em
              dinheiro;
            </p>
            <p>
              17) Celebração ou extinção de contrato, ou o insucesso na sua
              realização, quando a expectativa de concretização for de
              conhecimento público;
            </p>
            <p>
              18) Aprovação, alteração ou desistência de projeto ou atraso em
              sua implantação;
            </p>
            <p>
              19) Início, retomada ou paralisação da fabricação ou
              comercialização de produto ou da prestação de serviço;
            </p>
            <p>
              20) Descoberta, mudança ou desenvolvimento de tecnologia ou de
              recursos da companhia;
            </p>
            <p>21) Modificação de projeções divulgadas pela companhia; e</p>
            <p>
              22) Pedido de recuperação judicial ou extrajudicial, requerimento
              de falência ou propositura de ação judicial, de procedimento
              administrativo ou arbitral que possa vir a afetar a situação
              econômico-financeira da companhia.
            </p>
          </li>
          <li>
            <p>
              Os Fatos relevantes podem produzir um impacto nos preços dos
              Valores Mobiliários da companhia e sendo de extremo interesse dos
              acionistas e Investidores, por esse motivo é essencial que a
              divulgação desses fatos sejam realizadas de forma ampla, clara e
              objetiva e seguindo os critérios e prazos nos Manuais da BEE4 e
              resumidos abaixo:
            </p>
            <p>
              a) Para os atos ou fatos relevantes que ocorram no Período de
              Oferta Irrevogável, o Emissor deverá realizar a comunicação à BEE4
              com antecedência mínima de 30 minutos em relação ao momento em que
              o ato ou fato relevante for divulgado ao mercado, nos termos da
              legislação aplicável.
            </p>
            <p>
              b) Para os atos ou fatos relevantes que ocorram no(s) dia(s) de
              Sessão de Negociação, o Emissor deverá realizar a comunicação à
              BEE4 tão logo seja possível e ao mercado, preferencialmente antes
              do início da Sessão de Negociação ou depois do seu encerramento.
            </p>
            <p>
              c) Nas situações em que for imperativa a divulgação de ato ou fato
              relevante durante a Sessão de Negociação, e após o leilão de
              abertura, sob o risco de perda de controle sobre o sigilo da
              informação, o Emissor deverá informar à Diretoria de Emissores da
              BEE4 com, no mínimo, 10 (dez) minutos de antecedência em relação à
              divulgação do ato ou fato relevante ao mercado, nos termos da
              legislação aplicável.
            </p>
            <p>
              d) A depender das informações prestadas pelo Emissor, a BEE4,
              diante da verificação de que a suspensão poderá ser prejudicial ao
              funcionamento regular e eficiente do Mercado de Balcão Organizado,
              poderá não suspender a negociação dos Valores Mobiliários do
              Emissor.
            </p>
          </li>
        </ol>
      </Text>
      <Button height="40px" width="157px" onClick={handleClose}>
        Entendi
      </Button>
    </Container>
  );
};
