import React from 'react';
import { useNavigate } from 'react-router-dom';
import { RedirectContainer } from '../../components/redirectContainer';

export const SemEmpresas = () => {
  const navigate = useNavigate();

  return (
    <RedirectContainer
      title="Empresas indisponíveis"
      titleFontSize="64px"
      top="35%"
      description={
        <span
          style={{
            margin: '8px 0 48px',
            width: '83%',
            maxWidth: '1228px',
            fontSize: '32px',
            textAlign: 'center',
          }}
        >
          Ainda não há emissores disponíveis para o seu acesso. Assim que for
          liberado, você poderá visualizar o sistema e as informações do(s)
          emissor(es).
        </span>
      }
      buttonDescription="Deslogar"
      onClick={() => navigate('/sair')}
    />
  );
};
