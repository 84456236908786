import React from 'react';
import { useForm } from 'react-hook-form';
import { Button } from '../../../components/button';
import { Modal } from '../../../components/modal';
import { DeleteButtons, ModalContentForm } from '../styles';
import { Controller } from '../../../components/controller';

interface IButtonProps {
  description: boolean;
  closeDropdown: (e?: any) => void;
  parceiroId?: string;
  reenviarConvite: (e: any) => void;
}
export const InviteParceiroModal: React.FC<IButtonProps> = ({
  description,
  closeDropdown,
  parceiroId,
  reenviarConvite,
}) => {
  const handleInvite = () => {
    reenviarConvite({ id: parceiroId });
    closeDropdown();
  };

  return (
    <Modal open={description} handleClose={closeDropdown} width={500}>
      <ModalContentForm>
        <h3>Essa ação reenviará o convite para o parceiro</h3>

        <div className="descriptionDeleteModal">
          <span>Tem certeza que deseja continuar com essa ação?</span>
        </div>

        <DeleteButtons>
          <Button
            type="button"
            variant="outlined"
            width="96px"
            onClick={closeDropdown}
          >
            CANCELAR
          </Button>
          <Button
            type="button"
            width="96px"
            variant="outlined"
            onClick={handleInvite}
          >
            REENVIAR
          </Button>
        </DeleteButtons>
      </ModalContentForm>
    </Modal>
  );
};
