import styled from 'styled-components';
import { Container } from '../../../../components/container';

export const PostContainer = styled(Container)`
  &&& {
    margin-top: 21px;
    padding: 34px 30px 28px;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 34px;

  div + div {
    position: relative;
  }
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;
`;

export const CompanyName = styled.span`
  font-size: 16px;
  font-weight: bold;
  color: #101219;
`;

export const Date = styled.span`
  font-family: Inter;
  font-size: 12px;
  color: #90949c;
`;

export const Lightbox = styled.div`
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /*optional bg color*/
  background-color: rgba(0, 0, 0, 0.52);

  /*the following isn't necessary but will help center everything once we add buttons*/
  display: flex;
  align-items: center;
  justify-content: space-between;

  img {
    height: 80vh;
    max-width: 90vw;
    object-fit: cover;
  }

  button {
    border: none;
    background: transparent;
    img {
      height: 20px;
      width: 30px;
    }
  }
`;

export const LeftButton = styled.button`
  transform: rotate(90deg);
  margin-left: 20px;
`;

export const RightButton = styled.button`
  transform: rotate(-90deg);
  margin-right: 20px;
`;

export const Divider = styled.div`
  height: 30px;
`;

export const RadioContainer = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  padding-top: 10px;
`;
