import * as yup from 'yup';
import { isValid } from '../../services/isValid';

export interface Parceiro {
  id?: string;
  nome?: string;
  email?: string;
  cpf?: string;
  objetivoParaDarAcesso?: string;
  nomeDaEmpresaQualPertence?: string;
  celular?: string;
  tipoDoPerfil?: number;
  statusDoConvite?: 0 | 1 | 2 | 4 | 5;
  ativo?: boolean;
}

export const schemaParceiro = yup.object().shape({
  email: yup.string().required('Campo obrigatório'),
  nome: yup.string().required('Campo obrigatório'),
  celular: yup.string().required('Campo obrigatório'),
  cpf: yup
    .string()
    .test('validadeCPF', 'CPF inválido', cpf => {
      return cpf ? isValid.cpf(cpf) : true;
    })
    .required('Campo obrigatório'),
  objetivoParaDarAcesso: yup.string().required('Campo obrigatório'),
  nomeDaEmpresaQualPertence: yup.string().required('Campo obrigatório'),
  tipoDoPerfil: yup.number().required('Campo obrigatório'),
});

export const listProfileType = [
  {
    label: 'Emissor',
    value: 1,
  },
  {
    label: 'Emissor Colaborador',
    value: 2,
  },
];
