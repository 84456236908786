import styled from 'styled-components';

export const DeleteButtons = styled.div`
  display: flex;
  gap: 4rem;
  margin-top: 1.5rem;
  align-items: center;
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 0;
  h3 {
    font-weight: 400;
    margin-bottom: 0.7rem;
    text-align: center;
  }
`;
