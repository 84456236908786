import React, { createContext, useState, useContext, useEffect } from 'react';
import Carregando from '../components/carregando';
import { api } from '../services/api';
import { useAuth } from './auth';
import { declaracoesEmissor } from '../components/declarationModal/fields';
import { authenticationManager } from '../services/authenticationManager';

interface IEnterprise {
  empresaId: string;
  nomeFantasia: string;
  termoEmissor?: boolean;
  visualizouDecisaoDoComite?: boolean;
  resultadoDoComiteBEE4?: 1 | 2 | 3 | 4;
  passouPelaDecisaoDoComite?: boolean;
  visualizouDecisaoDoComiteListagem?: boolean;
  passouPelaDecisaoDoComiteListagem?: boolean;
  resultadoDoComiteBEE4Listagem?: 1 | 2 | 3 | 4;
}

interface EnterpriseState {
  enterprises: IEnterprise[];
  currentEnterpriseId: string;
}

interface EnterpriseContextData {
  enterprises: IEnterprise[];
  currentEnterpriseId: string;
  setCurrentEnterpriseId(enterpriseId: string): void;
  updateEnterprisesAsync(): Promise<void>;
  updateTermsAndAgreements(): Promise<void>;
  updateViewDocIPO(): Promise<void>;
  usuarioNaoPossuiEmpresas: boolean;
  currentEnterpriseName?: string;
  visualizouDecisaoDoComite?: boolean;
  resultadoDoComiteBEE4?: 1 | 2 | 3 | 4;
  passouPelaDecisaoDoComite?: boolean;
  visualizouDecisaoDoComiteListagem?: boolean;
  passouPelaDecisaoDoComiteListagem?: boolean;
  resultadoDoComiteBEE4Listagem?: 1 | 2 | 3 | 4;
  updateViewListagem(): Promise<void>;
}

const EnterpriseContext = createContext<EnterpriseContextData>(
  {} as EnterpriseContextData,
);

export const EnterpriseProvider = ({ children }: { children: any }) => {
  const { userId, logOut, refreshToken } = useAuth();

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<EnterpriseState>({} as EnterpriseState);
  const [error, setError] = useState('');

  async function setCurrentEnterpriseId(enterpriseId: string) {
    if (!data.enterprises.some(x => x.empresaId === enterpriseId)) {
      throw new Error('A empresa selecionada não está na lista disponível.');
    }
    window.localStorage.setItem('empresa', enterpriseId);

    const response: any = await api.main.put(
      `v1/autenticacao/setenterpriseid`,
      {
        enterpriseId,
      },
    );

    await refreshToken();

    setData({ ...data, currentEnterpriseId: enterpriseId });
  }

  async function updateEnterprisesAsync() {
    if (data?.enterprises?.length > 0) {
      return;
    }

    setIsLoading(true);
    try {
      const response: any = await api.main.get(
        `/v1/usuarios/${userId}/empresas`,
      );
      if (!response.data) {
        return;
      }

      let empresaSelecionada = window.localStorage.getItem('empresa') || '';

      const usuarioPossuiEmpresa = response.data.some(
        (x: IEnterprise) => x.empresaId === empresaSelecionada,
      );

      if (!empresaSelecionada || !usuarioPossuiEmpresa) {
        empresaSelecionada = response?.data[0]?.empresaId || '';
      }

      await api.main.put(`v1/autenticacao/setenterpriseid`, {
        enterpriseId: empresaSelecionada,
      });

      await refreshToken();

      setData({
        ...data,
        enterprises: response?.data?.map((enterprise: IEnterprise) => ({
          empresaId: enterprise.empresaId,
          nomeFantasia: enterprise.nomeFantasia,
          termoEmissor: enterprise?.termoEmissor,
          visualizouDecisaoDoComite: enterprise?.visualizouDecisaoDoComite,
          resultadoDoComiteBEE4: enterprise?.resultadoDoComiteBEE4,
          passouPelaDecisaoDoComite: enterprise?.passouPelaDecisaoDoComite,

          visualizouDecisaoDoComiteListagem:
            enterprise?.visualizouDecisaoDoComiteListagem,
          passouPelaDecisaoDoComiteListagem:
            enterprise?.passouPelaDecisaoDoComiteListagem,
          resultadoDoComiteBEE4Listagem:
            enterprise?.resultadoDoComiteBEE4Listagem,
        })),
        currentEnterpriseId: empresaSelecionada,
      });
    } catch (err: any) {
      console.error('Erro ao obter empresas do usuário.', err);
      setError(
        err?.response?.data?.error?.code ||
          'Erro ao obter empresas do usuário. Verifique o console.',
      );
    } finally {
      setIsLoading(false);
    }
  }

  async function updateTermsAndAgreements() {
    if (data?.enterprises?.length < 0) {
      return;
    }

    setIsLoading(true);
    try {
      const response: any = await api.main.post(
        `/v1/empresas/${data?.enterprises[0].empresaId}/declaracao-emissor`,
        { declaracoesEmissor },
      );

      setData({
        ...data,
        enterprises: data.enterprises?.map((enterprise: IEnterprise) => ({
          empresaId: enterprise.empresaId,
          nomeFantasia: enterprise.nomeFantasia,
          termoEmissor: true,

          visualizouDecisaoDoComite: enterprise?.visualizouDecisaoDoComite,
          resultadoDoComiteBEE4: enterprise?.resultadoDoComiteBEE4,

          visualizouDecisaoDoComiteListagem:
            enterprise?.visualizouDecisaoDoComiteListagem,
          resultadoDoComiteBEE4Listagem:
            enterprise?.resultadoDoComiteBEE4Listagem,
          // passouPelaDecisaoDoComiteListagem: enterprise?.passouPelaDecisaoDoComiteListagem
          // passouPelaDecisaoDoComite: enterprise?.passouPelaDecisaoDoComite,
        })),
      });
    } catch (err: any) {
      console.error('Erro ao atualizar a declaração do emissor.', err);
      setError(
        err?.response?.data?.error?.code ||
          'Erro ao atualizar a declaração do emissor. Verifique o console.',
      );
    } finally {
      setIsLoading(false);
    }
  }

  async function updateViewDocIPO() {
    if (data?.enterprises?.length < 0) {
      return;
    }

    setIsLoading(true);
    try {
      const response: any = await api.main.put(
        `/v1/empresas/${data.currentEnterpriseId}/ipo-comite/visualizou-decisao-comite`,
      );

      setData({
        ...data,
        enterprises: data.enterprises?.map((enterprise: IEnterprise) => ({
          empresaId: enterprise.empresaId,
          nomeFantasia: enterprise.nomeFantasia,
          termoEmissor: true,

          visualizouDecisaoDoComite: true,
          resultadoDoComiteBEE4: enterprise?.resultadoDoComiteBEE4,
          // passouPelaDecisaoDoComite: enterprise?.passouPelaDecisaoDoComite,
        })),
      });
    } catch (err: any) {
      console.error('Erro ao tentar prosseguir com a documentação IPO.', err);
      setError(
        err?.response?.data?.error?.code ||
          'Erro ao tentar prosseguir com a documentação IPO. Verifique o console.',
      );
    } finally {
      setIsLoading(false);
    }
  }

  async function updateViewListagem() {
    if (data?.enterprises?.length < 0) {
      return;
    }

    setIsLoading(true);
    try {
      const response: any = await api.main.put(
        `/v1/empresas/${data.currentEnterpriseId}/ipo-comite-adicional-listagem/visualizou-decisao-comite`,
      );

      setData({
        ...data,
        enterprises: data.enterprises?.map((enterprise: IEnterprise) => ({
          empresaId: enterprise.empresaId,
          nomeFantasia: enterprise.nomeFantasia,
          termoEmissor: true,

          visualizouDecisaoDoComiteListagem: true,
          resultadoDoComiteBEE4: enterprise?.resultadoDoComiteBEE4,
          resultadoDoComiteBEE4Listagem:
            enterprise?.resultadoDoComiteBEE4Listagem,
          // passouPelaDecisaoDoComite: enterprise?.passouPelaDecisaoDoComite,
        })),
      });
    } catch (err: any) {
      console.error('Erro ao tentar prosseguir com a documentação IPO.', err);
      setError(
        err?.response?.data?.error?.code ||
          'Erro ao tentar prosseguir com a documentação IPO. Verifique o console.',
      );
    } finally {
      setIsLoading(false);
    }
  }

  function getFantasyNameOrIPO(
    empresaId: string,
    empresas: IEnterprise[],
  ): any[] {
    const empresaEncontrada = empresas?.find(
      empresa => empresa?.empresaId === empresaId,
    );

    if (empresaEncontrada) {
      return [
        empresaEncontrada?.nomeFantasia,
        empresaEncontrada?.visualizouDecisaoDoComite,
        empresaEncontrada?.passouPelaDecisaoDoComite,
        empresaEncontrada?.resultadoDoComiteBEE4,
        empresaEncontrada?.visualizouDecisaoDoComiteListagem,
        empresaEncontrada?.passouPelaDecisaoDoComiteListagem,
        empresaEncontrada?.resultadoDoComiteBEE4Listagem,
      ];
    }

    return [undefined, false, false, undefined];
  }

  useEffect(() => {
    updateEnterprisesAsync();
  }, []);

  if (error) {
    return (
      <>
        <p>{error}</p>
        <button onClick={logOut} type="button">
          Sair
        </button>
      </>
    );
  }

  const enterpriseInfo = getFantasyNameOrIPO(
    data.currentEnterpriseId,
    data.enterprises,
  );

  return (
    <EnterpriseContext.Provider
      value={{
        enterprises: data.enterprises,
        currentEnterpriseId: data.currentEnterpriseId,
        currentEnterpriseName: enterpriseInfo[0],
        visualizouDecisaoDoComite: enterpriseInfo[1],
        passouPelaDecisaoDoComite: enterpriseInfo[2],
        resultadoDoComiteBEE4: enterpriseInfo[3],
        visualizouDecisaoDoComiteListagem: enterpriseInfo[4],
        passouPelaDecisaoDoComiteListagem: enterpriseInfo[5],
        resultadoDoComiteBEE4Listagem: enterpriseInfo[6],
        setCurrentEnterpriseId,
        updateEnterprisesAsync,
        updateTermsAndAgreements,
        updateViewDocIPO,
        usuarioNaoPossuiEmpresas: data.enterprises?.length === 0,
        updateViewListagem,
      }}
    >
      {isLoading ? <Carregando /> : children}
    </EnterpriseContext.Provider>
  );
};

export function useEnterprise(): EnterpriseContextData {
  const context = useContext(EnterpriseContext);
  return context;
}
