import React, { CSSProperties, useState } from 'react';
import { Button } from '../../../../components/button';
import { Modal } from '../../../../components/modal';
import {
  ConfirmModalContent,
  ModalButtons,
  ModalCheckboxContainer,
  ModalContent,
} from './styles';
import { Checkbox } from '../../../../components/checkbox';
import { listaDeDeclaracao } from '../../fields';

interface IButtonProps {
  description: boolean;
  closeDropdown: (e?: any) => void;
  onConfirm: () => void;
  confirmModal: boolean;
}
export const ClSendDocListagemModal: React.FC<IButtonProps> = ({
  description,
  closeDropdown,
  onConfirm,
  confirmModal,
}) => {
  const [v1, setV1] = useState(false);
  const [v2, setV2] = useState(false);
  const [v3, setV3] = useState(false);

  const CheckBoxLabelStyle: CSSProperties = {
    textAlign: 'start',
    fontSize: '14px',
    fontWeight: '500',
    color: '#171725',
    fontFamily: 'Georama',
    alignContent: 'baseline',
  };

  if (confirmModal) {
    return (
      <Modal open={confirmModal} handleClose={closeDropdown} width={350}>
        <ConfirmModalContent>
          <img
            style={{ width: '48px', height: '48px', marginBottom: '1rem' }}
            src="images/check-verde.svg"
            alt=""
          />
          <h3>Seu pedido foi submetido com sucesso!</h3>
          <p>
            Agora a Equipe da BEE4 irá analisar a documentação e retornará em
            breve.
          </p>
        </ConfirmModalContent>
      </Modal>
    );
  }
  return (
    <Modal open={description} handleClose={closeDropdown} width={500}>
      <ModalContent>
        <h2>Envio do dossiê para BEE4</h2>
        <p>
          O presente envio tem por objetivo apresentar as informações e
          documentos pertinentes para que seja concedido o registro da Empresa
          Emissora Requerente como Emissor junto à BEE4, mediante avaliação de
          seu Comitê de Aprovação de Empresa Emissora (&quot;Registro&quot;),
          bem como seja concedida a autorização para negociação dos Valores
          Mobiliários emitidos pela Empresa Emissora Requerente
          (&quot;Admissão&quot;), que ocorrerá após o encerramento com êxito da
          oferta pública junto à plataforma eletrônica de investimento
          participativo contratada.
        </p>
        <p style={{ fontSize: '14px', fontWeight: '500', color: '#171725' }}>
          O Consultor de Listagem, que assessora a Empresa Emissora Requerente,
          declara para os devidos fins que:
        </p>
        <ModalCheckboxContainer>
          <Checkbox
            label={listaDeDeclaracao[0]}
            onChange={e => {
              setV1(e.target.checked);
            }}
            checked={v1}
            labelStyle={CheckBoxLabelStyle}
          />
          <Checkbox
            label={listaDeDeclaracao[1]}
            onChange={e => {
              setV2(e.target.checked);
            }}
            checked={v2}
            labelStyle={CheckBoxLabelStyle}
          />
          <Checkbox
            label={listaDeDeclaracao[2]}
            onChange={e => {
              setV3(e.target.checked);
            }}
            checked={v3}
            labelStyle={CheckBoxLabelStyle}
          />
        </ModalCheckboxContainer>
        <ModalButtons>
          <Button
            type="button"
            width="150px"
            fontSize="12px"
            onClick={() => {
              closeDropdown();
            }}
            variant="outlined"
            color="secondary"
          >
            VOLTAR
          </Button>
          <Button
            type="button"
            width="150px"
            fontSize="12px"
            onClick={() => {
              onConfirm();
            }}
            disabled={!v1 || !v2 || !v3}
            color="secondary"
          >
            ENVIAR DOSSIÊ
          </Button>
        </ModalButtons>
      </ModalContent>
    </Modal>
  );
};
