import React from 'react';
import { Container } from '../../components/container';
import RenderSkeletonOrContent from '../../components/renderSkeletonOrContent';
import { Skeleton } from '../../components/skeleton';
import {
  StepRow,
  Divider,
  FirstColumn,
  SecondColumn,
  StepName,
} from './styles';

export const LoadingDashboard = () => {
  return (
    <Container style={{ padding: '0 25px' }}>
      <RenderSkeletonOrContent
        isLoading
        content={<></>}
        fallback={
          <div>
            {[...Array(4)].map((_, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <React.Fragment key={i}>
                <StepRow>
                  <FirstColumn>
                    <span>
                      <Skeleton width="20px" height="20px" variant="text" />
                    </span>
                    <StepName>
                      <Skeleton width="320px" height="20px" variant="text" />
                    </StepName>
                  </FirstColumn>
                  <SecondColumn validated={false}>
                    <Skeleton width="100px" height="32px" variant="text" />
                  </SecondColumn>
                </StepRow>
                {i < 3 && <Divider />}
              </React.Fragment>
            ))}
          </div>
        }
      />
    </Container>
  );
};
