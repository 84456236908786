import React from 'react';
import { Modal } from '../../../../../../components/modal';
import { ModalContent, ModalTitle } from '../../../companies/styles';

interface IButtonProps {
  description: boolean;
  closeDropdown: (e?: any) => void;
  label?: string;
  text?: string;
}
export const ArchiveDocumentModal: React.FC<IButtonProps> = ({
  description,
  closeDropdown,
  label,
  text,
}) => {
  return (
    <Modal open={description} handleClose={closeDropdown} width={600}>
      <ModalContent>
        <img
          style={{ width: '50px', height: '50px', marginBottom: '1rem' }}
          src="images/check-verde.svg"
          alt=""
        />
        <ModalTitle>{label ?? 'Documento arquivado com sucesso!'}</ModalTitle>
        <span>
          {text ?? (
            <>
              O documento está disponível para visualização em &quot;Itens
              arquivados&quot;
            </>
          )}
        </span>
      </ModalContent>
    </Modal>
  );
};
