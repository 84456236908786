export const declaracoesEmissor = [
  'A Empresa Emissora tem receita bruta anual individual ou consolidada de R$ 10 milhões até R$ 300 milhões apurada no exercício social encerrado no ano anterior à oferta.',
  'A Empresa Emissora não possui registro de Emissor de Valores Mobiliários perante a CVM.',
  'A Empresa Emissora é operacional há, no mínimo, 12 meses.',
  'A Empresa Emissora é organizada sob a forma de sociedade anônima (S.A.) ou sociedade limitada (LTDA.). *No caso da Listagem de Valores mobiliários previstos na Lei 6.404/76, será obrigatória a organização sob a forma de sociedade anônima.',
  'Me comprometo a informar, no prazo de 5 (cinco) dias úteis, quaisquer alterações que vierem a ocorrer nos dados cadastrais da Empresa Emissora.',
  'Me comprometo a colocar no Sistema de Emissores apenas informações e documentos verdadeiros e que estão de acordo com a documentação comprobatória.',
  'Li, compreendi e estou plenamente de acordo com os termos e condições contidas nos Manuais da BEE4, que se encontram disponíveis no link do sistema, bem como demais Manuais BEE4 disponibilizados por outros meios e dos quais este termo é parte integrante e indispensável.',
  'Estou ciente que no caso de não ocorrência da listagem, seja por desistência da empresa Emissora Requerente, não aprovação pela BEE4 ou outro motivo, os valores das taxas pagas não serão devolvidas.',
];
