import styled from 'styled-components';

export const FormContent = styled.form`
  padding-bottom: 55px;
  margin-top: 40px;
`;

export const ContentTitle = styled.h2`
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.54px;
  color: #51576c;
`;

export const ContentTitleDescription = styled.p`
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.54px;
  color: #51576c;
`;

export const InputDescription = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

export const ContentDescription = styled.h3`
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.54px;
  color: #51576c;
`;

export const InputContent = styled.div`
  margin-bottom: 50px;
`;

export const Division = styled.div`
  padding: 40px 0;
`;

export const SmallDivision = styled.div`
  padding: 1rem 0;
`;

export const BigDivision = styled.div`
  padding: 5rem 0;
`;

export const MarginDivision = styled.div`
  padding: 1rem 0;
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 0.5rem;
  gap: 1rem;
`;

export const Plus = styled.span`
  margin-right: 0.4rem;
  font-size: 1.2rem;
`;

export const InputDelete = styled.div`
  display: flex;
  button {
    border: none;
    background: none;
  }
`;

export const FinancialYearContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

export const FinancialYearDescription = styled.span`
  font-size: 0.8rem;
  font-weight: 600;
  color: #737787;
`;

export const ModalContent = styled.form`
  display: flex;
  flex-direction: column;
  align-items: left;
  gap: 1rem;
  .MuiFormGroup-root {
    display: flow-root;
  }
`;

export const ModalTitle = styled.h3`
  display: flex;
  justify-content: start;
  margin: 1rem 0 1.5rem 0;
`;

export const DeleteButton = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;
`;

export const UploadModalContent = styled.div`
  text-align: center;
`;

export const UploadModalTitle = styled.h2`
  font-size: 1.5rem;
  color: #171725;
`;

export const ModalFilesSuported = styled.p`
  font-size: 0.9rem;
  color: #bdbdbd;
  font-weight: 400;
  margin-top: 1rem;
`;
