import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import { useController } from 'react-hook-form';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import { Tooltip } from '../tooltip';
import { TooltipDiv, TooltipDivTextArea } from '../../pages/dadosGerais/styles';

interface InputProps {
  onChange?: (e?: any) => void;
  label?: string;
  name?: string;
  value?: any;
  textarea?: boolean;
  disabled?: boolean;
  error?: boolean;
  errorMessage?: string;
  height?: string;
  width?: string;
  placeholder?: string;
  maxLength?: number;
  ref?: any;
  type?: string;
  mask?: any;
  format?: string;
  min?: number;
  max?: number;
  description?: any;
  onlyCharacters?: boolean;
}

export interface MaskedInputOnChangeCallback {
  formattedValue: string;
  value: string;
  floatValue: number;
}

export const masks = {
  currency: {
    thousandSeparator: '.',
    decimalSeparator: ',',
    isNumericString: true,
    prefix: 'R$',
    decimalScale: 2,
    fixedDecimalScale: true,
  },
  percentage: {
    thousandSeparator: '.',
    decimalSeparator: ',',
    isNumericString: true,
    suffix: '%',
  },
  cep: {
    format: '#####-###',
    mask: '_',
    allowLeadingZeros: true,
  },
  date: {
    format: '##/##/####',
    mask: '_',
    allowLeadingZeros: true,
  },
  phone: {
    format: '(##) #####-####',
    mask: '_',
    allowLeadingZeros: true,
  },
  cnpj: {
    format: '##.###.###/####-##',
    mask: '_',
    allowLeadingZeros: true,
  },
  cpf: {
    format: '###.###.###-##',
    mask: '_',
    allowLeadingZeros: true,
  },
  integer: {
    isNumericString: true,
    decimalScale: 0,
    allowLeadingZeros: true,
    allowNegative: false,
  },
  thousandSeparator: {
    thousandSeparator: '.',
    decimalSeparator: ',',
    isNumericString: true,
    decimalScale: 0,
  },
  bank: {
    format: '###.###-#',
    mask: '_',
    allowLeadingZeros: true,
  },
  negativeCurrency: {
    thousandSeparator: '.',
    decimalSeparator: ',',
    isNumericString: true,
    prefix: 'R$',
    decimalScale: 2,
    fixedDecimalScale: false,
    allowNegative: true,
  },
};

export const InputControlled: React.FC<any> = props => {
  const {
    label,
    textarea,
    onChange,
    disabled,
    error,
    errorMessage,
    noZero,
    height,
    width,
    placeholder,
    maxLength = 350,
    ref,
    type,
    mask,
    format,
    onBlur,
    min,
    max,
    description,
    onlyCharacters = false,
  } = props;
  const { field, fieldState, formState } = useController(props);

  const labelHasEllipsis = () => {
    const labelObj = document.getElementById(label);
    if (labelObj) {
      if (labelObj.offsetWidth < labelObj.scrollWidth) {
        return true;
      }
      return false;
    }
    return true;
  };

  const [hasEllipsis, setHasEllipsis] = useState<boolean>(false);

  useEffect(() => {
    function handleResize() {
      setHasEllipsis(labelHasEllipsis());
    }

    window.addEventListener('resize', handleResize);

    setHasEllipsis(labelHasEllipsis());

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [label, labelHasEllipsis]);

  const handleOnValueChangeMask = (v: MaskedInputOnChangeCallback) => {
    if (v.floatValue === undefined && noZero) {
      field.onChange(null);
    } else if (
      v.floatValue === undefined &&
      noZero === false &&
      mask?.isNumericString
    ) {
      field.onChange(0);
    } else {
      mask?.isNumericString
        ? field.onChange(v.floatValue)
        : field.onChange(v.value);
    }
  };

  const handleIsAllowed = (values: NumberFormatValues) => {
    const { floatValue } = values;

    if (noZero && floatValue === 0 && noZero !== undefined) {
      return false;
    }
    if (floatValue && (floatValue < min || floatValue > max)) {
      return false;
    }
    return true;
  };

  const handleOnValueChange = (v: any) => {
    const val = v.value ? v.value : v.target.value;
    if (!onlyCharacters) {
      field.onChange(val);
    } else if (val !== '' && /\d$/.test(val)) {
      return '';
    } else {
      field.onChange(val);
    }
  };

  const handleHelperText =
    errorMessage ??
    fieldState?.error?.message ??
    (description ||
      (textarea && field.value
        ? field?.value?.length !== undefined &&
          `${field?.value?.length ?? 0}/${maxLength} caracteres`
        : undefined));

  return (
    <>
      {mask ? (
        <NumberFormat
          format={format}
          {...mask}
          customInput={TextField}
          error={!!fieldState?.error || error}
          helperText={handleHelperText}
          disabled={disabled}
          multiline={textarea}
          isAllowed={handleIsAllowed}
          variant="outlined"
          label={label}
          value={field?.value}
          placeholder={placeholder}
          onValueChange={handleOnValueChangeMask}
          fullWidth
          rows={6}
          onBlur={onBlur}
          inputRef={ref || field.ref}
          inputProps={{ maxLength, style: { height, width } }}
          InputLabelProps={{
            id: label,
            style: {
              color: disabled ? '#bdbdbd' : '#424242',
              pointerEvents: 'auto',
            },
            shrink: true,
          }}
        />
      ) : (
        <TextField
          error={!!fieldState?.invalid}
          helperText={handleHelperText}
          disabled={disabled}
          multiline={textarea}
          variant="outlined"
          label={label}
          value={field.value || ''}
          placeholder={placeholder}
          onChange={handleOnValueChange}
          fullWidth
          rows={6}
          type={type}
          inputRef={field.ref}
          inputProps={{ maxLength, style: { height, width } }}
          InputLabelProps={{
            id: label,
            style: {
              color: disabled ? '#bdbdbd' : '#424242',
              pointerEvents: 'auto',
            },
            shrink: true,
          }}
        />
      )}
      {hasEllipsis && (
        <Tooltip title={label} disabled={!hasEllipsis} placement="top">
          {textarea ? <TooltipDivTextArea /> : <TooltipDiv />}
        </Tooltip>
      )}
    </>
  );
};
