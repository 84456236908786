import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '../../components/button';
import { Controller } from '../../components/controller';
import { Tooltip } from '../../components/tooltip';
import { EmptyState } from '../../components/emptyState';
import { useAuth } from '../../hooks/auth';

import {
  Info,
  ButtonDiv,
  FileRow,
  FileContainer,
  DocumentsDiv,
  FormSection,
} from './styles';
import { Grid } from '../../components/grid';
import usePosIPO from './hooks/ipo';
import { useEnterprise } from '../../hooks/enterprise';
import { dateToTimestamp, timestampToDate } from '../../utils/dateHandling';
import usePosIPODocuments from './hooks/documents';
import { listYesNo } from '../../utils/fields';
import { IUsePosIPO, schema } from './fields';
import { Container } from '../../components/container';

export const DadosDaOferta = () => {
  const { currentRole } = useAuth();
  const { currentEnterpriseId } = useEnterprise();

  const profileCanUse = [
    'NOMAD',
    'NOMADBOSS',
    'Empreendedor',
    'TECHTERCEIRO',
  ].includes(currentRole);
  const [readOnly, setReadOnly] = useState(false);
  const [isGenerated, setGenerated] = useState(false);

  const { posIPOData, updateDados } = usePosIPO({ currentEnterpriseId });
  const { documents } = usePosIPODocuments({
    currentEnterpriseId,
    isDisabled: profileCanUse && !isGenerated,
  });

  const isDisabled = !profileCanUse || readOnly;

  const handleDownload = (url?: string) => {
    if (url) {
      const link = document.createElement('a');
      link.href = url;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const { handleSubmit, control, reset, watch } = useForm<IUsePosIPO>({
    resolver: yupResolver(schema),
  });

  const { loteAdicional, tipoDeAtivo } = watch();

  const handleConfirmForm = (data: IUsePosIPO) => {
    updateDados({
      ...data,
      qualOPercentual: loteAdicional != 'true' ? 0 : data?.qualOPercentual,
      dataDeEmissaoDoAtivoASerNegociadoNaBEE4: dateToTimestamp(
        data?.dataDeEmissaoDoAtivoASerNegociadoNaBEE4,
      ),
      dataDeInicioDaNegociacao: dateToTimestamp(data.dataDeInicioDaNegociacao),

      calendarioDaOferta: {
        dataDeConclusaoDaOfertaPublica: dateToTimestamp(
          data.calendarioDaOferta.dataDeConclusaoDaOfertaPublica,
        ),
        disponibilizacaoDosMateriaisDeDivulgacao: dateToTimestamp(
          data.calendarioDaOferta.disponibilizacaoDosMateriaisDeDivulgacao,
        ),
        inicioDoPeriodoDoPedidoDeReserva: dateToTimestamp(
          data.calendarioDaOferta.inicioDoPeriodoDoPedidoDeReserva,
        ),
        fimDoPeriodoDoPedidoDeReserva: dateToTimestamp(
          data.calendarioDaOferta.fimDoPeriodoDoPedidoDeReserva,
        ),
        dataDeLiquidacaoDaOferta: dateToTimestamp(
          data.calendarioDaOferta.dataDeLiquidacaoDaOferta,
        ),
        dataDeNegociacaoDoPrimeiroLeilao: dateToTimestamp(
          data.calendarioDaOferta.dataDeNegociacaoDoPrimeiroLeilao,
        ),
        dataDeInicioDasNegociacoes: dateToTimestamp(
          data.calendarioDaOferta.dataDeInicioDasNegociacoes,
        ),
      },
    });
  };

  useEffect(() => {
    if (!posIPOData) {
      return;
    }

    reset({
      montanteCaptado: posIPOData?.montanteCaptado,
      participacaoVendidaNaEmpresa: posIPOData?.participacaoVendidaNaEmpresa,
      numeroDeAcoesOuTitulosEmitidosAposAOfertaPublica:
        posIPOData?.numeroDeAcoesOuTitulosEmitidosAposAOfertaPublica,
      tipoDeAtivo: posIPOData?.tipoDeAtivo,
      codigoDeNegociacaoProposto: posIPOData?.codigoDeNegociacaoProposto,
      codigoIsin: posIPOData?.codigoIsin,
      dataDeEmissaoDoAtivoASerNegociadoNaBEE4: timestampToDate(
        posIPOData?.dataDeEmissaoDoAtivoASerNegociadoNaBEE4,
      ),
      loteAdicional: posIPOData?.loteAdicional?.toString(),
      qualOPercentual: posIPOData?.qualOPercentual,
      comentariosSobreAEmissaoAoEscriturador:
        posIPOData?.comentariosSobreAEmissaoAoEscriturador,
      dataDeInicioDaNegociacao: timestampToDate(
        posIPOData?.dataDeInicioDaNegociacao,
      ),
      loteDeNegociacao: posIPOData?.loteDeNegociacao,
      porcentagemDaOfertaQueEVendaPrimaria:
        posIPOData?.porcentagemDaOfertaQueEVendaPrimaria,
      porcentagemDaOfertaQueEVendaSecundaria:
        posIPOData?.porcentagemDaOfertaQueEVendaSecundaria,
      porcentagemDeParticipacaoNaEmpresa:
        posIPOData?.porcentagemDeParticipacaoNaEmpresa,
      numeroDeTokensEmitidosParaOfertaPublica:
        posIPOData?.numeroDeTokensEmitidosParaOfertaPublica,
      precoUnitarioDoToken: posIPOData?.precoUnitarioDoToken,
      calendarioDaOferta: {
        dataDeConclusaoDaOfertaPublica: timestampToDate(
          posIPOData?.calendarioDaOferta.dataDeConclusaoDaOfertaPublica,
        ),
        disponibilizacaoDosMateriaisDeDivulgacao: timestampToDate(
          posIPOData?.calendarioDaOferta
            .disponibilizacaoDosMateriaisDeDivulgacao,
        ),
        inicioDoPeriodoDoPedidoDeReserva: timestampToDate(
          posIPOData?.calendarioDaOferta.inicioDoPeriodoDoPedidoDeReserva,
        ),
        fimDoPeriodoDoPedidoDeReserva: timestampToDate(
          posIPOData?.calendarioDaOferta.fimDoPeriodoDoPedidoDeReserva,
        ),
        dataDeLiquidacaoDaOferta: timestampToDate(
          posIPOData?.calendarioDaOferta.dataDeLiquidacaoDaOferta,
        ),
        dataDeNegociacaoDoPrimeiroLeilao: timestampToDate(
          posIPOData?.calendarioDaOferta.dataDeNegociacaoDoPrimeiroLeilao,
        ),
        dataDeInicioDasNegociacoes: timestampToDate(
          posIPOData?.calendarioDaOferta.dataDeInicioDasNegociacoes,
        ),
      },
    });
    setReadOnly(posIPOData?.isReadOnly ?? true);
    setGenerated(posIPOData?.documentoGerado ?? false);
  }, [posIPOData, reset]);

  const tipoIsTitulos = tipoDeAtivo == 3 || tipoDeAtivo == 4;

  return (
    <>
      <Info>
        <p>
          Após a Oferta Pública (IPO) ocorrer na Beegin, iremos confirmar ou
          atualizar caso os dados do valor total captado, participação vendida e
          data de início das negociações tenham sido diferentes do que foi
          estipulado. Em seguida, seu documento será gerado automaticamente e
          ficará disponível abaixo.
        </p>
      </Info>
      <FormSection onSubmit={handleSubmit(handleConfirmForm)}>
        <Container title="Resultado da oferta pública">
          <Grid container spacing={2}>
            <Grid item desktop={6}>
              <Controller
                name="montanteCaptado"
                label="Volume da oferta"
                type="input"
                mask="currency"
                disabled={isDisabled}
                control={control}
                maxLength={12}
              />
            </Grid>
            <Grid item desktop={6}>
              <Controller
                name="participacaoVendidaNaEmpresa"
                label="Participação vendida da empresa"
                type="input"
                mask="percentage"
                disabled={isDisabled}
                control={control}
              />
            </Grid>

            <Grid item desktop={6}>
              <Controller
                name="numeroDeAcoesOuTitulosEmitidosAposAOfertaPublica"
                control={control}
                label="Nº total de títulos/ações na oferta pública"
                type="input"
                mask="thousandSeparator"
                maxLength={15}
                disabled={isDisabled}
              />
            </Grid>
          </Grid>
        </Container>
        <Container title="Elementos essenciais da oferta">
          {/* currentRole === 'BEE4' && (
                  <Export>
                    <Button startIcon={<IosShareIcon />}>
                      Exportar como PDF
                    </Button>
                    <Button startIcon={<IosShareIcon />}>
                      Exportar como Excel
                    </Button>
                  </Export>
                ) */}
          <Grid container spacing={2}>
            <Grid item desktop={6}>
              <Controller
                name="codigoDeNegociacaoProposto"
                control={control}
                label="Código de negociação proposto (ticker)"
                type="input"
                disabled={isDisabled}
              />
            </Grid>
            <Grid item desktop={6}>
              <Controller
                name="codigoIsin"
                control={control}
                label="Código ISIN"
                type="input"
                disabled={isDisabled}
                description={
                  <>
                    Este código deve ser solicitado diretamente pelo site da B3.
                    <a
                      href="https://www.b3.com.br/pt_br/produtos-e-servicos/solucoes-para-emissores/suporte-emissores/geracao-de-isin/"
                      target="_blank"
                      rel="noreferrer"
                      style={{ color: '#2E4064' }}
                    >
                      Clique aqui
                    </a>{' '}
                    para ser redirecionado.
                  </>
                }
              />
            </Grid>
            <Grid item desktop={6}>
              <Controller
                name="dataDeEmissaoDoAtivoASerNegociadoNaBEE4"
                control={control}
                label="Data de emissão do ativo a ser negociado na BEE4"
                type="input"
                mask="date"
                placeholder="dd/mm/aaaa"
                disabled={isDisabled}
              />
            </Grid>
            <Grid item desktop={6}>
              <Controller
                name="loteAdicional"
                control={control}
                label="Lote adicional"
                type="select"
                options={listYesNo}
                disabled={isDisabled}
              />
            </Grid>
            <Grid item desktop={6}>
              <Controller
                name="qualOPercentual"
                control={control}
                label="Qual percentual?"
                type="input"
                mask="percentage"
                disabled={isDisabled || loteAdicional != 'true'}
              />
            </Grid>
            <Grid item desktop={6}>
              <Controller
                name="comentariosSobreAEmissaoAoEscriturador"
                control={control}
                type="input"
                textarea
                maxLength={1750}
                label="Comentários sobre a emissão ao escriturador"
                placeholder="Máx. 1750 caracteres"
                disabled={isDisabled}
              />
            </Grid>
          </Grid>
        </Container>
        <Container title="Informações sobre a 1ª negociação na BEE4">
          <Grid container spacing={5}>
            <Grid item desktop={3}>
              <Controller
                name="dataDeInicioDaNegociacao"
                control={control}
                label="Data de início da negociação"
                type="input"
                mask="date"
                placeholder="dd/mm/aaaa"
                disabled={isDisabled}
              />
            </Grid>
            <Grid item desktop={3}>
              <Controller
                name="loteDeNegociacao"
                control={control}
                label="Lote de negociação"
                type="input"
                mask="thousandSeparator"
                disabled={isDisabled}
              />
            </Grid>
            <Grid item desktop={3}>
              <Controller
                name="porcentagemDaOfertaQueEVendaPrimaria"
                control={control}
                label="% da oferta que é venda primária"
                type="input"
                mask="percentage"
                disabled={isDisabled}
              />
            </Grid>
            <Grid item desktop={3}>
              <Controller
                name="porcentagemDaOfertaQueEVendaSecundaria"
                control={control}
                label="% da oferta que é venda secundária"
                type="input"
                mask="percentage"
                disabled={isDisabled}
              />
            </Grid>
            {!tipoIsTitulos && (
              <Grid item desktop={3}>
                <Controller
                  name="porcentagemDeParticipacaoNaEmpresa"
                  control={control}
                  type="input"
                  label="% que a oferta representa da empresa"
                  mask="percentage"
                  min={0}
                  max={100}
                  disabled={isDisabled}
                  description="Este percentual significa o quão a oferta, somando o primário com o secundário, representa do total da empresa."
                />
              </Grid>
            )}
            <Grid item desktop={3}>
              <Controller
                name="numeroDeTokensEmitidosParaOfertaPublica"
                control={control}
                type="input"
                label="% de tokens emitidos (free float)"
                mask="percentage"
                disabled={isDisabled}
                description="% que os tokens a serem listados representam do capital da empresa."
              />
            </Grid>
            <Grid item desktop={3}>
              <Controller
                name="precoUnitarioDoToken"
                control={control}
                type="input"
                label="Preço unitário do token"
                mask="currency"
                disabled={isDisabled}
              />
            </Grid>
          </Grid>
        </Container>
        <Container title="Calendário">
          <Grid container spacing={5}>
            <Grid item desktop={3}>
              <Controller
                name="calendarioDaOferta.dataDeConclusaoDaOfertaPublica"
                control={control}
                type="input"
                mask="date"
                placeholder="dd/mm/aaaa"
                label="Data de conclusão da oferta pública"
                disabled={isDisabled}
              />
            </Grid>
            <Grid item desktop={3}>
              <Controller
                name="calendarioDaOferta.disponibilizacaoDosMateriaisDeDivulgacao"
                control={control}
                type="input"
                mask="date"
                placeholder="dd/mm/aaaa"
                label="Disponibilização dos materiais de divulgação"
                disabled={isDisabled}
              />
            </Grid>
            <Grid item desktop={3}>
              <Controller
                name="calendarioDaOferta.inicioDoPeriodoDoPedidoDeReserva"
                control={control}
                type="input"
                mask="date"
                placeholder="dd/mm/aaaa"
                label="Início do período do pedido de reserva"
                disabled={isDisabled}
              />
            </Grid>
            <Grid item desktop={3}>
              <Controller
                name="calendarioDaOferta.fimDoPeriodoDoPedidoDeReserva"
                control={control}
                type="input"
                mask="date"
                placeholder="dd/mm/aaaa"
                label="Fim do período do pedido de reserva"
                disabled={isDisabled}
              />
            </Grid>
            <Grid item desktop={3}>
              <Controller
                name="calendarioDaOferta.dataDeLiquidacaoDaOferta"
                control={control}
                type="input"
                mask="date"
                placeholder="dd/mm/aaaa"
                label="Data de liquidação da oferta"
                disabled={isDisabled}
              />
            </Grid>
            <Grid item desktop={3}>
              <Controller
                name="calendarioDaOferta.dataDeNegociacaoDoPrimeiroLeilao"
                control={control}
                type="input"
                mask="date"
                placeholder="dd/mm/aaaa"
                label="Anúncio das regras de negociação do primeiro leilão"
                disabled={isDisabled}
              />
            </Grid>
            <Grid item desktop={3}>
              <Controller
                name="calendarioDaOferta.dataDeInicioDasNegociacoes"
                control={control}
                type="input"
                mask="date"
                placeholder="dd/mm/aaaa"
                label="Início das negociações"
                disabled={isDisabled}
              />
            </Grid>
          </Grid>
        </Container>
        {profileCanUse && (
          <ButtonDiv>
            <Button
              height="40px"
              width="220px"
              fontSize="16px"
              disabled={isDisabled}
              type="submit"
            >
              CONFIRMAR DADOS
            </Button>
            {/* <Button
              height="40px"
              width="220px"
              fontSize="16px"
              onClick={() => setGenerated(true)}
              disabled={isDisabled || !isGenerated}
            >
              GERAR DOCUMENTO
        </Button> */}
          </ButtonDiv>
        )}
      </FormSection>
      {isGenerated && (
        <DocumentsDiv>
          <FileContainer>
            {documents?.map(doc => (
              <FileRow>
                <div>
                  <img src="images/pdf.svg" alt="" />
                  <span>{doc.nome}</span>
                </div>

                <Tooltip title="Download" placement="top">
                  <button type="button" onClick={() => handleDownload(doc.url)}>
                    <img src="images/download.svg" alt="Download" />
                  </button>
                </Tooltip>
              </FileRow>
            ))}
          </FileContainer>
        </DocumentsDiv>
      )}
    </>
  );
};
