import React, { useState } from 'react';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import styled from 'styled-components';
import {
  TableLine,
  Section,
  FirstSection,
  AccordionHeader,
  AccordionContentDetails,
  EditArchiveButton,
  CategoryDescription,
  EditArchiveButtonContainer,
  CheckMainButton,
} from '../../styles';
import { Container } from '../../../../components/container';
import { ArchiveDocumentModal } from './components/archiveModal';
import { Categories } from './fields';
import useCategoriaEmpresa from './hooks/useEmpresa';
import useCategoriaDiligencia from './hooks/useDiligencia';
import useCategoriaFinanceiro from './hooks/useFinanceiro';
import useCategoriaRelatorio from './hooks/useRelatorio';
import useCategoriaEmissao from './hooks/useEmissao';
import useCategoriaJuridico from './hooks/useJuridico';
import useCategoriaDocIPO from './hooks/useDocIpo';
import { AccordionCategorySection } from './components/accordionCategorySection';
import useCategoriaDocListagem from './hooks/useDocListagem';
import { OutletPermify } from '../../../../components/outletPermify';
import { Roles } from '../../../../router/roles.routes';

export const CategoriesArchiveData = ({
  leaveArchiveItems,
}: {
  leaveArchiveItems: () => void;
}) => {
  const { empresa, loadingEmpresa, archiveCategoriaEmpresa, isEmpresaSuccess } =
    useCategoriaEmpresa({ arquivado: true });

  const { emissao, loadingEmissao, archiveCategoriaEmissao, isEmissaoSuccess } =
    useCategoriaEmissao({ arquivado: true });

  const {
    juridico,
    loadingJuridico,
    archiveCategoriaJuridico,
    isJuridicoSuccess,
  } = useCategoriaJuridico({ arquivado: true });

  const { docIpo, loadingDocIpo, archiveCategoriaDocIpo, isDocIpoSuccess } =
    useCategoriaDocIPO({
      arquivado: true,
    });

  const {
    docListagem,
    loadingDocListagem,
    archiveCategoriaDocListagem,
    isDocListagemSuccess,
  } = useCategoriaDocListagem({
    arquivado: true,
  });

  const {
    diligencia,
    loadingDiligencia,
    archiveCategoriaDiligencia,
    isDiligenciaSuccess,
  } = useCategoriaDiligencia({ arquivado: true });

  const {
    financeiro,
    loadingFinanceiro,
    archiveCategoriaFinanceiro,
    isFinanceiroSuccess,
  } = useCategoriaFinanceiro({ arquivado: true });

  const {
    relatorio,
    loadingRelatorio,
    archiveCategoriaRelatorio,
    isRelatorioSuccess,
  } = useCategoriaRelatorio({ arquivado: true });

  const loading =
    loadingEmpresa ||
    loadingEmissao ||
    loadingJuridico ||
    loadingDocIpo ||
    loadingDiligencia ||
    loadingFinanceiro ||
    loadingDocListagem ||
    loadingRelatorio;

  const isSuccess =
    isEmpresaSuccess ||
    isEmissaoSuccess ||
    isJuridicoSuccess ||
    isDocIpoSuccess ||
    isDiligenciaSuccess ||
    isFinanceiroSuccess ||
    isDocListagemSuccess ||
    isRelatorioSuccess;

  const ArchiveIconButton = styled(UnarchiveIcon)`
    font-size: 20px;
  `;

  const ArrowIcon = styled(ArrowBackOutlinedIcon)`
    font-size: 16px;
  `;

  const [archiveModal, setArchiveModal] = useState(false);

  const handleStage = (stage: number) => {
    if (stage === 1) {
      return 'financeiro';
    }
    if (stage === 2) {
      return 'juridico';
    }
    if (stage === 3) {
      return 'diligencia';
    }
    if (stage === 4) {
      return 'empresa';
    }
    if (stage === 5) {
      return 'emissao';
    }
    if (stage === 6) {
      return 'docIpo';
    }
    if (stage === 7) {
      return 'relatorio';
    }
    if (stage === 9) {
      return 'DocumentacaoListagem';
    }
    return '';
  };

  const handleArchiveCategory = (category: any) => {
    const v = { id: category.id };
    const chosenStage = handleStage(category.etapa);

    if (chosenStage === 'diligencia') {
      return archiveCategoriaDiligencia(v);
    }
    if (chosenStage === 'emissao') {
      return archiveCategoriaEmissao(v);
    }
    if (chosenStage === 'relatorio') {
      return archiveCategoriaRelatorio(v);
    }
    if (chosenStage === 'juridico') {
      return archiveCategoriaJuridico(v);
    }
    if (chosenStage === 'financeiro') {
      return archiveCategoriaFinanceiro(v);
    }
    if (chosenStage === 'empresa') {
      return archiveCategoriaEmpresa(v);
    }
    if (chosenStage === 'docIpo') {
      return archiveCategoriaDocIpo(v);
    }
    if (chosenStage === 'DocumentacaoListagem') {
      return archiveCategoriaDocListagem(v);
    }

    return () => {};
  };

  const handleCategoryList = (categoryList: Categories[]) => {
    if (categoryList) {
      const final = categoryList.length - 1;
      return categoryList.map((category, index) => {
        return (
          <React.Fragment key={category.id}>
            <AccordionContentDetails>
              <CategoryDescription>
                {category.ordem}. {category.nome}
              </CategoryDescription>
              <EditArchiveButtonContainer>
                <OutletPermify role={[Roles.EditarCategoria]}>
                  <EditArchiveButton
                    onClick={() => {
                      setArchiveModal(true);
                      handleArchiveCategory(category);
                    }}
                  >
                    <ArchiveIconButton htmlColor="#212e51" />
                  </EditArchiveButton>
                </OutletPermify>
              </EditArchiveButtonContainer>
            </AccordionContentDetails>
            {final === index ? null : <TableLine />}
          </React.Fragment>
        );
      });
    }
    return <></>;
  };

  return (
    <>
      <CheckMainButton
        type="button"
        onClick={() => {
          leaveArchiveItems();
        }}
      >
        <ArrowIcon htmlColor="#2dbcf7" />
        Voltar
      </CheckMainButton>
      <Container title="Novos Campos" titleMarginBottom="7px">
        <FirstSection>
          <span>
            A Administração global adiciona itens a todas as empresas na qual o
            Tech Interno é cadastrado.
          </span>
        </FirstSection>

        <Section>
          <AccordionHeader>
            <span>Cadastro e diligência</span>
          </AccordionHeader>
          <AccordionCategorySection
            loading={loading}
            name="Dados gerais"
            description="Documentos"
          >
            {handleCategoryList(empresa)}
          </AccordionCategorySection>

          <TableLine />

          <AccordionCategorySection
            loading={loading}
            name="Documentação adicional"
            description="Documentos"
          >
            {handleCategoryList(financeiro)}
          </AccordionCategorySection>

          <TableLine />

          <AccordionCategorySection
            loading={loading}
            name="Jurídico"
            description="Documentos"
          >
            {handleCategoryList(juridico)}
          </AccordionCategorySection>

          <TableLine />

          <AccordionCategorySection
            loading={loading}
            name="Diligência"
            description="Documentos"
          >
            {handleCategoryList(diligencia)}
          </AccordionCategorySection>
        </Section>
        <Section>
          <AccordionHeader>
            <span>Sobre o ativo</span>
          </AccordionHeader>
          <AccordionCategorySection
            loading={loading}
            name="Sobre o ativo"
            description="Documentos"
          >
            {handleCategoryList(emissao)}
          </AccordionCategorySection>
        </Section>
        <Section>
          <AccordionHeader>
            <span>Doc. IPO</span>
          </AccordionHeader>
          <AccordionCategorySection
            loading={loading}
            name="Doc. IPO"
            description="Documentos"
          >
            {handleCategoryList(docIpo)}
          </AccordionCategorySection>
        </Section>

        <Section>
          <AccordionHeader>
            <span>Doc. Listagem</span>
          </AccordionHeader>
          <AccordionCategorySection
            loading={loading}
            name="Doc. Listagem"
            description="Documentos"
          >
            {handleCategoryList(docListagem)}
          </AccordionCategorySection>
        </Section>

        <Section>
          <AccordionHeader>
            <span>Relatórios</span>
          </AccordionHeader>
          <AccordionCategorySection
            loading={loading}
            name="Relatórios"
            description="Documentos"
          >
            {handleCategoryList(relatorio)}
          </AccordionCategorySection>
        </Section>

        <ArchiveDocumentModal
          description={archiveModal && isSuccess && !loading}
          label="Campo desarquivado com sucesso"
          closeDropdown={() => {
            setArchiveModal(false);
          }}
          text="Agora ele está disponível para edição na 'Administração global'"
        />

        <Section />
      </Container>
    </>
  );
};
