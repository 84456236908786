import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import {
  CommitteeDecisionContainer,
  DocumentListContent,
  DocumentListDescription,
  Header,
  Main,
  Date,
  TypeResult,
  Docs,
  Wrapper,
} from './styles';
import { useEnterprise } from '../../hooks/enterprise';

import { DocumentListIpo } from '../documentacaoIPO/components/documentListIpo';
import Carregando from '../../components/carregando';
import { DocumentListListagem } from '../documentacaoListagem/components/documentListListagem';
import { texto } from './fields';
import { useHistoricoDecisaoComite } from './hooks/useHistoricoDecisaoComite';
import { OutletPermify } from '../../components/outletPermify';
import { Roles } from '../../router/roles.routes';

export const HistoricoDecisaoComite = () => {
  const { currentEnterpriseName, currentEnterpriseId } = useEnterprise();
  const { historicoDecisaoComite }: any = useHistoricoDecisaoComite({
    currentEnterpriseId,
  });

  if (!historicoDecisaoComite) return <Carregando />;

  const obterTexto = (avaliacaoBEE4: 1 | 2 | 3 | 4 | undefined) =>
    texto(currentEnterpriseName, avaliacaoBEE4);

  const renderDocs = (aprovacaoBEE4: any, tipo: 'ipo' | 'listagem') => (
    <CommitteeDecisionContainer>
      <h2>{obterTexto(aprovacaoBEE4?.avaliacaoBEE4)[0]}</h2>
      <Date>
        {moment(aprovacaoBEE4?.notificacaoDaDecisaoDoComite).format(
          'DD/MM/YYYY',
        )}
      </Date>
      <TypeResult>
        <strong>Tipo de decisão: </strong>
        <span>Comitê</span>
      </TypeResult>
      <p>{obterTexto(aprovacaoBEE4?.avaliacaoBEE4)[1]}</p>
      <DocumentListContent>
        <DocumentListDescription>
          Faça o download dos documentos comprobatórios
        </DocumentListDescription>
        <OutletPermify role={[Roles.VisualizarListaDocGerados]}>
          {tipo === 'ipo' ? (
            <DocumentListIpo
              docList={aprovacaoBEE4?.documentosDoComite}
              isDisable
              page
            />
          ) : (
            <DocumentListListagem
              docList={aprovacaoBEE4?.documentosDoComite}
              isDisable
              page
            />
          )}
        </OutletPermify>
      </DocumentListContent>

      <Docs>
        <Link to="/historico-documentacao">
          <img className="fileIcon" src="images/emissaoIcon.svg" alt="" />
          Visualizar historico documento
        </Link>
      </Docs>
    </CommitteeDecisionContainer>
  );

  return (
    <Main>
      <Header>
        <h2>Decisão do Comitê</h2>
        <span>
          Veja o histórico de decisões tomadas pelo Comitê, além de baixar os
          documentos comprobatórios e ver o histórico dos documentos gerados
          pela sua empresa.
        </span>
      </Header>

      {!historicoDecisaoComite ||
        (!historicoDecisaoComite.aprovacaoBEE4 && (
          <Wrapper>
            <h3>A proposta ainda não foi analisada</h3>
            <div>
              <span>
                É necessário progredir no processo de listagem antes de chegar a
                uma decisão do Comitê.
              </span>
              <span>
                Preencha os formulários e faça os envios de todos os documentos.
              </span>
            </div>
          </Wrapper>
        ))}

      {!!historicoDecisaoComite && historicoDecisaoComite?.aprovacaoBEE4 && (
        <>
          {renderDocs(historicoDecisaoComite?.aprovacaoBEE4, 'ipo')}

          {!!historicoDecisaoComite &&
            !!historicoDecisaoComite?.listaDeAprovacaoAdicionalBEE4.length &&
            historicoDecisaoComite.listaDeAprovacaoAdicionalBEE4.map(
              (item: any) => renderDocs(item, 'ipo'),
            )}

          {!!historicoDecisaoComite &&
            !!historicoDecisaoComite?.listaDeAprovacaoAdicionalListagemBEE4
              .length &&
            historicoDecisaoComite.listaDeAprovacaoAdicionalListagemBEE4.map(
              (item: any) => renderDocs(item, 'listagem'),
            )}
        </>
      )}
    </Main>
  );
};
