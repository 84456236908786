import React from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { Modal } from '../../../../../../components/modal';
import { Buttons, ModalTitle } from '../../styles';
import { Button } from '../../../../../../components/button';
import { FormDataReport } from '../../hooks/useReportDates';
import { Controller } from '../../../../../../components/controller';
import useCompanies from '../../../../../../hooks/companies';
import { isValid } from '../../../../../../services/isValid';

interface IModalProps {
  description: boolean;
  closeDropdown: (e?: any) => void;
  createDate: (e: FormDataReport) => void;
}

const schema = yup.object().shape({
  empresaListadaNaBee4: yup
    .string()
    .test('validateDate', 'Data inválida', data => {
      return isValid.date(data);
    })
    .nullable(),
  empresa: yup.string().required('Campo obrigatório'),
});
export const CreateReportDateModal: React.FC<IModalProps> = ({
  description,
  closeDropdown,
  createDate,
}) => {
  const { handleSubmit, control } = useForm<any>({
    resolver: yupResolver(schema),
  });

  const { companies } = useCompanies({});

  const submitForm = (nomad: FormDataReport) => {
    createDate(nomad);
    closeDropdown();
  };

  const handleEnterprises = () => {
    return companies?.map((company: any) => {
      return {
        id: company.id,
        label: company.nome,
      };
    });
  };

  return (
    <Modal open={description} handleClose={closeDropdown} width={1200}>
      <form onSubmit={handleSubmit(submitForm)}>
        <ModalTitle>Adicionar uma data para uma empresa</ModalTitle>
        <div style={{ marginBottom: '1.5rem', display: 'flex' }}>
          <div style={{ width: '48.5%' }}>
            <Controller
              name="empresa"
              control={control}
              type="autocomplete"
              label="Empresa"
              autocompleteOptions={handleEnterprises() ?? []}
            />
          </div>
          <div style={{ width: '48.5%', marginLeft: 'auto' }}>
            <Controller
              name="empresaListadaNaBee4"
              control={control}
              type="input"
              label="Data"
              mask="date"
              placeholder="dd/mm/aaaa"
            />
          </div>
        </div>
        <Buttons>
          <Button
            width="103px"
            height="40px"
            variant="outlined"
            onClick={closeDropdown}
          >
            CANCELAR
          </Button>
          <Button type="submit" width="103px" height="40px">
            SALVAR
          </Button>
        </Buttons>
      </form>
    </Modal>
  );
};
