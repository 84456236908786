import styled from 'styled-components';
import { Container } from '../../components/container';

export const InputDiv = styled(Container)`
  height: 300px;
  background-color: #fff;
  .quill {
    .ql-editor {
      min-height: 100px;
    }
  }
`;

export const Title = styled.div`
  display: flex;
  flex-direction: column;
  gap: 9.5px;
  margin-bottom: 31.5px;
  span {
    font-size: 18px;
    font-weight: bold;
  }
  a {
    color: #000000;
    font-size: 12px;
    font-family: Inter;
  }
`;

export const ObligationsButton = styled.button`
  border: none;
  background: transparent;
  width: 282px;
  font-size: 12px;
  font-family: Inter;
  text-decoration: underline;
`;

export const BottomSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 20px;
`;

export const Uploads = styled.div`
  display: flex;
  gap: 10px;
`;

export const ButtonsWrapper = styled.div`
  align-items: end;
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  position: relative;
  bottom: 0;
`;

export const AttachmentButton = styled.button`
  height: 32px;
  padding: 0.3rem;
  width: fit-content;
  border: 0;
  border-radius: 4px;
  background-color: #fff;
  align-items: center;
  justify-content: center;
  display: flex;

  &:hover {
    background-color: #a4a7b433;
  }
`;

export const LinkIcon = styled.img`
  height: 24.95px;
  width: 24.95px;
`;

export const ImageIcon = styled.img`
  height: 15.22px;
  width: 15.23px;
`;

export const FileButtonContent = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;

  color: var(--azul-5, #060b7a);
  font-family: Georama;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.6px;

  img {
    width: 1rem;
    height: 1rem;
  }
`;

export const ClipIcon = styled.img`
  height: 15.22px;
  width: 14.61px;
`;

export const Divider = styled.div`
  height: 20px;
`;

export const EditArea = styled.div`
  height: calc(100px);
`;

export const EndEditorWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  flex-direction: column;
  gap: 2rem;
`;
