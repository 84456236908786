// @ts-nocheck
import React from 'react';
import { styled } from '@mui/material/styles';
import MaterialTooltip, {
  TooltipProps,
  tooltipClasses,
} from '@mui/material/Tooltip';

interface ITooltipProps {
  title: any;
  children: any;
  disabled?: boolean;
  maxWidth?: string;
  placement?:
    | 'bottom'
    | 'left'
    | 'right'
    | 'top'
    | 'bottom-end'
    | 'bottom-start'
    | 'left-end'
    | 'left-start'
    | 'right-end'
    | 'right-start'
    | 'top-end'
    | 'top-start';
  color?: 'primary' | 'secondary';
}

const CustomTooltip = styled(
  ({ className, color, ...props }: TooltipProps & ITooltipProps) => (
    <MaterialTooltip {...props} arrow classes={{ popper: className }} />
  ),
)(({ color }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: color === 'primary' ? '#131b30' : '#060B7A',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: color === 'primary' ? '#131b30' : '#060B7A',
    fontSize: '14px',
    padding: '15px',
    borderRadius: '0',
    zIndex: 5,
  },
}));

export const Tooltip = ({
  title,
  children,
  disabled,
  maxWidth,
  placement = 'bottom',
  color = 'primary',
}: ITooltipProps) => {
  if (disabled) return children;
  return (
    <CustomTooltip
      title={title}
      disableHoverListener={disabled}
      placement={placement}
      color={color}
    >
      {children}
    </CustomTooltip>
  );
};
