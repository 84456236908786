/* eslint-disable react/self-closing-comp */
import React, { useState, useEffect } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import Box from '@mui/material/Box';
import { yupResolver } from '@hookform/resolvers/yup';
import DeleteIcon from '@mui/icons-material/Delete';
import { useAuth } from '../../hooks/auth';
import { useEnterprise } from '../../hooks/enterprise';
import { dateToTimestamp, timestampToDate } from '../../utils/dateHandling';
import RenderSkeletonOrContent from '../../components/renderSkeletonOrContent';
import { Skeleton } from '../../components/skeleton';
import { Controller } from '../../components/controller';
import { Button } from '../../components/button';
import { Grid } from '../../components/grid';
import { Section } from '../../components/section';
import { Container } from '../../components/container';
import { listYesNo } from '../../utils/fields';

import {
  Content,
  Export,
  Title,
  TitleDiv,
  InnerTitle,
  ButtonsContainer,
  BoxSkeleton,
  DeleteButton,
} from './styles';

import { schema, listTipoDeAtivo, DadosEmissaoForm } from './fields';
import { DocumentList } from '../../components/documents';
import useSobreAtivo from './hooks/useSobreAtivo';

export const SobreOAtivo = () => {
  const { handleSubmit, control, reset, watch } = useForm<DadosEmissaoForm>({
    resolver: yupResolver(schema),
  });

  const { currentRole } = useAuth();
  const { currentEnterpriseId } = useEnterprise();

  const { sobreAtivo, updateSobreAtivo, isLoading } = useSobreAtivo({
    currentEnterpriseId,
  });

  const [isDisabled, setIsDisabled] = useState(false);

  const {
    tipoDeAtivo,
    dadosDaOfertaPublica,
    direitoDeTagAlong,
    direitoDeDragAlong,
    direitoAoVoto,
    oceoTemLockup,
    temControladores,
    oControladorTemLockup,
    osMembrosDoConselhoTemLockup,
  } = watch();

  const { houveMaisInstituicoesEnvolvidasNoProcesso, teveInvestidorLider } =
    dadosDaOfertaPublica || {};

  const tipoIsTitulos = tipoDeAtivo == 3 || tipoDeAtivo == 4;

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'instituicoes',
  });

  useEffect(() => {
    if (!sobreAtivo) {
      return;
    }

    reset({
      tipoDeAtivo: sobreAtivo?.tipoDeAtivo,
      numeroDeAcoesOuTitulosEmitidosNaOfertaPublica:
        sobreAtivo?.numeroDeAcoesOuTitulosEmitidosNaOfertaPublica,
      numeroDeAcoesOuTitulosEmitidosAposAOfertaPublica:
        sobreAtivo?.numeroDeAcoesOuTitulosEmitidosAposAOfertaPublica,
      direitoDeTagAlong: sobreAtivo?.direitoDeTagAlong?.toString(),
      direitoDeDragAlong: sobreAtivo?.direitoDeDragAlong?.toString(),
      direitoAoVoto: sobreAtivo?.direitoAoVoto?.toString(),
      explicacaoDoDireitoDeTagAlong: sobreAtivo?.explicacaoDoDireitoDeTagAlong,
      explicacaoDoDireitoDeDragAlong:
        sobreAtivo?.explicacaoDoDireitoDeDragAlong,
      explicacaoDoDireitoAoVoto: sobreAtivo?.explicacaoDoDireitoAoVoto,
      descricaoDasCondicoesPosConversao:
        sobreAtivo?.descricaoDasCondicoesPosConversao,
      propositoDaEntradaNoMercadoSecundario:
        sobreAtivo?.propositoDaEntradaNoMercadoSecundario,
      politicaDeDividendos: sobreAtivo?.politicaDeDividendos,
      participacaoPercentualPreEmissaoDoCEO:
        sobreAtivo?.participacaoPercentualPreEmissaoDoCEO,
      participacaoPercentualPosEmissaoDoCEO:
        sobreAtivo?.participacaoPercentualPosEmissaoDoCEO,
      oceoTemLockup: sobreAtivo?.oceoTemLockup?.toString(),
      quantosDiasAposEmissaoOCEOTemLockup:
        sobreAtivo?.quantosDiasAposEmissaoOCEOTemLockup,
      temControladores: sobreAtivo?.temControladores?.toString(),
      participacaoPercentualPreEmissaoDoControlador:
        sobreAtivo?.participacaoPercentualPreEmissaoDoControlador,
      participacaoPercentualPosEmissaoDoControlador:
        sobreAtivo?.participacaoPercentualPosEmissaoDoControlador,
      oControladorTemLockup: sobreAtivo?.oControladorTemLockup?.toString(),
      quantosDiasAposEmissaoOControladorTemLockup:
        sobreAtivo?.quantosDiasAposEmissaoOControladorTemLockup,
      osPrincipaisExecutivosSaoSocios:
        sobreAtivo?.osPrincipaisExecutivosSaoSocios?.toString(),
      participacaoPercentualDosControladoresPosConversaoEmBaseTotalmenteDiluida:
        sobreAtivo?.participacaoPercentualDosControladoresPosConversaoEmBaseTotalmenteDiluida,
      capitalSocialEmBaseTotalmenteDiluido:
        sobreAtivo?.capitalSocialEmBaseTotalmenteDiluido,
      totalDeAcoesDaEmpresa: sobreAtivo?.totalDeAcoesDaEmpresa,
      osPrincipaisExecutivosTemParticipacoes:
        sobreAtivo?.osPrincipaisExecutivosTemParticipacoes?.toString(),
      participacaoVendidaNaEmpresa: sobreAtivo?.participacaoVendidaNaEmpresa,
      osMembrosDoConselhoTemLockup:
        sobreAtivo?.osMembrosDoConselhoTemLockup?.toString(),
      quantosDiasAposEmissaoOsMembrosDoConselhoTemLockup:
        sobreAtivo?.quantosDiasAposEmissaoOsMembrosDoConselhoTemLockup,
      dadosDaOfertaPublica: {
        ...sobreAtivo?.dadosDaOfertaPublica,
        dataDeInicio: timestampToDate(
          sobreAtivo?.dadosDaOfertaPublica.dataDeInicio,
        ),
        dataDeEncerramento: timestampToDate(
          sobreAtivo?.dadosDaOfertaPublica.dataDeEncerramento,
        ),
        teveInvestidorLider:
          sobreAtivo?.dadosDaOfertaPublica.teveInvestidorLider?.toString(),
        houveMaisInstituicoesEnvolvidasNoProcesso:
          sobreAtivo?.dadosDaOfertaPublica.houveMaisInstituicoesEnvolvidasNoProcesso?.toString(),
      },
      instituicoes: sobreAtivo?.instituicoes,
      alertaDeRiscoDePerdaDoPrincipal:
        sobreAtivo?.alertaDeRiscoDePerdaDoPrincipal,
      alertaDeRiscoDePerdaDeFaltaDeLiquidez:
        sobreAtivo?.alertaDeRiscoDePerdaDeFaltaDeLiquidez,
      riscoDeDificuldadeDeEntendimento:
        sobreAtivo?.riscoDeDificuldadeDeEntendimento,
      riscosInerentesAoPerfilDeInvestimentoNaoMencionadosNosItensAnteriores:
        sobreAtivo?.riscosInerentesAoPerfilDeInvestimentoNaoMencionadosNosItensAnteriores,
      calendarioDaOferta: {
        dataDeInicioDaOfertaPublica: timestampToDate(
          sobreAtivo?.calendarioDaOferta.dataDeInicioDaOfertaPublica,
        ),
        previsaoDeEncerramentoDaOfertaPublica: timestampToDate(
          sobreAtivo?.calendarioDaOferta.previsaoDeEncerramentoDaOfertaPublica,
        ),
        dataDeInicioDoExercicioDoDireitoDePreferencia: timestampToDate(
          sobreAtivo?.calendarioDaOferta
            .dataDeInicioDoExercicioDoDireitoDePreferencia,
        ),
        dataDeFimDoExercicioDoDireitoDePreferencia: timestampToDate(
          sobreAtivo?.calendarioDaOferta
            .dataDeFimDoExercicioDoDireitoDePreferencia,
        ),
      },
    });

    if (sobreAtivo?.isReadOnly === true) {
      setIsDisabled(true);
    }
  }, [sobreAtivo, currentRole]);

  const saveChanges = (data: DadosEmissaoForm) => {
    updateSobreAtivo({
      ...data,
      dadosDaOfertaPublica: {
        ...data.dadosDaOfertaPublica,
        dataDeInicio: dateToTimestamp(data.dadosDaOfertaPublica.dataDeInicio),
        dataDeEncerramento: dateToTimestamp(
          data.dadosDaOfertaPublica.dataDeEncerramento,
        ),
      },
      calendarioDaOferta: {
        dataDeInicioDaOfertaPublica: dateToTimestamp(
          data.calendarioDaOferta.dataDeInicioDaOfertaPublica,
        ),
        previsaoDeEncerramentoDaOfertaPublica: dateToTimestamp(
          data.calendarioDaOferta.previsaoDeEncerramentoDaOfertaPublica,
        ),
        dataDeInicioDoExercicioDoDireitoDePreferencia: dateToTimestamp(
          data.calendarioDaOferta.dataDeInicioDoExercicioDoDireitoDePreferencia,
        ),
        dataDeFimDoExercicioDoDireitoDePreferencia: dateToTimestamp(
          data.calendarioDaOferta.dataDeFimDoExercicioDoDireitoDePreferencia,
        ),
        participacaoPercentualPreEmissaoDoControlador:
          temControladores != 'true'
            ? 0
            : data.participacaoPercentualPreEmissaoDoControlador,
        participacaoPercentualPosEmissaoDoControlador:
          temControladores != 'true'
            ? 0
            : data.participacaoPercentualPosEmissaoDoControlador,
        participacaoPercentualDosControladoresPosConversaoEmBaseTotalmenteDiluida:
          temControladores != 'true'
            ? 0
            : data.participacaoPercentualDosControladoresPosConversaoEmBaseTotalmenteDiluida,
        oControladorTemLockup:
          temControladores != 'true' ? undefined : data.oControladorTemLockup,
        quantosDiasAposEmissaoOCEOTemLockup:
          oceoTemLockup != 'true'
            ? ''
            : data.quantosDiasAposEmissaoOCEOTemLockup,
        explicacaoDoDireitoAoVoto:
          direitoAoVoto != 'true' ? '' : data.explicacaoDoDireitoAoVoto,
        explicacaoDoDireitoDeDragAlong:
          direitoDeDragAlong != 'true'
            ? undefined
            : data.explicacaoDoDireitoDeDragAlong,
        explicacaoDoDireitoDeTagAlong:
          direitoDeTagAlong != 'true'
            ? undefined
            : data.explicacaoDoDireitoDeTagAlong,
        quantosDiasAposEmissaoOControladorTemLockup:
          oControladorTemLockup != 'true'
            ? ''
            : data.quantosDiasAposEmissaoOControladorTemLockup,
        quantosDiasAposEmissaoOsMembrosDoConselhoTemLockup:
          osMembrosDoConselhoTemLockup != 'true'
            ? ''
            : data.quantosDiasAposEmissaoOsMembrosDoConselhoTemLockup,
        dadosDaOfertaPublica:
          teveInvestidorLider != 'true'
            ? {
                ...data.dadosDaOfertaPublica,
                nomeDoInvestidorLider: undefined,
                urlDoLinkedInDoInvestidorLider: undefined,
              }
            : data.dadosDaOfertaPublica,
      },
    });
  };

  const handleInstitutions = () => (
    <>
      {fields.map((item, index) => (
        <>
          <Grid item desktop={12}>
            <InnerTitle>Instituição {index + 1}</InnerTitle>
            {index > 0 && (
              <DeleteButton
                type="button"
                disabled={isDisabled}
                onClick={() => {
                  if (fields.length > 1) {
                    remove(index);
                  }
                }}
                // eslint-disable-next-line react/no-array-index-key
                key={index}
              >
                <DeleteIcon htmlColor="#212E51" />
              </DeleteButton>
            )}
          </Grid>
          <Grid item desktop={6}>
            <Controller
              name={`instituicoes.${index}.nome`}
              control={control}
              type="input"
              label="Nome"
              disabled={isDisabled}
            />
          </Grid>
          <Grid item desktop={3}>
            <Controller
              name={`instituicoes.${index}.contato`}
              control={control}
              type="input"
              mask="phone"
              label="Contato"
              disabled={isDisabled}
            />
          </Grid>
          <Grid item desktop={3}>
            <Controller
              name={`instituicoes.${index}.site`}
              control={control}
              type="input"
              label="Site"
              disabled={isDisabled}
            />
          </Grid>
          <Grid item desktop={12}>
            <Controller
              name={`instituicoes.${index}.papelDesempenhado`}
              control={control}
              type="input"
              label="Qual papel foi desempenhado?"
              placeholder="Máx. 700 caracteres"
              maxLength={700}
              textarea
            />
          </Grid>
        </>
      ))}
    </>
  );

  return (
    <Content>
      <RenderSkeletonOrContent
        isLoading={isLoading}
        content={
          <form onSubmit={handleSubmit(saveChanges)}>
            <Section>
              <Container title="Elementos essenciais da oferta">
                {/* currentRole === 'BEE4' && (
                  <Export>
                    <Button startIcon={<IosShareIcon />}>
                      Exportar como PDF
                    </Button>
                    <Button startIcon={<IosShareIcon />}>
                      Exportar como Excel
                    </Button>
                  </Export>
                ) */}
                <Grid container spacing={5}>
                  <Grid item desktop={6}>
                    <Controller
                      name="tipoDeAtivo"
                      control={control}
                      label="Tipo de ativo"
                      type="select"
                      disabled={isDisabled}
                      options={listTipoDeAtivo}
                    />
                  </Grid>
                </Grid>
              </Container>
            </Section>
            {/* <Section>
              <Container title="Informações sobre a primeira negociação no mercado secundário">
                <Grid container spacing={5}>
                  {!tipoIsTitulos && (
                    <>
                      <Grid item desktop={3}>
                        <Controller
                          name="direitoDeTagAlong"
                          control={control}
                          type="select"
                          label="Direito de Tag Along?"
                          disabled={isDisabled}
                          options={listYesNo}
                        />
                      </Grid>
                      <Grid item desktop={3}>
                        <Controller
                          name="direitoDeDragAlong"
                          control={control}
                          type="select"
                          label="Direito de Drag Along?"
                          disabled={isDisabled}
                          options={listYesNo}
                        />
                      </Grid>
                      <Grid item desktop={3}>
                        <Controller
                          name="direitoAoVoto"
                          control={control}
                          type="select"
                          label="Direito a voto?"
                          disabled={isDisabled}
                          options={listYesNo}
                        />
                      </Grid>
                      <Grid item desktop={6}>
                        <Controller
                          name="explicacaoDoDireitoDeTagAlong"
                          control={control}
                          type="input"
                          label="Explicação do direito de Tag Along"
                          placeholder="Máx. 400 caracteres"
                          maxLength={400}
                          disabled={
                            isDisabled || !(direitoDeTagAlong == 'true')
                          }
                          textarea
                        />
                      </Grid>
                      <Grid item desktop={6}>
                        <Controller
                          name="explicacaoDoDireitoDeDragAlong"
                          control={control}
                          type="input"
                          label="Explicação do direito de Drag Along"
                          placeholder="Máx. 400 caracteres"
                          maxLength={400}
                          disabled={
                            isDisabled || !(direitoDeDragAlong == 'true')
                          }
                          textarea
                        />
                      </Grid>
                      <Grid item desktop={6}>
                        <Controller
                          name="explicacaoDoDireitoAoVoto"
                          control={control}
                          type="input"
                          label="Explicação do direto a voto"
                          placeholder="Máx. 700 caracteres"
                          maxLength={700}
                          disabled={isDisabled || !(direitoAoVoto == 'true')}
                          textarea
                        />
                      </Grid>
                    </>
                  )}
                  {tipoIsTitulos && (
                    <Grid item desktop={6}>
                      <Controller
                        name="descricaoDasCondicoesPosConversao"
                        control={control}
                        type="input"
                        label="Descrição das condições pós-conversão"
                        placeholder="Máx. 700 caracteres"
                        maxLength={700}
                        disabled={isDisabled}
                        textarea
                      />
                    </Grid>
                  )}
                  <Grid item desktop={6}>
                    <Controller
                      name="propositoDaEntradaNoMercadoSecundario"
                      control={control}
                      type="input"
                      label="Propósito da entrada na BEE4"
                      placeholder="Máx. 700 caracteres"
                      disabled={isDisabled}
                      maxLength={700}
                      textarea
                    />
                  </Grid>
                  {!tipoIsTitulos && (
                    <Grid item desktop={12}>
                      <Controller
                        name="politicaDeDividendos"
                        control={control}
                        type="input"
                        label="Política de dividendos"
                        placeholder="Máx. 700 caracteres"
                        maxLength={700}
                        disabled={isDisabled}
                        textarea
                      />
                    </Grid>
                  )}
                  <Grid item desktop={3}>
                    <Controller
                      name="participacaoPercentualPreEmissaoDoCEO"
                      control={control}
                      type="input"
                      mask="percentage"
                      label="Part. (%) pré-emissão do CEO"
                      disabled={isDisabled}
                      min={0}
                      max={100}
                    />
                  </Grid>
                  <Grid item desktop={3}>
                    <Controller
                      name="participacaoPercentualPosEmissaoDoCEO"
                      control={control}
                      type="input"
                      mask="percentage"
                      label="Part. (%) pós-emissão do CEO"
                      min={0}
                      max={100}
                      disabled={isDisabled}
                    />
                  </Grid>
                  <Grid item desktop={3}>
                    <Controller
                      name="oceoTemLockup"
                      control={control}
                      type="select"
                      label="O CEO tem lockup?"
                      disabled={isDisabled}
                      options={listYesNo}
                    />
                  </Grid>
                  <Grid item desktop={3}>
                    <Controller
                      name="quantosDiasAposEmissaoOCEOTemLockup"
                      control={control}
                      label="Quanto tempo após a emissão?"
                      description="Indicar o período em dias, meses, semestres ou anos."
                      type="input"
                      disabled={isDisabled || !(oceoTemLockup == 'true')}
                    />
                  </Grid>

                  {temControladores == 'true' && (
                    <>
                      <Grid item desktop={3}>
                        <Controller
                          name="participacaoPercentualPreEmissaoDoControlador"
                          control={control}
                          type="input"
                          mask="percentage"
                          label="Part. (%) pré-emissão dos controladores"
                          min={0}
                          max={100}
                          disabled={isDisabled || !(temControladores == 'true')}
                        />
                      </Grid>
                      <Grid item desktop={3}>
                        <Controller
                          name="participacaoPercentualPosEmissaoDoControlador"
                          control={control}
                          type="input"
                          mask="percentage"
                          label="Part. (%) pós-emissão dos controladores"
                          min={0}
                          max={100}
                          disabled={isDisabled || !(temControladores == 'true')}
                        />
                      </Grid>
                      <Grid item desktop={3}>
                        <Controller
                          name="oControladorTemLockup"
                          control={control}
                          type="select"
                          label="Os controladores têm lockup?"
                          disabled={isDisabled || !(temControladores == 'true')}
                          options={listYesNo}
                        />
                      </Grid>
                      <Grid item desktop={3}>
                        <Controller
                          name="quantosDiasAposEmissaoOControladorTemLockup"
                          control={control}
                          label="Quanto tempo após a emissão?"
                          description="Indicar o período em dias, meses, semestres ou anos."
                          type="input"
                          disabled={
                            isDisabled || !(oControladorTemLockup == 'true')
                          }
                        />
                      </Grid>
                      <Grid item desktop={6}>
                        <Controller
                          name="participacaoPercentualDosControladoresPosConversaoEmBaseTotalmenteDiluida"
                          control={control}
                          label="Part. (%) dos controladores pós-conversão em base totalmente diluída (fully diluited)"
                          type="input"
                          mask="percentage"
                          min={0}
                          max={100}
                          disabled={isDisabled || !(temControladores == 'true')}
                        />
                      </Grid>
                    </>
                  )}
                  {tipoIsTitulos && (
                    <>
                      <Grid item desktop={6}>
                        <Controller
                          name="capitalSocialEmBaseTotalmenteDiluido"
                          control={control}
                          label="Cap table fully diluited (Capital social em base totalmente diluído)"
                          type="input"
                          mask="currency"
                          disabled={isDisabled}
                        />
                      </Grid>
                      <Grid item desktop={6}>
                        <Controller
                          name="totalDeAcoesDaEmpresa"
                          control={control}
                          label="Cap table fully diluited/Total de ações da empresa"
                          type="input"
                          mask="percentage"
                          disabled={isDisabled}
                        />
                      </Grid>
                      <Grid item desktop={6}>
                        <Controller
                          name="osPrincipaisExecutivosTemParticipacoes"
                          control={control}
                          label="Os principais executivos têm participações?"
                          type="select"
                          options={listYesNo}
                          disabled={isDisabled}
                        />
                      </Grid>
                    </>
                  )}
                  <Grid item desktop={3}>
                    <Controller
                      name="osMembrosDoConselhoTemLockup"
                      control={control}
                      type="select"
                      label="Os membros do conselho têm lockup?"
                      disabled={isDisabled}
                      options={listYesNo}
                    />
                  </Grid>
                  <Grid item desktop={3}>
                    <Controller
                      name="quantosDiasAposEmissaoOsMembrosDoConselhoTemLockup"
                      control={control}
                      label="Quanto tempo após a emissão?"
                      description="Indicar o período em dias, meses, semestres ou anos."
                      type="input"
                      disabled={
                        isDisabled || !(osMembrosDoConselhoTemLockup == 'true')
                      }
                    />
                  </Grid>
                </Grid>
              </Container>
            </Section> */}

            <Section>
              <Container title="Dados da oferta pública">
                <Grid container spacing={5}>
                  <Grid item desktop={3}>
                    <Controller
                      name="dadosDaOfertaPublica.dataDeInicio"
                      control={control}
                      type="input"
                      label="Data de início"
                      mask="date"
                      placeholder="dd/mm/aaaa"
                      disabled={isDisabled}
                    />
                  </Grid>
                  <Grid item desktop={3}>
                    <Controller
                      name="dadosDaOfertaPublica.dataDeEncerramento"
                      control={control}
                      type="input"
                      label="Data de encerramento"
                      mask="date"
                      placeholder="dd/mm/aaaa"
                      disabled={isDisabled}
                    />
                  </Grid>
                  <Grid item desktop={6}>
                    <Controller
                      name="dadosDaOfertaPublica.montanteCaptado"
                      control={control}
                      type="input"
                      label="Volume da oferta"
                      mask="currency"
                      disabled={isDisabled}
                      maxLength={12}
                    />
                  </Grid>
                  <Grid item desktop={12}>
                    <Grid container rowSpacing={2} columnSpacing={5}>
                      <Grid item desktop={12} style={{ marginBottom: '1rem' }}>
                        <InnerTitle>Nessa oferta:</InnerTitle>
                      </Grid>
                      <Grid item desktop={3}>
                        <Controller
                          name="dadosDaOfertaPublica.porcentagemDeVendaPrimaria"
                          control={control}
                          type="input"
                          label="% de venda primária"
                          mask="percentage"
                          disabled={isDisabled}
                        />
                      </Grid>
                      <Grid item desktop={3}>
                        <Controller
                          name="dadosDaOfertaPublica.porcentagemDeVendaSecundaria"
                          control={control}
                          type="input"
                          label="% de venda secundária"
                          mask="percentage"
                          disabled={isDisabled}
                        />
                      </Grid>
                      <Grid item desktop={6}>
                        <Controller
                          name="dadosDaOfertaPublica.porcentagemTotalDaEmpresaQueSeraOfertado"
                          control={control}
                          type="input"
                          label="% total da empresa que será ofertado"
                          mask="percentage"
                          disabled={isDisabled}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item desktop={12}>
                    {/* espaçamento */}
                  </Grid>

                  <Grid item desktop={6}>
                    <Grid container spacing={5} rowSpacing={7}>
                      <Grid item desktop={12}>
                        <Controller
                          name="dadosDaOfertaPublica.valuationPostMoneyDaEmpresa"
                          control={control}
                          type="input"
                          label="Valuation da empresa post-money"
                          mask="currency"
                          disabled={isDisabled}
                        />
                      </Grid>
                      <Grid item desktop={6}>
                        <Controller
                          name="dadosDaOfertaPublica.teveInvestidorLider"
                          control={control}
                          type="select"
                          label="Teve investidor líder?"
                          disabled={isDisabled}
                          options={listYesNo}
                        />
                      </Grid>
                      <Grid item desktop={6}>
                        <Controller
                          name="dadosDaOfertaPublica.nomeDoInvestidorLider"
                          control={control}
                          type="input"
                          label="Nome do investidor líder"
                          disabled={
                            isDisabled || !(teveInvestidorLider == 'true')
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item desktop={6}>
                    <Controller
                      name="dadosDaOfertaPublica.sobreAOferta"
                      control={control}
                      type="input"
                      label="Sobre a oferta"
                      textarea
                      placeholder="Máx. 700 caracteres podendo colocar link"
                      maxLength={700}
                      disabled={isDisabled}
                    />
                  </Grid>

                  <Grid item desktop={6}>
                    <Controller
                      name="dadosDaOfertaPublica.urlDoLinkedInDoInvestidorLider"
                      control={control}
                      type="input"
                      label="LinkedIn do investidor líder"
                      disabled={isDisabled || !(teveInvestidorLider == 'true')}
                    />
                  </Grid>

                  <Grid item desktop={6}>
                    <Controller
                      name="dadosDaOfertaPublica.houveMaisInstituicoesEnvolvidasNoProcesso"
                      control={control}
                      type="select"
                      label="Teve instituição(ões) participante(s) da distribuição dessa oferta?"
                      disabled={isDisabled}
                      options={listYesNo}
                    />
                  </Grid>

                  {houveMaisInstituicoesEnvolvidasNoProcesso === 'true' &&
                  !isDisabled ? (
                    <>
                      {handleInstitutions()}
                      <Grid item desktop={12}>
                        <Button
                          onClick={() => {
                            append({
                              nome: '',
                              contato: '',
                              site: '',
                              papelDesempenhado: '',
                            });
                          }}
                          width="12rem"
                          height="2.2rem"
                          type="button"
                          disabled={isDisabled}
                        >
                          + Adicionar instituição
                        </Button>
                      </Grid>
                    </>
                  ) : (
                    <></>
                  )}
                </Grid>
              </Container>
            </Section>

            {/* <Section>
              <Container title="Alertas">
                <Grid container spacing={5}>
                  <Grid item desktop={6}>
                    <Controller
                      name="alertaDeRiscoDePerdaDoPrincipal"
                      control={control}
                      type="input"
                      label="Risco de perda do principal"
                      placeholder="Máx. 400 caracteres"
                      maxLength={400}
                      disabled={isDisabled}
                      textarea
                    />
                  </Grid>
                  <Grid item desktop={6}>
                    <Controller
                      name="alertaDeRiscoDePerdaDeFaltaDeLiquidez"
                      control={control}
                      type="input"
                      label="Risco de falta de liquidez"
                      placeholder="Máx. 400 caracteres"
                      maxLength={400}
                      disabled={isDisabled}
                      textarea
                    />
                  </Grid>
                  <Grid item desktop={6}>
                    <Controller
                      name="riscoDeDificuldadeDeEntendimento"
                      control={control}
                      type="input"
                      label="Risco de dificuldade de entendimento"
                      placeholder="Máx. 400 caracteres"
                      maxLength={400}
                      disabled={isDisabled}
                      textarea
                    />
                  </Grid>
                  <Grid item desktop={6}>
                    <Controller
                      name="riscosInerentesAoPerfilDeInvestimentoNaoMencionadosNosItensAnteriores"
                      control={control}
                      type="input"
                      label="Riscos inerentes ao perfil de investimento não mencionados nos itens anteriores"
                      placeholder="Máx. 400 caracteres"
                      maxLength={400}
                      disabled={isDisabled}
                      textarea
                    />
                  </Grid>
                </Grid>
              </Container>
            </Section> */}

            <Section>
              <Container title="Calendário">
                <Grid container spacing={5}>
                  <Grid item desktop={3}>
                    <Controller
                      name="calendarioDaOferta.dataDeInicioDaOfertaPublica"
                      control={control}
                      type="input"
                      mask="date"
                      placeholder="dd/mm/aaaa"
                      label="Data de início da oferta pública"
                      disabled={isDisabled}
                    />
                  </Grid>
                  <Grid item desktop={3}>
                    <Controller
                      name="calendarioDaOferta.previsaoDeEncerramentoDaOfertaPublica"
                      control={control}
                      type="input"
                      mask="date"
                      placeholder="dd/mm/aaaa"
                      label="Previsão de fechamento da oferta pública"
                      disabled={isDisabled}
                    />
                  </Grid>
                  <Grid item desktop={3}>
                    <Controller
                      name="calendarioDaOferta.dataDeInicioDoExercicioDoDireitoDePreferencia"
                      control={control}
                      type="input"
                      mask="date"
                      placeholder="dd/mm/aaaa"
                      label="Data de início do exercício do direito de preferência"
                      disabled={isDisabled}
                    />
                  </Grid>
                  <Grid item desktop={3}>
                    <Controller
                      name="calendarioDaOferta.dataDeFimDoExercicioDoDireitoDePreferencia"
                      control={control}
                      type="input"
                      mask="date"
                      placeholder="dd/mm/aaaa"
                      label="Data de fim do exercício do direito de preferência"
                      disabled={isDisabled}
                    />
                  </Grid>
                </Grid>
              </Container>
            </Section>

            <Section>
              <DocumentList stage="emissao" readOnly={isDisabled} />
            </Section>
            {!isDisabled &&
              ['Empreendedor', 'NOMAD', 'NOMADBOSS', 'TECHTERCEIRO'].includes(
                currentRole,
              ) && (
                <ButtonsContainer>
                  <Button type="submit" width="145px">
                    SALVAR
                  </Button>
                </ButtonsContainer>
              )}
          </form>
        }
        fallback={
          <>
            <Box
              width="100%"
              gap="24px"
              display="flex"
              marginBottom={1}
              marginTop={7}
            >
              <Skeleton width="30%" height="50px" variant="text" />
            </Box>
            <BoxSkeleton>
              <Box width="100%" display="flex" flexDirection="column">
                <Box width="100%" display="flex" gap="24px">
                  <Skeleton width="48%" height="100px" variant="text" />
                  <Skeleton width="49%" height="100px" variant="text" />
                  <Skeleton width="100%" height="100px" variant="text" />
                </Box>
                <Box width="100%" display="flex" gap="24px">
                  <Skeleton width="48%" height="100px" variant="text" />
                  <Skeleton width="49%" height="100px" variant="text" />
                  <Skeleton width="100%" height="100px" variant="text" />
                </Box>
                <Box width="100%" display="flex" gap="24px">
                  <Skeleton width="48%" height="100px" variant="text" />
                  <Skeleton width="49%" height="100px" variant="text" />
                  <Skeleton width="100%" height="100px" variant="text" />
                </Box>
                <Box width="100%" display="flex" gap="24px">
                  <Skeleton width="48%" height="100px" variant="text" />
                  <Skeleton width="49%" height="100px" variant="text" />
                  <Skeleton width="100%" height="100px" variant="text" />
                </Box>
                <Box width="100%" display="flex" gap="24px">
                  <Skeleton width="100%" height="100px" variant="text" />
                  <Skeleton width="100%" height="100px" variant="text" />
                </Box>
                <Box width="100%" display="flex" gap="24px">
                  <Skeleton width="48%" height="100px" variant="text" />
                  <Skeleton width="49%" height="100px" variant="text" />
                  <Skeleton width="100%" height="100px" variant="text" />
                </Box>
              </Box>
            </BoxSkeleton>
          </>
        }
      />
    </Content>
  );
};
