import React from 'react';
import { useForm } from 'react-hook-form';
import { Button } from '../../../../../../components/button';
import { Modal } from '../../../../../../components/modal';
import { Buttons, DeleteForm, ModalContent } from '../../styles';
import { Controller } from '../../../../../../components/controller';

interface IButtonProps {
  description: boolean;
  closeDropdown: (e?: any) => void;
  companyId?: string;
  companyType?: 'nomad' | 'escriturador' | 'auditor';
  deleteCompany: (v: any) => void;
  status: number;
  ativo?: boolean;
}
export const DeleteCompanyModal: React.FC<IButtonProps> = ({
  description,
  closeDropdown,
  companyId,
  companyType,
  deleteCompany,
  status,
  ativo,
}) => {
  const handleDelete = (e: any) => {
    if (companyType === 'nomad') {
      deleteCompany({ ...e, id: companyId });
      closeDropdown();

      return;
    }

    deleteCompany(companyId);
    closeDropdown();
  };

  const { handleSubmit, control } = useForm<any>({});

  const companyTypeDescription =
    companyType === 'escriturador'
      ? ' Escriturador'
      : companyType === 'auditor'
      ? ' Auditor'
      : ' Consultor de Listagem';

  return (
    <Modal open={description} handleClose={closeDropdown} width={480}>
      <DeleteForm onSubmit={handleSubmit(handleDelete)}>
        {(status === 1 && ativo) ||
        (companyType !== 'nomad' && status === 1 && !ativo) ? (
          <>
            <h3>
              {companyType === 'nomad' ? 'Desativar' : 'Deletar'}
              {companyTypeDescription}?
            </h3>
            {companyType === 'nomad' && (
              <div className="descriptionDeleteModal">
                <span>
                  Indique o motivo pelo qual deseja desativar esta empresa de
                  Consultores de Listagem.
                </span>
                <span>
                  Lembrando que ao desativar, todos os consultores, tanto
                  responsável quanto seus colaboradores, perderão acesso ao
                  sistema e seus Emissores ficarão sem consultor até que você
                  vincule com outro.
                </span>
                <span>
                  Os dados inseridos no sistema por estes usuários ficarão
                  salvos e todo histórico será mantido.
                </span>
              </div>
            )}
            {companyType === 'auditor' && (
              <div className="descriptionDeleteModal">
                <span>
                  Indique o motivo pelo qual deseja deletar esta Auditoria.
                </span>
                <span>
                  Ao excluir, os dados da Auditoria continuará aparecendo para
                  os Emissores que já foram vinculados com ele, mas não será
                  possível vincular com outros Emissores.
                </span>
              </div>
            )}
            {companyType === 'escriturador' && (
              <div className="descriptionDeleteModal">
                <span>
                  Indique o motivo pelo qual deseja deletar este Escriturador.
                </span>
                <span>
                  Ao excluir, os dados do Escriturador continuará aparecendo
                  para os Emissores que já foram vinculados com ele, mas não
                  será possível vincular com outros Emissores.
                </span>
              </div>
            )}
            <Controller
              name="motivoExclucao"
              control={control}
              type="input"
              label="Insira o motivo"
              textarea
            />
          </>
        ) : (
          <h3>
            Deseja realmente{' '}
            {companyType === 'nomad'
              ? ativo
                ? 'Desativar'
                : 'Ativar'
              : 'Excluir'}{' '}
            este {companyTypeDescription}?
          </h3>
        )}

        <div className="deleteButtons">
          <Button
            type="button"
            variant="outlined"
            width="96px"
            onClick={closeDropdown}
          >
            CANCELAR
          </Button>
          <Button type="submit" width="96px">
            {companyType === 'nomad'
              ? ativo
                ? 'DESATIVAR'
                : 'ATIVAR'
              : 'DELETAR'}
          </Button>
        </div>
      </DeleteForm>
    </Modal>
  );
};
