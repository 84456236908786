import React from 'react';
import { useForm } from 'react-hook-form';
import { Button } from '../../../components/button';
import { Modal } from '../../../components/modal';
import { DeleteButtons, ModalContent, ModalContentForm } from '../styles';
import { Controller } from '../../../components/controller';

interface IButtonProps {
  description: boolean;
  closeDropdown: (e?: any) => void;
  companyId?: string;
  consulterId?: string;
  deleteConsulter: (e: any) => void;
  status: number;
  ativo: boolean;
}
export const DeleteCollaboratorModal: React.FC<IButtonProps> = ({
  description,
  closeDropdown,
  companyId,
  consulterId,
  deleteConsulter,
  status,
  ativo,
}) => {
  const handleDelete = (e: any) => {
    deleteConsulter({ ...e, companyId, consulterId });
    closeDropdown();
  };

  const { handleSubmit, control } = useForm<any>({});

  return (
    <Modal open={description} handleClose={closeDropdown} width={500}>
      <ModalContentForm onSubmit={handleSubmit(handleDelete)}>
        {status === 1 && ativo ? (
          <>
            <h3>Desativar colaborador?</h3>

            <div className="descriptionDeleteModal">
              <span>
                Indique o motivo pelo qual deseja desativar este(a)
                Colaborador(a).
              </span>
              <span>
                Lembrando que ao desativar, o(a) Colaborador(a) perderá acesso
                ao sistema, mas os dados e documentos inseridos ficarão salvos e
                todo histórico será mantido.
              </span>

              <Controller
                name="motivoExclucao"
                control={control}
                type="input"
                label="Insira o motivo"
                textarea
              />
            </div>
          </>
        ) : (
          <h3>
            Deseja realmente {ativo ? 'desativar' : 'ativar'} este colaborador?
          </h3>
        )}

        <DeleteButtons>
          <Button
            type="button"
            variant="outlined"
            width="96px"
            onClick={closeDropdown}
          >
            CANCELAR
          </Button>
          <Button type="submit" width="96px" variant="outlined">
            {ativo ? 'DESATIVAR' : 'ATIVAR'}
          </Button>
        </DeleteButtons>
      </ModalContentForm>
    </Modal>
  );
};
