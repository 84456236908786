import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  align-items: center;
  background-color: #f3f4f5;
  color: #171725;
  cursor: pointer;
  border: none;
  outline: none;
  transition: background-color 0.6s ease;
  border-radius: 8px;
  padding: 0.7rem 2rem;
  width: 100%;

  & > img {
    height: 6px;
    width: 10px;
  }
`;

export const Title = styled.h3`
  font-weight: 500;
  font-size: 10px;
`;

export const Subtitle = styled.span`
  font-size: 10px;
  font-weight: 500;
  color: #424242;
  margin-left: 12px;
`;

export const Buttons = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  position: relative;

  .deleteEditButton {
    margin-right: 1rem;
    border: none;
    background: none;
  }

  .deleteEditButton:hover {
    background: #cbcbcb 0% 0% no-repeat padding-box !important;
    opacity: 1;
    border-radius: 8px;
  }

  .plus {
    font-size: 1.3rem;
    margin-right: 0.4rem;
  }

  .uploadIcon {
    width: 1rem;
    margin-right: 0.6rem;
  }
`;

export const TooltipDiv = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
`;
