import React, { useState } from 'react';
import { Nomads } from './components/companies';

import { Content } from './styles';
import { ReportDate } from './components/reportDates';
import { CategoriesData } from './components/categories';
import { CategoriesArchiveData } from './components/categories/archive';
import { ArchiveData } from './components/companies/archive';

export const AdministracaoGlobal = () => {
  const [archiveItems, setArchiveItems] = useState(false);
  const [archiveAuditoriaItems, setArchiveAuditoriaItems] = useState(false);
  const [archiveEscrituradorItems, setArchiveEscrituradorItems] =
    useState(false);

  return (
    <Content>
      {archiveItems ? (
        <CategoriesArchiveData
          leaveArchiveItems={() => {
            setArchiveItems(false);
          }}
        />
      ) : (
        <>
          {archiveAuditoriaItems || archiveEscrituradorItems ? (
            <ArchiveData
              leaveArchiveItems={() => {
                setArchiveAuditoriaItems(false);
                setArchiveEscrituradorItems(false);
              }}
              type={archiveAuditoriaItems ? 'auditor' : 'escriturador'}
            />
          ) : (
            <>
              <CategoriesData
                checkArchiveItems={() => {
                  setArchiveItems(true);
                }}
              />

              <ReportDate />

              <Nomads
                checkArchiveAuditoriaItems={() => {
                  setArchiveAuditoriaItems(true);
                }}
                checkArchiveEscrituradorItems={() => {
                  setArchiveEscrituradorItems(true);
                }}
              />
            </>
          )}
        </>
      )}
    </Content>
  );
};
