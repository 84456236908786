import React from 'react';
import { useNavigate } from 'react-router-dom';
import { RedirectContainer } from '../../components/redirectContainer';

export const SemConta = () => {
  const navigate = useNavigate();

  return (
    <RedirectContainer
      title="Sua conta foi deletada"
      description={
        <span style={{ marginBottom: '2rem' }}>
          Você não possui mais acesso ao sistema de emissores.
        </span>
      }
      buttonDescription="Sair"
      onClick={() => navigate('/sair')}
    />
  );
};
