import styled from 'styled-components';

interface ContentProps {
  status?: number;
}

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  p {
    font-weight: 600;
    color: white;
  }
`;

export const IndexColumns = styled.div`
  width: 100%;
  color: #171725;
  text-align: left;
  text-transform: uppercase;
  letter-spacing: 1.2px;
  display: flex;
  font-weight: 600;
  font-size: 12px;
  height: 48px;
  padding: 0 16px;
  margin: auto;
  margin-top: 3rem;
`;

export const FirstColumn = styled.div`
  width: 30%;
  display: flex;
  align-items: center;
`;
export const SecondColumn = styled.div`
  width: 25%;
  display: flex;
  align-items: center;
`;
export const ThirdColumn = styled.div`
  width: 25%;
  display: flex;
  align-items: center;
`;

export const FourthColumn = styled.div`
  width: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const FifthColumn = styled.div`
  width: 10%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const ModalTitle = styled.h3`
  display: flex;
  justify-content: start;
  margin: 1rem 0 1.5rem 0;
`;
export const ModalDescription = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: rgb(69, 75, 98);
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;
  gap: 1rem;
`;

export const DeleteButtons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1.5rem;
  width: 80%;
`;

export const ContentColumns = styled.div`
  width: 100%;
  text-align: left;
  display: block;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.17px;
  margin: auto;

  & > span {
  }
`;

export const TableRow = styled.div`
  display: flex;
  height: 48px;
  padding: 0 16px;
  font-weight: 400;
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 0;
  h3 {
    font-weight: 400;
    margin-bottom: 0.7rem;
  }
`;

export const Status = styled.div<ContentProps>`
  background-color: ${props => (props.status !== 1 ? '#A4A7B4' : '#0852DD')};
  border-radius: 28px;
  padding: 5px 10px;
  color: white;
  min-width: 118px;
  text-align: center;
`;

export const ModalContentForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;

  h3 {
    font-size: 1rem;
    font-weight: 600;
  }

  .deleteButtons {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    width: 216px;
  }

  .descriptionDeleteModal {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
`;
