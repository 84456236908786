import React from 'react';
import { useForm } from 'react-hook-form';
import { Modal } from '../../modal';
import { Button } from '../../button';
import { ModalButtons, ModalForm } from './styles';
import { StatusChangePayload } from '../fields';

interface IModalProps {
  description: boolean;
  closeDropdown: (e?: any) => void;
  onStatusChange: (e?: StatusChangePayload) => void;
  statusObject?: StatusChangePayload;
}

export const VerifyDocModal: React.FC<IModalProps> = ({
  description,
  closeDropdown,
  onStatusChange,
  statusObject,
}) => {
  const { handleSubmit } = useForm<any>({});

  const submitForm = () => {
    onStatusChange({ ...statusObject! });
    closeDropdown();
  };
  return (
    <Modal open={description} handleClose={closeDropdown} width={500}>
      <ModalForm onSubmit={handleSubmit(submitForm)}>
        <h3>Documento verificado</h3>
        <p>
          Verificando este documento, você estaria confirmando que com base na
          sua análise e no seu melhor conhecimento, determina que o mesmo está
          validado e as informações são verdadeiras, válidas e corretas.
        </p>

        <ModalButtons>
          <Button
            variant="outlined"
            color="secondary"
            onClick={closeDropdown}
            width="157px"
            height="40px"
          >
            VOLTAR
          </Button>
          <Button width="157px" height="40px" type="submit">
            CONFIRMADO
          </Button>
        </ModalButtons>
      </ModalForm>
    </Modal>
  );
};
