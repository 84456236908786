import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ManualUtilizacaoEmissor } from '../pages/termos/manual';
import { TermoParceiro } from '../pages/termos/terceiro';
import { TermoCL } from '../pages/termos/nomadBoss';
import { TermoEmissor } from '../pages/termos/emissor';
import { TermoCLColaborador } from '../pages/termos/nomad';

export const RouterWithoutToken = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/manual-emissor" element={<ManualUtilizacaoEmissor />} />
        <Route path="/termo-parceiro" element={<TermoParceiro />} />
        <Route path="/termo-cl" element={<TermoCL />} />
        <Route path="/termo-emissor" element={<TermoEmissor />} />
        <Route path="/termo-cl-colaborador" element={<TermoCLColaborador />} />
      </Routes>
    </BrowserRouter>
  );
};
