import styled from 'styled-components';

export const DownloadDocsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 2rem;
`;

export const DownloadDocsDescription = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const Wrapper = styled.section`
  border-top: 1px solid #8c8f9a;
`;
