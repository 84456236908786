import React, { useState } from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  CommitteeDecisionButton,
  CommitteeDecisionContent,
  OrientationButton,
  OrientationContainer,
  OrientationContent,
} from './style';
import { GenerateListagemModal } from '../generateListagemModal';
import { CategoryListagem } from '../../fields';
import { useAuth } from '../../../../hooks/auth';

type OrientationIpoProps = {
  status: number;
  documentacaoListagem?: CategoryListagem;
};

export const OrientationListagem = ({
  status,
  documentacaoListagem,
}: OrientationIpoProps) => {
  const [oritentationModal, setOrientationModal] = useState(false);
  const { currentRole } = useAuth();

  const aprovacaoBEE4 = documentacaoListagem?.aprovacaoBEE4;
  const { listaDeAprovacaoAdicionalListagemBEE4 } = documentacaoListagem || {
    listaDeAprovacaoAdicionalListagemBEE4: [],
  };

  const showCommitteeDecision =
    documentacaoListagem?.statusDocumentacaoListagem === 4 &&
    currentRole !== 'BEE4';

  return (
    <>
      <OrientationContainer>
        {showCommitteeDecision && (
          <CommitteeDecisionContent>
            {/* <h3>Decisão do Comitê</h3> */}
            <CommitteeDecisionButton>
              <InfoOutlinedIcon />
              <strong>
                Os documentos foram enviados para análise da BEE4.
              </strong>
              <span>
                Em breve você será notificado sobre a decisão e próximos passos.
              </span>
            </CommitteeDecisionButton>
          </CommitteeDecisionContent>
        )}
        {currentRole === 'TECHINTERNO' && status === 2 ? (
          <h3>Upload dos documentos para validação</h3>
        ) : currentRole === 'BEE4' && status === 4 ? (
          <h3>Insira a análise do Comitê</h3>
        ) : currentRole === 'NOMADBOSS' ? (
          <h3>Validar documentos</h3>
        ) : (
          <h3>Validação de documentos</h3>
        )}
        <OrientationContent>
          <>
            {currentRole === 'TECHINTERNO' && status === 2 ? (
              <p>
                É necessário realizar o upload dos documentos em destaque
                abaixo, para que eles sejam disponibilizados para o Emissor e
                Consultores de Listagem Colaborador e Responsável.
              </p>
            ) : currentRole === 'BEE4' && status === 4 ? (
              <p>
                Na lista abaixo, é possível fazer download dos documentos e
                inserir a análise e comentários do Comitê. Após definir todos os
                itens, é possível informar a decisão do comitê ao Emissor e seus
                Consultores.
              </p>
            ) : currentRole === 'NOMADBOSS' ? (
              <p>
                Esta é uma lista com todos os documentos do processo de
                listagem. Agora é o momento de observar o preenchimento dos
                dados e validar a veracidade e coerência de todas as informações
                registradas no sistema. Se necessário, solicite revisões e tire
                dúvidas com nossa equipe.
              </p>
            ) : (
              <p>
                Parabéns, sua empresa foi aprovada no processo de listagem na
                BEE4! No entanto, ainda há documentos a serem entregues ou
                revisados. Abaixo listamos quais pendências são necessárias
                serem resolvidas para que seu processo de listagem possa
                efetivamente ser concluído.
              </p>
            )}
            <OrientationButton
              type="button"
              onClick={() => {
                setOrientationModal(true);
              }}
            >
              <img src="images/comment-icon.svg" alt="" />
              <p>
                Visite as orientações para entender as definições de status.
                <strong className="blue"> &rarr;</strong>
              </p>
            </OrientationButton>
          </>
        </OrientationContent>
        <GenerateListagemModal
          description={oritentationModal}
          closeDropdown={() => {
            setOrientationModal(false);
          }}
        />
      </OrientationContainer>
    </>
  );
};
