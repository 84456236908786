import styled from 'styled-components';

interface IListItemProps {
  status: number;
}

export const StatusContainer = styled.div<IListItemProps>`
  border: ${({ status }) => (status === 3 && '1px solid #c3c6cf') || 'none'};
  padding: 4px 16px;
  width: fit-content;
  border-radius: 30px;
  background: ${({ status }) =>
    (status === 1 && '#E7E7E7') ||
    (status === 2 && '#FFCC4A') ||
    (status === 3 && '#FFFFFF') ||
    (status === 5 && '#0852DD')};
  p {
    color: ${({ status }) => (status === 5 && '#FFFFFF') || '#565656'};
    font-size: 0.75rem;
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  text-align: center;
`;

export const IndexDescription = styled.p`
  color: #7d8ea3;
  font-size: 0.75rem;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const ContentDescription = styled.p`
  color: #7d8ea3;
  font-size: 0.875rem;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const SelectStatusLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const SelectStatusLabelCircle = styled.div<IListItemProps>`
  border: ${({ status }) => (status === 3 && '1px solid #c3c6cf') || 'none'};
  height: 10px;
  width: 10px;
  border-radius: 50px;
  background: ${({ status }) =>
    (status === 1 && '#E7E7E7') ||
    (status === 2 && '#FFCC4A') ||
    (status === 3 && '#FFFFFF') ||
    (status === 5 && '#0852DD')};
`;

export const SelectContainer = styled.div`
  & > div {
    display: flex;
    width: 100%;
    align-items: flex-end;
  }
`;

export const Table = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  min-width: 700px;
  border: none;
  align-items: center;
  border-radius: 4px;
  overflow-x: auto;
  border: none;
  color: rgb(125, 142, 163);
  font-size: 0.875rem;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const TableHead = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5rem;
  background-color: rgb(249, 249, 249);
  width: 100%;
  gap: 0.5rem;
  justify-content: space-between;
  .tableCell-1 {
    width: 37%;
    justify-content: flex-start;
  }
  .tableCell-2 {
    width: 25%;
    justify-content: flex-end;
  }
  .tableCell-3 {
    width: 30%;
    justify-content: center;
  }
  .tableCell-4 {
    width: 8%;
    justify-content: flex-end;
  }
`;

export const TableCell = styled.div`
  padding: 8px;
  display: flex;
  border: none;
`;

export const TableContainer = styled.div`
  display
`;

export const TableRow = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5rem;
  background-color: '#f9f9f9';
  width: 100%;
  gap: 0.5rem;

  justify-content: space-between;
  &:nth-of-type(odd) {
    background-color: #eff1f8;
  }

  &:nth-of-type(even) {
    background-color: #f9f9f9;
  }
  .tableCell-1 {
    width: 37%;
    justify-content: flex-start;
  }
  .tableCell-2 {
    width: 25%;
    justify-content: flex-end;
  }
  .tableCell-3 {
    width: 30%;
    justify-content: center;
  }
  .tableCell-4 {
    width: 8%;
    justify-content: flex-end;
  }
`;

export const TableBody = styled.div`
  width: 100%;
`;
