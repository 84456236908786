export const roles: { [key: string]: string } = {
  'Tech.Empreendedor': 'Empreendedor',
  'Tech.Nomad': 'NOMAD',
  'Tech.Nomad.Boss': 'NOMADBOSS',
  'Tech.Admin': 'TECHINTERNO',
  'Tech.CVM': 'CVM',
  'Tech.BEE4': 'BEE4',
  'Tech.Terceiro': 'TECHTERCEIRO',
  'Tech.Empreendedor.Colaborador': 'TECHCOLABORADOR',
  'Tech.Supervisor': 'TECHSUPERVISOR',
};

export const validarRoles = (
  todasPermissoes: string[],
  permissoesDaFuncionalidade: string[],
) => {
  let hasPermission = false;

  permissoesDaFuncionalidade.forEach(item => {
    if (!hasPermission)
      hasPermission = todasPermissoes.filter(role => role === item).length > 0;
  });

  return hasPermission || permissoesDaFuncionalidade.length === 0;
};
