import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useEnterprise } from '../../hooks/enterprise';
import { statusCompleted } from '../dashboardListagem/fields';
import { DashboardProgress } from '../../components/dashboardProgress';
import { OrientationIPO } from './components/orientationIpo';
import { ButtonContainer, DocIpoContent, Wrapper } from './styles';
import { EmptyDocState } from './components/emptyDocState';
import { CategoriesTable } from '../../components/categoriesTable';
import useIPO from './hooks/useIpo';
import { LoadingDashboard } from '../dashboardListagem/loading';
import { Button } from '../../components/button';
import { useAuth } from '../../hooks/auth';
import { ClSendIpoModal } from './components/clSendIpoModal';
import { ShouldRenderChildren } from '../../components/shouldRenderChildren';
import { CommitteeDecision } from './components/committeeDecision';
import { verifyError } from '../../utils/verifyError';
import { DownloadDocs } from './components/downloadDocs';
import { validarRoles } from '../../utils/roles';
import { Roles } from '../../router/roles.routes';
import { OutletPermify } from '../../components/outletPermify';

export const DocumentacaoIPO = () => {
  const navigate = useNavigate();
  const { currentEnterpriseId } = useEnterprise();
  const { currentRole } = useAuth();
  const [clModal, setClModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);

  const {
    documentacaoIPO,
    isLoading,
    updateStatusCategoria,
    refreshDocsCategoria,
    sendDocIPOToCL,
    aproveDocIPObyBEE4,
    deleteCategoria,
    editPrazoIpo,
    uploadDocIpo,
    addReviewIpo,
    sendNewDocsIpoToBEE4,
    setIpoStatus,
    deleteStatusDocIpo,
    uplaodNewDocsStatusIpo,
    addAdicionalDecisionIpo,
    uplaodAdicionalDocsStatusIpo,
    deleteAdicionalDocIpo,
    editDecisionTypeIpo,
    downloadDocsIpo,
    sendBEE4,
  } = useIPO({
    currentEnterpriseId,
  });

  const status = documentacaoIPO?.statusDocumentacaoIpo;

  const adicionalDecision =
    !!documentacaoIPO?.aprovacaoBEE4?.notificacaoDaDecisaoDoComite;

  const disableCL =
    currentRole === 'NOMADBOSS' && !documentacaoIPO?.submeterParaAnaliseDaBEE4;

  const disableBEE4 =
    currentRole === 'BEE4' && !documentacaoIPO?.submeterDecisaoDoComite;

  const disableButton = disableCL || disableBEE4;

  // desabilitar todos os botões da decisão do comitê
  const disableCommitteeDecision = documentacaoIPO?.statusDocumentacaoIpo !== 4;

  const completed = statusCompleted(status);

  const handleSubmitText = () => {
    if ([5, 3].includes(status ?? 0) && currentRole === 'BEE4')
      return 'EMISSOR FOI NOTIFICADO SOBRE ESTA DECISÃO';

    if ([2].includes(status ?? 0) && currentRole === 'TECHINTERNO')
      return 'ENVIAR PARA VALIDAÇÃO';

    if ([1, 3].includes(status ?? 0)) return 'ENVIAR PARA ANÁLISE';

    if (status === 4 && adicionalDecision) return 'NOTIFICAR DECISÃO';

    if (status === 4) return 'NOTIFICAR DECISÃO DO COMITÊ';

    return '';
  };

  const handleSubmit = () => {
    if (status === 2) {
      sendDocIPOToCL();
    } else if (status === 3) {
      setClModal(true);
    } else if (status === 4) {
      aproveDocIPObyBEE4();
    }
  };

  const handleButton = () => {
    if (
      (status === 2 && currentRole === 'TECHINTERNO') ||
      (status === 3 && currentRole === 'NOMADBOSS') ||
      ([3, 4, 5].includes(status ?? 0) && currentRole === 'BEE4')
    ) {
      return true;
    }
    return false;
  };

  const handleButtonText = () => {
    if (status === 3 && currentRole === 'NOMADBOSS') {
      return (
        <p>
          Quando a empresa estiver com todos os documentos validados ou em prazo
          excepcional, é necessário protocolar o pedido de listagem. Esse é o
          pedido formal para a empresa ser listada na BEE4.
        </p>
      );
    }

    if (status === 2 && currentRole === 'TECHINTERNO') {
      return (
        <p>
          Após fazer o upload dos documentos, é necessário enviar para revisão e
          validação do Consultor de Listagem Responsável.
        </p>
      );
    }

    if (currentRole !== 'BEE4') return <></>;

    if (status === 4)
      return (
        <p>
          Notifique a decisão do Comitê para o Emissor, Consultor de Listagem e
          Colaborador responsável por esta empresa.
        </p>
      );

    return <></>;
  };

  const sendDocIPOToBEE4 = (payload?: any) => {
    sendBEE4.mutate(payload, {
      onSuccess: async res => {
        toast.dismiss();
        if (res?.status === 200 || res?.status === 201) {
          setConfirmModal(true);
        }
      },
      onError: async (err: any) => {
        toast.dismiss();
        const message = verifyError(
          err,
          'Erro ao tentar enviar a documentação',
        );
        toast.error(message);
      },
    });
  };

  if (!documentacaoIPO && isLoading) return <LoadingDashboard />;

  if (!documentacaoIPO && !isLoading) {
    return (
      <DocIpoContent>
        <DashboardProgress completed={statusCompleted(2)} />
        <EmptyDocState />
      </DocIpoContent>
    );
  }

  if (status === 1) {
    return <></>;
  }

  if (currentRole !== 'TECHINTERNO' && status === 2) {
    return (
      <DocIpoContent>
        <DashboardProgress completed={completed} />
        <EmptyDocState />
      </DocIpoContent>
    );
  }

  const buttonDecision = () => (
    <ButtonContainer
      hasText={currentRole === 'BEE4' && status === 4}
      justify={
        (status === 4 && currentRole === 'BEE4') || currentRole === 'BEE4'
          ? 'flex-end'
          : 'center'
      }
    >
      <Button
        width="220px"
        height="40px"
        onClick={() => {
          handleSubmit();
        }}
        disabled={disableButton}
      >
        <strong>{handleSubmitText()}</strong>
      </Button>
    </ButtonContainer>
  );

  return (
    <>
      <DocIpoContent>
        <DashboardProgress completed={completed} />
        <OrientationIPO
          status={status ?? 0}
          documentacaoIPO={documentacaoIPO}
        />
        <CategoriesTable
          categories={documentacaoIPO?.categorias}
          onStatusChange={updateStatusCategoria}
          refreshDocsCategoria={refreshDocsCategoria}
          status={status ?? 1}
          deleteCategoria={deleteCategoria}
          editPrazo={editPrazoIpo}
          uploadDoc={uploadDocIpo}
          addReview={addReviewIpo}
          sendNewDocsToBEE4={sendNewDocsIpoToBEE4}
          analyzedByCommittee={
            !!documentacaoIPO?.aprovacaoBEE4?.notificacaoDaDecisaoDoComite
          }
          type={1}
        />
        {/* <ShouldRenderChildren
          shouldRender={validarRoles(permissions, [
            Roles.VisualizarDecisaoComite,
          ])}
        > */}
        <ShouldRenderChildren
          shouldRender={
            currentRole === 'BEE4' && (status === 4 || status === 5)
          }
        >
          <OutletPermify role={[Roles.BaixarListaDocZipIPO]}>
            <DownloadDocs downloadDocsIpo={downloadDocsIpo} />
          </OutletPermify>
          <OutletPermify
            role={[Roles.VisualizarDecisaoComite, Roles.StatusDocIPO]}
          >
            <CommitteeDecision
              setIpoStatus={setIpoStatus}
              documentacaoIPO={documentacaoIPO}
              deleteStatusDocIpo={deleteStatusDocIpo}
              uplaodNewDocsStatusIpo={uplaodNewDocsStatusIpo}
              addAdicionalDecisionIpo={addAdicionalDecisionIpo}
              uplaodAdicionalDocsStatusIpo={uplaodAdicionalDocsStatusIpo}
              deleteAdicionalDocIpo={deleteAdicionalDocIpo}
              editDecisionTypeIpo={editDecisionTypeIpo}
              isDisable={disableCommitteeDecision}
            />
          </OutletPermify>
        </ShouldRenderChildren>
        <ShouldRenderChildren shouldRender={handleButton()}>
          <Wrapper
            center={
              !(
                (status === 4 && currentRole === 'BEE4') ||
                currentRole === 'BEE4'
              )
            }
          >
            <span className="container">{handleButtonText()}</span>
            {status === 4 && adicionalDecision ? (
              <OutletPermify
                role={[Roles.NotificarDecisao, Roles.NotificarDecisaoAdicional]}
              >
                {buttonDecision()}
              </OutletPermify>
            ) : (
              buttonDecision()
            )}
          </Wrapper>
        </ShouldRenderChildren>
      </DocIpoContent>
      <ShouldRenderChildren shouldRender={clModal}>
        <ClSendIpoModal
          description={clModal}
          onConfirm={() => {
            sendDocIPOToBEE4();
          }}
          closeDropdown={() => {
            setClModal(false);
            setConfirmModal(false);
          }}
          confirmModal={confirmModal}
        />
      </ShouldRenderChildren>
    </>
  );
};
