import React from 'react';
import { ButtonContainer, ContainerDiv, Title } from './styles';

export const Container = ({
  title,
  children,
  style,
  titleMarginBottom,
  button,
}: {
  title?: string;
  children: any;
  style?: any;
  button?: any;
  titleMarginBottom?: string;
}) => {
  return (
    <ContainerDiv style={style}>
      {title && <Title marginBottom={titleMarginBottom}>{title}</Title>}
      {children}
      {button && <ButtonContainer>{button}</ButtonContainer>}
    </ContainerDiv>
  );
};
