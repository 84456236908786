import React, { ChangeEvent, DragEvent } from 'react';
import { Controller as UseFormController } from 'react-hook-form';
import { masks } from '../input';
import { InputControlled as Input } from '../input/controlled';
import { Select, IOption } from '../select';
import { Radio } from '../radio';
import { AutoComplete, IAutocompleteOption } from '../autocomplete';
import { MultipleSelect, IMultipleSelectOption } from '../multipleSelect';
import UploadButton from '../uploadButton';
import { Checkbox } from '../checkbox';

interface ControllerProps {
  name: string;
  label?: string | React.ReactNode;
  type:
    | 'input'
    | 'select'
    | 'radio'
    | 'autocomplete'
    | 'multipleselect'
    | 'uploadButton'
    | 'checkbox';
  mask?:
    | 'currency'
    | 'percentage'
    | 'cep'
    | 'date'
    | 'phone'
    | 'cnpj'
    | 'cpf'
    | 'integer'
    | 'thousandSeparator'
    | 'bank'
    | 'negativeCurrency';
  options?: IOption[];
  multipleSelectOptions?: IMultipleSelectOption[];
  autocompleteOptions?: IAutocompleteOption[];
  disabled?: boolean;
  textarea?: boolean;
  placeholder?: string;
  row?: boolean;
  maxLength?: number;
  height?: string;
  control: any;
  format?: string;
  noZero?: boolean;
  onBlur?: (e: any) => void;
  max?: number;
  min?: number;
  description?: any;
  defaultValue?: any[];
  onSelectValue?: (e: any) => void;
  onlyCharacters?: boolean;
  acceptedFiles?: string[];
}

export const Controller = (props: ControllerProps) => {
  const {
    name,
    label,
    type,
    mask,
    options = [],
    multipleSelectOptions = [],
    autocompleteOptions = [],
    disabled,
    textarea,
    placeholder,
    row,
    maxLength,
    height,
    control,
    format,
    noZero,
    onBlur,
    max,
    min,
    description,
    defaultValue,
    onSelectValue,
    onlyCharacters = false,
    acceptedFiles,
  } = props;

  if (type === 'select') {
    return (
      <UseFormController
        name={name}
        control={control}
        render={({ field, fieldState }) => {
          return (
            <Select
              label={label}
              error={fieldState.invalid}
              errorMessage={fieldState.error?.message}
              {...field}
              options={options}
              disabled={disabled}
              onSelectValue={onSelectValue}
            />
          );
        }}
      />
    );
  }
  if (type === 'autocomplete') {
    return (
      <UseFormController
        name={name}
        control={control}
        render={({ field, fieldState }) => {
          return (
            <AutoComplete
              label={label}
              error={fieldState.invalid}
              errorMessage={fieldState.error?.message}
              {...field}
              options={autocompleteOptions}
              disabled={disabled}
            />
          );
        }}
      />
    );
  }
  if (type === 'radio') {
    return (
      <UseFormController
        name={name}
        control={control}
        render={({ field, fieldState }) => {
          return (
            <Radio
              label={label}
              onChange={(v: any) => {
                if (v.target.value !== 'true' && v.target.value !== 'false')
                  field.onChange(parseInt(v.target.value));
                else {
                  field.onChange(v.target.value);
                }
              }}
              errorMessage={fieldState.error?.message}
              options={options || []}
              row={row}
              value={field.value}
              disabled={disabled}
            />
          );
        }}
      />
    );
  }

  if (type === 'multipleselect') {
    return (
      <UseFormController
        name={name}
        control={control}
        render={({
          field: { onChange, ...field },
          fieldState: { invalid, error },
          formState,
        }) => (
          <MultipleSelect
            label={label}
            {...field}
            onChange={e => {
              onChange(e);
            }}
            options={multipleSelectOptions}
            defaultValue={defaultValue}
            disabled={disabled}
          />
        )}
      />
    );
  }

  if (type === 'uploadButton') {
    return (
      <UseFormController
        name={name}
        control={control}
        render={({ field: { onChange, value, ...field } }) => (
          <UploadButton
            selectedFile={value}
            {...field}
            onChange={onChange}
            handleDragOver={(event: DragEvent<HTMLDivElement>) => {
              event.preventDefault();
            }}
            handleDrop={(event: DragEvent<HTMLDivElement>) => {
              event.preventDefault();
              onChange(event?.dataTransfer?.files[0]);
            }}
            disabled={disabled}
            label={label}
            description={description}
            acceptedFiles={acceptedFiles}
          />
        )}
      />
    );
  }

  if (type === 'checkbox') {
    return (
      <UseFormController
        name={name}
        control={control}
        render={({ field: { onChange, value, ...field } }) => (
          <Checkbox
            {...field}
            onChange={e => {
              onChange(e.target.checked);
            }}
            checked={value}
            label={label}
            {...field}
            disabled={disabled}
          />
        )}
      />
    );
  }

  return (
    <>
      {!mask ? (
        <Input
          label={label}
          placeholder={placeholder}
          disabled={disabled}
          maxLength={maxLength}
          height={height}
          textarea={textarea}
          control={control}
          name={name}
          max={max}
          min={min}
          description={description}
          onlyCharacters={onlyCharacters}
        />
      ) : (
        <UseFormController
          name={name}
          control={control}
          render={({ field, fieldState }) => {
            return (
              <Input
                mask={masks[mask]}
                label={label}
                placeholder={placeholder}
                control={control}
                disabled={disabled}
                name={name}
                onBlur={onBlur}
                format={format}
                noZero={noZero}
                error={fieldState.invalid}
                errorMessage={fieldState.error?.message}
                max={max}
                min={min}
                description={description}
                maxLength={maxLength}
              />
            );
          }}
        />
      )}
    </>
  );
};
