import styled from 'styled-components';

interface IListItemProps {
  status: number;
}

interface StatusProp {
  validated: boolean;
}

export const DashboardContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3rem;
`;

export const StatusButton = styled.button<IListItemProps>`
  border: ${({ status }) => (status === 3 && '1px solid #c3c6cf') || 'none'};
  padding: 4px 16px;
  border-radius: 30px;
  background: ${({ status }) =>
    (status === 1 && '#E7E7E7') ||
    (status === 2 && '#FFCC4A') ||
    (status === 3 && '#FFFFFF') ||
    (status === 5 && '#0852DD')};
  font-weight: 400;
  font-size: 12px;
  text-align: center;
  p {
    color: ${({ status }) => (status === 5 && '#FFFFFF') || '#565656'};
    font-size: 0.75rem;
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;

export const DashboardContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 1rem;
  height: 100%;
  margin-top: 2rem;
`;

export const AccordionContainer = styled.div`
  width: 70%;
  min-width: 750px;
  padding-bottom: 3rem;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;

  hr {
    border: 1px solid #eeeff1;
  }
`;

export const DefaultButton = styled.button`
  color: #8c8f9a;
  font-weight: 700;
  font-size: 12;
  background: none;
  border: none;
  text-decoration: underline;
  text-underline-offset: 3px;
`;

export const AccordionDocumentContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1rem;
`;

export const ContactInfoContainer = styled.div`
  width: 28%;
  background: #8a84b033;
  height: 100%;
  min-height: 380px;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  overflow-y: none;
  padding: 2.5rem 1.5rem;
  gap: 1rem;
  text-align: start;
  min-width: 175px;

  img {
    width: 73px;
    height: 73px;
    margin: 0 auto;
    margin-bottom: 1rem;
  }

  a {
    text-decoration: none;
    color: #060b7a;
    font-weight: 600;
    font-size: 14px;
  }

  @media (max-width: 1440px) {
    padding: 2.5rem 0.8rem;
    a {
      font-size: 12px;
    }
    h2 {
      font-size: 18px;
    }
    p {
      font-size: 1rem;
    }
  }
`;

export const StepRow = styled.div`
  height: 84.8px;
  display: flex;
  align-items: center;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #cccccc;
  margin: 0 auto 0 auto;
`;

export const FirstColumn = styled.div`
  display: flex;
  align-items: center;
  width: 45%;
`;

export const StepName = styled.div`
  display: flex;
  align-items: center;
  margin-left: 30px;
  gap: 13px;
  img {
    height: 28px;
    width: 28px;
  }

  span {
    font-size: 18px;
    font-weight: 400;
  }
`;

export const SecondColumn = styled.div<StatusProp>`
  display: flex;
  align-items: center;
  width: 55%;
  color: ${props => (props.validated ? '#2db350' : '#f78157')};
`;
