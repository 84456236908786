import React, { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { AxiosResponse } from 'axios';
import { api } from '../../../services/api';
import { verifyError } from '../../../utils/verifyError';

export const useParceiros = ({
  currentEnterpriseId,
}: {
  currentEnterpriseId: string;
}) => {
  const queryClient = useQueryClient();

  const getParceiros = async () => {
    const getToast = toast.loading('Carregando colaboradores disponíveis');
    try {
      const response: AxiosResponse<any> = await api.main.get(
        `/v1/empresas/${currentEnterpriseId}/emissor`,
      );
      toast.update(getToast, {
        render: 'Colaboradores carregados com sucesso!',
        type: 'success',
        isLoading: false,
        autoClose: 1,
      });
      return response?.data;
    } catch (error) {
      const message = verifyError(error, 'Erro ao carregar os colaboradores');
      toast.update(getToast, {
        render: message,
        type: 'error',
        isLoading: false,
        autoClose: 5000,
      });
    }
  };

  const {
    isLoading,
    isSuccess,
    isError,
    isIdle,
    data: parceiros,
  } = useQuery(['parceiros', currentEnterpriseId], getParceiros, {});

  const invalidateQuery = async () => {
    await queryClient.invalidateQueries('parceiros');
  };

  const postDados = useMutation(async (payload: any) => {
    toast.loading('Enviando convite para o colaborador');

    const response = await api.main.post(
      `/v1/empresas/${currentEnterpriseId}/emissor`,
      payload,
    );

    invalidateQuery();

    return response;
  });

  const createParceiro = (payload: any) => {
    postDados.mutate(payload, {
      onSuccess: async res => {
        toast.dismiss();
        if (res?.status === 200 || res?.status === 201) {
          toast.success('Convite enviado com sucesso!');
        }
      },
      onError: async (err: any) => {
        toast.dismiss();
        const message = verifyError(
          err,
          'Houve um erro ao tentar enviar o convite ao colaborador',
        );
        toast.error(message);
      },
    });
  };

  const putDados = useMutation(async (payload: any) => {
    toast.loading('Atualizando colaborador');

    const { parceiro, parceiroId } = payload;

    const response = await api.main.put(
      `/v1/empresas/${currentEnterpriseId}/emissor/${parceiroId}`,
      {
        nome: parceiro.nome,
      },
    );

    invalidateQuery();

    return response;
  });

  const updateParceiro = (payload: any) => {
    putDados.mutate(payload, {
      onSuccess: async res => {
        toast.dismiss();
        if (res?.status === 200 || res?.status === 201) {
          toast.success('Colaborador atualizado com sucesso!');
        }
      },
      onError: async (err: any) => {
        toast.dismiss();
        const message = verifyError(
          err,
          'Houve um erro ao tentar atualizado o colaborador',
        );
        toast.error(message);
      },
    });
  };

  const deleteDados = useMutation(async (payload: any) => {
    toast.loading('Desativando colaborador');
    const { id, motivoExclucao } = payload;
    const response = await api.main.delete(
      `/v1/empresas/${currentEnterpriseId}/emissor/${id}`,
      {
        data: { motivoExclucao },
      },
    );

    invalidateQuery();

    return response;
  });

  const activateDados = useMutation(async (payload: any) => {
    toast.loading('Ativando colaborador');
    const { id } = payload;
    const response = await api.main.post(
      `/v1/empresas/${currentEnterpriseId}/emissor/${id}/ativar-conta`,
    );

    invalidateQuery();

    return response;
  });

  const deleteParceiro = (payload: any) => {
    deleteDados.mutate(payload, {
      onSuccess: async res => {
        toast.dismiss();
        if (res?.status === 200 || res?.status === 201) {
          toast.success('Colaborador(a) desativado(a) com sucesso!');
        }
      },
      onError: async (err: any) => {
        toast.dismiss();
        const message = verifyError(
          err,
          'Houve um erro ao tentar desativar o colaborador',
        );
        toast.error(message);
      },
    });
  };

  const activateParceiro = (payload: any) => {
    activateDados.mutate(payload, {
      onSuccess: async res => {
        toast.dismiss();
        if (res?.status === 200 || res?.status === 201) {
          toast.success('Colaborador(a) ativado(a) com sucesso!');
        }
      },
      onError: async (err: any) => {
        toast.dismiss();
        const message = verifyError(
          err,
          'Houve um erro ao tentar ativar o colaborador',
        );
        toast.error(message);
      },
    });
  };

  const sendDados = useMutation(async (payload: any) => {
    toast.loading('Reenviando convite para colaborador');
    const { id } = payload;
    const response = await api.main.post(
      `/v1/empresas/${currentEnterpriseId}/emissor/${id}/enviar-convite-para-emissor`,
    );

    invalidateQuery();

    return response;
  });

  const reenviarConviteParceiro = (payload: any) => {
    sendDados.mutate(payload, {
      onSuccess: async res => {
        toast.dismiss();
        if (res?.status === 200 || res?.status === 201) {
          toast.success('Convite reenviado com sucesso!');
        }
      },
      onError: async (err: any) => {
        toast.dismiss();
        const message = verifyError(
          err,
          'Houve um erro ao tentar reenviar o convite',
        );
        toast.error(message);
      },
    });
  };

  return {
    isLoading,
    isError,
    isIdle,
    isSuccess,
    parceiros,
    getParceiros,
    createParceiro,
    updateParceiro,
    deleteParceiro,
    reenviarConviteParceiro,
    queryClient,
    activateParceiro,
  };
};

export default useParceiros;
