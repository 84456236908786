import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Index } from '../pages/index';
import { RelacaoComInvestidores } from '../pages/relacaoComInvestidores';
import { AdministracaoGlobal } from '../pages/administracaoGlobal';
import { DashboardListagem } from '../pages/dashboardListagem';
import { DadosGerais } from '../pages/dadosGerais';
import { Financeiro } from '../pages/financeiro';
import { Juridico } from '../pages/juridico';
import { Diligencia } from '../pages/diligencia';
import { SobreOAtivo } from '../pages/sobreOAtivo';
import { DadosDaOferta } from '../pages/dadosDaOferta';
import { NotFound } from '../pages/404';
import { SemConta } from '../pages/semConta';
import { SemEmpresas } from '../pages/semEmpresas';
import { NotInTheSystem } from '../pages/notInTheSystem';
import Wrapper from '../components/wrapper';
import { Sair } from '../pages/sair';
import { RefreshToken } from '../pages/refreshToken';
import { Relatorios } from '../pages/relatorios';
import { AtualizacoesCadastrais } from '../pages/atualizacoesCadastrais';
import { FatosRelevantes } from '../pages/fatosRelevantes';
import { Colaboradores } from '../pages/colaboradores';
import { Parceiros } from '../pages/parceiros';
import { DireitosUsuario } from '../pages/direitosUsuario';
import { useAuth } from '../hooks/auth';
import { ManuaisBEE4 } from '../pages/manuaisBee4';
import { DocumentacaoIPO } from '../pages/documentacaoIPO';
import { DecisaoComite } from '../pages/decisaoComite';
import { useEnterprise } from '../hooks/enterprise';
import { HistoricoDocumentacaoIPO } from '../pages/historicoDocumentos';
import { DocumentacaoListagem } from '../pages/documentacaoListagem';
import { HistoricoDecisaoComite } from '../pages/historicoDecisaoComite';
import { PrivateRoutes } from './privateRoutes/privateRoutes.routes';
import { Roles } from './roles.routes';
import { Usuarios } from '../pages/usuarios';

export const Router = () => {
  const { currentRole } = useAuth();
  const { resultadoDoComiteBEE4, resultadoDoComiteBEE4Listagem } =
    useEnterprise();

  const showDocIPO = ![1, 2, 4].includes(resultadoDoComiteBEE4 ?? 0);

  const disapprovedCommittee =
    resultadoDoComiteBEE4 === 2 || resultadoDoComiteBEE4Listagem === 2;

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Index />} />
        <Route
          path="/direitos-do-usuario"
          element={
            <Wrapper>
              <DireitosUsuario />
            </Wrapper>
          }
        />
        <Route
          path="/relacao-com-investidores"
          element={
            <Wrapper>
              <RelacaoComInvestidores />
            </Wrapper>
          }
        />
        <Route
          element={<PrivateRoutes pageRole={[Roles.VisualizarDashboard]} />}
        >
          <Route
            path="/dashboard-listagem"
            element={
              <Wrapper>
                <DashboardListagem />
              </Wrapper>
            }
          />
        </Route>
        <Route element={<PrivateRoutes pageRole={[Roles.HistoricoComite]} />}>
          <Route
            path="/historico-comite"
            element={
              <Wrapper>
                <HistoricoDecisaoComite />
              </Wrapper>
            }
          />
        </Route>
        {!disapprovedCommittee && (
          <>
            <Route
              path="/dados-gerais"
              element={
                <Wrapper>
                  <DadosGerais />
                </Wrapper>
              }
            />
            <Route
              path="/documentacao-adicional"
              element={
                <Wrapper>
                  <Financeiro />
                </Wrapper>
              }
            />
            <Route
              path="/juridico"
              element={
                <Wrapper>
                  <Juridico />
                </Wrapper>
              }
            />
            <Route
              path="/diligencia"
              element={
                <Wrapper>
                  <Diligencia />
                </Wrapper>
              }
            />
            <Route
              path="/sobre-o-ativo"
              element={
                <Wrapper>
                  <SobreOAtivo />
                </Wrapper>
              }
            />
            <Route
              path="/dados-da-oferta"
              element={
                <Wrapper>
                  <DadosDaOferta />
                </Wrapper>
              }
            />
            <Route
              element={<PrivateRoutes pageRole={[Roles.VisualizarRelatorio]} />}
            >
              <Route
                path="/relatorios-pos-listagem"
                element={
                  <Wrapper>
                    <Relatorios />
                  </Wrapper>
                }
              />
            </Route>
            <Route
              path="/atualizacoes-cadastrais"
              element={
                <Wrapper>
                  <AtualizacoesCadastrais />
                </Wrapper>
              }
            />
            <Route
              element={
                <PrivateRoutes pageRole={[Roles.VisualizarFatoRelevante]} />
              }
            >
              <Route
                path="/fatos-relevantes"
                element={
                  <Wrapper>
                    <FatosRelevantes />
                  </Wrapper>
                }
              />
            </Route>
          </>
        )}

        <Route
          element={<PrivateRoutes pageRole={[Roles.ConsultarClColaborador]} />}
        >
          <Route
            path="/colaboradores"
            element={
              <Wrapper>
                <Colaboradores />
              </Wrapper>
            }
          />
        </Route>

        <Route element={<PrivateRoutes pageRole={[Roles.ConsultarEmissor]} />}>
          <Route
            path="/emissores"
            element={
              <Wrapper>
                <Parceiros />
              </Wrapper>
            }
          />
        </Route>

        <Route
          element={<PrivateRoutes pageRole={[Roles.AcessarAdminGlobal]} />}
        >
          <Route
            path="/administracao-global"
            element={
              <Wrapper>
                <AdministracaoGlobal />
              </Wrapper>
            }
          />
        </Route>

        <Route element={<PrivateRoutes pageRole={[Roles.ConsultarUsuario]} />}>
          <Route
            path="/gestao-usuarios"
            element={
              <Wrapper>
                <Usuarios />
              </Wrapper>
            }
          />
        </Route>

        <Route
          path="/manuais-bee4"
          element={
            <Wrapper>
              <ManuaisBEE4 />
            </Wrapper>
          }
        />
        {showDocIPO && (
          <Route
            path="/documentacao-ipo"
            element={
              <Wrapper>
                <DocumentacaoIPO />
              </Wrapper>
            }
          />
        )}
        <Route
          path="/documentacao-listagem"
          element={
            <Wrapper>
              <DocumentacaoListagem />
            </Wrapper>
          }
        />
        <Route
          path="/historico-documentacao"
          element={
            <Wrapper>
              <HistoricoDocumentacaoIPO />
            </Wrapper>
          }
        />
        <Route
          element={<PrivateRoutes pageRole={[Roles.VisualizarDecisaoComite]} />}
        >
          <Route
            path="/decisao-comite"
            element={
              <Wrapper onlyTop>
                <DecisaoComite />
              </Wrapper>
            }
          />
        </Route>
        <Route path="/refresh-token" element={<RefreshToken />} />
        <Route path="/sem-empresas" element={<SemEmpresas />} />
        <Route path="/sem-acesso" element={<NotInTheSystem />} />
        <Route path="/sem-conta" element={<SemConta />} />
        <Route path="/sair" element={<Sair />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};
