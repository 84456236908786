import React, { useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  AccordionContent,
  AccordionExpandedButton,
  TableLine,
  TableLableColumn,
  TableActionsColumn,
  TableButtonsColumn,
  RadioLable,
  StatusBall,
} from '../styles';
import { Radio } from '../../radio';
import {
  AddReviewIpoPayload,
  CategoryDocIPO,
} from '../../../pages/documentacaoIPO/fields';
import { useAuth } from '../../../hooks/auth';
import {
  ModalIPOTypes,
  ModalState,
  StatusChangePayload,
  UploadDocPayload,
  modalInitial,
} from '../fields';
import { ButtonsActions } from './buttonsActions';
import { ModalsContainer } from './modalsConteiner';
import variablesDoc from '../variables/variablesDoc';
import { OutletPermify } from '../../outletPermify';
import { Roles } from '../../../router/roles.routes';

interface ICategoryAccordionProps {
  categoryTheme?: CategoryDocIPO[];
  label?: string;
  onStatusChange: (status: any) => void;
  refreshDocsCategoria: (e: string) => void;
  deleteCategaria: (e: string) => void;
  status: number;
  editPrazo: (status: any) => void;
  uploadDoc: (e?: UploadDocPayload) => void;
  addReview: (e: AddReviewIpoPayload) => void;
  sendNewDocsToBEE4: (e: StatusChangePayload) => void;
  analyzedByCommittee: boolean;
  type?: 1 | 2;
}

export const CategoriesAccordion = ({
  categoryTheme,
  label,
  onStatusChange,
  refreshDocsCategoria,
  deleteCategaria,
  status,
  editPrazo,
  uploadDoc,
  addReview,
  sendNewDocsToBEE4,
  analyzedByCommittee,
  type,
}: ICategoryAccordionProps) => {
  const { currentRole } = useAuth();
  const [expanded, setExpanded] = useState(true);
  const [openModal, setOpenModal] = useState<ModalState>(modalInitial);
  const [viewCategory, setViewCategory] = useState<CategoryDocIPO>();
  const [categoryId, setCategoryId] = useState<string>('');
  const [statusObject, setStatusObject] = useState<
    StatusChangePayload | undefined
  >();

  const handleOpenModal = (modalName: ModalIPOTypes) => {
    setOpenModal(prevStates => ({
      ...prevStates,
      [modalName]: true,
    }));
  };
  const setCloseViewCategory = () => {
    setViewCategory(undefined);
  };
  const {
    hasToFinish,
    isHighlighted,
    handleChange,
    handleOptions,
    handleRadioChange,
    handleCloseModal,
  } = variablesDoc({
    onStatusChange,
    status,
    analyzedByCommittee,
    expanded,
    setExpanded,
    handleOpenModal,
    setCloseViewCategory,
    setCategoryId,
    setStatusObject,
    setOpenModal,
    currentRole,
    type,
  });

  return (
    <>
      <AccordionContent>
        <Accordion
          expanded={expanded}
          onChange={handleChange}
          sx={{
            boxShadow: 'none',
            border: 'none',
            padding: '0',
            '&::before': {
              backgroundColor: 'transparent',
            },
          }}
        >
          <AccordionSummary
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{
              alignItems: 'center',
              margin: 0,
              padding: 0,
              minHeight: 'fit-content',
            }}
          >
            <AccordionExpandedButton type="button">
              {label}
              <ExpandMoreIcon
                sx={{
                  transform: expanded ? 'none' : 'rotate(180deg)',
                  transition: 'transform 0.3s ease',
                }}
              />
            </AccordionExpandedButton>
          </AccordionSummary>
          <AccordionDetails sx={{ padding: 0 }}>
            {categoryTheme?.map((item, index) => (
              <TableLine
                index={index}
                key={item.id}
                highlight={isHighlighted(item)}
              >
                <TableLableColumn>
                  <StatusBall status={item.statusGeralDaCategoria!} />
                  {item.nomeDaCategoria}
                </TableLableColumn>
                <TableActionsColumn>
                  <ButtonsActions
                    category={item}
                    handleOpenModal={handleOpenModal}
                    setViewCategory={setViewCategory}
                    refreshDocsCategoria={refreshDocsCategoria}
                    status={status}
                    setCategoryId={setCategoryId}
                    setStatusObject={setStatusObject}
                    sendNewDocsToBEE4={sendNewDocsToBEE4}
                    analyzedByCommittee={analyzedByCommittee}
                    type={type}
                  />
                </TableActionsColumn>
                <TableButtonsColumn>
                  <OutletPermify
                    role={[
                      Roles.AtualizarStatusCategoria,
                      Roles.ValidarDocumentacao,
                    ]}
                  >
                    <Radio
                      onChange={(e: any) => {
                        handleRadioChange(parseInt(e?.target?.value), item);
                      }}
                      value={item.statusDaCategoria}
                      options={handleOptions(item)}
                      row
                      style={{ fontSize: '14px', color: '#262936' }}
                      disabled={hasToFinish(item)}
                    />
                  </OutletPermify>
                </TableButtonsColumn>
              </TableLine>
            ))}
          </AccordionDetails>
        </Accordion>
      </AccordionContent>
      <ModalsContainer
        openModal={openModal}
        handleCloseModal={handleCloseModal}
        onStatusChange={onStatusChange}
        statusObject={statusObject}
        viewCategory={viewCategory}
        deleteCategaria={() => {
          deleteCategaria(categoryId);
        }}
        editPrazo={editPrazo}
        uploadDoc={(e?: UploadDocPayload) => {
          uploadDoc({ ...e, categoriaId: categoryId });
        }}
        addReview={addReview}
        status={status}
      />
    </>
  );
};
