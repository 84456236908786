import React from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { AxiosResponse } from 'axios';
import { api } from '../../../../../services/api';
import { verifyError } from '../../../../../utils/verifyError';
import { CategoryProps } from '../fields';

export const useCategoriaFinanceiro = ({ arquivado }: CategoryProps) => {
  const queryClient = useQueryClient();
  const getCategoriaFinanceiro = async () => {
    try {
      const { data }: AxiosResponse<any> = await api.main.get(
        `/v1/categorias-de-documento${
          arquivado ? '/armazenado' : ''
        }?etapa=financeiro`,
      );

      return data;
    } catch (error) {
      const message = verifyError(
        error,
        'Erro ao carregar os dados da categoria financeiro',
      );
      toast.error(message);
    }
  };

  const {
    isLoading: loadingFinanceiro,
    isSuccess: isFinanceiroSuccess,
    isError,
    isIdle,
    data: financeiro,
  } = useQuery(['financeiro', arquivado], getCategoriaFinanceiro, {});

  const invalidateQuery = async () => {
    await queryClient.invalidateQueries('financeiro');
  };

  const postDados = useMutation(async (payload: any) => {
    toast.loading('Criando categoria');
    const { form } = payload;

    const response = await api.main.post('/v1/categorias-de-documento', form);

    invalidateQuery();

    return response;
  });

  const createCategoriaFinanceiro = (payload: any) => {
    postDados.mutate(payload, {
      onSuccess: async res => {
        toast.dismiss();
        if (res?.status === 200 || res?.status === 201) {
          toast.success('Categoria criada com sucesso!');
        }
      },
      onError: async (err: any) => {
        toast.dismiss();
        const message = verifyError(err, 'Erro ao criar uma nova categoria');
        toast.error(message);
      },
    });
  };

  const putDados = useMutation(async (payload: any) => {
    toast.loading('Atualizando categoria');
    const { form, id } = payload;

    const response = await api.main.put(
      `/v1/categorias-de-documento/${id}`,
      form,
    );

    invalidateQuery();

    return response;
  });

  const updateCategoriaFinanceiro = (payload: any) => {
    putDados.mutate(payload, {
      onSuccess: async res => {
        toast.dismiss();
        if (res?.status === 200 || res?.status === 201) {
          toast.success('Categoria atualizada com sucesso!');
        }
      },
      onError: async (err: any) => {
        toast.dismiss();
        const message = verifyError(err, 'Erro ao atualizar a categoria');
        toast.error(message);
      },
    });
  };

  const deleteDados = useMutation(async (payload: any) => {
    toast.loading('Arquivando categoria');
    const { id } = payload;

    if (arquivado) {
      const response = await api.main.put(
        `/v1/categorias-de-documento/${id}/desarquivar`,
      );

      invalidateQuery();

      return response;
    }

    const response = await api.main.delete(`/v1/categorias-de-documento/${id}`);

    invalidateQuery();

    return response;
  });

  const archiveCategoriaFinanceiro = (payload: any) => {
    deleteDados.mutate(payload, {
      onSuccess: async res => {
        toast.dismiss();
        if (res?.status === 200 || res?.status === 201) {
          toast.success('Categoria arquivada com sucesso!');
        }
      },
      onError: async (err: any) => {
        toast.dismiss();
        const message = verifyError(err, 'Erro ao arquivar a categoria');
        toast.error(message);
      },
    });
  };

  const orderDados = useMutation(async (payload: any) => {
    toast.loading('Atualizando categoria');

    const response = await api.main.put(
      `/v1/categorias-de-documento/1/ordenar`,
      payload,
    );

    invalidateQuery();

    return response;
  });

  const ordenarCategoriaFinanceiro = (payload: any) => {
    orderDados.mutate(payload, {
      onSuccess: async res => {
        toast.dismiss();
        if (res?.status === 200 || res?.status === 201) {
          toast.success('Categoria ordenada com sucesso!');
        }
      },
      onError: async (err: any) => {
        toast.dismiss();
        const message = verifyError(
          err,
          'Erro ao atualizar ordem da categoria',
        );
        toast.error(message);
      },
    });
  };

  return {
    loadingFinanceiro,
    isFinanceiroSuccess,
    isError,
    isIdle,
    financeiro,
    createCategoriaFinanceiro,
    updateCategoriaFinanceiro,
    archiveCategoriaFinanceiro,
    ordenarCategoriaFinanceiro,
    queryClient,
  };
};

export default useCategoriaFinanceiro;
