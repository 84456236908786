/* eslint-disable react/no-unescaped-entities */
import React, { useEffect } from 'react';
import moment from 'moment';
import saveAs from 'file-saver';
import {
  DocumentCard,
  TableLine,
  IndexColumns,
  ContentColumns,
  DocumentDetail,
  DocumentButtons,
  EmptyContainer,
  FirstColumn,
  SecondColumn,
  ThirdColumn,
  FourthColumn,
  FifthColumn,
  ExtraArea,
  Description,
  DescriptionContent,
  DownloadButton,
  FourthColumn2,
} from '../../documentsContainer/styles';
import { Button } from '../../../../button';
import { Document } from '../../../types';
import { DocumentosRequest } from '../../../../../pages/atualizacoesCadastrais/fields';
import { useEnterprise } from '../../../../../hooks/enterprise';
import { useAuth } from '../../../../../hooks/auth';
import {
  base64ToArrayBuffer,
  getMimeType,
} from '../../../../objectRenderer/utils';

interface Props {
  docList?: any;
  currentRole?: string;
  readOnly: boolean;
  descricao?: string;
  urlDoArquivoTemplate?: string;
  onDelete: (e: Document | DocumentosRequest) => void;
}

export const DocumentsContainerAC = (props: Props) => {
  const { docList, readOnly, descricao, urlDoArquivoTemplate, onDelete } =
    props;
  const [docs, setDocs] = React.useState(docList);
  const { enterprises } = useEnterprise();
  const { currentRole } = useAuth();

  const isDisabled =
    currentRole === 'Empreendedor' &&
    enterprises?.length > 0 &&
    enterprises[0].termoEmissor === false;

  // check if it is an empty documents container
  useEffect(() => {
    setDocs(docList);
  }, [docList]);

  if (!docList.length) {
    return (
      <>
        {descricao || urlDoArquivoTemplate ? (
          <div style={{ cursor: 'default' }}>
            <ExtraArea>
              {descricao ? (
                <Description>
                  Descrição:
                  <DescriptionContent>{descricao}</DescriptionContent>
                </Description>
              ) : (
                <></>
              )}
              {urlDoArquivoTemplate && (
                <DownloadButton>
                  <Button
                    type="button"
                    onClick={() => {
                      window.open(urlDoArquivoTemplate);
                    }}
                    disabled={isDisabled}
                  >
                    <img
                      className="uploadIcon"
                      src="images/download-branco.svg"
                      alt="upload"
                      style={{
                        marginRight: '1rem',
                        width: '16px',
                        height: '16px',
                      }}
                    />
                    DOWNLOAD DO TEMPLATE
                  </Button>
                </DownloadButton>
              )}
            </ExtraArea>
            <TableLine />{' '}
          </div>
        ) : null}
        <EmptyContainer>
          <img className="emptyIcon" src="images/EmptyState/vazio.svg" alt="" />
          <p>Ainda não há documentos. Insira clicando em "UPLOAD".</p>
        </EmptyContainer>
      </>
    );
  }

  const handleDownload = (documentUrl: string, fileName: string) => {
    const fileType = fileName?.split('.')?.pop();
    const mimeType = getMimeType(fileType ?? 'pdf');

    if (!mimeType) {
      console.error('Tipo de arquivo inválido');
      return null;
    }

    if (!mimeType) {
      console.error('Tipo de arquivo inválido');
      return null;
    }

    const blob = new Blob([documentUrl], {
      type: mimeType,
    });

    // Use a função saveAs para fazer o download do arquivo
    saveAs(blob, fileName);
  };

  function renderDocuments() {
    return docs.map((doc: any, index: number) => {
      const date = doc.createdOn
        ? moment(doc.createdOn).format('DD/MM/YYYY')
        : null;

      return (
        // eslint-disable-next-line react/no-array-index-key
        <React.Fragment key={index}>
          <ContentColumns>
            <FirstColumn />
            <SecondColumn>
              <img className="fileIcon" src="images/file.svg" alt={doc.nome} />
              <span>{doc.nome}</span>
            </SecondColumn>
            <ThirdColumn>
              <span>{date}</span>
            </ThirdColumn>
            <FourthColumn2>
              <DocumentButtons validated={readOnly}>
                {doc.createdOn &&
                ['Empreendedor', 'TECHINTERNO', 'BEE4'].includes(
                  currentRole,
                ) ? (
                  <button
                    type="button"
                    onClick={() => handleDownload(doc.url, doc.nome)}
                  >
                    <img
                      className="download"
                      src="images/download.svg"
                      alt="download"
                    />
                  </button>
                ) : (
                  <></>
                )}
                {currentRole === 'Empreendedor' ? (
                  <button
                    disabled={readOnly}
                    type="button"
                    onClick={() => (readOnly ? {} : onDelete(doc))}
                  >
                    <img
                      className="delete"
                      src={
                        !readOnly
                          ? 'images/lixeira.svg'
                          : 'images/lixeira-cinza.svg'
                      }
                      alt="delete"
                    />
                  </button>
                ) : null}
              </DocumentButtons>
            </FourthColumn2>
          </ContentColumns>
          {index < docList.length - 1 ? <TableLine /> : null}
        </React.Fragment>
      );
    });
  }
  return (
    <>
      <DocumentCard>
        {descricao || urlDoArquivoTemplate ? (
          <div style={{ cursor: 'default' }}>
            <ExtraArea>
              {descricao ? (
                <Description>
                  Descrição:
                  <DescriptionContent>{descricao}</DescriptionContent>
                </Description>
              ) : (
                <></>
              )}
              {urlDoArquivoTemplate && (
                <DownloadButton>
                  <Button
                    type="button"
                    onClick={() => {
                      window.open(urlDoArquivoTemplate);
                    }}
                    disabled={isDisabled}
                  >
                    <img
                      className="uploadIcon"
                      src="images/download-branco.svg"
                      alt="upload"
                      style={{
                        marginRight: '1rem',
                        width: '16px',
                        height: '16px',
                      }}
                    />
                    DOWNLOAD DO TEMPLATE
                  </Button>
                </DownloadButton>
              )}
            </ExtraArea>
            <TableLine />{' '}
          </div>
        ) : null}
        <IndexColumns>
          <FirstColumn />
          <SecondColumn>
            <span>NOME</span>
          </SecondColumn>
          <ThirdColumn>
            <span>DATA DE INSERÇÃO</span>
          </ThirdColumn>
          <FourthColumn />
          <FifthColumn />
        </IndexColumns>
        <TableLine />
        <DocumentDetail>{renderDocuments()}</DocumentDetail>
      </DocumentCard>
    </>
  );
};
