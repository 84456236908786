import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState } from 'react';
import { handleDateOrHour } from '../../../utils/dateHandling';
import { ReviewComment } from '../../../pages/documentacaoIPO/fields';
import {
  ReviewCommentContent,
  ReviewLabel,
  ReviewSummaryContent,
} from '../styles';

type ReviewAccordionProps = {
  comment: ReviewComment;
};
const TEXTLENGHT = 100;
export const ReviewAccordion = ({ comment }: ReviewAccordionProps) => {
  const [expanded, setExpanded] = useState(false);

  const handleChange = () => {
    setExpanded(!expanded);
  };

  const displayLabelComment =
    comment?.comentario?.length < TEXTLENGHT
      ? comment?.comentario
      : `${comment?.comentario?.slice(0, TEXTLENGHT)}...`;

  return (
    <Accordion expanded={expanded} onChange={handleChange}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        style={!expanded ? { backgroundColor: '#D0D4E0' } : {}}
      >
        <ReviewSummaryContent>
          <ReviewLabel>
            <h3>{comment?.nomeDoUsuario}</h3>
            <p>{handleDateOrHour(comment.criadoEm)}</p>
          </ReviewLabel>
          {!expanded && (
            <ReviewCommentContent>{displayLabelComment}</ReviewCommentContent>
          )}
        </ReviewSummaryContent>
      </AccordionSummary>
      <AccordionDetails>
        <ReviewCommentContent>{comment.comentario}</ReviewCommentContent>
      </AccordionDetails>
    </Accordion>
  );
};
