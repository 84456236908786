import * as yup from 'yup';

export interface ArquivoTemplate {
  base64Content: string;
  nome: string;
}
export interface DocumentForm {
  nome?: string;
  informacao?: string;
  descricao?: string;
  nomeDoArquivoTemplate?: string;
  arquivoTemplateEmBase64?: any;
  arquivoTemplate?: ArquivoTemplate;
  tipoDeEmpresa?: number;
  obrigatorio: boolean;
  comporNoDossie?: boolean;
  comporNoDossieTodosOsDocumentos?: boolean;
  outputAutomatico?: number;
  comporNaAtualizacaoCadastral?: boolean;
  segmentoDaDiligencia?: number;
  tipoDoRelatorio?: number;
  periodoDoRelatorio?: number;
  gerarDocumentoAutomaticamente?: boolean;
  tema?: number;
}

export const initialDocumentForm: DocumentForm = {
  nome: '',
  informacao: '',
  descricao: '',
  tipoDeEmpresa: undefined,
  obrigatorio: false,
};

export interface CategoryProps {
  arquivado?: boolean;
}

export const listTipoEmpresa = [
  { label: 'SA', value: 1 },
  { label: 'LTDA', value: 2 },
  { label: 'SA e LTDA', value: 3 },
];

export const automatedDocList = [{ label: 'Anexo C', value: 1 }];

export const listDiligenciaType = [
  { label: 'Societário', value: 1 },
  { label: 'Tributário', value: 2 },
  { label: 'Contencioso', value: 3 },
  { label: 'Certidões', value: 4 },
  { label: 'Contratos', value: 5 },
  { label: 'Propriedade Intelectual', value: 6 },
  { label: 'Patrimônio', value: 7 },
  { label: 'Trabalhista', value: 8 },
  { label: 'Estrutura de TI', value: 9 },
  { label: 'Em relação aos sócios', value: 10 },
];

export type Categories = {
  id: string;
  nome?: string;
  informacao?: string;
  descricao?: string;
  ordem?: number;
  etapa: number;
  urlDoArquivoTemplate?: string;
  tipoDeEmpresa?: number;
  tipoDoRelatorio?: number;
  periodoDoRelatorio?: number;
};

export type StageCategoryType =
  | 'emissao'
  | 'empresa'
  | 'financeiro'
  | 'juridico'
  | 'diligencia'
  | 'docIpo'
  | 'relatorio'
  | 'docListagem'
  | 'DocumentacaoListagem'
  | '';

export const categoryDocumentSchema = (stage?: StageCategoryType) =>
  yup.object().shape({
    nome: yup.string().required('Campo obrigatório'),
    tipoDeEmpresa: yup.number().required('Campo obrigatório'),
    informacao: yup.string().nullable(),
    descricao: yup.string().nullable(),
    segmentoDaDiligencia: yup.number().nullable(),
    obrigatorio: yup.boolean().nullable(),
    comporNaAtualizacaoCadastral: yup.boolean().nullable(),
    comporNoDossie: yup.boolean().nullable(),
    gerarDocumentoAutomaticamente: yup.boolean().nullable(),
    comporNoDossieTodosOsDocumentos: yup.boolean().nullable(),
    outputAutomatico: yup.number().when('gerarDocumentoAutomaticamente', {
      is: true,
      then: yup.number().required('Campo obrigatório'),
      otherwise: yup.number().nullable(),
    }),
    tipoDoRelatorio:
      stage === 'relatorio'
        ? yup.number().required('Campo obrigatório')
        : yup.number().nullable(),
    periodoDoRelatorio: yup.number().when('tipoDoRelatorio', {
      is: 2,
      then: yup.number().required('Campo obrigatório'),
      otherwise: yup.number().nullable(),
    }),
    tema: !['docIpo', 'DocumentacaoListagem'].includes(stage ?? '')
      ? yup.number().when('comporNoDossie', {
          is: true,
          then: yup.number().required('Campo obrigatório'),
          otherwise: yup.number().nullable(),
        })
      : yup.number().required('Campo obrigatório'),
  });
