import React, { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Select } from '../../../../components/select';
import {
  ButtonContaner,
  ButtonText,
  CommitteeDecisionContainer,
  CommitteeDecisionDescriptionContainer,
  LabelCancelContainer,
  RadioContent,
  SelectDecisionLabel,
  StatusBall,
} from './styles';
import { UploadModal } from '../../../../components/uploadModal';
import { toBase64 } from '../../../../services/toBase64';
import { ShouldRenderChildren } from '../../../../components/shouldRenderChildren';
import {
  CategoryIPO,
  IpoStatusChangePayload,
  radioDecisionOptions,
} from '../../fields';
import { DocumentListListagem } from '../documentListListagem';
import { Button } from '../../../../components/button';
import { Radio } from '../../../../components/radio';
import { useAuth } from '../../../../hooks/auth';
import { OutletPermify } from '../../../../components/outletPermify';
import { Roles } from '../../../../router/roles.routes';

type CommitteeDecisionProps = {
  documentacaoIPO?: CategoryIPO;
  setIpoStatus: (e: IpoStatusChangePayload) => void;
  deleteStatusDocIpo: (e: string) => void;
  uplaodNewDocsStatusIpo: (e: IpoStatusChangePayload) => void;
  addAdicionalDecisionIpo: (e: IpoStatusChangePayload) => void;
  updateAdicionalDecisionIpo: (
    e: IpoStatusChangePayload,
    idAprovacao: string,
  ) => void;
  uplaodAdicionalDocsStatusIpo: (e: IpoStatusChangePayload) => void;
  deleteAdicionalDocIpo: (e: string) => void;
  editDecisionTypeIpo: (e: { tipoDeDecisao: 1 | 2 }) => void;
  isDisable: boolean;
};

export const CommitteeDecision = ({
  documentacaoIPO,
  setIpoStatus,
  deleteStatusDocIpo,
  uplaodNewDocsStatusIpo,
  addAdicionalDecisionIpo,
  updateAdicionalDecisionIpo,
  uplaodAdicionalDocsStatusIpo,
  deleteAdicionalDocIpo,
  editDecisionTypeIpo,
  isDisable,
}: CommitteeDecisionProps) => {
  const [status, setStatus] = useState<1 | 2 | 3 | 4 | undefined | null>();
  const [uploadNewDoc, setUploadNewDoc] = useState(false);

  const [addNewDecision, setNewDecision] = useState(false);
  const [radioError, setRadioError] = useState('');

  const idAprovacaoAdicional = useRef('');
  const aprovacaoBEE4 = documentacaoIPO?.aprovacaoBEE4;
  const listaDeAprovacaoAdicionalListagemBEE4 =
    documentacaoIPO?.listaDeAprovacaoAdicionalListagemBEE4;

  const listaDeAprovacaoAdicionalBEE4 =
    documentacaoIPO?.listaDeAprovacaoAdicionalBEE4;

  const statusCommittee = aprovacaoBEE4?.avaliacaoBEE4;
  const adicionalDecision = !!aprovacaoBEE4?.notificacaoDaDecisaoDoComite;
  const lastAprovacao = listaDeAprovacaoAdicionalListagemBEE4?.length
    ? listaDeAprovacaoAdicionalListagemBEE4[
        listaDeAprovacaoAdicionalListagemBEE4?.length - 1
      ]
    : undefined;

  const disableFirstDecision = isDisable || adicionalDecision;

  const addNewDecisionButton =
    !addNewDecision &&
    documentacaoIPO?.podeAdicionarNovoComite &&
    documentacaoIPO.statusDocumentacaoListagem === 4;

  const [radioValue, setRadioValue] = useState<1 | 2 | undefined>(
    lastAprovacao && !lastAprovacao?.notificacaoDaDecisaoDoComite
      ? lastAprovacao.tipoDeDecisao
      : undefined,
  );

  const handleCloseModal = () => {
    setStatus(undefined);
    setUploadNewDoc(false);
    idAprovacaoAdicional.current = '';
  };

  const handleSave = async (files: File[]) => {
    if (!files) {
      toast.error('Coloque um documento antes de salvar');
    } else {
      const base64Content = await toBase64(files[0]);
      const nome = files[0].name;

      if (base64Content) {
        // criação da primeira decisao do comitê
        if (!uploadNewDoc && !adicionalDecision) {
          setIpoStatus({
            nome,
            base64Content: base64Content as string,
            avaliacaoBEE4: status as any,
          });
          // upload dos documentos da primeira decisao do comitê
        } else if (uploadNewDoc && !adicionalDecision) {
          uplaodNewDocsStatusIpo({
            nome,
            base64Content: base64Content as string,
          });
        } else if (!uploadNewDoc && adicionalDecision) {
          // criação de uma nova decisao do comitê
          if (radioValue) {
            idAprovacaoAdicional.current
              ? updateAdicionalDecisionIpo(
                  {
                    nome,
                    base64Content: base64Content as string,
                    avaliacaoBEE4: status as any,
                    tipoDeDecisao: radioValue,
                  },
                  idAprovacaoAdicional.current as string,
                )
              : addAdicionalDecisionIpo({
                  nome,
                  base64Content: base64Content as string,
                  avaliacaoBEE4: status as any,
                  tipoDeDecisao: radioValue,
                });
          } else {
            setRadioError('Campo obrigatóro');
          }
        } else {
          uplaodAdicionalDocsStatusIpo({
            nome,
            base64Content: base64Content as string,
          });
        }

        setStatus(undefined);
        setNewDecision(false);
        setUploadNewDoc(false);
        idAprovacaoAdicional.current = '';
      }
    }
  };

  const decisionOptionsIPO = [
    {
      label: (
        <SelectDecisionLabel>
          <StatusBall status={1} />
          Aprovada
        </SelectDecisionLabel>
      ),
      value: 1,
    },
    {
      label: (
        <SelectDecisionLabel>
          <StatusBall status={2} />
          Reprovada
        </SelectDecisionLabel>
      ),
      value: 2,
    },
    {
      label: (
        <SelectDecisionLabel>
          <StatusBall status={4} />
          Exigências
        </SelectDecisionLabel>
      ),
      value: 3,
    },
    {
      label: (
        <SelectDecisionLabel>
          <StatusBall status={4} />
          Aprovada com condicionantes
        </SelectDecisionLabel>
      ),
      value: 4,
    },
  ];

  const decisionOptions = [
    {
      label: (
        <SelectDecisionLabel>
          <StatusBall status={1} />
          Aprovada
        </SelectDecisionLabel>
      ),
      value: 1,
    },
    {
      label: (
        <SelectDecisionLabel>
          <StatusBall status={2} />
          Reprovada
        </SelectDecisionLabel>
      ),
      value: 2,
    },
    // {
    //   label: (
    //     <SelectDecisionLabel>
    //       <StatusBall status={4} />
    //       Exigência
    //     </SelectDecisionLabel>
    //   ),
    //   value: 3,
    // },
    {
      label: (
        <SelectDecisionLabel>
          <StatusBall status={4} />
          Aprovada com condicionantes
        </SelectDecisionLabel>
      ),
      value: 4,
    },
  ];

  return (
    <>
      <CommitteeDecisionContainer>
        <h3>Decisão do Comitê</h3>
        <CommitteeDecisionDescriptionContainer>
          <p>
            Indique a decisão final do Comitê de Aprovação da Empresa Emissora.
          </p>
          <Select
            onChange={(v: 1 | 2 | 3 | 4) => {
              setStatus(v);
            }}
            value={statusCommittee}
            disabled={disableFirstDecision}
            width="230px"
            height="40px"
            options={decisionOptionsIPO}
          />
        </CommitteeDecisionDescriptionContainer>
        {aprovacaoBEE4?.documentos && (
          <OutletPermify role={[Roles.VisualizarListaDocGerados]}>
            <DocumentListListagem
              docList={aprovacaoBEE4?.documentos}
              openUploadModal={() => {
                setUploadNewDoc(true);
              }}
              deleteStatusDocIpo={deleteStatusDocIpo}
              isDisable={disableFirstDecision}
            />
          </OutletPermify>
        )}
      </CommitteeDecisionContainer>
      {listaDeAprovacaoAdicionalBEE4?.map(aprov => (
        <CommitteeDecisionContainer key={aprov.aprovacaoAdicionalIpoId}>
          <h3>Decisão Adicional</h3>
          <RadioContent>
            <h4>Tipo de decisão:</h4>
            <Radio
              value={aprov?.tipoDeDecisao}
              row
              onChange={e => {
                const v = parseInt(e.target.value) as 1 | 2;
                editDecisionTypeIpo({ tipoDeDecisao: v });
              }}
              options={radioDecisionOptions}
              disabled={!!aprov?.notificacaoDaDecisaoDoComite}
            />
          </RadioContent>
          <CommitteeDecisionDescriptionContainer>
            <p>Indique a decisão da BEE4 (Comitê ou validação interna).</p>
            <Select
              onChange={() => {}}
              value={aprov.avaliacaoBEE4}
              disabled={!!aprov?.notificacaoDaDecisaoDoComite}
              width="230px"
              height="40px"
              options={decisionOptionsIPO}
            />
          </CommitteeDecisionDescriptionContainer>
          {aprov?.documentos && (
            <OutletPermify role={[Roles.VisualizarListaDocGerados]}>
              <DocumentListListagem
                docList={aprov?.documentos}
                isDisable={!!aprov?.notificacaoDaDecisaoDoComite}
                deleteStatusDocIpo={deleteAdicionalDocIpo}
                openUploadModal={() => {
                  setUploadNewDoc(true);
                }}
              />
            </OutletPermify>
          )}
        </CommitteeDecisionContainer>
      ))}
      {listaDeAprovacaoAdicionalListagemBEE4?.map(aprov => (
        <CommitteeDecisionContainer key={aprov.aprovacaoAdicionalIpoId}>
          <h3>Decisão Adicional</h3>
          <RadioContent>
            <h4>Tipo de decisão:</h4>
            <Radio
              value={aprov?.tipoDeDecisao}
              row
              onChange={e => {
                const v = parseInt(e.target.value) as 1 | 2;
                editDecisionTypeIpo({ tipoDeDecisao: v });
              }}
              options={radioDecisionOptions}
              disabled={!!aprov?.notificacaoDaDecisaoDoComite}
            />
          </RadioContent>
          <CommitteeDecisionDescriptionContainer>
            <p>Indique a decisão da BEE4 (Comitê ou validação interna).</p>
            <Select
              onChange={(v: 1 | 2 | 3 | 4) => {
                console.log(aprov);
                idAprovacaoAdicional.current = aprov.aprovacaoAdicionalIpoId;
                setStatus(v);
              }}
              value={aprov.avaliacaoBEE4}
              disabled={!!aprov?.notificacaoDaDecisaoDoComite}
              width="230px"
              height="40px"
              options={decisionOptions}
            />
          </CommitteeDecisionDescriptionContainer>
          {aprov?.documentos && (
            <OutletPermify role={[Roles.VisualizarListaDocGerados]}>
              <DocumentListListagem
                docList={aprov?.documentos}
                isDisable={!!aprov?.notificacaoDaDecisaoDoComite}
                deleteStatusDocIpo={deleteAdicionalDocIpo}
                openUploadModal={() => {
                  setUploadNewDoc(true);
                }}
              />
            </OutletPermify>
          )}
        </CommitteeDecisionContainer>
      ))}
      {addNewDecisionButton && (
        <ButtonContaner>
          <hr />
          <Button
            variant="text"
            onClick={() => {
              setNewDecision(true);
            }}
          >
            <ButtonText>
              <AddCircleIcon sx={{ color: 'black' }} />
              <span>ADICIONAR DECISÃO</span>
            </ButtonText>
          </Button>
          <hr />
        </ButtonContaner>
      )}
      {addNewDecision && (
        <CommitteeDecisionContainer>
          <LabelCancelContainer>
            <h3>Decisão adicional</h3>
            <Button
              variant="text"
              style={{ fontSize: '14px', textDecoration: 'underline' }}
              onClick={() => {
                setNewDecision(false);
              }}
            >
              Cancelar
            </Button>
          </LabelCancelContainer>

          <RadioContent>
            <h4>Tipo de decisão:</h4>
            <Radio
              value={radioValue}
              row
              onChange={e => {
                const v = parseInt(e.target.value) as 1 | 2;
                setRadioValue(v);
              }}
              options={radioDecisionOptions}
              errorMessage={!radioValue ? radioError : undefined}
            />
          </RadioContent>

          <CommitteeDecisionDescriptionContainer>
            <p>Indique a decisão da BEE4 (Comitê ou validação interna).</p>
            <Select
              onChange={(v: 1 | 2 | 3 | 4) => {
                if (!radioValue) {
                  toast.error(
                    'Selecione o tipo de decisão antes de prosseguir.',
                  );

                  // Adaptacao tecnica por conta de como o fluxo de abrir o modal foi feito, sera alterado quando refizermos as telas para adotar o layout do figma (entrou como debito tecnico)
                  setStatus(status === null ? undefined : null);

                  return;
                }

                setStatus(v);
              }}
              value={status}
              width="230px"
              height="40px"
              options={decisionOptions}
            />
          </CommitteeDecisionDescriptionContainer>
        </CommitteeDecisionContainer>
      )}
      <ShouldRenderChildren shouldRender={!!status || uploadNewDoc}>
        <UploadModal
          openModal={!!status || uploadNewDoc}
          dialogTitle="Importar documentos"
          onSave={files => {
            handleSave(files);
          }}
          onClose={() => {
            handleCloseModal();
          }}
        />
      </ShouldRenderChildren>
    </>
  );
};
