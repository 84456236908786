import styled from 'styled-components';

export const ViewerContainer = styled.div`
  width: 100%;
  height: 550px;

  canvas {
    display: flex;
    margin: auto;
  }
`;
export const NoSuportContainer = styled.div`
  width: 100%;
  height: 300px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const BEE4Button = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1rem;
`;
