export function routeWithNoToken() {
  const route = window.location.pathname;
  switch (route) {
    case '/manual-emissor':
      return true;
    case '/termo-emissor':
      return true;
    case '/termo-parceiro':
      return true;
    case '/termo-cl':
      return true;
    case '/termo-cl-colaborador':
      return true;
    default:
      return false;
  }
}
