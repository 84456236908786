import dayjs from 'dayjs';
import React, { useState } from 'react';

import { Switch } from '@mui/material';

import useUsuarios from './hooks/usuarios';
import { Input } from '../../components/input';
import { Button } from '../../components/button';
import { Container } from '../../components/container';
import { ShouldRenderChildren } from '../../components/shouldRenderChildren';

import { UpdateModal } from './modals/updateModal';
import { DetailsModal } from './modals/detailsModal';

import {
  ContentColumns,
  FifthColumn,
  FirstColumn,
  FourthColumn,
  IndexColumns,
  InputGroup,
  SecondColumn,
  SeventhColumn,
  SixthColumn,
  TableRow,
  ThirdColumn,
  WithoutData,
  Wrapper,
} from './styles';
import { Usuario } from './fields';
import { formatCPFeCNPJ } from '../../utils/strings';

export const Usuarios = () => {
  const [detailsModal, setDetailsModal] = useState(false);
  const [updateUserModal, setUpdateUserModal] = useState(false);

  const [input, setInput] = useState('');
  const [queryParam, setQueryParam] = useState('');
  const [chosenUsuario, setChosenUsuario] = useState<Usuario | undefined>(
    undefined,
  );

  const { usuarios, ativarInativarUsuario, getUsuario } = useUsuarios({
    queryParameter: queryParam,
  });

  function userList() {
    return usuarios?.length > 0 ? (
      usuarios?.map((usuario: Usuario) => {
        return (
          <React.Fragment key={usuario.usuarioId}>
            <TableRow>
              <FirstColumn>
                <span>{usuario.nome}</span>
              </FirstColumn>
              <SecondColumn title={usuario.email} value>
                <span>{usuario.email}</span>
              </SecondColumn>
              <ThirdColumn>
                <span>{usuario.cpf ? formatCPFeCNPJ(usuario.cpf) : ''}</span>
              </ThirdColumn>
              <FourthColumn>
                <span>{usuario.descricaoDoGrupo}</span>
              </FourthColumn>
              <FifthColumn>
                <span>
                  {usuario.ultimoAcesso
                    ? dayjs(usuario.ultimoAcesso).format('DD/MM/YYYY')
                    : ''}
                </span>
              </FifthColumn>
              <SixthColumn>
                <Switch
                  checked={usuario.ativo}
                  onChange={() => {
                    setChosenUsuario(usuario);
                    setUpdateUserModal(true);
                  }}
                />
              </SixthColumn>
              <SeventhColumn>
                <Button
                  variant="text"
                  onClick={() => {
                    setChosenUsuario(usuario);
                    setDetailsModal(true);
                  }}
                >
                  DETALHES
                </Button>
              </SeventhColumn>
            </TableRow>
          </React.Fragment>
        );
      })
    ) : (
      <WithoutData>
        <h1>Nenhum resultado encontrado.</h1>
        <h2>Utilize outra palavra-chave e tente novamente.</h2>
      </WithoutData>
    );
  }

  return (
    <>
      <Container style={{ minWidth: '720px' }}>
        <Wrapper>
          <InputGroup>
            <Input
              label="Pesquisar por nome ou CPF"
              onChange={(v: string) => setInput(v)}
            />
          </InputGroup>
          <Button onClick={() => setQueryParam(input)} height="40px">
            Buscar
          </Button>
        </Wrapper>
        <IndexColumns>
          <FirstColumn>
            <span>Nome do usuário</span>
          </FirstColumn>
          <SecondColumn>
            <span>E-mail</span>
          </SecondColumn>
          <ThirdColumn>
            <span>CPF</span>
          </ThirdColumn>
          <FourthColumn>
            <span>Perfil</span>
          </FourthColumn>
          <FifthColumn>
            <span>Último acesso</span>
          </FifthColumn>
          <SixthColumn>
            <span>Acesso</span>
          </SixthColumn>
          <SeventhColumn />
        </IndexColumns>
        <ContentColumns>{userList()}</ContentColumns>
      </Container>
      <ShouldRenderChildren shouldRender={detailsModal}>
        <DetailsModal
          description={detailsModal}
          closeDropdown={() => {
            setDetailsModal(false);
            setChosenUsuario(undefined);
          }}
          userId={chosenUsuario?.usuarioId}
          getUsuario={getUsuario}
        />
      </ShouldRenderChildren>
      <ShouldRenderChildren shouldRender={updateUserModal}>
        <UpdateModal
          description={updateUserModal}
          closeDropdown={() => {
            setUpdateUserModal(false);
            setChosenUsuario(undefined);
          }}
          user={chosenUsuario}
          updateUser={ativarInativarUsuario}
        />
      </ShouldRenderChildren>
    </>
  );
};
