import styled from 'styled-components';

export const ModalForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  font-style: normal;

  h3 {
    color: var(--chumbo-2, #262936);
    font-family: Inter;
    font-size: 24px;
    font-weight: 700;
    line-height: normal;
  }

  p {
    color: var(--chumbo-1, #454b62);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
`;

export const ModalButtons = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ViewDocContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;
  gap: 1rem;
`;

export const PrazoEditContainer = styled.div`
  height: 170px;
  border-radius: 4px;
  padding: 16.5px 14px;
  position: relative;
  background-color: #f5f4f7;
  display: flex;
  flex-direction: column;
`;

export const PrazoEditIcon = styled.button`
  position: absolute;
  top: -1rem;
  right: 1rem;
  background: #ffff;
  padding: 1px 8px;
  border-radius: 4px;
  border: 1px solid #79747e;
`;

export const ReviewModalWithComments = styled.div`
  display: flex;
  flex-direction: column;
  gap 2rem;
`;

export const ReviewModalWithCommentsTitle = styled.h3`
  color: var(--Chumbo-2, #262936);
  font-size: 24px;
  font-weight: 700;
`;

export const AddReviewButton = styled.button`
  display: flex;
  padding: 8px;
  align-items: center;
  gap: 16px;
  color: #2dbcf7;
  border: none;
  background: none;
  font-weight: 600;
  font-size: 16px;
  border-radius: 4px;
  transition: background-color 0.3s ease;
  width: fit-content;

  &:active {
    background-color: #e0e0e0;
  }
`;

export const NewMessageTypography = styled.h4`
  color: #0852dd;
  font-size: 16px;
  font-weight: 600;

  margin-bottom: 1rem;
`;
