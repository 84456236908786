import React from 'react';
import {
  FooterDiv,
  Info,
  FirstLine,
  BEE4Logo,
  Icons,
  SocialMedia,
  Contact,
  SecondLine,
  ThirdLine,
  Disclaimer,
} from './styles';

export const Footer = () => {
  return (
    <FooterDiv>
      <Info>
        <FirstLine>
          <a
            href="https://www.bee4.com.br"
            target="_blank"
            rel="noopener noreferrer"
          >
            <BEE4Logo src="images/logo-mono-preto-bee4.svg" alt="BEE4" />
          </a>
          <Icons>
            <SocialMedia>
              <a
                href="https://instagram.com/bee4oficial?igshid=YmMyMTA2M2Y="
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="images/instagram.svg" alt="Instagram da BEE4" />
              </a>
              <a
                href="https://www.linkedin.com/company/bee4oficial/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="images/linkedin.svg" alt="LinkedIn da BEE4" />
              </a>
              <a
                href="https://m.facebook.com/bee4oficial/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="images/facebook.svg" alt="da BEE4" />
              </a>
              <a
                href="https://youtube.com/c/BEE4oficial"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="images/youtube.svg" alt="YouTube da BEE4" />
              </a>
            </SocialMedia>
            <Contact>
              <a
                href="mailto:emissores@bee4.com.br?subject=Contato BEE4"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="images/email.svg" alt="E-mail da BEE4" />
              </a>
            </Contact>
          </Icons>
        </FirstLine>
        {/* <SecondLine>
          <span>Rua Renato Paes de Barros, 33, 18º andar</span>
          <br />
          <span>Itaim - São Paulo</span>
          <br />
          <span>CEP 04530-904</span>
        </SecondLine> */}
        <ThirdLine>
          <span>
            Regulado por: Plataforma licenciada conforme ato declaratório n°
            17.300, de 8 de agosto de 2019
          </span>
          <hr />
        </ThirdLine>
      </Info>
      <Disclaimer>
        <div>
          <img src="images/cvm.png" alt="Logo da CVM" />
          <span>
            A BEE4 INTERMEDIACAO COMPENSACAO E LIQUIDACAO S.A., inscrita no CNPJ
            42.729.018/0001-79, é um ambiente de negociação de tokens
            representativos de valores mobiliários, autorizada como mercado de
            balcão organizado pela Comissão de Valores Mobiliários no âmbito de
            seu sandbox regulatório, nos termos da{' '}
            <a
              href="https://conteudo.cvm.gov.br/legislacao/resolucoes/resol029.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              Resolução CVM n° 29, de 11 de maio de 2021
            </a>
            , e{' '}
            <a
              href="https://conteudo.cvm.gov.br/legislacao/deliberacoes/deli0800/deli874.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              Deliberação CVM n° 874, de 30 de setembro de 2021
            </a>
            , cuja vigência vai do dia 07 de junho de 2022 a 06 de junho de
            2024. As atividades disponibilizadas neste site são realizadas em
            caráter experimental, mediante autorização temporária para
            desenvolvimento de atividade regulamentada no mercado de valores
            mobiliários brasileiro. O tipo de investimento disponibilizado neste
            site pode não ser adequado a todos os investidores, recomendamos que
            o investidor conheça as características e riscos de cada
            investimento antes de investir. Recomendamos também o preenchimento
            do seu perfil de investidor antes da realização de investimentos,
            bem como que entre em contato com o seu intermediário BEE4 para
            orientação com base em suas características e objetivos pessoais.
            Investimentos no mercado de capitais estão sujeitos a risco de perda
            superior ao valor total do capital investido. As informações
            disponíveis neste site tem caráter meramente informativo e não é uma
            recomendação para a realização de investimento. Em caso de dúvida
            entre em contato nos canais de atendimento dos intermediários BEE4.
          </span>
        </div>
        <span>© BEE4 2022. Todos os direitos reservados</span>
      </Disclaimer>
    </FooterDiv>
  );
};
