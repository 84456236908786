import React from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { AxiosResponse } from 'axios';
import { api } from '../../../../../services/api';
import { verifyError } from '../../../../../utils/verifyError';
import { EmpresaEscolhida } from '../fields';

export const useConsultorListagem = () => {
  const queryClient = useQueryClient();

  const getConsultorListagem = async () => {
    const getToast = toast.loading('Carregando colaboradores');
    try {
      const response: AxiosResponse<EmpresaEscolhida[]> = await api.main.get(
        `/v1/nomads`,
      );
      toast.update(getToast, {
        render: 'Colaboradores carregados com sucesso!',
        type: 'success',
        isLoading: false,
        autoClose: 1,
      });
      return response?.data;
    } catch (error) {
      const message = verifyError(error, 'Erro ao carregar colaboradores');
      toast.update(getToast, {
        render: message,
        type: 'error',
        isLoading: false,
        autoClose: 5000,
      });
    }
  };

  const {
    isLoading,
    isSuccess,
    isError,
    isIdle,
    data: nomads,
  } = useQuery(['nomads'], getConsultorListagem, {});

  const invalidateQuery = async () => {
    await queryClient.invalidateQueries('nomads');
  };

  const createDados = useMutation(async (payload: any) => {
    toast.loading('Enviando convite para um novo consultor de listagem');

    const response = await api.main.post(`/v1/nomads`, payload);
    toast.dismiss();

    invalidateQuery();

    return response;
  });

  const createConsultorDeListagem = (payload: any) => {
    createDados.mutate(payload, {
      onSuccess: async res => {
        toast.dismiss();
        if (res?.status === 200 || res?.status === 201) {
          toast.success('Convite enviado com sucesso!');
        }
      },
      onError: async (err: any) => {
        toast.dismiss();
        const message = verifyError(
          err,
          'Erro ao enviar convite para um novo consultor de listagem',
        );
        toast.error(message);
      },
    });
  };

  const updateDados = useMutation(async (payload: any) => {
    toast.loading('Atualizando consultor de listagem');
    const { id, company } = payload;

    const response = await api.main.put(`/v1/nomads/${id}`, company);

    invalidateQuery();

    return response;
  });

  const updateConsultorDeListagem = (payload: any) => {
    updateDados.mutate(payload, {
      onSuccess: async res => {
        toast.dismiss();
        if (res?.status === 200 || res?.status === 201) {
          toast.success('Consultor(a) de listagem atualizado com sucesso!');
        }
      },
      onError: async (err: any) => {
        toast.dismiss();
        const message = verifyError(
          err,
          'Erro ao atualizar o consultor de listagem',
        );
        toast.error(message);
      },
    });
  };

  const deleteDados = useMutation(async (payload: any) => {
    toast.loading('Desativando consultor de listagem');

    const { id, motivoExclucao } = payload;

    const response = await api.main.delete(`/v1/nomads/${id}`, {
      data: { motivoExclucao },
    });

    invalidateQuery();

    return response;
  });

  const activateDados = useMutation(async (payload: any) => {
    toast.loading('Ativando consultor de listagem');

    const { id } = payload;

    const response = await api.main.post(`/v1/nomads/${id}/ativar-conta`);

    invalidateQuery();

    return response;
  });

  const deleteConsultorDeListagem = (payload: any) => {
    deleteDados.mutate(payload, {
      onSuccess: async res => {
        toast.dismiss();
        if (res?.status === 200 || res?.status === 201) {
          toast.success('Consultor(a) de listagem excluído(a) com sucesso!');
        }
      },
      onError: async (err: any) => {
        toast.dismiss();
        const message = verifyError(
          err,
          'Erro ao excluir o consultor de listagem',
        );
        toast.error(message);
      },
    });
  };

  const activateConsultorDeListagem = (payload: any) => {
    activateDados.mutate(payload, {
      onSuccess: async res => {
        toast.dismiss();
        if (res?.status === 200 || res?.status === 201) {
          toast.success('Consultor(a) de listagem ativado(a) com sucesso!');
        }
      },
      onError: async (err: any) => {
        toast.dismiss();
        const message = verifyError(
          err,
          'Erro ao ativar o consultor de listagem',
        );
        toast.error(message);
      },
    });
  };

  const createCollaborator = useMutation(async (payload: any) => {
    toast.loading(
      'Enviando o convite de colaborador para uma empresa consultora de listagem',
    );
    const { id, consulter } = payload;

    const response = await api.main.post(
      `/v1/nomads/${id}/consultores`,
      consulter,
    );

    invalidateQuery();

    return response;
  });

  const createConsultorDeListagemCollaborator = (payload: any) => {
    createCollaborator.mutate(payload, {
      onSuccess: async res => {
        toast.dismiss();
        if (res?.status === 200 || res?.status === 201) {
          toast.success('Convite enviado com sucesso!');
        }
      },
      onError: async (err: any) => {
        toast.dismiss();
        const message = verifyError(
          err,
          'Erro ao enviar convite para o colaborador de uma empresa consultora de listagem',
        );
        toast.error(message);
      },
    });
  };

  const updateCollaborator = useMutation(async (payload: any) => {
    toast.loading('Atualizando colaborador da empresa consultora de listagem');
    const { id, consulter, consultorId } = payload;

    const response = await api.main.put(
      `/v1/nomads/${id}/consultores/${consultorId}`,
      consulter,
    );

    invalidateQuery();

    return response;
  });

  const updateConsultorDeListagemCollaborator = (payload: any) => {
    updateCollaborator.mutate(payload, {
      onSuccess: async res => {
        toast.dismiss();
        if (res?.status === 200 || res?.status === 201) {
          toast.success('Colaborador atualizado com sucesso!');
        }
      },
      onError: async (err: any) => {
        toast.dismiss();
        const message = verifyError(
          err,
          'Erro ao atualizar o colaborador para a empresa consultora de listagem',
        );
        toast.error(message);
      },
    });
  };

  const deleteCollaborator = useMutation(async (payload: any) => {
    toast.loading('Desativando colaborador de empresa consultora de listagem');

    const { companyId, consulterId, motivoExclucao } = payload;

    const response = await api.main.delete(
      `/v1/nomads/${companyId}/consultores/${consulterId}`,
      {
        data: { motivoExclucao },
      },
    );

    invalidateQuery();
    return response;
  });

  const activateCollaborator = useMutation(async (payload: any) => {
    toast.loading('Ativando colaborador de empresa consultora de listagem');

    const { companyId, consulterId } = payload;

    const response = await api.main.post(
      `/v1/nomads/${companyId}/consultores/${consulterId}/ativar-conta`,
    );

    invalidateQuery();
    return response;
  });

  const deleteConsultorDeListagemCollaborator = (payload: any) => {
    deleteCollaborator.mutate(payload, {
      onSuccess: async res => {
        toast.dismiss();
        if (res?.status === 200 || res?.status === 201) {
          toast.success('Colaborador(a) desativado(a) com sucesso!');
        }
      },
      onError: async (err: any) => {
        toast.dismiss();
        const message = verifyError(
          err,
          'Erro ao desativar o colaborador de empresa consultora de listagem',
        );
        toast.error(message);
      },
    });
  };

  const activateConsultorDeListagemCollaborator = (payload: any) => {
    activateCollaborator.mutate(payload, {
      onSuccess: async res => {
        toast.dismiss();
        if (res?.status === 200 || res?.status === 201) {
          toast.success('Colaborador(a) ativado(a) com sucesso!');
        }
      },
      onError: async (err: any) => {
        toast.dismiss();
        const message = verifyError(
          err,
          'Erro ao ativar o colaborador de empresa consultora de listagem',
        );
        toast.error(message);
      },
    });
  };

  const sendInvite = useMutation(async (payload: any) => {
    const { id } = payload;
    const response = await api.main.post(
      `/v1/nomads/${id}/enviar-convite-para-consultor-listagem`,
    );
    invalidateQuery();
    return response;
  });

  const inviteConsultorDeListagem = (payload: any) => {
    sendInvite.mutate(payload, {
      onSuccess: async res => {
        toast.dismiss();
        if (res?.status === 200 || res?.status === 201) {
          toast.success('Convite reenviado com sucesso!');
        }
      },
      onError: async (err: any) => {
        toast.dismiss();
        const message = verifyError(
          err,
          'Erro ao reenviar convite para o consultor de listagem',
        );
        toast.error(message);
      },
    });
  };

  const sendInviteColaborador = useMutation(async (payload: any) => {
    const { nomadId, consultorId } = payload;
    const response = await api.main.post(
      `/v1/nomads/${nomadId}/consultores/${consultorId}/enviar-convite-para-colaborador`,
    );
    invalidateQuery();
    return response;
  });

  const inviteConsultorDeListagemCollaborator = (payload: any) => {
    sendInviteColaborador.mutate(payload, {
      onSuccess: async res => {
        toast.dismiss();
        if (res?.status === 200 || res?.status === 201) {
          toast.success('Convite reenviado com sucesso!');
        }
      },
      onError: async (err: any) => {
        toast.dismiss();
        const message = verifyError(
          err,
          'Erro ao reenviar convite para o colaborador de uma empresa de consultores de listagem',
        );
        toast.error(message);
      },
    });
  };

  return {
    isLoading,
    isError,
    isIdle,
    isSuccess,
    nomads,
    getConsultorListagem,
    createConsultorDeListagem,
    updateConsultorDeListagem,
    deleteConsultorDeListagem,
    activateConsultorDeListagem,
    createConsultorDeListagemCollaborator,
    updateConsultorDeListagemCollaborator,
    deleteConsultorDeListagemCollaborator,
    activateConsultorDeListagemCollaborator,
    inviteConsultorDeListagem,
    inviteConsultorDeListagemCollaborator,
    queryClient,
  };
};

export default useConsultorListagem;
