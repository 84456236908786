import React from 'react';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio as MaterialRadio,
  FormHelperText,
} from '@mui/material';
import { RadioDiv } from './styles';

interface IOptions {
  label?: string | React.ReactNode;
  value: any;
}

interface IRadioProps {
  onChange: (e?: any) => void;
  label?: string | React.ReactNode;
  value: any;
  options: IOptions[];
  disabled?: boolean;
  row?: boolean;
  errorMessage?: string;
  style?: any;
  defaultValue?: any;
}

export const Radio: React.FC<IRadioProps> = ({
  onChange,
  label,
  value,
  options,
  disabled,
  row,
  errorMessage,
  defaultValue,
  style,
}) => {
  const generateRadioOptions = () => {
    return options.map(option => {
      return (
        <FormControlLabel
          value={option.value}
          key={option.value}
          label={option.label ? option.label : option.value}
          className="form-radio"
          control={
            <MaterialRadio
              size="small"
              disabled={disabled}
              checked={option.value == value}
              defaultValue={defaultValue}
            />
          }
        />
      );
    });
  };

  return (
    <RadioDiv>
      <FormControl component="fieldset">
        <FormLabel
          style={{ color: disabled ? '#bdbdbd' : '#424242', ...style }}
          component="legend"
        >
          {label}
        </FormLabel>
        <RadioGroup
          row={row}
          value={value ?? ''}
          onChange={e => {
            onChange(e);
          }}
        >
          {generateRadioOptions()}
        </RadioGroup>
        <FormHelperText style={{ color: '#d32f2f' }}>
          {errorMessage}
        </FormHelperText>
      </FormControl>
    </RadioDiv>
  );
};
