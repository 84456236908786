import React from 'react';
import MaterialButton from '@mui/material/Button';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Container } from './styles';

// const theme = createTheme({
//   palette: {
//     primary: {
//       main: '#161A32',
//       contrastText: '#fff',
//     },
//     secondary: {
//       main: '#212e51',
//     },
//     info: {
//       main: '#bdbdbd',
//     },
//     warning: {
//       main: '#F7981D',
//       contrastText: '#fff',
//     },
//   },
// });

const theme = createTheme({
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: 'contained', color: 'primary' },
          style: {
            backgroundImage:
              'linear-gradient(90deg, #49DBDF 0%, #219AEE 22.16%, #0A4CDC 48.26%, #1E09D0 73.1%, #04037A 100%)',
            fontFamily: 'Georama',
            '& span': {
              color: 'white',
            },
            '&:disabled': {
              backgroundImage: 'none',
              backgroundColor: '#C8C8C8 !important',
              color: 'white',
            },
          },
        },
        {
          props: { variant: 'contained', color: 'secondary' },
          style: {
            backgroundColor: '#000 !important',
            fontFamily: 'Georama',
            '& span': {
              color: 'white',
              fontSize: '0.75rem',
              fontFamily: 'Georama',
              fontWeight: '600',
              lineHeight: '1rem',
              letterSpacing: '0.0625rem',
            },
            '&:disabled': {
              backgroundColor: '#C8C8C8 !important',
              color: 'white',
              fontSize: '0.75rem',
              fontFamily: 'Georama',
              fontWeight: '600',
              lineHeight: '1rem',
              letterSpacing: '0.0625rem',
            },
          },
        },
        {
          props: { variant: 'text' },
          style: {
            fontFamily: 'Georama',
            '&:disabled': {
              backgroundImage: 'none',
            },
            color: '#2dbcf7',
            backgroundColor: 'unset!important',
          },
        },
        {
          props: { color: 'info' },
          style: {
            background: '#bdbdbd',
            fontFamily: 'Georama',
            color: 'black',
          },
        },
        {
          props: { variant: 'outlined', color: 'secondary' },
          style: {
            border: '2px solid black',
            color: 'black',
            transition: 'border-width 0.2s ease-in-out',
            '&:hover': {
              border: '2px solid black',
              boxShadow: '0 0 0 1px rgba(0, 0, 0, 0.5)',
            },
            '&:disabled': {
              color: 'rgba(0, 0, 0, 0.26)',
              boxShadow: 'none',
              backgroundColor: 'rgba(0, 0, 0, 0.12)',
            },

            background: 'none',
          },
        },
      ],
    },
  },
});

interface ButtonProps {
  onClick?: any;
  size?: 'small' | 'medium' | 'large';
  disabled?: boolean;
  children: any;
  variant?: 'text' | 'outlined' | 'contained';
  width?: string;
  height?: string;
  fontSize?: string;
  color?:
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'error'
    | 'info'
    | 'warning';
  type?: 'button' | 'submit' | 'reset';
  startIcon?: any;
  style?: any;
}

export const Button: React.FC<ButtonProps> = ({
  onClick,
  size,
  disabled,
  children,
  variant = 'contained',
  width,
  height,
  fontSize = '12px',
  color = 'primary',
  type = 'button',
  startIcon,
  style,
}) => {
  return (
    <Container disabled={disabled}>
      <ThemeProvider theme={theme}>
        <MaterialButton
          disabled={disabled}
          size={size}
          variant={variant}
          onClick={onClick}
          color={color}
          type={type}
          startIcon={startIcon}
          style={{
            width,
            height,
            fontSize,
            fontWeight: 400,
            lineHeight: 1.2,
            borderRadius: '4px',
            textTransform: 'uppercase',
            textDecoration: 'none',
            backgroundColor: '#fff',
            ...style,
          }}
        >
          {children}
        </MaterialButton>
      </ThemeProvider>
    </Container>
  );
};
