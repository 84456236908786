import React, { useEffect } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import axios, { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { api } from '../../../services/api';
import { DocumentPerCategory, IUseDocumentsHooks } from '../types';
import { verifyError } from '../../../utils/verifyError';

export const useDocuments = ({
  currentEnterpriseId,
  stage,
  year,
}: IUseDocumentsHooks) => {
  const queryClient = useQueryClient();

  const getAllDocuments = async () => {
    if (stage !== 'relatorio') {
      const response: AxiosResponse<DocumentPerCategory[]> = await api.main.get(
        `/v1/empresas/${currentEnterpriseId}/documentos?etapa=${stage}`,
      );

      return response?.data;
    }

    if (!year) return [];

    const response: AxiosResponse<DocumentPerCategory[]> = await api.main.get(
      `/v1/empresas/${currentEnterpriseId}/documentos-relatorio/ano/${year}`,
    );

    return response?.data;
  };

  const {
    isLoading,
    isSuccess,
    isError,
    isIdle,
    data: categories,
  } = useQuery(
    ['categories', currentEnterpriseId, stage, year],
    getAllDocuments,
    {
      enabled: !!currentEnterpriseId && !!stage,
    },
  );

  const invalidateQuery = async () => {
    await queryClient.invalidateQueries('categories');
  };

  const createDocument = useMutation(async (payload: any) => {
    if (stage !== 'relatorio') {
      const response = await api.main.post(
        `/v1/empresas/${currentEnterpriseId}/documentos`,
        payload,
      );
      invalidateQuery();
      return response;
    }

    const response = await api.main.post(
      `/v1/empresas/${currentEnterpriseId}/documentos-relatorio`,
      {
        ...payload,
        ano: year,
      },
    );
    invalidateQuery();
    return response;
  });

  const updateDocument = useMutation(async (payload: any) => {
    const { currentEnterpriseId, document, stage, id } = payload;

    const response = await api.main.put(
      `/v1/empresas/${currentEnterpriseId}/documentos/${id}/arquivo`,
      {
        nome: document.nome,
        base64Content: document.base64Content,
      },
    );

    invalidateQuery();
    return response;
  });

  const deleteDocument = useMutation(async (payload: any) => {
    const { currentEnterpriseId, documentId } = payload;
    if (stage !== 'relatorio') {
      const response = await api.main.delete(
        `/v1/empresas/${currentEnterpriseId}/documentos/${documentId}`,
      );

      invalidateQuery();
      return response;
    }

    const response = await api.main.delete(
      `/v1/empresas/${currentEnterpriseId}/documentos-relatorio/${documentId}`,
    );

    invalidateQuery();
    return response;
  });

  const downloadDocument = useMutation(async (payload: any) => {
    const { currentEnterpriseId, documentId } = payload;
    if (stage !== 'relatorio') {
      const response = await api.main.get(
        `/v2/empresas/${currentEnterpriseId}/documentos/${documentId}/download`,
      );

      return response;
    }

    const response = await api.main.get(
      `/v1/empresas/${currentEnterpriseId}/documentos-relatorio/download/${documentId}`,
    );

    return response;
  });

  const updateStatusDocument = useMutation(async (payload: any) => {
    const { currentEnterpriseId, documentId, value, stage } = payload;
    const response = await api.main.put(
      `/v1/empresas/${currentEnterpriseId}/documentos/${documentId}`,
      { status: value },
    );
    invalidateQuery();
    return response;
  });

  const updateMandatoryType = useMutation(async (payload: any) => {
    const { categoryId, value } = payload;
    const response = await api.main.put(
      `/v1/empresas/${currentEnterpriseId}/complemento-de-categorias-de-documento/${categoryId}/declaracao-de-documento`,
      { declaracaoDocumento: value },
    );
    invalidateQuery();
    return response;
  });

  const updateDeclaracaoDocumento = (payload: any) => {
    updateMandatoryType.mutate(payload, {
      onSuccess: async res => {
        toast.dismiss();
        if (res?.status === 200 || res?.status === 201) {
          toast.success('Dados atualizados com sucesso!');
        }
      },
      onError: async (err: any) => {
        toast.dismiss();
        const message = verifyError(
          err,
          'Erro ao tentar atualizar a declaração do documento',
        );
        toast.error(message);
      },
    });
  };

  return {
    isLoading,
    isError,
    isIdle,
    isSuccess,
    categories,
    getAllDocuments,
    createDocument,
    updateDocument,
    deleteDocument,
    downloadDocument,
    updateStatusDocument,
    updateDeclaracaoDocumento,
    queryClient,
  };
};

export default useDocuments;
