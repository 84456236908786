import * as msal from '@azure/msal-browser';
import jwt from 'jsonwebtoken';

class AuthenticationManager {
  private token = '';

  private tokenExpiry: Date = new Date();

  private appUrl = process.env.REACT_APP_URL_WEB_TECH as string;

  private clientId = process.env.REACT_APP_B2C_CLIENT_ID_TECH as string;

  private tenantId = process.env.REACT_APP_B2C_TENANT_ID as string;

  private policy = process.env.REACT_APP_B2C_POLICY as string;

  private b2cUrl = process.env.REACT_APP_B2C_URL as string;

  private authority =
    `${this.b2cUrl}/tfp/${this.tenantId}/${this.policy}` as string;

  private msalConfig: msal.Configuration = {
    auth: {
      clientId: this.clientId,
      authority: this.authority,
      knownAuthorities: [this.authority],
      redirectUri: this.appUrl,
    },
  };

  private msalRedirectRequest: msal.RedirectRequest = {
    redirectUri: this.appUrl,
    scopes: [this.clientId],
  };

  private msalSilentRequest: msal.SilentRequest = {
    scopes: [this.clientId],
  };

  private msalInstance = new msal.PublicClientApplication(this.msalConfig);

  public async signIn(): Promise<void> {
    try {
      const tokenResponse: msal.AuthenticationResult | null =
        await this.msalInstance.handleRedirectPromise();

      const enterpriseId: any =
        (await window.localStorage.getItem('empresa')) || '';

      if (!tokenResponse) {
        await this.msalInstance.loginRedirect({
          ...this.msalRedirectRequest,
          extraQueryParameters: enterpriseId,
        });

        return;
      }
      // eslint-disable-next-line
      this.setToken(tokenResponse.idToken);
    } catch (err) {
      console.error('[handleRedirectPromise] Erro ao fazer login.', err);
    }
  }

  public async refreshToken(): Promise<void> {
    try {
      const account = this.msalInstance.getAllAccounts()[0];
      const tokenResponse = await this.msalInstance.acquireTokenSilent({
        ...this.msalSilentRequest,
        forceRefresh: true,
        account,
      });
      if (!tokenResponse) {
        return;
      }
      // eslint-disable-next-line
      this.setToken(tokenResponse.idToken);
    } catch (err) {
      console.error(
        '[handleRedirectPromise] Erro ao fazer refresh token.',
        err,
      );

      const enterpriseId: any =
        (await window.localStorage.getItem('empresa')) || '';

      this.msalInstance.acquireTokenRedirect({
        ...this.msalRedirectRequest,
        extraQueryParameters: enterpriseId,
      });
    }
  }

  public async getTokenAsync(): Promise<string> {
    if (!!this.token && this.tokenExpiry > new Date()) {
      return this.token;
    }

    const account = this.msalInstance.getAllAccounts()[0];

    if (!account) {
      // eslint-disable-next-line
      await this.signIn();
      return '';
    }

    try {
      const tokenResponse = await this.msalInstance.acquireTokenSilent({
        ...this.msalSilentRequest,
        account: this.msalInstance.getAllAccounts()[0],
      });

      if (!tokenResponse) {
        return '';
      }
      // eslint-disable-next-line
      this.setToken(tokenResponse.idToken);

      return tokenResponse.idToken;
    } catch (err) {
      console.error('[getTokenAsync]', err);
      return '';
    }
  }

  public async logOut(): Promise<void> {
    await this.msalInstance.logoutRedirect({ postLogoutRedirectUri: '/' });
  }

  public async logOutInactiveUser() {
    /* jshint -W069 */
    await (this.msalInstance as any).browserStorage.clear();
    /* jshint +W069 */
    await this.msalInstance.logoutRedirect({ postLogoutRedirectUri: '/' });
  }

  private setToken(token: string): void {
    const decoded = jwt.decode(token);
    if (!decoded) {
      return;
    }
    if (typeof decoded === 'string') {
      return;
    }
    if (!decoded.exp) {
      return;
    }
    this.token = token;
    this.tokenExpiry = new Date(decoded.exp * 1000);
  }
}

export const authenticationManager = new AuthenticationManager();
