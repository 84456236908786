/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, useFieldArray } from 'react-hook-form';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { DocumentList } from '../../components/documents';
import { Grid } from '../../components/grid';
import { Controller } from '../../components/controller';
import {
  ContentTitle,
  Division,
  Buttons,
  FormContent,
  ContentDescription,
  Plus,
  InputDelete,
  SmallDivision,
  ContentTitleDescription,
  InputDescription,
  FinancialYearContainer,
  FinancialYearDescription,
  UploadModalContent,
  UploadModalTitle,
  ModalFilesSuported,
} from './styles';
import { Button } from '../../components/button';
import {
  AuditadoOptions,
  FinancialForm,
  FinancialFormSubmit,
  Indicadores,
  IndicadoresColunas,
  listCycleMoment,
  listMainSuppliers,
  listMarketConcentration,
  listRisks,
  listRisks2,
  mockRegion,
  schema,
} from './fields';
import { useEnterprise } from '../../hooks/enterprise';
import { useAuth } from '../../hooks/auth';
import { listYesNo } from '../../utils/fields';
import {
  dateToTimestamp,
  dateYear,
  timestampToDate,
} from '../../utils/dateHandling';
import { isValid } from '../../services/isValid';
import { AddEditFieldModal } from './components/addEditFieldModal';
import useFinanceiro from './hooks/financeiro';
import { Tooltip } from '../../components/tooltip';
import { UploadModal } from '../../components/uploadModal';
import { toBase64 } from '../../services/toBase64';
import { Container } from '../../components/container';

export const Financeiro = () => {
  const { currentEnterpriseId, enterprises } = useEnterprise();
  const { currentRole } = useAuth();

  const {
    financeiroData,
    isLoading,
    submitDadosFinanceiro,
    dowloadTemplate,
    submitTemplate,
  } = useFinanceiro({
    currentEnterpriseId,
  });

  const isCompletelyDisabled =
    currentRole === 'Empreendedor' &&
    enterprises?.length > 0 &&
    enterprises[0].termoEmissor === false;

  const [chosenInd, setChosenInd] = useState<any>({});

  const [addRowModal, setAddRowModal] = useState(false);
  const [uploadModal, setUploadModal] = useState(false);
  const [templateType, setTemplateType] = useState<undefined | 1 | 2 | 3 | 4>(
    undefined,
  );
  const [fieldType, setFieldType] = useState<{ type?: 1 | 2; field?: 1 | 2 }>({
    type: undefined,
    field: undefined,
  });
  const [dataIndicadoresFinanceiros, setDataIndicadoresFinanceiros] = useState<
    Indicadores[]
  >([]);
  const [dataBalancoPatrimonial, setDataBalancoPatrimonial] = useState<
    Indicadores[]
  >([]);

  const [disabled, setIsDisabled] = useState(false);
  const isDisabled = disabled || isLoading || isCompletelyDisabled;
  const { handleSubmit, control, watch, reset, getValues, formState } =
    useForm<FinancialFormSubmit>({
      resolver: yupResolver(schema),
    });

  const dataReferenciaDosIndicadoresFinanceiros = watch(
    'dataReferenciaDosIndicadoresFinanceiros',
  );

  const dataReferenciaDoBalancoPatrimonial = watch(
    'dataReferenciaDoBalancoPatrimonial',
  );

  const handleFieldSize = () => {
    if (fieldType.type === 1 && fieldType.field === 1) {
      return (
        handleIndicadores(
          dataIndicadoresFinanceiros?.filter(
            (v: Indicadores) => v.referencia <= 5,
          ),
        ).length + 1
      );
    }
    if (fieldType.field === 1 && fieldType.type === 2) {
      return (
        handleIndicadores(
          dataIndicadoresFinanceiros?.filter(
            (v: Indicadores) => v.referencia > 5,
          ),
        ).length + 1
      );
    }
    if (fieldType.field === 2 && fieldType.type === 1) {
      return (
        handleIndicadores(
          dataBalancoPatrimonial?.filter((v: Indicadores) => v.referencia <= 5),
        ).length + 1
      );
    }
    if (fieldType.field === 2 && fieldType.type === 2) {
      return (
        handleIndicadores(
          dataBalancoPatrimonial?.filter((v: Indicadores) => v.referencia > 5),
        ).length + 1
      );
    }
    return 0;
  };

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'distribuicaoDeReceitaLiquidaPorRegiaoOuPais',
  });

  const handleRegion = () => {
    return (
      <>
        {fields.map((item, index) => (
          <React.Fragment key={item.id}>
            <Grid item desktop={6}>
              <Controller
                name={`distribuicaoDeReceitaLiquidaPorRegiaoOuPais.${index}.regiaoOuPais`}
                control={control}
                type="input"
                label=""
                onlyCharacters
                disabled={isDisabled}
              />
            </Grid>
            <Grid item desktop={6}>
              <InputDelete>
                <Controller
                  name={`distribuicaoDeReceitaLiquidaPorRegiaoOuPais.${index}.receitaLiquida`}
                  control={control}
                  type="input"
                  label=""
                  mask="currency"
                  disabled={isDisabled}
                />
                {fields.length > 1 ? (
                  <button
                    style={{
                      background: 'none',
                      border: 'none',
                    }}
                    type="button"
                    onClick={() => {
                      remove(index);
                    }}
                    disabled={isDisabled}
                    aria-label="Deletar região"
                  >
                    <DeleteIcon htmlColor="#212E51" />
                  </button>
                ) : null}
              </InputDelete>
            </Grid>
          </React.Fragment>
        ))}
      </>
    );
  };

  function handleIndicadoresColumnsFront(
    data: IndicadoresColunas[] | undefined,
  ): (number | undefined)[] | undefined {
    if (!data) {
      return;
    }
    const resultado: (number | undefined)[] = Array.from(
      { length: Math.max(...data.map(objeto => objeto.referencia)) },
      () => undefined,
    );

    data.forEach(objeto => {
      if (objeto.auditado !== undefined) {
        resultado[objeto.referencia - 1] = objeto.auditado;
      }
    });

    return resultado;
  }

  const handleIndicadoresFront = (dataV: Indicadores[], tipo: 1 | 2) => {
    let type: string;
    if (tipo === 1) {
      setDataIndicadoresFinanceiros(dataV);
      type = 'indicadoresFinanceiros';
    } else {
      setDataBalancoPatrimonial(dataV);
      type = 'balancoPatrimonial';
    }
    const newValues: any = {};

    dataV.map(ind => {
      if (!newValues[ind.label]) {
        newValues[ind.label] = {};
      }
      if (!newValues[ind.label][ind.ordem]) {
        newValues[ind.label][ind.ordem] = {};
      }
      if (!newValues[ind.label][ind.ordem][ind.referencia]) {
        newValues[ind.label][ind.ordem][ind.referencia] = ind;
      }
    });

    return newValues;
  };

  function handleIndicadoresColumnsBack(
    arr: (number | undefined)[],
  ): IndicadoresColunas[] {
    return arr?.map((valor, indice) => {
      return {
        referencia: indice + 1,
        auditado: valor !== null ? valor : undefined,
      };
    });
  }

  const handleIndicadoresBack = (dataV: FinancialFormSubmit): FinancialForm => {
    const { indicadoresFinanceiros, balancoPatrimonial } = dataV;

    const keysI = Object.keys(indicadoresFinanceiros);
    const keysB = Object.keys(balancoPatrimonial);

    const valuesI: Indicadores[] = [];
    const valuesB: Indicadores[] = [];

    keysI.map(label => {
      const ordemL = Object.keys(indicadoresFinanceiros[label]);

      ordemL?.map(ordem => {
        if (indicadoresFinanceiros[label][ordem])
          return Object.keys(indicadoresFinanceiros[label][ordem]).map(
            referencia => {
              const campo = indicadoresFinanceiros[label][ordem][referencia];
              if (campo.label && campo.referencia && campo.ordem)
                valuesI.push(campo);
            },
          );
      });
    });

    keysB.map(label => {
      const ordemL = Object.keys(balancoPatrimonial[label]);

      ordemL?.map(ordem => {
        if (balancoPatrimonial[label][ordem])
          return Object.keys(balancoPatrimonial[label][ordem]).map(
            referencia => {
              const campo = balancoPatrimonial[label][ordem][referencia];
              if (campo.label && campo.referencia && campo.ordem)
                valuesB.push(campo);
            },
          );
      });
    });

    return {
      ...dataV,
      indicadoresFinanceiros: valuesI,
      balancoPatrimonial: valuesB,
      indicadoresFinanceirosColunas: handleIndicadoresColumnsBack(
        dataV.indicadoresFinanceirosColunas,
      ),
      balancoPatrimonialColunas: handleIndicadoresColumnsBack(
        dataV.balancoPatrimonialColunas,
      ),
    };
  };

  const submitForm = (dataV: FinancialFormSubmit) => {
    const newData = handleIndicadoresBack(dataV);

    submitDadosFinanceiro({
      ...newData,
      dataReferenciaDosIndicadoresFinanceiros: dateToTimestamp(
        newData.dataReferenciaDosIndicadoresFinanceiros,
      ),
      dataReferenciaDoBalancoPatrimonial: dateToTimestamp(
        newData.dataReferenciaDoBalancoPatrimonial,
      ),
      dataDeReferenciaDoValuationPreMoney: dateToTimestamp(
        newData.dataDeReferenciaDoValuationPreMoney,
      ),
      indicadoresFinanceirosAuditados:
        newData?.indicadoresFinanceirosAuditados === 'true',
      balancoPatrimonialAuditado:
        newData?.balancoPatrimonialAuditado === 'true',
    });
  };

  const handleAddField = (
    field: Indicadores[],
    newField: Indicadores[],
    isType: boolean,
    isBefore?: boolean,
  ) => {
    if (isType) {
      if (isBefore) {
        return [...field, ...newField].sort((a, b) => {
          if (a.ordem === b.ordem) {
            if (a.chave && !b.chave) {
              return -1;
            }
            if (!a.chave && b.chave) {
              return 1;
            }
            return 0;
          }
          return a.ordem - b.ordem;
        });
      }
      return [...field, ...newField].sort((a, b) => {
        if (a.ordem === b.ordem) {
          if (a.chave && !b.chave) {
            return 1;
          }
          if (!a.chave && b.chave) {
            return -1;
          }
          return 0;
        }
        return a.ordem - b.ordem;
      });
    }
    return field;
  };

  const addField = (field: Indicadores[], fieldType?: 1 | 2) => {
    const dataV = getValues();
    const newData = handleIndicadoresBack(dataV);

    submitDadosFinanceiro({
      ...newData,
      dataReferenciaDosIndicadoresFinanceiros: dateToTimestamp(
        newData.dataReferenciaDosIndicadoresFinanceiros,
      ),
      dataReferenciaDoBalancoPatrimonial: dateToTimestamp(
        newData.dataReferenciaDoBalancoPatrimonial,
      ),
      dataDeReferenciaDoValuationPreMoney: dateToTimestamp(
        newData.dataDeReferenciaDoValuationPreMoney,
      ),
      indicadoresFinanceiros: handleAddField(
        newData?.indicadoresFinanceiros,
        field,
        fieldType === 1,
      ),
      balancoPatrimonial: handleAddField(
        newData?.balancoPatrimonial,
        field,
        fieldType === 2,
      ),
      indicadoresFinanceirosAuditados:
        newData?.indicadoresFinanceirosAuditados === 'true',
      balancoPatrimonialAuditado:
        newData?.balancoPatrimonialAuditado === 'true',
    });
  };

  const deleteField = (chave?: string, type?: 1 | 2, field?: 1 | 2) => {
    const dataV = getValues();
    const newData = handleIndicadoresBack(dataV);
    const { indicadoresFinanceiros, balancoPatrimonial } = newData;

    let ind = indicadoresFinanceiros ?? [];
    let bal = balancoPatrimonial ?? [];
    if (field === 1) {
      if (type === 1) {
        ind = indicadoresFinanceiros?.filter(
          v => v.chave !== chave || v.referencia > 5,
        );
      } else if (type === 2) {
        ind = indicadoresFinanceiros?.filter(
          v => v.chave !== chave || v.referencia <= 5,
        );
      }
    } else if (field === 2) {
      if (type === 1) {
        bal = balancoPatrimonial?.filter(
          v => v.chave !== chave || v.referencia > 5,
        );
      } else if (type === 2) {
        bal = balancoPatrimonial?.filter(
          v => v.chave !== chave || v.referencia <= 5,
        );
      }
    }

    submitDadosFinanceiro({
      ...newData,
      dataReferenciaDosIndicadoresFinanceiros: dateToTimestamp(
        newData.dataReferenciaDosIndicadoresFinanceiros,
      ),
      dataReferenciaDoBalancoPatrimonial: dateToTimestamp(
        newData.dataReferenciaDoBalancoPatrimonial,
      ),
      dataDeReferenciaDoValuationPreMoney: dateToTimestamp(
        newData.dataDeReferenciaDoValuationPreMoney,
      ),
      indicadoresFinanceiros: ind,
      balancoPatrimonial: bal,
      indicadoresFinanceirosAuditados:
        newData?.indicadoresFinanceirosAuditados === 'true',
      balancoPatrimonialAuditado:
        newData?.balancoPatrimonialAuditado === 'true',
    });
  };

  const editField = (
    result: Indicadores[],
    chave?: string,
    type?: 1 | 2,
    field?: 1 | 2,
    isBefore?: boolean,
  ) => {
    const dataV = getValues();
    const newData = handleIndicadoresBack(dataV);
    const { indicadoresFinanceiros, balancoPatrimonial } = newData;

    let ind = indicadoresFinanceiros ?? [];
    let bal = balancoPatrimonial ?? [];
    if (field === 1) {
      if (type === 1) {
        ind = indicadoresFinanceiros?.filter(
          v => v.chave !== chave || v.referencia > 5,
        );
      } else if (type === 2) {
        ind = indicadoresFinanceiros?.filter(
          v => v.chave !== chave || v.referencia <= 5,
        );
      }
    } else if (field === 2) {
      if (type === 1) {
        bal = balancoPatrimonial?.filter(
          v => v.chave !== chave || v.referencia > 5,
        );
      } else if (type === 2) {
        bal = balancoPatrimonial?.filter(
          v => v.chave !== chave || v.referencia <= 5,
        );
      }
    }

    submitDadosFinanceiro({
      ...newData,
      dataReferenciaDosIndicadoresFinanceiros: dateToTimestamp(
        newData?.dataReferenciaDosIndicadoresFinanceiros,
      ),
      dataReferenciaDoBalancoPatrimonial: dateToTimestamp(
        newData.dataReferenciaDoBalancoPatrimonial,
      ),
      dataDeReferenciaDoValuationPreMoney: dateToTimestamp(
        newData.dataDeReferenciaDoValuationPreMoney,
      ),
      indicadoresFinanceiros: handleAddField(
        ind,
        result,
        field === 1,
        isBefore,
      ),
      balancoPatrimonial: handleAddField(bal, result, field === 2, isBefore),
      indicadoresFinanceirosAuditados:
        newData?.indicadoresFinanceirosAuditados === 'true',
      balancoPatrimonialAuditado:
        newData?.balancoPatrimonialAuditado === 'true',
    });
  };

  useEffect(() => {
    reset({
      processoDeDistribuicaoOuProducao:
        financeiroData?.processoDeDistribuicaoOuProducao,
      forcasDaMatrizSWOT: financeiroData?.forcasDaMatrizSWOT,
      fraquezasDaMatrizSWOT: financeiroData?.fraquezasDaMatrizSWOT,
      oportunidadesDaMatrizSWOT: financeiroData?.oportunidadesDaMatrizSWOT,
      ameacasDaMatrizSWOT: financeiroData?.ameacasDaMatrizSWOT,
      principaisCompetidores: financeiroData?.principaisCompetidores,
      distribuicaoDeReceitaLiquidaPorRegiaoOuPais:
        financeiroData?.distribuicaoDeReceitaLiquidaPorRegiaoOuPais
          ? financeiroData.distribuicaoDeReceitaLiquidaPorRegiaoOuPais
          : mockRegion,
      publicoAlvo: financeiroData?.publicoAlvo,
      focoGeografico: financeiroData?.focoGeografico,
      tamanhoDoMercadoPotencial: financeiroData?.tamanhoDoMercadoPotencial,
      explicacaoDoCalculoDoTamanhoDoMercadoPotencial:
        financeiroData?.explicacaoDoCalculoDoTamanhoDoMercadoPotencial,
      concentracaoDoMercadoDeAtuacao:
        financeiroData?.concentracaoDoMercadoDeAtuacao,
      maturidadeDoMercadoDeAtuacao:
        financeiroData?.maturidadeDoMercadoDeAtuacao,
      concentracaoDosPrincipaisFornecedores:
        financeiroData?.concentracaoDosPrincipaisFornecedores,
      principaisRiscos: financeiroData?.principaisRiscos,
      riscosDeExecucao: financeiroData?.riscosDeExecucao,
      riscoCambial: financeiroData?.riscoCambial,
      riscoFiscal: financeiroData?.riscoFiscal,
      riscoTecnologico: financeiroData?.riscoTecnologico,
      riscosDeAlavancagem: financeiroData?.riscosDeAlavancagem,
      riscoRegulatorio: financeiroData?.riscoRegulatorio,
      explicacaoDoEbitdaAjustado: financeiroData?.explicacaoDoEbitdaAjustado,
      indicadoresFinanceirosAuditados:
        financeiroData?.indicadoresFinanceirosAuditados?.toString(),
      dataReferenciaDosIndicadoresFinanceiros: timestampToDate(
        financeiroData?.dataReferenciaDosIndicadoresFinanceiros,
      ),
      indicadoresFinanceiros: handleIndicadoresFront(
        financeiroData?.indicadoresFinanceiros ?? [],
        1,
      ),
      indicadoresFinanceirosColunas: handleIndicadoresColumnsFront(
        financeiroData?.indicadoresFinanceirosColunas,
      ),
      balancoPatrimonialAuditado:
        financeiroData?.balancoPatrimonialAuditado?.toString(),
      dataReferenciaDoBalancoPatrimonial: timestampToDate(
        financeiroData?.dataReferenciaDoBalancoPatrimonial,
      ),
      balancoPatrimonial: handleIndicadoresFront(
        financeiroData?.balancoPatrimonial ?? [],
        2,
      ),
      balancoPatrimonialColunas: handleIndicadoresColumnsFront(
        financeiroData?.balancoPatrimonialColunas,
      ),
      valuationPreMoney: financeiroData?.valuationPreMoney,
      dataDeReferenciaDoValuationPreMoney: timestampToDate(
        financeiroData?.dataDeReferenciaDoValuationPreMoney,
      ),
      valorDaEmpresaOuEbitda: financeiroData?.valorDaEmpresaOuEbitda,
      capitalizacaoDeMercadoAoPrecoDaOferta:
        financeiroData?.capitalizacaoDeMercadoAoPrecoDaOferta,
      caixaLiquidoEInvestimentosFinanceiros:
        financeiroData?.caixaLiquidoEInvestimentosFinanceiros,
      valorDaEmpresaAoPrecoDaOferta:
        financeiroData?.valorDaEmpresaAoPrecoDaOferta,
      relacaoPrecoLucroAjustada: financeiroData?.relacaoPrecoLucroAjustada,

      relacaoPrecoLucro: financeiroData?.relacaoPrecoLucro,
      comentarioDaEmpresaSobreSuaAvaliacao:
        financeiroData?.comentarioDaEmpresaSobreSuaAvaliacao,
      qualSeraADestinacaoDosRecursosDaOferta:
        financeiroData?.qualSeraADestinacaoDosRecursosDaOferta,
    });
    setIsDisabled(financeiroData ? financeiroData.isReadOnly : true);
  }, [financeiroData, reset]);

  const handleDate = ({
    date,
    value,
    symbol,
  }: {
    date: string | null;
    value: 1 | 2 | 3 | 4 | 5;
    symbol?: '-' | '+';
  }) => {
    const refDate = dateYear(date);
    if (refDate && isValid.date(date)) {
      if (symbol === '-')
        return (
          <ContentTitleDescription>{refDate - value}</ContentTitleDescription>
        );
      return (
        <FinancialYearContainer>
          <ContentTitleDescription>{refDate + value}</ContentTitleDescription>
        </FinancialYearContainer>
      );
    }
    if (symbol === '-') {
      if (value === 1) {
        return (
          <FinancialYearContainer>
            <img src="images/bdi-sec_historic_before.svg" alt="" />
            <FinancialYearDescription>
              Ano anterior à data de referência
            </FinancialYearDescription>
          </FinancialYearContainer>
        );
      }
      if (value === 2) {
        return (
          <FinancialYearContainer>
            <img src="images/bdi-sec_historic_before.svg" alt="" />
            <FinancialYearDescription>
              Segundo ano anterior à data referência
            </FinancialYearDescription>
          </FinancialYearContainer>
        );
      }
      if (value === 3) {
        return (
          <FinancialYearContainer>
            <img src="images/bdi-sec_historic_before.svg" alt="" />
            <FinancialYearDescription>
              Terceiro ano anterior à data referência
            </FinancialYearDescription>
          </FinancialYearContainer>
        );
      }
    }
    if (value === 1) {
      return (
        <FinancialYearContainer>
          <img src="images/bdi-sec_historic_after.svg" alt="" />
          <FinancialYearDescription>
            Ano posterior à data referência
          </FinancialYearDescription>
        </FinancialYearContainer>
      );
    }
    if (value === 2) {
      return (
        <FinancialYearContainer>
          <img src="images/bdi-sec_historic_after.svg" alt="" />
          <FinancialYearDescription>
            2º ano posterior à data referência
          </FinancialYearDescription>
        </FinancialYearContainer>
      );
    }
    if (value === 3) {
      return (
        <FinancialYearContainer>
          <img src="images/bdi-sec_historic_after.svg" alt="" />
          <FinancialYearDescription>
            3º ano posterior à data referência
          </FinancialYearDescription>
        </FinancialYearContainer>
      );
    }
    if (value === 4) {
      return (
        <FinancialYearContainer>
          <img src="images/bdi-sec_historic_after.svg" alt="" />
          <FinancialYearDescription>
            4º ano posterior à data referência
          </FinancialYearDescription>
        </FinancialYearContainer>
      );
    }
    if (value === 5) {
      return (
        <FinancialYearContainer>
          <img src="images/bdi-sec_historic_after.svg" alt="" />
          <FinancialYearDescription>
            5º ano posterior à data referência
          </FinancialYearDescription>
        </FinancialYearContainer>
      );
    }
  };

  function handleIndicadores(values: Indicadores[]) {
    const newValues: any = {};

    values?.map((v: Indicadores) => {
      if (!newValues[v.label]) {
        newValues[v.label] = [];
      }
      newValues[v.label].push({ ...v });
    });

    return Object.entries(newValues);
  }

  const handleUploadTemplate = async (file: any) => {
    const base64Content = await toBase64(file);
    if (templateType)
      submitTemplate({
        templateDadosFinanceiros: templateType,
        base64Content,
      });
  };

  return (
    <>
      <DocumentList stage="financeiro" />
      {/* <FormContent onSubmit={handleSubmit(submitForm)}>
        <Container title="Dados do negócio">
          <SmallDivision>
            <Grid container spacing={4}>
              <Grid item desktop={6} />
              <Grid item desktop={6}>
                <ContentTitle>Matriz SWOT</ContentTitle>
              </Grid>
              <Grid item desktop={6}>
                <Controller
                  name="processoDeDistribuicaoOuProducao"
                  control={control}
                  type="input"
                  label="Processo de distribuição ou produção"
                  textarea
                  maxLength={400}
                  height="135px"
                  disabled={isDisabled}
                />
              </Grid>
              <Grid item desktop={6}>
                <Grid container spacing={1}>
                  <Grid item desktop={6}>
                    <Controller
                      name="forcasDaMatrizSWOT"
                      control={control}
                      type="input"
                      label="Forças"
                      disabled={isDisabled}
                      textarea
                      maxLength={400}
                      height="160px"
                    />
                  </Grid>
                  <Grid item desktop={6}>
                    <Controller
                      name="fraquezasDaMatrizSWOT"
                      control={control}
                      type="input"
                      label="Fraquezas"
                      disabled={isDisabled}
                      textarea
                      maxLength={400}
                      height="160px"
                    />
                  </Grid>
                  <Grid item desktop={6}>
                    <Controller
                      name="oportunidadesDaMatrizSWOT"
                      control={control}
                      type="input"
                      label="Oportunidades"
                      disabled={isDisabled}
                      textarea
                      maxLength={400}
                      height="160px"
                    />
                  </Grid>
                  <Grid item desktop={6}>
                    <Controller
                      name="ameacasDaMatrizSWOT"
                      control={control}
                      type="input"
                      label="Ameaças"
                      disabled={isDisabled}
                      textarea
                      maxLength={400}
                      height="160px"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </SmallDivision>
          <Division>
            <Grid container spacing={4}>
              <Grid item desktop={6}>
                <ContentTitle>Principais competidores (até 5)</ContentTitle>
              </Grid>
              <Grid item desktop={6}>
                <ContentTitle>
                  Distribuição de receita líquida por região/país
                </ContentTitle>
              </Grid>
              <Grid item desktop={6}>
                <ContentDescription>Nome</ContentDescription>
              </Grid>
              <Grid item desktop={3}>
                <ContentDescription>Região e/ou País</ContentDescription>
              </Grid>
              <Grid item desktop={3}>
                <ContentDescription>Receita líquida</ContentDescription>
              </Grid>
              <Grid item desktop={6}>
                <Grid container spacing={4}>
                  <Grid item desktop={12}>
                    <Controller
                      name="principaisCompetidores.0.nome"
                      control={control}
                      type="input"
                      label=""
                      disabled={isDisabled}
                    />
                  </Grid>
                  <Grid item desktop={12}>
                    <Controller
                      name="principaisCompetidores.1.nome"
                      control={control}
                      type="input"
                      label=""
                      disabled={isDisabled}
                    />
                  </Grid>
                  <Grid item desktop={12}>
                    <Controller
                      name="principaisCompetidores.2.nome"
                      control={control}
                      type="input"
                      label=""
                      disabled={isDisabled}
                    />
                  </Grid>
                  <Grid item desktop={12}>
                    <Controller
                      name="principaisCompetidores.3.nome"
                      control={control}
                      type="input"
                      label=""
                      disabled={isDisabled}
                    />
                  </Grid>
                  <Grid item desktop={12}>
                    <Controller
                      name="principaisCompetidores.4.nome"
                      control={control}
                      type="input"
                      label=""
                      disabled={isDisabled}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item desktop={6}>
                <Grid container spacing={4}>
                  {handleRegion()}
                  <Grid item desktop={12}>
                    <Button
                      width="10rem"
                      height="2rem"
                      type="button"
                      disabled={isDisabled}
                      onClick={() => {
                        append({
                          regiaoOuPais: '',
                          receitaLiquida: undefined,
                        });
                      }}
                    >
                      <Plus>+</Plus> Adicionar região
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Division>
          <Division>
            <Grid container spacing={4}>
              <Grid item desktop={6}>
                <Controller
                  name="publicoAlvo"
                  control={control}
                  type="input"
                  label="Público alvo"
                  disabled={isDisabled}
                  textarea
                  maxLength={100}
                  height="112px"
                />
              </Grid>
              <Grid item desktop={6}>
                <Grid container spacing={4}>
                  <Grid item desktop={12}>
                    <Controller
                      name="focoGeografico"
                      control={control}
                      type="input"
                      label="Foco geográfico"
                      disabled={isDisabled}
                    />
                  </Grid>
                  <Grid item desktop={12}>
                    <Controller
                      name="tamanhoDoMercadoPotencial"
                      control={control}
                      type="input"
                      label="Tamanho do mercado potencial"
                      disabled={isDisabled}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item desktop={12}>
                <Controller
                  name="explicacaoDoCalculoDoTamanhoDoMercadoPotencial"
                  control={control}
                  type="input"
                  label="Explicação do cálculo do mercado potencial"
                  textarea
                  maxLength={1000}
                  height="135px"
                  disabled={isDisabled}
                />
              </Grid>
              <Grid item desktop={6}>
                <Controller
                  name="concentracaoDoMercadoDeAtuacao"
                  control={control}
                  type="select"
                  label="Concentração do mercado de atuação"
                  disabled={isDisabled}
                  options={listMarketConcentration}
                />
              </Grid>
              <Grid item desktop={6}>
                <Controller
                  name="maturidadeDoMercadoDeAtuacao"
                  control={control}
                  type="select"
                  label="Maturidade do mercado de atuação"
                  disabled={isDisabled}
                  options={listCycleMoment}
                />
              </Grid>
              <Grid item desktop={6}>
                <Controller
                  name="concentracaoDosPrincipaisFornecedores"
                  control={control}
                  type="select"
                  label="Concentração dos principais fornecedores"
                  disabled={isDisabled}
                  options={listMainSuppliers}
                />
              </Grid>
              <Grid item desktop={6} />
            </Grid>
          </Division>
        </Container>
        <Division>
          <Container
            title="Descrição dos riscos ao negócio da empresa emissora"
            titleMarginBottom="0px"
          >
            <SmallDivision>
              <Grid container spacing={4}>
                <Grid item desktop={12}>
                  <ContentTitleDescription>
                    5 principais riscos do emissor, com probabilidade e impacto
                    financeiro
                  </ContentTitleDescription>
                </Grid>
                <Grid item desktop={6}>
                  <ContentTitleDescription>
                    Principais riscos
                  </ContentTitleDescription>
                </Grid>
                <Grid item desktop={3}>
                  <ContentTitleDescription>
                    Probabilidade
                  </ContentTitleDescription>
                </Grid>
                <Grid item desktop={3}>
                  <ContentTitleDescription>
                    Impacto financeiro
                  </ContentTitleDescription>
                </Grid>
                <Grid item desktop={6}>
                  <Controller
                    name="principaisRiscos.0.nome"
                    control={control}
                    type="input"
                    label=""
                    disabled={isDisabled}
                  />
                </Grid>
                <Grid item desktop={3}>
                  <Controller
                    name="principaisRiscos.0.probabilidade"
                    control={control}
                    type="select"
                    label=""
                    disabled={isDisabled}
                    options={listRisks2}
                  />
                </Grid>
                <Grid item desktop={3}>
                  <Controller
                    name="principaisRiscos.0.impactoFinanceiro"
                    control={control}
                    type="select"
                    label=""
                    disabled={isDisabled}
                    options={listRisks}
                  />
                </Grid>
                <Grid item desktop={6}>
                  <Controller
                    name="principaisRiscos.1.nome"
                    control={control}
                    type="input"
                    label=""
                    disabled={isDisabled}
                  />
                </Grid>
                <Grid item desktop={3}>
                  <Controller
                    name="principaisRiscos.1.probabilidade"
                    control={control}
                    type="select"
                    label=""
                    disabled={isDisabled}
                    options={listRisks2}
                  />
                </Grid>
                <Grid item desktop={3}>
                  <Controller
                    name="principaisRiscos.1.impactoFinanceiro"
                    control={control}
                    type="select"
                    label=""
                    disabled={isDisabled}
                    options={listRisks}
                  />
                </Grid>
                <Grid item desktop={6}>
                  <Controller
                    name="principaisRiscos.2.nome"
                    control={control}
                    type="input"
                    label=""
                    disabled={isDisabled}
                  />
                </Grid>
                <Grid item desktop={3}>
                  <Controller
                    name="principaisRiscos.2.probabilidade"
                    control={control}
                    type="select"
                    label=""
                    disabled={isDisabled}
                    options={listRisks2}
                  />
                </Grid>
                <Grid item desktop={3}>
                  <Controller
                    name="principaisRiscos.2.impactoFinanceiro"
                    control={control}
                    type="select"
                    label=""
                    disabled={isDisabled}
                    options={listRisks}
                  />
                </Grid>
                <Grid item desktop={6}>
                  <Controller
                    name="principaisRiscos.3.nome"
                    control={control}
                    type="input"
                    label=""
                    disabled={isDisabled}
                  />
                </Grid>
                <Grid item desktop={3}>
                  <Controller
                    name="principaisRiscos.3.probabilidade"
                    control={control}
                    type="select"
                    label=""
                    disabled={isDisabled}
                    options={listRisks2}
                  />
                </Grid>
                <Grid item desktop={3}>
                  <Controller
                    name="principaisRiscos.3.impactoFinanceiro"
                    control={control}
                    type="select"
                    label=""
                    disabled={isDisabled}
                    options={listRisks}
                  />
                </Grid>
                <Grid item desktop={6}>
                  <Controller
                    name="principaisRiscos.4.nome"
                    control={control}
                    type="input"
                    label=""
                    disabled={isDisabled}
                  />
                </Grid>
                <Grid item desktop={3}>
                  <Controller
                    name="principaisRiscos.4.probabilidade"
                    control={control}
                    type="select"
                    label=""
                    disabled={isDisabled}
                    options={listRisks2}
                  />
                </Grid>
                <Grid item desktop={3}>
                  <Controller
                    name="principaisRiscos.4.impactoFinanceiro"
                    control={control}
                    type="select"
                    label=""
                    disabled={isDisabled}
                    options={listRisks}
                  />
                </Grid>
                <Grid item desktop={12}>
                  <Controller
                    name="riscosDeExecucao"
                    control={control}
                    type="input"
                    label="Riscos de execução"
                    disabled={isDisabled}
                  />
                </Grid>
                <Grid item desktop={12}>
                  <Controller
                    name="riscoCambial"
                    control={control}
                    type="input"
                    label="Risco cambial"
                    disabled={isDisabled}
                  />
                </Grid>
                <Grid item desktop={12}>
                  <Controller
                    name="riscoFiscal"
                    control={control}
                    type="input"
                    label="Risco fiscal"
                    disabled={isDisabled}
                  />
                </Grid>
                <Grid item desktop={12}>
                  <Controller
                    name="riscoTecnologico"
                    control={control}
                    type="input"
                    label="Risco tecnológico"
                    disabled={isDisabled}
                  />
                </Grid>
                <Grid item desktop={12}>
                  <Controller
                    name="riscosDeAlavancagem"
                    control={control}
                    type="input"
                    label="Risco de alavancagem"
                    disabled={isDisabled}
                  />
                </Grid>
                <Grid item desktop={12}>
                  <Controller
                    name="riscoRegulatorio"
                    control={control}
                    type="input"
                    label="Risco regulatório"
                    disabled={isDisabled}
                  />
                </Grid>
              </Grid>
            </SmallDivision>
          </Container>
        </Division>
        <>
          <Container title="Indicadores financeiros">
            <SmallDivision>
              <>
                <Grid container spacing={4}>
                  <Grid
                    item
                    desktop={7}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <ContentTitleDescription>
                      Insira as informações da empresa emissora através do
                      template ao lado ou direto no sistema.
                    </ContentTitleDescription>
                  </Grid>
                  <Grid
                    item
                    desktop={3}
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                    }}
                  >
                    <Button
                      variant="outlined"
                      color="secondary"
                      width="180px"
                      height="50px"
                      onClick={() => {
                        dowloadTemplate(1);
                      }}
                      disabled={isCompletelyDisabled}
                    >
                      DOWNLOAD TEMPLATE
                    </Button>
                  </Grid>
                  <Grid item desktop={2}>
                    <Button
                      onClick={() => {
                        setUploadModal(true);
                        setTemplateType(1);
                      }}
                      width="180px"
                      height="50px"
                      disabled={isDisabled}
                    >
                      <CloudUploadOutlinedIcon
                        style={{ width: '1.2rem', marginRight: '0.6rem' }}
                      />
                      UPLOAD
                    </Button>
                  </Grid>
                  <Grid item desktop={2}>
                    <Controller
                      name="dataReferenciaDosIndicadoresFinanceiros"
                      control={control}
                      type="input"
                      mask="date"
                      label="Data de referência"
                      disabled={isDisabled}
                    />
                  </Grid>
                  <Grid item desktop={10} />
                  <Grid item desktop={12}>
                    <ContentTitle>
                      Histórico (em milhares de reais - R$):
                    </ContentTitle>
                    <ContentTitleDescription>
                      *Incluir histórico de no mínimo 12 meses ou em até 3 anos
                      anteriores
                    </ContentTitleDescription>
                  </Grid>
                  <Grid item desktop={12} />
                </Grid>
                <Grid container spacing={2}>
                  <Grid item desktop={11}>
                    <Grid container spacing={2}>
                      <Grid item desktop={2} />
                      <Grid
                        item
                        desktop={2}
                        style={{ display: 'flex', alignItems: 'center' }}
                      >
                        <ContentTitleDescription>
                          Últ. Trimestre
                        </ContentTitleDescription>
                      </Grid>
                      <Grid
                        item
                        desktop={2}
                        style={{ display: 'flex', alignItems: 'center' }}
                      >
                        <ContentTitleDescription>
                          Acum. Ano
                        </ContentTitleDescription>
                      </Grid>
                      <Grid item desktop={2}>
                        {handleDate({
                          date: dataReferenciaDosIndicadoresFinanceiros,
                          value: 1,
                          symbol: '-',
                        })}
                      </Grid>
                      <Grid item desktop={2}>
                        {handleDate({
                          date: dataReferenciaDosIndicadoresFinanceiros,
                          value: 2,
                          symbol: '-',
                        })}
                      </Grid>
                      <Grid item desktop={2}>
                        {handleDate({
                          date: dataReferenciaDosIndicadoresFinanceiros,
                          value: 3,
                          symbol: '-',
                        })}
                      </Grid>
                      <Grid item desktop={2} />
                      <Grid item desktop={2}>
                        <Controller
                          name="indicadoresFinanceirosColunas.0"
                          control={control}
                          type="select"
                          options={AuditadoOptions}
                          disabled={isDisabled}
                        />
                      </Grid>
                      <Grid item desktop={2}>
                        <Controller
                          name="indicadoresFinanceirosColunas.1"
                          control={control}
                          type="select"
                          options={AuditadoOptions}
                          disabled={isDisabled}
                        />
                      </Grid>
                      <Grid item desktop={2}>
                        <Controller
                          name="indicadoresFinanceirosColunas.2"
                          control={control}
                          type="select"
                          options={AuditadoOptions}
                          disabled={isDisabled}
                        />
                      </Grid>
                      <Grid item desktop={2}>
                        <Controller
                          name="indicadoresFinanceirosColunas.3"
                          control={control}
                          type="select"
                          options={AuditadoOptions}
                          disabled={isDisabled}
                        />
                      </Grid>
                      <Grid item desktop={2}>
                        <Controller
                          name="indicadoresFinanceirosColunas.4"
                          control={control}
                          type="select"
                          options={AuditadoOptions}
                          disabled={isDisabled}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  {handleIndicadores(
                    dataIndicadoresFinanceiros?.filter(
                      (v: Indicadores) => v.referencia <= 5,
                    ),
                  ).map((ind: any[]) => {
                    return (
                      <React.Fragment key={ind[0]}>
                        <Grid item desktop={11}>
                          <Grid container spacing={2}>
                            <Grid item desktop={2}>
                              <InputDescription>
                                <p>{ind[0]}</p>
                              </InputDescription>
                            </Grid>
                            {ind[1].map((val: any) => {
                              return (
                                <Grid
                                  item
                                  desktop={2}
                                  key={`${val.label}.${val.ordem}.${val.referencia}`}
                                >
                                  <Controller
                                    name={`indicadoresFinanceiros.${val.label}.${val.ordem}.${val.referencia}.valor`}
                                    control={control}
                                    type="input"
                                    mask={
                                      val.tipoCampo === 2
                                        ? 'percentage'
                                        : 'thousandSeparator'
                                    }
                                    disabled={isDisabled}
                                  />
                                </Grid>
                              );
                            })}
                          </Grid>
                        </Grid>

                        <Grid
                          container
                          spacing={1}
                          style={{ display: 'flex', paddingTop: '12px' }}
                        >
                          {!ind[1][0].fixo && (
                            <button
                              style={{
                                background: 'none',
                                border: 'none',
                              }}
                              type="button"
                              onClick={() => {
                                setChosenInd(ind[1][0]);
                                setFieldType({ type: 1, field: 1 });
                                setAddRowModal(true);
                              }}
                              disabled={isDisabled}
                              aria-label="Editar indicador"
                            >
                              <EditIcon />
                            </button>
                          )}
                        </Grid>
                      </React.Fragment>
                    );
                  })}
                  <Grid item desktop={12}>
                    <Button
                      startIcon={<AddCircleOutlineOutlinedIcon />}
                      variant="text"
                      onClick={() => {
                        setAddRowModal(true);
                        setFieldType({ type: 1, field: 1 });
                      }}
                      disabled={isDisabled}
                    >
                      ADICIONAR LINHA NA TABELA
                    </Button>
                  </Grid>
                </Grid>
                <div style={{ marginTop: '1rem' }}>
                  <Controller
                    name="explicacaoDoEbitdaAjustado"
                    control={control}
                    type="input"
                    textarea
                    label="Explicação do EBITDA ajustado"
                    maxLength={500}
                    height="80px"
                    disabled={isDisabled}
                  />
                </div>
              </>
              <Division>
                <Grid container spacing={4}>
                  <Grid item desktop={12}>
                    <ContentTitle>
                      Projeção (em milhares de reais - R$):
                    </ContentTitle>
                    <ContentTitleDescription>
                      *Incluir a projeção dos próximos 5 anos
                    </ContentTitleDescription>
                  </Grid>
                  <Grid item desktop={7}>
                    Insira as informações da empresa emissora através do
                    template ao lado ou direto no sistema.
                  </Grid>
                  <Grid
                    item
                    desktop={3}
                    style={{ display: 'flex', justifyContent: 'flex-end' }}
                  >
                    <Button
                      variant="outlined"
                      color="secondary"
                      width="180px"
                      height="50px"
                      onClick={() => {
                        dowloadTemplate(2);
                      }}
                      disabled={isCompletelyDisabled}
                    >
                      DOWNLOAD TEMPLATE
                    </Button>
                  </Grid>
                  <Grid item desktop={2}>
                    <Button
                      onClick={() => {
                        setUploadModal(true);
                        setTemplateType(2);
                      }}
                      width="180px"
                      height="50px"
                      disabled={isDisabled}
                    >
                      <CloudUploadOutlinedIcon
                        style={{ width: '1.2rem', marginRight: '0.6rem' }}
                      />
                      UPLOAD
                    </Button>
                  </Grid>
                  <Grid item desktop={12} />
                </Grid>
                <Grid container spacing={2}>
                  <Grid item desktop={11}>
                    <Grid container spacing={2}>
                      <Grid item desktop={2} />
                      <Grid item desktop={2}>
                        {handleDate({
                          date: dataReferenciaDosIndicadoresFinanceiros,
                          value: 1,
                        })}
                      </Grid>
                      <Grid item desktop={2}>
                        {handleDate({
                          date: dataReferenciaDosIndicadoresFinanceiros,
                          value: 2,
                        })}
                      </Grid>
                      <Grid item desktop={2}>
                        {handleDate({
                          date: dataReferenciaDosIndicadoresFinanceiros,
                          value: 3,
                        })}
                      </Grid>
                      <Grid item desktop={2}>
                        {handleDate({
                          date: dataReferenciaDosIndicadoresFinanceiros,
                          value: 4,
                        })}
                      </Grid>
                      <Grid item desktop={2}>
                        {handleDate({
                          date: dataReferenciaDosIndicadoresFinanceiros,
                          value: 5,
                        })}
                      </Grid>
                    </Grid>
                  </Grid>
                  {handleIndicadores(
                    dataIndicadoresFinanceiros?.filter(
                      (v: Indicadores) => v.referencia > 5,
                    ),
                  ).map((ind: any[]) => {
                    return (
                      <React.Fragment key={ind[0]}>
                        <Grid item desktop={11}>
                          <Grid container spacing={2}>
                            <Grid item desktop={2}>
                              <InputDescription>
                                <p>{ind[0]}</p>
                              </InputDescription>
                            </Grid>
                            {ind[1].map((val: any) => {
                              return (
                                <Grid
                                  item
                                  desktop={2}
                                  key={`${val.label}.${val.ordem}.${val.referencia}`}
                                >
                                  <Controller
                                    name={`indicadoresFinanceiros.${val.label}.${val.ordem}.${val.referencia}.valor`}
                                    control={control}
                                    type="input"
                                    mask={
                                      val.tipoCampo === 2
                                        ? 'percentage'
                                        : 'thousandSeparator'
                                    }
                                    disabled={isDisabled}
                                  />
                                </Grid>
                              );
                            })}
                          </Grid>
                        </Grid>

                        <Grid
                          container
                          spacing={1}
                          style={{ display: 'flex', paddingTop: '12px' }}
                        >
                          {!ind[1][0].fixo && (
                            <button
                              style={{
                                background: 'none',
                                border: 'none',
                              }}
                              type="button"
                              onClick={() => {
                                setChosenInd(ind[1][0]);
                                setFieldType({ type: 2, field: 1 });
                                setAddRowModal(true);
                              }}
                              disabled={isDisabled}
                              aria-label="Editar indicador"
                            >
                              <EditIcon />
                            </button>
                          )}
                        </Grid>
                      </React.Fragment>
                    );
                  })}
                  <Grid item desktop={12}>
                    <Button
                      startIcon={<AddCircleOutlineOutlinedIcon />}
                      variant="text"
                      onClick={() => {
                        setAddRowModal(true);
                        setFieldType({ type: 2, field: 1 });
                      }}
                      disabled={isDisabled}
                    >
                      ADICIONAR LINHA NA TABELA
                    </Button>
                  </Grid>
                </Grid>
              </Division>
            </SmallDivision>
          </Container>
        </>
        <Division>
          <Container title="Principais linhas do balanço">
            <SmallDivision>
              <>
                <Grid container spacing={4}>
                  <Grid item desktop={7}>
                    Insira as informações da empresa emissora através do
                    template ao lado ou direto no sistema.
                  </Grid>
                  <Grid
                    item
                    desktop={3}
                    style={{ display: 'flex', justifyContent: 'flex-end' }}
                  >
                    <Button
                      variant="outlined"
                      color="secondary"
                      width="180px"
                      height="50px"
                      onClick={() => {
                        dowloadTemplate(3);
                      }}
                      disabled={isCompletelyDisabled}
                    >
                      DOWNLOAD TEMPLATE
                    </Button>
                  </Grid>
                  <Grid item desktop={2}>
                    <Button
                      onClick={() => {
                        setUploadModal(true);
                        setTemplateType(3);
                      }}
                      width="180px"
                      height="50px"
                      disabled={isDisabled}
                    >
                      <CloudUploadOutlinedIcon
                        style={{ width: '1.2rem', marginRight: '0.6rem' }}
                      />
                      UPLOAD
                    </Button>
                  </Grid>
                  <Grid item desktop={2}>
                    <Controller
                      name="dataReferenciaDoBalancoPatrimonial"
                      control={control}
                      type="input"
                      mask="date"
                      label="Data de referência"
                      disabled={isDisabled}
                    />
                  </Grid>
                  <Grid item desktop={10} />
                  <Grid item desktop={12}>
                    <ContentTitle>
                      Histórico (em milhares de reais - R$):
                    </ContentTitle>
                    <ContentTitleDescription>
                      *Incluir histórico de no mínimo 12 meses ou em até 3 anos
                      anteriores
                    </ContentTitleDescription>
                  </Grid>
                  <Grid item desktop={12} />
                </Grid>
                <Grid container spacing={2}>
                  <Grid item desktop={11}>
                    <Grid container spacing={2}>
                      <Grid item desktop={2} />
                      <Grid
                        item
                        desktop={2}
                        style={{ display: 'flex', alignItems: 'center' }}
                      >
                        <ContentTitleDescription>
                          Últ. Trimestre
                        </ContentTitleDescription>
                      </Grid>
                      <Grid
                        item
                        desktop={2}
                        style={{ display: 'flex', alignItems: 'center' }}
                      >
                        <ContentTitleDescription>
                          Acum. Ano
                        </ContentTitleDescription>
                      </Grid>
                      <Grid item desktop={2}>
                        {handleDate({
                          date: dataReferenciaDoBalancoPatrimonial,
                          value: 1,
                          symbol: '-',
                        })}
                      </Grid>
                      <Grid item desktop={2}>
                        {handleDate({
                          date: dataReferenciaDoBalancoPatrimonial,
                          value: 2,
                          symbol: '-',
                        })}
                      </Grid>
                      <Grid item desktop={2}>
                        {handleDate({
                          date: dataReferenciaDoBalancoPatrimonial,
                          value: 3,
                          symbol: '-',
                        })}
                      </Grid>
                      <Grid item desktop={2} />
                      <Grid item desktop={2}>
                        <Controller
                          name="balancoPatrimonialColunas.0"
                          control={control}
                          type="select"
                          options={AuditadoOptions}
                          disabled={isDisabled}
                        />
                      </Grid>
                      <Grid item desktop={2}>
                        <Controller
                          name="balancoPatrimonialColunas.1"
                          control={control}
                          type="select"
                          options={AuditadoOptions}
                          disabled={isDisabled}
                        />
                      </Grid>
                      <Grid item desktop={2}>
                        <Controller
                          name="balancoPatrimonialColunas.2"
                          control={control}
                          type="select"
                          options={AuditadoOptions}
                          disabled={isDisabled}
                        />
                      </Grid>
                      <Grid item desktop={2}>
                        <Controller
                          name="balancoPatrimonialColunas.3"
                          control={control}
                          type="select"
                          options={AuditadoOptions}
                          disabled={isDisabled}
                        />
                      </Grid>
                      <Grid item desktop={2}>
                        <Controller
                          name="balancoPatrimonialColunas.4"
                          control={control}
                          type="select"
                          options={AuditadoOptions}
                          disabled={isDisabled}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  {handleIndicadores(
                    dataBalancoPatrimonial?.filter(
                      (v: Indicadores) => v.referencia <= 5,
                    ),
                  ).map((ind: any[]) => {
                    return (
                      <React.Fragment key={ind[0]}>
                        <Grid item desktop={11}>
                          <Grid container spacing={2}>
                            <Grid item desktop={2}>
                              <InputDescription>
                                <p>{ind[0]}</p>
                              </InputDescription>
                            </Grid>
                            {ind[1].map((val: any) => {
                              return (
                                <Grid
                                  item
                                  desktop={2}
                                  key={`${val.label}.${val.ordem}.${val.referencia}`}
                                >
                                  <Controller
                                    name={`balancoPatrimonial.${val.label}.${val.ordem}.${val.referencia}.valor`}
                                    control={control}
                                    type="input"
                                    mask={
                                      val.tipoCampo === 2
                                        ? 'percentage'
                                        : 'thousandSeparator'
                                    }
                                    disabled={isDisabled}
                                  />
                                </Grid>
                              );
                            })}
                          </Grid>
                        </Grid>

                        <Grid
                          container
                          spacing={1}
                          style={{ display: 'flex', paddingTop: '12px' }}
                        >
                          {!ind[1][0].fixo && (
                            <button
                              style={{
                                background: 'none',
                                border: 'none',
                              }}
                              type="button"
                              onClick={() => {
                                setChosenInd(ind[1][0]);
                                setFieldType({ type: 1, field: 2 });
                                setAddRowModal(true);
                              }}
                              disabled={isDisabled}
                              aria-label="Editar balanço"
                            >
                              <EditIcon />
                            </button>
                          )}
                        </Grid>
                      </React.Fragment>
                    );
                  })}
                  <Grid item desktop={12}>
                    <Button
                      startIcon={<AddCircleOutlineOutlinedIcon />}
                      variant="text"
                      onClick={() => {
                        setAddRowModal(true);
                        setFieldType({ type: 1, field: 2 });
                      }}
                      disabled={isDisabled}
                    >
                      ADICIONAR LINHA NA TABELA
                    </Button>
                  </Grid>
                </Grid>
              </>
              <Division>
                <Grid container spacing={4}>
                  <Grid item desktop={12}>
                    <ContentTitle>
                      Projeção (em milhares de reais - R$):
                    </ContentTitle>
                    <ContentTitleDescription>
                      *Incluir a projeção dos próximos 5 anos
                    </ContentTitleDescription>
                  </Grid>
                  <Grid item desktop={7}>
                    Insira as informações da empresa emissora através do
                    template ao lado ou direto no sistema.
                  </Grid>
                  <Grid
                    item
                    desktop={3}
                    style={{ display: 'flex', justifyContent: 'flex-end' }}
                  >
                    <Button
                      variant="outlined"
                      color="secondary"
                      width="180px"
                      height="50px"
                      onClick={() => {
                        dowloadTemplate(4);
                      }}
                      disabled={isCompletelyDisabled}
                    >
                      DOWNLOAD TEMPLATE
                    </Button>
                  </Grid>
                  <Grid item desktop={2}>
                    <Button
                      onClick={() => {
                        setUploadModal(true);
                        setTemplateType(4);
                      }}
                      width="180px"
                      height="50px"
                      disabled={isDisabled}
                    >
                      <CloudUploadOutlinedIcon
                        style={{ width: '1.2rem', marginRight: '0.6rem' }}
                      />
                      UPLOAD
                    </Button>
                  </Grid>
                  <Grid item desktop={12} />
                </Grid>
                <Grid container spacing={2}>
                  <Grid item desktop={11}>
                    <Grid container spacing={2}>
                      <Grid item desktop={2} />
                      <Grid item desktop={2}>
                        {handleDate({
                          date: dataReferenciaDoBalancoPatrimonial,
                          value: 1,
                        })}
                      </Grid>
                      <Grid item desktop={2}>
                        {handleDate({
                          date: dataReferenciaDoBalancoPatrimonial,
                          value: 2,
                        })}
                      </Grid>
                      <Grid item desktop={2}>
                        {handleDate({
                          date: dataReferenciaDoBalancoPatrimonial,
                          value: 3,
                        })}
                      </Grid>
                      <Grid item desktop={2}>
                        {handleDate({
                          date: dataReferenciaDoBalancoPatrimonial,
                          value: 4,
                        })}
                      </Grid>
                      <Grid item desktop={2}>
                        {handleDate({
                          date: dataReferenciaDoBalancoPatrimonial,
                          value: 5,
                        })}
                      </Grid>
                    </Grid>
                  </Grid>
                  {handleIndicadores(
                    dataBalancoPatrimonial?.filter(
                      (v: Indicadores) => v.referencia > 5,
                    ),
                  ).map((ind: any[]) => {
                    return (
                      <React.Fragment key={ind[0]}>
                        <Grid item desktop={11}>
                          <Grid container spacing={2}>
                            <Grid item desktop={2}>
                              <InputDescription>
                                <p>{ind[0]}</p>
                              </InputDescription>
                            </Grid>
                            {ind[1].map((val: any) => {
                              return (
                                <Grid
                                  item
                                  desktop={2}
                                  key={`${val.label}.${val.ordem}.${val.referencia}`}
                                >
                                  <Controller
                                    name={`balancoPatrimonial.${val.label}.${val.ordem}.${val.referencia}.valor`}
                                    control={control}
                                    type="input"
                                    mask={
                                      val.tipoCampo === 2
                                        ? 'percentage'
                                        : 'thousandSeparator'
                                    }
                                    disabled={isDisabled}
                                  />
                                </Grid>
                              );
                            })}
                          </Grid>
                        </Grid>

                        <Grid
                          container
                          spacing={1}
                          style={{ display: 'flex', paddingTop: '12px' }}
                        >
                          {!ind[1][0].fixo && (
                            <button
                              style={{
                                background: 'none',
                                border: 'none',
                              }}
                              type="button"
                              onClick={() => {
                                setChosenInd(ind[1][0]);
                                setFieldType({ type: 2, field: 2 });
                                setAddRowModal(true);
                              }}
                              disabled={isDisabled}
                              aria-label="Editar balanço"
                            >
                              <EditIcon />
                            </button>
                          )}
                        </Grid>
                      </React.Fragment>
                    );
                  })}
                  <Grid item desktop={12}>
                    <Button
                      startIcon={<AddCircleOutlineOutlinedIcon />}
                      variant="text"
                      onClick={() => {
                        setAddRowModal(true);
                        setFieldType({ type: 2, field: 2 });
                      }}
                      disabled={isDisabled}
                    >
                      ADICIONAR LINHA NA TABELA
                    </Button>
                  </Grid>
                </Grid>
              </Division>
            </SmallDivision>
          </Container>
        </Division>
        <>
          <Container title="Rodada">
            <SmallDivision>
              <Grid container spacing={4}>
                <Grid item desktop={3}>
                  <Controller
                    name="valuationPreMoney"
                    control={control}
                    type="input"
                    mask="currency"
                    label="Valuation pre-money (em R$ milhões)"
                    disabled={isDisabled}
                  />
                </Grid>
                <Grid item desktop={3}>
                  <Controller
                    name="dataDeReferenciaDoValuationPreMoney"
                    control={control}
                    type="input"
                    mask="date"
                    label="Data de referência"
                    disabled={isDisabled}
                  />
                </Grid>
                <Grid item desktop={3}>
                  <Controller
                    name="valorDaEmpresaOuEbitda"
                    control={control}
                    type="input"
                    mask="negativeCurrency"
                    label="Valor da empresa / EBITDA"
                    disabled={isDisabled}
                  />
                </Grid>
                <Grid item desktop={3}>
                  <Controller
                    name="relacaoPrecoLucro"
                    control={control}
                    type="input"
                    label={
                      <div
                        style={{
                          display: 'flex',
                          gap: '0.8rem',
                          alignItems: 'center',
                        }}
                      >
                        Relação Preço / Lucro
                        <Tooltip title="Capitalização de mercado / Lucro líquido após impostos">
                          <img
                            src="images/System/18.svg"
                            alt=""
                            style={{ height: '16px', zIndex: 3 }}
                          />
                        </Tooltip>
                      </div>
                    }
                    disabled={isDisabled}
                  />
                </Grid>
                <Grid item desktop={3}>
                  <Controller
                    name="capitalizacaoDeMercadoAoPrecoDaOferta"
                    control={control}
                    type="input"
                    mask="currency"
                    label="Capitalização de mercado ao preço da Oferta"
                    disabled={isDisabled}
                  />
                </Grid>
                <Grid item desktop={3}>
                  <Controller
                    name="caixaLiquidoEInvestimentosFinanceiros"
                    control={control}
                    type="input"
                    mask="currency"
                    label="Caixa Líquido e Investimentos financeiros"
                    disabled={isDisabled}
                  />
                </Grid>
                <Grid item desktop={3}>
                  <Controller
                    name="valorDaEmpresaAoPrecoDaOferta"
                    control={control}
                    type="input"
                    mask="currency"
                    label="Valor da empresa ao preço da Oferta"
                    disabled={isDisabled}
                  />
                </Grid>
                <Grid item desktop={3}>
                  <Controller
                    name="relacaoPrecoLucroAjustada"
                    control={control}
                    type="input"
                    mask="currency"
                    label="Relação Preço / Lucro (ajustada)"
                    disabled={isDisabled}
                  />
                </Grid>
                <Grid item desktop={12}>
                  <Controller
                    name="comentarioDaEmpresaSobreSuaAvaliacao"
                    control={control}
                    type="input"
                    textarea
                    label="Comentário da empresa"
                    maxLength={1000}
                    height="135px"
                    disabled={isDisabled}
                  />
                </Grid>
                <Grid item desktop={12}>
                  <Controller
                    name="qualSeraADestinacaoDosRecursosDaOferta"
                    control={control}
                    type="input"
                    textarea
                    maxLength={1000}
                    height="135px"
                    label="Qual será a destinação dos recursos da oferta?"
                    disabled={isDisabled}
                  />
                </Grid>
              </Grid>
            </SmallDivision>
          </Container>
        </>

        <Buttons>
          {!isDisabled ? (
            <Button
              width="103px"
              height="40px"
              type="submit"
              disabled={isDisabled}
            >
              SALVAR
            </Button>
          ) : null}
        </Buttons>
      </FormContent> */}
      <AddEditFieldModal
        description={addRowModal}
        closeDropdown={() => {
          setAddRowModal(false);
          setFieldType({ type: undefined, field: undefined });
          setChosenInd({});
        }}
        size={handleFieldSize()}
        type={fieldType.type}
        field={fieldType.field}
        submitField={(result, field) => {
          addField(result, field);
        }}
        editField={(e, chave, tipo, campo, isBefore) => {
          editField(e, chave, tipo, campo, isBefore);
        }}
        deleteField={(chave, tipo, campo) => {
          deleteField(chave, tipo, campo);
        }}
        chosenInd={chosenInd}
      />
      <UploadModal
        openModal={uploadModal}
        onSave={files => {
          handleUploadTemplate(files[0]);
          setUploadModal(false);
        }}
        onClose={() => {
          setUploadModal(false);
        }}
        acceptedFiles={['.xlsx']}
        dialogTitle={
          <UploadModalContent>
            <UploadModalTitle>Importar documento</UploadModalTitle>
            <ModalFilesSuported>Arquivo suportado: xlsx</ModalFilesSuported>
          </UploadModalContent>
        }
      />
    </>
  );
};
