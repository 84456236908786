import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Dialog } from '@mui/material';
import { Button } from '../../components/button';
import { Post } from './components/post';
import { Obligations } from './components/obligations';
import { Editor } from '../../components/editor';
import { UploadModal } from '../../components/uploadModal';
import { toBase64 } from '../../services/toBase64';
import { useAuth } from '../../hooks/auth';
import { useEnterprise } from '../../hooks/enterprise';
import useFatosRelevantes from './hooks/fatosRelevantes';

import {
  AttachmentButton,
  BottomSection,
  Uploads,
  ButtonsWrapper,
  ImageIcon,
  ClipIcon,
  Divider,
  InputDiv,
  Title,
  ObligationsButton,
  FileButtonContent,
  EndEditorWrapper,
} from './styles';
import { ImagePreview } from './components/imagePreview';
import { FilePreview } from './components/filePreview';
import { FileProps, IFatosRelevantes, RelacaoFatosRelevantes } from './fields';
import { SingleRadio } from '../../components/singleRadio';
import { Input } from '../../components/input';
import { OutletPermify } from '../../components/outletPermify';
import { Roles } from '../../router/roles.routes';

export const FatosRelevantes = () => {
  const { currentRole } = useAuth();
  const { currentEnterpriseId } = useEnterprise();

  const [obligationsModal, setObligationsModal] = useState(false);
  const [photoUploadModal, setPhotoUploadModal] = useState(false);
  const [fileUploadModal, setFileUploadModal] = useState(false);
  const [hasPublishedChannels, setHasPublishedChannels] = useState(false);
  const [publishedChannels, setPublishedChannels] = useState('');

  const {
    fatosRelevantes,
    uploadedImages,
    submitFatoRelevante,
    submitImagem,
    handleDeleteImagem,
  } = useFatosRelevantes({
    currentEnterpriseId,
  });

  const fatosRelevantesNaoDeletados =
    fatosRelevantes?.relacaoFatoRelevantes.filter(
      fato => fato.armazenar !== true,
    );

  const [inputContent, setInputContent] = useState('');

  const [uploadedFiles, setUploadedFiles] = useState<FileProps[]>([]);

  const handleUploadImage = async (files: File[]) => {
    if (!files || !files[0]) {
      toast.error('Carregue uma imagem antes de salvar');
    } else {
      // @ts-ignore
      const arquivoTemplateEmBase64: string = await toBase64(files[0]);
      const nomeDoArquivoTemplate: string = files[0].name;
      submitImagem({
        nome: nomeDoArquivoTemplate,
        base64Content: arquivoTemplateEmBase64,
      });
      setPhotoUploadModal(false);
    }
  };

  const handleUploadFile = async (files: File[]) => {
    if (!files || !files[0]) {
      toast.error('Coloque um documento antes de salvar');
    } else {
      // @ts-ignore
      const arquivoTemplateEmBase64: string = await toBase64(files[0]);
      const nomeDoArquivoTemplate: string = files[0].name;
      setUploadedFiles([
        ...uploadedFiles,
        {
          nome: nomeDoArquivoTemplate,
          type: 'pdf',
          base64Content: arquivoTemplateEmBase64,
        },
      ]);
      setFileUploadModal(false);
    }
  };

  const handleSubmitPost = () => {
    submitFatoRelevante({
      post: inputContent,
      imagens: uploadedImages.map(({ id: imagemId }) => {
        return { imagemId };
      }),
      documentos: uploadedFiles,
      fatoRelevanteVinculadoEmOutroCanal: hasPublishedChannels,
      qualCanalFoiVinculado: publishedChannels,
    });
    setInputContent('');
    setUploadedFiles([]);
    setHasPublishedChannels(false);
    setPublishedChannels('');
  };

  const handleDeleteImage = (
    image: {
      id: string;
      nome: string;
      url: string;
    },
    i: number,
  ) => {
    handleDeleteImagem(image.id, i);
  };

  const isEmpty = () => {
    return (
      (inputContent === '' || inputContent === '<p><br></p>') &&
      uploadedImages.length === 0 &&
      uploadedFiles.length === 0
    );
  };

  return (
    <>
      {currentRole === 'Empreendedor' && (
        <>
          <Dialog
            open={obligationsModal}
            onClose={() => setObligationsModal(false)}
            maxWidth="md"
          >
            <Obligations handleClose={() => setObligationsModal(false)} />
          </Dialog>
          <Title>
            <span>Informe ao mercado</span>
            <ObligationsButton onClick={() => setObligationsModal(true)}>
              Entenda suas obrigações sobre fatos relevantes
            </ObligationsButton>
          </Title>
          <InputDiv style={{ padding: '29px' }}>
            <Editor
              placeholder="Lorem ipsum dolor sit amet..."
              html={inputContent}
              onChange={e => setInputContent(e)}
            />
            <BottomSection>
              <EndEditorWrapper>
                <SingleRadio
                  canUncheck
                  onChange={() => {
                    setHasPublishedChannels(current => {
                      if (current) {
                        setPublishedChannels('');
                      }
                      return !current;
                    });
                  }}
                  label="Fato relevante veiculado em outro canal"
                  checked={hasPublishedChannels}
                />
                {hasPublishedChannels && (
                  <div style={{ width: '60%' }}>
                    <Input
                      label="Em quais canais o fato já foi publicado"
                      textarea
                      value={publishedChannels}
                      maxLength={500}
                      onChange={(e: string) => {
                        setPublishedChannels(e);
                      }}
                    />
                  </div>
                )}

                <Uploads>
                  {uploadedImages?.map((item, i) => (
                    <ImagePreview
                      {...item}
                      onDelete={() => handleDeleteImage(item, i)}
                    />
                  ))}
                  {uploadedFiles?.map((item, i) => (
                    <FilePreview
                      {...item}
                      onDelete={() =>
                        setUploadedFiles([
                          ...uploadedFiles.slice(0, i),
                          ...uploadedFiles.slice(i + 1),
                        ])
                      }
                    />
                  ))}
                </Uploads>
                <ButtonsWrapper>
                  <OutletPermify role={[Roles.CompartilharFatoRelevante]}>
                    <AttachmentButton
                      type="button"
                      onClick={() => {
                        setFileUploadModal(true);
                      }}
                    >
                      <FileButtonContent>
                        <img src="images/clip.svg" alt="" />
                        Anexar documento
                      </FileButtonContent>
                    </AttachmentButton>
                  </OutletPermify>
                  <OutletPermify role={[Roles.CompartilharFatoRelevante]}>
                    <AttachmentButton
                      type="button"
                      onClick={() => {
                        setPhotoUploadModal(true);
                      }}
                    >
                      <FileButtonContent>
                        <ClipIcon src="images/image.svg" alt="" />
                      </FileButtonContent>
                    </AttachmentButton>
                  </OutletPermify>

                  <OutletPermify role={[Roles.CompartilharFatoRelevante]}>
                    <Button
                      onClick={handleSubmitPost}
                      height="32px"
                      fontSize="10px"
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                      }}
                      disabled={isEmpty()}
                    >
                      Compartilhar
                    </Button>
                  </OutletPermify>
                </ButtonsWrapper>
              </EndEditorWrapper>
            </BottomSection>
          </InputDiv>
        </>
      )}
      {fatosRelevantesNaoDeletados?.map((item: RelacaoFatosRelevantes) => (
        <React.Fragment key={item.fatoRelevanteId}>
          <Divider />
          <Post
            company={{ name: item.razaoSocial, id: currentEnterpriseId }}
            fatoRelevante={item}
          />
        </React.Fragment>
      ))}
      <UploadModal
        openModal={photoUploadModal}
        onSave={files => {
          handleUploadImage(files);
        }}
        onClose={() => {
          setPhotoUploadModal(false);
        }}
        dialogTitle="Importar documentos"
      />
      <UploadModal
        openModal={fileUploadModal}
        onSave={files => {
          handleUploadFile(files);
        }}
        onClose={() => {
          setFileUploadModal(false);
        }}
        acceptedFiles={[
          '.pdf',
          '.xls',
          '.xlsx',
          '.csv',
          '.doc',
          '.docx',
          '.ppt',
        ]}
        dialogTitle="Importar documentos"
      />
    </>
  );
};
