import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import EditIconMaterial from '@mui/icons-material/Edit';
import { Modal } from '../../modal';
import { Button } from '../../button';
import {
  PrazoEditContainer,
  ModalButtons,
  ModalForm,
  PrazoEditIcon,
} from './styles';
import { Controller } from '../../controller';
import { StatusChangePayload } from '../fields';

interface IModalProps {
  description: boolean;
  closeDropdown: (e?: any) => void;
  statusObject?: StatusChangePayload;
  onStatusChange: (e?: StatusChangePayload) => void;
  editStatus: (e?: StatusChangePayload) => void;
  disabled: boolean;
}

type PrazoModalData = {
  motivo: string;
};

const schema = yup.object().shape({
  motivo: yup.string().required('Campo obrigatório'),
});
export const PrazoModal: React.FC<IModalProps> = ({
  description,
  closeDropdown,
  statusObject,
  onStatusChange,
  editStatus,
  disabled,
}) => {
  const [edit, setEdit] = useState(false);

  const { handleSubmit, control, reset } = useForm<PrazoModalData>({
    resolver: yupResolver(schema),
  });

  const submitForm = (e: any) => {
    if (statusObject?.motivo) {
      editStatus({ ...statusObject, ...e });
    } else {
      onStatusChange({ ...statusObject, ...e });
    }
    closeDropdown();
  };

  useEffect(() => {
    reset({
      motivo: statusObject?.motivo ?? '',
    });
  }, [reset]);

  if (statusObject?.motivo) {
    return (
      <Modal open={description} handleClose={closeDropdown} width={672}>
        <ModalForm onSubmit={handleSubmit(submitForm)}>
          <h3>Solicitação de prazo excepcional</h3>
          {edit ? (
            <Controller
              name="motivo"
              control={control}
              textarea
              type="input"
              label="Mensagem"
            />
          ) : (
            <PrazoEditContainer>
              {statusObject?.motivo ?? ''}
              {!disabled && (
                <PrazoEditIcon
                  type="button"
                  onClick={() => {
                    setEdit(true);
                  }}
                  disabled={disabled}
                >
                  <EditIconMaterial />
                </PrazoEditIcon>
              )}
            </PrazoEditContainer>
          )}

          <ModalButtons>
            <Button
              variant="outlined"
              color="secondary"
              onClick={closeDropdown}
              width="157px"
              height="40px"
            >
              VOLTAR
            </Button>
            {!disabled && (
              <Button
                type="submit"
                width="157px"
                height="40px"
                disabled={disabled}
              >
                Salvar
              </Button>
            )}
          </ModalButtons>
        </ModalForm>
      </Modal>
    );
  }

  return (
    <Modal open={description} handleClose={closeDropdown} width={672}>
      <ModalForm onSubmit={handleSubmit(submitForm)}>
        <h3>Solicitação de prazo excepcional</h3>
        <p>
          Caso não seja possível finalizar este documento até o prazo para
          iniciar o IPO, você pode solicitar a postergação da entrega.{' '}
          <strong>
            Lembrando que este não pode ultrapassar a data de início oficial da
            listagem no mercado BEE4.
          </strong>
        </p>
        <p>
          O pedido será analisado pelo{' '}
          <strong>Comitê de Aprovação da BEE4</strong> e poderá ou não ser
          acatado. Se não for possível adiar, a área de Emissores entrará em
          contato para propor uma solução.
        </p>

        <Controller
          name="motivo"
          control={control}
          textarea
          type="input"
          label="Mensagem"
        />
        <ModalButtons>
          <Button
            variant="outlined"
            color="secondary"
            onClick={closeDropdown}
            width="157px"
            height="40px"
          >
            VOLTAR
          </Button>

          <Button type="submit" width="157px" height="40px" disabled={disabled}>
            Salvar
          </Button>
        </ModalButtons>
      </ModalForm>
    </Modal>
  );
};
