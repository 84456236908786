import React from 'react';
import { useForm } from 'react-hook-form';
import { Modal } from '../../modal';
import { Button } from '../../button';
import { ModalButtons, ModalForm } from './styles';
import { StatusChangePayload } from '../fields';

interface IModalProps {
  description: boolean;
  closeDropdown: (e?: any) => void;
  onStatusChange: (e?: StatusChangePayload) => void;
  statusObject?: StatusChangePayload;
}

export const FinishDocModal: React.FC<IModalProps> = ({
  description,
  closeDropdown,
  onStatusChange,
  statusObject,
}) => {
  const { handleSubmit } = useForm<any>({});

  const submitForm = (e: any) => {
    closeDropdown();
    onStatusChange(statusObject);
  };
  return (
    <Modal open={description} handleClose={closeDropdown} width={672}>
      <ModalForm onSubmit={handleSubmit(submitForm)}>
        <h3>Finalização do documento</h3>
        <p>
          O sistema notificará tanto o Emissor quanto o Consultor de Listagem
          vinculado à empresa emissora para completarem com os dados necessários
          ou inserir o documento faltante.
        </p>

        <ModalButtons>
          <Button
            variant="outlined"
            color="secondary"
            onClick={closeDropdown}
            width="157px"
            height="40px"
          >
            VOLTAR
          </Button>
          <Button width="157px" height="40px" type="submit">
            Notificar
          </Button>
        </ModalButtons>
      </ModalForm>
    </Modal>
  );
};
