import React from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import axios, { AxiosResponse } from 'axios';
import { api } from '../../../services/api';
import { verifyError } from '../../../utils/verifyError';

interface IUsePosIPODocumentsHooks {
  currentEnterpriseId: string;
  isDisabled: boolean;
}

export interface PosIPODocument {
  nome?: string;
  blobContainer?: string;
  blobName?: string;
  url?: string;
  empresaId?: string;
  empresa?: string;
  id: string;
  criadoEm?: string | null;
  criadoPeloUsuario?: string;
}

interface PayloadDocument {
  document: PosIPODocument;
  documentId: string;
}

export const usePosIPODocuments = ({
  currentEnterpriseId,
  isDisabled,
}: IUsePosIPODocumentsHooks) => {
  const queryClient = useQueryClient();

  const getPosIPODocuments = async () => {
    const getToast = toast.loading('Carregando os dados da oferta');
    try {
      const response: AxiosResponse<PosIPODocument[]> = await api.main.get(
        `/v1/empresas/${currentEnterpriseId}/pos-ipo/documentos`,
      );
      toast.update(getToast, {
        render: 'Dados carregado com sucesso!',
        type: 'success',
        isLoading: false,
        autoClose: 1,
      });
      return response?.data;
    } catch (error) {
      const message = verifyError(error, 'Erro ao caregar os dados da oferta');
      toast.update(getToast, {
        render: message,
        type: 'error',
        isLoading: false,
        autoClose: 5000,
      });
    }
  };

  const {
    isLoading,
    isSuccess,
    isError,
    isIdle,
    data: documents,
  } = useQuery(['posIPODocuments', currentEnterpriseId], getPosIPODocuments, {
    enabled: !!currentEnterpriseId || !!isDisabled,
  });

  const invalidateQuery = async () => {
    await queryClient.invalidateQueries('posIPODocuments');
  };

  return {
    isLoading,
    isError,
    isIdle,
    isSuccess,
    documents,
    getPosIPODocuments,
    queryClient,
  };
};

export default usePosIPODocuments;
