import React from 'react';
import { ObjectContainer } from './styles';
import { base64ToArrayBuffer, getMimeType } from './utils';

interface Props {
  base64?: string;
  fileType?: 'jpg' | 'png' | 'xls' | 'xlsx' | 'csv' | 'pdf' | 'doc' | 'docx';
  canDownload?: boolean;
  url?: string;
  style?: any;
}

export default function ObjectRenderer({
  base64,
  fileType = 'pdf',
  canDownload = false,
  url,
  style,
}: Props) {
  if (!url && !base64) {
    return null;
  }
  if (base64) {
    const fileArrayBuffer = base64ToArrayBuffer(base64);

    const mimeType = getMimeType(fileType);

    if (!mimeType) {
      console.error('Tipo de arquivo inválido');
      return null;
    }

    const blob = new Blob([fileArrayBuffer], {
      type: mimeType,
    });

    const objectUrl = URL.createObjectURL(blob);

    return (
      <ObjectContainer
        data={`${objectUrl}${!canDownload ? '#toolbar=0' : ''}`}
        title="Documento"
        style={style}
      >
        Seu navegador não suporta a visualização desse tipo de arquivo.
      </ObjectContainer>
    );
  }
  const objectUrl = url;
  return (
    <ObjectContainer
      data={`${objectUrl}${canDownload ? '#toolbar=0' : ''}`}
      title="Documento"
      style={style}
    >
      Seu navegador não suporta a visualização desse tipo de arquivo.
    </ObjectContainer>
  );
}
