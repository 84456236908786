import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  ButtonContainer,
  CommitteeDecisionContainer,
  DocumentListContent,
  DocumentListDescription,
} from './styles';
import { useEnterprise } from '../../hooks/enterprise';
import useIPO from '../documentacaoIPO/hooks/useIpo';
import { Button } from '../../components/button';
import { DocumentListIpo } from '../documentacaoIPO/components/documentListIpo';
import Carregando from '../../components/carregando';
import useListagem from '../documentacaoListagem/hooks/useDocumentacaoListagem';
import { DocumentListListagem } from '../documentacaoListagem/components/documentListListagem';
import { textoIPO, textoListagem } from './fields';
import { OutletPermify } from '../../components/outletPermify';
import { Roles } from '../../router/roles.routes';

enum tipoComite {
  IPO = 1,
  Listagem = 2,
}

export const DecisaoComite = () => {
  const {
    currentEnterpriseName,
    currentEnterpriseId,
    visualizouDecisaoDoComite,
    passouPelaDecisaoDoComite,
    resultadoDoComiteBEE4,
    updateViewDocIPO,
    visualizouDecisaoDoComiteListagem,
    passouPelaDecisaoDoComiteListagem,
    resultadoDoComiteBEE4Listagem,
    updateViewListagem,
  } = useEnterprise();

  const navigate = useNavigate();

  const [comite, setComite] = useState<tipoComite>();

  const { documentacaoIPO } = useIPO({
    currentEnterpriseId,
  });

  const { documentacaoListagem } = useListagem({
    currentEnterpriseId,
  });

  const status = resultadoDoComiteBEE4;
  const statusListagem = resultadoDoComiteBEE4Listagem;

  const lastAprovacaoIPO: any = documentacaoIPO?.listaDeAprovacaoAdicionalBEE4
    ?.length
    ? documentacaoIPO?.listaDeAprovacaoAdicionalBEE4[
        documentacaoIPO?.listaDeAprovacaoAdicionalBEE4?.length - 1
      ]
    : documentacaoIPO?.aprovacaoBEE4;

  const lastAprovacaoListagem: any = documentacaoListagem
    ?.listaDeAprovacaoAdicionalListagemBEE4?.length
    ? documentacaoListagem?.listaDeAprovacaoAdicionalListagemBEE4[
        documentacaoListagem?.listaDeAprovacaoAdicionalListagemBEE4?.length - 1
      ]
    : documentacaoListagem?.aprovacaoBEE4;

  useEffect(() => {
    if (!documentacaoIPO || !documentacaoListagem) return;

    if (
      (visualizouDecisaoDoComite &&
        passouPelaDecisaoDoComite &&
        documentacaoListagem?.statusDocumentacaoListagem !== 5 &&
        lastAprovacaoListagem?.avaliacaoBEE4 !== 4) ||
      (visualizouDecisaoDoComiteListagem && passouPelaDecisaoDoComiteListagem)
    ) {
      navigate('/dashboard-listagem');
      return;
    }

    if (
      (!visualizouDecisaoDoComite &&
        (documentacaoListagem?.statusDocumentacaoListagem !== 5 ||
          documentacaoIPO?.statusDocumentacaoIpo !== 5)) ||
      status === 3
    ) {
      setComite(tipoComite.IPO);
      return;
    }

    if (!visualizouDecisaoDoComiteListagem) setComite(tipoComite.Listagem);
  }, [
    visualizouDecisaoDoComite,
    visualizouDecisaoDoComiteListagem,
    documentacaoIPO,
    documentacaoListagem,
    status,
    currentEnterpriseId,
  ]);

  if (
    !documentacaoIPO?.aprovacaoBEE4 ||
    (tipoComite.Listagem === comite && !documentacaoListagem?.aprovacaoBEE4) ||
    !status
  )
    return <Carregando />;

  const handleButtonText = () => {
    if (tipoComite.IPO === comite ? status === 2 : statusListagem === 2)
      return 'Ver histórico de documentos';

    return 'Prosseguir';
  };

  const handleSubmitButton = async () => {
    if (tipoComite.IPO === comite) {
      await updateViewDocIPO();

      if (status === 2) navigate('/historico-documentacao');
      else if (status === 3) navigate('/documentacao-ipo');
      else navigate('/dashboard-listagem');

      return;
    }

    await updateViewListagem();

    if (statusListagem === 2) navigate('/historico-documentacao');
    else if (statusListagem === 3) navigate('/documentacao-listagem');
    else navigate('/dashboard-listagem');
  };

  const obterTexto = () => {
    return tipoComite.IPO === comite
      ? textoIPO(currentEnterpriseName, status)
      : textoListagem(currentEnterpriseName, statusListagem);
  };

  return (
    <CommitteeDecisionContainer>
      <h2>{obterTexto()[0]}</h2>
      <p>{obterTexto()[1]}</p>
      <p>
        Antes de prosseguir, sugerimos que faça o download dos documentos
        comprobatórios e guarde-os em um local seguro.
      </p>
      <DocumentListContent>
        <DocumentListDescription>
          Faça o download dos documentos comprobatórios
        </DocumentListDescription>

        <OutletPermify role={[Roles.VisualizarListaDocGerados]}>
          {tipoComite.IPO === comite ? (
            <DocumentListIpo
              docList={lastAprovacaoIPO?.documentos}
              isDisable
              page
            />
          ) : (
            <DocumentListListagem
              docList={lastAprovacaoListagem?.documentos}
              isDisable
              page
            />
          )}
        </OutletPermify>
      </DocumentListContent>
      <h3>O que fazer agora?</h3>
      <p>{obterTexto()[2]}</p>
      <p>
        Você pode prosseguir clicando no botão abaixo, pois os detalhes da
        decisão e documentos comprobatórios estão na página &quot;Decisão do
        comitê&quot;, disponível para consulta a qualquer momento pelo menu.
      </p>
      <ButtonContainer>
        <Button
          color="secondary"
          width={status === 2 || statusListagem === 2 ? '221px' : '157px'}
          height="40px"
          onClick={() => {
            handleSubmitButton();
          }}
        >
          {handleButtonText()}
        </Button>
      </ButtonContainer>
    </CommitteeDecisionContainer>
  );
};
