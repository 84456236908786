import styled from 'styled-components';

export const CommitteeDecisionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0 8rem;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
`;

export const DocumentListContent = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1.5rem 0;
`;

export const DocumentListDescription = styled.h3`
  font-family: Inter;
  padding: 16px;
`;
