import { useQuery, useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { AxiosResponse } from 'axios';
import { useState } from 'react';
import { api } from '../../../services/api';
import { verifyError } from '../../../utils/verifyError';
import { IFatosRelevantes, ImageProps } from '../fields';

interface IUseFatosRelevantesHooks {
  currentEnterpriseId: string;
}

export const useFatosRelevantes = ({
  currentEnterpriseId,
}: IUseFatosRelevantesHooks) => {
  const queryClient = useQueryClient();
  const [uploadedImages, setUploadedImages] = useState<ImageProps[]>([]);

  const getFatosRelevantes = async () => {
    const getToast = toast.loading('Carregando feed');
    try {
      const { data }: AxiosResponse<IFatosRelevantes> = await api.main.get(
        `/v1/empresas/${currentEnterpriseId}/fato-relevante`,
      );

      toast.update(getToast, {
        render: 'Feed carregado com sucesso!',
        type: 'success',
        isLoading: false,
        autoClose: 1,
      });

      return data;
    } catch (error) {
      const message = verifyError(
        error,
        'Erro ao carregar os fatos relevantes',
      );
      toast.update(getToast, {
        render: message,
        type: 'error',
        isLoading: false,
        autoClose: 5000,
      });
    }
  };

  const { data: fatosRelevantes } = useQuery(
    ['fatosRelevantes'],
    getFatosRelevantes,
    {
      enabled: !!currentEnterpriseId,
    },
  );

  const invalidateQuery = async () => {
    await queryClient.invalidateQueries('fatosRelevantes');
  };

  const postFatoRelevante = useMutation(async (payload: any) => {
    toast.loading('Publicando fato relevante');
    const response = await api.main.post(
      `/v1/empresas/${currentEnterpriseId}/fato-relevante`,
      payload,
    );

    invalidateQuery();

    return response;
  });

  const submitFatoRelevante = (payload: any) => {
    postFatoRelevante.mutate(payload, {
      onSuccess: async res => {
        toast.dismiss();
        if (res?.status === 200 || res?.status === 201) {
          toast.success('Dados atualizados com sucesso!');
        }
      },
      onError: async (err: any) => {
        toast.dismiss();
        const message = verifyError(err, 'Erro ao criar um novo histórico');
        toast.error(message);
      },
    });
    setUploadedImages([]);
  };

  const postImagem = useMutation(async (payload: any) => {
    const postToast = toast.loading('Subindo imagem');
    const response = await api.main.post(
      `/v1/empresas/${currentEnterpriseId}/fato-relevante/documentos/imagens`,
      payload,
    );

    invalidateQuery();
    toast.update(postToast, {
      render: 'Imagem carregada com sucesso!',
      type: 'success',
      isLoading: false,
      autoClose: 1,
    });

    setUploadedImages([...uploadedImages, response.data]);

    return response;
  });

  const submitImagem = (payload: any) => {
    postImagem.mutate(payload, {
      onSuccess: async res => {
        if (res?.status === 200 || res?.status === 201) {
          toast.success('Imagem carregada com sucesso!');
        }
      },
      onError: async (err: any) => {
        const message = verifyError(err, 'Erro ao subir imagem');
        toast.error(message);
      },
    });
  };

  const deleteFatoRelevante = useMutation(async (payload: any) => {
    const { currentEnterpriseId, fatoRelevanteId } = payload;
    const response = await api.main.delete(
      `/v1/empresas/${currentEnterpriseId}/fato-relevante/${fatoRelevanteId}`,
    );

    invalidateQuery();
    return response;
  });

  const handleDeleteFatoRelevante = (fatoRelevanteId: string) => {
    const payload = {
      currentEnterpriseId,
      fatoRelevanteId,
    };
    deleteFatoRelevante.mutate(payload, {
      onSuccess: async (res: any) => {
        if (res?.status === 200 || res?.status === 201) {
          toast.success('Fato relevante excluído com sucesso!');
        }
      },
      onError: async (err: any) => {
        const message = verifyError(err, 'Erro ao excluir o fato relevante');
        toast.error(message);
      },
    });
  };

  const deleteImagem = useMutation(async (payload: any) => {
    const { currentEnterpriseId, imagemId } = payload;
    const response = await api.main.delete(
      `/v1/empresas/${currentEnterpriseId}/fato-relevante/documentos/imagens/${imagemId}`,
    );

    invalidateQuery();
    return response;
  });

  const handleDeleteImagem = (imagemId: string, index: number) => {
    const payload = {
      currentEnterpriseId,
      imagemId,
    };
    deleteImagem.mutate(payload, {
      onSuccess: async (res: any) => {
        if (res?.status === 200 || res?.status === 201) {
          toast.success('Imagem excluída com sucesso!');
        }
        setUploadedImages([
          ...uploadedImages.slice(0, index),
          ...uploadedImages.slice(index + 1),
        ]);
      },
      onError: async (err: any) => {
        const message = verifyError(err, 'Erro ao excluir imagem');
        toast.error(message);
      },
    });
  };

  const publishPost = useMutation(async (payload: any) => {
    const { id } = payload;
    const response = await api.main.put(
      `/v1/empresas/${currentEnterpriseId}/fato-relevante/${id}/publicar`,
    );

    invalidateQuery();
    return response;
  });

  const handlePublishPost = (payload: any) => {
    publishPost.mutate(payload, {
      onSuccess: async (res: any) => {
        if (res?.status === 200 || res?.status === 201) {
          toast.success('Postagem publicada!');
        }
      },
      onError: async (err: any) => {
        const message = verifyError(
          err,
          'Erro ao confirmar publicação de postagem',
        );
        toast.error(message);
      },
    });
  };

  return {
    fatosRelevantes,
    submitFatoRelevante,
    handleDeleteFatoRelevante,
    submitImagem,
    handleDeleteImagem,
    uploadedImages,
    handlePublishPost,
  };
};

export default useFatosRelevantes;
