export type mapType = {
  [key: number]: any;
};

export const listYesNo = [
  {
    label: 'Sim',
    value: 'true',
  },
  {
    label: 'Não',
    value: 'false',
  },
];

export const getStatusConvite = {
  0: 'Nenhum',
  1: 'Cadastrado',
  2: 'Excluido',
  4: 'Convite enviado',
  5: 'Convite reenviado',
};

export const listCompanyType = [
  { value: 1, label: 'S.A.' },
  { value: 2, label: 'LTDA' },
];

export const listModeloVenda = [
  { label: 'B2B - Business to Business', value: 1 },
  { label: 'B2C - Business to Costumers', value: 2 },
  { label: 'B2B2C - Business to Business to Costumers', value: 3 },
  { label: 'D2C - Direct to Costumers', value: 4 },
  { label: 'B2G - Business to Government', value: 5 },
  { label: 'C2C - Costumers to Costumers', value: 6 },
];

export const listCountries = [
  {
    label: 'Afeganistão',
  },
  {
    label: 'África do Sul',
  },
  {
    label: 'Albânia',
  },
  {
    label: 'Alemanha',
  },
  {
    label: 'Andorra',
  },
  {
    label: 'Angola',
  },
  {
    label: 'Anguilla',
  },
  {
    label: 'Antártida',
  },
  {
    label: 'Antígua e Barbuda',
  },
  {
    label: 'Arábia Saudita',
  },
  {
    label: 'Argélia',
  },
  {
    label: 'Argentina',
  },
  {
    label: 'Armênia',
  },
  {
    label: 'Aruba',
  },
  {
    label: 'Austrália',
  },
  {
    label: 'Áustria',
  },
  {
    label: 'Azerbaijão',
  },
  {
    label: 'Bahamas',
  },
  {
    label: 'Bahrein',
  },
  {
    label: 'Bangladesh',
  },
  {
    label: 'Barbados',
  },
  {
    label: 'Belarus',
  },
  {
    label: 'Bélgica',
  },
  {
    label: 'Belize',
  },
  {
    label: 'Benin',
  },
  {
    label: 'Bermudas',
  },
  {
    label: 'Bolívia',
  },
  {
    label: 'Bósnia-Herzegóvina',
  },
  {
    label: 'Botsuana',
  },
  {
    label: 'Brasil',
  },
  {
    label: 'Brunei',
  },
  {
    label: 'Bulgária',
  },
  {
    label: 'Burkina Fasso',
  },
  {
    label: 'Burundi',
  },
  {
    label: 'Butão',
  },
  {
    label: 'Cabo Verde',
  },
  {
    label: 'Camarões',
  },
  {
    label: 'Camboja',
  },
  {
    label: 'Canadá',
  },
  {
    label: 'Canárias',
  },
  {
    label: 'Cazaquistão',
  },
  {
    label: 'Ceuta e Melilla',
  },
  {
    label: 'Chade',
  },
  {
    label: 'Chile',
  },
  {
    label: 'China',
  },
  {
    label: 'Chipre',
  },
  {
    label: 'Cingapura',
  },
  {
    label: 'Colômbia',
  },
  {
    label: 'Comores',
  },
  {
    label: 'Congo',
  },
  {
    label: 'Coréia do Norte',
  },
  {
    label: 'Coréia do Sul',
  },
  {
    label: 'Costa do Marfim',
  },
  {
    label: 'Costa Rica',
  },
  {
    label: 'Croácia',
  },
  {
    label: 'Cuba',
  },
  {
    label: 'Curaçao',
  },
  {
    label: 'Diego Garcia',
  },
  {
    label: 'Dinamarca',
  },
  {
    label: 'Djibuti',
  },
  {
    label: 'Dominica',
  },
  {
    label: 'Egito',
  },
  {
    label: 'El Salvador',
  },
  {
    label: 'Emirados Árabes Unidos',
  },
  {
    label: 'Equador',
  },
  {
    label: 'Eritréia',
  },
  {
    label: 'Eslováquia',
  },
  {
    label: 'Eslovênia',
  },
  {
    label: 'Espanha',
  },
  {
    label: 'Estados Unidos',
  },
  {
    label: 'Estônia',
  },
  {
    label: 'Etiópia',
  },
  {
    label: 'Fiji',
  },
  {
    label: 'Filipinas',
  },
  {
    label: 'Finlândia',
  },
  {
    label: 'França',
  },
  {
    label: 'Gabão',
  },
  {
    label: 'Gâmbia',
  },
  {
    label: 'Gana',
  },
  {
    label: 'Geórgia',
  },
  {
    label: 'Gibraltar',
  },
  {
    label: 'Grã-Bretanha (Reino Unido, UK)',
  },
  {
    label: 'Granada',
  },
  {
    label: 'Grécia',
  },
  {
    label: 'Groelândia',
  },
  {
    label: 'Guadalupe',
  },
  {
    label: 'Guam (Território dos Estados Unidos)',
  },
  {
    label: 'Guatemala',
  },
  {
    label: 'Guernsey',
  },
  {
    label: 'Guiana',
  },
  {
    label: 'Guiana Francesa',
  },
  {
    label: 'Guiné',
  },
  {
    label: 'Guiné Equatorial',
  },
  {
    label: 'Guiné-Bissau',
  },
  {
    label: 'Haiti',
  },
  {
    label: 'Holanda',
  },
  {
    label: 'Honduras',
  },
  {
    label: 'Hong Kong',
  },
  {
    label: 'Hungria',
  },
  {
    label: 'Iêmen',
  },
  {
    label: 'Ilha Bouvet',
  },
  {
    label: 'Ilha de Ascensão',
  },
  {
    label: 'Ilha de Clipperton',
  },
  {
    label: 'Ilha de Man',
  },
  {
    label: 'Ilha Natal',
  },
  {
    label: 'Ilha Pitcairn',
  },
  {
    label: 'Ilha Reunião',
  },
  {
    label: 'Ilhas Aland',
  },
  {
    label: 'Ilhas Cayman',
  },
  {
    label: 'Ilhas Cocos',
  },
  {
    label: 'Ilhas Cook',
  },
  {
    label: 'Ilhas Faroes',
  },
  {
    label: 'Ilhas Geórgia do Sul e Sandwich do Sul',
  },
  {
    label: 'Ilhas Heard e McDonald (Território da Austrália)',
  },
  {
    label: 'Ilhas Malvinas',
  },
  {
    label: 'Ilhas Marianas do Norte',
  },
  {
    label: 'Ilhas Marshall',
  },
  {
    label: 'Ilhas Menores dos Estados Unidos',
  },
  {
    label: 'Ilhas Norfolk',
  },
  {
    label: 'Ilhas Salomão',
  },
  {
    label: 'Ilhas Seychelles',
  },
  {
    label: 'Ilhas Tokelau',
  },
  {
    label: 'Ilhas Turks e Caicos',
  },
  {
    label: 'Ilhas Virgens (Estados Unidos)',
  },
  {
    label: 'Ilhas Virgens (Inglaterra)',
  },
  {
    label: 'Índia',
  },
  {
    label: 'Indonésia',
  },
  {
    label: 'Irã',
  },
  {
    label: 'Iraque',
  },
  {
    label: 'Irlanda',
  },
  {
    label: 'Islândia',
  },
  {
    label: 'Israel',
  },
  {
    label: 'Itália',
  },
  {
    label: 'Jamaica',
  },
  {
    label: 'Japão',
  },
  {
    label: 'Jersey',
  },
  {
    label: 'Jordânia',
  },
  {
    label: 'Kiribati',
  },
  {
    label: 'Kosovo',
  },
  {
    label: 'Kuait',
  },
  {
    label: 'Laos',
  },
  {
    label: 'Lesoto',
  },
  {
    label: 'Letônia',
  },
  {
    label: 'Líbano',
  },
  {
    label: 'Libéria',
  },
  {
    label: 'Líbia',
  },
  {
    label: 'Liechtenstein',
  },
  {
    label: 'Lituânia',
  },
  {
    label: 'Luxemburgo',
  },
  {
    label: 'Macau',
  },
  {
    label: 'Macedônia (República Yugoslava)',
  },
  {
    label: 'Madagascar',
  },
  {
    label: 'Malásia',
  },
  {
    label: 'Malaui',
  },
  {
    label: 'Maldivas',
  },
  {
    label: 'Mali',
  },
  {
    label: 'Malta',
  },
  {
    label: 'Marrocos',
  },
  {
    label: 'Martinica',
  },
  {
    label: 'Maurício',
  },
  {
    label: 'Mauritânia',
  },
  {
    label: 'Mayotte',
  },
  {
    label: 'México',
  },
  {
    label: 'Micronésia',
  },
  {
    label: 'Moçambique',
  },
  {
    label: 'Moldova',
  },
  {
    label: 'Mônaco',
  },
  {
    label: 'Mongólia',
  },
  {
    label: 'Montenegro',
  },
  {
    label: 'Montserrat',
  },
  {
    label: 'Myanma',
  },
  {
    label: 'Namíbia',
  },
  {
    label: 'Nauru',
  },
  {
    label: 'Nepal',
  },
  {
    label: 'Nicarágua',
  },
  {
    label: 'Níger',
  },
  {
    label: 'Nigéria',
  },
  {
    label: 'Niue',
  },
  {
    label: 'Noruega',
  },
  {
    label: 'Nova Caledônia',
  },
  {
    label: 'Nova Zelândia',
  },
  {
    label: 'Omã',
  },
  {
    label: 'Países Baixos Caribenhos',
  },
  {
    label: 'Palau',
  },
  {
    label: 'Palestina',
  },
  {
    label: 'Panamá',
  },
  {
    label: 'Papua-Nova Guiné',
  },
  {
    label: 'Paquistão',
  },
  {
    label: 'Paraguai',
  },
  {
    label: 'Peru',
  },
  {
    label: 'Polinésia Francesa',
  },
  {
    label: 'Polônia',
  },
  {
    label: 'Porto Rico',
  },
  {
    label: 'Portugal',
  },
  {
    label: 'Qatar',
  },
  {
    label: 'Quênia',
  },
  {
    label: 'Quirguistão',
  },
  {
    label: 'República Centro-Africana',
  },
  {
    label: 'República Democrática do Congo',
  },
  {
    label: 'República Dominicana',
  },
  {
    label: 'República Tcheca',
  },
  {
    label: 'Romênia',
  },
  {
    label: 'Ruanda',
  },
  {
    label: 'Rússia (antiga URSS) - Federação Russa',
  },
  {
    label: 'Saara Ocidental',
  },
  {
    label: 'Saint-Pierre e Miquelon',
  },
  {
    label: 'Samoa Americana',
  },
  {
    label: 'Samoa Ocidental',
  },
  {
    label: 'San Marino',
  },
  {
    label: 'Santa Helena',
  },
  {
    label: 'Santa Lúcia',
  },
  {
    label: 'São Bartolomeu',
  },
  {
    label: 'São Cristóvão e Névis',
  },
  {
    label: 'São Martim',
  },
  {
    label: 'São Martinho',
  },
  {
    label: 'São Tomé e Príncipe',
  },
  {
    label: 'São Vicente e Granadinas',
  },
  {
    label: 'Senegal',
  },
  {
    label: 'Serra Leoa',
  },
  {
    label: 'Sérvia',
  },
  {
    label: 'Síria',
  },
  {
    label: 'Somália',
  },
  {
    label: 'Sri Lanka',
  },
  {
    label: 'Suazilândia',
  },
  {
    label: 'Sudão',
  },
  {
    label: 'Sudão do Sul',
  },
  {
    label: 'Suécia',
  },
  {
    label: 'Suíça',
  },
  {
    label: 'Suriname',
  },
  {
    label: 'Svalbard',
  },
  {
    label: 'Tadjiquistão',
  },
  {
    label: 'Tailândia',
  },
  {
    label: 'Taiwan',
  },
  {
    label: 'Tanzânia',
  },
  {
    label: 'Território Britânico do Oceano índico',
  },
  {
    label: 'Territórios do Sul da França',
  },
  {
    label: 'Timor-Leste',
  },
  {
    label: 'Togo',
  },
  {
    label: 'Tonga',
  },
  {
    label: 'Trinidad e Tobago',
  },
  {
    label: 'Tristão da Cunha',
  },
  {
    label: 'Tunísia',
  },
  {
    label: 'Turcomenistão',
  },
  {
    label: 'Turquia',
  },
  {
    label: 'Tuvalu',
  },
  {
    label: 'Ucrânia',
  },
  {
    label: 'Uganda',
  },
  {
    label: 'Uruguai',
  },
  {
    label: 'Uzbequistão',
  },
  {
    label: 'Vanuatu',
  },
  {
    label: 'Vaticano',
  },
  {
    label: 'Venezuela',
  },
  {
    label: 'Vietnã',
  },
  {
    label: 'Wallis e Futuna',
  },
  {
    label: 'Zâmbia',
  },
  {
    label: 'Zimbábue',
  },
];

export const listStates = [
  { value: 'AC' },
  { value: 'AL' },
  { value: 'AM' },
  { value: 'AP' },
  { value: 'BA' },
  { value: 'CE' },
  { value: 'DF' },
  { value: 'ES' },
  { value: 'GO' },
  { value: 'MA' },
  { value: 'MG' },
  { value: 'MS' },
  { value: 'MT' },
  { value: 'PA' },
  { value: 'PB' },
  { value: 'PE' },
  { value: 'PI' },
  { value: 'PR' },
  { value: 'RJ' },
  { value: 'RN' },
  { value: 'RO' },
  { value: 'RR' },
  { value: 'RS' },
  { value: 'SC' },
  { value: 'SE' },
  { value: 'SP' },
  { value: 'TO' },
];

export const listSectors = [
  { value: 1, label: 'Aeronautica' },
  { value: 2, label: 'Agricultura' },
  { value: 3, label: 'Agtech' },
  { value: 4, label: 'Alimentos e Bebidas' },
  { value: 5, label: 'Arquitetura e Urbanismo' },
  { value: 6, label: 'Automotivo' },
  { value: 7, label: 'Bens de Consumo' },
  { value: 8, label: 'Consultoria' },
  { value: 9, label: 'Cosméticos e Beleza' },
  { value: 10, label: 'Edtech' },
  { value: 11, label: 'Educação' },
  { value: 12, label: 'Fintech' },
  { value: 13, label: 'Hardware' },
  { value: 14, label: 'Healthtech' },
  { value: 15, label: 'Imobiliário' },
  { value: 16, label: 'Indústria' },
  { value: 17, label: 'Infraestrutura' },
  { value: 18, label: 'Jurídico' },
  { value: 19, label: 'Legaltech' },
  { value: 20, label: 'Logística' },
  { value: 21, label: 'Marketing' },
  { value: 22, label: 'Mídia e Entretenimento' },
  { value: 23, label: 'Óleo e Gás' },
  { value: 24, label: 'Proptech' },
  { value: 25, label: 'Publicidade' },
  { value: 26, label: 'Recursos Humanos' },
  { value: 27, label: 'Saúde' },
  { value: 28, label: 'Seguros' },
  { value: 29, label: 'Serviços Gerais' },
  { value: 30, label: 'Social' },
  { value: 31, label: 'Tecnologia' },
  { value: 32, label: 'Varejo de Alimentos' },
  { value: 33, label: 'Varejo de Moda' },
];
