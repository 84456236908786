import React, { useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { AxiosResponse } from 'axios';
import { api } from '../../../../../services/api';
import { verifyError } from '../../../../../utils/verifyError';

export const useCollaboratorCompanies = ({
  companyId,
  colaboradorId,
  companyType,
}: {
  companyId: string;
  colaboradorId?: string;
  companyType?: 'nomad' | 'auditor' | 'escriturador';
}) => {
  const queryClient = useQueryClient();
  const [companiesAvailable, setCompaniesAvailable] = useState<any>([]);

  const handleCompanyType = () => {
    if (companyType === 'auditor') {
      return 'sem-colaborador-auditoria';
    }
    return 'sem-colaborador';
  };

  const getCollaboratorCompanies = async () => {
    const getToast = toast.loading('Carregando empresas disponíveis');
    try {
      const response: AxiosResponse<any> = await api.main.get(
        `/v1/empresas/${handleCompanyType()}/${companyId}${
          colaboradorId ? `?colaboradorId=${colaboradorId}` : ''
        }`,
      );
      if (colaboradorId) {
        const response2 = await api.main.get(
          `/v1/empresas/${handleCompanyType()}/${companyId}`,
        );
        setCompaniesAvailable(response2.data);
      } else {
        setCompaniesAvailable(response.data);
      }
      toast.update(getToast, {
        render: 'Empresas carregadas com sucesso!',
        type: 'success',
        isLoading: false,
        autoClose: 1,
      });
      return response?.data;
    } catch (error) {
      const message = verifyError(
        error,
        'Erro ao carregar empresas disponíveis para o colaborador',
      );
      toast.update(getToast, {
        render: message,
        type: 'error',
        isLoading: false,
        autoClose: 5000,
      });
    }
  };

  const {
    isLoading,
    isSuccess,
    isError,
    isIdle,
    data: companies,
  } = useQuery(
    ['empresasColaborador', companyId, colaboradorId, companyType],
    getCollaboratorCompanies,
    {},
  );

  const invalidateQuery = async () => {
    await queryClient.invalidateQueries('empresasColaborador');
  };

  return {
    isLoading,
    isError,
    isIdle,
    isSuccess,
    companies,
    companiesAvailable,
    getCollaboratorCompanies,
    queryClient,
  };
};

export default useCollaboratorCompanies;
