import styled from 'styled-components';

export const ButtonsWrapp = styled.div`
  display: flex;
  width: 360px;
  justify-content: space-between;
  margin: 3rem auto;
`;

export const PageDescription = styled.p`
  margin-bottom: 2rem;
`;

export const VersionControl = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
`;

export const VersionContent = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const VersionText = styled.h2`
  font-size: 18px;
  color: #171725;
  letter-spacing: 0.54px;
  font-weight: bold;
`;

export const Version = styled.span`
  font-size: 12px;
  font-weight: 600;
`;

export const HistoryTitle = styled.h2`
  font-size: 24px;
`;

export const VersionChangeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #c3c6cf4d;
  color: #171725;
  border: none;
  outline: none;
  border-radius: 8px;
  padding: 0.7rem 2rem;
  margin: 1rem 0;
  width: 100%;
`;

export const VersionContainer = styled.div`
  background-color: white;
  margin: 2rem 0 4rem 0;
  padding: 20px 30px;
  border-radius: 4px;
  box-shadow: 0px 1px 4px #15223214;
`;

export const PageDescription2 = styled.p`
  font-size: 12px;
  margin-top: 0.5rem;
`;

export const DividedGrid = styled.div`
  display: flex;
  gap: 5px;
  width: 100%;

  & > * {
    flex: 1;
  }
`;

export const RadioLabel2 = styled.div`
  font-size: 14px;
  color: #51576c;
  margin-bottom: 2rem;
`;

export const RadioTitle = styled.p`
  font-size: 14px;
  color: #171725;
`;

export const RadioDescription = styled.p`
  font-size: 12px;
  color: #454b62;
`;
