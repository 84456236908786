export interface Routes {
  [key: string]: string;
}

export const routes: Routes = {
  '/direitos-do-usuario': 'Direitos do usuário',
  '/relacao-com-investidores': 'Relação com investidores',
  '/administracao-global': 'Administração global',
  '/gestao-usuarios': 'Gestão de usuários',
  '/processo-de-listagem': 'Processo de listagem na BEE4',
  '/dashboard-listagem': 'Dashboard',
  '/dados-gerais': 'Dados gerais',
  '/documentacao-adicional': 'Documentação adicional',
  '/juridico': 'Jurídico',
  '/diligencia': 'Diligência',
  '/sobre-o-ativo': 'Sobre o ativo',
  '/dados-da-oferta': 'Dados da oferta',
  '/relatorios-pos-listagem': 'Relatórios',
  '/atualizacoes-cadastrais': 'Atualizações cadastrais',
  '/convidar-usuario': 'Convidar usuário',
  '/fatos-relevantes': 'Fatos relevantes',
  '/emissores': 'Colaboradores',
  '/manuais-bee4': 'Manuais da BEE4',
  '/documentacao-ipo': 'Documentação IPO',
  '/documentacao-listagem': 'Documentação Listagem',
};
