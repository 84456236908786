import styled from 'styled-components';

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 24px 8px;
  h3 {
    color: #262936;
    font-size: 1.5rem;
  }
  span {
    color: #454b62;
    font-size: 1rem;
  }
`;

export const ButtonContent = styled.div`
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
`;
